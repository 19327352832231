@keyframes bounceup {
    from {
        transform: translate(-50%, 100%);
    }
    to {
        transform: translate(-50%, 0);
    }
}

.ds-modal-dialog {
    color: $text-primary;
    background: $white;
    bottom: 0;
    box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.15);
    left: 50%;
    max-width: 568px;
    position: fixed;
    transform: translate(-50%, 100%);
    width: 100%;
    z-index: 2147483638;
    display: none;

    .ds-modal-content,
    .ds-modal-success {
        padding: 32px;
    }

    .ds-modal-success-content {
        display: flex;
        flex-direction: column;

        .ds-done-button {
            @include ds-utilities-button;
            align-self: end;
            width: auto;
        }
    }

    .ds-modal-heading {
        @include ds-heading(h2);
        margin: 0 0 8px;
    }

    .ds-modal-paragraph {
        @include ds-body(l);
        color: $text-secondary;
        margin-bottom: 24px;
    }

    &.ds-modal-open {
        display: block;
        animation: bounceup 0.2s;
        animation-timing-function: cubic-bezier(0.2, 0.05, 0.55, 0.2);
        transform: translate(-50%, 0);
    }

    button.ds-close-button {
        background-image: $close-small;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        height: 48px;
        max-height: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        transition: none;

        &:not([disabled]):not(.disabled):hover {
            background-color: $grey-10;
            box-shadow: inset 0 0 0 8px white;
        }
    }

    .ds-buttons-container {
        display: flex;
        justify-content: flex-end;

        .ds-cancel {
            @include ds-ui-base(m);
            background-color: $white;
            border: none;
            color: $dim-gray;
            text-decoration: underline;
            margin-top: 8px;
            margin-right: 32px;
            text-transform: none;
            padding: 0;
            align-self: center;

            &:not([disabled]):not(.disabled):hover {
                background-color: transparent;
                color: $dim-gray;
            }
        }

        .ds-cancel:active {
            outline: #000 dotted 1px;
            outline-offset: 2px;
        }

        .ds-cancel:hover,
        .ds-cancel:focus {
            text-decoration: none;
            cursor: default;
            color: $dim-gray;
        }
    }
}
