#secondary{
    a {
        @include ds-ui-regular(m);
        color: $text-primary;
        display: block;
    }
    nav{
        ul{
            li{
                a{
                    /*display: inline;*/
                    &.current {
                        color: $cobalt;
                    }
                }
            }
        }
    }
}
.folder-refinement{
    &.refinement {
        h3 {
            @include font-bold(14px);
            border-bottom: none;
            margin:0 0 10px 0 !important;
        }
        #category-level-1 {
            li {
                margin-bottom: 5px !important;
                .refinement-link{
                    @include font-regular(12px);
                    @include line-height(16px);
                    color: $secondary-color;
                    text-transform: none;
                    padding-bottom: 5px;
                }
            }
        }
    }
}
#category-level-1 {
    li {
        color: $dim-gray;
        display: block;
        .refinement-link {
            @include font-bold(12px);
            color: $secondary-color;
            text-transform: uppercase;
            padding-bottom: 20px;
            display: block;
        }
        &.expandable {
            .refinement-link {
            }
            .active {
                font-weight: bold;
            }
        }
    }
    > li{
        margin-bottom: 28px;
    }
}

#category-level-2 {
    a {
        &.active {
            color: $night-rider;
        }
        &.refinement-link {
            padding-bottom: 18px;
            @include font-regular(13px);
            text-transform: none;
            display: inline-block;
        }
        &.close-button{
            display: none;
        }
    }
}

#category-level-2 {
    padding: 0;
}

#category-level-3 {
    padding: .3em 0 0;
    a {
        &.refinement-link {
            font-style: normal;
            margin: 0 0 0 .9em;
            padding: 0;
        }
        &.active {}
    }
}

.refinement-header {
    background-color: $white-smoke;
    border-top: 1px solid $gainsboro;
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.125em;
    margin: 0;
    padding: 1.15em .75em .75em;
    text-transform: uppercase;
    display: none;
}

.refinement {
    padding-bottom: .65em;
    position: relative;
    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
        display: none;
        &.category-refinement {
            display: block;
        }
    }
    h3 {
        @include ds-heading(h4);
        color: $text-primary;
        border-bottom: 3px solid $secondary-border;
        padding: 3px 0;
        text-transform: capitalize;
        .ie7 & {
            clear: both;
        }
       

        i.fa{
            width: 15px;
            height: 15px;
            margin: 2px 2px 0 2px;
            background: url('../images/svg-icons/filter-carat.svg') no-repeat center;
            @include respond(medium-down) {
                background: url('../images/svg-icons/UI-Carat.svg') no-repeat center;
                background-size: 100%;
            }
            background-size: 15px;
            @include transition(all 0.5s ease-out);
            &.rotate{
                transform: rotate(180deg);
            }
        }
    }

    .ref-toggle {
        @media only screen and (min-width: $lMin) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    ul {
        list-style: none outside none;
        margin: 0;
        padding: 0 10px;
        margin-bottom: 5px;
    }
    li {
        padding: 0;
    }
    ul {
        li {
            i {
                color: $gainsboro;
            }
            &.selected {
                i {
                    color: $rain-forest;
                }
            }
        }
        &.swatches li {
            float: left;
            padding: .16em;
            a {
                border: 2px solid $gainsboro;
                border-radius: .5em;
                display: block;
                padding: 0.2em 0.8em;
            }
            &.selected a {
                border-color: $citrus;
                font-weight: bold;
            }
            &.unselectable a {
                background-image: url("../images/interface/icon-color-swatch-unselectable.png");
                background-position: right top;
            }
        }
        &.refinementColor li a {
            height: 23px;
            padding: 0;
            text-indent: -999em;
            width: 30px;
        }
    }
    .clear-refinement {
        font-weight: bold;
        padding: 0 1.05em 0.4em;
    }
    .refinement-link {
        border: 0 none;
    }
    .selected .refinement-link {
        background-position: left bottom;
    }
    .refinement-top-level {
        padding: 1em;
    }
}

.scrollable {
    height: 270px;
    overflow: auto;
    @include respond(medium-down) {
        height: auto;
    }

    @include respond(large) {
        height: auto;
    }
}

.nav {
    ul {
        border: 0 none;
        list-style: none outside none;
        margin: calculateEm(10px) 0;
        padding: 0;
        li {
            list-style: none outside none !important;
            margin: calculateEm(20px) 0;
            @include line-height(20px);
            a{
                @include font-regular(14px);
            }
        }
    }
    .toggle {
        @include ds-heading(h6);
        color: $text-primary;
        border: 0 none;
        display: block;
        margin: calculateEm(20px) 0;
        span {
            background: none repeat scroll 0 0 transparent;
        }
    }
    .expanded {
        background-position: left -96px;
    }
    .top-level{
        display: none;
    }
    @include respond(medium-down) {
    	&.content-refinements {
    		margin-bottom: 20px;
    		.secondary-navigation {
				padding: 0 15px;
    		}
    	}
        .top-level{
            display: block;
            &.toggle{
                @include font-regular(14px);
                padding: 23px 15px;
                margin: 5px 0;
                background: $bg-color;
                &:after{
                    content: "";
                    width: 18px;
                    height: 18px;
                    float: right;
                    background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
                    @include transition(transform 0.5s ease-out);
                }
                &.rotate{
                    &:after{
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .secondary-navigation{
            display: none;
            .toggle{
                padding: 0 15px;
            }
        }
    }
}
.mac .nav, .iphone .nav{
    @include respond(medium-down) {
        .top-level {
            &.toggle {
                padding: 25px 15px 21px;
                &:after{
                    position: relative;
                    top: -2px;
                }
            }
        }
    }
}
.secondary-navigation{
    @include respond(large-up) {
        display: block !important;
        .toggle{
            &:first-child{
                margin-top: 0;
            }
            margin: 20px 0;
        }
         > ul{
             margin: 0px 0 45px;
             li{
                 margin: 20px 0 23px !important;
             }
        }
    }
    @include respond(medium-down) {
        > ul{
            li{
                padding: 10px 15px;
                margin: 0 !important;
            }
        }
    }
}
.active-sub-category{
    h2.toggle{
        &:first-child{
            margin-top: 1px;
        }
    }
    .category-level-2{
        margin-bottom: 45px;
    }
}