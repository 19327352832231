@import "nav";
@import "menu_utility";
@import "minicart";
body{
    &.sticky{
        header{
            @include respond(large-up) {
                min-height: 152px;
            }
        }
    }
}
.promo-banner-container.visible + header.stickyOn {
    z-index: 111 !important;
    min-height: unset !important;
}
header, header.stickyOn {
   border: none !important;
   z-index: 111 !important;
}
header.header-fixed {
	position:fixed;
	top:0;
	left:0;
	z-index: 2;
}
@media screen and (max-width: 767px) {
   .promo-banner-container {
       z-index: 112 !important;
   }
}

.skip-nav .ds-link {
    align-items: center;
    background: $white;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.15);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 8px;
    padding: 16px;
    position: absolute;
    top: 8px;
    transform: translate(0%, calc(-100% - 16px));
    transition: transform 0.8s;
    z-index: 1000;

    &:focus {
        transform: translate(0, 0);
        transition: none;
    }
}

.bopisHeader, .bopisHomePage {
	    text-align: center;
        color: $text-brand-primary;
        border-bottom: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        float: right;
        padding: 0px;

        @include respond(large-up) {
            box-sizing: border-box;
        }

    .bopis-icon {
		&::before {
			content: '';
            background: url('../images/svg-icons/bopis-icon-16x16.svg') no-repeat;
            width: 16px;
            height: 16px;
            padding: 0px 4px 0px 0px;
            display: flex;
		}
    }
    .bopis-set-store {
        @include ds-ui-regular(s);
        color: $text-brand-primary;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 8px;
        @include primary-default-link; //:hover, :focus, :active states
        &.bopis-store-name {
            text-transform: capitalize;
        }
    }

    .bopis-pickup-instore-txt {
        @include ds-heading(h6);
    }
}
.bopisHomePage {
    float: left;
    margin: 10px 15px;

    &.mobile-only {
        justify-content: space-between;
        float: unset;

        .bopis-left-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
.bopis-mobileheader .bopis-icon {
    &::before {
		content: '';
        background: url('../images/svg-icons/bopis-icon-small.svg') no-repeat;
        background-size: 24px 35px;
        width: 24px;
        height: 35px;
        padding: 0px 10px 0px 0px;
    }
}
.top-banner {
    min-width: $small-breakpoint;
    .wrap {
        position: relative;
        margin: auto;
        @include clearfix;

        @include respond(desktop) {
            max-width: $max-width;
            z-index: 150;
        }
        @include respond(medium-down) {
            height: auto;
            border-bottom: 3px solid $bg-color;
            @include transition(height 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94));
        }
        @include respond(medium-down) {
            .nav-icn {
                z-index: 120;
            }
            &.search-focus{
                .header-search {
                    input[type="text"],
                    input[type="search"] {
                        display: block;
                    }
                    button{
                        position: absolute;
                        top: 9px;
                        transition: none;
                    }
                    .cancel{
                        color: $secondary-color;
                        cursor: pointer;
                        display: inline;
                        @include font-size(12px);
                        padding: 11px;
                        position: absolute;
                        right: 0;
                        text-decoration: underline;
                        text-transform: capitalize;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .icon-items{
            @include respond(medium-down) {
                background-color: $white;
                overflow: hidden;
                padding: 9px 6px 8px;
            }
            @include respond(large-up) {
                float: right;
                height: 0;
            }
            .nav-icn {
                &:hover {
                    .cart-icon, .minicart-quantity {
                        @include transition(opacity 0.5s ease-out);
                        @include opacity(0.65);
                    }
                }

                &:active {
                    .cart-icon, .minicart-quantity {
                        @include opacity(0.33);
                        filter: grayscale(100%);
                    }
                }
                .user-account, .wishlist {
                    &:hover {
                        svg, img.icon {
		                    @include transition(opacity 0.5s ease-out);
		                    @include opacity(0.65);
                           }
                    }
	                &:active {
	                    svg, img.icon {
	                        @include opacity(0.33);
	                        filter: grayscale(100%);
	                    }
	                }
                }
            }
        }
        .cancel{
            display: none;
        }
    }
    .icon:not(.custom-menu):not(.icon-search):not(.cart-icon):not(.question-mark):not(.question-mark-invert):not(.question-mark-blue):not(.question-mark-blue-invert){
        height: 36px;
        width: 36px;
        @include borderbox;
    }
    .icon.PDP-Wishlist-4 {
        width: 25px !important;
        @include respond(medium-down) {
            width: 14px !important;
            top: 2px !important;
        }
    }
    * {
        @include borderbox;
    }
    @include respond(large-up) {
        &.sticky.stickyOn {
            position: fixed;
            z-index: 111;
            top: 0;
            width: 100%;
            background: $white;
            -webkit-transform: translate3d(0px,0px,0px);
            &.addOn {
                margin-top: 40px !important;
            }
        }
    }
    @include respond(large-down){
	    &.stickyOn.addOn {
	        margin-top: 0px !important;
	    }
    }
    &.sticky{
        @include respond(medium-down) {
            .menu-toggle span,
            .mini-cart-total .mobile-label,
            .stores span {
                display: none;
            }
        }
    }
}

.primary-logo {
    margin: -2% auto 0;
    width: 48vw;
    height: auto;
    z-index: 1;
    left: 2px;
    min-width: 166.4px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: scale(1);
    @include borderbox;
    // XS
    @include respond(320px, 375px) {
        width: 50vw;
    }
    // SM
    @include respond(376px, 479px) {
        left: 6px;
        max-width: 258px;
        width: 54vw;
    }
    @include respond(medium) {
        margin-top: -12px;
        width: 258px;
    }
    @include respond(large-up) {
        margin: 17px auto 8px;
        width: 258px;
        height: 25px;
        left: auto;
        position: relative;
        right: auto;
    }
    @include respond(desktop) {
        left: 5px;
    }
    @include respond(standart) {
        margin-top: 27px;
        width: 340px;
        height: 34px;
    }
    a,img{
        transition: width 500ms, height 500ms;
        transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        @include respond(large) {
            position: relative;
        }
    }
    a {
        @include respond(medium-down) {
            display: block;
            line-height: 0;
        }
    }
    &:hover {
        @include transition(opacity 0.5s ease-out);
        @include opacity(0.65);
    }

    &:active {
        @include opacity(0.33);
        filter: grayscale(100%);
    }
    img {
        width:100%;
        height:100%;
    }
}
.header-banner {
    display: block;
    width: 100%;
    height: 36px;
    background: $bg-primary;
    border-bottom: $border-primary 2px solid;

    @include respond(medium-down){
        display: none;
    }

    &.addOn.stickyOn{
        margin-top: 0px !important;
    }

    &.promo-banner-container{
        position:relative;

        &.visible{
            display: inline;
        }
    }
    .wrap-promo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $max-width;
        height: 100%;
        @include respond(large) {
            padding: 0px 20px 0;
        }
        @include respond(standart) {
            padding: 0px 41px 0 25px;
        }
        @include respond(desktop) {
            padding: 0 25px;
        }
    }
    .header-promotion {
        width: 50%;
        float: right;
        position: relative;
        a{
            display: inline-block;
            &.home-fragrance-link{
                width: 115px;
                height: 35px;
            }
        }

        @include respond(desktop) {
            span{
            }
        }
    }
}
.monetate{
    //@include line-height(42px);
    height: 42px;
    text-align: center;
    overflow: hidden;
    margin-top: 0;
    position: relative;
    display: none;
    .close{
        position: absolute;
        right: 0;
        top: 0;
        width: 42px;
        height: 42px;
        background: $cobalt url("../images/svg-icons/UI-Close-dark-hover.svg") no-repeat scroll center;
        background-size: 15px;
        cursor: pointer;
    }
    &.stickyOn{
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 111;
    }
    @include respond(medium-down){
        display: none;
        height: 42px;
    }

    .monetate-template .regular {
        top: -4px;
    }
    &.visible + header{
        &.stickyOn {
            @include respond(medium-down) {
                min-height: 107px !important;
            }
        }
    }

    .monetate-template.desktop {
        display: none;
    }
    @include respond(large-up) {
        .monetate-template.desktop {
            display: block;
        }

        .monetate-template.mobile {
            display: none;
        }
    }
    .monetate-template{
        .monetate-styles-container{
            position: relative;
            min-height: 42px;
            box-sizing: border-box;
            @include respond(desktop){
                padding: 4px 20px 5px 30px;
            }
            @include respond(large){
                padding: 4px 11px 5px;
            }
            @include respond(medium){
                padding: 3px 32px 0px;
            }
            @include respond(small){
                padding: 3px 5px 0px;
            }
        }
    }
}

header {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    background: $white;
}
%mobile-label{
    @include font-bold(8px);
    text-transform: uppercase;
    color: $cobalt;
    letter-spacing: 0;
}
.stores{
    width: 25px;
    float: left;
    margin: 0;
    text-align: center;
    @include respond(medium-down) {
        width: 36px;
    }
    .icon{
        &.locator-mobile{
            width: 20px;
            height: 30px !important;
            margin:0px 0 2px 6px;
        }
    }
    span{
        @extend %mobile-label;
    }
}

.search {
    float: left;
    margin: 0;
    text-align: center;
    @include respond(medium) {
        margin-left: 2vw;
    }
    a {
        display: block;
        height: 36px;
        width: 36px;
    }
    .icon{
        &.search-mobile{
            padding: 8px;
        }
    }
}

.offers{
    float: right;
    margin: 0;
    @include respond(medium) {
        margin-right: 2vw;
    }
    @include respond(medium-down) {
        width: 36px;
    }
    .icon{
        &.offers-mobile{
            width: 21px;
            height: 29.5px;
            padding: 3px 7.5px 4px;
        }
    }
    a {
        display: block;
        height: 36px;
        width: 36px;
    }
}

.header-search {
    display: block !important;
    @include respond(medium-down) {
        background-color: $white;
        height: rem(53px);
        left: 0;
        opacity: 0;
        pointer-events: none;
        padding: 10px 60px 10px 45px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        &::before {
            background: url("../images/svg-icons/UI-Search-v2.svg") no-repeat 8px 8px;
            box-sizing: border-box;
            content: "";
            height: 36px;
            left: 6px;
            padding: 8px;
            position: absolute;
            top: 9px;
            @include transition(none);
            width: 36px;
        }
        .search-focus & {
            opacity: 1;
            pointer-events: auto;
            @include transition(all 0.075s ease-in);
            z-index: 130;
        }
    }
    @include respond(large-up) {
        margin: 0;
        z-index: 1;
        float: right;
        position: relative;
        top: -37px;
        width: 110px;
        display: block !important;
        &.onsite-search-wrapper {
            z-index: 130;
        }
    }
    @include respond(standart) {
        width: 145px;
        top: -49px;
    }
    @include respond(desktop) {
        width: 177px;
    }
    form {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        fieldset {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
        }
    }
    input[type="search"],
    input[type="text"] {
        @include ds-ui-regular(l);
        background-color: $white;
        border: 1px solid $dark-gray;
        color: $secondary-color;
        float: left;
        margin: 0;
        padding: 0 40px 0 12px;
        width: 100%;
        @include transition(none);
        -webkit-appearance: none;
        vertical-align: middle;

        @include respond(medium-down) {
            background-color: transparent;
            border: 1px solid $dark-gray;
            border-bottom: 2px solid $cobalt;
            padding: 0 0 0 5px;
            position: relative;
            width: 100%;
        }

        @include respond(large-up) {
            @include ds-ui-regular(m);
        }
    }

    ::placeholder {
        opacity: 1;
        color: $dim-gray;
    }
    ::-webkit-input-placeholder {
        color: $dim-gray;
    }
    :-moz-placeholder {
        color: $dim-gray;
        opacity:  1;
    }
    ::-moz-placeholder {
        color: $dim-gray;
        opacity:  1;
    }
    :-ms-input-placeholder {
        color: $dim-gray;
    }

    button {
        background-color: transparent;
        border: none;
        color: $secondary-color;
        padding: 0;
        width: auto;
        height: 20px;
        margin: 8px 0 5px -29px;
        position: relative;
        z-index: 101;
        transition: none;
        .icon-search{
            width: 22px;
            height: 20px;
        }
        @include respond(medium-down) {
            margin: 5px 0 5px -27px;
            width: 25px;
            height: 25px;
            .icon-search{
                width: 20px;
                height: 18px;
                display: none;
            }
        }
        &:hover{
            background-color: transparent !important;
        }
        @include respond(large-up) {
            padding: 0;
            margin-top: 8px;
            @include line-height(20px);
            min-height: 20px;
        }
    }
}

.checkout-header {
	border-bottom: 3px solid $bg-color !important;
	@include respond(large-up) {
	   min-height: inherit!important;
    }
    @include respond(medium-down) {
       display: none;
    }
    .header-banner {
        @include respond(large-up) {
            display: block;
            width: 100%;
            height: 36px;
         }

        .wrap-promo {
            @include ds-ui-regular(s);
            color: $text-primary;
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1400px;
            height: 100%;
            @include respond(large-up) {
                padding: 0 20px;
            }
            span {
                padding-right: 12px;
                &.separator {
                    padding: 0 8px;
                }
            }
        }
    }
    .top-banner {
        .wrap {
            height: 63px;
            @include respond(large-up) {
                height: 80px;
            }
            @include respond(large) {
                height: 60px;
            }
            .primary-logo {
		        margin: 0 auto;
		        width: 220px;
		        position: relative;
		        top: 22px;
		        height: initial;
		        @include respond(medium) {
		            width: 276px;
		            top: 20px;
		        }
		        @include respond(large-up) {
		            width: 340px;
		            top: 25px;
		        }
		        @include respond(large) {
		            width: 258px;
		            top: 20px;
		        }
            }
            #mini-cart {
                top: -10px;
				margin: 0 14px 0 0;
				@include respond(medium) {
				    margin-right: 18px;
				    top: -15px;
				}
				@include respond(large-up) {
					top: -14px;
					margin-right: 41px;
				}
				@include respond(large) {
				    margin-right: 27px;
				}
				.mobile-label {
				    display: none;
				}
		    }
        }
    }
}

.mobile-search.ui-widget-overlay {
    z-index: 110 !important;
}
.loyalty-welcome-message {
    color: $text-brand-primary;
    @include ds-ui-regular(s);
    margin: 0;
    &.desktop-only {
        width: 50%;
        float: left;
        height: 100%;
		display: flex;
		align-items: center;
        justify-content: flex-end;
        &:nth-child(2), &:only-child {
            width: 100%;
        }
    }

    &.mobile-only {
        .header-rewards-link {
            margin-bottom: 4px;
            margin-left: 3px;
            vertical-align: bottom;
        }
    }
    .greeting {
        text-transform: uppercase;
        margin-right: 4px;
		@media screen and (max-width: 767px) {
            display: block;
        }
    }

    b {
        @include ds-heading(h6);
        color: $text-brand-primary;
    }
    a {
        text-decoration: none;

        &.recoginzedUser {
            color: $text-primary;
        }

        &.l-name {
            color: $text-brand-primary;
            margin-right: 5px;
            @include respond(medium-down) {
                margin-right: 0;
                display: inline-block;
                padding-top: rem(20px);
                vertical-align: bottom;
            }
        }

        &.recoginzedUser {
            text-decoration: underline;
        }

        span.singular {
            display: none;
        }
        span.one {
            span.singular {
                display: inline-block;
            }
            span.plural {
                display: none;
            }
        }
    }
    .join-message,
    .rewards-status {

        strong {

        }
    }
    &.member {

    }
    .guest-first-span {
        @include ds-heading(h6);
        color: $text-brand-accent;
    }
    .guest-second-span {
        color: $text-primary;
        padding-left: 4px;
    }
    .n-dash {
        color: $text-primary;
        padding: 0 rem(3px);
    }
    .join-text {
        a {
            @include ds-ui-regular(s);
            color: $text-primary;
            text-decoration: underline;
        }
    }
    .guest-third-span {
        @include ds-ui-regular(s);
        color: $text-primary;
        text-decoration: underline;
        margin-left: 4px;
    }
    .new-line {
        display: inline-block;
        @include respond(medium-down) {
            display: block;
        }
    }
    .desktop-only {
        display: inline-block;
        @include respond(medium-down) {
            display: none;
        }
    }
    .black-text {
        color: $text-primary;
    }
 }
.ui-dialog.bocpopup {
    @include respond(medium-down) {
        width: 290px !important;
        .dialog-content {
            padding: 0 15px !important;
        }
    }
    &.loyalty-error-msg-flow {
	    @include respond(medium-down) {
	        width: 346px !important;
	        .dialog-content {
	            padding: 0 15px !important;
	        }
	        .loyalty-header {
	            width: 346px;
	        }
	        .loyaltylogin #dwfrm_login {
     			max-width: unset;
			}
	    }
    }
    .ui-dialog-title {
        display: none;
    }
    .boc-header, .loyalty-header {
        width: 400px;
        margin-left: -35px;
        margin-top: 5px;
        @include respond(medium-down) {
            width: 290px;
            margin-left: -15px;
        }
        img {
            width: 100%;
        }
    }
    .boclogin, .loyaltylogin {
        .login-box {
            margin-bottom: 25px;
            margin-top: 5px;
            .loginform-new .form-row-button {
                .login-rememberme {
                    margin-left: 37px;
                    label {
                        color: $dim-gray;
                        padding-right: 0;
                    }
                    @include respond(medium-down) {
                        margin-left: 20px;
                    }
                }

                #password-reset {
                    color: $dim-gray;
                    &:hover {
                        text-decoration:none;
                    }
                    &:active {
                        text-decoration:none;
                        color: $click;
                    }
                }

                button {
                    display: block;
                    @media screen and (max-width: 767px) {
                    	width: 100%;
                    }
                }
            }
        }
        .form-row label {
            margin-bottom: 17px;
        }
        #dwfrm_login {
            max-width: 260px;
        }
        .create-an-account-link {
            margin: 2em 0.5em;
            text-align: center;
            button {
                color: $night-rider;
                text-decoration: underline;
                background-color: transparent;
                letter-spacing: 0;
                text-transform: none;
                font-size: 14px;
                border: 0;
                &:hover {
                    text-decoration: none;
                    background-color: transparent;
                    outline: 0;
                }
                &:active {
                    text-decoration: none;
                    color: $click;
                    outline: 0;
                    background-color: transparent;
                }
            }
        }
    }
    .boc-success {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        @include respond(medium-down) {
            margin-bottom: 25px;
            margin-top: 0;
        }
        .width-206 {
            @include respond(medium-down) {
                width: 206px;
            }
        }
        .boc-heading {
            @include font-bold(21px);
            @include line-height(21px);
            color: $brand-pink;
            margin-top: 20px;
            margin-bottom: 30px;
        }
        @include respond(medium-down) {
            a.button {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}

.csr-bopis-toggle-form {
    display: flex;
    align-items: center;

    &.desktop-only {
        float: right;
        padding-right: 10px;
        @include respond(medium-down) {
            display: none;
        }
    }

    &.mobile-only {
        padding: 5px 15px;
        @include respond(large-up) {
            display: none;
        }
    }
    .input-checkbox {
        width: 16px;
        height: 16px;
    }

    .bopis-experience {
        display: inline;
        top: unset;

        margin: 0;
    }
}

nav#navigation{
    .bopis-eligible-loyalty-customer{
        .mobile-menu-message-container{
            background: none;
            padding: 15px 5px 5px 15px;
                .loyalty-welcome-message{
                    a.l-name{
                        @include respond(medium-down){
                            padding-top: 0;
                            text-transform: none;
                        }
                    }
                    .greeting{
                        @include respond (medium-down){
                            margin-bottom: 4px;
                        }
                    }
                    a.header-rewards-link{
                        margin-bottom: 1px;
                    }

                    &.mobile-only {
                        .join-loyalty-mobile-container.mobile-only {
                            display: none;
                        }
                    }
                }
        }
    }
}

.header-rewards-link {
    display: inline-block;
    margin-left: 5px;
    margin-top: -1px;
    width: 15px;
    height: 15px;
    outline-offset: 2px;
    overflow: visible;
    position: relative;
    z-index: 2;
    @include respond(desktop) {
    	&:hover {
	        outline: 1px dotted $night-rider;
	        .standard, .active {
	            display: none;
	        }
	        .hover {
	            display: inline;
	        }
	    }
    }
    .hover, .active {
        display: none;
    }
    &:active {
        outline: 1px dotted $night-rider;
        .standard, .hover {
            display: none;
        }
        .active {
            display: inline;
            fill: $dark-gray;
            vertical-align: top;
        }
    }
    svg, img {
        display: block;
        fill: $brand-blue;
        width: 15px;
        height: 15px;
        position: relative;
        z-index: -1;
        pointer-events: none;
    }
}

@-moz-document url-prefix() {
    .header-rewards-link {
        margin-top: 0;
     }
}

.open-modal {
    height: 100vh;
    overflow: hidden;
    .ui-dialog {
        overflow-y: auto;
        max-height: 90vh;
    }
}

.employee-banner {
    background-color: $bg-feedback-info;
    color: $text-feedback-info;
    @include ds-ui-bold(m);
    padding: 10px;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 999;

    a {
        color: $text-feedback-info;
        text-decoration: underline;
    }
}

.associate-discount {
    text-align: left;
    font-style: normal;
    color: $text-primary;

    h2, h3 {
        line-height: 24px;
        letter-spacing: 0.1px;
        text-align: left;
        margin: 0px;
    }

    h2 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    h3 {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
    }

    label {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.16px;
        margin: 0px;
        padding-bottom: 8px;
    }
}
