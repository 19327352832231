.ds-accordion {
    text-align: left;

    ul,
    li {
        list-style: none;
    }

    .ds-accordion-link-lists {
        .menu-expanded {
            .accordionSubMenu {
                display: block;
            }

            .ds-chevron-icon {
                transform: rotate(180deg);
            }
        }

        @media only screen and (min-width: $mMin) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 16px;
            row-gap: 40px;
        }

        @media only screen and (min-width: $lMin) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 24px;
        }

        @media only screen and (min-width: $xlMin) {
            column-gap: 32px;
        }
    }

    .parent {
        padding: 8px 0;
        border-top: 1px solid $white-smoke;

        @media only screen and (min-width: $mMin) {
            padding: 0;
            border: none;
        }

        &:last-child {
            border-bottom: 1px solid $white-smoke;
        }

        .ds-accordion-heading {
            @include ds-heading(h6);
            align-items: center;
            color: $text-primary;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            min-height: 40px;
            text-transform: uppercase;
            margin: 0 16px;

            @media only screen and (min-width: $mMin) {
                margin: 0;
                min-height: 0;
                pointer-events: none;
            }
        }

        .accordionSubMenu {
            display: none;
            margin: 16px 16px 0;
            text-align: left;

            @media only screen and (min-width: $mMin) {
                display: block;
                margin: 16px 0 0;
            }

            li {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                button.link-style {
                    padding: 0;
                    margin-bottom: 20px;
                }
            }
        }

        a,
        button.link-style {
            @include ds-ui-regular(m);
            text-decoration: none;
            color: $text-primary;
        }
    }

    .ds-accordion-heading-text {
        padding-right: 16px;
    }

    .ds-chevron-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0046 17.3001L3 9.35285L3.90503 8.30005L12.0046 15.4553L20.095 8.30005L21 9.35285L12.0046 17.3001Z' fill='black'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        content: "";
        width: 24px;
        min-width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;

        &.rotate {
            transform: rotate(180deg);
        }

        @media only screen and (min-width: $mMin) {
            display: none;
        }
    }
}
