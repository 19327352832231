
.ds-dialog__header {
    h1 {
        @include ds-heading(h4);
        padding: 0;
        margin: 0;
    }
}

.ds-dialog__text {
    text-align: left;
    margin-top: 24px;
    padding: 12px 8px;
    flex-grow: 1;
    overflow: auto;
    overscroll-behavior: contain;
    border: 1px solid $border-secondary;
    color: $text-primary;
    b,
    strong {
        font-weight: bold;
    }
    p {
        @include ds-body(m);
        &:first-child {
            margin-top: 0;
        }
    }
    a {
        @include ds-body(m);
        color: $text-primary;
        text-decoration: underline;
    }
}

.ds-dialog__button {
    @include ds-ui-semi(m);
    position: relative;
    background: $bg-primary;
    border: none;
    color: $text-primary;
    text-align: center;
    text-decoration: underline;
    text-transform: none;
}
