.stacked-categories {
    .category-preview {
        margin-bottom: 80px;

        .show-all-link {
            font-size: 16px;
            padding: 5px 30px;
            letter-spacing: 0;
            margin: 0 auto;
            display: block;
            width: fit-content;
        }
    }
}