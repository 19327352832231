#wrapper {
    &.pt_fragrance {
        footer {
            @include respond(medium-up){
                margin-top: 60px;
            }
            @include respond(medium-down){
                margin-top: 0px;
            }
        }
        #main {
            padding: 0 20px;            
            .primary-content {
                max-width: 1440px;
                margin: auto;
                padding: 0;
                .breadcrumb {
                	padding: 15px 0 0 0;
                }
            }
            .fragrance-top-banner {
                text-align: center;
                img {
                    max-width: 650px;
                    vertical-align: top;
                    @include respond(medium-down){
                        max-width: 205px;
                    }
                }
                .text-live{
                    @include font-regular(28px);
                    color: $secondary-color;
                    margin-top: 20px;
                    @include respond(medium-down){
                        @include font-regular(16px);
                        margin-top: 15px;
                        letter-spacing: 0.05em;
                    }
                }
            }
            .fragrance-list {
    			margin: auto;
                position: relative;
                padding: 0;
                @include respond(desktop) {
                    padding: 0 42px 0 42px;
                }
                @include respond(medium-down) {
                    padding: 32px 42px 0 42px;
                }
                .search-promo {
                    @include respond(medium-down) {
                        padding: 20px 20px 0px 20px;
                    }
                    p {
                        font-size: 1rem;

                    }
                    h2 {

                       font-size: 24px;
                       margin: 4px 0px 20px 0px;
                    }
                }
                &.fr-category {
                	padding: 0;
                	border-bottom: 3px solid #D3D3D3;
	                @include respond(medium-down) {
	                    padding: 20px 0 5px 30px;
	                    margin-right: -20px;
	                    margin-left: -20px;
	                }
	                @include respond(small-down) {
	                	padding: 20px 0 5px 0;
	                }
                	@include respond (small-down-iphone){
						padding-left: 28px;
					}
	                
	                ul {
	                	margin: auto;
	                	padding: 35px 0;
	                	@include respond(medium-down) {
		                    padding: 0;
	                	}
	                	@include respond(desktop) {
		                    padding: 40px 0 50px 0;	
	                	}
					    li {
		                    img {
								@include respond(medium-down) {
									padding-left: 0;
								}
		                    }
					    }
					}
                }
                .subtitle {
					@include font-bold(16px);
					margin: 0;
					position: absolute;
					left: 50%;
					transform: translate(-50%, 0);
					background: $white;
					padding: 0 18px;
					top: -10px;
					@include respond(large-up) {
                        @include font-bold(18px);
						padding: 0 15px;
						top: -11px;
					}
				}
				.note {
					text-align: center;
					@include font-size(12px);
					@include line-height(16px);
					margin: 0 0 22px;
					padding: 0 15px;
					@include respond(large-up) {
                        @include font-size(16px);
					}
				}
				ul {
				    list-style: none;
				    margin: 0;
				    padding: 0;
				    li {
				        list-style: none;
                        img {
							max-width: 100%;
							vertical-align: top;
                        }
				    }
				}
				&.fr-name {
					max-width: 1215px;
				    @include respond(medium-down){
				        margin-bottom: 35px;
				        padding-left: 0;
				        padding-right: 0;
				    }
				    @include respond (small-down){
				    	padding-top: 0;
				    }
				    .filter-overflow-fragrance {
					    @include respond(desktop) {
					    	padding-left: 40px;
					    }
						.noresult{
							text-align: center;
							padding-top: 12px;
							@include font-size(16px);
							line-height: 1.6;
							margin-bottom: 279px;
							color: #333;
							@include respond(desktop) {
								margin-left: -40px;
							}
							@include respond(small-down) {
								margin-bottom: 40px;
								padding-top: 0;
							}
						}
				        .filtering{
							position: relative;
							top: -4px;
							margin-bottom: -4px;
							height: 50px;
							background: $white;
							font-size: 14px;
							padding-bottom: 25px;
							@include respond(medium-up) {
								float: right;
								margin-bottom: 0;
								padding-bottom: 20px;
								max-width: 230px;
								max-height: 36px;
								margin-right: 25px;
							}
							@include respond(desktop) {
								margin-right: 0;
								padding-bottom: 40px;
							}
				            input{
				                background: transparent;
				                background-size: 20px;
				                padding-right: 32px;
				                margin: 0;
				                width: 100%;
								position: relative;
								top: 10px;
								z-index: 11;
								height: 36px;
				                padding-left: 13px;
								@include respond(medium-up) {
									width: 100%;
									height: 100%;
									margin-top: -2px;
									letter-spacing: normal;
								}
				            }
				            &:after {
				                content: '';
				                background: url('../images/svg-icons/UI-Search.svg') no-repeat center;
								height: 20px;
								width: 20px;
				                position: absolute;
				                right: 7px;
    							top: 32px;
								@include respond(medium-up) {
									height: 20px;
									width: 20px;
								}
				            }
				        }
				    }
				    .name-list {
				        @include respond(medium-up) {
				        	display: flex;
						    flex-wrap: wrap;
						    width: 100%;
						    height: auto;
						    margin-top: 42px;
				        }
				        @include respond (small-down) {
				        	padding-top: 18px;
				        }
				        @include respond (desktop) {
				        	max-width: 990px;
						    margin: auto;
						    padding: 0 42px 0 42px;
				        }
				        li {
	                        @include respond (medium-down) {
		                        &:last-child {
	                            	border: none;
	                        	}
	                        }
				        }
				        .level-1 {
				        		width: 100%;
					            flex: 0 1 100%;
					            padding-top: 0;
					            max-width: none;
				            @include respond(medium-up) {
				            	min-height: 125px;
				            	max-width: 184px;
							    height: auto;
					            border-bottom: 2px solid #D3D3D3;
					            min-width: 33%;
					            flex: 0 1 33%;
					            padding-top: 25px;
				            }
				            @include respond(1024px) {
					            padding-left: 25px;
					            padding-right: 25px;
					            min-width: unset;
					            width: 25%;
					            flex: 0 1 25%;
					            padding-top: 15px;
				            }
				            &.first {
								@include respond(desktop) {
									padding-left: 4px;
								}
							}
							&.fourth {
								@include respond(desktop) {
									padding-right: 8px;
								}
							}
							&.last-border {
								@include respond(large-up) {
									border: none;
								}
							}
				            .title {
				                color: #333333;

							    font-size: 21px;
				                padding: 6px 26px;
				                text-transform: uppercase;
				                margin-bottom: 11px;
				                padding: 0;
								margin: 0;
								line-height: 24px;
				                @include respond(large-up) {
				                    color: $secondary-color;
				                    background: transparent;
				                }
				            }
				            .alphabet-border {
				            	border: 1px solid #ED088C;
				            	margin-right: 20px;
				            	margin-bottom: 15px;
				            	margin-top: -5px;
				            	margin-left: 20px;
				            	@include respond(desktop) {
						            margin-right: 0;
						            &:nth-child(4n) {
						            	margin-right: 0;
						            }
				            	}
				            	@include respond(medium-down) {
						            margin-right: 0;
				            	}
				            }
				            ul {
				                margin-bottom: 25px;
				                padding-left: 20px;
				                max-width: 153px;
				                @include respond(large-up) {
				                    margin-bottom: 0;
				                }
				                @include respond(medium-down) {
				                    max-width: none;
				                    margin-bottom: 30px;
				                }
				                li {
				                    margin-bottom: 15px;
				                    @include respond(large-up) {
				                        &:last-child {
				                            margin-bottom: 20px;
				                            @include respond(large-down) {
				                            	margin-bottom: 30px;
				                            }
				                            @include respond(large) {
				                            	margin-bottom: 25px;
				                            }
				                        }
				                    }
				                    a {
				                        display: block;
				                        width: fit-content;
				                        @include font-size(14px);
				                        @include line-height(18px);
				                        color: #666;
				                    }
				                    svg, img.icon {
				                    	margin-bottom: -3px;
				                    	padding-left:3px;
				                    	@include respond(large-up) {
			                            	padding-left: 3px;
			                            }
				                    }
				                }
				            }
				        }
				    }
				    
				}
            }
            .divider {
                @include font-bold(16px);
                text-align: center;
                margin: 11px auto 49px;
                width: 181px;
                position: relative;
                border-top: 1px solid $primary-border;
                @include respond(large-up) {
                    width: 255px;
                    margin: 44px auto 54px;
                }
                span {
                    @include font-bold(16px);
                    text-transform: uppercase;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    padding: 0 19px;
                    background: $white;
                    top: -9px;
                    color: $secondary-color;
                    @include respond(large-up) {
                        @include font-bold(16px);
                        padding: 0 18px;
                        top: -11px;
                    }
                }
            }
        }
    }	
}
.fragrance-top-banner{
	.fragrance-landing-header {
		  max-width: 1440px;
		  color: $night-rider;

		  letter-spacing: 0;
		  font-weight: unset;
		  line-height: 1;
		  text-align: center;
		  border: none;
		  text-transform: uppercase;
		  padding-bottom: 0;
		  font-size: 32px;
		  margin-top: 20px;
		  margin-bottom: 15px;

		  @include respond(large-up) {
		      font-size: 45px;
		      margin-top: 30px;
		      margin-bottom: 8px;
		  }

		  @include respond(desktop) {
				margin-top: 12px;
				margin-bottom: 23px;
		  }
	}
	.fragrance-landing-subheader{
		  max-width: 1440px;
		  color: $night-rider;

		  font-size: 16px;
		  letter-spacing: normal;
		  line-height: 19px;
		  margin: 0;
	}
}
.fragrance-banner-first, .fragrance-banner-second {
	max-width: 1050px;
	margin: auto;

	.fragrance-banner-content-desktop,
	.fragrance-banner-content-tablet,
	.fragrance-banner-content-mobile {
		a {
			display: flex;
			justify-content: center;
		}
	}

	.fragrance-banner-content-desktop {
		img {
			width: 1052px;
		}
	}
	.fragrance-banner-content-tablet {
		img {
			width: 729px;
		}
	}
	.fragrance-banner-content-mobile {
		img {
			width: 290px;
		}
	}
}
.fragrance-category-container {
    display: flex;
    max-width: 840px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include respond(small-down) {
		display: none;
	}
	@include respond(large) {
		max-width: 728px;
	}
    .fragrance-category-section{
	    display:flex;
	    justify-content: center;
	    align-items: center;
	    margin: 10px;
	    position: relative;
	    text-align: center;
	    color: white;
	    box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
	    @include respond(large-down) {
			margin: 5px;
		}
		a {
	    	width: 100%;
	    }
	    img {
			transition: transform .5s ease;
			padding-left: 0;
		}
		&:hover img {
			transform: scale(1.2);
		}
		.centered {
			justify-content: center;
			align-items: center;
			position: absolute;
			display: flex;
			width: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			color: #fff;

			text-align: center;
			text-shadow: 0 2px 4px #000;
			background: rgba(51, 51, 51, 0.25);
			font-size: 40px;
			@media only screen and (min-width: 481px) {
				font-size: 26px;
			}
			@include respond(desktop) {
				font-size: 40px;
			}
		}
	}
	.owl-stage {
		padding-left: 0 !important;
	}
	.owl-stage-outer {
		@include respond(small-down-iphone) {
			margin-left: -15px;
		}
	}
	.owl-dots .owl-dot {
		width: 10px;
		height: 10px;
		border-radius: 10px;
		font-size: unset;
		font-weight: unset;
		background: transparent;
		border-color: #D3D3D3;
		padding: 0;
	}
	.owl-dots .owl-dot.active{
		width: 10px;
		height: 10px;
		border-radius: 10px;
		font-size: unset;
		font-weight: unset;
		background: #D3D3D3;
		border-color: #D3D3D3;
		padding: 0;
	}
}
.fragrance-slot{
	a{
		width: 100%;
	}
	img {
		width: 100%;
	}
}
/* .top-fragrance-header {
	border-top: 2px solid #D3D3D3;
    margin-top: 60px;
	margin-bottom: 45px;
	.top-fr-head-container {
		display: flex;
		justify-content: center;
		align-items: center;
		@include respond(desktop) {
			margin-top: -42px;
		}
		@include respond(medium-down) {
			max-width: 200px;
    		margin: auto;
		}
		img {
			background: white;
    		padding-left: 75px;
    		@include respond(medium-down) {
				left: 30%;
			    position: relative;
			    top: -17px;
			    padding-left: 0;
			}
		}
		h2 {
		  color: $night-rider;

		  font-size: 36px;
		  letter-spacing: normal;
		  text-align: center;
		  background: white;
		  padding-left: 15px;
		  padding-right: 75px;
		  @include respond(medium-down) {
			  line-height: 1;
			  font-size: 26px;
			  padding-left: 0;
			  padding-right: 30px;
		  }
		}
	}
	p {
	  color: $night-rider;

	  font-size: 16px;
	  font-weight: bold;
	  letter-spacing: 0;
	  max-width: 235px;
	  margin-top: 0;
      margin: auto;
	  line-height: 19px;
	  text-align: center;
	}
} */
.all-fragrance-header {
	border-top: 2px solid #D3D3D3;
    margin-top: 40px;
	margin-bottom: 45px;
	@include respond (medium-up) {
		margin-top: 20px;
		margin-bottom: 15px;
	}
	@include respond (small-down) {
		margin-top: 25px;
		margin-bottom: 35px;
		margin-left: -15px;
		margin-right: -15px;
	}
	@include respond (desktop) {
		margin-top: 80px;
	}
	.top-fr-head-container {
		display: flex;
		justify-content: center;
		align-items: center;
		@include respond(desktop) {
			margin-top: -42px;
		}
		h2 {
		  color: $night-rider;

		  font-size: 36px;
		  letter-spacing: normal;
		  text-align: center;
		  background: white;
		  @include respond(large-up) {
			  padding-left: 160px;
		  	  padding-right: 160px;
		  }
		  @include respond(large) {
			  margin-bottom: 25px;
			  margin-top: 30px;
		  }
		  @include respond(medium-down) {
			  line-height: 1;
			  font-size: 26px;
			  margin-top: 27px;
			  margin-bottom: 13px;
		  }
		}
	}
	p {
	  color: $night-rider;

	  font-size: 16px;
	  font-weight: bold;
	  letter-spacing: 0;
	  margin-top: 0;
	  line-height: 19px;
	  text-align: center;
	  @include respond(small-down) {
		  max-width: 280px;
		  margin: auto;
	  }
	  @include respond(large) {
		  margin-bottom: 5px;
	  }
	}
	.top-fr-head {
		display: flex;
		justify-content: center;
		align-items: center;
		@include respond (medium-down){
			width: 295px;
    		padding-top: 10px;
    		margin: auto;
		}
		svg, img.icon {
			padding-left: 5px;
    		padding-right: 7px;
		}
		.top-fr-subheader {
			font-size: 14px;
		}
	}
}

.fragrance-primary {
	margin: auto;
	@include respond(desktop) {
		padding: 5px 80px;
	}
	.search-result-options {
        h1, .ds-subcategory-heading {
            @include ds-heading(h2);
            border-bottom: 1px solid $secondary-border;
            padding-bottom: 16px;           
            margin: 0 0 17px;
			@media only screen and (max-width: 900px) {
				margin-top: 5px;
			}
        }
    }
	.content-container {
		margin-bottom: 20px;
		@include respond (medium-down){
    		margin-bottom: 18px;
    	}
		
		.fragrance-A-banner {
			position: relative;
			box-sizing: border-box;
			width: 100%;
			padding: 15px;
			background: #F2F2F2;
			min-height: 200px;
		
			@include respond (large-up) {
				min-height: 335px;
			}
		
			img {
				width: 100%;
				height: 100%;
			}
		
			.slot-text-one {
				color: #333333;

				font-size: 12px;
				letter-spacing: 0;
				text-align: center;
				line-height: 14px;
				margin: 0;
		
				@include respond(large-up) {
					font-size: 18px;
					line-height: 23px;
					height: 19px;
					margin-top: 5px;
				}
			}
		
			.slot-text-two {
				color: #333333;
				font-size: 50px;
				letter-spacing: 0;
				text-align: center;
				margin: 10px 0;
				line-height: 40px;
		
				@include respond(large-up) {
					font-size: 147px;
					line-height: 110px;
					margin: 20px 0 29px 0;
				}
			}
		
			.slot-text-three {
				color: #333333;

				font-size: 12px;
				letter-spacing: 0;
				text-align: center;
				line-height: 15px;
				margin: 0 0 40px 0;
				
				@include respond(large-up) {
					font-size: 18px;
					line-height: 26px;
					padding: 3px 0;
					margin: 0 0 43px 0;
				}
			}
		
			.shop-now-cta {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
		
				@include respond(large-up) {
					margin-bottom: 6px;
				}
		
				.shop-now-copy {
					color: #333333;

					font-size: 12px;
					letter-spacing: 0;
					line-height: 12px;
					text-align: center;
					margin-bottom: 2px;
				}
		
				.shop-now-icon {
					box-sizing: border-box;
					height: 27px;
					width: 27px;
					border: 1.5px solid #666666;
					background-color: #FFFFFF;
					border-radius: 50%;
					position: relative;
		
					svg, img.icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
		
					&:hover {
						cursor: pointer;
						background-color: #666666;
		
						svg, img.icon {
		
							g,
							path {
								fill: #ffffff;
							}
						}
					}
				}
			}
		}

		.fragrance-B-banner {
		    position: relative;
			width: 100%;
			padding: 15px;
		    background: #F2F2F2;
			height: 243px;
			box-sizing: border-box;

		    @include respond (medium-down) {
		        height: 129px;
		    }

		    img {
		        width: 100%;
		        height: 100%;
		    }

		    .slot-text-one {
		        color: #333333;

				font-size: 12px;
				line-height: 14px;
		        letter-spacing: 0;
				text-align: center;
				margin: 0;

		        @include respond(large-up) {
					font-size: 18px;
					margin-top: 5px;
					height: 19px;
		        }
		    }

		    .slot-text-two {
		        color: #333333;
				font-size: 50px;
				line-height: 40px;
				margin: 10px 0;
		        letter-spacing: 0;
		        text-align: center;

		        @include respond(large-up) {
					font-size: 147px;
					margin: 20px 0 29px 0;
					line-height: 110px;
		        }
		    }

		    .slot-text-three {
		        color: #333333;

		        font-size: 12px;
		        letter-spacing: 0;
				text-align: center;
				line-height: 15px;
				margin: 0 0 10px 0;

		        @include respond(large-up) {
					font-size: 18px;
					margin-bottom: 5px;
					line-height: 26px;
		        }
		    }
		}
		.fragrance-second-banner {
			height: 321px;
			flex-direction: row;
		    display: flex;
		    margin-top: 10px;
		    background: #F2F2F2;
		    height: 321px;
		    align-items: center; 
		    @include respond(medium-down) {
	    	  	flex-direction: column;
	    	  	height: fit-content;
	    	  	padding: 20px;
	    	}
		    .fragrance-optional-image {
		    	width: 50%;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    padding-right: 25px;
			    @include respond(medium-down) {
	    	  		width: 100%;
	    	  		padding: 0;
	    	  	}
	    	  	@include respond(large-up) {
	    	  		justify-content: flex-end;
	    	  	}
	    	  	.image-container {
					display: flex;
	    	  		width: 400px;
	    	  		@include respond(large-up) {
						justify-content: flex-end;
					}
					@include respond(medium-down) {
						width: 290px;
					}
					.desktop-only {
						@include respond(medium-down) {
							display: none;
						}
					}
					.mobile-only {
						@include respond(large-up) {
							display: none;
						}
					}
	    	  	}
			    img {
			    	width: 90%;
			    	@include respond(desktop) {
						width: 100%;
					}
					@include respond(medium-down) {
						width: 100%;
					}
			    }
			}
			.optional-head-container {
				position: relative;
				height: 260px;
				width: 48%;
				@include respond (large-up) {
					max-width: 375px;
				}
				@include respond(medium-down) {
					display: block;
					max-width: 290px;
					width: 100%;
    				height: fit-content;
				}
				.optional-header {
					  margin: 0;
					  color: #333333;

					  font-size: 20px;
					  letter-spacing: 1px;
					  @include respond (medium-down){
					  	padding-top: 20px;
					  }
				}
				.second-copy-text {
	    			  color: #333333;

					  font-size: 18px;
					  letter-spacing: 0;
					  @include respond (medium-down){
						  margin: 9px 0 0 0;
						  font-size: 12px;
						  line-height: 15px;
					  }
					  @include respond (medium){
					  	text-align: center;
					  }
				}
			}
		}
	}
	#results-products {
	@include respond (medium-down) {
			border-bottom: 1px solid; 
		}
	}
	.fragrance-copy-container {
		border-top: 1px solid;
		padding: 30px;
		.fragrance-seo-content{
		    max-width: 762px;
		}
		.header-copy {
		  color: #333333;

		  font-size: 24px;
		  font-weight: 300;
		  letter-spacing: 0;
		}
		.copy-block {
		  color: #666666;

		  font-size: 14px;
		  font-weight: 300;
		  letter-spacing: 0;
		  line-height: 16px;
		}
	}
}
