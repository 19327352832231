#wrapper.pt_ingredients {
    .breadcrumb {
        .breadcrumb-element {
            font-weight: 300;


        }

        @include respond(medium-down) {
            padding-left: 15px;
        }
    }

    #main {
        @include respond(medium-down) {
            padding: 0 15px;
        }

        .ingredients-search {
            max-width: 464px;
            margin: 0 auto;
            position: relative;

            @include respond(medium-down) {
                padding: 0 4px;
            }

            &>fieldset {
                position: relative;
            }

            &.results {
                button[type="reset"] {
                    display: block;
                    height: 14px;
                    bottom: 7px;
                }

                button[type="submit"] {
                    display: block;
                    opacity: 1;
                }
            }

            button {
                background-color: transparent;
                border: none;
                color: $secondary-color;
                padding: 0;
                width: auto;
                min-width: 38px;
                height: 100%;
                top:0;
                position: absolute;
                right: 0;
                cursor: pointer !important;
                bottom: 5px;
                opacity: 1; 

                .icon-search {
                    width: 21px;
                    height: 21px;
                    fill: $brand-black;
                    padding-top: 3px;
                }

                .icon-close {
                    width: 14px;
                    height: 14px;
                    fill: $brand-black;
                    display: block;

                    &:hover {
                        fill: $brand-blue;
                    }

                    &:active {
                        fill: $click;
                    }
                }

                &[type="reset"] {
                    display: none;
                }

                &[type="submit"] {
                    display: inline-block;
                }

                @include respond(medium-down) {
                    .icon-search {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            input[type="search"] {
                background-color: $white;
                border: 0;
                border-bottom: 1px solid $night-rider;
                color: $black;
                float: left;
                line-height: normal;
                margin: 0;
                padding: 0 40px 0 0;
                width: 100%;
                @include transition(none);
                -webkit-appearance: none;
                vertical-align: middle;

                @include font-size(16px);
                font-weight: 300;
                letter-spacing: 0.2px;
                @include line-height(17px);

                @include respond(medium-down) {
                    width: 100%;
                }

                height: 1.75rem;

                &::placeholder {
                    opacity: 1;
                    color: $dim-gray;
                }
                &::-webkit-input-placeholder {
                    color: $dim-gray;
                }
                &:-moz-placeholder {
                    color: $dim-gray;
                    opacity: 1;
                }
                &::-moz-placeholder {
                    color: $dim-gray;
                    opacity: 1;
                }
                &:-ms-input-placeholder {
                    color: $dim-gray;
                }
            }
        }

        .slot-top {
            max-width: 704px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;

            @include respond(medium-down) {
                margin-bottom: 25px;
            }

            h1 {
                color: $brand-blue;
                text-transform: uppercase;

                @include font-size(24px);
                @include line-height(21px);
                text-align: center;
                margin-top: 25px;
                margin-bottom: 10px;

                @include respond(medium-down) {
                    @include font-size(18px);
                    text-align: left;
                    margin-top: 15px;
                    margin-bottom: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 470px;
                }
            }

            p {
                color: $dim-gray;

                @include font-size(14px);
                @include line-height(18px);
                font-weight: 300;
                text-align: center;
                max-width: 470px;
                margin: 0 auto;

                @include respond(medium-down) {
                    text-align: left;
                }
            }
        }

        .slot-bottom {
            margin-top: 257px;
            margin-bottom: 10px;

            &.results {
                margin-top: 139px;
            }

            @include respond(medium-down) {
                margin-top: 158px;
                margin-bottom: 94px;
                max-width: 267px;
                margin-left: auto;
                margin-right: auto;

                &.results {
                    margin-top: 126px;
                }
            }

            p {
                color: $dim-gray;

                @include font-size(14px);
                @include line-height(21px);
                font-weight: 300;
                text-align: center;
                max-width: 428px;
                margin: 0 auto;

                @include respond(medium-down) {
                    @include font-size(12px);
                }
            }
        }

        .search-nohits {
            &__text {
                text-align: center;
                color: $night-rider;
                margin: rem(8px) auto;
            }

            &__text--bold {
                @include font-size(16px);
                @include line-height(21px);
                max-width: rem(289px);
                margin-top: rem(7px);


                @include respond(large-up) {
                    max-width: rem(479px);
                }
            }

            &__text--light {
                color: $dim-gray;
                @include font-size(14px);
                @include line-height(18px);
                max-width: rem(269px);

                opacity: .96;

                @include respond(large-up) {
                    max-width: rem(445px);
                }
            }
        }

        .search-success {
            @include font-size(16px);
            color: $night-rider;

            .result-query {

            }
        }
    }

    .ingredients-search-results {
        max-width: 700px;
        padding: 16px;
        margin: 3.25em auto 0 auto;
        color: $night-rider;

        @include respond(medium-down) {
            padding: 0;
            margin-top: 2.75em;
        }
    }

    .results-content-header {
        float: left;
        text-transform: none;
        margin: 0;
        font-weight: 300;

        @include respond(medium-down) {
            float: none;
            margin-bottom: 14px;
        }

        .result-query {

        }
    }

    .articles-hits {
        float: right;
        text-transform: uppercase;
        line-height: 14px;
        margin-top: 4px;

        @include respond(medium-down) {
            float: none;
            display: block;
        }
    }

    .ingredients-h1 {
        padding-bottom: 11px;
        border-bottom: 1px solid $night-rider;
        margin-bottom: 14px;

        @include respond(medium-down) {
            text-align: center;
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 16px;
        }
    }

    .item-list {
        border: 1px solid $border-ingredient;
        border-radius: 3px;
        margin-bottom: 0;
    }

    .item-ingredient {
        background: $bg-ingredient;

        .accordion-header {
            padding-left: 15px;
            padding-right: 15px;
            margin: 0;
            background: white;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $bg-content;

            &.active {
                border-width: 2px;
            }
        }

        .ingredient-name {
            color: $night-rider;

            @include font-size(16px);
            @include line-height(19px);
            letter-spacing: 0.25px;
            margin-bottom: 5px;
        }

        .ingredient-type {
            color: $dim-gray;

            @include font-size(14px);
            @include line-height(17px);
            letter-spacing: 0.25px;
            font-weight: 300;
            margin-bottom: 5px;
        }

        .ingredient-upc {
            color: $dim-gray;

            @include font-size(14px);
            @include line-height(17px);
            letter-spacing: 0.21px;
            font-weight: 300;
        }

        .ingredient-iln {
            color: $night-rider;

            @include font-size(14px);
            @include line-height(17px);
            margin-bottom: 15px;
        }

        .ingredient-ingredients {
            color: $night-rider;

            @include font-size(14px);
            @include line-height(22px);
            font-weight: 300;
            margin-bottom: 13px;
        }

        .ingredient-links {
            a {
                color: $brand-blue;

                @include font-size(14px);
                @include line-height(18px);
                font-weight: 300;
                display: inline-block;
                margin-bottom: 20px;
                float: left;

                @include respond(medium-down) {
                    margin-bottom: 16px;
                }
            }

            .icon-external-link {
                width: 14px;
                height: 18px;
                vertical-align: top;
                margin-left: 3px;
            }

            li {
                height: 38px;
                line-height: 18px;
                
                @include respond(medium-down) {
                    height: 36px;
                }

                &:last-child {
                    height: 33px;

                    @include respond(medium-down) {
                        height: 28px;
                    }

                    a {
                        margin-bottom: 13px;

                        @include respond(medium-down) {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        .ui-icon {
            position: absolute;
            width: 19px;
            height: 22px;
            top: 50%;
            right: 30px;
            left: auto;
            margin-top: -11px;
            @include transition(all 0.5s ease-out);
            background-size: 20px;
            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;

            @include respond(medium-down) {
                right: 17px;
            }
        }

        .ui-icon.iconOpen {
            transform: rotate(180deg);
        }

        .iln-text {
            letter-spacing: 0.5px;
            float: left;
            margin-right: 8px;
        }

        .divider {
            width: 100%;
            border-top: 1px solid $gainsboro;
            margin-top: 13px;
            margin-bottom: 29px;
        }
    }

    .accordion-header {
        padding: 15px 0 12px 0;

        .header-content {
            list-style: none;
        }
    }

    .accordion-body {
        background-color: $bg-ingredient;
        overflow: visible;
    }

    .accordion-body-padding {
        margin: 15px;
        border: 1px solid $border-ingredient2;
        padding: 15px;
        background-color: $white;
        list-style: none;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &:active {
                color: $click;
                text-decoration: none !important;
            }
        }
    }

    .category-header {
        border-bottom: 1px solid $night-rider;
        background-color: $white-smoke;
        padding: 14px 15px;

        h3 {
            color: $night-rider;

            @include font-size(14px);
            @include line-height(17px);
            letter-spacing: 0.54px;
            display: inline-block;
            margin: 0;
        }

        a {
            color: $brand-blue;

            @include font-size(14px);
            @include line-height(17px);
            font-weight: 300;
            float: right;
            display: inline-block;
            text-decoration: underline;
            margin-top: -3px;
            cursor: pointer;

            &.accordion-collapse-all {
                display: none;
            }
        }
    }

    .what-is-this {
        color: $brand-blue;
    }

    .ingredient-links {
        list-style-type: none;
    }

    .pagination {
        margin-top: 15px;
        float: none;

        ul {
            text-align: center;
            float: none;

            li {
                display: inline-block;
                float: none;
                padding: 1.5px 2px;

                >a {
                    padding: 5px 8px;
                }

                &.current-page {
                    width: 27px;
                    padding: 0 0 1.5px 0;

                    @include respond(medium-down) {
                        margin-top: 0;
                    }
                }

                &.first-last {
                    padding: 1.5px 2px;

                    >a {
                        display: inline-block;
                        height: auto;
                    }

                    .pagination-hand {
                        top: 7px;
                        height: 12px;
                    }
                }
            }
        }
    }

    .highlight {
        background-color: #FFF7A6;

        color: $night-rider;
        text-transform: capitalize;
    }
}

/** 'Whats an ILN?' dialog */
.dialog-ingredients-iln {
    text-align: center;

    .ui-dialog-titlebar {

        /** make close button clear and properly centered **/
        button {
            background-color: $white;
            height: 3.5em;
            width: 3.5em;
            right: 0;

            .ui-icon-closethick {
                border: none;
                width: 100%;
                height: 100%;
            }
        }

        .ui-dialog-title {
            display: none;
        }
    }

    h1 {
        color: $brand-blue;
        text-transform: uppercase;

        @include font-size(18px);
        @include line-height(21px);
        margin-top: 2em;
        margin-bottom: .9em;

        @include respond(medium-down) {
            margin-top: 2.25em;
        }

        @include respond(small-down) {
            margin-top: 2.5em;
        }
    }

    p {
        @include line-height(18px);
        margin-left: 1.5em;
        margin-right: 1.5em;
    }

    #dialog-iln {
        margin-bottom: 2em;

        @include respond(medium-down) {
            margin-bottom: 3.5em;
        }

        @include respond(small-down) {
            margin-bottom: 3.9em;
        }
    }
}