.eventdetails {
    margin: 0 auto;
    text-align: center;
    a.details-pop-up {
        color: #333;
        text-decoration: underline;
        padding: 8px 0;
        display: block;
        margin-bottom: -38px;
    }
}
@media screen and (max-width: 768px) {
    .eventdetails a.details-pop-up {
        padding: 0;
        margin-bottom: -22px;
    }
}
.topoffers .eventdetails a.details-pop-up,
.eventheader .eventdetails a.details-pop-up {
    padding: 0;
    margin-bottom: -22px;
}
.pdp-main .eventdetails {
    padding-bottom: 5%;
}
.home-grid-two .eventdetails {
    margin-top: -55px;
    margin-bottom: 55px;
}
@media screen and (max-width: 768px) {
    .eventheader .eventdetails a.details-pop-up {
        margin-bottom: -15px;
    }
    .home-grid-two .eventdetails {
        margin-top: -25px;
        margin-bottom: 35px;
    }
}
