.breadcrumb {
        margin: 0 auto;
        padding: calculateRem(8px) calculateRem(20px) calculateRem(27px);
        max-width: $max-width;
    li {
        display: inline;
        list-style: none outside none;
    }
}

.paging-current-page {
    left: -999em;
    position: absolute;
}

.breadcrumb-element {
    @include ds-ui-regular(m);
    color: $text-secondary;
    text-transform: capitalize;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
    &:active{
        text-decoration: none;
    }
    &:after {
        content: '/';
        padding: 0 8px 0 13px;
        display: inline-block;
    }
    &.resultstext,
    &.breadcrumb-result-text {
        text-decoration: none;
        a {
            cursor: text;
            pointer-events: none;
        }
    }
}
@include respond(medium-down) {
    .breadcrumb > * {
        margin-bottom: 4px;
    }
}
.breadcrumb > *:last-child {
    &.breadcrumb-element,
    .breadcrumb-element {
        color: $text-primary;
        text-decoration: none;
        cursor: text;
        pointer-events: none;
    }
    &:after,
    .breadcrumb-element:after {
        content: '';
    }
}

.breadcrumb-refinement {
    &:after {
        content: attr(data-divider);
        font-style: normal;
    }
    &:last-of-type {
        &:after {
            content: '';
            padding: 0;
            display: none;
        }
    }
}

.breadcrumb-refinement-value {
    background-color: rgba($citrus, 0.3);
    display: inline-block;
    border-radius: 3px;
    margin: 0 .42em .42em 0;
    padding: 0 .42em;
}

.breadcrumb-relax {
    background: none;
    color: $sangria;
    font-style: normal;
    padding: 0 0 0 .4em;
}
