
.store-locator-header {
    /*margin: 0 0 20px;*/
    padding: 0 15px;
    position: relative;
    h1 {
        @include ds-heading(h2);
        color: $text-primary;
        margin: 32px 0 8px;
        
        @media only screen and (min-width: $lMin) {
            padding-bottom: 16px;
            margin-bottom: 32px;
        }
    }
    @include respond(large-up){
        padding: 22px 0 4px;
    }
    .right{
        position: absolute;
        right: 0;
        bottom: 7px;
        @include respond(large-up){
            bottom: 53px;
        }
        @include respond(small-down){
            bottom: 15px;
        }
        .logos-main{
            width: 183px;
            height: 18px;
            float: right;
            margin-right: 20px;
            @include respond(medium-down){
                float: right;
                width: 156px;
                height: 16px;
                position: relative;
                top: 5px;
                margin-right: 10px;
                padding-right: 10px;
            }
            @include respond(small-down){
                position: relative;
                top: 5px;
                float: left;
            }
            &.powered-google {
                width: 96px;
                height: 12px;
                margin-bottom: .25em;
                margin-right: 0;
                @include respond(medium-down) {
                    padding-right: 15px;
                }
                @include respond(medium) {
                    margin-bottom: 1em;
                }
                @include respond(small-down) {
                    margin-bottom: .5em;
                }
            }
        }
        .logos-whitebarn{
            width: 123px;
            height: 31px;
            float: right;
            position: relative;
            top: -12px;
            left: -6px;
            @include respond(medium-down){
                width: 113px;
                height: 28px;
                top: -5px;
            }
            @include respond(small-down){
                float: none;
                top: 3px;
            }
        }
    }
}
.store-information {
    background: none repeat scroll 0 0 $trans;
    border-bottom: 1px solid $border-color;
}

.store-name {
    margin: 0 0 10px;
}

.store-map {
    border-bottom: 1px solid $border-color;
}

.store-locator-details {
    float: left;
    width: 44%;
    img {
        max-width: 100%;
    }
    h1,
    p {
        margin: 0.25em 0;
    }
}

.store-locator-details-map {
    float: right;
    width: 50%;
    img {
        max-width: 100%;
    }
}

#store-locator {
    position: relative;
}

.map-canvas-container {
    position: relative;
    #map-canvas, #map-canvas-overlay {
        display: block;
        padding: 0;
        height: 255px;
        /*visibility: visible;*/
        
        @include respond(large-up) {
            float: right;
            width: 58%;
            height: 560px;
        }
        @include respond(medium-down) {
            margin-top: 24px;
            &.hidden{
                top: -3000px;
            }
        }
    
    }
    
    #map-canvas-overlay {
        position: absolute;
        top: 0;
        right: 0;
        background: $white;
        z-index: 2;
        box-sizing: border-box;
        p {
            display: none;
            max-width: 224px;
            padding: 0 15px;
            color: $red;
            text-align: center;
            margin: 268px auto 0 auto;
            font-size: 14px;
            line-height: 24px;
            
        }
        @include respond(medium-down) {
            top: -24px;
            width: 100%;
            border: 3px solid #f2f2f2;
            p {
                margin-top: 104px;
            }
            
        }
    }
}

.search-bar {
    border-bottom: 3px solid $bg-color;
    padding: 0 15px;
    @include borderbox;
    @include respond(large-up){
        float: left;
        width: 39.5%;
        min-height: 560px;
        border: 3px solid $bg-color;
        padding: 18px 15px 0 45px;
    }

    .section-header{
        @include ds-heading(h3);
        color: $text-primary;
        text-transform: capitalize;
        padding: 32px 0 4px 0;
        margin: 0 0 15px 0;
        border-bottom: 1px solid $bg-secondary;
        
        @media only screen and (min-width: $lMin) {
            margin-right: 30px;
        }
    }
    .refine-search{
        h2 {
            @include ds-heading(h4);
            color: $text-primary;
            text-transform: capitalize;
            padding: 10px 0 4px 0;
            margin: 0 30px 28px 0;
            border-bottom: 1px solid $bg-secondary;
            
            @media only screen and (min-width: $lMin) {
                padding-bottom: 12px;
            }
        }
        .form-row {
            label {
                @include ds-ui-regular(m);
                color: $text-secondary;
            }
            @include respond(medium-down){
                margin: 13px 0 27px 8px;
            }
        }
        @include respond(medium-down){
            margin-bottom: 50px;
        }
    }
    .form-row{
        margin: 10px 0 27px;
        text-align: left;
        position: relative;
        
        @media only screen and (min-width: $lMin) {
            width: 90%;
        }
        #address-error{
            position: absolute;
            left: -10px;
            top: 55px;
        }
        label {
            @include ds-ui-bold(l);
            color: $text-secondary;
            margin-bottom: 3px;
            
            @media only screen and (min-width: $lMin) {
                @include ds-ui-bold(m);
                margin-bottom: 5px;
            }
        }
        button{
            @include ds-utilities-button;
            max-height: 48px;
            line-height: 48px;
            width: 100%;
        }
        .zero-result{
            display: none;
        }
        &.form-row-button{
            padding-top: 2px;
            @include respond(large-up){
                padding-top: 5px;
            }
        }
    }
}

.mob-nav{
    padding: 25px 15px;
    @include respond(medium-down){
        padding: 25px 15px 20px;
    }
    .button, .button:active {
        float: right;
        margin-left: 10px;
        padding-left: 30px;
        padding-right: 30px;
        background: $bg-color !important;
        color: $secondary-color !important;
        border-color: $bg-color !important;
        &.active{
            background: $secondary-color !important;
            border-color: $secondary-color !important;
            color: $white !important;
        }
    }
    .list-view, .map-view {
        @media only screen and (max-width: $mMax) {
            @include ds-utilities-button-labels;
            max-height: 48px;
            line-height: 48px;
            width: auto;
        }
    }
    .map-view{
        color: $text-primary;
    }
    .stores-header{
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
        clear: both;
        display: block;
    }
    &.mobile-only{
        padding-bottom: 0;
        .stores-header{
            .stores-header{
                @include ds-heading(h3);
                color: $text-primary;
                border-bottom: 1px solid $bg-secondary;
                padding-bottom: 4px;
                margin-bottom: 0;
            }
        }
    }
}
.stores-wrapper {
    @include respond(large-up){
        padding-top: 22px;
    }

}
.stores-header {
    @include ds-heading(h3);
    color: $text-primary;
    border-bottom: 1px solid $bg-color;
    padding: 15px 0 16px;
    text-transform: capitalize;
    
    @media only screen and (max-width: $mMax) {
        display: none;
    }
}
.stores-container{
    @include respond(medium-down){
        margin-top: -285px;
    }
    @include respond(large-up){
        margin-top: 0px !important;
    }
}
#stores {
    width: 100%;
    @include respond(medium-down){
        margin-top: 25px;
    }
    .stores-container {
        .store {
            position: relative;
            margin: 10px 0;
            background-color: $bg-color;
            transition: background-color 1000ms linear, max-height .5s ease-in-out;
            max-height: 110px;
            overflow: hidden;
            @include respond(large-up){
                margin: 20px 0;
                padding: 0;
                max-height: 119px !important;
            }
            &.active,  &.open {
                 max-height: 580px !important;
                 background-color: $white;
                 @include respond(large-up){
                     .headinfo {
                        .distance {
                            opacity: 0;
                        }
                    }
                }
                .headinfo:after {
                    background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
                    transform: rotate(180deg);
                }
            }
            &.empty{
                background: $bg-color;
                @include font-size(18px);
                position: relative;
                letter-spacing: 0.7px;
                padding: 25px 0 25px 40px;
                color: $secondary-color;
                @include respond(large-up){
                    top: -20px;
                    padding: 46px 0 50px 40px;
                }
            }
            .headinfo {
                margin: 0;
                cursor: pointer;
                padding: 26px 10px 33px;
                @include respond(large-up){
                    padding: 49px 28px 50px 38px;
                    margin-bottom: 20px;
                }
                &:after {
                    content: "";
                    float: right;
                    position: absolute;
                    width:40px;
                    height:22px;
                    @include transition(all 0.5s ease-out);
                    background-size: 20px;
                    background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
                    top: 47px;
                    right: 3.5%;
                    @include respond(medium-down) {
                        width: 33px;
                        height: 20px;
                    }
                }
                @extend %clearfix;
                .distance {
                    opacity: 1;
                    transition: opacity .5s ease-in-out;
                }
                .storename{
                    float: left;
                    @include ds-heading(h3);
                    width: 85%;
                    height: 40px;
                    @include respond(large-up){
                        width: auto;
                    }
                    a{
                        color: $secondary-color;
                        padding-left: 15px;
                        display: block;
                        @include respond(large-up){
                            display: inline;
                        }

                        .primaryName {
                            @include ds-heading(h4);
                            color: $text-primary;
                            text-transform: capitalize;
                        }
                    }
                    > span{
                        float: left;
                        width: 15px;
                        display: block;
                        @include respond(large-up){
                            width: auto;
                        }
                    }
                }
            }
            .distance{
                @include ds-ui-bold(m);
                color: $text-primary;
                margin-left: 30px;
                text-transform: none;
                clear: both;
                @include respond(large-up){
                    float: left;
                    padding-top: 2px !important;
                    clear: none;
                }
                span{
                    @include ds-ui-regular(m);
                    padding-right: 5px;
                }
            }
            .content-distance {
                @include respond(large-up){
                    float: right;
                    width: 30%;
                    margin-left: 0;
                    margin-right: 100px;
                    text-align: right;
                }
                 @include respond(medium-down){
                    display: none;
                 }
            }
            .bodyinfo{
                margin-top: 0px;
                margin-bottom: 20px;
                padding-left: 40px;
                @include ds-ui-regular(m);
                color: $text-secondary;
                @include respond(large-up){
                    padding-left: 70px;
                    margin-bottom: 60px;
                }
                .phone{
                    margin-top: 10px;
                }
                .directions{
                    margin: 16px 0 38px;
                    a{
                        @include ds-ui-semi(m);
                        text-decoration: underline;
                        color: $text-primary;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    @include respond(large-up){
                        width: 38%;
                    }
                }
                .events{
                    @include ds-ui-regular(m);
                    color: $text-feedback-info;
                    margin-bottom: 16px;
                }
                .hours{
                    margin-bottom: 10px;
                    text-transform: lowercase;
                    .days{
                        @include ds-ui-regular(m);
                        color: $text-secondary;
                        padding: 2px 0;
                        list-style: none;
                        &:first-letter {
                            text-transform:capitalize;
                        }
                        .time{
                            @include ds-ui-semi(m);
                            color: $text-primary;
                            margin-left: 5px;
                        }
                        .exthours{
                            color: $cobalt;
                            padding-left: 7px;
                            @include font-size(10px);
                            text-transform: uppercase;
                        }
                    }
                    @include respond(large-up){
                        width: auto;
                        margin-top: 0;
                        margin-bottom: 0;
                        position: absolute;
                        left: 41.5%;
                        top: 117px;
                    }
                    p{
                        margin: 0;
                    }
                }
                .address1 {
                    @include respond(large-up) {
                        width: 38%;
                    }
                }
            }
        }
    }
}

/* Fixes the layering of the marker icon text */
.gmnoprint[title] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.markerLabel{
    @include ds-ui-regular(s);
    color: $text-primary;
    width: 23px;
    margin-left: -12px !important;
    text-align: center !important;
}

.gm-style-iw{
    @include respond(medium-down){
        min-width: 220px;
        padding-left: 0px;
        
        > div{
            overflow: hidden !important;
        }
    }
}

.fixarrow{
    box-shadow: none !important;
    height: 10px !important;
    top: 4px !important;

}
.mapContent{
    margin: 15px 20px;
    @include respond(medium-down) {
        margin: 8px 20px;
    }

    h1{
        @include ds-ui-bold(s);
        color: $text-primary;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 40px;
        padding: 0 0 5px 0;
        margin: 0;
        border: none;
    }
    .contentBody {
        @include ds-ui-regular(s);
        color: $text-secondary;
        .phone {
            margin-top: 15px;
        }
        p {
            a {
                @include ds-ui-semi(s);
                color: $text-primary;
            }
        }
    }
}
.store-locator-slot{
    @include respond(medium-down){
        text-align: center;
        padding: 0 15px;
    }
}

div {
    @include respond(medium-down) {
        max-width: none;
    }
}