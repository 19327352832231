.pt_storefront {
    .ds-announcement-banner-global + #main {
        .bopisHomePage {
            @media screen and (max-width: $mMin) {
                margin-bottom: 48px;
            }
        }
    }

    .ds-announcement-banner-global {
        @media screen and (min-width: $xlMin) {
            margin-bottom: 56px;
        }
    }
}

.ds-announcement-banner {
    background: $alice-blue;
    color: $text-feedback-info;
    padding: 12px 0;
    position: relative;

    .ds-background-link {
        display: none;

        &[href] {
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .ds-content-link {
        color: $text-feedback-info;
        display: block;
        margin: 0 16px;
    }

    .ds-announcement-heading,
    .ds-announcement-subheading {
        position: relative;
    }

    .ds-announcement-heading {
        @include ds-ui-semi(m);
    }

    .ds-announcement-subheading,
    .ds-popover {
        @include ds-ui-regular(m);
    }

    .ds-dialog__button {
        background: none;
        padding: 0 16px;
        color: inherit;
    }

    .ds-simpletooltip {
        background: $white;
    }

    .ds-dialog__button {
        @include ds-ui-regular(m);
    }

    .ds-simpletooltip_container {
        color: #1876a7;
        margin: 0;
    }
}

.ds-announcement-banner {
    @media screen and (min-width: $xlMin) {
        padding: 12px 16px;
        display: flex;
        align-items: baseline;
        justify-content: center;

        .ds-announcement-heading,
        .ds-announcement-subheading {
            display: inline-block;
            padding: 0 8px 0 0;

            &:last-child {
                padding-right: 0;
            }
        }

        .ds-content-link {
            margin: 0;
            display: inline-block;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;

                .ds-announcement-heading,
                .ds-announcement-subheading,
                .ds-popover {
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        .ds-dialog__button {
            padding: 0;
        }
    }
}