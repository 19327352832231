.ds-subs {
    .ds-grid-contents {
        display: grid;
        row-gap: 32px;
    }
    
    .ds-paragraph {
        color: var(--customColor, $primary-color);
    }

    img {
        width: 100%;
    }

    @media screen and (min-width: $lMin) {
        .ds-grid-contents {
            grid-template-columns: 1fr 1fr;
            column-gap: 32px;
        }
    }
    
    @media screen and (min-width: $mMin) {
        .ds-grid-contents {
            row-gap: 40px;
        }
    }
}
