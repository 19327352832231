.content-asset {
    .account-options {
        #join-rewards {
            .info {
                box-sizing: border-box;
                height: 94px;
                @media only screen and (max-width: 767px) {
                    height: 174px;
                }
                @media only screen and (max-width: 1023px) {
                    height: 94px;
                    width: calc(100% - 94.95px);
                }
                & > img {
                    @media only screen and (max-width: 1024px) and (min-width: 768px) {
                        width: 94.95px;
                    }
                }
                p {
                    @media only screen and (max-width: 1023px) {

                        font-size: 11px;
                    }
                }
            }
            h3 > img {
                height: 30px;
                width: 177px;
                @media only screen and (max-width: 1023px) {
                    height: 25px;
                    width: 163px;
                }
            }
        }
    }
}
