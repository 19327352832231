.quickview {
    .owl-carousel {
        display: block;
        img {
            max-width: 90%;
        }
        .owl-nav span {
            transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1) ease-in-out;
            background: rgba(256,256,256,.75) url(../images/svg-icons/UI-Carat.svg) no-repeat 50% !important;
            background-size: 12px;
            border: 0;
            border-radius: 11px;
            color: transparent;
            overflow: hidden;
            width: 38px;
            height: 26px;
            padding: 0;
            &:hover,
            &:focus,
            &:active {
                color: transparent !important;
                border-color: transparent !important;
                background-color: rgba(256,256,256,.75) !important;
            }
        }
        .owl-prev span {
            transform: rotate(90deg);
            margin-left: 30px;
        }
        .owl-next span {
            transform: rotate(-90deg);
            margin-right: 30px;
        }
    }
    .ui-dialog-title{
        display: none;
    }
    .ui-dialog-content {
        padding-bottom: 30px;
        padding-top: 15px;

        .product-col-1 {
            width: 44%;
            .product-primary-image{
                padding: 0;
            }
            .product-image {
                max-width: 100%;
            }
        }
        .product-col-2 {
            padding: 0;
            width: 53%;
            margin-top: calculateRem(21px);
            .small-title {
                @include font-size(12px);
            }
            .product-name{
                width: 100%;
                @include font-size(18px);
                margin: 0;
                & + .small-title {
                    @include font-size(13px);
                    padding-bottom: 0; 
                }
            }
            #product-content {
                .small-title {
                    @include font-size(13px);
                    padding-bottom: 9px;
                    @include respond(large-up) {
                        padding-bottom: 20px;
                    }
                }
                .bv-cv2-cleanslate {
                    .bv-primarySummary-rating-container {
                        .bv-write-review{
                            display: none!important;
                        }
                        .bv-popup-target {
                            z-index: -1!important;
                            text-decoration: none!important;
                        }
                    }
                }
                .product-price {
                    margin-bottom: 15px;
                }
                .promotion {
                    margin-bottom: 20px;
                    p {
                        line-height: 1.4;
                    }
                }
                .availability-web {
                    margin-top: 1px;
                    padding-bottom: 0;
                }
                .productpromo {
                    margin: 8px 0 0;
                }
            }
        }
        .pdp-main {
            .product-col-1 {
                margin-top: calculateRem(21px);
            }
            .product-set-list {
                border: 1px solid $gainsboro;
                height: 500px;
                overflow: auto;
                .product-set-item {
                    margin: 1em 0;
                }
                .product-set-image {
                    width: 25%;
                }
                .product-set-details {
                    width: 75%;
                }
                .product-name {
                    margin: 0;
                }
                .block-add-to-cart .price {
                    font-size: 1.5em;
                    padding: 0.4em 0 0;
                    width: 130px;
                }
            }
            .wish-list-link {
                width: 32px;
				height: 32px;
				top: 16px;
				right: 13px;
            }
            button.wish-list-link {
				border:none;
            }
            .product-thumbnails{
                display: none;
            }
            .product-actions{
                display: none;
            }
            .description{
                @include font-size(14px);
                @include line-height(24px);
                padding: 5px 0 17px;
            }
            .pdp-ingredients {
                margin: 12px 0 31px;
                a {
                    margin-left: 0;
                }
            }
            .details-view{
                text-decoration: underline;
                margin-top: 14px;
                @include font-size(14px);
                display: block;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .wish-list-guest-message, .wish-list-added{
            top: 46px;
        }
    }
    #thumbnails{
        width: calc(100% - 30px);
    }
    button#ratings-summary[aria-label*="0.0"] {
        cursor: default; 
        pointer-events: none; 
    }
}
.slide-cont{
	#thumbnails{
        button{
            background-color: transparent;
            border:none;
            line-height: unset;
            max-height: unset;
            padding: 0;
            width: 100%;
        }
		.owl-stage{
			.owl-item{
				button{
					background-color: transparent;
					border:none;
					line-height: unset;
					max-height: unset;
					padding: 0;
					width: 100%;
				}
			}
		}
	}
}