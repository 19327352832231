.search-result-options {
    &.top .ds-subcategory-heading {
       
        +.slot-grid-header {
            margin-bottom: 0;
        }
    }
    
    &.top h1 {

        + .slot-grid-header {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .search-result-title.hidden {
        display: block !important;
        opacity: 1 !important;
        position: relative;
        top: 3px;
    }
}

.grid-page-video {
    position: relative;
    .video-wrap {
        left: 50%;
        position: absolute;
        width: 71.234%;
        top: 17.6%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            left: 49.411%;
            top: 50%;
            transform: translate(0, -50%);
            width: 44.537%;
        }
    }
}