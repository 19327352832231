//-----------------------------------------------
//  EXTENSIBLE CLASSES
//
//  ex: %my-class - renders a "class" the is only
//		meant to be extended (will not render in
//		compiled .css files.
//
//  ex: @extend %my-class;
//
//  Best practices when using the @extend directive in Sass:
//  Make sure the extended selector is present only once throughout the site.
//  Avoid extending from nested selectors.
//  Avoid chaining @extend directives.
//  Don’t try extending from within a media query; it doesn’t work.
//-----------------------------------------------

// Clearfix
//---------------------------------------

// can apply directly in SCSS nested within another mixin if nec, such as respond()
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// can extend directly in SCSS
%clearfix {
    @include clearfix;
}

// can apply directly in ISML as a class
.clearfix {
    @extend %clearfix;
}

// Headings
//---------------------------------------

// can apply directly in SCSS
@mixin h1 {
    @include font-regular(21px);
    @include line-height(21px);
    color: $secondary-color;
    margin: calculateRem(20px) 0;
    font-weight: normal;
    @include respond(large-up){
        @include font-size(24px);
    }
}

@mixin h2 {
    @include font-regular(16px);
    @include line-height(18px);
    color: $secondary-color;
}

@mixin h3 {
    @include font-regular(14px);
    @include line-height(16px);
    color: $secondary-color;
    @include respond(large-up){
        @include font-size(13px);
        @include line-height(18px);
    }
}

@mixin h4 {
    @include font-bold(12px);
    @include line-height(14px);
    margin: calculateRem(6px) 0;
    color: $secondary-color;
    @include respond(large-up){
        @include font-bold(14px);
        @include line-height(16px);
    }

}
@mixin transform($transformation){
    transform: $transformation; /* W3C */
    -webkit-transform: $transformation; /* Safari and Chrome */
    -moz-transform: $transformation; /* Firefox */
    -ms-transform: $transformation; /* IE 9 */
    -o-transform: $transformation; /* Opera */
}

@mixin transition($transition) {
    transition: $transition;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
}

@mixin borderRadius($value){
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    border-radius: $value;
}
@mixin borderbox{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin background-image($name, $size:false, $position:false){
    background: url(../images/svg-icons/#{$name}.svg) no-repeat;
    @if($size){
        background-size: $size;
    }
    @if($position){
        background-position: $position;
    }
    .no-svg &{
        background-image: url(../images/#{$name}.png});
    }
}
/*
	usage of mixin above
	@include background-image('name', 31px 31px, left center);
	.no-svg{
		background: url(../images/name.png) no-repeat left center;
	}
*/
@mixin svg-fill($name, $color, $size:false, $position:false){
    background: none;
    -webkit-mask: url(../images/svg-icons/#{$name}.svg) no-repeat;
    mask: url(../images/svg-icons/#{$name}.svg) no-repeat;
    background-color: $color;
    @if($size){
        -webkit-mask-size: $size;
        mask-size: $size;
    }
    @if($position){
        -webkit-mask-position: $position;
        mask-position: $position;
    }
}
// can apply directly in ISML as a class
.h1 {
    @include h1;
}

.h2 {
    @include h2;
}

.h3 {
    @include h3;
}

.h4 {
    @include h4;
}

// Secondary paragraph text (smaller)
//---------------------------------------

// can apply directly in SCSS
@mixin alt-p {
    @include font-bold(14px);
    @include line-height(21px);
    color: $secondary-color;
}

// can apply directly in ISML as a class
.alt-p {
    @include alt-p;
}

// Informational Text (smallest)
//---------------------------------------

// can apply directly in SCSS nested within another mixin, like respond()
@mixin info {
    @include font-regular(11px);
    @include line-height(16px);
    color: $primary-color;
}

// can apply directly in ISML as a class
.info {
    @include info;
}

// Visually Hidden Elements
//---------------------------------------

// can apply directly in SCSS nested within another mixin, like respond()
@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// can extend directly in SCSS
%visually-hidden {
    @include visually-hidden;
}

// can apply directly in ISML as a class
.visually-hidden {
    @include visually-hidden;
}

// Menu - Base Styles
//---------------------------------------

// can extend directly in SCSS
%menu {
    float: left;
    margin: 0;
    padding: 0;
    li {
        float: left;
        list-style: none outside none !important;
    }
}

// can apply directly in SCSS nested within another mixin, like respond()
@mixin menu {
    @extend %menu;
}

// can apply directly in ISML as a class
.menu {
    @extend %menu;
}

// Full-Bleed (using VW's)
//---------------------------------------

// can apply directly in SCSS nested within another mixin, like respond()
@mixin full-bleed-width {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc(0px - (100vw - 100%) /2);
    // just in case
    @media only screen and (max-width: $small-breakpoint - 1) {
        width: 100%;
        margin-left: 0;
    }
}
%button-wide{
    width: 100%;
    @include font-size(13px);
    @include borderbox;
}

// can extend directly in SCSS
%full-bleed-width {
    @include full-bleed-width;
}

// can apply directly in ISML as a class
.full-bleed-width {
    @include full-bleed-width;
}

%desktop-only {
    @include respond(medium-down) {
        display: none !important;
    }
}
%desktop-tablet {
    @include respond(small) {
        display: none;
    }
}

%tablet-only {
    @include respond(desktop) {
        display: none;
    }

    @include respond(small) {
        display: none;
    }
}

%tablet-mobile {
    @include respond(desktop) {
        display: none;
    }
}

%mobile-only {
    @include respond(large-up) {
        display: none !important;
    }
}
.desktop-only{
    @extend %desktop-only;
}
.mobile-only{
    @extend %mobile-only;
}
.nooverflow{
    overflow: hidden;
}
/**
 * These values will not show up in content, but can be
 * queried by JavaScript to know which breakpoint is active.
 * Add or remove as many breakpoints as you like.
 */
body:before {
    content: "small";
    display: none; /* Prevent from displaying. */
}
@media (min-width: 480px) {
    body:before {
        content: "meduim";
    }
}
@media (min-width: 768px) {
    body:before {
        content: "large";
    }
}
@media (min-width: 1024px) {
    body:before {
        content: "desktop";
    }
}

.display-none {
    display: none !important;
}