//-----------------------------
// Form Components
//-----------------------------

button,
.button,
.buttonstyle,
input[type="button"] {
    @include font-regular-extend(12px);
    cursor: pointer;
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    @include line-height(33px);
    color: $white;
    display: inline-block;
    padding: 0 calculateEm(17px);
    text-align: center;
    transition: all 0.3s ease;
    max-height: 35px;
    letter-spacing: 0.12em;
    border-radius: 0;
    @include respond(medium-up){
        @include font-regular-extend(11px);
        padding: 0 calculateEm(20px);
    }
    body:not(.touch) &:not([disabled]):not(.disabled) &:not(.link-style) {
        &:hover {
	        color: $night-rider;
	        border-color: $white-smoke;
	        background-color: $white-smoke;
        }
        &:active {
	        color: $night-rider;
	        border-color: $white-smoke;
	        background-color: $white;
        }
    }
    &:active {
        color: $night-rider;
        border-color: $white-smoke;
        background-color: $white;
    }
    &[disabled],
    &.disabled {
        cursor: default;
        @include opacity(0.5);
    }
    a {
        color: $white;
        &:hover:not([disabled]):not(.disabled) {
            color: $white;
        }
    }
    i {
        &.left {
            margin-right: 0.6em;
        }
        &.right {
            margin-left: 0.6em;
        }
    }
    &.secondary {
        @include font-bold(14px);
        @include line-height(35px);
        background-color: $white;
        border: 3px solid $secondary-color;
        color: $secondary-color;
        padding: 0 calculateEm(25px);
        max-height: 41px;
        a {
            color: $citrus;
            &:hover:not([disabled]):not(.disabled) {
                color: $white;
            }
        }
        body:not(.touch) & {
	        &:hover:not([disabled]):not(.disabled) {
	            color: $white;
	            border-color: $night-rider;
	            background-color: $night-rider;
	        }
	        &:active:not([disabled]):not(.disabled) {
	            color: $white;
	            background-color: $secondary-color;
	            border-color: $secondary-color;
            }
        }
        &:active:not([disabled]):not(.disabled) {
            color: $white;
            background-color: $secondary-color;
            border-color: $secondary-color;
        }
    }
    &.cancel-button {
		background: $white;
		color: $secondary-color;
		border-color: $primary-border;
		body:not(.touch) & {
			&:hover {
				background: $secondary-color;
				color: $white;
				border-color: $secondary-border;
			}
			&:active {
	            background: $white;
	            color: $secondary-color;
	            border-color: $primary-border;
            }
        }
		&:active {
			background: $white;
			color: $secondary-color;
			border-color: $primary-border;
		}
	}
    &.hide-show {
        align-items: center;
        background: transparent;
        border: none;
        color: #333333;
        display: flex;
        min-height: 46px;
        padding: 0;
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 5;
        letter-spacing: normal;
        text-transform: capitalize;

        &:hover {
            background-color: $bg-secondary;
            cursor: pointer;
        }
        &:active {
            color: #c2c2c2;
            cursor: pointer;
        }

        > span {
            @include font-size(16px);
            border-left: 1px solid $grey-40;
            line-height: 24px;
            padding: 0 12px;
        }
    }
    &.link-style {
        background: none;
        border: none;
        color: $grey-70;
        display: block;
        padding: 10px 0 10px 0;
        text-transform: none;

        @include ds-ui-regular(s);

        &:hover {
            background-color: none;
            color: $brand-blue;
        }
    }

    &.global-header-sign-in {
        color: $text-primary;
        @include ds-ui-regular(s);
        padding: 0px;
        text-decoration: underline;
    }
}

a.button.fullwidth {
	@include respond(small) {
    	width: 92%;
    }
}

.fake-button {
    @include font-bold(12px);
    @include line-height(34px);
    background-color: $white;
    border: 2px solid $secondary-color;
    color: $secondary-color;
    padding: 0 calculateRem(13px);
    max-height: 35px;
    height: 35px;
    display: inline-block;
    letter-spacing: 0.999px;
    text-transform: uppercase;
    text-decoration: none;
    box-sizing: border-box;
    @include respond(large-up) {
        @include line-height(32px);
    }
    body:not(.touch) & {
	    &:hover {
	        color: $white;
	        border-color: $night-rider;
	        background-color: $night-rider;
	    }
    }
    &:active {
        color: $dim-gray;
        border-color: $dim-gray;
        background-color: $white;
    }
}

.button-text {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    color: $secondary-color;
    background-color: transparent;
    border-width: 0;
    padding: 0;
    text-decoration: none;
    letter-spacing: 0;
    text-transform: none;
    body:not(.touch) & {
        &:hover {
	        background-color: transparent;
	        text-decoration: underline;
        }
    }
    &:hover {
        background-color: transparent;
        text-decoration: underline;
    }
}

.button-full-width {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
}

legend {
    @include font-regular(14px);
    margin: 0;
    padding: calculateRem(25px) 0;
    position: relative;
    text-transform: uppercase;
    width: 96%;
    .ie7 & {
        width: 94%;
    }
    span {
        @include font-size(12px);
        position: relative;
        text-transform: none;
        padding: 0 .5em;
        em {
            color: $nero;
        }
    }
}

.dialog-required {
    @include ds-ui-regular(s);
    display: inline;
    position: relative;
    text-transform: none;
    float: right;
    span {
        color: transparent;
        &:before {
            content: "*";
            color: $text-feedback-danger;
            position: relative;
            left: 5px;
        }

        em {
            color: $text-primary;
            text-transform: capitalize;
        }
    }
}

label {
    display: block;
    @include font-regular(14px);
    //@include line-height(20px);
    line-height: 1;
    margin-bottom: calculateRem(20px);
    color: $secondary-color;
    text-align: left;
    .form-horizontal & {
        float: none;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto calculateEm(20px);
        @include respond(large-up) {
            width: 60%;
            padding-left: 2%;
        }
    }
    .label-inline &, .field-wrapper & {
        @include font-size(14px);
        @include line-height(16px);
        display: inline;
        float: none;
        margin-top: 0;
        text-align: left;
        width: auto;
    }
    .label-above & {
        margin-bottom: .3rem;
        width: auto;
    }
    .why-link {
        display: none;
        margin-left: 10px;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
        &:active {
            color: $click;
        }
    }
}

.form-label-text {
    @include font-bold;
    @include font-size(13px);
    float: left;
    padding: 0.75em 0 0;
    text-align: right;
    width: 25%;
}

.form-row {
    @extend %clearfix;
    margin: calculateRem(10px) 0 calculateRem(30px);
    text-align: center;
    &.required{
        label {
            position: relative;
            left: -10px;
        }
    }
    &.checkbox, &.radio{
        text-align: left;
    }
}

.form-row-button {
    clear: left;
    button {
        @include respond(large-up) {
            padding: 0 calculateEm(45px);
        }
    }

    .form-horizontal & {
        @include respond(large-up) {
            margin-left: 21%;
            width: 63%;
        }
    }
}

.field-wrapper {
    box-sizing: border-box;
    //margin-bottom: .5rem;
    width: 100%;
    .form-horizontal & {
        float: none;
        width: 100%;
        @include respond(large-up) {
            padding-left: 2%;
            width: 60%;
            margin: auto;
        }
    }
    .label-inline & {
        float: left;
        padding-left: 0;
        width: auto;
    }
    .label-above & {
        float: none;
        padding-left: 0;
        width: auto;
    }
    &.show-hide-field-wrapper {
        position: relative;
    }
}

input[type="search"],
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
textarea {
    box-sizing: border-box;
    height: calculateRem(48px);
    @include ds-ui-regular(l);
    color: $text-primary;
    padding: calculateRem(5px) calculateRem(11px);
    /*change from 12 to 10px because letter "y or q" was not showing full text **/
    border: 1px solid $grey-60;
    text-indent: 0;
    width: 100%;
    -webkit-appearance: none;
    @include borderRadius(0);
}

input[type="number"] {
	-moz-appearance:textfield;
     &::-webkit-inner-spin-button,
     &::-webkit-outer-spin-button {
       -webkit-appearance: none;
         margin: 0;
     }
}
input::-ms-clear {
    display: none;
}

.header-search {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus {
        border: 1px solid $grey-60;
        -webkit-text-fill-color: $secondary-color;
        -webkit-box-shadow: 0 0 0px 1000px $white inset;
        /*transition: background-color 50s ease-in-out 0s;*/
    }
}

textarea {
    box-sizing: border-box;
    max-width: 100%;
    height: calculateRem(90px);
    letter-spacing: 0.25px;
}

select {
    width: 100%;
    height: calculateRem(35px);
    border: 1px solid $grey-60;
    @include ds-ui-regular(l);
    color: $text-primary;
    background-color: #fff;
    // set background color to make height work
    .search-result-options & {
        height: auto;
    }
}

select::-ms-expand {
    display: none;
}
/* ------ Select Box Styles: danielneumann.com Method (customized to iRobot) ------ */
/* ------ Source: http://danielneumann.com/blog/how-to-style-dropdown-with-css-only/ ------ */
.select-style {
    width: 100%;
    height: 50px;
    border: 1px solid $grey-60;
    //background: $white url("../images/svg-icons/UI-Carat.svg") no-repeat scroll calc(100% - 10px) center;
    //background-size: 15px;
    position: relative;
    box-sizing: border-box;
    select {
        border: 0;
        background: $trans;
        padding: 0 calculateRem(36px) 0 calculateRem(10px);
        //width: calc(100% - 35px);
        height: 48px;
        width: 100%;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
    }
    label {
        display: none;
    }
    &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 35px;
        border-left: 1px solid $grey-60;
        background: $white url("../images/svg-icons/UI-Carat.svg") no-repeat center;
        background-size: 15px;
    }
    &:hover{
        background: $bg-color;
        border: none;

        select{
            top: 1px;
            left: 1px;
            padding-right: calculateRem(38px);
        }

        &:after{
            border-left: 1px solid $white;
            background-color: $bg-color;
            background-position: center;
            right: 1px;
            height: 100%;
        }
        span.error{
            left: 1px;
            margin-bottom: 31px;
        }
    }
    &.focused{
        &:after{
            transform: rotate(180deg);
            border-right: 1px solid $grey-60;
            border-left: none;
        }
    }
    span.error{
        top: 50px;
        left: 0;
        margin-bottom: 30px;
    }
}

input[type="checkbox"],
input[type="radio"] {
    & + label {
        padding: 0 calculateEm(10px);
    }
}

.form-caption {
    clear: left;
    width: auto;
    font-size: inherit;
    text-align: left;
    .checkbox & {
        @include font-size(12px);
        text-indent: calculateRem(32px);
    }
    .form-horizontal & {
        @include respond(large-up) {
            margin-left: 15px;
            @include font-size(12px);
        }
    }
    .label-above & {
        margin-left: 0;
    }
    .form-indent & {
        margin-left: 0;
    }
}

.form-indent {
    @include respond(large-up) {
        margin-left: 27%;
    }
}

.error-message {
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 49.5966%;
}
.password-reset-form {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;

    p, a {
        font-size: 16px;
        color: $text-primary;
    }

    a {
        text-align: center;
        margin-top: 1em;
    }

	.error-message {
	    padding: 0.7em 0.5em;
	    text-indent: 0;
	    width: 100%;
	}

    fieldset {
        display: flex;
        flex-direction: column;

        &:not(.modal){
            @include respond(medium-up){
                display: block;
                width: max-content;

                .form-row {
                    margin-top: 0;
                    display: inline-block;

                    .field-wrapper {
                        display: flex;
                        flex-direction: column;
                    }

                    .input-text {
                        width: 290px;
                        float: left;
                    }

                    label {
                        margin-bottom: 1.2em;
                    }
                }

                button {
                    display: inline-block;
                    float: right;
                    margin-top: 1.94em;
                    margin-left: 0.5em;
                    width: max-content;
                }
            }
        }
    }
}

.error, input.error, input.pw-error {
    border-color: $sangria;
}

span.error,
div.error,
span.misMatch, span.foundMatch, span.isavilable, span.pwreq-error {
    @include ds-ui-regular(s);
    color: $text-feedback-danger;
    background: none;
    padding: 0 0 calculateRem(5px) calculateRem(10px);
    position: relative;
    top: calculateRem(5px);
    float: left;
    text-align: left;

    a{
    	text-decoration: underline;
    }
}
.form-row.error{
    float: none;
    padding: 0;
}

.error-form,
.blue-error-message {
    background-color: $medium-spring-green;
    color: $red;
    @include font-bold(14px);
    margin: 5px 0;
    text-align: left;
    position: relative;
    img.icon{
        width: 28px;
        height: 28px;
        float: left;
        position: absolute;
        left: 10px;
        top: calc(50% - 14px);
    }
    ul {
        padding: 8px 20px 8px 20px;
    }
    li {
        color: $red;
        @include font-bold(14px);
        line-height: 1.3em;
        padding-left: 10px;
    }
    a {
        text-decoration: underline;
        color: $red;
        @include font-bold(14px);
    }
}
.error-form {
    padding: 8px 20px 8px 44px;
}

.status-msg {
	text-align: center;
    .success {
        color: $egg-blue;
        display: inline-block;
        @include font-bold(14px);
        &:before {
            content: '';
            display: inline-block;
            float: left;
            width: 20px;
            height: 20px;
            margin: -2px 0 0 -20px;
            background: url("../images/svg-icons/Alert-Check.svg") no-repeat center;
        }
    }
    .error {
        padding: 0;
		top: auto;
		float: none;
		@include font-bold(14px);
    }
}

.form-inline {
    form {
        padding: 0 0 2em;
        width: 98%;
    }
    label {
        padding: 0 0 0.75em;
        text-transform: uppercase;
        width: auto;
    }
    input[type="text"], select {
        margin-left: 0;
        width: 95%;
    }
    button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.row-inline {
    display: inline;
    label {
        width: auto;
    }
}

.require-dialog {
    position: absolute;
    right: 1em;
    text-transform: none;
    top: 1em;
}

.required-indicator {
    margin-right: calculateEm(2px);
    float: left;
    color: $red;
}

.form-field-tooltip {
    @include font-size(12px);
    float: left;
    width: 25%;
    margin-left: 3%;
    padding-top: .75em;
    line-height: 1em;
    text-decoration: underline;
}

.js .simple-submit {
    display: none;
}
.char-count{
    float: right;
}
.maskpassword .field-wrapper {
	position: relative;
}
.privacy-policy {
	text-decoration: underline;
	color: $secondary-color;
	font-size: .875rem;
	&:hover {
		text-decoration: none;
	}
	&:active {
		text-decoration: none !important;
		color: $click !important;
	}
    @include respond(medium-down) {
      text-align: center;
    }
	&.privacy-cta-inline {
        @include ds-ui-regular(m);
        color: $text-secondary;

	    @include respond(large-up) {
	        margin-left: 20px;
	    }
	    @include respond(medium-down) {
            display: block;
			width: calc(100% - 10px);
			margin: 15px 0;
			padding: 5px;
			text-align: center;
        }
	}
	&.gift-card-privacy-cta {
		display: block;

	    @include respond(large-up) {
	        margin-top: 30px;
	    }
	    @include respond(medium-down) {
			width: 100%;
			margin: 20px 0 15px 0;
			padding: 5px;
			text-align: center;
        }
	}
}

div.privacy-cta-block {
    margin: 1.25em 0 1em 0;
    width: 100%;
    text-align: center;

    a {
	    padding: 5px;
	}

	@include respond(medium-down) {
	    margin: .75em 0 0 0;
		a {
			display: block;
			width: auto;
			margin: 15px 0;
			padding: 5px;
		}
	}

   &.password-reset {
		margin-top: 0;
		a {
			margin-top: 0;
		}
   }
}
