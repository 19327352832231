body:not(.touch) button.ds-js-simple-tooltip:not([disabled]):not(.disabled) {
    &:hover,
    &:active {
        color: inherit;
        border-color: white;
        background-color: transparent;
    }
}

.ds-js-simple-tooltip {
    @include ds-ui-semi(s);
    background: white;
    border: none;
    color: #333;
    text-align: center;
    text-decoration: underline;
    text-transform: none;
}

.ds-js-simple-tooltip:active {
    outline: #000 dotted 1px;
    outline-offset: 2px;
}

.ds-js-simple-tooltip:hover,
.ds-js-simple-tooltip:focus {
    text-decoration: none;
    cursor: default;
}

/* tooltip styles */
.ds-simpletooltip {
    @include ds-body(s);
    position: absolute;
    bottom: 0;
    background-color: white;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    text-align: left;
    width: 232px;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.15);
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 4px 8px 4px 8px;
    margin: 24px 0px;
    color: #333;
    display: block;
    z-index: 102;
}

.ds-tooltip-button:hover::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 16px;
    bottom: 100%;
}

.ds-simpletooltip-below {
    .ds-simpletooltip {
        bottom: auto;
        top: 0;
    }
    .ds-tooltip-button:hover::before {
        bottom: auto;
        top: 100%;
    }
}

.ds-simpletooltip[aria-hidden="true"] {
    display: none;
}

.ds-simpletooltip_container {
    color: #333;
    position: relative;
    display: inline-block;
    margin-top: 16px;
}

/*text inside tooltip*/
span.ds-simpletooltip {
    @include ds-body(s);
    left: 50%;
    text-decoration: none;
    transform: translateX(-50%);
    b,
    strong {
        font-weight: bold;
    }
    p {
        @include ds-body(s);
        color: #333;
        margin: 0;
    }
    a {
        @include ds-body(s);
    }
}

.ds-close-popover {
    align-self: start;
    background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L14 14' stroke='%23333333' stroke-width='2'/%3E%3Cpath d='M2 14L14 2' stroke='%23333333' stroke-width='2'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    float: right;
    height: 40px;
    width: 40px;
    &:hover {
        background-color: transparent;
    }
}

body:not(.touch) .ds-close-popover:not([disabled]):not(.disabled):hover {
    background-color: transparent;
}

.ds-popover-heading {
    align-items: center;
    border-bottom: 1px solid $gainsboro;
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
}

.ds-popover-heading-content {
    @include ds-heading(h4);
    margin: 0;
    text-transform: none;
}

.ds-popover {
    .ds-simpletooltip {
        @include ds-body(m);
        padding: 0;

        p,
        a {
            @include ds-body(m);
        }

        b {
            @include ds-body(m);
            font-weight: 700;
        }

        strong {
            @include ds-body(m);
            font-weight: 600;
        }
    }

    .ds-tooltip-content {
        padding: 16px;
    }
}

.ds-headerless {
    .ds-simpletooltip {
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
        width: 325px;

        &[aria-hidden="true"] {
            display: none;
        }
    }
    .ds-popover-heading {
        border-bottom: none;
        padding: 0 0 0 8px;
    }
    .ds-close-popover {
        height: 48px;
        max-height: 48px;
        max-width: 48px;
        width: 48px;
    }
    .ds-tooltip-content {
        padding: 16px 0 16px 16px;
    }
}

.ingredient-links {
    .ds-popover {
        display: inline-block;
    }
    .ds-simpletooltip_container {
        margin-top: 0;
    }
    .ds-tooltip-button {
        color: $text-brand-primary;
        padding: 0 8px;
    }

    .ds-tooltip-accordion-button {
        color: $text-feedback-info;
        font-weight: 700;
        padding: 0 8px;
    }
}

.ds-tooltip-container.ds-iroll {
    line-height: 0;
    margin-bottom: 8px;
    margin-left: 16px;
    text-align: right;

    &.ds-right-edge .ds-simpletooltip {
        transform: translateX(-90%);
        left: 0;
    }

    &.ds-left-edge .ds-simpletooltip {
        transform: translateX(0%);
        left: 0;
    }

    &.ds-center .ds-simpletooltip {
        transform: translateX(-50%);
    }

    .ds-simpletooltip_container {
        margin-top: 8px;
    }

    .ds-tooltip-button {
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' /%3E%3Crect width='32' height='32' /%3E%3Cpath d='M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z' fill='%23333333'/%3E%3Cpath d='M17.1667 9.00004C17.1667 9.64437 16.6444 10.1667 16 10.1667C15.3557 10.1667 14.8334 9.64437 14.8334 9.00004C14.8334 8.35571 15.3557 7.83337 16 7.83337C16.6444 7.83337 17.1667 8.35571 17.1667 9.00004ZM15.125 13.375L15.125 22.125C15.125 22.6083 15.5168 23 16 23C16.4833 23 16.875 22.6083 16.875 22.125L16.875 13.375C16.875 12.8918 16.4833 12.5 16 12.5C15.5168 12.5 15.125 12.8918 15.125 13.375Z' fill='white'/%3E%3C/svg%3E");
        content: "";
        display: block;
        background-repeat: no-repeat;
        height: 32px;
        width: 32px;
        padding: 0;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' /%3E%3Cpath d='M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z' fill='%23E5E5E5'/%3E%3Cpath d='M17.1667 9.00004C17.1667 9.64437 16.6444 10.1667 16 10.1667C15.3557 10.1667 14.8334 9.64437 14.8334 9.00004C14.8334 8.35571 15.3557 7.83337 16 7.83337C16.6444 7.83337 17.1667 8.35571 17.1667 9.00004ZM15.125 13.375L15.125 22.125C15.125 22.6083 15.5168 23 16 23C16.4833 23 16.875 22.6083 16.875 22.125L16.875 13.375C16.875 12.8918 16.4833 12.5 16 12.5C15.5168 12.5 15.125 12.8918 15.125 13.375Z' fill='%23333333'/%3E%3C/svg%3E");
        }

        &:active,
        &:focus {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='31' height='31' rx='15.5' fill='white'/%3E%3Cpath d='M17.1667 9C17.1667 9.64433 16.6443 10.1667 16 10.1667C15.3557 10.1667 14.8333 9.64433 14.8333 9C14.8333 8.35567 15.3557 7.83333 16 7.83333C16.6443 7.83333 17.1667 8.35567 17.1667 9ZM15.125 13.375L15.125 22.125C15.125 22.6082 15.5167 23 16 23C16.4832 23 16.875 22.6082 16.875 22.125L16.875 13.375C16.875 12.8917 16.4832 12.5 16 12.5C15.5167 12.5 15.125 12.8918 15.125 13.375ZM2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM16 3.75C9.23451 3.75 3.75 9.23451 3.75 16C3.75 22.7655 9.23451 28.25 16 28.25C22.7655 28.25 28.25 22.7655 28.25 16C28.25 9.23451 22.7655 3.75 16 3.75Z' fill='%23333333'/%3E%3Crect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='%23333333' stroke-dasharray='1 1'/%3E%3C/svg%3E");
            outline: none !important;
        }
    }

    .ds-simpletooltip {
        transform: translateX(-90%);
        left: 30%;
        margin: 40px 0;

        @media screen and (min-width: $mMax) {
            transform: translate(-120px, 0);
        }
    }
}

.field-wrapper {
    z-index: 101;
    .ds-tooltip-container.ds-iroll {
        margin: 0;
        position: absolute;
        right: 3%;
        top: 50%;
        transform: translate(0, -50%);
        .ds-tooltip-button {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM11.9964 10.4996C12.3761 10.4994 12.6901 10.7813 12.74 11.1473L12.7469 11.2491L12.7505 16.7507C12.7508 17.1649 12.4152 17.5009 12.001 17.5012C11.6213 17.5014 11.3073 17.2195 11.2574 16.8535L11.2505 16.7517L11.2469 11.2501C11.2467 10.8359 11.5822 10.4999 11.9964 10.4996ZM12.0005 7.00184C12.5521 7.00184 12.9992 7.44896 12.9992 8.0005C12.9992 8.55205 12.5521 8.99917 12.0005 8.99917C11.449 8.99917 11.0019 8.55205 11.0019 8.0005C11.0019 7.44896 11.449 7.00184 12.0005 7.00184Z' fill='%23333333'/%3E%3C/svg%3E");
            content: "";
            display: block;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            padding: 0;
            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='8' fill='%23333333'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM11.9964 10.4996C12.3761 10.4994 12.6901 10.7813 12.74 11.1473L12.7469 11.2491L12.7505 16.7507C12.7508 17.1649 12.4152 17.5009 12.001 17.5012C11.6213 17.5014 11.3073 17.2195 11.2574 16.8535L11.2505 16.7517L11.2469 11.2501C11.2467 10.8359 11.5822 10.4999 11.9964 10.4996ZM12.0005 7.00184C12.5521 7.00184 12.9992 7.44896 12.9992 8.0005C12.9992 8.55205 12.5521 8.99917 12.0005 8.99917C11.449 8.99917 11.0019 8.55205 11.0019 8.0005C11.0019 7.44896 11.449 7.00184 12.0005 7.00184Z' fill='%23666666'/%3E%3C/svg%3E");
            }
            &:active,
            &:focus {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='white'/%3E%3Cpath d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM12.0001 3.49902C7.30481 3.49902 3.49854 7.3053 3.49854 12.0006C3.49854 16.6959 7.30481 20.5021 12.0001 20.5021C16.6954 20.5021 20.5016 16.6959 20.5016 12.0006C20.5016 7.3053 16.6954 3.49902 12.0001 3.49902ZM11.9964 10.4996C12.3761 10.4994 12.6901 10.7813 12.74 11.1473L12.7469 11.2491L12.7505 16.7507C12.7508 17.1649 12.4152 17.5009 12.001 17.5012C11.6213 17.5014 11.3073 17.2195 11.2574 16.8535L11.2505 16.7517L11.2469 11.2501C11.2467 10.8359 11.5822 10.4999 11.9964 10.4996ZM12.0005 7.00184C12.5521 7.00184 12.9992 7.44896 12.9992 8.0005C12.9992 8.55205 12.5521 8.99917 12.0005 8.99917C11.449 8.99917 11.0019 8.55205 11.0019 8.0005C11.0019 7.44896 11.449 7.00184 12.0005 7.00184Z' fill='%23333333'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='%23333333' stroke-dasharray='1 1'/%3E%3C/svg%3E");
                outline: none !important;
            }
        }
        .ds-simpletooltip_container {
            margin: 0;
        }
    }
}

.ds-grid-content-tile, .ds-user-info-heading {
    .ds-tooltip-container.ds-iroll {
        margin-bottom: 0;
        margin-left: 8px;

        &:not(.ds-left-edge):not(.ds-right-edge) {
            .ds-simpletooltip {
                transform: translateX(-50%);
                left: 50%;
            }
        }

        .ds-simpletooltip {
            margin: 0 0 32px;
        }

        .ds-simpletooltip_container {
            margin-top: 0;
        }

        .ds-tooltip-button {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='white'/%3E%3Cpath d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM12.0001 3.49902C7.30481 3.49902 3.49854 7.3053 3.49854 12.0006C3.49854 16.6959 7.30481 20.5021 12.0001 20.5021C16.6954 20.5021 20.5016 16.6959 20.5016 12.0006C20.5016 7.3053 16.6954 3.49902 12.0001 3.49902ZM11.9964 10.4996C12.3761 10.4994 12.6901 10.7813 12.74 11.1473L12.7469 11.2491L12.7505 16.7507C12.7508 17.1649 12.4152 17.5009 12.001 17.5012C11.6213 17.5014 11.3073 17.2195 11.2574 16.8535L11.2505 16.7517L11.2469 11.2501C11.2467 10.8359 11.5822 10.4999 11.9964 10.4996ZM12.0005 7.00184C12.5521 7.00184 12.9992 7.44896 12.9992 8.0005C12.9992 8.55205 12.5521 8.99917 12.0005 8.99917C11.449 8.99917 11.0019 8.55205 11.0019 8.0005C11.0019 7.44896 11.449 7.00184 12.0005 7.00184Z' fill='%231A1A1A'/%3E%3C/svg%3E");
            content: "";
            display: block;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            padding: 0;

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='white'/%3E%3Cpath d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM12.0001 3.49902C7.30481 3.49902 3.49854 7.3053 3.49854 12.0006C3.49854 16.6959 7.30481 20.5021 12.0001 20.5021C16.6954 20.5021 20.5016 16.6959 20.5016 12.0006C20.5016 7.3053 16.6954 3.49902 12.0001 3.49902ZM11.9964 10.4996C12.3761 10.4994 12.6901 10.7813 12.74 11.1473L12.7469 11.2491L12.7505 16.7507C12.7508 17.1649 12.4152 17.5009 12.001 17.5012C11.6213 17.5014 11.3073 17.2195 11.2574 16.8535L11.2505 16.7517L11.2469 11.2501C11.2467 10.8359 11.5822 10.4999 11.9964 10.4996ZM12.0005 7.00184C12.5521 7.00184 12.9992 7.44896 12.9992 8.0005C12.9992 8.55205 12.5521 8.99917 12.0005 8.99917C11.449 8.99917 11.0019 8.55205 11.0019 8.0005C11.0019 7.44896 11.449 7.00184 12.0005 7.00184Z' fill='%23666666'/%3E%3C/svg%3E");
            }
            &:active {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%23F2F2F2'/%3E%3Cpath d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM12.0001 3.49902C7.30481 3.49902 3.49854 7.3053 3.49854 12.0006C3.49854 16.6959 7.30481 20.5021 12.0001 20.5021C16.6954 20.5021 20.5016 16.6959 20.5016 12.0006C20.5016 7.3053 16.6954 3.49902 12.0001 3.49902ZM11.9964 10.4996C12.3761 10.4994 12.6901 10.7813 12.74 11.1473L12.7469 11.2491L12.7505 16.7507C12.7508 17.1649 12.4152 17.5009 12.001 17.5012C11.6213 17.5014 11.3073 17.2195 11.2574 16.8535L11.2505 16.7517L11.2469 11.2501C11.2467 10.8359 11.5822 10.4999 11.9964 10.4996ZM12.0005 7.00184C12.5521 7.00184 12.9992 7.44896 12.9992 8.0005C12.9992 8.55205 12.5521 8.99917 12.0005 8.99917C11.449 8.99917 11.0019 8.55205 11.0019 8.0005C11.0019 7.44896 11.449 7.00184 12.0005 7.00184Z' fill='%23666666'/%3E%3C/svg%3E");
                outline: none !important;
            }
            &:focus {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='white'/%3E%3Cpath d='M12.0001 1.99902C17.5238 1.99902 22.0016 6.47687 22.0016 12.0006C22.0016 17.5243 17.5238 22.0021 12.0001 22.0021C6.47638 22.0021 1.99854 17.5243 1.99854 12.0006C1.99854 6.47687 6.47638 1.99902 12.0001 1.99902ZM12.0001 3.49902C7.30481 3.49902 3.49854 7.3053 3.49854 12.0006C3.49854 16.6959 7.30481 20.5021 12.0001 20.5021C16.6954 20.5021 20.5016 16.6959 20.5016 12.0006C20.5016 7.3053 16.6954 3.49902 12.0001 3.49902ZM11.9964 10.4996C12.3761 10.4994 12.6901 10.7813 12.74 11.1473L12.7469 11.2491L12.7505 16.7507C12.7508 17.1649 12.4152 17.5009 12.001 17.5012C11.6213 17.5014 11.3073 17.2195 11.2574 16.8535L11.2505 16.7517L11.2469 11.2501C11.2467 10.8359 11.5822 10.4999 11.9964 10.4996ZM12.0005 7.00184C12.5521 7.00184 12.9992 7.44896 12.9992 8.0005C12.9992 8.55205 12.5521 8.99917 12.0005 8.99917C11.449 8.99917 11.0019 8.55205 11.0019 8.0005C11.0019 7.44896 11.449 7.00184 12.0005 7.00184Z' fill='%23666666'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='%239E9E9E' stroke-dasharray='1 1'/%3E%3C/svg%3E");
            }
        }
    }
}
