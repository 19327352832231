html.menu-active, html.menu-active body {
    overflow: hidden;
}

html.dialog-modal-opened,
html.dialog-modal-opened body {
    overflow: hidden;

    // overwriting parent element z-indexes for elements appearing above modal overlay
    .top-banner .wrap,
    .field-wrapper {
        z-index: 0;
    }

    header.stickyOn {
        z-index: 7 !important;
    }
}

#wrapper {
    background-color: $white;
    border: 0;
    left: 0;
    margin: auto;
    position: relative;
    transition: left 0.2s ease-in;
    width: 100%;
    @include respond(medium-down) {
        &.menu-active {
            left: 0%;
            /*position: fixed;*/
        }
    }

    @include respond(large-up) {
        &.menu-active {
            left: auto;
        }
    }
    #main{
        padding: 0 20px;
        position: relative;
        @include respond(desktop) {
        @include respond(large-up) {
            z-index: 1;
        }
            max-width: $max-width;
            margin: auto;
            &.page-content{
                max-width: $max-small-coll;
            }
        }
        @include respond(medium-down) {
            &.page-content{
                padding: 0 10px;
                h1{
                    margin: 0 15px;
                }
                &.account-content {
                    padding: 0 15px;
                    h1 {
                        margin: 0 0 20px;
                        &.page-title {
                            margin: 0;
                        }
                        &.order-summary-title {
                            margin: 27px 0 0;
                        }
                    }
                }
                .customer-care-content {
                    h1 {
                        margin: 0 0 20px;
						&.page-title {
						    margin: 0;
						}
                    }
                }
            }
        }
        @include respond(small) {
        	&.page-content{
                padding: 0 10px;
                h1{
                    margin: 0 15px;
                }
                &.account-content {
                    padding: 0 15px;
                    h1 {
                        margin: 20px 0 20px;
                        &.page-title {
                            margin: 0;
                        }
                    }
                }
            }
        }
        &.no-max-width {
            max-width: none;
            @include respond(large-up) {
                margin-bottom: -100px;
                padding-bottom: 100px;
            }
        }
    }
    &.pt_product-search-result{
        @include respond(large-up) {
            .primary-content {
                left: 5px;
                min-width: calc(100% - 210px);
                width: calc(100% - 225px);
                &.no-left-nav {
                    left: auto;
                    width: 100%;
                }
            }
        }
        @include respond(large) {
            .primary-content {
                width: 85%;
                width: calc(100% - 182px);
                &.no-left-nav {
                    width: 100%;
                }
            }
        }
        #main{
            @include respond(medium-down) {
                padding: 0 15px;
                .breadcrumb{
                    padding-left: 0;
                }
            }
        }
    }
    &.pt_store-locator {
        #main {
            @include respond(medium-down) {
                padding: 0;
            }
        }
    }
    &.pt_product-details, &.pt_customer-service{
        #main{
            @include respond(medium-down){
                padding: 0;
                .form-horizontal{
                    &.contact-us{
                        padding: 20px 15px 0;
                    }
                }
            }
        }
    }
}

.primary-content {
    width: 100%;
    box-sizing: border-box;
	@include respond(large-up) {
        float: right;
        padding: 0 0 20px;
        position: relative;
        width: calc(100% - 225px);
        &.resetsuccessprimary{
        	float: none;
        	width: 100%;
        }
        &.centeredresetsuccessprimary{
        	float: none;
        	width: 90%;
        	text-align: left;
        }
        .primary-focus & {
            border: 0 none;
            float: left;
            margin: 0;
        }
        .full-width & {
            border: 0 none;
            width: 100%;
            max-width: $max-small-coll;
            float: none;
            margin: 0 auto;
        }
        .page-content & {
            h2{
                border-bottom: 1px solid $secondary-border;
                @include font-regular(24px);
                padding-bottom: 20px;
                text-transform: none;
            }
        }
    }
}

#wrapper:not(.pt_cart) {
    .primary-content {
        @include respond(large-up) {
            padding: 0 0 20px 25px;
        }
    }
}

#secondary {
    border: 0;
    float: none;
    font-size: 1.1em;
    width: 100%;

    @include respond(large-up) {
        float: left;
        width: 225px;
        position: relative;
        &.refinements{
            width: 13%;
            min-width: 150px;
            margin-top: 15px;
        }
        nav {
            /*padding-left: 10px;*/
            padding-right: 10px;
            a {
                display: block;
            }
        }
        .primary-focus & {
            background: none;
            border: none;
            box-sizing: border-box;
            float: left;
            padding-left: .4rem;
            width: 225px;
        }
    }
}

.secondary-content {
    clear: both;
}

.horizontal-carousel{
    li{
        list-style: none;
    }
}

.language-popup {
    text-align: center;
    .language-top-header {
        margin-top: -31px;
        color: $black;

        @include font-size(12.05px);
    }
    .language-choose {
        margin-top: -3px;
        .switch {
            @include font-regular(12px);
            display: inline-block;
            padding: 0.25em 0.5em;
            border: 1px solid transparent;
            cursor: pointer;
            background: none;
            text-transform: none;
            color: $cobalt;
            line-height: 22px;
            width: 71px;
            &:hover{
            	color: $cobalt;

            }
            &:active{
            	color: #f2f2f2;

            }
        }
        .active {

    		border-color: $cobalt;
        }
    }
    .language-middle-block {
    	margin-bottom: 19px;
    	margin-top: 35px;
    	@include respond(medium-down) {
            max-width: 210px;
            margin-left: auto;
            margin-right: auto;
    	}
    	&.french{
    		margin-bottom: 0px;
    	}
        .language-header {
            @include font-regular(21px);
            color: $cobalt;
            text-transform: none;
            @include line-height(21px);
            max-width: 280px;
            margin-left: auto;
            margin-right: auto;
        }
        p {
            @include font-regular(14px);
            line-height: 1.25rem;
            margin-top: 9px;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
            @include respond(medium-down) {
            	line-height: 1.1rem;
            }
        }
        .button {
            
        }
    }
    .language-bottom-block {
        .link {
            display: block;
            text-decoration: underline;
            @include font-regular(14px);
            @include line-height(20px);
            &:hover{
                text-decoration: none;
                color: #333333;
            }
            &:active{
                text-decoration: none;
                color: #c2c2c2;
            }
        }
        span {
            @include font-regular(10px);
            line-height: .7rem;
            margin-top: 5px;
            display: block;
            max-width: 310px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 10px;
            @include respond(medium-down) {
                @include line-height(12px);
                max-width: 220px;
            }
        }
    }
}
.language-wrapper .language {
    display: none;
    &.active {
        display:block;
    }
}
.fly-in-dialog.language-popup {
    @include respond(medium-down) {
       width: 290px!important;
    }
    .dialog-content {
        padding: 38px 10px 4px !important;
    }
    .ui-dialog-titlebar-close {
        overflow: hidden;
        right: 0;
    }
}
.exit {
    min-width: 300px;
    .language-popup .language-middle-block {
        margin: 0;
        max-width: none;
    }
    .language-popup .language-middle-block .language-header {
        font-size: 1.25rem;
        line-height: 1.2em;
        padding: 0 40px;
        @include respond(medium-down) {
            padding: 0;
        }   
    }
    .language-popup .language-middle-block p {
        font-size: 1.25em;
        line-height: 1.4em;
        padding: 0px 30px 10px 30px;
        color: #000;
        &.french{
            padding: 0px;
        }
        @include respond(medium-down) {
        	padding: 0 15px;
        }
    }
    .language-popup .language-middle-block .button {
        min-width: 100px;
        margin-bottom: 10px;
    }
    .ui-dialog-titlebar-close {
        overflow: hidden;
        right: 0;
    }
}
#exit-dialog {
    display: none;
}
