.gift-card-details {
    h2 {
        @include ds-heading(h3);
        color: $text-primary;
    }

    h3 {
        @include ds-ui-regular(m);
        color: $text-secondary;
    }

    p {
        @include ds-body(m);
        color: $text-secondary;
    }
}

#giftcard-details-form .form-row {
    .error {
        @include ds-ui-regular(s);
        color: $text-feedback-danger;
    }

    .select-style span {
        padding: 10px 0 0 0;
    }

    span {
        @include ds-ui-semi(m);
        color: $text-primary;
    }

    .required-indicator {
        @include ds-ui-regular(s);
        color: $text-secondary;
    }

    input.field-wrapper {
        @include ds-ui-regular(s);
        color: $text-primary;

        .error {
            @include ds-ui-regular(s);
            color: $text-feedback-danger;
        }
    }

    .input-textarea {
        @include ds-ui-regular(l);
        color: $text-primary;
    }

    .input-select {
        @include ds-ui-regular(l);
        color: $text-primary;
    }

    a {
        @include ds-ui-regular(m);
        color: $text-secondary;
    }

    button {
        @include ds-utilities-button;
        width: auto;
        margin: auto;
    }
}

.gift-certificate-purchase {
    background-color: $white-smoke;
    margin-bottom: 1em;
    padding: .83em;

    .content-asset {
        @include respond(large-up) {
            box-sizing: border-box;
            float: left;
            padding-right: 1rem;
            width: 50%;
        }

        img {
            margin: 1.66em 0;
        }
    }

    form {
        background-color: $white;
        box-sizing: border-box;
        padding: 0 1.66em 1.66em;
        width: 100%;

        @include respond(large-up) {
            float: left;
            width: 50%;
        }

        .cert-amount {
            clear: none;
            float: left;
            margin: 0;
            width: 40%;
        }

        button {
            margin-top: -4px;
            width: 12em;
            //for IE
            width: max-content;
        }

        .form-row-button {
            clear: none;
            float: left;
            margin: 2.1em 0 0 1.66em;
            width: 50%;
        }
    }

    .cert-amount {
        .form-caption {
            margin: 1.66em 0 0;
            position: absolute;
            width: 40%;
        }

        &.error {
            padding-bottom: 2.92em;
        }
    }

    span.error {
        margin-left: 2%;
    }
}

.gc-image-thumb {
    float: left;
    padding-top: calculateRem(9px);
    width: 31%;
    min-width: 90px;
    margin-left: 3.45%;
    text-align: center;

    @include respond(large-up) {
        width: 31.07%;
        margin-left: 3.3%;
        padding-top: calculateRem(10px);
    }

    .gc-image-thumb:first-child,
    .gc-image-thumb:nth-child(3n + 4) {
        margin-left: 0;
    }

    a {
        outline-offset: 2px;
        display: inline-block;
    }

    img {
        box-sizing: border-box;
        cursor: pointer;
        max-width: 100%;
        height: auto;
        opacity: 0.5;
        vertical-align: text-bottom;
    }

    &.selected img {
        opacity: 1;
        border: 1px solid #333;
    }
}

.check-balance {
    border-bottom: 1px solid $gainsboro;
    margin: 0 0 1.66em;
    padding: 0 0 1.66em;
}

.gift-cert-balance {
    float: left;
    width: 40%;

    label {
        display: none;
    }

    input {
        margin-top: 0;
        width: 93%;
    }

    .form-caption {
        margin-left: 0;
    }
}

.gift-cert-balance-submit {
    clear: none;
    float: left;
}

.gift-cert-balance .label {
    display: none;
}

.gift-card-top-banner {
    position: relative;

    img {
        width: 100%;
        max-width: 100%;
        vertical-align: top;
    }

    .note {
        @include font-size(16px);
        @include line-height(24px);
        padding: 0 12.5%;
        text-align: center;

        @include respond(large-up) {
            position: absolute;
            padding: 0;
            left: 0;
            bottom: -1px;
            width: 100%;
            letter-spacing: 0.6px;
        }

        @include respond(large) {
            @include font-size(12px);
            @include line-height(20px);
        }
    }
}

.gift-card-landing-subtitle {
    text-align: center;
    margin-top: 32px;

    @include respond(large-up) {
        margin-top: 62px;
    }

    h2 {
        @include font-size(20px);
        @include line-height(26px);
        margin: 0 15px 5px;

        @include respond(large-up) {
            @include font-size(24px);
            margin: 0 0 2px;
        }
    }

    p {
        margin: 0;

        @include respond(medium-down) {
            color: $secondary-color;
        }
    }
}

.gift-card-options {
    margin: 11px 15px 0;
    max-width: none;

    @include respond(large-up) {
        margin: 2px 0 0;
        position: relative;
    }

    .col-1,
    .col-2 {
        margin-bottom: 0;

        @include respond(large-up) {
            p {
                margin-bottom: 0;
            }
        }
    }

    .gift-card-box {
        text-align: center;
        padding: 21px 0 28px;

        @include respond(large-up) {
            padding-bottom: 0;
        }

        .card-name {
            text-transform: none;
            @include font-size(18px);
            @include line-height(20px);
            margin: 0 0 20px;
        }

        .card-img,
        .urgency {
            img {
                vertical-align: top;
                min-height: 169px;
                max-height: 169px;
            }
        }

        .card-desc {
            @include font-size(14px);
            @include line-height(24px);
            margin: 20px 0 24px;

            @include respond(large-up) {
                margin: 22px 0 33px;
                padding: 0;
            }

            @include respond(medium-down) {
                color: $secondary-color;
            }

            &.mobile {
                @include respond(large-up) {
                    display: none;
                }
            }

            &.desk {
                display: none;

                @include respond(large-up) {
                    display: block;
                    padding: 0 10%;
                }
            }
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $primary-border;

        @include respond(large-up) {
            position: absolute;
            width: 1px;
            height: 300px;
            left: 50%;
            margin-left: -1px;
            top: 37px;
        }
    }
}

.gift-card-balance {
    border-top: 1px solid $primary-border;
    border-bottom: 1px solid $primary-border;
    margin: 15px 0 17px;
    max-width: none;

    @include respond(large-up) {
        margin: 15px 0 80px;
        border-bottom: 0 none;
    }

    &.ui-accordion {
        .ui-accordion-content {
            overflow: inherit;
        }
    }

    .ui-accordion-header {
        text-align: center;
        @include font-size(16px);
        @include line-height(22px);
        padding: calculateRem(10px) 0 calculateRem(22px);

        @include respond(large-up) {
            text-transform: none;
            padding: calculateRem(20px) 0;
            border-bottom: 1px solid $primary-border;
        }

        &.ui-accordion-header-active {
            border-bottom: 1px solid $primary-border;
        }

        .or {
            text-transform: none;
            display: block;

            @include respond(large-up) {
                display: none;
            }
        }

        .desk-title {
            display: none;

            @include respond(large-up) {
                display: block;
                text-transform: uppercase;
                @include font-size(24px);
                margin-bottom: calculateRem(7px);
            }
        }

        .ui-icon {
            float: right;
            position: relative;
            width: 15px;
            height: 15px;
            @include transition(all 0.5s ease-out);
            background-size: 20px;
            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
            top: 33px;
            right: 30px;
            left: auto;

            @include respond(large-up) {
                right: auto;
                left: calc(50% + 157px);
                position: absolute;
                top: 43px;
            }

            &.iconOpen {
                background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
                transform: rotate(180deg);
            }
        }
    }

    .balance-box {
        padding: 6px 0 30px;

        @include respond(large-up) {
            padding: 0;
        }

        .card-balance-asset {
            margin-bottom: calculateRem(25px);

            @include respond(large-up) {
                margin-bottom: calculateRem(19px);
            }

            img {
                vertical-align: top;
                width: 100%;
            }
        }

        form {
            width: 290px;
            margin: 0 auto;

            .form-row {
                &.pin {
                    .field-wrapper {
                        max-width: 156px;
                        float: left;
                    }
                }

                span {
                    &.error {
                        padding-left: 0;
                    }
                }

                .form-field-tooltip {
                    width: 26px;
                    padding-top: 0;
                    margin-left: calculateRem(11px);
                    float: left;
                }
            }

            .g-recaptcha {
                transform: scale(0.95395);
                transform-origin: 0 0;
            }

            #check-giftcert {
                margin-top: calculateRem(27px);
                width: 100%;
                @include font-size(14px);

                @include respond(large-up) {
                    float: right;
                    width: auto;
                    @include font-size(11px);
                }
            }
        }

        .balance {
            text-align: center;
            @include font-size(18px);
            line-height: 1.5rem;

            &.success {
                color: $hollywood-cerise;
                text-shadow: 0 1px 0 white;
                border-top: 1px solid $primary-border;
                padding: calculateRem(8px) 0 0;
                margin-top: calculateRem(27px);

                @include respond(large-up) {
                    border: 0 none;
                    padding: 0;
                }
            }

            &.error {
                float: none;
                padding: calculateRem(8px) 0 0;
                margin-top: calculateRem(27px);
                position: static;

                @include respond(large-up) {
                    padding: 0;
                }
            }

            .gc-learn-more {
                color: $brand-black;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.gift-card-questions {
    border-top: 1px solid $primary-border;
    margin: 15px 0 17px;
    max-width: none;
    text-align: center;
    padding: calculateRem(10px) 0 calculateRem(22px);
    @include line-height(22px);

    @include respond(large-up) {
        margin: 15px 0 80px;
        padding: calculateRem(20px) 0;
    }

    h2 {
        @include font-size(16px);
        margin: 0 0 calculateRem(7px);

        @include respond(large-up) {
            @include font-size(24px);
        }
    }

    a {
        @include font-size(14px);
        text-decoration: underline;
        color: $secondary-color;

        &:hover {
            text-decoration: none;
        }

        &:active {
            color: $click;
            text-decoration: none;
        }
    }
}

#pdpMain {
    &.gift-card {
        margin: 0;

        .gift-product-name {
            margin: 20px 15px 19px;
            padding-bottom: 11px;

            @include respond(large-up) {
                margin: 0 0 53px;
            }
        }

        .product-col-1 {
            @include respond(large-up) {
                width: 47%;
                max-width: 362px;
                margin-right: 9%;
            }

            .dialog-required {
                float: left;
                display: block;
                width: 100%;
                margin: 12px 0 1px;

                em {
                    @include font-size(13px);

                    @include respond(large-up) {
                        @include font-size(12px);
                    }
                }
            }
        }

        .product-col-2 {
            @include respond(large-up) {
                width: 44%;
                margin-top: calculateRem(27px);
            }

            .gift-card-details {
                margin: 34px 0 32px;

                @include respond(large-up) {
                    max-width: 290px;
                    margin: 0 0 34px;
                }

                h2 {
                    text-transform: none;
                    @include font-size(30px);
                    @include line-height(28px);
                    margin: 0 0 26px;

                    @include respond(large-up) {
                        margin-bottom: 20px;
                    }
                }

                h3 {
                    @include font-size(14px);
                    margin: 0 0 3px;
                }

                p {
                    margin: 0;
                }
            }

            form {
                @include respond(large-up) {
                    max-width: 290px;
                }

                .dialog-required {
                    top: -22px;

                    span {
                        em {
                            @include font-size(12px);
                        }
                    }
                }

                .form-row {
                    text-align: left;

                    &.selectbox {
                        margin-bottom: 35px;

                        @include respond(large-up) {
                            margin-bottom: 28px;
                        }

                        .field-wrapper {
                            width: 169px;
                        }
                    }

                    &.form-row-button {
                        button {
                            padding: 0;
                            width: 112px;

                            @include respond(medium-down) {
                                @include font-size(14px);
                                width: 100%;
                            }
                        }
                    }

                    label {
                        text-transform: none;
                    }
                }
            }
        }

        div.privacy-cta-block {
            @include line-height(24px);
            margin: 0 0 1em 0;
            text-align: left;

            @include respond(medium-down) {
                margin-bottom: .7em;
            }

            a {
                @include respond(large-up) {
                    padding-left: 0;
                }

                @include respond(large-down) {
                    margin-bottom: 0;
                }

                @include font-size(12px);
                color: $night-rider;
                margin: 0;
            }
        }
    }
}
