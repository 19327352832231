#wrapper.loyalty_boc {

	#main {
		padding: 0;
	}

	.primary-content {
		padding-right: 0;

		@include respond(large-up) {
			float: none;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
		}
	}
}
#wrapper #main.page-content.account-content
.boc-rewards {
	color: $black;


	@media screen and (max-width: $large-breakpoint) {
		margin: 0 rem(-16px);
	}

	@include respond(medium-up){
	    margin-top: rem(30px);
	    margin-left: auto;
	    margin-right: auto;
	    max-width: 704px;
	    min-height: 568px;
	    min-width: 320px;
	}
	.content {
		margin: 0 auto;
	}

	.content-asset,
	form fieldset {
		box-sizing: border-box;
		height: auto;
		line-height: 1;
		margin: auto;
		max-width: 290px;
		@media screen and (min-width: $medium-breakpoint){
			max-width: 460px;
		}
		position: relative;
		width: 100%;
	}

	h1 {
		@include font-size(80px);
		@include font-smoothing;
		border: none;
		color: $black;
		line-height: 1;
		margin: 0 -25px 0 auto;
		padding-bottom: 0;
		text-transform: lowercase;

		& + p {
			@include font-size(20px);

			line-height: 1.2;
		}
	}

	h2 {
		@include font-size(14px);
		@include font-smoothing;
		@include tracking(30);
		color: $black;

		line-height: 2.428571429;
		margin: 20px auto 25px;

		@media screen and (min-width: $large-breakpoint) {
			@include font-size(15.35px);
			line-height: 2.410423453;
		}
	}

	h3 {
		@include font-smoothing;
		@include tracking(30);
		color: $black;

		font-size: 25px;
		line-height: 1;
		margin-bottom: 0;

		// trademark symbol
		span {

		}
	}

	p {
		@include font-size(16px);
		@include tracking(30);
		@include line-height(20px);
		color: $black;
		left: auto;
	}

	a {
		color: $black;
		font-size: inherit;

		&:hover,
		&:active,
		&:focus {
			color: $cobalt;
			text-decoration: underline;

			svg path {
				fill: $cobalt;
			}
		}
	}

	a.button,
	button,
	.bbwd-button {
		@include font-size(13px);
		@include tracking(50);
		background-color: $black;
		border: 1px solid $gainsboro;
		box-sizing: border-box;
		color: $white;
		display: inline-block;

		line-height: 2.538461538;
		margin: 0 auto;
		padding: 0 20px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		width: 100%;

		&:hover,
		&:active,
		&:focus,
		body:not(.touch) &:not([disabled]):not(.disabled):hover,
		body:not(.touch) &:not([disabled]):not(.disabled):active,
		body:not(.touch) &:not([disabled]):not(.disabled):focus {
			background-color: $black;
			border-color: $black ;
			color: $white;
			text-decoration: none;
            &.link-edit {
                background-color: transparent;
				color: $black;
            }
		}


		&:disabled {
			background-color: $white;
			border-color: $gainsboro;
			color: #c2c2c2;
		}
	}

	.fit-width{
		max-width: 460px;
	}

	input {
		// @include font-size(20px);
		background-color: transparent;
		border: 1px solid $gainsboro;
		box-sizing: border-box;
		color: $black;
		height: 2.1875rem;
		padding: 0.3125rem 0.6875rem;

		width: 100%;

		&::placeholder {
			opacity: 1;

			color: $bg-content;
		}
		&::-webkit-input-placeholder {

			color: $bg-content;
		}

		&::-moz-placeholder {

			color: $bg-content;
			opacity: 1;
		}

		&:-ms-input-placeholder {

			color: $bg-content;
		}

		&:-moz-placeholder {

			color: $bg-content;
			opacity: 1;
		}

		&[disabled] {
			border-bottom-color: $boc-border;
		}

		&:focus {
			outline: 1px dotted $black !important;
			outline-offset: 2px;
		}

		&:read-only {
			border-bottom-color: $cobalt;
		}
	}

	// MY BBWD LOGO
	.boc-header {
		background-color: $pink-boc;
		padding-bottom: 20px;
		padding-top: 20px;
		margin-bottom: 40px;

		.content-asset {
			max-width: 330px;

			img.bbwd-my-logo {
				max-width: 100%;
				background-color: $pink-boc;
			}
		}
	}

	// GATED ZIP LANDING
    .boc-landing-content {
        .content-asset {
            margin: 0 rem(54px);
            padding-top: rem(17px);
            max-width: unset;
            width: unset;
            position: static;
	        img {
	            max-width: 100%;
	            margin-bottom: rem(20px);
	        }
        }
    }
    .boc-links {
        text-align: center;
        & > p {
            margin-top: 0;
            margin-bottom: rem(8px);
            &:hover,
            &:active,
            &:focus {
                span {
                    color: $black;
            }
        }
            a {
                border-bottom: 1px solid $white;
            font-size: rem(14px);

            letter-spacing: normal;
            line-height: rem(24px);
                text-decoration: none;
                &:hover,
                &:active,
                &:focus {
                    border-bottom-color: $black;
        }
        }
        }
        .boc-rewards-stores {
            border-bottom: 1px solid $white;
            font-size: rem(14px);

            letter-spacing: normal;
            line-height: rem(24px);
        }
    }
    div.boc-gated-form-row {
        margin-bottom: 0;
    }

	// HEADLINE & INSTRUCTIONS
	.boc-instructions {

		.content-asset {
			padding-top: 0;

			h1 {
				display: none;

				& + p {
					@include tracking(30);
					@include line-height(20px);
					color: $black;
					left: auto;
					font-size: 0.8rem;
					margin: 1em 0 !important;
				}
			}
		}

		& + .button {
			display: block;
			margin: 10px auto 30px;
			max-width: 230px;
		}

		&.boc-active + .button {
			margin-bottom: 42px;
		}

		&.boc-success + .button {
			margin-bottom: 40px;
		}

        &.boc-success {

            .content-asset {
                max-width: 350px;
                text-align: center;

                h1.rlp-success-headline {
                    @include font-size(18px);
                    display: inline-block;

                    letter-spacing: 0.01em;
                    margin-bottom: 0;
                    margin-right: 3px;
                    margin-top: 15px;
                    text-transform: none;
                    -webkit-font-smoothing: none;
                    -moz-osx-font-smoothing: auto;

                    & + p {
                        @include font-size(18px);
                        display: inline;

                    }
                }
			}
        }
	}

	// SIGNUP FORM
	form fieldset {
		padding-bottom: 21px;
		padding-top: 0;
		@media screen and (min-width: $medium-breakpoint) {
			max-width: 460px;
		}
	}

	.dialog-required {
		@include font-size(12px);
		color: $black;
		display: block;
		float: none;

		line-height: 1;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 10px;

		span {
			color: transparent;
			float: none;

			&::before {
				@include font-size(12px);
				content: "*";
				color: $red;

				position: relative;
				left: 7px;
			}

			em {
				@include font-size(12px);
				color: $black;

				text-transform: capitalize;
			}
		}
	}

	.form-row {
		margin: 0 auto 18px;
		position: relative;

		label {
			@include font-size(16px);
			@include line-height(20px);
			@include tracking(60);
			color: $black;

			left: auto;
			letter-spacing: 0.0015em;
			margin-bottom: 2px;
			font-weight: 700;
		}

		.input-text {

			&.error {
				border: 1px solid $red;
				border-width: 1px;
				color: $black;
			}
		}

		span.error:not(.input-checkbox) {
			@include font-size(12px);
			background: $white;
			color: $red;
			display: block;
			float: none;

			line-height:  0.95rem;
			padding: 0 0 0.25rem 0.625rem;
			text-align: left;
			top: auto;
		}

		span.error.input-checkbox {
			background: $red;
			border-collapse: $white;
		}

		.required-indicator {
			float: none;
			margin: 0;
			color: $red;
		}

		.form-caption {
			@include font-size(12px);
			color: $black;
			display: block;

			line-height: 1.333333333;
			margin: 5px 0 0;
		}

		&.email,
		&.password {
			.required-indicator {
				display: none;
			}
		}
	}

	.combined-row {
		position: relative;
	}

	.link-edit {
		background-color: $white;
		color: $black;
		border: none;
		position: absolute;
		right: 1px;
		top: 23px;
		width: auto;
		z-index: 999;
		padding: 0px 10px;
	}

	.link-edit-button {
		@include font-size(13px);
		@include tracking(50);
		box-sizing: border-box;
		text-decoration: none;
    	text-transform: uppercase;
		padding: 8px 10px;

		&:hover {
			color: inherit;
			text-decoration: none;
		  }

	}

	#dwfrm_profile_login_password,
	#dwfrm_login_password,
	#dwfrm_resetpassword_password {
		padding-right: 40px;
	}

	 button.hide-show {
		background-color: transparent;
		color: $black;
		border: none;
		position: absolute;
		right: 1px;
		top: 1px;
		width: auto;
		z-index: 5;
		padding: 0px 10px;

		&:hover,
		&:active,
		&:focus,
		body:not(.touch) &:not([disabled]):not(.disabled):hover,
		body:not(.touch) &:not([disabled]):not(.disabled):active,
		body:not(.touch) &:not([disabled]):not(.disabled):focus {
			background-color: transparent;
			color: $black;
		}

		&[aria-label="Hide Password"] {

			&:hover,
			&:active,
			&:focus,
			body:not(.touch) &:not([disabled]):not(.disabled):hover,
			body:not(.touch) &:not([disabled]):not(.disabled):active,
			body:not(.touch) &:not([disabled]):not(.disabled):focus {
				background-color: transparent;
				color: $black;
			}
		}
	}

	// PASSWORD RESET
	#password-reset {
		@include font-size(14px);

		line-height: 1.25;
		margin-bottom: 12px;
		margin-top: 5px;
		text-align: left;
		text-decoration: underline;
		float: left;
	}

	// PASSWORD REQUIREMENTS
	.required-pass {
		background-color: $white;
		margin-bottom: 21px;
		padding: 14px 10px 10px;
		position: relative;

		&:before {
			border-color: transparent;
			border-bottom-color: $white;
			border-style: solid;
			border-width: 15px;
			bottom: 97%;
			content: " ";
			height: 0;
			left: 15%;
			margin-left: -15px;
			pointer-events: none;
			position: absolute;
			width: 0;
		}
	}

	// TERMS & CONDITIONS
	.term-condition {

		.form-row {
			margin-bottom: 30px;
		}

		a {
			text-decoration: underline;
		}

		.input-checkbox {
			color: $black;
			height: 16px;
			left: 0;
			position: absolute;
			top: 0;
			width: 16px;
		}

		label {
			display: block;
			margin-left: 24px;
			padding: 0;

			& > span {
				@include font-size(13px);
				display: block;
				line-height: 1;
			}
		}

		.term-description {
			margin-bottom: 5px;
			margin-left: 24px;
			text-indent: 0;

            &, label, a.privacy-policy {
                color: $text-secondary;
                @include font-size(16px);
            }

            .loyalty-join-terms-label {
                display: block;
                float: none;
                font-weight: bold;
                margin: 0 0 0.5em 0;
                padding: 0;
                width: 100%;
            }
		}
		span.error {
			margin-left: 20px;
		}
	}

	// PROGRESS BAR
	.boc-progress {

		progress {
			background-color: $dark-gray;
			border: none;
			border-radius: 5px;
			height: 5px;
			width: 100%;

			&::-webkit-progress-bar {
				background-color: $dark-gray;
				border-radius: 5px;
			}

			&::-webkit-progress-value {
				background-color: $cobalt;
				border-radius: 5px;
			}

			&::-moz-progress-bar {
				background-color: $cobalt;
				border-radius: 5px;
			}

			& + span {
				@include font-size(14px);
				color: $black;
				display: block;

				margin-top: 7px;
				text-align: center;
				width: 100%;
			}
		}
	}

	// FOOTER HELP
	.boc-footer {

		.content-asset {
			background-image: url("../images/svg-icons/info.svg");
			background-position: center top;
			background-repeat: no-repeat;
			background-size: 28px;
			margin-top: 34px;
			padding-bottom: 20px;
			padding-top: 45px;

			ul {
				margin: 0;
				text-align: center;

				li {
                    color: $black;

					list-style: none;
					margin-bottom: 5px;

					a {
						text-decoration: underline;
					}
				}
			}
		}

		p {
			display: inline-block;
			line-height: 1.35;
			margin-top: 10px;
			padding-top: 34px;
			text-align: center;
			width: auto;

            &.bbwd-footnote {
                @include font-size(12px);
			    @include tracking(0);
                display: block;

                font-style: italic;
                margin-left: auto;
                margin-right: auto;
                padding-top: 10px;
                text-align: center;
                width: 80%;
            }

			span {

				@media screen and (max-width: $large-breakpoint) {
					display: block;
				}
			}
		}
	}

	// ------------------------------
	// LANDING PAGE
	// ------------------------------

	@mixin rotate($degrees: -11) {
		transform: rotate(#{$degrees}deg);
	}

	// fake bold for Trend Sans One
	@mixin faux-bold($size: 0.35px, $color: $white) {
		text-shadow: -$size -$size 0 $color, $size -$size 0 $color, -$size $size 0 $color, $size $size 0 $color;
	}

	&.landing {

		h1,
		h2,
		p {

		}

		.content-asset {
			max-width: 320px;
			padding: 0 15px;
		}

        .boc-landing-head {

            .content-asset {
                padding: 0;
            }
		}

		p {
			@include font-size(10px);
			@include tracking(-90);
			color: $white;
			line-height: 1.2;
            }

		.bbwd-ribbon {
			@include font-size(12px);
			@include tracking(30);
			background-color: $brand-blue;
			color: $white;
			display: inline-block;
			line-height: 25px;
			margin: 0 auto 15px;
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			text-align: center;
			text-transform: uppercase;

			&:before, &:after {
				background-color: $brand-pink;
				bottom: 0;
				content: "";
				height: 18px;
				left: -9px;
				position: absolute;
				top: 3.5px;
				transform: scale(0.5, 1) rotate(45deg);
				width: 18px;
			}

			&:after {
				left: auto;
				right: -9px;
			}
		}

		.bbwd-header {
			padding-top: 15px;
			text-align: center;

			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: 253.5px;
				position: relative;
				z-index: 50;

				&.bbwd-app-icn {
					@include rotate();
					border-radius: 24%;
					height: 54px;
					left: calc(50% - 25px);
					top: auto;
					width: 54px;
				}
			}

			h1 {
                background-image: url("../images/my-bath-and-body-works-rewards.png");
				background-position: center top;
				background-repeat: no-repeat;
				background-size: 320px 47px;
                height: 47px;
				margin: 0 auto 30px !important;
				text-indent: -99999px;
                width: 320px;
			}
		}

		.bbwd-zip-lookup {

			h2 {
				@include faux-bold(0.125px);
				@include font-size(16px);
				@include tracking(-100);
				border-bottom: none;
				line-height: 1.25;
				margin-bottom: 2px;
				margin-top: 6px;
				padding-bottom: 0;
				text-align: center;
				text-transform: uppercase;

				& + p {
					@include font-size(18px);
					@include tracking(10);

					line-height: 1.166666667;
					margin-top: 2px;
					text-align: center;
				}
			}
		}

		#RegistrationFormBoc {
			margin-bottom: 20px;
			margin-top: 30px;

			.form-row.postal {
				margin-bottom: 13px;
			}
		}

		.bbwd-benefits {
			background-image: url("../images/loyalty-balloons.png");
			background-position: center top;
			background-repeat: no-repeat;
			background-size: calc(84px * 0.5) calc(72px * 0.5);
			margin: 0 auto;
			max-width: none;
			padding: 50px 0 10px;
			text-align: center;

			h2 {
				@include faux-bold(0.25px);
				@include font-size(12px);
				@include tracking(-30);
				border-bottom: none;
				margin-bottom: 0;
				padding-bottom: 0;
				padding-left: 30px;
				padding-right: 30px;
				text-transform: uppercase;
			}

			p {
				margin: 0 auto;

				img {
					height: auto;
					width: 150px;
				}
			}

			.bbwd-grid-list {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				list-style: none;
				margin: 10px auto 0;
				padding: 0;
				width: 90%;

				& + p {
					line-height: 1.2;
					margin-bottom: 20px;
					margin-top: 10px;
					text-align: center;

					span {
						display: block;
					}
				}
			}

			.bbwd-grid-list-item {
				box-sizing: border-box;
				height: auto;
				line-height: 0;
				list-style: none;
				margin-bottom: 0;
				position: relative;
				width: 100%;
				z-index: 20;

				&.bbwd-text-item {
					background-position: center top;
					background-repeat: no-repeat;
					margin-top: 15px;
					padding: 0 10px;
					text-align: center;
					z-index: 30;

					p {
						@include font-size(16px);
						@include tracking(10);

						line-height: 1.0625;
						padding: 58px 0 10px;
						text-transform: none;

						strong {

						}
					}

					// 1. PRODUCT
					&.bbwd-benefit1 {
                        background-image: url("../images/rewards-earn-icn.png");
						background-size: 27.12px 50px;

						p {
							padding-top: 60px;
						}
					}

					// 2. OFFERS
					&.bbwd-benefit2 {
                        background-image: url("../images/rewards-reward-icn.png");
						background-size: 24.64px 60px;
						padding-left: 20px;
						padding-right: 20px;

						p {
							padding-top: 70px;
						}
					}

					// 3. WALLET
					&.bbwd-benefit3 {
						background-image: url("../images/rewards-offer-icn.png");
						background-size: 36.25px 50px;
                        padding-left: 0;
                        padding-right: 0;

						p {
							padding-top: 60px;
						}
					}

				}
			}
		}

		.button {
			display: block;
			margin: 10px auto 0;
			max-width: 230px;
		}

		// FORM

		.bbwd-form-row {
			// display: table-row;
			margin-bottom: 18px;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			max-width: 230px;
		}

		label {
			@include font-size(14px);
			@include tracking(60);
			color: $white;

			line-height: 1.214285714;
			margin-bottom: 13px;
			position: relative;

			&.bbwd-required {

				&:before {
					color: $white;
					content: "*";

					left: -11px;
					position: absolute;
				}
			}

		}

		input:not(.bbwd-button) {
			@include font-size(20px);
			@include tracking(0);
			background-color: transparent;
			border-color: transparent;
			border-bottom: 2px solid $white;
			color: $white;


			&:focus {
				outline: 1px dotted $white !important;
				outline-offset: 2px;
			}

			&:read-only {
				border-bottom-color: $cobalt;
			}

			&::-webkit-input-placeholder {
				color: $bg-content;
			}

			&::-moz-placeholder {
				color: $bg-content;
				opacity: 1;
			}

			&:-ms-input-placeholder {
				color: $bg-content;
			}

			&:-moz-placeholder {
				color: $bg-content;
			}

			&.error {
				background-color: $red;
				border-color: $white !important;
				border-width: 2px;
			}
		}

		.boc-links {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-left: auto;
			margin-right: auto;
			margin-top: 10px;
			max-width: 310px;
			text-align: center;

			p {
				text-transform: uppercase;
			}

			a {
				@include font-size(14px);
				@include tracking(0);

			}
		}

		.boc-footer {

			.content-asset {
				margin-top: 34px;
				padding-bottom: 20px;
				padding-top: 45px;
			}
		}
	}

	// ------------------------------
	// APP OVERVIEW
	// ------------------------------

	.boc-app {
		background-color: $white;

		.content-asset {
			max-width: 400px;
			margin-top: 3px;
			padding-left: 0;
			padding-right: 0;

			section {
				width: 50%;
			}

			& > img:first-child {
				border-radius: 24%;
				box-shadow: 0 2px 4px rgba($black, 0.5);
				height: 50px;
				left: calc(50% - 25px);
				top: -25px;
				position: relative;
				width: 50px;
			}

			h2,
			h3,
			p,
			aside {
				color: $black;
			}

			p {
				@include tracking(10);

			}

			h2 {
				font-size: 2rem;
				@include tracking(0);
				border-bottom: none;
				line-height: 1;
				margin-bottom: 5px;
				margin-top: -22px;
				padding-bottom: 0;
				text-align: center;
				text-transform: lowercase;

				& + p {
					@include font-size(18px);
					color: $dim-gray;
					margin: 2px 30px 18px;
					text-align: center;
				}
			}

			h3 {
				@include faux-bold(0.025px, $black);
				@include font-size(18px);
				@include tracking(-100);
				background-position: center top;
				background-repeat: no-repeat;

				line-height: 1.0625;
				margin: 0 auto 4px;

				& + p {
					@include font-size(10px);
					line-height: 1.2;
					margin: 0 auto;
					text-align: center;
					width: 85%;
				}
			}

			aside {
				@include rotate(-8);
				margin-top: 8px;

				p {
					@include font-size(30px);
					@include font-smoothing;
					line-height: 0.833333333;
					margin: 0 auto;
					text-transform: lowercase;
				}
			}

			.bbwd-button {
				background-color: $black;
				color: $white;
				display: block;
				line-height: 2.75;
				margin-bottom: 30px;
				width: 230px;

				&:hover, &:focus {
					border-color: $black;
					color: $black;
					text-decoration: none;

					@media screen and (max-width: $large-breakpoint) {
						background-color: $black;
						color: $white;
					}
				}

				&:disabled {
					background-color: $white;
					border-color: $gainsboro;
					color: #c2c2c2;
				}
			}

			section {
				margin-top: 10px;
				max-width: 200px;
				overflow: hidden;
				padding-right: 50%;
				position: relative;
				text-align: center;
				width: 50%;

				// 1-4
				&.boc-app-overview-earn {

					h3 {
						background-image: url("../images/svg-icons/app-overview-earn-icn.svg");
						background-size: calc(116px * 0.5) calc(106px * 0.5);
						padding-top: 62px;
					}

					aside > p {
						width: 80%;
					}
				}

				// 2-4
				&.boc-app-overview-wallet {

					h3 {
						background-image: url("../images/svg-icons/app-overview-shop-icn.svg");
						background-size: calc(76px * 0.5) calc(76px * 0.5);
						padding-top: 45px;
					}
				}

				// 3-4
				&.boc-app-overview-alert {

					h3 {
						background-image: url("../images/svg-icons/app-overview-alert-icn.svg");
						background-size: calc(76px * 0.5) calc(76px * 0.5);
						padding-top: 45px;

						& + p {
							width: 75%;
						}
					}
				}

				// 4-4
				&.boc-app-overview-exclusive {

					h3 {
						background-image: url("../images/svg-icons/app-overview-exclusive-icn.svg");
						background-size: calc(54px * 0.5) calc(112px * 0.5);
						padding-top: 64px;
					}
				}

				& > img {
					display: block;
					max-width: 100%;
				}

				& > div {
					max-width: 200px;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 50%;
				}

				&.alt-position {
					padding-left: 50%;
					padding-right: 0;

					& > div {
						left: 0;
						right: auto;
					}
				}
			}
		}
	}
}

.bbwd-caps {
	text-transform: uppercase;
}
