.home-carousel{
    .owl-item{
        img{
            display: block;
            width: auto;
            @include respond(small-down){
                width: 100%;
            }
        }
    }
    .owl-controls {
        .owl-dots {
            position: absolute;
            bottom: -60px;
            width: 99%;
            .owl-dot {
                width: 28px;
                height: 28px;
                background: $secondary-color;
                @include font-bold(16px);
                border-radius: 30px;
                @include line-height(28px);
                @include transition(all 0.3s ease);
                &:hover{
                    background: $primary-color;
                }
                &.active {
                    background: $primary-color;
                }
            }
        }
    }
    .item {
    	position: relative;
    	.image {
    	   text-align: center;
    	   overflow: hidden;
    	   max-width: 1920px;
    	   margin: auto;
	       img {
	           max-width: 100%;
	           @include respond(large-up) {
	                width: 114.275%;
	                max-width: none;
	                position: relative;
	                left: 50%;
	                transform: translate(-50%, 0);
	            }
	            @include respond(hero-crop) {
                    min-width: 1920px;
                    width: auto;
	            }
            }
    	}

    	.details.overlay {
   			.wrap {
	    		.box {
					display: block;
				    color: $cobalt;
				    text-align: center;
				    @include font-size(14px);
				    position: absolute;
				    line-height: 1;
				    width: auto;
				    box-sizing: border-box;
				    text-transform: uppercase;
			        bottom: 17px;
				    left: 25px;
				    width: calc(100% - 50px);
				    padding: 15px 0;
				    @include respond(large-up) {
				        bottom: 6.5%;
	                    right: 4%;
	                    left: inherit;
	                    padding: 30px;
	                    max-width: 262px;
                        max-height: 192px;
				    }
				    span {
				    	@include font-bold(28px);
				    	@include respond(large-up) {
				    		@include font-size(30px);
				    		letter-spacing: -1px;
				    		display: block;
				    		&.small-title {
				    		  margin-top: 4px;
				    		}
				    		&.sub-title {
				    			@include font-size(50px);
				    			letter-spacing: -3px;
                                line-height: 0.9;
				    		}
						}
				    }
    			}
    		}
    	}
    }
}

.bottom-text {
	display: block;
	text-align: center;
	margin: 15px 0 35px;
	@include font-size(14px);
	color: $secondary-color;
    vertical-align: middle;
    line-height: 1;
    @include respond(large-up) {
    	margin-bottom: 30px;
    }
	span, a {
        color: $night-rider;
		&.standard-text {
			@include font-size(20px);
		}
		&.embellish {
		    text-transform: uppercase;
		    @include font-bold(14px);
		    &:hover {
		    	color: $cobalt;
		    }
		    @include respond(medium-down) {
		    	display: block;
		    	margin-top: 10px;
		    }
		    @include respond(large-up) {
		    	&:before {
	    		    content: '|';
    				margin: 0 24px 0 21px;
				    @include font-regular(14px);
				    color: $secondary-color;
		    	}
		    }
		}
	}
	.home-grid-two & {
        margin-bottom: 30px;
        @include respond(large-up) {
            margin-bottom: 50px;
        }
		.standard-text {
			display: block;
			margin-bottom: 10px;
		}
	}
}

.home-bottom-slots {
    margin:0 auto 100px;
    max-width: $max-width + 70;
    padding: 0 15px;
    .responsive-grid{
        max-width: $max-width + 40;
        text-align: center;
        &.desktop-only{
            @extend %desktop-only;
        }
        img {
        	vertical-align: top;
        	max-width: 100%;
        }
    }
    .home-bottom-left, .home-bottom-center, .home-bottom-right {
        clear: none;
        width: 100%;
        @include respond(desktop) {
            float: left;
            width: 33.33333333333333333%;
        }
    }
    .home-grid-tree{
        .grid-col{
            .alt-p{
                @include font-regular(12px);
                text-transform: uppercase;
                margin-bottom: 0;
            }
            .style-spec{
                @include font-regular(12px);
                margin: 0;
            }
            .price{
                @include font-regular(24px);
            }
            .buttons{
                margin-top: 10px;
            }
        }
    }

    .home-grid-one{
        .grid-col {
        	.under-menu {
			    position: relative;
			    border-top: 1px solid $secondary-border;
			    text-align: center;
			    margin: 37px auto 9px;
			    @include respond(large-up) {
			    	margin: 66px auto 53px;
			    }
			    h4 {
			        @include font-bold(16px);
			        letter-spacing: 3.134px;
			        display: inline;
			        padding: 0 20px;
			        position: relative;
			        top: -8px;
			        background: $white;
			        margin-bottom: 30px;
			        @include respond(large-up) {
			        	letter-spacing: 5px;
			        }
			    }
			    ul {
			        margin-top: 10px;
			        @include respond(large-up) {
			        	margin-top: 28px;
			        }
			        li {
			            display: block;
			            list-style: none;
			            text-transform: uppercase;
			            display: inline;
		            	padding: 0 2%;
		            	@include font-regular(14px);
		            	@include respond(large-up) {
		            		&.first {
			    				padding-left: 0;
			    			}
		            		&.last {
			    				padding-right: 0;
			    			}
		            	}
			    		@include respond(medium-down) {
			    			display: block;
			    			width: 50%;
			    			padding: 0;
			    			float: left;
			    			&.odd {
			    				padding-right: 7px;
			    			}
			    			&.even {
			    				float: right;
			    				padding-left: 7px;
			    			}
			    			@include font-regular(12px);
			    			span {
			    				display: block;
		    				    line-height: 1;
    							margin: 9px 0 19px;
			    			}
			    		}
			        }
			    }
			}
            @include respond(large-up) {
                h3 {
                    @include font-size(22px);
                    margin-bottom: 0;
                }
                h2 {
                    @include font-bold(28px);
                    margin-top: 10px;
                }
            }
        }
    }
}

@include respond(medium-down) {
    .home-bottom-left, .home-bottom-center {
        width: 100% !important;
    }
    .home-bottom-right {
        clear: both;
        float: none;
        width: 100% !important;
        .product-tile {
            min-height: 0;
        }
    }
}


@include respond(large) {
    .home-bottom-right {
        .product-tile {
            min-height: 0;
        }
    }
}
.pt_storefront{
    &#wrapper{
        #main{
            max-width: none;
            padding: 0;
            img {
            	vertical-align: top;
            }
        }
    }
}

.center{
    .wrap{
        text-align: center;
    }
}
.right{
    .wrap{
        @include respond(small-down) {
            text-align: center;
        }
        text-align: right;
    }
}
.left{
    .wrap{
        @include respond(small-down) {
            text-align: center;
        }
        text-align: left;
    }
}
.details.overlay {
    position: absolute;
    padding: 0;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .wrap{
        height: 100%;
        font-size: 0;
        .box{
            vertical-align: middle;
            display: inline-block;
            padding: 30px 20px;
            text-align: center;
            background: rgba(255,255,255,0.9);
            @include respond(large-up) {
                width: 25%;
            }
        }
        &:before{
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
}
.bottom{
    .box{
        vertical-align: bottom !important;
    }
    &:before{
        vertical-align: bottom !important;
    }
}
.mobi-bottom{
    @include respond(small-down) {
        .box {
            vertical-align: bottom !important;
        }
        &:before {
            vertical-align: bottom !important;
        }
    }
}
.mobi-middle{
    @include respond(small-down) {
        .box {
            vertical-align: middle !important;
        }
        &:before {
            vertical-align: middle !important;
        }
    }
}

.item{
    .image{
        &.part{
            @include respond(large-up) {
                width: 70%;
            }
        }
        &.right{
            @include respond(medium-up) {
                float: right;
                img{
                    float: right;
                }
            }
        }
        &.left{
            @include respond(medium-up) {
                float: left;
            }
        }
    }
}

.home-grid-two {
    @include column-respond((
            768px : 2,
            gutter : 0
    ));
    @include respond(large-up) {
        margin-bottom: 10px;
    }
    .grid-col{
        @include respond(large-up) {
            padding: 0;
            &.left {
                padding-right: 25px;
            }
            &.right {
                padding-left: 25px;
            }
        }
        .grid-item {
        	text-align: center;
        }
    }
}
.home-grid-one {
    @include column-respond((
            768px : 1,
            gutter : 0
    ));
    .grid-col{
        width: 100%;
        &.without-text {
        	margin-bottom: 30px;
        	@include respond(large-up) {
        		margin-bottom: 60px;
        	}
        }
    }
}
.home-grid-tree{
    max-width: 980px !important;
    margin-bottom: 20px;
    .grid-col{
        @include respond(large-down) {
            padding: 10px;
            img{
                width: 100%;
            }
        }
        @include respond(large-up) {
            width: 33.33333%;
        }
    }
}


.title{
    color: $primary-color;
    text-transform: none;
    margin: 0 0 5px;
}
h2.title{
    @include h1;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .details.overlay {
        width: calc(100% - 200px);
    }
}

.HP-member-exclusive{
	width:100%;
	.guest-member-exclusive-container{
		box-sizing: border-box;
		padding:0 15px;
		width:100%;
		.guest-HP-member-exclusive-banner{
			display: flex;
			flex-direction: row;
			margin:30px 0;
			width:100%;
			a{
				width:100%;
				picture{
					width:100%;
					img{
						height: auto;
						width:100%;
					}
				}
			}
		}
	}
}

.cat-exclusive{
	width:100%;
	.cat-exclusive-container{
		box-sizing: border-box;
		padding:0;
		width:100%;
		.cat-exclusive-banner{
			display: flex;
			flex-direction: row;
			width:100%;
			picture{
				width:100%;
				img{
					height: auto;
					width:100%;
				}
			}
		}
	}
}

.PDP-promo{
	width:100%;
	.PDP-promo-button{
		display: flex;
		flex-direction: row;
		margin:12px 0 23px;
		width:100%;
		@include respond(small) {
			margin:7px 0 19px;
		}
		picture{
			width:100%;
			img{
				height: auto;
				vertical-align: bottom;
				width:auto;
			}
		}
	}
}
#QuickViewDialog .PDP-promo{
	width:100%;
	.PDP-promo-button{
		display: flex;
		flex-direction: row;
		margin:14px 0 0;
		width:100%;
		picture{
			width:100%;
			img{
				height: auto;
				vertical-align: bottom;
				width:auto;
			}
		}
	}
}

.private-sale-checkout{
	margin:20px 0;
	width:100%;
	picture{
		width:100%;
		img{
			height: auto;
			width:100%;
		}
	}
}

/*Private Sale login page*/
.private-sale-login{
	margin: 0 auto;
	width:400px;
	@include respond(small) {
		width:100%;
	}
	.private-sale-login-header{
		width:100%;
		.private-sale-login-banner{
			margin:40px 0 20px;
			width:100%;

			@include respond(small) {
			    width: calc(100% + 1.875rem);
                margin-left: rem(-15px);
                margin-top:0;
            }

			picture{
				width: 100%;
				img{
					display: block;
					width:100%;
				}
			}
		}
	}
	.content{
		margin:0 70px;
		@include respond(small) {
			margin:0 15px;
		}
		.form-row.password{
			margin:30px 0 18px;
		}
		.form-row{
			margin:10px 0 20px;
		}
		.form-row.private-sale-sign-in-btn{
			margin:0 0 18px;
		}
		.hide-show{
			background: #fff;
			border: 0;
			color: #333;
			font-size: 13px;
			margin: 0;
			padding: 0 12px;
			position: absolute;
			right: 1px;
			top: 1px;
			z-index: 5;
			&:hover{
				background-color: none;
				border:none;
			}
		}
		#password-reset{
			color:$dim-gray;

			font-size: 14px;
			line-height: 24px;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
		.form-row-button{
			@include respond(small) {
				button[type=submit]{
					width:100%;
				}
			}
		}
		.login-rememberme{
			color:$dim-gray;
			margin:5px 0 0 0;
			float:right;
			label{
				span{
					color:$dim-gray;

					font-size: 13px !important;
					line-height: 16px;
				}
			}
		}
		.private-sale-area{
				color:$night-rider;

				line-height: 24px;
				text-align: center;
				font-size: 14px;
				margin:0;
				padding:0;
				a{
					color:$night-rider;
					text-decoration: underline;
					&:hover{
						text-decoration: none;
					}
				}
			}
			.private-sale-privacy-policy{
				text-align: center;
				margin:0 0 40px;
				padding:0;
				a{
					color:$night-rider;

					font-size: 12px;
					line-height: normal;
					text-align: center;
					text-decoration: underline;
					&:hover{
						text-decoration: none;
					}
				}
			}
			.private-sale-create-account{
				margin:0 0 15px;
				padding:0;
				text-align: center;
				button[type=submit]{
					background: none;
					border:none;
					color:$night-rider;

					font-size: 14px;
					line-height: 24px;
					letter-spacing: normal;
					padding:0;
					text-align: center;
					text-decoration: underline;
					text-transform: none;
					&:hover{
						background: none;
						text-decoration:none;
						color:$night-rider;
					}
				}
			}
	}
}

/*Loyalty Eligible Customer*/
.private-sale-loyalty-join-container{
	margin:0 auto;
	width:290px;
	.loyalty-join-header{
		.content-asset{
			background: #E6F5FF;
			margin:40px 0 15px;
			padding: 15px;
			text-align: center;
			.want-join-now{
				color: #005699;
				font-size: 21px;
				line-height: 21px;
				margin:0;
				padding:10px 0 0;
				text-align: center;
			}
			.loyalty-prospect-message{
				color: #005699;
				font-size: 14px;
				line-height: 16px;
				margin:0;
				padding:10px 0 0;
				text-align: center;
			}
		}
	}
	.loyalty-prospect-join-form{
		width:100%;
		.loyalty-welcome-message{
			width:100%;
			.join-button{
				display: flex;
				margin:0 15px;
				width:calc(100% - 30px);
				.black-text.join-text{
					background: $night-rider;
					width:100%;
					&:hover{
						background-color:#f2f2f2;
					}
					a{
						box-sizing: border-box;
						color:$white;
						display: block;

						font-size: 13px;
						line-height: 13px;
						letter-spacing: 1.3px;
						padding:10px 0;
						text-align: center;
						text-decoration: none;
						width:100%;
						&:hover{
							color:$night-rider;
						}
					}
					
				}
			}
		}
	}
}

.private-sale-error-container{margin:0 auto;width:290px;}
