.ds-falselink {
    @include ds-ui-semi(l);
    text-decoration-line: underline;
    color: #333333;
    margin-top: 8px;

    &:hover {
        text-decoration: none;
    }
}

.ds-linkcontainer {
    padding-top: 8px;
    width: 0;
    min-width: 100%;

    a {
        display: block;
    }
}

.ds-paragraph {
    @include ds-ui-regular(l);
    text-align: center;
    color: #666;
    margin-top: 8px;
}

.ds-link-image {
    display: inline-block;
    line-height: 0;

    >a {
        display: inline-block;
    }
}

.ds-link-image img {
    max-width: 100%;
}