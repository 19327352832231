.video-js {
    &.bc-player-AFS7XDlac_default,
    &.bc-player-5r2UlMxrqy_default,
    &.bc-player-49mZePJM5I_default,
    &.bc-player-default_default,
    &.bc-player-psDD7I3Ti7_default {
        background-color: transparent;
    }

    &.vjs-captions-on{
        button.vjs-subs-caps-button {
            &.vjs-subs-caps-button:not([disabled]):not(.disabled) {
                .vjs-icon-placeholder:before {
                    background: url("../images/svg-icons/ds-video-cc-on.svg") no-repeat center;
                    content: "";
                }
            }
        }
    }

    .vjs-poster {
        background-color: transparent;
    }

    .vjs-big-play-button,
    .vjs-progress-control,
    .vjs-time-control,
    .vjs-volume-control,
    .vjs-picture-in-picture-control,
    .vjs-fullscreen-control,
    .vjs-dock-text,
    .vjs-title-bar,
    .vjs-custom-control-spacer.vjs-spacer {
        display: none;
    }

    .vjs-control-bar {
        background-color: transparent;
        justify-content: flex-end;
        display: flex;
        padding-left: 32px;
        bottom: 40px;
        height: 32px;
        padding-right: 8px;

        .vjs-volume-panel {
            &.vjs-hover {
                width: 40px;
                transition: none;
            }

            &.vjs-volume-panel-vertical {
                width: 40px;
            }
        }
    }

    .vjs-play-control,
    .vjs-mute-control,
    .vjs-volume-panel,
    .vjs-subs-caps-button {
        width: 40px;
        height: 40px;
        max-height: 40px;
        text-shadow: none;
        margin-right: 24px;
    }

    button {
        &.vjs-subs-caps-button,
        &.vjs-mute-control,
        &.vjs-play-control {
            &:not([disabled]):not(.disabled) {

                &:hover,
                &:focus,
                &:active {
                    background-color: transparent;
                }

                &:focus,
                &:active {
                    border-radius: 25px;
                    outline-offset: -1px;
                }

                .vjs-icon-placeholder {
                    background-color: $bg-inverse-primary;
                    border-radius: 100%;
                    box-sizing: border-box;
                    display: block;
                    margin: 0 auto;
                    height: 32px;
                    width: 32px;

                    &:before {
                        background-position: 80% 80%;
                    }

                    &:focus {
                        outline: $border-dark dotted 1px !important;
                        outline-offset: 2px;
                        background-color: $bg-inverse-secondary;
                    }

                    &:hover {
                        background-color: $bg-inverse-secondary;
                    }

                    &:active {
                        background-color: $bg-tertiary;
                    }
                }
            }
        }

        &.vjs-play-control,
        &.vjs-paused {
            &:not([disabled]):not(.disabled) {
                .vjs-icon-placeholder:before {
                    background: url("../images/svg-icons/ds-video-play.svg") no-repeat center;
                    content: "";
                    background-position: 50% 50%;
                }
            }

            &.vjs-ended {
                &:not([disabled]):not(.disabled) .vjs-icon-placeholder:before {
                    background: url("../images/svg-icons/ds-video-replay.svg") no-repeat center;
                    content: "";
                }
            }
        }

        &.vjs-playing:not([disabled]):not(.disabled) {
            .vjs-icon-placeholder:before {
                background: url("../images/svg-icons/ds-video-pause.svg") no-repeat center;
                content: "";
            }
        }

        &.vjs-mute-control:not([disabled]):not(.disabled) {
            .vjs-icon-placeholder:before {
                background: url("../images/svg-icons/ds-video-volume.svg") no-repeat center;
                content: "";

                &:active {
                    background: url("../images/svg-icons/ds-video-mute-active.svg") no-repeat center;
                    content: "";
                }
            }


            &.vjs-vol-0 {
                .vjs-icon-placeholder:before {
                    background: url("../images/svg-icons/ds-video-mute.svg") no-repeat center;
                    content: "";
                }

                &:active .vjs-icon-placeholder:before{
                    background: url("../images/svg-icons/ds-video-mute-active.svg") no-repeat center;
                    content: "";
                }

                &:hover .vjs-icon-placeholder:before,
                &:focus .vjs-icon-placeholder:before {
                    background: url("../images/svg-icons/ds-video-mute-hover-and-focus.svg") no-repeat center;
                    content: "";
                }
            }
        }

        &.vjs-subs-caps-button:not([disabled]):not(.disabled),
        &:not([disabled]):not(.disabled) .vjs-subs-caps-button {
            flex: none;
            padding: 0;
        }

        &.vjs-subs-caps-button:not([disabled]):not(.disabled) {
            .vjs-icon-placeholder:before {
                background: url("../images/svg-icons/ds-video-cc-off.svg") no-repeat center;
                content: "";
            }

            &:active .vjs-icon-placeholder:before {
                background: url("../images/svg-icons/ds-video-cc-off-active.svg") no-repeat center;
                content: "";
            }

            &:hover .vjs-icon-placeholder:before,
            &:focus .vjs-icon-placeholder:before {
                background: url("../images/svg-icons/ds-video-cc-off-focus-and-hover.svg") no-repeat center;
                content: "";
            }
        }
    }

    div.vjs-subs-caps-button.vjs-menu-button-popup .vjs-menu {
        bottom: 7px;
        left: -7.25em;

        .vjs-menu-content {
            .vjs-texttrack-settings {
                display: none;
            }

            .vjs-menu-item {
                font-size: 0.875rem;
                list-style: none;
                margin-bottom: 0;
                padding-left: 6px;
                padding-right: 6px;
                text-align: left;
            }
        }
    }

    &.bc-player-AFS7XDlac_default,
    &.bc-player-5r2UlMxrqy_default {
        min-height: 457px;
    }
}

.bc-player-49mZePJM5I_default,
.bc-player-default_default,
.bc-player-psDD7I3Ti7_default,
.bc-player-AFS7XDlac_default,
.bc-player-5r2UlMxrqy_default {
    &.vjs-hide-controls.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
    &.vjs-hide-controls.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
    &.vjs-hide-controls.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
    &.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
        transform: translateY(7em);
        transition: transform 1s ease 0s;
    }
}
