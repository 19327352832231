.ui-widget-overlay {
    background: $night-rider;
    @include opacity(0.6);
    z-index: 150 !important;
}

.ui-dialog {
    background-color: $white;
    padding: 0;
    z-index: 151 !important;
    .ui-widget-header {
        border: 0 none;
        border-radius: 0;
        text-transform: uppercase;
        padding: 0;
    }
    .ui-dialog-title {
        @include ds-heading(h4);
        color: $text-primary;
        display: block;
        margin: calculateEm(35px);
        margin-bottom: calculateEm(24px);
        border-bottom: 3px solid $bg-color;
        text-transform: capitalize;
        padding-bottom: calculateEm(6px);
        width: 85%;
        
        @include respond(medium-down){
            margin: calculateRem(9px) calculateRem(16px) calculateRem(20px);
            padding-bottom: calculateRem(3px);
        }
        @include respond(small){
            width: 75%;
        }
    }
    .ui-icon-closethick {
        background-image: url("../images/svg-icons/UI-Close-dark.svg");
        background-position: center;
        background-size: 15px;
        height: 34px;
        width: 34px;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        border: 1px solid $bg-color;
    }
    .ui-dialog-titlebar-close {
        background-color: $bg-color;
        border: 0 none;
        border-radius: 0;
        height: 35px;
        margin: 0;
        padding: 0;
        right: 1px;
        top: 0;
        width: 35px;
        z-index: 101;
        &:hover {
            span {
                background-image: url("../images/svg-icons/UI-Close-dark-hover.svg");
                background-color: $secondary-border;
                border: 1px solid $secondary-border;
            }
        }
        &:active{
            span {
                background-image: url("../images/svg-icons/UI-Close-dark.svg");
                background-color: $white;
                border: 1px solid $bg-color;
            }
        }
        span {
            margin: 0;
            padding: 0;
        }
        span.ui-button-text {
            font-size: 0px;
    	        color: transparent;
    	        text-indent: unset;
        }
    }
    h1 h2 {
        font-size: 1.8em;
        font-style: italic;
        font-weight: 400;
        margin: 5px 158px 5px 0;
    }
    .dialog-required {
        font-weight: 400;
        position: relative;
        text-transform: none;
    }
    .ui-dialog-content {
        padding: 3px 35px 35px;
    }
    @include respond(medium-down) {
        max-width: 98%;
        .ui-dialog-content {
            padding: .25em;
            height: auto !important;
            overflow: visible;
        }
    }

    @include respond(large) {
        max-width: 98%;
        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }
}

.no-close .ui-dialog-titlebar-close {
    display: none;
}

.ui-accordion .ui-accordion-content {
    padding: 0;
}

.ui-tabs {
    .ui-tabs-nav {
        height: 2.45em;
        padding: 0;
        li {
            border-color: $white;
            border-style: solid;
            border-width: 0 0 3px 0;
            &.ui-state-active {
                border-bottom-color: $citrus;
                a {
                    color: $black;
                    font-weight: 700;
                }
            }
            a {
                color: $nobel;
            }
        }
    }
    .ui-tabs-panel {
        background-color: $white;
        border: 1px solid $white;
        line-height: 1.5em;
        margin: 0;
        padding: 20px 10px;
        @include respond(large) {
            h3 {
                display: none;
            }
        }
    }
}

#vertical-carousel {
    height: 19rem;
    position: relative;
    overflow: hidden;
    ul {
        height: 10000em;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        li {
            float: left;
            height: 19rem;
            width: 100%;
            .capture-product-id {
                display: none;
            }
        }
    }
    .jcarousel-nav {
        color: $nobel;
        cursor: pointer;
        font-size: 0.9em;
        font-weight: 700;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        &:hover {
            color: $citrus;
        }
        &.inactive {
            display: none;
        }
    }
    .jcarousel-prev {
        bottom: 30px;
    }
    .jcarousel-next {
        bottom: 0;
    }
}

.ui-dialog.global-email-dialog {
    .ui-dialog-title {
        display: none; 
    }
}