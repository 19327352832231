#RegistrationForm .form-row {
	@include respond(medium-down) {
	    max-width: 100%;
	}
}
.pt_pospasswordlanding.short-header {
    .top-banner {
        border-bottom: 3px solid $white-smoke;
        @include respond(medium-down) {
            border-bottom: none;
            overflow: hidden;
        }
    }
}
.pos-heading {
    	color: #E71389;
    	font-family: "Gotham ExtraLight";
    	@include font-size(28px);
    	letter-spacing: 1.59px;
    	line-height: 34px;
    	text-align: center;
        @include respond(medium-down) {
	        margin-top: 0;
	    }
    	&__text {

    		@include font-size(16px);

    		text-align: center;
    		max-width: 400px;
    		margin: 0 auto 3em auto;
    		@include respond(medium-down) {
    			max-width: none;
    			margin: 0 2em 3em 2em;
    		}
    		b {

    			color:#333;
    		}
    	}
    	&__text:nth-child(2) {
    		margin-bottom: 15px;
    		@include respond(medium-down) {
    			width: 100%;
    		}
    	}

    .content-asset img {
    	    margin: 32px auto;
    	    max-width: 775px;
			margin-top: 32px;
			margin-bottom: 23px;
    	    @media only screen and (max-width: 958px) {
		    	width: 100%;
			}
			@include respond(medium-down) {
				margin-top: 0;
			}
    }

    &__title {
		color: #ED068C;

		font-weight: 300;
	    	@include font-size(36px);
	    	line-height: 44px;
	}
}
h2.pos-heading.success {
	margin-bottom: 0;
	img {
		margin-bottom: 0;
	}
	.pos-heading__title {
		margin-top: 22px;
		@include respond(medium-down) {
		    margin-top: 0;
		}
		line-height: 1.2;
    		letter-spacing: 1.6px;
	}
}
h2.pos-heading.success2 {
	margin-bottom: 28px;
	img {
		margin-bottom: 0;
	}
	.pos-heading__title {
		margin-top: 22px;
		@include respond(medium-down) {
		    margin-top: 0;
		}
		line-height: 1.2;
    		letter-spacing: 1.6px;
	}
}
.pos-body {
    text-align: center;
    border: 15px solid $brand-pink;
    padding: 20px 10px 20px 10px;
    position: relative;
    max-width: 775px;
    margin: 45px auto auto auto;
    box-sizing: border-box;

    &__bbw-icon {
        margin-top: -65px;
    		text-align: center;
    }

    &__header-text {
	    color: #333333;

	    font-size: 16px;
	    line-height: 19px;
	    text-align: center;
	    text-transform: none;
    		margin: 1em auto;

	    b {

	    }
	    &--top {
	        margin-top: 15px;
	    }
	}
	&__row {
        display: inline-block;
        vertical-align: text-top;
    }
    &__box-title {
    		color: #333333;

    		font-size: 18px;
    		letter-spacing: 0.52px;
    		line-height: 22px;
    		text-align: center;
    		text-transform: uppercase;
    }
    &__box-body {
    		width: 100%;
    		text-align: center;

    		.icon{
    			width: 100px;
    			height: 100px;
    			margin: 15px;
    		}
    		.accounticon{
    			width: 85px;
    			height: 85px;
    			margin: 25px;
    		}
    }
    &__box-cta {
    		width: 100%;
    		max-width: 290px;
    		margin: 0 auto;
    		padding: 0;

    		.apple-store{
    			float:left;
    		}
    		.google-store{
    			float:right;
    		}
    		@include respond(medium-down) {
	    		@supports (-webkit-overflow-scrolling: touch) {
			  	/* CSS specific to iOS devices */
			  	.google-store{
					display:none;
				}
				.apple-store{
		    			float:none;
		    		}
			}

			@supports not (-webkit-overflow-scrolling: touch) {
			  	/* CSS for other than iOS devices */
		  		.apple-store{
					display:none;
				}
				.google-store{
		    			float:none;
		    		}
			}
		}
    }
    .getapp, .myaccount {
    		width: 45%;
    		@include respond(medium-down) {
    			width: 100%;
    		}
    		vertical-align: text-top;
    }
    .spacer {
    		width: 9%;
    		background-image: url("../images/v-or-spacer.gif");
    		background-repeat: no-repeat;
    		background-position: center;
    		@include respond(medium-down) {
    			width: 100%;
    			height: 100px;
    			background-image: url("../images/h-or-spacer.gif");
    			background-repeat: no-repeat;
    			background-position: center;
    		}
    }
	form{
		text-align: left;
	}
	.dialog-required {
		width: 38.8%;
		@include respond(medium-down) {
		    width: initial;
		    margin-right: 0px !important;
		}
    		margin-top: 0.625rem;
	}
	.term-condition label {
		font-size: 0.75rem;

		span {
			display: block;
			padding-left: 10px;
		}
		span:nth-child(2){
			font-size: 0.7rem;
		}
		a {
			font-size: inherit;
			text-decoration: underline;
		}
	}
	.flex-grid {
		display: flex;
		flex-direction: row;
		@include respond(medium-down) {
		    flex-direction: column;
		}
		padding: 2em 2em 3em 2em;
	}
	.myaccount fieldset {
		width: 100%;
	}
	#RegistrationForm.form-horizontal .form-caption {
	   margin-top: 10px;
	}
	.form-row.image {
		margin-bottom: 7px;
	}
	#RegistrationForm {
		margin-bottom: 0;
	}
	#RegistrationForm .form-row {
		margin-right: 0;
		.field-wrapper {
		    padding-left: 0;
		}
		label {
		    padding-left: 0;
		}
		.form-caption {
		    padding-left: 0;
		}
	}
	.pw-requirements {
		@include respond(medium-down) {
			width: auto;
		}
	}
	.form-row-button button[type="submit"] {
	    width: 40%;
	}
}

p.pos-heading__text.success, p.pos-heading__text.success2 {
	color: #333;
 	line-height: 1.2;
    letter-spacing: 0.4px;
}
@include respond(medium-down) {
    p.pos-heading__text.success:nth-child(2){
        width: auto;
    }
    p.pos-heading__text.success2:nth-child(2){
        width: auto;
    }
}
.capitalize {
	display: inline-block;
	&::first-letter {
	    text-transform: capitalize;
	}
}
div.pos-body.success, div.pos-body.success2 {
    @include respond(medium-down) {
	    margin: 37px auto auto auto;
	}
}
div.pos-body.success .spacer, div.pos-body.success2 .spacer {
	background: none;
	position: relative;
	.line {
        border-left: 1px solid #E71389;
        width: 1px;
        height: 100%;
        position: absolute;
        left: 50%;
        @include respond(medium-down) {
            border-left: 0px solid #E71389;
		    border-top: 1px solid #E71389;
            width: 100%;
            height: 1px;
            left: inherit;
            top: 50%;
		}
	}
	.or {
	    color: #E71389;
	    width: 23px;
	    height: 18px;
	    background-color: #fff;

		font-weight: 800;
	    font-size: 14px;
	    letter-spacing: 0.52px;
	    line-height: 18px;
	    text-transform: uppercase;
	    position: absolute;
	    top: calc(50% - 9px);
	    left: calc(50% - 11px);
	}

}
.canceled-grace {
    .cg-heading {
        text-align: center;
        margin: 0 auto;

        h1 {
            font-weight: bold;
            font-size: 36px;
        }
    }
    .cg__text {
        color: $night-rider;
        font-size: 20px;
        letter-spacing: 0.1px;
        line-height: 28px;
        text-align: center;
        margin-top: 4px;

        p {
            max-width: 744px;
            span {
                font-weight: bold;
            }
        }
    }
    .content-asset img {
        margin: 32px auto;
        max-width: 775px;
        margin-top: 32px;
        margin-bottom: 8px;
        @media only screen and (max-width: 958px) {
            width: 100%;
        }
        @include respond(medium-down) {
            margin-top: 0;
        }
    }
    .cg-body__box-cta {
        width: 100%;
        max-width: 261px;
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        display: block;
        margin-top: 41px;
    }
    .pos-body {
        margin-top: 58px;
    }
    &.success {
        .cg__image {
            @include respond(medium-down) {
                width: 89.58%;
                margin: 0 auto;
                margin-top: 26px;
                margin-bottom: 8px;
            }
        }
        .cg-body__box-cta {
            margin-top: 26px;
        }
        .cg__text {
            padding: 0 10px;
        }
    }
    &.expired {
        .content-asset img {
            @include respond(medium-down) {
                margin-top: 0;
            }
        }
        .cg__image {
            @include respond(medium-down) {
                width: 23.54%;
                margin: 0 auto;
                margin-top: 13px;
                margin-bottom: 11px;
            }
        }
        .cg__text {
            padding: 0 15px;
            max-width: 775px;
            margin: 0 auto;
            margin-bottom: 54px;
        }
    }
}
