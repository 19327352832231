.svg-Alert-24-red-dims {
	width: 24px;
	height: 24px;
}

.svg-Alert-Check-dims {
	width: 612px;
	height: 792px;
}

.svg-Alert-Error-dims {
	width: 612px;
	height: 792px;
}

.svg-Badge_Dt-dims {
	width: 340px;
	height: 340px;
}

.svg-Badge_mb-dims {
	width: 340px;
	height: 81px;
}

.svg-Caret-dims {
	width: 14px;
	height: 7px;
}

.svg-Caret-left-dims {
	width: 14px;
	height: 26px;
}

.svg-Caret-right-dims {
	width: 14px;
	height: 26px;
}

.svg-Caret_Bold-dims {
	width: 9px;
	height: 5px;
}

.svg-Caret_Bold_Grey-dims {
	width: 9px;
	height: 5px;
}

.svg-Caret_Grey-dims {
	width: 14px;
	height: 7px;
}

.svg-Carousel-1-dims {
	width: 612px;
	height: 792px;
}

.svg-Carousel-2-dims {
	width: 612px;
	height: 792px;
}

.svg-Carousel-3-dims {
	width: 612px;
	height: 792px;
}

.svg-Carousel-pause-dims {
	width: 612px;
	height: 792px;
}

.svg-Carousel-play-dims {
	width: 612px;
	height: 792px;
}

.svg-FragranceAZ-dims {
	width: 612px;
	height: 792px;
}

.svg-Guarantee-dims {
	width: 269px;
	height: 61px;
}

.svg-Happiness_Guaranteed_left-dims {
	width: 379.3px;
	height: 76.7px;
}

.svg-Happiness_Guaranteed_right-dims {
	width: 381.2px;
	height: 76.3px;
}

.svg-Heart-dims {
	width: 40px;
	height: 40px;
}

.svg-Logos-Firstlook-experience-dims {
	width: 612px;
	height: 792px;
}

.svg-Logos-Firstlook-plain-dims {
	width: 612px;
	height: 792px;
}

.svg-Logos-Firstlook-plain2-dims {
	width: 74.3px;
	height: 17.4px;
}

.svg-Logos-Firstlook-plain3-dims {
	width: 74.3px;
	height: 17.4px;
}

.svg-Logos-Firstlook-plain4-dims {
	width: 74.3px;
	height: 17.4px;
}

.svg-Logos-WhiteBarn-dims {
	width: 612px;
	height: 162px;
}

.svg-Logos-WhiteBarn-White-dims {
	width: 612px;
	height: 162px;
}

.svg-Logos-main-dims {
	width: 517.2px;
	height: 50px;
}

.svg-Logos-main-stacked-dims {
	width: 73px;
	height: 60px;
}

.svg-Logos-main-v2-dims {
	width: 149.75px;
	height: 14.21px;
}

.svg-PDP-Arrow-1-dims {
	width: 384.9px;
	height: 792px;
}

.svg-PDP-Arrow-2-dims {
	width: 384.9px;
	height: 792px;
}

.svg-PDP-Wishlist-1-dims {
	width: 40px;
	height: 40px;
}

.svg-PDP-Wishlist-2-dims {
	width: 40px;
	height: 40px;
}

.svg-PDP-Wishlist-3-dims {
	width: 40px;
	height: 40px;
}

.svg-PDP-Wishlist-4-dims {
	width: 40px;
	height: 40px;
}

.svg-PDP-Wishlist-5-dims {
	width: 40px;
	height: 40px;
}

.svg-Pagination-Arrow-1-dims {
	width: 384.9px;
	height: 792px;
}

.svg-Pagination-Arrow-2-dims {
	width: 384.9px;
	height: 792px;
}

.svg-Path-dims {
	width: 13px;
	height: 13px;
}

.svg-Path-icon-default-dims {
	width: 13px;
	height: 13px;
}

.svg-Payment-VS-dims {
	width: 39px;
	height: 26px;
}

.svg-Search-dims {
	width: 15px;
	height: 15px;
}

.svg-ShippingTruck-dims {
	width: 612px;
	height: 792px;
}

.svg-Social-fb-dims {
	width: 7.4px;
	height: 16px;
}

.svg-Social-fb-black-dims {
	width: 24px;
	height: 24px;
}

.svg-Social-fb-reverse-dims {
	width: 40px;
	height: 40px;
}

.svg-Social-ig-dims {
	width: 512px;
	height: 512px;
}

.svg-Social-ig-black-dims {
	width: 25px;
	height: 25px;
}

.svg-Social-ig-reverse-dims {
	width: 32px;
	height: 32px;
}

.svg-Social-pinterest-dims {
	width: 12.4px;
	height: 16px;
}

.svg-Social-pinterest-black-dims {
	width: 25px;
	height: 25px;
}

.svg-Social-pinterest-reverse-dims {
	width: 40px;
	height: 40px;
}

.svg-Social-tiktok-black-dims {
	width: 25px;
	height: 25px;
}

.svg-Social-tiktok-reverse-dims {
	width: 150px;
	height: 150px;
}

.svg-Social-twitter-dims {
	width: 16px;
	height: 13px;
}

.svg-Social-twitter-black-dims {
	width: 25px;
	height: 25px;
}

.svg-Social-twitter-reverse-dims {
	width: 40px;
	height: 40px;
}

.svg-Social-x-black-dims {
	width: 24px;
	height: 24px;
}

.svg-Social-youtube-dims {
	width: 15.5px;
	height: 15.7px;
}

.svg-Social-youtube-black-dims {
	width: 25px;
	height: 25px;
}

.svg-Social-youtube-reverse-dims {
	width: 40px;
	height: 40px;
}

.svg-Success-16-green-dims {
	width: 16px;
	height: 16px;
}

.svg-UI-AddToBag-dims {
	width: 603.1px;
	height: 584px;
}

.svg-UI-AddToBag-Minicart-dims {
	width: 666px;
	height: 666px;
}

.svg-UI-AddToBag-v2-dims {
	width: 23.95px;
	height: 21.85px;
}

.svg-UI-Carat-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Carat-active-dims {
	width: 6px;
	height: 11px;
}

.svg-UI-Carat-active-v2-dims {
	width: 6.02px;
	height: 11.34px;
}

.svg-UI-Carat-blue-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Carat-bold-dims {
	width: 16px;
	height: 10px;
}

.svg-UI-Carat-v2-dims {
	width: 4.69px;
	height: 9.89px;
}

.svg-UI-Close-blue-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-blue-click-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-blue-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-dark-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-dark-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-gc-dark-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-gc-dark-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-green-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-green-click-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-green-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Close-light-dims {
	width: 17px;
	height: 17px;
}

.svg-UI-Close-nocolor-dims {
	width: 15.98px;
	height: 15.97px;
}

.svg-UI-Close-v2-dims {
	width: 15.98px;
	height: 15.97px;
}

.svg-UI-GlobalLocator-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Information-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Information-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Locator-Pin1-dims {
	width: 612px;
	height: 737.9px;
}

.svg-UI-Locator-Pin2-dims {
	width: 612px;
	height: 737.9px;
}

.svg-UI-Menu-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Menu-old-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Menu-v2-dims {
	width: 16.73px;
	height: 17.46px;
}

.svg-UI-MyAccount-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-MyAccount-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-MyAccount-v2-dims {
	width: 20.79px;
	height: 20.94px;
}

.svg-UI-MyAccount-v2-active-dims {
	width: 20.79px;
	height: 20.94px;
}

.svg-UI-Open-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-Pagination-double-dims {
	width: 298px;
	height: 392.7px;
}

.svg-UI-Pagination-single-dims {
	width: 198px;
	height: 392.6px;
}

.svg-UI-Rewards-badge-dims {
	width: 23px;
	height: 31px;
}

.svg-UI-Search-dims {
	width: 603.1px;
	height: 584px;
}

.svg-UI-Search-v2-dims {
	width: 18.97px;
	height: 18.39px;
}

.svg-UI-Search-v3-dims {
	width: 603.1px;
	height: 584px;
}

.svg-UI-StoreLocator-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-StoreLocator-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-StoreLocator-v2-dims {
	width: 17.95px;
	height: 24.54px;
}

.svg-UI-StoreLocator-v2-active-dims {
	width: 17.95px;
	height: 24.54px;
}

.svg-UI-Tag-dims {
	width: 612px;
	height: 757px;
}

.svg-UI-Tag-v2-dims {
	width: 20.35px;
	height: 29.31px;
}

.svg-UI-Tag-v2-active-dims {
	width: 20.35px;
	height: 29.31px;
}

.svg-UI-viewoption-1-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-viewoption-1-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-viewoption-2-dims {
	width: 612px;
	height: 792px;
}

.svg-UI-viewoption-2-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-alert-error-triangle-dims {
	width: 32px;
	height: 32px;
}

.svg-alert-triangle-filled-dims {
	width: 24px;
	height: 24px;
}

.svg-app-icon-dims {
	width: 80px;
	height: 80px;
}

.svg-app-overview-alert-icn-dims {
	width: 76px;
	height: 76px;
}

.svg-app-overview-earn-icn-dims {
	width: 114.52px;
	height: 103.34px;
}

.svg-app-overview-exclusive-icn-dims {
	width: 54px;
	height: 112.11px;
}

.svg-app-overview-scan-icn-dims {
	width: 98px;
	height: 66px;
}

.svg-app-overview-shop-icn-dims {
	width: 76px;
	height: 76px;
}

.svg-app-overview-wallet-icn-dims {
	width: 69px;
	height: 61.34px;
}

.svg-appstore-icon-dims {
	width: 135px;
	height: 40px;
}

.svg-arrow-jatc_l-dims {
	width: 16px;
	height: 28px;
}

.svg-arrow-jatc_l-active-dims {
	width: 16px;
	height: 28px;
}

.svg-arrow-jatc_l-hover-dims {
	width: 16px;
	height: 28px;
}

.svg-arrow-jatc_r-dims {
	width: 16px;
	height: 28px;
}

.svg-arrow-jatc_r-active-dims {
	width: 16px;
	height: 28px;
}

.svg-arrow-jatc_r-hover-dims {
	width: 16px;
	height: 28px;
}

.svg-arrow-left-blue-dims {
	width: 14px;
	height: 9px;
}

.svg-arrow-left-dark-grey-dims {
	width: 14px;
	height: 9px;
}

.svg-arrows-left-dims {
	width: 612px;
	height: 792px;
}

.svg-arrows-left-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-arrows-right-dims {
	width: 612px;
	height: 792px;
}

.svg-arrows-right-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-arrows-up-dims {
	width: 19.1px;
	height: 31.5px;
}

.svg-arrows-up-hover-dims {
	width: 612px;
	height: 792px;
}

.svg-autopopulate-arrow-icon-active-dims {
	width: 12px;
	height: 12px;
}

.svg-autopopulate-arrow-icon-focus-dims {
	width: 12px;
	height: 12px;
}

.svg-autopopulate-arrow-icon-hover-dims {
	width: 12px;
	height: 12px;
}

.svg-autorefresh-jatc-dims {
	width: 16px;
	height: 16px;
}

.svg-badge-1-dims {
	width: 35.9px;
	height: 16.5px;
}

.svg-badge-1-Full-dims {
	width: 612px;
	height: 608px;
}

.svg-badge-2-dims {
	width: 11.5px;
	height: 10.4px;
}

.svg-badge-2-Full-dims {
	width: 612px;
	height: 606.8px;
}

.svg-badge-3-dims {
	width: 17.5px;
	height: 16.7px;
}

.svg-badge-3-Full-dims {
	width: 612px;
	height: 609.3px;
}

.svg-badge-4-dims {
	width: 20.3px;
	height: 15.9px;
}

.svg-badge-4-Full-dims {
	width: 612px;
	height: 613px;
}

.svg-badge-5-Full-dims {
	width: 612px;
	height: 610.2px;
}

.svg-baseline-launch-24px-dims {
	width: 24px;
	height: 24px;
}

.svg-bbw_app_icon-dims {
	width: 80px;
	height: 80px;
}

.svg-bbw_app_icon_2-dims {
	width: 113px;
	height: 113px;
}

.svg-bbwd-tooltip-close-dims {
	width: 16px;
	height: 16px;
}

.svg-bbwd-tooltip-close-active-dims {
	width: 15.1px;
	height: 15.2px;
}

.svg-bbwd-tooltip-close-hover-dims {
	width: 15.4px;
	height: 15.3px;
}

.svg-bopis-icon-16x16-dims {
	width: 16px;
	height: 16px;
}

.svg-bopis-icon-large-dims {
	width: 24px;
	height: 31px;
}

.svg-bopis-icon-small-dims {
	width: 18px;
	height: 22px;
}

.svg-bopis-shipping-dims {
	width: 25px;
	height: 14px;
}

.svg-button-spinner-dims {
	width: 16px;
	height: 16px;
}

.svg-cc-types-single-images-dims {
	width: 212px;
	height: 26px;
}

.svg-check-circle-fill-dims {
	width: 24px;
	height: 24px;
}

.svg-checkmark-jatc-dims {
	width: 23.11px;
	height: 16px;
}

.svg-chevron-dims {
	width: 16px;
	height: 16px;
}

.svg-chevron-24-r-dims {
	width: 25px;
	height: 25px;
}

.svg-chevron-active-24-r-dims {
	width: 24px;
	height: 24px;
}

.svg-close-dims {
	width: 16px;
	height: 16px;
}

.svg-close-blue-dims {
	width: 16px;
	height: 16px;
}

.svg-close-click-dims {
	width: 16px;
	height: 16px;
}

.svg-close-hover-dims {
	width: 16px;
	height: 16px;
}

.svg-close-without-border-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-carousel-next-button-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-carousel-next-button-active-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-carousel-next-button-disabled-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-carousel-next-button-hover-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-carousel-prev-button-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-carousel-prev-button-active-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-carousel-prev-button-disabled-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-carousel-prev-button-hover-dims {
	width: 28px;
	height: 28px;
}

.svg-ds-video-cc-off-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-cc-off-active-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-cc-off-focus-and-hover-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-cc-on-dims {
	width: 24px;
	height: 25px;
}

.svg-ds-video-mute-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-mute-active-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-mute-hover-and-focus-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-pause-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-play-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-replay-dims {
	width: 24px;
	height: 24px;
}

.svg-ds-video-volume-dims {
	width: 24px;
	height: 24px;
}

.svg-edit-dims {
	width: 17px;
	height: 17px;
}

.svg-external-open-dims {
	width: 25px;
	height: 24px;
}

.svg-filter-button-icon-dims {
	width: 15px;
	height: 12px;
}

.svg-filter-carat-dims {
	width: 15px;
	height: 9px;
}

.svg-free-product-icn-dims {
	width: 64.64px;
	height: 58.14px;
}

.svg-giftbox-dims {
	width: 21px;
	height: 21px;
}

.svg-googleplay-icon-dims {
	width: 134px;
	height: 41px;
}

.svg-history-24-dims {
	width: 24px;
	height: 24px;
}

.svg-in-store-dims {
	width: 30px;
	height: 30px;
}

.svg-in-store-online-dims {
	width: 30px;
	height: 32px;
}

.svg-info-dims {
	width: 32px;
	height: 32px;
}

.svg-instacart-dims {
	width: 87px;
	height: 14px;
}

.svg-its-great-to-be-a-m-dims {
	width: 290px;
	height: 47px;
}

.svg-its-great-to-be-a-member-dims {
	width: 290px;
	height: 47px;
}

.svg-left-arrow-dims {
	width: 9px;
	height: 18px;
}

.svg-lock-dims {
	width: 25px;
	height: 25px;
}

.svg-lock-black-dims {
	width: 11px;
	height: 12px;
}

.svg-lock-filled-dims {
	width: 24px;
	height: 25px;
}

.svg-magnifying-glass-dims {
	width: 15px;
	height: 15px;
}

.svg-megamenu-carat-dims {
	width: 6px;
	height: 11px;
}

.svg-megamenu-carat_active-dims {
	width: 8px;
	height: 13px;
}

.svg-megamenu-mobile_primary-carat-dims {
	width: 17px;
	height: 10px;
}

.svg-megamenu-mobile_secondary-carat-dims {
	width: 12px;
	height: 6px;
}

.svg-my-account-icon-dims {
	width: 90px;
	height: 90px;
}

.svg-my-rewards-logo-lockup-dims {
	width: 180px;
	height: 48px;
}

.svg-offer-icn-dims {
	width: 27.59px;
	height: 57.88px;
}

.svg-offers-icon-dims {
	width: 30px;
	height: 30px;
}

.svg-og-icon-dims {
	width: 80px;
	height: 80px;
}

.svg-online-dims {
	width: 30px;
	height: 30px;
}

.svg-pagination-arrow-dims {
	width: 8px;
	height: 14px;
}

.svg-pagination-double-arrow-dims {
	width: 16px;
	height: 14px;
}

.svg-popular-searches-icon-dims {
	width: 15px;
	height: 9px;
}

.svg-question-icn-dims {
	width: 197.63px;
	height: 188px;
}

.svg-question-mark-dims {
	width: 15px;
	height: 15px;
}

.svg-question-mark-blue-dims {
	width: 15px;
	height: 15px;
}

.svg-question-mark-dim-gray-dims {
	width: 15px;
	height: 15px;
}

.svg-question-mark-invert-dims {
	width: 15px;
	height: 15px;
}

.svg-question-mark-invert-blue-dims {
	width: 15px;
	height: 15px;
}

.svg-question-mark-invert-dim-gray-dims {
	width: 15px;
	height: 15px;
}

.svg-quicklook-eye-dims {
	width: 15px;
	height: 9px;
}

.svg-remove-search-icon-active-dims {
	width: 11px;
	height: 11px;
}

.svg-remove-search-icon-default-dims {
	width: 11px;
	height: 11px;
}

.svg-remove-search-icon-focus-dims {
	width: 11px;
	height: 11px;
}

.svg-remove-search-icon-hover-dims {
	width: 11px;
	height: 11px;
}

.svg-reward-count-background-dims {
	width: 40px;
	height: 48px;
}

.svg-right-arrow-dims {
	width: 9px;
	height: 18px;
}

.svg-search-history-icon-dims {
	width: 15px;
	height: 15px;
}

.svg-search-icon-dims {
	width: 21px;
	height: 20px;
}

.svg-search-icon-blue-dims {
	width: 21px;
	height: 20px;
}

.svg-search-icon-blue-new-dims {
	width: 20px;
	height: 20px;
}

.svg-search-icon-light-dims {
	width: 21px;
	height: 20px;
}

.svg-show-dims {
	width: 25px;
	height: 15px;
}

.svg-show-none-dims {
	width: 25px;
	height: 23px;
}

.svg-sorry-dims {
	width: 138px;
	height: 88px;
}

.svg-tooltip-active-dims {
	width: 30px;
	height: 30px;
}

.svg-tooltip-default-dims {
	width: 26px;
	height: 26px;
}

.svg-tooltip-focus-dims {
	width: 30px;
	height: 30px;
}

.svg-tooltip-hover-dims {
	width: 26px;
	height: 26px;
}

.svg-top-badge-lrg-2-dims {
	width: 16px;
	height: 17px;
}

.svg-ui-rewards-badge2-dims {
	width: 22px;
	height: 31px;
}

.svg-wallet-icn-dims {
	width: 24.64px;
	height: 22.73px;
}

.svg-wallet-icon-dims {
	width: 14px;
	height: 13px;
}

.svg-we-are-glad-you-stay-dims {
	width: 523px;
	height: 90px;
}

