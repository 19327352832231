body {
    background: none repeat scroll 0 0 $white;
    color: $primary-color;
    font-family: $source-sans-pro;
    font-size: 12px;
}
/* prevent scrolling screen when fixed elements get focus*/
/*html,body{
    -webkit-overflow-scrolling : touch;
    overflow: auto;
}*/
@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi){
    /* Retina-specific aliasing */
    body {
        -webkit-font-smoothing: antialiased;
    }
}

p{
    @include font-regular(14px);
    color: $primary-color;
    @include line-height(24px);

    a{
        @include font-regular(14px);
        color: $secondary-color;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}
div,
img {
    @include respond(medium-down) {
        max-width: 100%;
    }
}

a {
    color: $primary-color;
    outline: medium none;
    overflow: hidden;
    text-decoration: none;
    
    &.underlined {
        text-decoration: underline;
    }
}

fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

td {
    padding: 1em;
    vertical-align: top;
}
.center{
    text-align: center;
    &.small{
        margin-top: 0;
    }
}
.small{
    @include font-size(11px);
}

.bold {

    color: $night-rider;
}

.slot-banner{
    margin-bottom: 27px;
    @include respond(medium-down){
        text-align: center;
        padding: 0 15px;
        &.account-top-banner {
            padding: 0;
        }
    }
    img{
        max-width: 100%;
    }
    a{
        &:active{
            color: $cobalt;
        }
        @include respond(large-up){
            text-decoration: none;
            &:hover{
                color: $cobalt;
            }
            &:active{
                color: $click;
            }
        }
    }
    &.bottom{
        margin-top: 0px;
        @extend %clearfix;
        @include respond(medium-down){
            padding: 0 15px;
            text-align: left;
            margin-bottom: 30px;
        }
        h3{
            @include font-regular(24px);
            @include line-height(24px);
            margin-bottom: 10px;
            text-transform: none;
            letter-spacing: 0.25px;
            @include respond(medium-down){
                @include font-regular(20px);
                @include line-height(20px);
            }
        }
        > p{
            float: left;
            width: 25%;
            padding-right: 3%;
            @include borderbox;
            @include respond(medium-down){
                float: none;
                width: 100%;
                @include line-height(18px);
                text-decoration: none;
            }
            strong{
                display: block;
                @include font-bold(14px);
                color: $secondary-color;
                @include respond(medium-down){
                    @include font-bold(12px);
                    text-transform: uppercase;
                }
            }
        }
    }
}
button,
.button,
.buttonstyle,
input[type="button"] {
    background-color: $black;
    border-color: darken($citrus, 10%);
    border-style: solid;
    border-width: 1px;
    color: $secondary-color;
    cursor: pointer;
    display: inline-block;
    @include ds-utilities-button-labels;
    padding: 0 calculateEm(20px);
    text-align: center;
    transition: all 0.3s ease;
    &.large {
        font-size: 1.35em;
        text-transform: uppercase;
    }
    &.raised {
        border-width: 0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        &:hover {
            background-color: darken($citrus, 10%);
            color: $white;
        }
    }
    &.simple {
        background-color: $white;
        color: darken($citrus, 10%);
    }
    &[disabled],
    &.disabled {
        background-color: $very-light-gray;
        border-color: $very-light-gray;
        color: $dim-gray;
        @include opacity(0.5);
        &:hover, &:active {
            background-color: $very-light-gray;
            border-color: $very-light-gray;
        }
    }
    a {
        color: $white;
        &:hover {
            color: $citrus;
        }
    }
    i {
        &.left {
            margin-right: 0.6em;
        }
        &.right {
            margin-left: 0.6em;
        }
    }
}
textarea:focus, input:focus, a:focus, button:focus, select:focus{
    outline:#000 dotted 1px!important;  
    outline-offset: 2px;
}
div[tabindex="0"]:focus, h2[tabindex="0"]:focus, legend[tabindex="0"]:focus, h3[tabindex="0"]:focus, li[tabindex="0"]:focus, span[tabindex="0"]:focus{
	outline:#000 dotted 1px!important;  
    outline-offset: 2px;
}
.promo-banner-click-layer:focus{
	outline-offset: -2px;
}

.button-fancy-small {}

.button-fancy-medium {}

.button-fancy-large {
    @extend .button.large;
}

.button-text {
    background-color: transparent;
    border-width: 0;
    color: $primary-color;
    padding: 0;
    letter-spacing: 0;
    &:hover, &:active {
        background-color: transparent;
        text-decoration: underline;
    }
}

.button-full-width {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
}

/*Custom scrollbar*/
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-corner {
    background-color: $bg-color;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $click;
}
::-webkit-scrollbar-track {
    background-color: $bg-color;
}
::-webkit-scrollbar-track-piece {
    background-color: $bg-color;
}
html, body {
    scrollbar-track-color: $bg-color;
    scrollbar-darkshadow-color: $bg-color;
    scrollbar-3dlight-color: $bg-color;
    scrollbar-highlight-color: $bg-color;
    scrollbar-shadow-color: $bg-color;
    scrollbar-arrow-color: $click;
    scrollbar-face-color: $click;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.js {
    .loader {
        background: $white;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 150;
        @include opacity(0.8);
        @include respond(medium-down){
            position: fixed;
        }
    }
    .loader-indicator {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        background: url("../images/spinner-rewards.gif") no-repeat;
        background-size: 32px;
        margin-left: -16px;
        z-index: 1;
        @include respond(medium-down){
            margin-top: -16px;
        }
    }
}
.screen-reader-content {
    opacity:0;
    height:0;
    margin:0;
    padding:0;
}
@include respond(medium-down){
	.mobile-hidden {
		display: none;
	}
}