.ds-top-offers-container{
    display: flex;
    flex-flow: row nowrap;
    scrollbar-width: none;
    overflow: auto hidden;
    column-gap: 16px;
    padding: 0 16px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.ds-top-offers {
    background: $white-smoke;
    padding-bottom: 24px;

    .ds-marketing-copy-heading {
        padding: 32px 16px 8px;
        position: relative;
    }
    
}

.ds-top-offers-slide {
    margin: 8px 0;
}

.ds-top-offers-tile-copy {
    color: $text-primary;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    justify-content: center;
    padding: 16px;
}

.ds-top-offers-slide {
    .ds-top-offers-tile-content {
        &--image {
            display: grid;
            grid-template-columns: 96px 1fr;
            padding: 16px;
            gap: 16px;
            .ds-link-button {
                margin-top: 0;
                display: flex;
                align-items: flex-end;
                width: 100%;
                grid-column: 1/3;
                padding: 0;
                box-sizing: border-box;
                .ds-false-link-button {
                    width: 100%;
                }
            }
            .ds-top-offers-tile-copy {
                padding: 0;
                align-items: flex-start;
                justify-content: flex-start;
                div {
                    text-align: left;
                }
            }
            .ds-top-offers-image {
                position: relative;
                display: inline-block;
                width: 96px;
                height: 96px;
                img {
                    width: 96px;
                    height: 96px;
                    position: relative;
                    display: inline-block;
                    object-fit: cover;
                    overflow: hidden;
                }
            }
        }

    }
}

@media screen and (min-width: $mMin) {
    .ds-top-offers {
        padding-bottom: 32px;
        .ds-marketing-copy-heading {
            margin: 0 auto;
            max-width: 568px;
        }
    }
    
    .ds-top-offers-container {
        overflow: auto;
        overflow-y: hidden;
        scrollbar-width: auto;
        column-gap: 16px;
        padding: 0 0 10px 0;
        margin: 0 16px;
        scrollbar-color: $bg-tertiary rgba($bg-tertiary, 0.25);
    
        &::-webkit-scrollbar {
            display: initial;
            width: 8px;
            height: 8px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: $bg-tertiary;
        }

        &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
            background: rgba($bg-tertiary, 0.25);
            border-radius: 8px;
        }
    }

    .ds-top-offers-tile-copy {
        padding: 32px 32px 0;
    }
}

@media screen and (min-width: $lMin){
    .ds-top-offers-container{
        column-gap: 24px;
    }
}

@media screen and (min-width: $xlMin) {
    .ds-top-offers {
        .ds-top-offers-container {
            column-gap: 32px;
        }
    }
}
