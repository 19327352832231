.unsupported-browser {
    text-align: center;
    h2 {
        margin: 0;
        text-transform: none;
        @include font-size(24px);
        @include line-height(27px);
    }
    p {
        margin: 4px 0 46px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        width: 780px;
        li {
            display: inline-block;
            list-style: none;
            vertical-align: top;
            padding: 0 33px;
            float: left;
            div {
                margin-top: 4px;
            }
            a {
                @include font-size(14px);
                @include line-height(20px);
                color: $cobalt;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
                &:active {
                    color: $click;
                }
            }
        }
    }
}
.oldie {
	background: $bg-color;
}
#wrapper {
	&.pt_unsupported {
        background: $bg-color;
        zoom: 1;
        width: 100%;
	    height: 100%;
	    left: 0;
	    top: 0;
	    position: absolute;
	    .unsupported-top-banner {
		    width: 100%;
		    text-align: center;
		    background: $white;
		    padding: 42px 0;
		}
		img {
		    vertical-align: top;
		}
	    #main {
            padding-top: 44px;
            background: $bg-color;
	    }
    }
}

.unsupported-dialog {
	&.mobile {
	    .dialog-content {
	        padding: 44px 20px !important;
	    }
		.unsupported-browser {
		    h2 {
		        margin: 0;
		        text-align: center;
		        text-transform: none;
		        @include font-size(24px);
		        @include line-height(27px);
		    }
		    p {
		        margin: 20px 0;
		    }
	    }
	}
	.ui-widget-header {
        .ui-dialog-title {
            display: none;
        }
    }
    .dialog-content {
        padding: 44px 0!important;
    }
}

