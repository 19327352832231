.pt_customer-service .page-content .primary-content {

    /**
      * MAIN COMPONENTS
      -----------------------------------**/
    h1,
    .customer-care-content {
    	h3 {

	        @include line-height(24px);
	        margin-bottom: 1em;
	        padding-right: 30%;
	        padding-bottom: .75em;
	        @include respond(large-down) {
	            @include font-size(24px);
	        }
	    }
    }
    h1 {
        padding-right: 0;
    }
    h2 {
        @include font-bold(24px);
        @include line-height(24px);
        border: none;
    }
    h3 {
         @include font-bold(20px);
         @include line-height(28px);
         letter-spacing: 0.1px;
         text-transform: none;
         margin-bottom: 1.5em;
    }
    h4 {
        @include font-size(16px);
        text-transform: none;
        margin-top: 2em;
        &+ p {
            margin-top: 0;
        }
    }
    .customer-care-content {
        h3 {
            @include font-size(24px);
            @include line-height(24px);
            border-bottom: 1px solid $brand-black;
            letter-spacing: initial;
        }
        h4 {
            @include font-bold(20px);
            @include line-height(28px);
            letter-spacing: 0.1px;
            text-transform: none;
            margin-bottom: 1.5em;
        }
        h5 {
            @include font-bold(16px);
            @include line-height(16px);
            font-weight: initial;
            text-transform: none;
            color: $brand-black;
            margin-top: 32px;
            margin-bottom: 6px;
            &+ p {
                margin-top: 0;
            }
        }
        section > div {
        	position: relative;
        }
        .section-navigation {
            position: absolute;
            right: 20px;
            bottom: 15px;
            @include respond(medium-down) {
                right: 0;
            }
            .disabled a {
            	text-transform: none;
                color: $dim-gray;
                &:hover {
                    text-decoration: underline;
                    cursor: not-allowed;
                }
            }
            a {
                @include font-size(14px);
                @include line-height(24px);
                color: $night-rider;

                font-weight: 300;
            }
            .prev {
	            margin-right: 20px;
	        }
        }
        div {
        	ol {
        		padding: 0;
        		li {
    				list-style-type: none;
    				padding: 0;
    			}
        	}
    		h4 {
	            margin-left: -1em;
	            margin-top: 20px;
	            @include respond(large-down) {
	                margin-left: 0;
	            }
	        }
        }
        .wide-2 {
            margin-left: 27px;
            margin-right: 0;
    		padding-left: 0;
            @include respond(large-down) {
                margin-left: 0;
            }
            h4 {
                margin-left: 0;
            }
            h5 {
                margin-left: 30px;
            }
            > li {
            	list-style-type:none;
            }
        }
        .wide-3 {
            margin-left: 20px;
            @include respond(large-down) {
                margin-left: 30px;
            }
        }
        .wide-4 {
            margin-left: 30px;
            padding-left: 30px;
        }
        .wide-5 {
            padding-left: 30px;
        }
        .wide-3-margin-right {
            margin-right: 20px;
        }
        .wide-3-margin-right-2 {
            margin-right: 30px;
        }
        .break-link {
            @include respond(large-down) {
                word-break: break-all;
            }
        }
    }
    p,
    li,
    .content-asset p {
        @include line-height(28px);
        @include font-size(16px);
        color: $night-rider;
        letter-spacing: 0.1px;

    }
    a,
    .customer-care-content a {
        @include font-size(16px);
        color: $cobalt;

        white-space: pre-wrap;
        word-wrap: break-word;
        &.back-to-top {
            @include line-height(19px);
            letter-spacing: .09px;
            color: $night-rider;
            display: block;
            margin-bottom: 59px;
            margin-top: 36px;
            @include respond(medium-down) {
                margin-bottom: 50px;
            }
        }
    }
    .customer-care-content button.back-to-top {
        @include font-size(16px);
        @include line-height(19px);
        letter-spacing: .09px;
        color: $night-rider;
        display: block;
        margin-bottom: 59px;
        margin-top: 36px;
        background-color: $white;
        padding: 0;
        text-transform: none;
        text-decoration: underline;
        border: none;

        &:hover {
            text-decoration: none;
        }
        @include respond(medium-down) {
            margin-bottom: 50px;
        }
    }
    #contents button.scroll-down {
        @include font-size(16px);

        @include line-height(28px);
        letter-spacing: .1px;
        color: $brand-blue;
        display: block;
        background-color: transparent;
        padding: 0;
        text-align:  left;
        text-transform: none;
        text-decoration: underline;
        border: none;
        max-width: 571px;
        &:hover {
            text-decoration: none;
        }
        @include respond(medium-down) {

        }
    }
    > div {
        @include respond(large-down) {
            margin-left: 0;
            margin-right: 0;
        }
        h3 {
            margin-left: -1em;
            @include respond(large-down) {
                margin-left: 0;
            }
        }
    }
    /**
      * LIST STYLES
      -----------------------------------**/
    ul,
    ol,
    .content-asset ul,
    .content-asset ol {
        padding: 0 0 0 4em;
        margin: 1em 0;
    }
    li {
        @include line-height(20px);
        padding-left: .3em;
        list-style-type: disc;
        &>ul>li{
            &>ul { /** level 4 nested list **/
                margin-left: 0;
                padding-left: 1em;
                &>li {
                    list-style: none;
                    margin-bottom: 1.25em;
                    &:before {
                        display: inline-block;
                        content: "\FE58";
                        width: 2em;
                        margin-left: -3em;
                    }
                }
            }
        }
    }
    ol,
    .content-asset ol {
        li {
            padding-left: .75em;
        }
        &[type='a'] {
            &>li {
                list-style-type: lower-alpha;
            }
        }
    }
    /**
      * TABLE STYLES
      -----------------------------------**/
    th,
    .customer-care-content th {
        @include line-height(14px);
        @include font-size(12px);
    }
    td,
    .customer-care-content td {
        @include font-size(12px);
        @include line-height(14px);
        color: #3f3f3f;

    }
    /**
      * OTHER COMPONENTS
      -----------------------------------**/
    #contents {

        background-color: $white-smoke;
        padding: 3em;
        margin: 5em 0 5em;
        h2 {
            margin: 0;
            padding: 0;
        }
        ul {
            padding-left: 2em;
        }
        li {
            list-style-type: none;
            margin-bottom: 1.75em;
        }
        button {
            max-height: none;
        }
        p {

        }
    }
    #cs-tabs {
        position: relative;
        padding: .2em;
        ul:first-child{
            margin-top: 1em;
            display: inline-block;
            li{
                display: inline-block;
                padding-left: 0 0 2px 0;
                line-height: 1.25rem;
                float: left;
                a{
                   text-decoration: none;
                   letter-spacing: .1px;
                   float: left;
                }
            }
        }
        &> ul {
             padding: 0;
             margin-bottom: 0;
             height: 2.25em;
             @include respond(large-down) {
                 height: auto;
             }
             li {
                 list-style-type: none;
                 text-transform: uppercase;
                 border-bottom-width: 2px;
                 margin-right: 1.25em;
                 padding-left: 0;
                 @include respond(large-down) {
                     max-width: 30%;
                     float: none;
                     display: inline-block;
                     margin-right: 3%;
                 }
                 &:last-of-type {
                     margin-right: 0;
                 }
             }
             a {
                 @include font-bold(12px);
                 @include line-height(16px);
                 color: $dim-gray;
                 padding: 0;
                 @include respond(large-down) {
                     width: 100%;
                     white-space: initial;
                 }
             }
             .ui-state-active {
                 a {
                     color: $night-rider;
                     font-weight: 400;
                 }
             }
        }
        div {
            padding: 0;
        }
        .item {
            padding-left: 0;
            li {
                padding-bottom: 1em;
                padding-left: 0;
                list-style: none;
            }
            a {
                display: block;
                margin-top: 1.5em;
            }
            p {
                &.item-subhead {
                    margin: 1.75em 0 -.5em 0;
                    letter-spacing: normal;
                }
            }
        }
    }
    /**
      * CONTACT US FORM
      -----------------------------------**/
    .contact-us {
        .privacy-cta-block {
            margin: 0 0 1.5em 0;
            a {
                @include font-size(12px);
                color: $night-rider;
            }
        }
    }
}
.pt_customer-service {
    .secondary-navigation {
        padding-right: 1px;
    }
}
.online-privacy-policy {
    :target:before {
        content: "";
        display: block;
        height: 30px;
        margin-top: -30px;
    }
    h1 {
        padding-right: calc(30%) !important;
    }
}
.pt_customer-service .secondary-navigation > ul li a {
    letter-spacing: .023px;
}

// ----------------------------------------
// TERMS OF USE
// ----------------------------------------

.pt_customer-service[data-pageid^="terms-of-use"] .page-content .primary-content .customer-care-content {
    @include font-size(16px);
    @include line-height(28px);
    color: $brand-black;

    letter-spacing: 0.1px;

    h2,
    dt {
        @include h1;
        line-height: 1.083333333;
        text-transform: none;
    }

    h1,
    h2 {
        border-bottom: 1px solid #333;
        // margin-bottom: 20px !important;
        margin-bottom: 20px;
        padding-bottom: 24px;
    }

    h1 + p {
        border-bottom: 1px solid #333;
        margin-bottom: 0;
        padding-bottom: 20px;
    }

    h2 {
        margin-top: 0;
    }

    p strong {

    }

    dl,
    dd,
    ul,
    li {
        @include font-size(16px);
        @include line-height(28px);
        color: $brand-black;

        letter-spacing: 0.1px;
    }

    dl {
        margin-bottom: 0;
        margin-top: 0;

        dt {
            float: none;
            padding-bottom: 0;
        }

        dd {
            float: none;
            margin-bottom: 20px;
            margin-top: 0;
            padding-left: 0;
        }
    }

    ul {
        padding-left: 24px;

        li {
            padding-left: 15px;
        }
    }
}

// ----------------------------------------
// OneTrust
// ----------------------------------------

.otnotice-content {
    &, .otnotice-sections, .otnotice-section-content {
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    /* hide menu */
    .otnotice-menu {
        display: none;
    }
}

/* hide policy version */
div.otnotice__version {
    display: none;
}
