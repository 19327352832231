.ds-live-text-image-container {
    display: inline-block;
    position: relative;
    z-index: 1;

    .ds-live-text {
        bottom: 0;
        color: $text-primary;
        position: absolute;
        text-align: center;
        user-select: text;
    }

    .ds-live-text {
        @media screen and (max-width: $sMax) {
            &-center {
                left: 50%;
                transform: translateX(-50%);

                .ds-live-text-price-point {
                    width: 295px;
                }
            }

            &-right {
                right: 0;
            }

            &-top {
                bottom: auto;
                top: 0;
            }

            &-middle {
                bottom: auto;
                top: 50%;
                transform: translateY(-50%);

                &.ds-live-text-center {
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .ds-live-text-desktop {
        @media screen and (min-width: $mMin) {
            &-center {
                left: 50%;
                transform: translateX(-50%);

                .ds-live-text-price-point {
                    @media screen and (max-width: $mMax) {
                        width: 320px;
                    }

                    @media screen and (max-width: $lMin) {
                        width: 440px;
                    }

                    @media screen and (max-width: $xlMin) {
                        width: 600px;
                    }
                }
            }

            &-right {
                right: 0;
            }

            &-top {
                bottom: auto;
                top: 0;
            }

            &-middle {
                bottom: auto;
                top: 50%;
                transform: translateY(-50%);

                &.ds-live-text-desktop-center {
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .ds-live-text-text {
        max-height: 308px;
        max-width: 295px;
        padding: 16px 24px;
        color: $base;

        &.ds-color-scheme-dark {
            color: $grey-100;
        }

        @media screen and (min-width: $mMin) {
            max-height: 296px;
            padding: 12px 32px;
            max-width: 320px;
        }

        @media screen and (min-width: $lMin) {
            max-height: 400px;
            padding: 16px 56px;
            max-width: 440px;
        }

        @media screen and (min-width: $xlMin) {
            max-height: 456px;
            max-width: 600px;
        }
    }

    .ds-live-text-text {
        &.ds-live-text-bogo {
            @media screen and (min-width: $xlMin) {
                max-height: 466px;
            }
        }
    }

    .ds-live-text-percentage {
        .ds-live-text-text {
            box-sizing: border-box;
            @media screen and (min-width: $mMin) {
                padding: 16px 32px;
            }
        }
    }

    .ds-heading-holder {
        margin-bottom: 24px;
    }

    .ds-live-text-eyebrow {
        @include ds-ui-bold(s);
        letter-spacing: 0.21px;

        @media screen and (min-width: $lMin) {
            @include ds-heading(h1);
        }

        @media screen and (min-width: $xlMin) {
            @include ds-display-heading(3);
        }

        + .ds-live-text-heading {
            margin-top: -4px;

            @media screen and (min-width: $xlMin) {
                margin-top: -8px;
            }
        }
    }

    .ds-live-text-percentage {
        .ds-live-text-eyebrow {
            + .ds-live-text-heading {
                margin-top: -8px;
            }
        }
    }

    .ds-live-text-heading {
        @include ds-heading(h1);
        letter-spacing: -0.14px;

        @media screen and (min-width: $lMin) {
            @include ds-display-heading(2);
        }

        @media screen and (min-width: $xlMin) {
            @include ds-display-heading(1);
        }
    }

    .ds-live-text-quantity-specifier,
    .ds-live-text-product-category {
        @include ds-ui-bold(s);
        letter-spacing: 0.21px;
        margin-top: -4px;

        @media screen and (min-width: $lMin) {
            @include ds-heading(h3);
        }

        @media screen and (min-width: $xlMin) {
            @include ds-heading(h2);
        }
    }

    .ds-live-text--button{
        padding: 0;
        margin: 0;
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        background: transparent;
        border: none;
    }

    @media screen and (min-width: $lMin) {
        .ds-live-text-quantity-specifier {
            margin-top: 0;
        }
    }

    .ds-promo-price-holder {
        display: flex;
        justify-content: center;
        margin-top: -12px;

        @media screen and (min-width: $lMin) {
            margin-top: -16px;
        }

        @media screen and (min-width: $xlMin) {
            margin-top: -25px;
        }
    }

    .ds-price-symbol {
        font-weight: 900;
        @include font-size(36px);
        @include line-height(60px);
        letter-spacing: -0.005em;
        vertical-align: top;
        direction: rtl;
        margin-right: -2px;

        @media screen and (min-width: $lMin) {
            @include font-size(40px);
            @include line-height(81px);
            margin-right: 1px;
        }

        @media screen and (min-width: $xlMin) {
            @include font-size(52px);
            @include line-height(108px);
            margin-right: -3px;
        }
    }

    .ds-live-text-price-dollars,
    .ds-live-text-price-cents {
        font-feature-settings: "pnum" on, "lnum" on;
    }

    .ds-live-text-price-dollars {
        @include font-size(96px);
        @include line-height(96px);
        display: inline-block;
        font-weight: 900;
        letter-spacing: -0.055em;
        margin-right: 2px;

        @media screen and (min-width: $lMin) {
            @include font-size(140px);
            @include line-height(140px);
        }

        @media screen and (min-width: $xlMin) {
            @include font-size(188px);
            @include line-height(188px);
            margin-right: 5px;
        }
    }

    .ds-promo-cents-holder {
        display: inline-block;
        margin-top: 14px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: $lMin) {
            margin-top: 20px;
        }

        @media screen and (min-width: $xlMin) {
            margin-top: 27px;
        }
    }

    .ds-live-text-price-cents {
        font-weight: 900;
        @include font-size(36px);
        @include line-height(36px);
        letter-spacing: -0.04em;
        margin-bottom: -6px;

        @media screen and (min-width: $lMin) {
            @include font-size(48px);
            @include line-height(48px);
            margin-bottom: 8px;
        }

        @media screen and (min-width: $xlMin) {
            @include font-size(64px);
            @include line-height(64px);
        }
    }

    .ds-promo-code-holder {
        @include ds-ui-regular(s);
        margin-top: -8px;
        white-space: nowrap;

        @media screen and (min-width: $lMin) {
            margin-top: 0;
        }
    }

    .ds-promo-code-text {
        margin-top: -4px;
    }

    .ds-live-text-code {
        @include ds-ui-bold(s);
    }

    .ds-live-text-cta {
        margin-top: 16px;
        display: block;
        @include ds-body(m);
        color: $text-primary;
        content: "";
        z-index: 0;
    }

    .ds-button-container {
        margin-top: 4px;

        @media screen and (min-width: $lMin) {
            margin-top: -1px;
        }

        @media screen and (min-width: $xlMin) {
            margin-top: -8px;
        }
    }

    .ds-live-text-price-point {
        &.ds-live-text-text {
            @media screen and (min-width: $mMin) {
                padding: 16px 24px;
            }

            @media screen and (min-width: $lMin) {
                padding: 40px;
            }

            @media screen and (min-width: $xlMin) {
                padding: 56px;
            }
        }

        .ds-live-text-eyebrow {
            @include ds-ui-bold(s);
            margin-bottom: 0.25rem;

            @media screen and (min-width: $lMin) {
                @include ds-heading(h4);
            }

            +.ds-live-text-heading {
                margin-top: -3px;

                @media screen and (min-width: $xlMin) {
                    margin-top: -4px;
                }
            }
        }

        .ds-heading-holder {
            margin-bottom: 10px;

            @media screen and (min-width: $lMin) {
                margin-bottom: 24px;
            }
        }

        .ds-live-text-heading {
            @include ds-heading(h1);
            @include line-height(28px);
            position: relative;

            @media screen and (min-width: $mMin) {
                @include ds-heading(h1);
                @include line-height(28px);
            }

            @media screen and (min-width: $lMin) {
                font-weight: 700;
                @include font-size(40px);
                @include line-height(40px);
                letter-spacing: -0.01em;
            }

            @media screen and (min-width: $xlMin) {
                @include font-size(48px);
                @include line-height(48px);
                letter-spacing: 0.01em;
            }

            &::before {
                content: attr(headingtext);
                color: transparent;
                position: absolute;
                top: 1px;
                left: 1px;
                width: 100%;
            }
        }

        .ds-promo-category-holder {
            display: flex;
            flex-direction: column;
            gap: 1px;

            @media screen and (min-width: $xlMin) {
                gap: 4px;
            }
        }

        .ds-live-text-quantity-specifier,
        .ds-live-text-product-category {
            @include ds-ui-bold(s);
            margin: 0;

            @media screen and (min-width: $lMin) {
                @include ds-ui-bold(m);
            }

            @media screen and (min-width: $xlMin) {
                @include ds-ui-bold(l);
            }
        }

        .ds-promo-code-holder {
            padding-left: 4px;
            margin: 0 0 11px 0;

            @media screen and (min-width: $lMin) {
                margin: 0 0 17px 0;
            }

            @media screen and (min-width: $xlMin) {
                margin: 0 0 27px 0;
            }
        }

        .ds-promo-code-text {
            margin-top: 0;

            @media screen and (min-width: $xlMin) {
                margin-top: 1px;
            }
        }

        .ds-live-text-limit {
            margin: -4px 0;

            @media screen and (min-width: $lMin) {
                margin: -1px 0;
            }

            @media screen and (min-width: $xlMin) {
                margin: 0;
            }
        }

        .ds-live-text-code-label {
            margin: -4px 0;

            @media screen and (min-width: $lMin) {
                margin: 0;
            }
        }

        .ds-promo-cents-holder-no-cents {
            justify-content: flex-end;

            @media screen and (min-width: $lMin) {
                .ds-promo-code-holder {
                    padding-left: 6px;
                }
            }

            .ds-live-text-limit {
                margin: 0;

                @media screen and (min-width: $lMin) and (max-width: $lMax) {
                    margin: -1px 0;
                }
            }

            .ds-live-text-code-label {
                @media screen and (max-width: $mMax) {
                    margin: 0 0 1px 0;
                }
            }

            @media screen and (min-width: $xlMin) {
                .ds-promo-code-holder {
                    margin: 0 0 24px 0;
                }
            }
        }

        &.ds-multi-price-point.ds-live-text-text {
            @media screen and (max-width: $mMin) {
                padding: 24px;
            }

            .ds-multi-price-line {
                border-right: 1px solid;
                margin-bottom: 14px;
                margin-top: 5px;
            }

            .ds-live-text-eyebrow {
                letter-spacing: 0.21px;
                margin-bottom: 3px;

                @media screen and (min-width: $lMin) {
                    @include font-size(28px);
                    @include line-height(32px);
                    letter-spacing: -0.14px;
                    margin-bottom: -3px;
                }

                @media screen and (min-width: $xlMin) {
                    @include ds-display-heading(3);
                    letter-spacing: -0.16px;
                }
            }

            .ds-live-text-heading {
                letter-spacing: -0.14px;
                margin-top: -1px;

                @media screen and (min-width: $lMin) {
                    @include ds-display-heading(2);
                    letter-spacing: -0.48px;
                    margin-top: -3px;
                }

                @media screen and (min-width: $xlMin) {
                    @include ds-display-heading(1);
                    letter-spacing: -0.96px;
                    margin-top: -7px;
                }
            }

            .ds-heading-holder {
                margin-bottom: 9px;

                @media screen and (min-width: $lMin) {
                    margin-bottom: 25px;
                }
            }

            .ds-multi-price-point-container {
                display: flex;
                justify-content: center;
                margin-bottom: 9px;

                .ds-live-text-channel {
                    @include ds-ui-bold(s);
                    letter-spacing: 0.21px;
                    margin-bottom: 8px;

                    @media screen and (min-width: $lMin) {
                        @include ds-heading(h3);
                        letter-spacing: 0.1px;
                        margin-bottom: 10px;
                    }

                    @media screen and (min-width: $xlMin) {
                        @include ds-heading(h2);
                        margin-bottom: 9px;
                    }
                }

                .ds-live-text-cat-line {
                    @include ds-ui-regular(s);
                    letter-spacing: 0.21px;

                    @media screen and (min-width: $lMin) {
                        @include ds-ui-regular(l);
                        letter-spacing: 0.09px;
                    }

                    @media screen and (min-width: $xlMin) {
                        @include font-size(20px);
                        @include line-height(24px);
                        letter-spacing: 0.1px;
                    }
                }

                .ds-promo-price-holder {
                    margin-top: -5px;

                    @media screen and (min-width: $lMin) {
                        margin-top: -6px;
                    }
                }

                .ds-price-symbol {
                    font-weight: 900;
                    @include font-size(24px);
                    @include line-height(24px);
                    letter-spacing: -0.12px;
                    margin-top: 10px;
                    margin-bottom: -5px;
                    margin-right: -1px;

                    @media screen and (min-width: $lMin) {
                        @include font-size(40px);
                        @include line-height(44px);
                        letter-spacing: -0.2px;
                        margin-top: 17px;
                        margin-right: 1px;
                    }

                    @media screen and (min-width: $xlMin) {
                        @include font-size(52px);
                        @include line-height(56px);
                        letter-spacing: -0.26px;
                        margin-right: 0;
                        margin-top: 30px;
                    }
                }

                .ds-live-text-price-dollars {
                    font-weight: 900;
                    @include font-size(80px);
                    @include line-height(80px);
                    letter-spacing: -4.4px;
                    font-variant-numeric: proportional-nums;
                    margin-right: 4px;

                    @media screen and (min-width: $lMin) {
                        @include font-size(140px);
                        @include line-height(140px);
                        letter-spacing: -7.7px;
                    }

                    @media screen and (min-width: $xlMin) {
                        @include font-size(188px);
                        @include line-height(188px);
                        letter-spacing: 12px;
                        margin-right: -4px;
                    }
                }

                .ds-promo-cents-holder {
                    margin-bottom: -5px;
                    margin-left: -1px;
                    margin-top: 5px;
                    justify-content: center;
                }


                .ds-live-text-price-cents {
                    font-weight: 900;
                    @include font-size(24px);
                    @include line-height(24px);
                    letter-spacing: -0.96px;
                    font-variant-numeric: proportional-nums;
                    margin-top: 5px;
                    margin-bottom: 0;

                    @media screen and (min-width: $lMin) {
                        @include font-size(48px);
                        @include line-height(48px);
                        letter-spacing: -1.92px;
                        margin-bottom: 1px;
                    }

                    @media screen and (min-width: $xlMin) {
                        @include font-size(64px);
                        @include line-height(64px);
                        letter-spacing: -2.56px;
                        margin-bottom: 11px;
                        margin-top: 0;
                    }
                }

                .ds-promo-code-holder {
                    padding-left: 0;

                    @media screen and (min-width: $lMin) {
                        margin-bottom: 22px;
                    }

                    @media screen and (min-width: $xlMin) {
                        margin-bottom: 6px;
                    }
                }

                .ds-live-text-limit,
                .ds-live-text-code-label {
                    @include ds-ui-regular(s);
                    letter-spacing: 0.21px;
                    font-variant-numeric: proportional-nums;
                    margin-bottom: -4px;

                    @media screen and (min-width: $lMin) {
                        @include ds-ui-regular(l);
                        letter-spacing: -0.09px;
                    }
                }

                .ds-live-text-code {
                    font-variant: small-caps;
                    letter-spacing: 0.21px;

                    @media screen and (min-width: $lMin) {
                        @include ds-ui-regular(l);
                        letter-spacing: -0.09px;
                    }
                }

                .ds-promo-1,
                .ds-promo-2 {
                    display: flex;
                    flex-direction: column;
                }

                .ds-promo-1 {
                    margin-right: 8px;

                    @media screen and (min-width: $lMin) {
                        margin-right: 24px;
                    }
                }

                .ds-promo-2 {
                    margin-left: 8px;

                    @media screen and (min-width: $lMin) {
                        margin-left: 24px;
                    }
                }
            }

            &.ds-multi-price-point .ds-promo-price-holder.ds-price-point {
                display: none;
            }
        }
    }

    .ds-live-text-percentage {
        &.ds-live-text-text {
            max-height: 336px;

            @media screen and (min-width: $mMin) {
                max-height: 296px;
            }

            @media screen and (min-width: $lMin) {
                max-height: 455px;
            }

            @media screen and (min-width: $xlMin) {
                max-height: 540px;
            }
        }

        .ds-heading-holder {
            @media screen and (min-width: $mMin) {
                margin-bottom: 12px;
            }

            @media screen and (min-width: $lMin) {
                margin-bottom: 25px;
            }

            @media screen and (min-width: $xlMin) {
                margin-bottom: 32px;
            }
        }

        .ds-quantity-and-category-container {
            @media screen and (min-width: $mMin) and (max-width: $mMax) {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
            }

            @media screen and (min-width: $lMin) {
                margin-bottom: 4px;
            }
        }

        .ds-live-text-product-category {
            @media screen and (min-width: $mMin) {
                margin-left: 4px;
            }
        }

        .ds-live-text-leading-text {
            @include ds-display-heading(3);
            margin-top: 8px;

            @media screen and (min-width: $mMin) {
                margin-top: 0;
            }

            @media screen and (min-width: $lMin) {
                @include ds-display-heading(2);
            }

            @media screen and (min-width: $xlMin) {
                @include ds-display-heading(1);
                margin-top: -12px;
            }
        }

        .ds-promo-percent-holder {
            display: flex;
            justify-content: center;
            flex-direction: row;
            margin-top: -16px;

            @media screen and (min-width: $lMin) {
                margin-top: -32px;
            }

            @media screen and (min-width: $xlMin) {
                margin-top: -40px;
            }
        }

        .ds-promo-symbol-holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            margin-left: -4px;
        }

        .ds-live-text-percent {
            display: inline-block;
            font-weight: 900;
            @include font-size(110px);
            @include line-height(110px);
            letter-spacing: -0.055em;
            font-feature-settings: "pnum" on, "lnum" on;
            margin-right: 10px;

            @media screen and (min-width: $lMin) {
                @include font-size(140px);
                @include line-height(140px);
                margin-right: 14px;
            }

            @media screen and (min-width: $xlMin) {
                @include font-size(230px);
                @include line-height(230px);
                margin-right: 16px;
            }
        }

        .ds-percent-symbol {
            font-weight: 900;
            @include font-size(40px);
            @include line-height(40px);
            letter-spacing: -0.005em;
            vertical-align: top;

            @media screen and (min-width: $lMin) {
                @include font-size(60px);
                @include line-height(60px);
                margin-bottom: 8px;
            }

            @media screen and (min-width: $xlMin) {
                @include font-size(100px);
                @include line-height(100px);
            }
        }

        .ds-off-text {
            font-weight: 900;
            @include font-size(24px);
            @include line-height(20px);
            letter-spacing: -0.005em;
            text-transform: capitalize;
            vertical-align: top;

            @media screen and (min-width: $lMin) {
                @include ds-display-heading(2);
            }

            @media screen and (min-width: $xlMin) {
                @include ds-display-heading(1);
            }
        }
    }

    .ds-live-text-percentage {
        .ds-promo-code-holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media screen and (min-width: $mMin) {
                margin-top: -12px;
            }
        }

        .ds-promo-details {
            display: flex;
            flex-direction: row;
            text-align: center;
            gap: 5px;
            margin-top: 10px;
        }

        .ds-link-button {
            margin-left: 8px;
            min-width: 184px;
            margin-top: 0;
        }

        .ds-live-text-limit {
            text-transform: capitalize;
        }

        .ds-live-text-code-label-text {
            display: inline-block;
            @include font-size(14px);
            &::first-letter {
                text-transform: uppercase;
            }
        }

        .ds-live-text-limit + .ds-live-text-code-label {
            &:before {
                content: "•";
            }
        }

        .ds-live-text-code {
            text-transform: lowercase;
            font-variant: small-caps;
        }
    }

    .ds-live-text-bogo {
        &.ds-live-text-text {
            width: 295px;

            @media screen and (min-width: $mMin) {
                width: 320px;
            }

            @media screen and (min-width: $lMin) {
                width: 440px;
            }

            @media screen and (min-width: $xlMin) {
                width: 600px;
            }
        }

        .ds-heading-holder {
            margin-bottom: 13px;

            @media screen and (min-width: $lMin) {
                margin-bottom: 25px;
            }

            @media screen and (min-width: $xlMin) {
                margin-bottom: 23px;
            }

            .ds-live-text-eyebrow+.ds-live-text-heading {
                margin-top: -1px;

                @media screen and (min-width: $lMin) {
                    margin-top: -3px;
                }

                @media screen and (min-width: $xlMin) {
                    margin-top: -8px;
                }
            }

            .ds-live-text-eyebrow {
                @media screen and (min-width: $lMin) {
                    letter-spacing: -0.14px;
                }

                @media screen and (min-width: $xlMin) {
                    letter-spacing: -0.16px;
                }
            }

            .ds-live-text-heading {
                @include ds-heading(h1);
                @include line-height(28px);
                position: relative;

                @media screen and (min-width: $lMin) {
                    @include font-size(40px);
                    @include line-height(40px);
                    letter-spacing: -0.96px;
                }

                @media screen and (min-width: $xlMin) {
                    @include font-size(48px);
                    @include line-height(48px);
                    letter-spacing: 0.01em;
                }
            }
        }

        .ds-promo-category-holder {
            letter-spacing: 0.1px;

            .ds-live-text-product-category {
                margin-top: 0;

                @media screen and (min-width: $lMin) {
                    margin-top: -2px;
                }

                @media screen and (min-width: $xlMin) {
                    margin-top: -6px;
                }
            }
        }

        .ds-promo-bogo-holder {
            margin-top: -4px;
            @media screen and (min-width: $lMin) {
                margin-top: -2px;
            }
            @media screen and (min-width: $xlMin) {
                margin-top: -6px;
            }
        }

        .ds-live-text-bogo1,
        .ds-live-text-bogo2 {
            display: flex;
            justify-content: center;
            font-weight: 900;
            @include font-size(56px);
            @include line-height(56px);
            letter-spacing: -0.56px;
            margin-bottom: -19px;
            font-variant-numeric: proportional-nums;

            @media screen and (min-width: $lMin) {
                @include font-size(78px);
                @include line-height(78px);
                letter-spacing: -0.78px;
                margin-bottom: -23px;
            }

            @media screen and (min-width: $xlMin) {
                @include font-size(102px);
                @include line-height(102px);
                letter-spacing: -1.02px;
                margin-bottom: -33px;
            }
        }

        .ds-live-text-bogo-comma {
            @include font-size(28px);
            @include line-height(28px);
            letter-spacing: -0.28px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-right: 8px;
            margin-bottom: 6px;

            @media screen and (min-width: $lMin) {
                @include font-size(36px);
                @include line-height(36px);
                letter-spacing: -0.36px;
                margin-right: 12px;
                margin-bottom: 9px;
            }

            @media screen and (min-width: $xlMin) {
                @include font-size(48px);
                @include line-height(48px);
                letter-spacing: -0.48px;
                margin-right: 16px;
                margin-bottom: 4px;
            }
        }

        .ds-live-text-bogo3 {
            font-weight: 900;
            @include font-size(88px);
            @include line-height(88px);
            letter-spacing: -1.32px;
            font-variant-numeric: proportional-nums;

            @media screen and (min-width: $lMin) {
                @include font-size(120px);
                @include line-height(120px);
                letter-spacing: -1.8px;
            }

            @media screen and (min-width: $xlMin) {
                @include font-size(160px);
                @include line-height(160px);
                letter-spacing: -2.4px;
            }
        }

        .ds-promo-code-holder {
            margin-top: 8px;

            .ds-promo-details {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                letter-spacing: 0.21px;

                @media screen and (min-width: $lMin) {
                    @include ds-ui-regular(l);
                    letter-spacing: 0.09px;
                }

                .ds-live-text-limit {
                    text-transform: capitalize;
                    margin-right: 4px;
                }

                .ds-live-text-limit-number {
                    font-variant-numeric: oldstyle-nums;
                    margin-right: 4px;
                }

                .ds-live-text-code-label {
                    margin-right: 4px;

                    &:before {
                        content: "•";
                        @include ds-ui-regular(s);
                        margin-right: 4px;
                    }
                }

                .ds-live-text-code {
                    letter-spacing: 0.21px;
                    font-variant: small-caps;

                    @media screen and (min-width: $lMin) {
                        @include ds-ui-bold(l);
                        letter-spacing: 0.09px;
                    }
                }
            }
        }

        .ds-button-container {
            margin-top: -1px;

            @media screen and (min-width: $lMin) {
                margin-top: -7px;
            }

            @media screen and (min-width: $xlMin) {
                margin-top: -14px;
            }
        }
    }

    @media screen and (max-width: $sMax) {
        .small-4-3 .ds-live-text-price-point {
            padding: 12px;
        }
    }

    .medium-6-1 {
        @media screen and (min-width: $mMin) {
            .ds-live-text-desktop-left .ds-live-text-price-point {
                justify-content: flex-start;
            }

            .ds-live-text-desktop-right .ds-live-text-price-point {
                justify-content: flex-end;
            }

            .ds-live-text-price-point {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 40px;
                width: 480px;
                max-width: 480px;
                padding: 0 16px;
            }

            .ds-heading-holder {
                flex-shrink: 0;
                margin: 0;
            }

            .ds-content-holder {
                padding-top: 4px;
            }

            .ds-promo-category-holder {
                align-items: flex-start;
                gap: 0px;
            }

            .ds-live-text-eyebrow,
            .ds-live-text-quantity-specifier,
            .ds-live-text-product-category,
            .ds-promo-code-holder,
            .ds-live-text-code {
                @include font-size(12px);
                @include line-height(14px);
            }

            .ds-promo-cents-holder-no-cents {
                .ds-promo-code-holder {
                    @include ds-ui-regular(s);
                    padding-left: 4px;
                }

                .ds-live-text-code {
                    @include ds-ui-bold(s);
                }

                .ds-live-text-limit,
                .ds-live-text-code-label {
                    margin: -1px 0;
                }
            }

            .ds-live-text-eyebrow,
            .ds-live-text-heading {
                text-align: left;
            }

            .ds-live-text-eyebrow+.ds-live-text-heading {
                margin-top: -2px;
            }

            .ds-live-text-quantity-specifier {
                margin-bottom: -1px;
            }

            .ds-price-symbol {
                @include font-size(24px);
                @include line-height(45px);
                margin-right: 1px;
            }

            .ds-live-text-price-dollars {
                @include font-size(76px);
                @include line-height(76px);
            }

            .ds-promo-cents-holder {
                margin-top: 10px;
            }

            .ds-promo-code-holder {
                padding-left: 2px;
                margin: 0 0 9px 0;
            }

            .ds-live-text-price-cents {
                @include font-size(24px);
                @include line-height(24px);
            }
        }

        @media screen and (min-width: $lMin) {
            .ds-live-text-price-point {
                column-gap: 56px;
                width: 652px;
                max-width: 652px;
                padding: 8px 24px;
            }

            .ds-content-holder {
                padding-top: 0;
            }

            .ds-promo-category-holder {
                gap: 1px;
            }

            .ds-promo-price-holder {
                margin-top: -11px;
            }

            .ds-live-text-eyebrow,
            .ds-live-text-quantity-specifier,
            .ds-live-text-product-category {
                @include ds-ui-bold(m);
            }

            .ds-live-text-code {
                @include ds-ui-bold(s);
            }

            .ds-live-text-limit,
            .ds-live-text-code-label {
                margin: -4px 0;
            }

            .ds-live-text-code-label {
                margin-bottom: -3px;
            }

            .ds-live-text-eyebrow+.ds-live-text-heading {
                margin-top: -5px;
            }

            .ds-live-text-quantity-specifier {
                margin-bottom: 0;
            }

            .ds-price-symbol {
                @include font-size(36px);
                @include line-height(63px);
                margin-right: 0px;
            }

            .ds-promo-cents-holder {
                margin-top: 12px;
            }

            .ds-live-text-price-dollars {
                @include font-size(96px);
                @include line-height(96px);
            }

            .ds-live-text-price-cents {
                @include font-size(36px);
                @include line-height(36px);
                margin-bottom: 0;
            }

            .ds-promo-code-holder {
                @include ds-ui-regular(s);
                padding-left: 4px;
                margin: 0 0 10px 0;
            }

            .ds-promo-cents-holder-no-cents {
                .ds-live-text-code-label {
                    margin: 0;
                }

                .ds-promo-code-holder {
                    margin-bottom: 11px;
                }
            }
        }

        @media screen and (min-width: $xlMin) {
            .ds-live-text-price-point {
                column-gap: 64px;
                width: 808px;
                max-width: 808px;
                padding: 12px 40px;
            }

            .ds-promo-price-holder {
                margin-top: -15px;
            }

            .ds-live-text-eyebrow,
            .ds-live-text-quantity-specifier,
            .ds-live-text-product-category {
                @include ds-ui-bold(l);
            }

            .ds-live-text-eyebrow+.ds-live-text-heading {
                margin-top: -3px;
            }

            .ds-promo-category-holder {
                gap: 5px;
            }

            .ds-live-text-percentage {
                .ds-promo-category-holder {
                    margin-top: -8px;
                }
            }

            .ds-live-text-code {
                margin: 0;
            }

            .ds-live-text-limit,
            .ds-live-text-code-label {
                margin: -1px 0;
            }

            .ds-live-text-code-label {
                margin-bottom: 0;
            }

            .ds-live-text-quantity-specifier {
                margin-bottom: 0;
            }

            .ds-price-symbol {
                @include font-size(48px);
                @include line-height(88px);
                margin-right: 1px;
            }

            .ds-promo-cents-holder {
                margin-top: 20px;
            }

            .ds-live-text-price-dollars {
                @include font-size(140px);
                @include line-height(140px);
                margin-right: 3px;
            }

            .ds-live-text-price-cents {
                @include font-size(48px);
                @include line-height(48px);
            }

            .ds-promo-cents-holder-no-cents .ds-promo-code-holder,
            .ds-promo-code-holder {
                margin: 0 0 17px 0;
            }
        }
    }

    .ds-live-text-text-left {
        .ds-live-text-price-point--single {
            text-align: left;
            .ds-promo-price-holder {
                justify-content: flex-start;
            }
        }

        .ds-live-text-percentage {
            text-align: left;
            .ds-promo-percent-holder {
                justify-content: flex-start;
            }
            .ds-promo-code-holder{
                align-items: flex-start;
            }
        }

        .ds-live-text-bogo {
            text-align: left;
            .ds-live-text-bogo1, .ds-promo-details {
                justify-content: flex-start;
            }
        }

    }

    .ds-live-text-text-right {
        .ds-live-text-price-point--single {
            text-align: right;
            .ds-promo-price-holder {
                justify-content: flex-end;
            }
        }
        .ds-live-text-percentage {
            text-align: right;
            .ds-promo-percent-holder {
                justify-content: flex-end;
            }
            .ds-promo-code-holder {
                align-items: flex-end;
            }
        }
        .ds-live-text-bogo {
            text-align: right;
            .ds-live-text-bogo1, .ds-promo-details {
                justify-content: flex-end;
            }
        }
    }

    .ds-live-text-desktop-text-left {
        @include respond(desktop) {
            .ds-live-text-price-point--single {
                text-align: left;
                .ds-promo-price-holder {
                    justify-content: flex-start;
                }
            }

            .ds-live-text-percentage {
                text-align: left;
                .ds-promo-percent-holder {
                    justify-content: flex-start;
                }
                .ds-promo-code-holder {
                    align-items: flex-start;
                }
            }

            .ds-live-text-bogo {
                text-align: left;
                .ds-live-text-bogo1, .ds-promo-details {
                    justify-content: flex-start;
                }
            }


        }
    }

    .ds-live-text-desktop-text-right {
        @include respond(desktop) {
            .ds-live-text-price-point--single {
                text-align: right;
                .ds-promo-price-holder {
                    justify-content: flex-end;
                }
            }
            .ds-live-text-percentage {
                text-align: right;
                .ds-promo-percent-holder {
                    justify-content: flex-end;
                }
                .ds-promo-code-holder {
                    align-items: flex-end;
                }
            }

            .ds-live-text-bogo {
                text-align: right;
                .ds-live-text-bogo1, .ds-promo-details {
                    justify-content: flex-end;
                }
            }
        }
    }

    .ds-live-text-desktop-text-center {
        @include respond(desktop){
            .ds-live-text-price-point--single {
                text-align: center;
                .ds-promo-price-holder{
                    justify-content: center;
                }
            }

            .ds-live-text-percentage {
                text-align: center;
                .ds-promo-percent-holder {
                    justify-content: center;
                }
                .ds-promo-code-holder {
                    align-items: center;
                }
            }
            .ds-live-text-bogo {
                text-align: center;
                .ds-live-text-bogo1, .ds-promo-details {
                    justify-content: center;
                }
            }

        }
    }
}

.ds-live-text-image a {
    display: block;
}
