#BBW-loveit-table {
	@include respond(tablet-small) {
       table-layout: fixed;
    }
    tr {
        &.wishlist-row {
	        @include respond(medium-down) {
	            position: relative;
	            display: block;
	            overflow: hidden;
	        }
        }
        &.last {
            border: 0 none; 
        }
        td {
            padding: 35px 8px 48px;
            @include respond(medium-down) {
                display: block;
			    float: right;
			    width: 75%;
			    box-sizing: border-box;
			    padding: 0;
            }
			&.item-image {
                padding: 35px 0 48px;
                width:72px;
                @include respond(medium-down) {
                    float: left;
                    width: 25%;
                    padding: 20px 0 0;
                }
                img {
					max-width: 100%;
					vertical-align: top;
                }
			}
			&.item-details {
				@include respond(medium-down) {
				    padding: 27px 0 8px 15px;
				}
                .product-list-item {
                    @include respond(desktop) {
                        width: 200px;
                    }
                    @include respond(large-up) {
                        max-width: 200px;
                    }
                    .name {
                        margin-bottom: 9px;
                        @include respond(large-up) {
                            margin-bottom: 3px;
                        }
                    }
                }
			}
			&.item-price {
                @include respond(medium-down) {
                    padding-left: 15px;
                }
                .product-pricing {
                    @include font-size(14px);
                    margin-bottom: 10px;
                    @include respond(large-up) {
                        margin-top: 0;
                    }
                    .price-sales {
                        color: $secondary-color;
                    }
                    .price-standard {
                        text-decoration: line-through;
                        & ~ .price-sales {
                            color: $red;
                        }
                    }
                }
                .promotion {
                    max-width: 200px;
                    .promotion-callout {
                        margin-bottom: 10px;
                        color: $red;
	                    @include font-size(12px);
	                    line-height: 1;
	                    @include respond(large-up) {
                            @include font-size(11px);
                        }
                        * {
                            @include font-size(12px);
	                        line-height: 1;
	                        @include respond(large-up) {
	                            @include font-size(11px);
	                        }
                        }
                        p {
	                        margin: 0;
	                        color: $red;
                        }
                    }
                }
                .product-availability-list {
                    li {
                        @include font-size(12px);
                        line-height: 1;
                        @include respond(large-up) {
                            @include font-size(11px);
                        }
                        &.notavailable{
                            color: $red;
                        }
                    }
                }
                .option-date-added {
                    @include font-size(12px);
                    @include respond(large-up) {
                        @include font-size(11px);
                    }
                }
			}
			&.product-detail {
                @include respond(medium-down) {
                    padding: 25px 0 25px 15px!important;
                }
                @include respond(large-up) {
                    padding-right: 0;
                    text-align: right;
                }
                button {
                    &.add-to-cart {
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        width: 115px;
                        padding: 0;
                        @include respond(large-up) {
                            width: 104px;
                            margin-bottom: 0;
                        }
                    }
                    &.delete-item {
	                    text-decoration: underline;
	                    margin-top: 12px;
	                    svg, img.icon {
	                        width: 24px;
	                        height: 24px;
	                        float: left;
	                        margin-right: 5px;
	                    }
	                    span {
	                        @include font-size(14px);
	                        @include line-height(24px);
	                    }
	                    &:hover {
	                        text-decoration: none;
	                    }
	                }
                }
			}
        }
        &.first {
			td {
                padding-top: 0;
			}
        }
        &.view-more-row {
            border: 0 none;
            & ~ .wishlist-row {
                display: none;
            }
            td {
                text-align: center;
                padding: 34px 0 0;
                @include respond(medium-down) {
                    width: 100%;
                }
                .button {
                    box-sizing: border-box;
                    width: 100%;
                    @include respond(large-up) {
                        width: 163px;
                    }
                }
            }
        }
    }
}