@keyframes bounceup {
    from {
        transform: translate(-50%, 100%);
    }

    to {
        transform: translate(-50%, 0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ds-modal {
    color: $text-primary;
    background: $white;
    bottom: 0;
    box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.15);
    left: 50%;
    width: 90vw;
    max-width: 568px;
    position: fixed;
    transform: translate(-50%, 100%);
    z-index: 2147483638;
    display: none;
    height: max-content;
    top: 40vh;

    &-content,
    &-success {
        padding: 32px;

        &.no-padding {
            padding: 0;
        }
    }

    &-success-content {
        display: flex;
        flex-direction: column;

        .ds-done-button {
            @include ds-utilities-button;
            align-self: end;
            width: auto;
        }
    }

    &-heading {
        @include ds-heading(h2);
        margin: 0 0 8px;
    }

    &-paragraph {
        @include ds-body(l);
        color: $text-secondary;
        margin-bottom: 24px;
    }

    &.open {
        display: block;
        animation: bounceup 0.2s;
        animation-timing-function: cubic-bezier(0.2, 0.05, 0.55, 0.2);
        transform: translate(-50%, 0);
    }

    button.ds-close-button {
        background-image: $close-small;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        height: 48px;
        max-height: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        transition: none;

        &:not([disabled]):not(.disabled):hover {
            background-color: $grey-10;
            box-shadow: inset 0 0 0 8px $white;
        }

        &:hover {
            background-color: $grey-10;
        }
    }

    .ds-buttons-container {
        display: flex;
        justify-content: flex-end;

        .ds-cancel {
            @include ds-ui-base(m);
            background-color: $white;
            border: none;
            color: $dim-gray;
            text-decoration: underline;
            margin-top: 8px;
            margin-right: 32px;
            text-transform: none;
            padding: 0;
            align-self: center;

            &:not([disabled]):not(.disabled):hover {
                background-color: transparent;
                color: $dim-gray;
            }
        }

        .ds-cancel:active {
            outline: #000 dotted 1px;
            outline-offset: 2px;
        }

        .ds-cancel:hover,
        .ds-cancel:focus {
            text-decoration: none;
            cursor: default;
            color: $dim-gray;
        }
    }

    &-overlay {
        background: rgba(26, 26, 26, 0.6);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 150;
        display: none;

        &.visible {
            display: block;
        }
    }

    &.auth-modal {
        top: 0;
        margin-top: 3vh;
        text-align: left;
        max-width: 343px;

        .auth-modal-wrapper {
            overflow-y: auto;
            max-height: calc(94vh - 64px);
            padding-right: 32px;
            margin-right: -32px;
        }

        .error-form {
            color: $text-feedback-danger;
            background-color: $bg-feedback-danger;
            font-weight: bold;
            .icon {
                top: 4px;
            }
        }

        .loginerror {
            border-color: $text-feedback-danger;
        }

        h4 {
            @include ds-heading(h4);
            color: $text-primary;
        }

        p {
            @include ds-body(m);
        }

        fieldset {
            display: flex;
            flex-direction: column;

            a {
                position: relative;
                right: auto;
                top: auto;
                margin: 0;

                &.password-reset {
                    margin-top: 0.25em;
                    margin-bottom: 0.5em;
                    text-align: left;
                }
            }

            .form-row {
                margin: 0;
                margin-bottom: 0.5em;

                .field-wrapper .input-text {
                    margin-bottom: 1.5em;

                    &.error {
                        margin-bottom: 0;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                label {
                    @include ds-utilities-button-labels;
                    text-transform: none;
                    margin-bottom: 0.5em;
                }
            }

            button.hide-show {
                background: none;
                height: 100%;
            }
        }

        .register {
            margin-top: 1em;
            padding-top: 1em;
            border-top: #000 solid 1px;

            h4 {
                color: $text-brand-accent;
            }

            a {
                font-size: inherit;
            }
        }

        .auth-modal-loader {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.75);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @keyframes modal_spinner {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            .icon {
                height: 48px;
                width: 48px;
                animation: modal_spinner 1.2s linear infinite;
            }

            span {
                color: $text-brand-primary;
                font-weight: bold;
                text-align: center;
                padding-top: 0.5em;
                animation: fadeIn 2s;
            }

            &.hidden {
                display: none;
            }
        }
    }
}

.trigger-link {
    &.hidden {
        display: none;
    }

    &.wish-list-link {
        position: absolute !important;
        right: 16px;
        top: 16px;
    }
}

.timeout-dialog-active {

    .ds-modal,
    .ds-modal-overlay {
        visibility: hidden;
    }
}

.size-guide-modal {
    width: 360px;
    top: 10vh;

    .size-guide-modal-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .size-guide-modal-header {
        font-weight: 700;
        font-size: 1.125rem;
        letter-spacing: 0.09px;
        line-height: 20px;
        margin: 0;
    }

    .size-guide-modal-description {
        color: $grey-100;
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        margin: 0;
        width: 296px;
    }

    .size-guide-table-container {
        background-color: $bg-table-header;
        margin-top: 24px;
        width: 296px;

        .size-guide-table-header {
            background-color: $grey-10;
            font-weight: 700;
        }

        .size-guide-row {
            border-top: 1px solid $grey-30;
            border-bottom: 1px solid $grey-30;
            font-size: 0.9rem;
            line-height: 16px;
            letter-spacing: 0.21px;
        }
    }
}


.bonus-details-modal {
    top: 15vh;

    &-content {
        max-height: 25em;
        overflow-y: scroll;
        margin-bottom: 1em;

        p.bold {
            font-weight: bold;
        }

        b {
            padding-bottom: 1em;
            display: block;
        }

        br {
            display: none;
        }
    }

    button {
        display: block;
        margin-right: 0;
        margin-left: auto;
    }
}

.promo-detail-modal.ds-modal.open {
    animation: none;
}

.promo-detail-modal {

    width: 464px;
    height: 60vh;

    @include respond(small-down) {
        width: calc(100% - 40px);
    }

    @media screen and (min-height: 500px) {
        height: 408px;
    }

    .ds-modal-content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

}
