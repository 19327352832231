.dropd-pagination {
    display: flex;
    justify-content: end;
    align-items: center;

    &-select {
        width: max-content;
        padding: 0 0.5em;
        margin-right: 0.5em;
    }

    p {
        color: $nero;
        font-weight: bold;
        font-size: 18px;
    }

    button {
        background: none;
        border: none;

        &.invisible {
            visibility: hidden;
        }
    }

    &-arrow {
        height: 1em;
        width: 1em;
        background: none;
        border: none;
        border-right: 1px $nero solid;
        border-top: 1px $nero solid;

        &.prev {
            transform: rotate(225deg);
            margin-right: 1em;
        }

        &.next {
            transform: rotate(45deg);
            margin-left: 1em;
        }
    }
}
