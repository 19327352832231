.tooltip {
    &:not(.product-tile):not(.text-inline) {
        /*cursor: help;*/
        position: relative;
        background: $bg-color url("../images/svg-icons/UI-Information.svg") no-repeat center;
        background-size: 11px;
        height: 26px;
        width: 26px;
        cursor: pointer;
        text-indent: -999em;
        display: inline-block;
        @include borderRadius(20px);
        @include transition(all 0.5s ease-out);
        &:hover{
            background: $secondary-color url("../images/svg-icons/UI-Information-hover.svg") no-repeat center;
            background-size: 11px;
        }
        &:active{
            background: $click url("../images/svg-icons/UI-Information-hover.svg") no-repeat center;
            background-size: 11px;
        }
    }
    &.product-tile {
        cursor: pointer;
        display: block;
    }
    &.text-inline {
        cursor: pointer;
        .details {
            text-decoration: underline;
            color: $dim-gray;
            @include font-size(12px);
        }
        &:hover {
            .details {
                text-decoration: none;
            }
        }
    }
}
#product-content{
    .tooltip {
        background: none;
        text-indent: 0;
        width: auto;
        height: auto;
        text-decoration: underline;
        display: inline;
        &:hover{
            text-decoration: none;
        }
        &:active{
            color: $click;
        }
    }
}
@include respond(medium-down) {
    .tooltip {
        font-size: .9em;
    }
    .tooltip, #tooltip {
        /*display: none;*/
    }
    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }
}


.tooltip-content {
    display: none;
}
.ui-tooltip {
    padding: 0;
    max-width: none;
}

.ui-tooltip.ui-widget-content {
    background: $white;
    color: $primary-color;
    border: 1px solid $bg-color;
    @include font-regular(14px);
    @include line-height(20px);
    box-shadow: none;
    .bonus-info {
        @include font-bold(14px);
    }
    @include respond(small) {
        margin-left: 1%;
        width: auto;
        max-width: 240px;
    }
    @include respond(desktop) {
        &:after, &:before {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-right-color: $white;
            border-width: 10px;
            margin-top: -10px;
        }
        &:before {
            border-color: rgba(229, 229, 229, 0);
            border-right-color: $gainsboro;
            border-width: 11px;
            margin-top: -11px;
        }
    }
}

.ui-tooltip-content {
    padding: 10px 25px;
    position: relative;
    @include respond(medium-down) {
        padding: 10px;
    }
    p{
        color: $primary-color;
    }
    a{
        color: $cobalt;
        &:hover{
            text-decoration: none;
        }
        &:active{
            color: $click;
        }
    }
    .recommendation-tooltip-header {
        font-size: 1.2em;
        font-weight: bold;
        padding: 0.5em;
        .product-price {
            font-weight: 400;
            .price-standard {
                color: $white;
                text-decoration: line-through;
            }
        }
    }
    .recommendation-tooltip-description,
    .recommendation-tooltip-attributes {
        padding: 0.8em;
    }
    .shipping-method-cost,
    .surcharge-product,
    .promo {
        text-align: right;
        padding-bottom: .3rem;
    }
    .shippingtotal {
        clear: right;
        text-align: right;
        margin-top: .3rem;
        .value {
            border-top: 1px solid $charcoal;
            padding-top: .3rem;
        }
    }
}
