.ds-single-subs {
    .ds-live-text-image-container+.ds-linkcontainer {
        display: none;
    }

    img {
        margin-bottom: 24px;
    }

    .ds-copy {
        display: grid;
        align-content: center;
    }

    .ds-marketing-copy-heading {
        letter-spacing: 0.09px;
        margin-bottom: 12px;
    }

    .ds-marketing-copy-paragraph {
        line-height: 28px;
        letter-spacing: 0.09px;
        padding-bottom: 12px;
    }

    .ds-link {
        @include ds-heading(h4);
        font-weight: 600px;
        letter-spacing: 0.09px;
    }

    &.ds-right-aligned {
        @media screen and (max-width: $mMin) {
            display: grid;
            grid-template-areas: "image" "text";

            .ds-copy {
                grid-area: text;
            }

            .ds-link-image {
                grid-area: image;
            }
        }
    }

    @media screen and (min-width: $xlMin) {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .ds-link-image {
            justify-self: start;
        }

        .ds-copy {
            justify-items: center;
        }

        .ds-marketing-copy {
            align-self: center;

            .ds-marketing-copy-heading {
                @include ds-display-heading(3);
                line-height: 28px;
                margin-bottom: 24px;
            }

            .ds-marketing-copy-paragraph {
                @include ds-ui-regular(l);
                line-height: 20px;
                letter-spacing: 0.09px;
                margin-bottom: 16px;
            }
        }

        .ds-button {
            width: fit-content;
        }

        &.ds-right-aligned {
            .ds-link-image {
                justify-self: end;
            }
        }
    }
}