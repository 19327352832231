.social-commerce-container {
    border-top: 1px solid $secondary-color;
    margin-top: 2em;
    padding-top: 2.25em;
    padding-bottom: calc(2.25em - 20px);
    display: none;

    .social-commerce-copy {
        margin: 0 44px 20px;
        text-align: center;

        .social-commerce-heading {
            @include ds-heading(h2);
            color: $text-primary;
            display: block;
        }

        .social-commerce-text {
            @include font-size(16px);
            margin-top: 16px;
        }
    }

    button {
        max-height: fit-content;
    }
}
