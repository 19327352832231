.search-result-items {
    display: flex;
    flex-wrap: wrap;
    .grid-tile {
        display: flex;
        flex-basis: 47.245%;
        @include respond(medium-up) {
            flex-basis: 31%;
        }
        @include respond(desktop) {
            flex-basis: 21.107%;
        }
    }
}
