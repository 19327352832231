@import '../global/_dropdown_pagination.scss';


.page-content .primary-content {
    .points-history {
        h2 {
            font-size: 21px;
            font-weight: 700;
            color: $text-primary;
            border-bottom: 1px solid $border-dark;
            margin-bottom: 0;
            padding-bottom: 1em;
            margin-top: 1em;


            @include respond(large-up) {
                @include ds-heading(h2);
            }
        }
    }
}

.points-history {
    .points-history-banner {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        background-color: $brand-primary;
        padding: 0 1.25em;
        margin: 0 -15px 12px;

        @include respond(large-up) {
            margin: 0;
        }

        p {
            color: $base;
        }

        button.trigger-link {
            background: none;
            border: none;
            text-transform: math-auto;
            line-height: 1;
            letter-spacing: normal;
            padding: 0;
            text-decoration: underline;
            font-size: 12px;
            outline: none;
            color: $base;
        }

        button:not([disabled]):not(.disabled):hover {
            background-color: transparent;
            border: none;
            color: $base;
            opacity: 0.5;
        }

        .total {
            font-weight: bold;
        }
    }

    article.points-history-line-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-bottom: 1px $border-primary solid;
        margin-bottom: 1em;
        padding: 0.5em 0;

        p {
            margin: 0;
            font-size: 14px;
            color: $text-primary;

            &.name,
            &.value {
                font-size: 16px;
            }
        }

        .name,
        .order,
        .date {
            grid-column: 1;
        }

        .value,
        .type {
            grid-column: 2;
            text-align: right;
        }

        .value {
            grid-row: 1;
            color: $text-feedback-success;
            display: flex;
            justify-content: right;

            &::before {
                content: '+ ';
                display: block;
                padding-right: 3px;
            }
        }

        .type {
            grid-row: 2;
            text-transform: capitalize;
        }

        .name,
        .value {
            font-weight: bold;
        }

        &.debit {
            .value {
                color: $text-primary;

                &::before {
                    content: '';
                }
            }
        }

        &.Burn {
            .name {
                color: $hollywood-cerise;
            }
        }
    }

    &-no-history {
        display: flex;
        flex-direction: column;
        margin-bottom: 4em;

        p {
            font-size: 16px;
            color: $text-primary;
        }

        a.outline-button {
            align-self: center;
            border: 1px solid $grey-100;
            padding: 0em 1em;
            cursor: pointer;
            background: $base;
            font-size: 16px;
            color: $grey-100;

            &:hover {
                border: 2px solid $border-secondary;
            }

            &:active {
                border: 2px solid $border-secondary;
                background: $grey-10;
            }
        }
    }
}

.ph-disclaimer {
    text-align: center;
    width: 290px !important;

    button {
        width: 100%;
    }
}
