.ds-announcement-banner-global + #main .ds-fulfillment:first-child,
.design-system + .design-system .ds-fulfillment {
    margin-top: 0;
}

.ds-fulfillment {
    max-width: 396px;
    margin: 48px auto 0;

    @media screen and (min-width: $xlMin) {
        max-width: 1112px;
        margin-top: 56px;
    }

    .ds-fulfillment-heading {
        @include ds-heading(h4);
        color: $text-primary;
        margin: 0 auto 16px;
        max-width: 568px;

        @media screen and (min-width: $xlMin) {
            @include ds-heading(h3);
        }
    }

    .ds-fulfillment-cards {
        column-gap: 16px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        margin-bottom: 24px;
        row-gap: 16px;
        align-items: center;

        @media screen and (min-width: $xlMin) {
            display: flex;
            justify-content: center;
        }
    }

    .ds-fulfillment-card {
        height: 100%;

        &:first-child,
        &:last-child:nth-child(2) {
            grid-column: span 2;

            .ds-fulfillment-desktop {
                display: block;
            }

            .ds-fulfillment-mobile {
                display: none;
            }
            .ds-supporting-text__post {
                display: block;
            }

            .ds-fulfillment-content__branding {
                padding-right: 8px;
            }
        }
    }

    .ds-fulfillment-footer {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        column-gap: 4px;

        @media screen and (min-width: $xlMin) {
            flex-direction: row;
            justify-content: center;
        }
    }

    .ds-fulfillment-footer-header {
        @include ds-ui-regular(s);
        color: $text-secondary;

        @media screen and (min-width: $xlMin) {
            display: inline-block;
        }
    }

    .ds-fulfillment-footer-link {
        @include ds-ui-semi(s);
        color: $text-secondary;
    }
}
