.sizinginformation {
    border: 0 none;
    padding: 10px;
    th {
        background: none repeat scroll 0 0 $white-smoke;
    }
    td, th {
        border-bottom: 1px solid $white-smoke;
        padding: 10px;
    }
    td {
        border-left: 1px solid $white-smoke;
    }
    .ui-tabs-nav {
        height: 33px;
    }
    .sizing-charts {
        border: 0 none;
    }
    .tips-list {
        padding: 0 !important;
        li {
            list-style: none outside none !important;
        }
    }
    @include respond(medium-down) {
        padding: 0;
        th {
            font-weight: normal;
            padding: 0 0 0 2px;
        }
        td {
            background: #ffffff;
            padding: 5px;
            text-align: center;
        }
        .sizechart {
            padding: 0 !important;
        }
    }
}
