
.cart-delivery-options {
    border-top: 3px solid $white-smoke;
    border-bottom: 3px solid $white-smoke;
    box-sizing: border-box;
    padding: 13px 0 12px 0;
    margin-top: 18px;

    @include respond(large-up) {
    	margin-right: 18px;
    }

    .ship-it-form-row {
        display: flex;
        .ship-it-delivery-option {
            width: 16px;
            height: 16px;
            margin: 2px 9px 0 0;
        }

        .ship-it-label {
            @include ds-heading(h5);
            color: $text-primary;
            margin-bottom: 0;
            padding: 0;
        }

        &.disabled-state {
            .ship-it-label {
                color: $text-disabled;
            }
        }
    }

    .noteligibleforpickup {
        @include ds-ui-regular(m);
        color: $text-secondary;
        max-width: 276px;
        padding-top: 5px;

        .not-eligible-for-pickup-label {
            display: block;
        }
    }

    .availability-in-online {
        @include ds-ui-regular(m);
        color: $text-secondary;
        margin: 4px 0 0 25px;

        &.available-online {
            color: $text-feedback-success;
        }

        &.out-of-stock-online {
            color: $text-feedback-danger;
        }
    }

    .pick-up-form-row {
        display: flex;
        align-items: flex-start;
        margin-top: 14px;
        .pick-up-delivery-option {
            width: 16px;
            height: 16px;
            margin: 2px 9px 0 0;
        }

        .full-pick-up-label {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            margin-bottom: 4px;
        }

        .pick-up-label {
            @include ds-heading(h5);
            color: $text-brand-primary;
            margin-right: 2px;
            margin-bottom: 0;
        }

        .bopis-set-store {
            @include ds-ui-semi(m);
            color: $text-primary;
            text-decoration: underline;
            text-transform: capitalize;
        }

        &.disabled-state {
            .pick-up-label {
                color: $text-disabled;
            }

            .pick-up-store-name {
                @include ds-ui-semi(m);
                color: $text-disabled;
                text-transform: capitalize;
            }

            .noteligibleforpickup {
                color: $text-disabled;
            }
        }

        &.online-exclusive .full-pick-up-label {
            flex-direction: column;
        }
    }

    .availability-in-store {
        @include ds-ui-regular(m);
        margin: 4px 0 0 25px;

        &.available-in-store {
            color: $text-feedback-success;
        }

        &.unavailable-in-store {
            color: $text-feedback-danger;
        }

        @include respond(large-up) {
            margin-top: 0;
        }
    }
}


.radial-qty-error {
    @include ds-ui-regular(m);
    color: $text-feedback-danger;
    margin: 5px 0 0 0;

	.bopis-set-store {
        text-decoration: underline;
        @include error-text-link;
	}
}

.overlay {
    opacity: .4;
    pointer-events: none;
}
