.ds-modal-email-collection {
    margin-top: 16px;
    color: $night-rider;
    max-width: 568px;

    .ds-user-info-heading {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
        text-align: left;
    }

    .ds-info-heading-text {
        @include ds-ui-semi(s);
        flex: 1;
    }

    .ds-input-field {
        label {
            @include ds-ui-semi(l);
            height: auto;
            margin-bottom: 4px;
        }
    
        .input-text {
            @include ds-ui-regular(l);
            padding: 16px 8px 16px 16px;
        }
    
        .form-row {
            margin: 0;
            padding-bottom: 16px;
        }
    }
    
    .ds-user-info-collection-form {
        .ds-submit-button {
            margin-top: 8px;
        }
    }
}

@media screen and (min-width: $mMax) {
    .ds-container.ds-user-info-form {
        max-width: 568px;
    }
}