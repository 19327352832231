.ds-grid-content-tile {
    display: flex;
    flex-direction: column;
    max-width: 599px;
    max-height: 573px;

    @media only screen and (min-width: 480px) {
        max-width: 248px;
        max-height: 500px;
    }

    @media only screen and (min-width: $xlMin) {
        display: flex;
        flex-direction: column;
    }

    .ds-link-image img {
        @media only screen and (min-width: 480px) {
            max-height: 424px;
        }
    }

    picture {
        display: block;
    }

    .ds-marketing-copy {
        display: block;

        @media only screen and (min-width: 480px) {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .ds-margin {
        margin-bottom: 12px;

        @media only screen and (max-width: 479px) {
            margin-bottom: 20px;
        }
    }

    .ds-marketing-copy-heading {
        @include ds-ui-semi(m);
        color: $text-primary;
        margin-top: 8px;
        margin-bottom: 4px;
        display: flex;
        align-items: flex-end;
    }

    .ds-marketing-copy-paragraph {
        @include ds-ui-regular(m);
        color: $text-secondary;
        margin-top: 4px;
    }

    .ds-marketing-copy-cta {
        text-align: center;
        margin-top: 8px;

        @media only screen and (max-width: 479px) {
            .ds-link-button {
                width: 100%;
            }
        }

        @media only screen and (min-width: $xlMin) {
            &:first-child {
                margin-top: auto;
            }
        }
    }

    .ds-link-button {
        @media only screen and (min-width: $xlMin) {
            width: 100%;
        }
    }
}

.grid-slot {
    .product-review {
        @include ds-ui-regular(s);
        color: $text-primary;
        margin: 10px 0 10px;
        display: inline-block;
        text-decoration: underline;
    }
}
