.footer-container {
    .footer-item {
        &.sub-item {
            padding: 0 15px;
            float: left;
            @media only screen and (max-width: 1024px) and (min-width: 768px) {
                padding: 0;
                float: none;
            }
        }
    }
}
