.ds-link-button {
    display: inline-block;
    line-height: 0;

    .ds-button {
        @include ds-utilities-button;
    }
}

.ds-link-button-secondary {
    .ds-button {
        background: $gainsboro;
        border-color: $gainsboro;
        color: $night-rider;

        &:hover {
            background: $night-rider;
            color: #fff;
            border-color: $night-rider;
        }

        &:active {
            background-color: $white;
            color: $night-rider;
            border: 2px solid $night-rider;
        }

        &:focus {
            outline: 1px dotted #000;
            outline-offset: 2px;
        }
    }
}

.ds-link-button-fullwidthsmallbreakpoint {
    width: 100%;

    .ds-button {
        padding: 0 16px;
    }

    @media screen and (min-width: $xlMin) {
        width: auto;
        
        .ds-button {
            padding: 0 32px;
        }
    }
}

.ds-outline-button {
    .ds-button {
        background: $base;
        border: 2px solid $border-dark;
        color: $text-primary;

        &:hover {
            background: $base;
            color: $text-secondary;
            border-color: $border-secondary;
        }

        &:active {
            background: $bg-secondary;
            color: $text-secondary;
            border-color: $border-secondary;
        }

        &:focus {
            background: $base;
            color: $text-secondary;
            border-color: $border-secondary;
            outline: 1px dotted $border-secondary;
            outline-offset: 2px;
        }
    }
}