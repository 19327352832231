.bopisDialog {
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    
    &.ui-dialog {
        left: 50% !important;
        position: fixed;
        top: 50% !important;
        transform: translate(-50%, -50%);
        width: 420px;
        max-width: 420px;

        @include respond(medium-down) {
            width: 290px !important;
        }
    }
    .ui-dialog-content.ui-widget-content {
        padding: 4px 20px 30px;
    }
    .search-bar {
        width: 100%;
        min-height: auto;
    }

    .ui-dialog-title {
        @include ds-heading(h5);
        text-transform: capitalize;
        border-bottom: none;
        box-sizing: border-box;
        color: $cobalt;
        display: flex;
        justify-content: center;
        margin:20px 0 11px;
        padding:0 36px 0 36px;
        text-align: center;
        width:100%;

        &::before {
            content: '';
            background: url('../images/svg-icons/bopis-icon-small.svg') no-repeat;
            background-image: 18px 23px;
            display: flex;
            width: 18px;
            height: 23px;
            fill: $cobalt;
            padding: 0px 11px 0px 1px;
        }
    }

    .bopis-subheading {
        @include ds-ui-regular(m);
        color:$dim-gray;
        text-align: left;

        
        @include respond(medium-down) {
            margin: 0 auto;
            text-align: center;
            width: 250px;
        }

    }
    .bopis-currentlocation {
        @include ds-ui-semi(m);
        margin-top: 8px;
        text-align: center;

        .bopis-currentlocation-link {
            text-decoration: underline;
            @include night-rider-link; //:hover, :focus, :active states
        }
    }

    .bopis-search-bar {
        border:none;
        padding: 6px 0px 0 0px;
        margin-top: 14px;

        .bopis-form-row {
            width: 100%;
            margin-bottom: 0px;
            position: relative;

            label {
                @include ds-ui-bold(m);
                color: $night-rider;                
                margin-bottom: 4px;
            }

            .bopis-postal-code {
                @include ds-ui-regular(m);
                height: 48px;
                padding-right: 10px;
            }

            span.error {
                @include ds-ui-regular(s);
                top: unset;
                padding: 6px 0 0 12px;
            }
        }
    }

    .bopis-store-search {
        width: auto;
        position: absolute;
        top: 25px;
        right: 0;
        height: 48px;
        z-index: 1;
        display: flex;
        align-items: center;
        padding-right: 10px;

        &.show-search-icon {
            .remove-bopis-search {
                display: none !important;
            }

            .search-bopis {
                display: block !important;
                padding: 0;
                margin: 0;
                width: 100%;
                background: transparent;
                border: none;
                top: unset;
                height: auto;
                line-height: unset;
                min-height: 20px;

                .bopis-search-icon {
                    &::before {
                        content: '';
                        background: url('../images/svg-icons/search-icon.svg') no-repeat;
                        background-size: 20px 20px;
                        width: 20px;
                        height: 20px;
                        display: flex;
                    }                    
                }

                &:hover {
                    background: transparent;
                    border: none;

                    .bopis-search-icon {
                        &::before {
                            background-image: url('../images/svg-icons/search-icon-blue.svg');
                        }                    
                    }
                }

                &:focus {
                    .bopis-search-icon {
                        &::before {
                            background-image: url('../images/svg-icons/search-icon.svg');
                        }
                    }
                }
                
                &:active {
                    .bopis-search-icon {
                        &::before {
                            background-image: url('../images/svg-icons/search-icon-light.svg');
                        }
                    }
                }
                
            }
        }
        
        &.show-remove-icon {
            .remove-bopis-search {
                display: block !important;
                padding: 0;
                margin: 0;
                width: 100%;
                background: transparent;
                border: none;
                top: unset;
                height: auto;
                line-height: unset;

                .clear-bopis-search-field-icon {
                    content: '';
                    background: url('../images/svg-icons/UI-Close-dark.svg') no-repeat;
                    background-size: 16px 16px;
                    width: 16px;
                    height: 16px;
                    display: flex;
                }

                &:hover {
                    background: transparent;
                    border: none;
                    
                    .clear-bopis-search-field-icon {
                        background-image: url('../images/svg-icons/UI-Close-blue.svg');
                    }
                }

                &:focus {
                    .clear-bopis-search-field-icon {
                        background-image: url('../images/svg-icons/UI-Close-dark.svg');
                    }
                }
                
                &:active {
                    .clear-bopis-search-field-icon {
                        background-image: url('../images/svg-icons/UI-Close-light.svg');
                    }
                }
            }

            .search-bopis {
                display: none !important;
            }
        }
    }

   #bopisstores .bopis-stores-container { 
        margin-top: 20px;

        #bopis-select-store-form {
            display: flex;
            flex-direction: column;

            .setstorebtn {
                @include ds-utilities-button();
                align-self: center;
                margin-top: 20px;
                height: 48px;
                width: 100%;

                @media only screen and (min-width: $lMin) {
                    width: auto;
                }

            }

            .bopis-stores-wrap {
                max-height: 191px;
                overflow-y: auto;
                list-style-type: none;
                border:solid 1px $gainsboro;

                @include respond(medium-down){
                    max-height: 134px;
                }

                &.product-unavailable-stores-wrap {
                    border-color: $red;
                }
            }

            .bopis-store-listitem {
                position: relative;
                background-color: $white;
                padding: 0 11px 0 10px;

                &.active-list-item {
                    &.bopis-product-unavailable {
                        background-color: $medium-spring-green;
                    }
                }
                &.bopis-product-unavailable {
                    .bopis-product-availability {
                        color: $red;
                    }
                }

                &.bopis-product-available {
                    .bopis-product-availability {
                        color: $egg-blue;
                    }
                }

                .bopis-store-info-container {
                    border-bottom: 1px solid $gainsboro;
                    padding: 12px 0;
                }
                
                &.last-list-item {
                    .bopis-store-info-container {
                        border-bottom: none;
                    }
                }

                .bopis-store-list-headinfo {
                    display: flex;
                    align-items: center;
                }

                .bopis-store-name-and-details {
                    padding-left: 10px;
                    
                    .bopis-store-name {
                        @include ds-ui-bold(m);
                        color: $night-rider;
                        margin: 0;
                        text-transform: capitalize;
                        margin-bottom: 4px;
                    }

                    .bopis-store-show-details-link {
                        @include ds-ui-regular(s);
                        align-items: center;
                        color: $night-rider;
                        cursor: pointer;
                        display: flex;
                        float: left;

                    &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        padding-left: 2px;
                        @include transition(all 0.5s ease-out);
                        background-size: 10px;
                        background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
                    }

                    &.active {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                    }
                }

                .bopis-store-dist-container {
                    margin-left: auto;
                    clear: both;
                }

                .bopis-store-distance {
                    @include ds-ui-regular(s);
                    margin-bottom: 6px;
                    text-align: right;
                    white-space: nowrap;
                }

                .bopis-product-availability {

                    font-size: 12px;
                    letter-spacing: 0.25px;
                    line-height: 16px;
                    text-align: right;
                    white-space: nowrap;
                    margin-top: auto;
                    color: $green;
                }

                .bopis-product-unavailable {
                    .bopid-product-availability {
                        color: $red;
                    }
                }

                .bopis-collapsedinfo {
                    @include ds-ui-regular(s);
                    padding-left: 23px;
                    padding-top: 10px;
                    color: $dim-gray;

                    .bopis-store-address {
                        a {
                            text-transform: capitalize;
                            display: inline-flex;
                            @include night-rider-link;
                        }
                    }

                    .bopis-store-phone-no {
                        margin-top: 15px;
                        a {
                            @include night-rider-link;
                        }
                    }

                    .bopis-store-hours {
                        margin-top: 18px;
                        .bopis-store-time {
                            text-transform: lowercase;
                        }
                    }

                }
            }

            .product-un-available-in-store-err-msg {
                color: $red;

                font-size: 12px;
                font-weight: 300;
                line-height: 16px;
                padding: 7px 14px 0;
            }
        }
    }
    
    .current-location-not-found {
        width: 223px;
        margin: 15px auto 3px;
        color: $red;

        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        text-align: center;
        padding: 0 19px 0 8px;
        @include respond(large-up) {
        	width: 100%;
            padding: 0;
            br {
                display: none;
            }
        }
    }
}

.bopis-mobileheader{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    padding: 20px 24px 15px 15px;
    background: $white;
}
.bopis-subtext {
    @include ds-ui-regular(s);
    color: $dim-gray;
    display: flex;
}

.bopis-mobile-parent{
    text-align: left;
    .bopis-txt{
        padding-bottom: 5px;
        display: flex;
    }
}

.bopis-mobileheader {

    @include respond(large-up) {
        display: none !important;
    }

    .bopis-set-store {
        @include respond(medium-down){
            @include ds-ui-semi(m);
            padding: 0;
            color: $night-rider;
            text-decoration: underline;
            margin: 12px 0 0 34px;
            text-transform: capitalize;
        }
    }
}

.bopis-loader {
    position: absolute;;
    width:100%;
    height: 100%;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: rgba(255,255,255,0.9);
    z-index:9999;

    .we-are-pulling-store {
        @include ds-ui-regular(s);
        color: $dim-gray;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        
        &::before{
            content:"";
            display:block;
            background-image: url('../images/spinner-rewards.gif');
            background-size: 32px 32px;
            width: 32px;
            height: 32px;
            margin: 0 auto 5px;
        }
    }
}
.bopis-orderlimits-unavailable {
    text-align: center;
    max-width: 650px;
    margin: 20px auto 122px;
    color: #333;

    @include respond(large-up) {
        margin-top: 46px;
    }

    .orderlimits-pickupunavailable-message {
        color: $night-rider;

        font-size: 16px;
        letter-spacing: -0.23px;
        line-height: 19px;
        text-align: center;
        margin: 0 0 8px;
        text-transform: none;
    }
    .orderlimits-storeunavailable-message {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: $dim-gray;

        padding: 2px 28px 0;
        margin: 0;

        .capped-name {
            color: $night-rider;

            text-transform: capitalize;
        }
    }
    .orderlimits-selectanotherstore-message {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: $dim-gray;

        margin: 0 0 8px;

        .bopis-set-store {
            color: $night-rider;
        }
    }
}