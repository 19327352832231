.ds-input-field {
    label {
        @include ds-ui-semi(m);
        height: auto;
        margin-bottom: 4px;
    }

    .input-text {
        @include ds-ui-regular(l);
        padding: 16px 8px 16px 16px;
    }

    .form-row {
        margin: 0;
        padding-bottom: 8px;
    }

    span.error {
        @include ds-ui-regular(s);
        color: $red;
        display: none;
        text-align: left;
        padding-bottom: 8px;
        padding-left: 0px;
        margin: 0;
    }
}

.ds-user-info-collection-form {
    .ds-submit-button {
        margin-top: 8px;
    }
}

.ds-success-message {
    @include ds-ui-regular(s);
    color: $egg-blue;
    display: none;
    text-align: left;
    padding-bottom: 8px;
    margin: 0;
}