.ds-top-offers-grid {
    display: grid;
    grid-column-gap: 32px;
    margin-bottom: 104px;
    row-gap: 32px;

    @media screen and (min-width: $mMin) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: $lMin) {
        grid-template-columns: repeat(3, 1fr);
    }
    
    @media screen and (min-width: $xlMin) {
        grid-template-columns: repeat(4, 1fr);
    }
}
