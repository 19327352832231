.design-system {
    .ds-shop-the-module-container {
        max-width: none;

        .ds-single-link-image-container,
        .ds-link-image-container {
            margin: 0 16px;
        }

        .ds-graphic-and-tile-container {
            overflow: hidden;
            margin: 0 16px;

            .shop-the-module-scroll {
                overflow: scroll;
                display: flex;
                flex-flow: row nowrap;
                scrollbar-width: none;
                padding-top: 16px;
                column-gap: 16px;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .ds-link-image {
                max-height: 376px;
                margin-bottom: 16px;
            }

            .ds-shop-the-image-product-tile {
                .add-to-cart {
                    @include ds-utilities-button;

                    &:disabled {
                        background-color: $white-smoke;
                        color: $dark-gray;
                        border-color: $white-smoke;
                    }
                }
            }

            .ds-single-product-tile-wrapper .ds-shop-the-image-product-tile {
                margin: 0 16px;
                padding-top: 8px;

                .product-tile img {
                    max-height: 195px;
                    height: auto;
                }
            }
        }

        .shop-the-module-slide {
            min-height: 100%;
            margin-top: auto;
            margin-bottom: 0;
            flex: 1;
            min-width: 253px;

            .ds-shop-the-image-product-tile .product-tile {
                a {
                    user-select: none;
                }

                img {
                    pointer-events: none;
                    user-select: none;
                    max-height: 160px;
                    height: auto;
                }
            }
        }

        .ds-marketing-copy {
            padding-bottom: 24px;
            margin: 0px 16px;

            .ds-marketing-copy-paragraph {
                padding-bottom: 0px;
            }

            .ds-margin {
                margin-bottom: 16px;
            }

            .ds-margin:last-child {
                margin-bottom: 0px;
            }
        }

        &.ds-without-media {
            .ds-graphic-and-tile-container {
                padding: 0;
            }
            .ds-marketing-copy {
                padding-bottom: 16px;
            }
            .shop-the-module-slide {
                min-width: 228px;
            }
        }

        .product-promo {
            display: flex;
        }
    }
}

.design-system {
    @media screen and (min-width: $mMin) {
        .ds-shop-the-module-container {
            .ds-graphic-and-tile-container {
                .ds-link-image-container,
                .ds-single-link-image-container {
                    margin: 0;

                    .ds-link-image {
                        margin-bottom: 0;
                        max-height: none;
                    }
                }

                .ds-single-product-tile-wrapper .ds-shop-the-image-product-tile {
                    margin: 0 112px;
                    padding-top: 24px;
                }

                .shop-the-module-scroll {
                    margin-top: 24px;
                    margin-bottom: 24px;
                }
            }
        }
    }
}

.design-system {
    @media screen and (min-width: $lMin) {
        .ds-shop-the-module-container {
            max-width: 900px;
            margin: auto;

            .ds-single-link-image-container,
            .ds-link-image-container {
                margin: 0;
            }

            .ds-video-container {
                padding: 0;
                margin-bottom: 40px;
            }

            .ds-marketing-copy {
                margin: 0 auto;
                max-width: 560px;
            }

            .ds-graphic-and-tile-container {
                align-items: center;
                box-sizing: border-box;
                column-gap: 24px;
                display: inline-grid;
                grid-template-columns: repeat(12, 1fr);
                height: auto;
                margin: auto;
                max-height: 462px;
                overflow: hidden;
                padding-left: 24px;
                padding-right: 24px;
                width: 100%;

                .ds-single-link-image-container {
                    grid-column: 1/10;
                }

                .ds-link-image-container {
                    grid-column: 1/7;
                }

                .ds-single-link-image-container,
                .ds-link-image-container {
                    .ds-link-image {
                        width: 100%;
                        height: auto;
                        padding: 0;
                        max-height: 310px;
                        margin-bottom: 0;

                        img {
                            max-width: 100%;
                        }
                    }
                }
                .ds-single-product-tile-wrapper {
                    grid-column: 10/13;

                    .ds-shop-the-image-product-tile {
                        height: 100%;
                        margin: auto;
                        padding-top: 0px;

                        .product-tile img {
                            max-height: 160px;
                            height: auto;
                        }
                    }
                }

                .shop-the-module-scroll {
                    grid-column: 7/13;
                    margin: 0;
                    min-width: 0;
                    margin-top: 0;
                    column-gap: 24px;
                }
            }

            .shop-the-module-slide {
                min-width: 150px;
                padding-bottom: 20px;

                .ds-shop-the-image-product-tile {
                    .product-tile {
                        img {
                            max-height: 160px;
                            height: auto;
                        }

                        .product-review {
                            display: none;
                        }
                    }
                }

                .ds-shop-the-image-product-tile {
                    .add-to-cart {
                        padding: 0px 12px;
                    }
                }
            }

            &.ds-without-media {
                .ds-graphic-and-tile-container {
                    display: block;
                }
            }
        }

        .ds-single-product {
            padding-right: 16px;
        }

        .ds-video-container {
            margin-bottom: 0;
        }
    }
}

.design-system {
    @media screen and (min-width: $xlMin) {
        .ds-shop-the-module-container {
            max-width: 1200px;

            .ds-marketing-copy {
                max-width: 568px;
            }

            .ds-graphic-and-tile-container {
                column-gap: 32px;
                max-height: 616px;
                padding-left: 16px;
                padding-right: 16px;

                .ds-single-link-image-container,
                .ds-link-image-container {
                    .ds-link-image {
                        max-height: 426px;
                    }
                }

                .product-tile img {
                    max-height: 160px;
                }

                .shop-the-module-scroll {
                    column-gap: 32px;
                    overflow-x: auto;
                    overflow-y: hidden;
                    display: flex;
                    flex-flow: row nowrap;
                    min-width: 100%;
                    margin-top: 16px;
                    column-gap: 32px;

                    &::-webkit-scrollbar {
                        height: 8px;
                        display: inline;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 8px;
                        background-color: $bg-tertiary;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-track-piece {
                        border-radius: 8px;
                    }
                }
            }

            .shop-the-module-slide {
                min-width: 200px;

                .ds-shop-the-image-product-tile {
                    .product-tile {
                        max-width: 256px;
                        
                        img {
                            max-height: 160px;
                        }
                    }

                    .add-to-cart {
                        padding: 0px 16px;
                    }
                }
            }

            &.ds-without-media .ds-graphic-and-tile-container {
                margin: 0 16px;
                width: auto;
            }
        }
    }
}
