.design-system {
    .ds-marketing-copy {
        text-align: center;
        display: inline-block;

        .ds-margin {
            display: inline-block;
        }
        
        .ds-marketing-copy-cta-link {
            display: inline-block;
            margin: 0 0 0 2px;
        }
    }

    .ds-marketing-copy + .ds-popover {
        display: inline-block;
        position: relative;

        .ds-tooltip-button {
            padding: 0;
        }
            
        &:before {
            content: "•";
            display: inline-block;
            @include ds-ui-regular(l);
            color: $text-secondary;   
            margin: 0 4px;   
        }
    }

    .ds-marketing-copy-heading {
        @include ds-heading(h2);
        text-transform: none;
        color: var(--customColor, $text-primary);
        margin: 0;
    }

    .ds-marketing-copy-eyebrow {
        @include ds-ui-bold(l);
        color: $text-primary;
    }

    .ds-marketing-copy-paragraph,
    .ds-marketing-copy-live-text {
        @include ds-ui-regular(l);
        margin: 4px 0 0 0;
        padding-bottom: 16px;
    }

    .ds-marketing-copy-paragraph {
        color: var(--customColor, $text-secondary);
    }

    .ds-marketing-copy-live-text {
        padding: 0;

        p,
        a {
            @include ds-ui-regular(l);
        }

        a {
            color: $dim-gray;
        }

        p {
            margin: 0;
        }
    }

    .ds-marketing-copy-cta-link .ds-link {
        @include ds-ui-semi(l);
    }

    .ds-marketing-copy.ds-sub-module {
        margin-top: 16px;

        .ds-marketing-copy-paragraph {
            margin: 0;
            padding-bottom: 8px;
        }

        .ds-margin:only-child .ds-marketing-copy-paragraph:last-child {
            padding-bottom: 0;
        }

        @media screen and (min-width: $mMin) {
            max-width: 568px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
