.pt_product-search-result {
    #secondary {
        @include respond(large-up) {
            margin-top: -2px;
        }
        @include respond(large-up) {
            min-width: 160px;
        }
        
        @include respond(large) {
            min-width: 152px;
        }
        .category-top-offers {
            .toggle {
                margin-top: 0;
            }
            ul {
                padding: 0;
                margin: 19px 0 30px;
                @include respond(large-up) {
                    margin-bottom: 49px;
                }
            }
        }
    }
    footer{
        @include respond(large-up) {
            margin-top: 0;
        }
    }
}

.pt_product-search-result .primary-content,
.pt_content-search-result {

    @include respond(medium-down) {
        .folder-content-list{
            &.folder-list {
                padding: 0 15px;
            }
            li {
                float: none;
                width: 100%;
                box-sizing: border-box;
                clear: both;
            }
        }
    }
}

.pt_product-search-result .primary-content h1.content-header {
    padding-left: 15px;
}

.category-main-banner img {
    max-width: 100%;
}

.no-hits-search-term,
.no-hits-search-term-suggest {
    text-decoration: underline;
    @include font-bold(18px);
}

.no-hits-footer {
    margin: 40px 0 90px;
    overflow: hidden;
    @include respond(large-up) {
        margin: 40px 0;
    }
    img {
        width: 100%;
        vertical-align: top;
    }
}

.search-result-bookmarks {
    margin: 15px 0;

    .first {
        border-right: 1px solid $nobel;
        padding-right: 5px;
    }
}

.search-result-options {
    margin: 0 0 21px;
    width: 100%;
    position: relative;

    &.content-options {
        margin: 0;
        @media only screen and (max-width: $mMax) {
            padding: 0 15px;
            margin: 10px 0 0;
            box-sizing: border-box;
            &.top {
                display: none;
            }
        }
    }
    .ds-subcategory-heading {
        @include ds-heading(h2);
        color: $text-primary;
        border-bottom: 1px solid $secondary-border;
        padding-bottom: 16px;
        margin: 5px 0 32px;
    }

    h1 { 
        width: 100%;
    }
    .search-result-title {
        &.hidden {
            border: 0;

            @media only screen and (min-width: 901px) {
                opacity: 0;
            }

            @media only screen and (max-width: $mMax) {
                display: none;
            }
        }
    }
    .results-hits{
        position: absolute;
        right: 0;
        top: 12px;
        display: block;
        @include ds-ui-regular(s);
        color: $text-secondary;

        &.hide-count {
            visibility: hidden;
        }
    }

    @media only screen and (max-width: $mMax) {
        margin: .5em 0;
    }

    .sort-by {

        @media only screen and (max-width: $mMax) {
            margin: 0;
        }
    }

    .items-per-page {

        @media only screen and (max-width: $mMax) {
            display: none;
        }
    }

    .pagination {

        @media only screen and (max-width: $mMax) {
            margin: 40px 0 0;
        }

        @include respond(large) {
	        margin: 0;
	    }

        .results-hits {

            @media only screen and (max-width: $mMax) {
                display: none;
            }
        }
    }

    &.top {

        @media only screen and (max-width: $mMax) {
            margin: 0 0 50px;
            position: relative;

            .results-hits{
                @include ds-ui-regular(s);
                color: $text-secondary;
                width: 100%;
                text-align: center;
                padding-top: 5px;
                position: absolute;
                bottom: -30px;
                top: initial;

                @media only screen and (min-width: 901px) {
                    @include font-regular(16px);
                }
            }
        }
    }

    &.top {
        display: flex;
        flex-wrap: wrap;

        .grid-sort-menu.field-wrapper {
            flex: 1;

            @media only screen and (min-width: $lMin) {
                flex: 1;
                margin-left: 20px;
                width: 15.625%;
            }

            .select-style {

                @media only screen and (min-width: 901px) and (max-width: 1199px) {
                    margin-top: 38px;
                }

                @media only screen and (min-width: 901px) {
                    background-color: transparent;
                    border-color: $white;
                    float: right;
                    max-width: 182px;
                }

                @media only screen and (max-width: $mMax) {
                    border: 1px solid $brand-black;
                    border-left: none;
                    height: 100%;
                }
            }

            select {

                &:hover {

                    @media only screen and (max-width: $mMax) {
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
    &.bottom {

        @include respond(medium-down) {
            margin: 40px 0 .5em 0;
        }
        .pagination {
            margin-top: 11px;
            position: static;

            @include respond(medium-down) {
                text-align: right;
                margin-bottom: 16px;
            }
        }
    }
    // adding default styling for Graphic Header content assets - added 3/17/2020
    .graphic-header {
        margin: 0 0 10px;
        display: block;
    }
}

.sort-by,
.items-per-page {
    margin: 0;
    padding: 0;

    label {
        display: none;

        @media only screen and (min-width: 901px) and (max-width: 1199px) {
            display: none;
            visibility: hidden;
        }
    }

    select {
        @include borderbox;
        @include ds-ui-semi(m);
        color: $text-primary;
        background-image: url('../images/svg-icons/filter-carat.svg'), linear-gradient(to bottom, $white 0%, $white 100%);
        background-position: right 10px top 50%, 0 0;
        background-repeat: no-repeat, repeat;
        background-size: 15px 9px, 100%;
        border-radius: 0;
        font-style: normal !important;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (min-width: $lMin) {
            @include ds-heading(h4);
            color: $text-primary;
            background-position: right 0 top 75%, 0 0;
            border: none;
            border-bottom: 3px solid $secondary-border;
            padding: 2px 1rem 0 0;
            text-transform: capitalize;
        }

        @media only screen and (max-width: $mMax) {
            height: 100%;
            padding: 10px 20px 10px 10px !important;
        }

        @media only screen and (max-width: 375px) {
            height: 100%;
        }

        @media only screen and (max-width: 350px) {
            height: 100%;
        }

        &:hover {

            @media only screen and (min-width: 901px) {
                padding-right: 0;
                width: calc(100% - 2px);
            }
        }

        option {
            text-transform: none;

            &:nth-child(1),
            &:checked {
                font-style: normal !important;
            }
        }
    }

    button {
        background: none repeat scroll 0 0 $very-light-gray;
        border: 0 none;
        color: $night-rider;
        font-size: 0.9em;
        padding: 0.3em;
        width: auto;
    }
}

.pagination {
    position: absolute;
    right: 81px;
    top: 2px;

    &.bopis-on {
        right: 0;
    }

    @include respond(mid-large) {
	    right: 0;
	}

    ul {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        @include respond(medium-down) {
            overflow: hidden;
            text-align: left;
        }
    }

    li {
        display: block;
        float: left;
        list-style: none;
        padding: 5px 2px;
        @include respond(medium-down) {
        padding: 8px 2px 2px;
        }
        > a{
            @include ds-ui-semi(m);
            color: $text-secondary;
            padding: 5px 8px;

            @media only screen and (max-width: $mMax) {
                padding: 8px 13px 2px;
            }
        }
        &.first-last{
            position: relative;
            padding: 5px 12px;
            transform: rotate(180deg);
            @include respond(medium-down) {
                padding: 8px 15px 2px;
            }
            > a{
                text-transform: uppercase;
                @include font-bold(14px);
                color: $secondary-color;
                padding: 0;
            }
            .page-previous, .page-next{
                    display: inline-block;
                    .pagination-hand{
                        width: 9px;
                    }
            }
            .pagination-hand{
                position: absolute;
                width: 17px;
                height: 25px;
                top: 5px;
                left: 5px;
                fill: $primary-color;
                @include respond(medium-down) {
                    top: 2px;
                }
            }
            &.next{
                transform: rotate(0deg) !important;

                .pagination-hand{
                    @include respond(medium-down) {
                        top: 8px;
                    }
                }
            }
        }
        &.current-page{
            background: $bg-color;
            @include borderRadius(30px);
            @include ds-ui-semi(m);
            color: $text-primary;
            padding: 5px 0;
            width: 32px;
            text-align: center;

            @media only screen and (max-width: $mMax) {
                margin-top: 3px;
            }

        }
    }

    .results-hits {
        color: $dim-gray;
    }

    // new styles for Load More section of the product grid 7/2020
    .bottom-controls-container {
        text-align: center;
        margin-bottom: 80px;
		.progress-bar-container {
            .progress-message {
                @include font-size(16px);
                color: $brand-black;
            }
            .progress-bar {
                display: block;
                width: 186px;
                background-color: #e5e5e5;
                height: 3px;
                margin: 16px auto 29px;
            }
            .progress-marker {
                height: 100%;
                width: 50%;
                background-color: $brand-blue;
                display: block;
            }
        }

		.btn {
            &.load-more-btn {
                background-color: $white;
                padding: 10px 25px;
                border: $brand-black solid 3px;
                color: $brand-black;

                @include font-size(14px);
                line-height: 17px;
                letter-spacing: 1.4px;
                max-height: 43px;
                display: block;
                margin: 0 auto 15px;
                text-transform: uppercase;
                width: 300px;
                @include respond(small-down){
                    width: auto;
                }
                &:hover,
                &:active {
                    background-color: $brand-black;
                    color: $white;
                }
            }
			&.back-to-top-btn {

                @include font-size(16px);
                cursor: pointer;
                margin-top: 2px;
                margin-left: 8px;
                color: $primary-color;
                text-decoration: underline;
                display: block;
                margin: 0 auto;
                width: 120px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &.infinite-scroll-test {
        display: none;
    }
}

body.infinite-scroll-disabled {
    .pagination.infinite-scroll-test {
        display: block;
    }
}

.search-result-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    li {
        list-style: none outside none;
    }

    .new-row {
        //clear: both;
    }

    .grid-tile {
        background: none repeat scroll 0 0 $white;
        display: flex;
        flex-basis: 47.245%;
        list-style: none outside none;
        margin-bottom: 30px;
        margin-left: 0;
        margin-top: 20px;
        padding: 0;
        width: auto;
        @include respond(small-down){
            &.even {
                margin-left: 15px;
            }
            &:nth-last-child(-n+2) {
                margin-bottom: 14px;
            }
        }
        @include respond(medium-up){
            flex-basis: 31%;
            margin-bottom: 50px;
            margin-left: 3%;
            margin-top: 30px;
            &:nth-child(3n+1){
                margin-left: 0;
            }
        }
        @include respond(desktop){
            flex-basis: 21.107%;
            margin-left: 5.1%;
            &:nth-child(3n+1){
                margin-left: 5.1%;;
            }
            &:nth-child(4n+1){
                margin-left: 0;
            }
        }
        .product-tile {
            display: block;
            max-width: 248px;
            margin: 0 auto;
        }
    }

    .invisible {
        display: none;
    }

    @include respond(large) {
        ul.search-result-items {
            .new-row {
                clear: none;
            }

            .grid-tile {
                width: 41.5%;
            }
        }
    }

    .wide-tiles & {
        .grid-tile {
            width: 91%;
        }

        .product-tile {
            .product-swatches {
                bottom: auto;
                left: auto;
                position: relative;
                right: auto;
                margin-top: 5px;

                li {
                    float: left;
                }
            }
        }
    }
}

.search-result-content {
    clear: both;
}

.search-promo {
    padding: 20px;
    h2 {
        @include font-size(24px);
        line-height: 1.125;
        text-transform: capitalize;
    }
}

.results-content-header {
    &#results-content {
        padding-top: 30px;
    }
    .articles-hits {
        float: right;
        display: block;
        @include font-regular(13px);
        margin-top: 4px;
    }
}

.folder-content-list {
    margin: 0;
    overflow: hidden;
    padding: 0;
    &.folder-content {
        @include respond(medium-down){
            padding: 0 15px;
        }
    }
    li {
        list-style: none outside none;
        margin: 0;
        padding: 2%;
        border-bottom: 1px solid $gainsboro;
        @extend %clearfix;
        &:last-child {
            border-bottom: 0 none;
        }
        &:hover{
            background: $bg-color;
        }
        a.content-title{
            text-transform: uppercase;
            @include font-regular(13px);
            color: $secondary-color;
        }
        p{
            a.readmore{
                @extend .button;
                text-decoration: none;
                float: right;
                margin-top: 10px;
            }
        }
    }

    .content-title {
        display: block;
    }
}

.folder-content-options {
    @include respond(medium-down){
        text-align: center;
        margin-top: 10px;
    }
}

.rendering-content {
    @include respond(medium-down){
        .back {
            padding: 0 15px;
        }
        .content-asset {
            p {
                padding: 0 15px;
            }
            h3 {
                padding: 0 15px;
            }
        }
    }
}

.no-results {
    padding: 20px;
}

.toggle-grid {
    display: block;
    float: left;
    margin-right: 0.5em;

    i {
        cursor: pointer;
        margin-right: 0.3em;
    }

    [data-option="column"] {
        color: $citrus;
    }

    &.wide {
        [data-option="wide"] {
            color: $citrus;
        }

        [data-option="column"] {
            color: $dim-gray;
        }
    }
}

.filter-show {
    @extend %button-wide;
    @include ds-ui-semi(m);
    color: $text-primary;
    text-transform: capitalize;
    border-color: $brand-black;
    background-repeat: no-repeat;
    background-color: $white;
    background-image: url('../images/svg-icons/filter-button-icon.svg');
    background-position: right 10px top 50%;
    background-size: 13px 10px;
    max-height: none;
    // padding: 0;
    padding: 10px;
    text-align: left;
    width: 50%;

    @media only screen and (max-width: $mMax) {
        // flex: 50%;
        flex: 0 1 50%;
    }

    &.sort-disabled {

        @media only screen and (max-width: $mMax) {
            flex: 0 1 100%;
        }
    }

    .search-result-options.top &.mobile-only {

        @media only screen and (max-width: $mMax) {
            display: block;
        }
    }

    &:hover {
        color: $white-smoke;
        border-color: $brand-black;
        background-color: $white-smoke;
    }
    &:active {
        color: $white;
        border-color: $brand-black;
        background-color: $brand-black;
    }
}

/*Shop By fragrance page, Remove the top-of-the-page load more button*/
.search-result-options.top .pagination #loadMoreControls {
    display: none;
}

.search-result-options.top {

    .filter-overflow {
        background-color: $white-smoke;
        
        @media only screen and (min-width: 901px) {
            display: flex;
            flex-wrap: wrap;
        }

        @media only screen and (max-width: $mMax) {
            background-color: $white;
        }
    }

    .m-selected-values {
        width: 100%;
    }
}

.filter-overflow{
    @media only screen and (min-width: 901px) and (max-width: 1199px) {
        display: block;
    }

	@media only screen and (min-width: 901px) {
        position: relative;
        display: inline-block;
        // width: 81%;
        width: 78.799%;
	}

    @media only screen and (max-width: $mMax) {
        overflow-y: auto;
        position: fixed;
        bottom: -100%;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 115;
        @include transition(bottom 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94));
        &.active{
            background-color: $white;
            bottom: 0;
            .outer-close-filters{
                display: block;
            }
            .mobile-only {
                display: block !important;
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 900px) {
        flex: 1;
    }

    &.sort-disabled {

        @media only screen and (min-width: 901px) {
            width: 100%;
        }
    }

    .outer-close-filters{
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px;
        width: 100%;
        border-top: 1px solid $primary-border;
        background: $white;
        z-index: 112;
        @include borderbox;
        display: none;

        @media only screen and (min-width: 901px){
            bottom: -50px;
        }
    }
    
    .close-filters{
        @extend %button-wide;
        @include ds-utilities-button-labels;
        color: $text-inverse;

        @media only screen and (max-width: $mMax) {
            max-height: none;
            line-height: calculateRem(48px);
        }
    }
}

.filter-title{
    display: inline-block;
    vertical-align: top;
    margin: 18px 0 0 15px;
    @include ds-heading(h3);
    color: $text-brand-primary;
    text-transform: capitalize;

    @media only screen and (max-width: $mMax) {
        width: auto;
        padding: 23.5px 15px;
        margin: 0;

        span.mobile-only{
            position: absolute;
            right: 15px;
            top: 17px;
            width: auto;
            svg, img.icon{
                width: 20px;
                height: 20px;
            }
        }
    }

    @media only screen and (min-width: $lMin) {
        @include ds-heading(h5);
        color: $text-primary;
        margin-top: 22px;

    }

    &:after {
        content: ":";
    }
}

.filters{
    // width: 100%;
    display: inline-block;
    max-width: 800px;
    margin: 0;
    @include respond(large-up){
        display: flex;
        flex: 1;
        justify-content: flex-start;
        margin-right: 18px;
    }

    @media only screen and (max-width: $mMax) {
        margin-bottom: 75px;
        background: $white;
        display: block;
        // width: 100%;
    }

    .refinement{
        display: inline-block;
        vertical-align: text-top;
        width: 100%;
        min-width: calculateRem(225px);
        max-width: calculateRem(250px);
        // margin: 0 7px 0 8px;
        margin: 0 0 0 18px;
        z-index: 1;
        padding-bottom: 0;
        cursor: pointer;
        white-space: nowrap;
        @media only screen and (max-width: 1440px) {
            flex: 1 1 100%;
            max-width: 31.5%;
            min-width: fit-content;
            
        }
        @include respond(medium){
            // margin: 0 .5%;
        }
        @include respond(search-max){
            width: calculateRem(256px);
            // margin: 0 10px;
        }
        &:first-child {
            // margin-left: 0;
        }
        &.last {
            // margin-right: 0;
        }
        
        @media only screen and (max-width: $mMax) {
            width: 100%;
            max-width: initial;
            margin: -1px 0 0 0;
            padding-bottom: 0;

            h3{
                border-top: 1px solid $primary-border;
                border-bottom: 1px solid $primary-border;
                padding: 20px 15px;
                margin: 0;
                @include ds-heading(h4);
                color: $text-primary;
                text-transform: capitalize;
                &.expanded{
                    background: $bg-color;
                    border:none
                }
            }
            &.open {
                .selected-refinement {
                    display: none;
                }
            }
        }
        &.nonactive {
            display: none;
            &:after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                width: 100%;
                height: 57px;
                background: $white;
                opacity: 0.75;
                cursor: default;
            }
        }
        .ref-block{
            display: none;
            position: absolute;
            left: 0;
            top: 37px;
            border: 1px solid $bg-color;
            border-bottom: 3px solid $secondary-border;
            background: $white;
            width: 100%;
            @include borderbox;
            padding: 15px 0 58px 0;

            @media only screen and (min-width: $xlMin) {
                top: 43px;
            }

            @media only screen and (max-width: $mMax) {
                position: relative;
                border: none;
                top: -1px;
                padding: 16px 0 15px;
            }
            .close-button{
                position: absolute;
                bottom: -2px;
                left: -1px;
                width: calc(100%  - -2px);
                text-align: center;
                padding: 0 0 15px;
                border-bottom: 3px solid $secondary-border;
                a.button{
                    @include borderbox;
                    @include ds-utilities-button;
                    width: calc(100%  - 26px);
                    max-width: 182px;
                }

                @media only screen and (min-width: $lMin) {
                    display: flex;
                    justify-content: center;
                }
            }
            .scrollable {
                height: 200px;
                @include respond(medium-down) {
                    height: auto;
                }

                @include respond(large) {
                    height: auto;
                }
            }

            ul {
                @media only screen and (min-width: $xlMin) {
                    margin-bottom: 16px;
                }
            }

            li{
                padding: 0 1px;
                @include respond(large-up) {
                    @include font-size(13px);
                }
                @include respond(medium-down){
                    padding: 10px 15px;
                }
                @include respond(large-down){
                    white-space: normal;
                }
                &.selected{
                    i.fa{
                        background: $secondary-color;
                    }
                    a {
                        color: $primary-color;
                    }
                }
                a {
                    display: block;
                    padding:6px 0 6px 25px;
                    @include ds-ui-bold(m);
                    color: $text-secondary;
                    @include line-height(18px);
                    &:hover {
                        color: $primary-color;
                    }
                    @include respond(medium-down){
                        padding-left: 33px;
                    }
                }
                i.fa{
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border: 1px solid $secondary-color;
                    position: relative;
                    top: 2px;
                    margin: 0 8px 0 -25px;
                    @include respond(medium-down){
                        margin: 0 18px 0 -33px;
                    }
                    @include respond(large-up) {
                        top: 3px;
                    }
                }
            }
            &#category-level-1{

                > li{
                    margin-bottom: 0;
                    .refinement-link{
                        padding-bottom: 0;
                    }
                }
            }
        }
        @include respond(medium-down) {
            .selected-refinement .refinement-wrap {
                margin: 10px 16px 11px;
            }
        }
        &.custom-filter {
            .ref-block{
                @include respond(large-up) {
                    padding-bottom: 66px;
                }
            }
            .grouping {
                padding: 0 9px;
                overflow-x: hidden;
                &.scrollable {

                    @media only screen and (min-width: $xlMin) {
                        margin-bottom: 16px;
                    }

                    @media only screen and (min-width: $lMin) {
                        height: 274px;
                    }

                    @include respond(large) {
                        height: auto;
                    }
                }
                @include respond(medium-down){
                    padding: 0;
                    li {
                        padding: 5px 12px;
                    }
                }
                ul {
                    padding: 3px 10px;
                    @include respond(medium-down){
                        padding: 0;
                    }
                }
            }
            .close-button {
                .button {
                    max-width: 214px!important;
                    width: calc(100%  - 28px)!important;
                }
            }
        }
    }
    .clear-refinement{
        a{
            text-decoration: underline;
            @include font-size(14px);
            color: $secondary-color;
            position: absolute;
            white-space: nowrap;
            @include respond(large-up){
                left: 100px;
                top: 10px;
                text-decoration: none;
                display: inline-block;
                box-sizing: border-box;
                text-align: center;
                width: 70px;
                @include line-height(32px);
                background: #333;
                color: $white;
                border: 1px solid transparent;
                &:hover {
                    background: $white;
                    color: #333;
                    border-color: #e5e5e5;
                }
                &:active {
                    background: $white-smoke;
                    color: $secondary-color;
                    border-color: $white;
                }
            }
            @media screen and (min-width: 901px){
                left: 20px;
                top: calc(1rem + 37px);
            }
            @include respond(medium-down){
                @include font-size(12px);
                top: 26px;
                left: 100px;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
    .safari & {
        .refinement{
                .ref-toggle {
                    .fa {
                        margin-top: 0;
                    }
                }
        }
        .filter{
            ul{
                > li{
                    .title{
                        padding-top: 3px;
                    }
                }
            }
        }
    }
    .filter{
        .filtering{
            position: relative;
            top: -16px;
            height: 34px;
            background: $white;
            @include respond(medium-down){
                overflow: hidden;
            }
            input{
                @include ds-ui-regular(l);
                color: $text-secondary;
                background: transparent;
                background-size: 20px;
                padding-right: 50px;
                margin: 0 -1px;
                width: calc(100% + 2px);
                position: relative;
                z-index: 11;
                height: 50px;
                border-bottom-color: $secondary-color;
                padding-left: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .noresult{
                text-align: center;
                padding-top: 12px;
            }
            &:after {
                content: '';
                background: url('../images/svg-icons/UI-Search.svg') no-repeat scroll center;
                position: absolute;
                height: 20px;
                width: 20px;
                right: 23px;
                top: 15px;
            }
        }
        ul{
            > li{
                .title{
                    background: $bg-color;
                    padding: 1px 0 0 25px;
                    margin: 0 -11px 3px -11px;
                    @include font-bold(14px);
                    color: $primary-color;
                    box-sizing: border-box;
                    @include respond(medium-down){
                        margin: -4px -15px 4px -15px;
                        padding-left: 30px;
                    }
                }
            }
        }
    }
}

.selected-refinement{
    .refinement-wrap {
        display: block;
        .refinement-value {
            display: inline-block;
            margin: 0 0 10px;
            background: $white;
            border: 1px solid #e5e5e5;
            padding: 8px 40px 8px 10px;
            @include font-size(14px);
            color: $secondary-color;
            position: relative;
            .remove-relax {
                position: absolute;
                right: 8px;
                top: 8px;
                .icon {
                    width: 17px;
                    height: 17px;
                }
            }
            @include respond(medium-down) {
                background-color: $white-smoke;
            }
        }
    }
    .m-selected-values & {
        .refinement-wrap {
            margin-top: 15px;
            .refinement-value {
                display: block;
                clear: both;
                float: left;
            }
        }
    }
}

.pt_product-search-noresult {
	&#wrapper {
        #main {
            @include respond(medium-down) {
                padding: 0;
            }
        }
	}
    .breadcrumb {
        @include respond(medium-down) {
            padding-bottom: calculateRem(20px);
        }
    }
    &#wrapper #main{
        @include respond(desktop) {
            margin-top: 10px;
        }
    }

    .no-hits-banner{
        overflow: hidden;
        * {
            &:first-of-type {
                @include respond(large-up){
                    margin-bottom: 70px;
                } 
            } 
        }
        img {
            width: 100%;
            vertical-align: top;
        }
    }
    .wrap-search{
        text-align: center;
        h1{
            border-bottom: none;
            @include font-regular(48px);
            @include line-height(45px);
            padding: 37px 20px 0;
            margin: 0;
            @include respond(large-up){
                padding: 0;
                @include line-height(48px);
            }
        }
        .section-header{
            padding: 18px 0 23px;
            text-transform: none;
            @include respond(large-up) {
                padding: 18px 0 37px;
            }
            p{
                margin: 0px;
                @include font-regular(18px);
                @include line-height(24px);
                color: $secondary-color;
            }
        }
        .no-hits-search{
            .input-text{
                border: none;
                width: calc(100% - 66px);
                @include font-regular(16px);
                color: $primary-color;
                height: 30px;

                @include respond(large-up){
                    @include font-regular(24px);
                    height: 45px;
                    width: 71.205%;
                    border-bottom: 1px solid $secondary-border;
                }
            }
            button{
                background: none;
                border:none;
                padding: 0 11px 0 0;
                &:hover{
                    background: none;
                }
                @include respond(large-up){
                    padding: 0 8px;
                }
            }
            .icon-search{
                width: 22px;
                height: 22px;
                position: relative;
                top: 8px;
                @include respond(large-up){
                    width: 28px;
                    height: 28px;
                    top: 10px;
                }
            }
            .form-row {
                &:after {
                    content: '';
                    display: block;
                    width: calc(100% - 30px);
                    margin-left: 15px;
                    height: 1px;
                    background: $secondary-border;
                    @include respond(large-up){
                        content: none;
                    }
                }
            }
        }
        .no-hits-help{
            margin: 25px 0 65px;
            @include respond(large-up){
                margin: 0;
                .content-asset {
                    margin: 51px 0 60px;
                }
            }
            p{
                @include font-regular(18px);
                color: $secondary-color;
                strong{
                    @include font-bold(18px);
                }
                span {
                    display: block;
                    @include respond(large-up){
                        display: inline;
                    }
                }
            }
        }
    }
    .recommendations {
        @include respond(large-up){
            margin: 20px 0 60px;
        }
        h2{
            text-align: center;
            @include font-size(24px);
            text-transform: capitalize;
            margin: 35px 0;
            border-bottom: none;
            @include respond(medium-down){
                padding: 35px 0 0;
                margin: 10px 15px 45px;
            }
        }
        .horizontal-carousel {
            display: none;
            .product-tile {
                .sub-attribute {
                    @include font-size(12px);
                    margin-top: 2px;
                }
                .product-price {
                    @include font-size(16px);
                    margin: 5px 0 2px;
                    .price-sales {
                        color: $secondary-color;
                    }
                    .price-standard {
                        text-decoration: line-through;
                        & ~ .price-sales {
                            color: $red;
                        }
                    }
                }
                .promotion {
                    .promotion-callout {
                        color: $red;
                        @include font-size(14px);
                        @include line-height(24px);
                        .callout-message {
                            margin-bottom: 3px;
                        }
                        * {
                            @include font-size(14px);
                            @include line-height(24px)
                        }
                        p {
                            margin: 0;
                            color: $red;
                        }
                    }
                }
            }
        }
    }
}

.banner-details{
    .ui-dialog-title{
        text-transform: none;
        border-bottom: none;
        margin: 35px 35px 0;
        text-overflow: clip;
        white-space: normal;
        @include font-regular(21px);
        @include respond(medium-down) {
            margin: 20px 20px 0;
        }
    }
    .ui-dialog-content{
        p{
            @include font-regular(13px);
            line-height: 1.8;
            strong{
                @include font-bold(12px);
            }
            img {
                max-width: 100%;
            }
        }
    }
}
