@import "accessible-slick-theme";
@import "slick";
.slider-controls {
    align-items: center;
    display: flex;
    gap: 4px;
    justify-content: center;

    .slick-dots {
        position: static;

        li {
            margin: 0 4px;

            button {
                height: 40px;
                max-height: none;
                width: 8px;
                margin: 0;

                .slick-dot-icon {
                    color: $icon-secondary;
                    opacity: .55;

                    &:before {
                        font-size: 23px;
                        line-height: 40px;
                    }
                }
            }

            &.slick-active button .slick-dot-icon {
                color: $text-primary;
                opacity: 1;
            }
        }
    }

    .slick-disabled {
        opacity: 0;
    }
}

.carousel-item {
    box-sizing: border-box;
    cursor: grab;
    padding: 0 16px;

    &:active {
        cursor: grabbing;
    }
}

.slick-slider {
    margin-bottom: 0;

    &.slick-dotted {
        margin-bottom: 0;
    }
}

.slick-track {
    margin-bottom: 16px;
}

.ds-loading-skeleton .ds-carousel .carousel-item:not(:first-child) {
    display: none;
}

.ds-carousel-next-button,
.ds-carousel-prev-button {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        background-color: transparent;
        border: none;
        height: 32px;
        max-height: 32px;
        position: relative;
        width: 32px;
        z-index: 1;

        &::before {
            content: "";
            background: url("../images/svg-icons/chevron-24-r.svg") no-repeat scroll center transparent;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $bg-secondary;
        }

        &:active::before {
            background-image: url("../images/svg-icons/chevron-active-24-r.svg");
        }
    }
}

.ds-carousel-prev-button button::before {
    transform: rotate(180deg);
}
