@import "../../../../../org_bathandbodyworks/cartridge/templates/default/components/variables";


// mixins
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.clearfix {
    @extend %clearfix;
}

// icons
$close-small: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.53478 2.47041L2.47412 3.53107L12.4726 13.5296L13.5333 12.4689L3.53478 2.47041Z' fill='black'/%3E%3Cpath d='M12.4726 2.47044L2.47412 12.4689L3.53478 13.5296L13.5333 3.5311L12.4726 2.47044Z' fill='black'/%3E%3C/svg%3E");

// viewports
$small-breakpoint: 320px;
$small-breakpoint-iphone: 375px;
$medium-breakpoint: 480px;
$large-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$max-width: 1400px;
$max-small-coll: 955px;
$menu-max-with: 1290px;
$standart-breakpoint: 960px;
$hero-crop-width: 1680px;

// colors
// names taken from http://www.color-blindness.com/color-name-hue/
$cerulean: #00629b;
$charcoal: #424242;
$citrus: #84bd00;
$limeade: #4C891F;
$medium-spring-green: #ffe9e9;

// button hover bg
$nero: #1A1A1A;
//  entered form text


$gainsboro: #e0e0e0;
$islamic-green: #019328;
$nobel: #999;
$rain-forest: #64772d;
$sangria: #990000;
$stark-white: #cebdb3;
$teal: #008578;
$tutu: #faeded;
$very-light-gray: #ccc;
$light-gray: #efefef;

$swatch-types: black #000000, beige #f5f5dc, blue blue, purple purple, red red, brown #783201, green green, grey #8f979d, pink #fe249a, orange orange, white #ffffff, yellow #ffff00, navy navy;

// new colors for BBW site
$night-rider: #333;
$dim-gray: #666;
$dark-gray: #AAA;
$gainsboro: #E5E5E5;
$white-smoke: #F2F2F2;
$cobalt: #005699;
$active-blue: #4f89be;
$denim: #1B71AA;
$hollywood-cerise: #E20887;
$red: #CC0000;
$black: #000;
$white: #fff;
$trans: transparent;
$menu: #767676;
$click: #c2c2c2;
$alice-blue: #e6f5ff;
$egg-blue: #1d6b49;
$mint-cream: #e9fff2;
$mint-cream2: #1c6a48;
$reward-hr: #EBEBEB;
$green: #008527;
$emphasized: #cd1a20;

// override color variables for BBW site
$nero: $night-rider;
$light-gray: $gainsboro;
$sangria: $red;
$citrus: $night-rider;


//own BBW variables
$primary-color: $dim-gray;
$secondary-color: $night-rider;
$primary-border: $gainsboro;
$secondary-border: $night-rider;
$bg-color: $white-smoke;
$border-color: #ff0080;
$facebook: #365fa1;
$twitter: #27aade;
$instagram: #34648c;
$pinterest: #ce2127;
$wb-text: #2c2a2a;
$wb-btn-blue: #6cd4e1;
$wb-dark-color: #070506;
$bg-content: #D8D8D8;
$border-ingredient: #A3A3A3;
$border-ingredient2: #DFDFDF;
$bg-ingredient: #F7F7F7;
$bg-table-header: #FCFCFC;
$pink-boc1: #e20887;
$pink-boc: #E20887; // BBWD
$boc-placeholder: #fff;
$boc-border: #e945a5;

// BBW core brand colors
$brand-blue: $cobalt; // #005699
$brand-pink: $hollywood-cerise; // #E20887
$brand-black: $night-rider; // #333


//BBWD Foundations 2.0
//Extended color palette variables
//Used on the UI Color Palette
$pink-10: #FCF3F9;
$pink-20: #FFDBF2;
$pink-30: #FFC2EA;
$pink-40: #FF8FD5;
$pink-50: #FF5CBD;
$pink-60: #F42EA3;
$pink-70: #E20887;
$pink-80: #B80171;
$pink-90: #870057;
$pink-100: #56023A;
$red-10: #FCF0EF;
$red-20: #FAE3E1;
$red-30: #F6C6C2;
$red-40: #F19993;
$red-50: #EA5B54;
$red-60: #DD140F;
$red-70: #CB0100;
$red-80: #AF0002;
$red-90: #890003;
$red-100: #5A0003;
$orange-10: #FCF5F0;
$orange-20: #FBE1CF;
$orange-30: #FACCAF;
$orange-40: #F6A172;
$orange-50: #EF783E;
$orange-60: #E55616;
$orange-70: #D43E00;
$orange-80: #B93400;
$orange-90: #922800;
$orange-100: #601A00;
$yellow-10: #FAF5C8;
$yellow-20: #F8EB83;
$yellow-30: #F1D73C;
$yellow-40: #E6B700;
$yellow-50: #D79800;
$yellow-60: #C27600;
$yellow-70: #AA5700;
$yellow-80: #8E3D00;
$yellow-90: #702A00;
$yellow-100: #531D00;
$green-10: #E6F7EB;
$green-20: #BFF4CF;
$green-30: #99F1B3;
$green-40: #4CE075;
$green-50: #09C638;
$green-60: #00A222;
$green-70: #007A14;
$green-80: #00570B;
$green-90: #003D06;
$green-100: #002D04;
$cyan-10: #E6F8FA;
$cyan-20: #B5F2F8;
$cyan-30: #80E5F0;
$cyan-40: #4DD1E4;
$cyan-50: #21B7D3;
$cyan-60: #0098BC;
$cyan-70: #007699;
$cyan-80: #006082;
$cyan-90: #004662;
$cyan-100: #002E42;
$blue-10: #EEF6FC;
$blue-20: #D0E8FA;
$blue-30: #B3DAF8;
$blue-40: #7ABCEF;
$blue-50: #4BA0E3;
$blue-60: #2686D1;
$blue-70: #0D6DB9;
$blue-80: #005699;
$blue-90: #003E73;
$blue-100: #00274B;
$cool-grey-10: #EEF1F2;
$cool-grey-20: #E1E5E7;
$cool-grey-30: #D5D9DC;
$cool-grey-40: #B9C0C5;
$cool-grey-50: #9BA5AE;
$cool-grey-60: #7B8A98;
$cool-grey-70: #5E7081;
$cool-grey-80: #46586A;
$cool-grey-90: #334354;
$cool-grey-100: #24303D;
$grey-10: #F2F2F2;
$grey-20: #E4E4E4;
$grey-30: #D5D5D5;
$grey-40: #BABABA;
$grey-50: #9E9E9E;
$grey-60: #828282;
$grey-70: #666666;
$grey-80: #4D4D4D;
$grey-90: #333333;
$grey-100: #1A1A1A;
$base: #FFFFFF;

//text-shadows
$text-shadow-dark: rgba(26, 26, 26, 0.45);
$text-shadow-light: rgba(255, 255, 255, 0.5);

//background
$bg-primary: $base;
$bg-secondary: $grey-10;
$bg-tertiary: $grey-60;
$bg-inverse-primary: $grey-100;
$bg-inverse-secondary: $grey-80;
$bg-disabled: $grey-20;
$bg-feedback-danger: $red-10;
$bg-feedback-success: $green-10;
$bg-feedback-warning: $yellow-10;
$bg-feedback-info: $cyan-10;
$bg-brand-minimal: $blue-10;

//checkout-buttons
$checkout-button-dark: $green-80;
$checkout-button-default: $green-70;
$checkout-button-light: $green-60;

//overlay
$overlay-dark: $grey-100;
$overlay-light: $base;

//text
$text-primary: $grey-100;
$text-secondary: $grey-80;
$text-subtle: $grey-70;
$text-tertiary: $grey-60;
$text-inverse: $base;
$text-disabled: $grey-50;
$text-brand-primary: $blue-80;
$text-brand-accent: $pink-70;
$text-feedback-danger: $red-70;
$text-feedback-success: $green-70;
$text-feedback-warning: $yellow-70;
$text-feedback-info: $cyan-70;

//Link
$link-primary-default: $grey-100;
$link-primary-pressed: $grey-80;

//Border
$border-primary: $grey-10;
$border-secondary: $grey-60;
$border-dark: $grey-100;
$border-feature: $grey-30;

//Brand
$brand-primary: $blue-80;
$brand-accent: $pink-70;

//Icon
$icon-primary: $grey-100;
$icon-secondary: $grey-60;
$icon-secondary-filter: brightness(0) saturate(100%) invert(54%) sepia(0%) saturate(13%) hue-rotate(140deg) brightness(94%) contrast(88%); //#828282
$icon-inverse: $base;
$icon-inverse-filter: brightness(0) saturate(100%) invert(95%) sepia(95%) saturate(21%) hue-rotate(344deg) brightness(105%) contrast(108%); //#FFFFFF
$icon-disabled: $grey-40;
$icon-brand-primary: $blue-80;
$icon-brand-primary-filter: brightness(0) saturate(100%) invert(21%) sepia(96%) saturate(1707%) hue-rotate(189deg) brightness(88%) contrast(101%); //#005699
$icon-feedback-danger: $red-70;
$icon-feedback-success: $green-70;
$icon-feedback-warning: $yellow-70;
$icon-feedback-info: $cyan-70;
$icon-module-ratings: $yellow-30;

// END of BBWD Foundations 2.0
