.menu-utility-user {
    float: right;
    @include respond(large-up) {
        position: relative;
        top: -38px;
    }
    @include respond(standart){
        top: -50px;
    }
    li {
        list-style: none;
        @include respond(large-up) {
            background-color: transparent;
            border-color: transparent;
            float: left;
            margin: 0;
            text-align: center;
        }

        a {
            color: $white;
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: block;
            &:active {
                color: $citrus;
            }
            .icon.user-account{
                width: 36px;
                height: 36px !important;
            }
            @include respond(large-up) {
                color: $nobel;
                transition: color .5s ease;
                &:hover {
                    color: $citrus;
                    text-decoration: none;
                }
                i {
                    display: block;
                    font-size: 3em;
                    padding: 0;
                }
                span {
                    display: none;
                }
            }
        }
    }

    .menu-utility-user__item {
        @include respond(large-up) {
            @include font-size(12px);
            line-height: initial;
        }
    }

    .user-info {
        display: none;
        margin-left: 13px;
        @include respond(large-up) {
            display: block;
            position: relative;
            transition: color .5s ease;
        }
        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }

        .user-account {
            display: inline-block;
        }
        
        .user-panel {
            display: none;
            box-sizing: content-box;
            //See https://code.google.com/p/chromium/issues/detail?can=2&q=max-content&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified&id=522191&thanks=522191&ts=1439925077
            margin-top: .5rem;
            text-align: left;
            transition: all .3s ease;
            visibility: visible;
            width: 10rem;
            //For IE
            width: max-content;
            @include respond(large-up) {
                background-color: $white;
                border: 1px solid $dark-gray;
                margin-top: -1px;
                padding: 1em;
                position: absolute;
                display: none;
                right: 0;
                visibility: hidden;
                z-index: 200;
                width: 202px;
                opacity: 0;
                transition: width 800ms, visibility 20ms, opacity 500ms;
                transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);  /* easeOutCirc */
                &.active {
                    visibility: visible;
                    opacity: 1;
                    display: block;
                }
            }

            .user-links {
                padding-left: 1.5rem;
                text-align: left;
                @include respond(large-up) {
                    padding-left: .5rem;
                }
            }
            a {
                @include ds-ui-regular(s);
                color: $primary-color;
                display: block;
                padding: 10px 0 10px 0;
                
                &:hover{
                    color: $cobalt;
                }
                &.wishlist{
                    padding-left: 0;
                }
                .login-icon{
                    height: 20px !important;
                    width: 20px !important;
                    max-width: none;
                    position: relative;
                    top: 5px;
                    padding-right: 5px;
                }
            }
            .user-logout {
                color: $white;
                margin-top: .5rem;
                padding: 0.5em 2em;
                text-transform: uppercase;
                &:hover {
                    color: $citrus;
                }
            }
        }
    }
}
