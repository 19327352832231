.pt_order {
    &#wrapper {
        #main {
            .page-title {
                @include respond(large-up) {
                    margin: 0 0 11px;
                }
            }
            #primary {
                h2 {
                    padding-bottom: 0.625em;
                    margin-bottom: 1.5625em;
                }
            }
            &.full-width {
                padding: 0 15px;

                .order-totals-wrap {
                    @include respond(large-up) {
                        float: right;
                        margin-top: 0;
                        max-width: 311px;
                    }
                    .order-totals {
                        @include respond(large-up) {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }
                    .order-totals-banner {
                        @include respond(large-up) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    @include respond(medium-down) {
        footer {
            margin-top: 100px;
        }
    }
}

.orders-list {
    .headinfo-year {
        @include font-size(21px);
        margin: 0;
        color: $secondary-color;
        padding: 0 0 10px;
        border-bottom: 1px solid $secondary-border;
        @include transition(all 0.5s ease-out);
        @include respond(large-up) {
            @include font-size(24px);
        }
    }
    .ui-accordion-header {
        text-align: center;
        border-bottom: 1px solid $primary-border;
        text-transform: uppercase;
        padding: 18px 15px;
        &:first-child {
            margin-top: -1px;
            border-top: 1px solid $primary-border;
        }
        @include respond(medium-down) {
            margin: 0 -15px;
            padding-bottom: 18px 15px 27px;
        }
        .ui-icon {
            float: right;
            position: relative;
            width: 20px;
            height: 22px;
            @include transition(all 0.5s ease-out);
            background-size: 20px;
            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
            top: 11px;
            left: 0;
            @include respond(large-up) {
                left: -28px;
                top: 15px;
            }
            &.iconOpen {
                background: url("../images/svg-icons/UI-Carat.svg") no-repeat
                    center;
                transform: rotate(180deg);
            }
        }
    }
    .order-history-order {
        border-bottom: 1px solid $primary-border;
        padding: 40px 15px;
        &:first-child {
            background: $bg-color;
            .button {
                body:not(.touch) & {
                    &:hover {
                        background-color: $white;
                        border-color: $gainsboro;
                    }
                    &:active {
                        background-color: $white-smoke;
                        border-color: $white;
                    }
                }
                &:active {
                    background-color: $white-smoke;
                    border-color: $white;
                }
            }
        }
        @include respond(large-up) {
            padding: 40px 30px;
        }
        .order-history-info {
            @include font-size(14px);
            @include respond(large-up) {
                float: left;
                @include font-size(11px);
            }
            .order-date-history {
                text-transform: uppercase;
                color: $secondary-color;
                margin-bottom: 7px;
                @include respond(large-up) {
                    @include font-size(13px);
                    margin-bottom: 6px;
                }
                .value {
                    @include font-bold(14px);
                    padding-left: 10px;
                    @include respond(large-up) {
                        @include font-bold(13px);
                    }
                }
            }
            .order-number {
                .value {
                    padding-left: 12px;
                    @include respond(large-up) {
                        padding-left: 5px;
                    }
                }
            }
        }
        .order-details-link {
            text-align: center;
            margin-top: 38px;
            @include respond(large-up) {
                float: right;
                margin: 14px 13px 0 0;
            }
            .button {
                width: 115px;
                box-sizing: border-box;
                @include font-size(14px);
                @include respond(large-up) {
                    width: 104px;
                    @include font-size(11px);
                }
            }
        }
    }
}

.order-details-page {
    padding: 0;
    .orderdetails {
        display: flex;
        flex-direction: row;
        gap: 24px;
        @include respond(small-down) {
            flex-direction: column;
            gap: 12px;
            padding-top: 12px;
        }
    }
    .order-returns-information {
        padding: 16px;
        width: 50%;
        @include respond(small-down) {
            width: inherit;
        }
        float: right;
        background: $white;
        margin-bottom: 11px;
        border: 1px solid $primary-border;
        .return-policy-text {
            color: $grey-100;
            line-height: 20px;
            letter-spacing: 0.015em;
            font-size: 14px;
            margin-bottom: 16px;
        }
        .return-info-button {
            width: -webkit-fill-available;
            .button {
                @include ds-utilities-button-outline;
            }
        }
    }
    .order-information {
        padding: 40px 15px 38px;
        background: $bg-color;
        margin-bottom: 11px;
        width: 50%;
        @include respond(small-down) {
            width: inherit;
            margin: 0;
        }
        border-bottom: 1px solid $primary-border;
        @include respond(large-up) {
            padding: 40px 30px 50px;
        }
        @include respond(tablet-small) {
            padding: 20px 10px 30px;
        }
        .order-info {
            @include font-size(14px);
            @include respond(large-up) {
                float: left;
                @include font-size(11px);
                @include font-size(14px);
            }
            .order-date {
                text-transform: uppercase;
                color: $secondary-color;
                margin-bottom: 7px;
                @include font-size(16px);
                @include respond(large-up) {
                    @include font-size(13px);
                    margin-bottom: 6px;
                }
                .value {
                    @include font-bold(16px);
                    padding-left: 10px;
                    @include respond(large-up) {
                        @include font-bold(13px);
                    }
                }
            }
            .order-left-block {
                color: $secondary-color;
                margin-bottom: 19px;
                @include font-size(15px);
                @include respond(large-up) {
                    @include font-size(16px);
                    margin-bottom: 12px;
                }
                .label {
                    color: $dim-gray;
                }
                .value {
                    @include font-bold(15px);
                    padding-left: 10px;
                    @include respond(large-up) {
                        @include font-bold(16px);
                    }
                }
            }
            .order-number,
            .order-status {
                .value {
                    padding-left: 12px;
                    @include font-bold(14px);
                    @include respond(large-up) {
                        padding-left: 5px;
                        @include font-bold(11px);
                    }
                }
            }
        }
        .order-banner {
            @include respond(large-up) {
                float: right;
            }
            .content-asset {
                margin-top: 35px;
                text-align: center;
                @include respond(large-up) {
                    margin-top: 7px;
                    margin-right: 85px;
                    text-align: left;
                }
                @include respond(tablet-small) {
                    margin-right: 0;
                }
                img {
                    max-width: 100%;
                    vertical-align: top;
                }
            }
        }
    }
    .order-totals {
        background: $bg-color;
        box-sizing: border-box;
        background: $bg-color;
        padding: 0 14px 30px;
        @include respond(large-up) {
            float: left;
            max-width: 311px;
            width: 49%;
        }
        @include respond(medium-down) {
            margin: 18px -15px 0;
            padding: 0 15px 25px;
        }
        h3 {
            text-align: center;
            border-bottom: 3px solid $primary-border;
            margin: 0 0 10px;
            padding: 18px 0;
            @include font-size(16px);
        }
        .order-totals-table {
            tr {
                td {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    .order-totals-wrap {
        @include respond(large-up) {
            margin-top: 10px;
        }
        .order-totals-banner {
            float: right;
            max-width: 380px;
            width: 49%;
            img {
                max-width: 100%;
                vertical-align: top;
            }
        }
    }
    .order-summary-title {
        @include respond(large-up) {
            margin-top: 31px;
            &.returned-title {
                margin-top: 44px;
            }
        }
    }
    .related-items-note {
        margin-top: 28px;
		border-bottom: 1px solid $primary-border;
		padding-bottom: 27px;
		@include respond(large-up) {
			padding: 0 12px;
			margin: 24px 0 18px;
			border: 0 none;
		}
	}
	#cart-table.order-details-table {
	   .section-header {
	       @include respond(large-up) {
	           &.item-total {
	               width: 190px;
	           }
	       }
	       @include respond(tablet-small) {
	           &.item-total {
                   width: 152px;
               }
	       }
	   }
	   .cart-row {
	       .method {
            border-bottom: none;
	           .shipment-title {
	               &:not(.first) {
	                   margin-top: 30px;
	                   padding-top: 32px;
	               }
	           }
	       }
	   }
	}
	.order-related-table {
	    @include respond(tablet-small) {
	       table-layout: fixed;
	    }
	    thead {
	        display: none;
	        @include respond(large-up) {
	            display: table-header-group;
	            th {
	                @include font-bold(14px);
	                @include line-height(15px);
	                padding: 8px 12px 10px;
	                white-space: nowrap;
                    box-sizing: border-box;
                    &.item-qty {
                        text-align: center;
                        width: 54px;
                    }
                    &.header-total-price {
                        text-align: right;
                        padding-right: 30px;
                        width: 190px;
                        @include respond(tablet-small) {
                            width: 152px;
                        }
                    }
                }
            }
        }
        tr {
            &.cart-row {
                border: 0 none;
            }
            @include respond(medium-down) {
                position: relative;
                display: block;
                overflow: hidden;
            }
            td {
                box-sizing: border-box;
                padding: 0;
                @include respond(large-up) {
                    padding: 39px 15px 0;
                }
                @include respond(medium-down) {
                    display: block;
                    float: right;
                    width: 70%;
                }
                &.item-image {
                    float: left;
                    text-align: center;
                    width: 30%;
                    padding-top: 15px;
                    @include respond(large-up) {
                        float: none;
                        width: 120px;
                        padding: 20px 0 0;
                    }
                    img {
                        max-width: 100%;
                        vertical-align: top;
                    }
                }
                &.item-details {
                    padding-top: 30px;
                    @include respond(large-up) {
                        float: none;
                        width: auto;
                        padding: 39px 15px 40px 0;
                    }
                    .product-list-item {
                        margin-bottom: 13px;
                        @include respond(large-up) {
                            max-width: 200px;
                        }
                        .name {
                            margin-bottom: 9px;
                            @include respond(large-up) {
                                margin-bottom: 3px;
                            }
                            a {
                                color: $secondary-color;
                            }
                        }
                    }
                }
                &.item-qty {
                    @include font-size(13px);
                    @include respond(large-up) {
                        text-align: center;
                    }
                    .mobile-label {
                        text-transform: uppercase;
                        padding-right: 5px;
                        @include respond(large-up) {
                            display: none;
                        }
                    }
                }
                &.item-total {
                    color: $red;
                    padding-top: 10px;
                    @include font-size(13px);
                    @include respond(large-up) {
                        text-align: right;
                        padding: 39px 30px 0 0;
                    }
                }
            }
        }
    }

    .credit-info-wrap {
        background: $bg-color;
        margin-top: 35px;
        .order-totals-table {
            tr {
                td {
                    & + td {
                        color: $red;
                    }
                }
            }
        }
    }
    .credit-inf {
        background: $bg-color;
        margin: 0 -15px;
        padding: 12px 15px 24px;
        @include respond(large-up) {
            float: right;
            padding: 14px 0 0 1%;
            width: 49%;
            box-sizing: border-box;
            margin: 0;
        }
        .credit-info {
            margin: 0 0 28px;
            @include respond(large-up) {
                margin: 35px 0 0;
            }
            .credit-label {
                text-transform: uppercase;
                @include font-bold(13px);
                color: $secondary-color;
            }
            .credit-val {
                @include font-size(13px);
                .pan {
                    @include font-bold(13px);
                }
            }
        }
    }
    .divider {
        height: 1px;
        width: 100%;
        background: $primary-border;
        margin-top: 35px;
    }
}

.order-actions {
    margin-top: 30px;
    padding: 0 5px;
    text-align: center;
    @include respond(large-up) {
        margin-top: 41px;
        padding: 0;
    }
    .actions-row {
        margin-bottom: 17px;
        .button {
            box-sizing: border-box;
            width: 100%;
            @include font-size(14px);
            @include respond(large-up) {
                @include font-size(11px);
                width: 181px;
                letter-spacing: 0.5px;
            }
        }
    }
}

.ca-tax-info {
    @include font-size(9px);
    line-height: 14px;
    margin: 5px 0 0;
    text-transform: none;
}
