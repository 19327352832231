.ui-dialog #listings-nav {
    float: right;
    margin-top: 2%;
    a {
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        &.active {
            font-weight: bold;
        }
    }
}

.pdp-main .product-add-to-cart {
    .online-stock span {
        float: left;
    }
    .store-stock {
        display: block;
        clear: left;
    }
}

// Store inventory

.availability-web {
    padding-bottom: 10px;
    label {
        float: none;
    }
    p {
        margin: 0;
    }
    .availability-novariation {
        color: $nobel;
    }
    .availability-msg{
        @include font-size(12px);
        color: $primary-color;
        p{
            font-size: inherit;
            color: inherit;
        }

        .header-msg {
            @include font-regular(24px);
            color: $secondary-color;
            margin: 15px 0 5px;
        }
        .text-msg{
            @include font-regular(14px);
            color: $primary-color;
        }
        .a-msg{
            display: block;
            margin-bottom: 10px;
            text-decoration: underline;
            @include font-regular(14px);
            color: $secondary-color;
            &:hover{
                text-decoration: none;
            }
        }

    }
}

.availability-storepickup {
    .availability-instore,
    .availability-web {
        background-color: $gainsboro;
        padding: 1rem;
    }
    .availability-web {
        float: none;
    }
    .availability-instore {
        margin-bottom: 1rem;
    }
}

.in-stock-msg,
.is-in-stock,
[data-status="store-in-stock"] {

}

.not-available-msg,
.in-stock-date-msg,
.not-available,
.out-of-stock,
.store-error {
    color: $teal;
}

.on-order,
.preorder-msg,
.backorder-msg {
    color: $teal;
}

#user-zip {
    display: block;
    margin: 1rem auto 0;
}

.store-list-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    width: 565px;
}

.store-list {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}

.store-tile {
    border: 1px dashed $very-light-gray;
    box-sizing: border-box;
    float: left;
    list-style: none outside none;
    margin: 0.3rem 0.4rem;
    padding: 0.8rem;
    text-align: center;
    width: 175px;
    &.selected {
        background-color: rgba($citrus, 0.5);
    }
    .store-status {
        display: block;
        font-size: 0.9em;
        margin: 1rem 0;
    }
    &.selected {
        .select-store-button {
            @extend button.simple;
        }
    }
}

.store-list-pdp-container {
    margin-top: 1em;
    .store-list-pdp {
        margin-top: 0.5em;
    }
    .store-list-item {
        @extend %clearfix;
        display: none;
        padding: 0.5em  0.8em;
        &:last-of-type {
            border: none;
        }
        &.selected {
            display: block;
            font-weight: bold;
        }
        &.visible {
            display: block;
        }
        .store-address {
            float: left;
        }
        .store-status {
            float: right;
        }
    }
}
