.international-redirect-modal {
    top: 50% !important;   
    &.open {
        transform: translate(-50%, -50%) !important; 
    }
}
.ds-international-redirect {
    max-width: 600px;

    .ds-modal-heading {
        @media screen and (min-width: $xlMin) {
            margin-bottom: 24px;
        }
    }

    .ds-modal-paragraph {
        color: $text-primary;
    }

    .ds-select-label {
        @include ds-ui-semi(m);
    }

    .ds-select.error {
        border: 1px solid $text-feedback-danger;

        +.error-text {
            display: flex;
            align-items: center;
        }
    }

    .ds-select-container .error-text {
        display: none;
    }

    .ds-buttons-container {
        margin-top: 24px;
    }

    .ds-cancel {
        color: $text-primary;
        margin-top: 0;
        margin-right: 24px;

        @media screen and (min-width: $xlMin) {
            margin-right: 32px;
        }

        &:hover,
        &:focus {
            cursor: pointer;
        }

        &:not([disabled]):not(.disabled):hover {
            color: $text-primary;
        }
    }

    .ds-link {
        @include ds-ui-regular(s);
        color: $text-secondary;
    }

    .ds-link-button {
        float: right;
    }
}
