.ds-other-ways {
    .ds-link-image {
        padding: 0;

        > a {
            width: 100%;
        }
    }

    .ds-grid-contents {
        column-gap: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 40px;
    }

    img {
        display: block;
        width: 100%;
    }

    @media screen and (min-width: $mMin) {
        .ds-grid-contents {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .ds-live-text-image-container {
        width: 100%;
    }
}

.ds-other-ways--8-col {
    @media screen and (min-width: $lMax) {
        .ds-grid-contents {
            grid-template-columns: repeat(8, 1fr);
            column-gap: 32px;
        }
    }
}

.ds-other-ways--6-col {
    @media screen and (min-width: $lMax) {
        .ds-grid-contents {
            grid-template-columns: repeat(6, 1fr);
            column-gap: 32px;
        }
    }
}

.ds-other-ways--4-col {
    @media screen and (min-width: $lMax) {
        .ds-grid-contents {
            grid-template-columns: repeat(4, 1fr);
            column-gap: 32px;
        }
    }
}

.ds-other-ways--2-col {
    @media screen and (min-width: $lMax) {
        .ds-grid-contents {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 32px;
        }
    }
}
