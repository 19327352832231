.promo__tooltip,
.bbwd-tooltip{
    @include ds-ui-regular(s);
    color: $text-secondary;
    background: transparent;
    border: none;
    cursor: pointer;
    display: table;
    letter-spacing: normal;
    margin-top: 5px;
    padding: 0;
	position: relative;
    text-align: left;
    text-decoration: underline;
    text-transform: none;

    &:hover {
        text-decoration: none;
    }
}
// Shipping method display inline
.expected-delivery {
    .bbwd-tooltip {
        display: inline;
    }
}

.promo__tooltip-content,
.bbwd-tooltip-content {
    @include ds-body(m);
    color: $text-primary;
    background-color: #fff;
	border: 1px solid #666;
	box-shadow: 0 0 13px 0 rgba(0,0,0,0.15);
    display: none;
    padding: 24px 8px 24px 0;
    max-width: 260px;
    position: absolute;
    width: auto;
	z-index: -1;
	
    &.active {
        display: block;
        z-index: 105;
	}
	
	b, strong {
		@include font-bold();
    }
}

.bbwd-tooltip-content-container {
	@include ds-body(s);
    color: $text-primary;
	background-color: #fff;
	height: auto;
	margin-left: 0;
	max-height: 300px;
	overflow-y: auto;
	padding-left: 24px;
	padding-right: 16px;
    width: 210px;
    text-align: left;

    .arrow-outline, .arrow-fill {
        content: '';
        height: 0;
        position: absolute;
        width: 0;
        left: 0;
        transform: translate(-100%,-50%);
        @media screen and (max-width: 429px) {
            display: none;
        }
    }
    .arrow-outline {
        border-top: 10px solid transparent;
        border-right: 9px solid #666;
        border-bottom: 10px solid transparent;
    }
    .arrow-fill {
        border-top: 9px solid transparent;
        border-right: 8px solid white;
        border-bottom: 9px solid transparent;
    }
	p {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}

        @include ds-body(s);
        color: $text-primary;

        a {
            @include ds-body(s);
            color: $text-primary;
        }
	}

    h4 {
        @include ds-ui-semi(m);
        color: $text-primary;
    }
}

.placement {
    &-below,
    &-above {
        &-start,
        &-center,
        &-end {
            .bbwd-tooltip-content-container {
                .arrow-outline, .arrow-fill {
                    width: 0;
                    height: 0;
                    bottom: 0;
                    transform: translate(-50%, 100%);
                    left: 50%;
                }
                @media screen and (max-width: 429px) {
                    .arrow-outline, .arrow-fill {
                        border: none !important;
                    }
                }
            }
        }
    }
    &-above {
        &-start,
        &-center,
        &-end {
            .bbwd-tooltip-content-container {
                .arrow-outline{
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 9px solid #666;
                }
                .arrow-fill {
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-top: 8px solid white;
                }
            }
        }
    }
    &-below {
        &-start,
        &-center,
        &-end {
            .bbwd-tooltip-content-container {
                .arrow-outline {
                    // top: -38px;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 9px solid #666;
                }
                .arrow-fill {
                    // top: -34px;
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-bottom: 8px solid white;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        &-mobile-below,
        &-mobile-above {
            &-start,
            &-center,
            &-end {
                .bbwd-tooltip-content-container {
                    .arrow-outline, .arrow-fill {
                        width: 0;
                        height: 0;
                        top: auto;
                        bottom: 0;
                        transform: translate(-50%, 100%);
                        left: 50%;
                    }
                }
            }
        }
        &-mobile-above {
            &-start,
            &-center,
            &-end {
                .bbwd-tooltip-content-container {
                    .arrow-outline {
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 9px solid #666;
                    }
                    .arrow-fill {
                        border-left: 9px solid transparent;
                        border-right: 9px solid transparent;
                        border-top: 8px solid white;
                    }
                }
            }
        }
        &-mobile-below {
            &-start,
            &-center,
            &-end {
                .bbwd-tooltip-content-container {
                    .arrow-outline {
                        // top: -38px;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 9px solid #666;
                    }
                    .arrow-fill {
                        // top: -34px;
                        border-left: 9px solid transparent;
                        border-right: 9px solid transparent;
                        border-bottom: 8px solid white;
                    }
                }
            }
        }
    }
}
.bbwd-tooltip-close-button {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 8px;
    right: 8px;
    text-indent: -999em;
    padding: 0;
    background: url("../images/svg-icons/bbwd-tooltip-close.svg") no-repeat center;
    background-size: 13px;
    border-color: transparent;
    transition: none;
    overflow: hidden;
        
    &:hover{
        background: url("../images/svg-icons/bbwd-tooltip-close-hover.svg") no-repeat center;
        background-size: 13px;
        border-color: transparent !important;
        background-color: transparent !important;
    }
    
    &:active{
        background: url("../images/svg-icons/bbwd-tooltip-close-active.svg") no-repeat center;
        background-size: 13px;
        border-color: transparent !important;
        background-color: transparent !important;
    }
}

.bbwd-tooltip-close-button {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 8px;
    right: 8px;
    text-indent: -999em;
    padding: 0;
    background: url("../images/svg-icons/bbwd-tooltip-close.svg") no-repeat center;
    background-size: 13px;
    border-color: transparent;
    transition: none;
    overflow: hidden;
		
    &:hover{
        background: url("../images/svg-icons/bbwd-tooltip-close-hover.svg") no-repeat center;
        background-size: 13px;
        border-color: transparent !important;
        background-color: transparent !important;
    }
    
    &:active{
        background: url("../images/svg-icons/bbwd-tooltip-close-active.svg") no-repeat center;
        background-size: 13px;
        border-color: transparent !important;
        background-color: transparent !important;
    }
}

// BASE TOOLTIP ---------------

.bbwd-iroll {

    &:not(.product-tile):not(.text-inline) {
        @include borderRadius(20px);
        cursor: pointer;
        background: $bg-color url("../images/svg-icons/UI-Information.svg") no-repeat center;
        background-size: 11px;
        display: inline-block;
        height: 26px;
        position: relative;
        text-indent: -999em;
        width: 26px;
		
        &:hover{
            background: $secondary-color url("../images/svg-icons/UI-Information-hover.svg") no-repeat center;
            background-size: 11px;
		}
		
        &:active{
            background: $click url("../images/svg-icons/UI-Information-hover.svg") no-repeat center;
            background-size: 11px;
        }
	}
	
    &.product-tile {
        cursor: pointer;
        display: block;
	}
	
    &.text-inline {
		cursor: pointer;
		
        .details {
            @include font-size(12px);
            color: $dim-gray;
            text-decoration: underline;
		}
		
        &:hover {

            .details {
                text-decoration: none;
            }
        }
    }
}

#product-content {

    .bbwd-iroll {
        background: none;
		display: inline;
        height: auto;
        text-decoration: underline;
        text-indent: 0;
        width: auto;
		
        &:hover {
            text-decoration: none;
		}
		
        &:active {
            color: $click;
        }
    }
}

@include respond(medium-down) {

    .bbwd-iroll {
        font-size: .9em;
	}
	
    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }
}

.ui-tooltip {
    max-width: none;
    padding: 0;
}

.ui-tooltip.ui-widget-content {
    @include font-regular(14px);
    @include line-height(20px);
    background: $white;
    border: 1px solid $bg-color;
	box-shadow: none;
    color: $primary-color;
	
    .bonus-info {
        @include font-bold(14px);
	}
	
    @include respond(small) {
        margin-left: 1%;
        max-width: 240px;
        width: auto;
	}
	
    @include respond(desktop) {

        &:after, &:before {
            border: solid transparent;
            content: " ";
            height: 0;
            pointer-events: none;
            position: absolute;
            right: 100%;
            top: 50%;
            width: 0;
		}
		
        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-right-color: $white;
            border-width: 10px;
            margin-top: -10px;
		}
		
        &:before {
            border-color: rgba(229, 229, 229, 0);
            border-right-color: $gainsboro;
            border-width: 11px;
            margin-top: -11px;
        }
    }
}

.ui-tooltip-content {
    padding: 10px 25px;
	position: relative;
	
    @include respond(medium-down) {
        padding: 10px;
	}
	
    p {
        color: $primary-color;
	}
	
    a {
		color: $cobalt;
		
        &:hover{
            text-decoration: none;
		}
		
        &:active{
            color: $click;
        }
	}
	
    .recommendation-tooltip-header {
        font-size: 1.2em;
        font-weight: bold;
		padding: 0.5em;
		
        .product-price {
			font-weight: 400;
			
            .price-standard {
                color: $white;
                text-decoration: line-through;
            }
        }
	}
	
    .recommendation-tooltip-description,
    .recommendation-tooltip-attributes {
        padding: 0.8em;
	}
	
    .shipping-method-cost,
    .surcharge-product,
    .promo {
        padding-bottom: .3rem;
        text-align: right;
	}
	
    .shippingtotal {
        clear: right;
		margin-top: .3rem;
        text-align: right;
		
        .value {
            border-top: 1px solid $charcoal;
            padding-top: .3rem;
        }
    }
}
// special case for max-height on PDP
.pdp-main .promotion .bbwd-tooltip-content-container {
    @media screen and (min-width: 430px) {
        max-height: 147px;
    }
}