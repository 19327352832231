footer {
    background-color: $white;
    border-top: 3px solid $bg-color;
    clear: both;
    min-height: 398px;
    position: relative;

    @media only screen and (min-width: $mMin) {
        padding: 0 16px;
        margin-top: 96px;
    }

    @media only screen and (min-width: $lMin) {
        padding: 0;
    }

    &.checkout-footer {
        min-height: 64px;
        margin-top: 96px;

        .footer-container {
            padding: 48px 16px 16px;

            @media only screen and (min-width: $mMin) {
                padding: 32px 24px 48px;
            }

            @media only screen and (min-width: $lMin) {
                padding: 32px 0 48px;
            }

            .content-asset {
                font-size: 0;
            }

            .nav-link-list {
                margin-bottom: 0;
            }

            .ds-checkout-footer-heading {
                @include ds-heading(h6);
                font-weight: 700;
                margin: 0 0 16px;
                color: $text-primary;

                @media only screen and (min-width: $mMin) {
                    text-align: center;
                }
            }

            .ds-list-links {
                margin-bottom: 24px;
                
                @media only screen and (min-width: $mMin) {
                    margin-bottom: 0;
                }

                .content {
                    @media only screen and (min-width: $mMin) {
                        padding-right: 16px;
                        padding-left: 8px;
                    }
                }
            }

            .ds-link {
                @include ds-ui-regular(m);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    color: $text-secondary;
                }

                &:active {
                    color: $text-disabled;
                }
            }

            .guarantee {
                @include respond(large-up) {
                    float: left;
                }

                svg {
                    width: 269px;
                    height: 61px;
                    @include respond(large-up) {
                        width: 191px;
                        height: 43px;
                    }
                }
            }
        }

        +.ds-under-footer .nav-link-list {
            @media screen and (max-width: $lMin) {
                margin-bottom: 24px;
            }
        } 
    }
}

.cookie-notice-wrapper {
    height: 55px;
    width: 100%;
    background-color: #005699;
    margin: auto;
    position: fixed;
    left: 0px;
    bottom: 0;
    display: none;
    z-index: 100;

    .content-asset {
        height: 100%;
    }

    .cookie_flex_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 0 0 15px;
    }

    .cookie-text {
        @include ds-body(s);
        display: inline-block;
        color: #ffffff;
        font-size: 16px;
        line-height: 20px;

        a {
            font-size: 16px;
        }

        @include respond(large) {
            max-width: 564px;
            text-align: left;

            a {
                font-size: 16px;
            }
        }
        /*added for landscape only orientation*/
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            display: inline-block;
            @include ds-body(s);
            color: #ffffff;
            font-size: 16px;
            line-height: 20px;
            max-width: 100%;
            a {
                font-size: 16px;
            }
        }
        @include respond(medium-down) {
            line-height: 16px;
            font-size: 12px;

            a {
                font-size: 12px;
            }
        }
        @include respond(small) {
            max-width: 282px;
            text-align: left;
            margin-left: 14px;
            font-size: 12px;
            line-height: 14px;

            a {
                font-size: 12px;
            }
        }
        a {
            text-decoration: underline;
            color: #ffffff;

            &:hover {
                color: #ffffff;
                text-decoration: none;
            }
            &:active {
                color: #f2f2f2;
                text-decoration: none;
            }
        }
    }

    .button_wrapper {
        display: inline-block;
    }

    button.cookie-notice-accept {
        background-color: #005699;
        border: solid 2px;
        width: 95px;
        height: 40px;
        margin: 8px 13px 8px 54px;
        font-size: 14px;
        @include respond(small) {
            width: 54px;
            height: 35px;
            margin-left: 12px;
        }
        &:hover {
            background-color: #ffffff;
            color: #333333;
            border: solid 2px white;
        }
        &:active {
            background-color: #005699;
            color: #aaaaaa;
            border: solid 2px #aaaaaa;
            @include respond(small) {
                background-color: #ffffff;
                color: #333333;
                border: solid 2px white;
            }
            @include respond(large) {
                background-color: #ffffff;
                color: #333333;
                border: solid 2px white;
            }
        }
    }
}

.footer-container {
    box-sizing: border-box;
    margin: auto;
    padding-top: 23px;
    position: relative;
    width: 100%;

    @media only screen and (min-width: $mMin) {
        padding: 32px 0 48px;
    }

    @media only screen and (min-width: $lMin) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media only screen and (min-width: $xlMin) {
        padding-left: 0px;
        padding-right: 0px;
    }

    @include respond(large-up) {
        text-align: center;
    }
    @extend %clearfix;
    @include respond(desktop) {
        max-width: 1168px;
    }

    .socials {
        margin-bottom: 40px;
        min-width: 0;

        @media only screen and (min-width: $mMin) and (max-width: $mMin) {
            margin-bottom: 32px;
        }
    }

    .ds-container.ds-user-info-collection {
        min-width: 320px;

        @media only screen and (min-width: $mMin) {
            margin-top: 0;
            padding: 0;
        }
    }

    .ds-accordion {
        @media only screen and (max-width: $sMax) {
            margin-bottom: 72px;
        }
    }

    .footer-item {
        width: 100%;
        @include borderbox;

        .asset {
            @media only screen and (min-width: $mMin) {
                display: grid;
                column-gap: 16px;
                grid-template-columns: 1fr 1fr;
            }

            @media only screen and (min-width: $lMin) {
                grid-template-columns: 1fr 2fr;
                column-gap: 24px;
            }

            @media only screen and (min-width: xlMin) {
                column-gap: 32px;
            }

            @include respond(large-up) {
                padding: 0;
            }
        }
        .showlabel {
            background: $bg-color;
            @include font-regular(14px);
            @include line-height(14px);
            text-transform: uppercase;
            padding: 25px 15px;
            margin: 25px 0;
            border-top: 1px solid $bg-color;
            cursor: pointer;
            .icon {
                background: url("../images/svg-icons/UI-Carat.svg") no-repeat scroll center;
                width: 25px;
                height: 20px;
                float: right;
                margin: -3px 0 0 0;
                @include transition(all 0.5s ease-out);
                cursor: pointer;
                &.rotate {
                    -webkit-transform: rotate(180deg); /* Chrome, Safari 3.1+ */
                    -moz-transform: rotate(180deg); /* Firefox 3.5-15 */
                    -ms-transform: rotate(180deg); /* IE 9 */
                    -o-transform: rotate(180deg); /* Opera 10.50-12.00 */
                    transform: rotate(180deg);
                }
            }
            &.active {
                background: $white;
            }
        }
        h5 {
            @include font-regular(14px);
            margin-bottom: 20px;
            color: $secondary-color;
        }
    }
    .maney-back {
        margin-bottom: 25px;
        padding: 0 15px;
        @include respond(large-up) {
            float: right;
            margin-top: 25px;
            padding-right: 0;
            width: auto;
        }
        &.active {
            @include respond(medium-down) {
                border-top: 1px solid $bg-color;
                padding-top: 20px;
            }
        }
        .mobile-only {
            svg {
                @include respond(small) {
                    width: 80%;
                }
                height: 61px;
                width: auto;
            }
        }
        .desktop-only {
            padding: 55px 0;
            svg {
                width: 300px;
                height: 60px;
            }
        }
    }
    h3 {
        color: $text-primary;
    }
    .menu-footer {
        float: none;
        margin: 15px 0 10px 15px;
        padding: 0;
        @include respond(large-up) {
            margin: 5px 0;
        }

        li {
            float: none;
            list-style: none !important;
            @include respond(large-up) {
                margin-bottom: 4px;
            }
            @include respond(medium-down) {
                margin-bottom: 21px;
            }
        }
    }
    a {
        color: $primary-color;
        @include font-regular(14px);
        &:hover {
            color: $cobalt;
        }
        &:active {
            color: $click;
        }
    }
}
.social-links {
    display: flex;
    column-gap: 16px;
    margin: auto;

    @media only screen and (min-width: $mMin) {
        flex-flow: row nowrap;
        max-width: 312px;
        margin: 0;
        justify-content: flex-start;
    }

    li {
        display: block;
        float: none;

        a {
            display: inline-block;
            width: 24px;
            height: 24px;
            @include transition(opacity 0.5s ease-out);

            &.facebook {
                background: url("../images/svg-icons/Social-fb-black.svg") no-repeat center;
                background-size: 24px;
            }

            &.twitter {
                background: url("../images/svg-icons/Social-x-black.svg") no-repeat center;
                background-size: 24px;
            }

            &.instagram {
                background: url("../images/svg-icons/Social-ig-black.svg") no-repeat center;
                background-size: 24px;
            }

            &.tiktok {
                background: url("../images/svg-icons/Social-tiktok-black.svg") no-repeat center;
                background-size: 24px;
            }

            &.youtube {
                background: url("../images/svg-icons/Social-youtube-black.svg") no-repeat center;
                background-size: 24px;
            }

            &.pinterest {
                background: url("../images/svg-icons/Social-pinterest-black.svg") no-repeat center;
                background-size: 24px;
            }
            
            &:hover {
                @include opacity(0.75);
            }
        }
    }
}

#email-alert-signup {
    label {
        color: $secondary-color;
        margin-bottom: calculateRem(7px);
        clear: both;
    }
    .row {
        position: relative;
    }
    input[type="email"] {
        margin-left: 0;
        width: 88%;
        margin-bottom: 15px;
        @include borderbox();
        @include respond(large-up) {
            width: 180px;
        }
        & + .error {
            position: relative;
            width: 100%;
            top: -5px;
            left: -10px;
            padding-bottom: 10px;
        }
    }
    button {
    }
    .form-field-tooltip {
        display: inline-block;
        position: absolute;
        width: auto;
        padding-top: 0;
        float: right;
        @include respond(medium) {
            margin-right: 20px;
        }
        @include respond(large-up) {
            margin-right: 70px;
        }
    }
    .form-field {
        @include respond(large-up) {
            display: inline;
            position: relative;
            left: -3px;
            .signup-btn {
                line-height: 34px;
                @include font-size(12px);
                letter-spacing: 0.5px;
            }
        }
    }
    .footer-email-success {
        color: $cobalt;
    }
}
.ui-tooltip-content {
    @include respond(large-up) {
        width: 220px;
    }
}
.mac.safari {
    #email-alert-signup {
        .form-field {
            @include respond(large-up) {
                top: -1px;
                left: -4px;
            }
        }
    }
}
.mac.firefox {
    #email-alert-signup {
        .form-field {
            @include respond(large-up) {
                left: -4px;
            }
        }
    }
}
.windows.mozilla {
    #email-alert-signup {
        .form-field {
            @include respond(large-up) {
                left: -4px;
                top: -1px;
                .signup-btn {
                    line-height: 32px;
                }
            }
        }
    }
}

.ds-under-footer {
    background-color: $bg-secondary;
    padding: 24px 16px;
    padding-bottom: 56px;

    .copyright {
        @include ds-ui-regular(s);
        color: $text-secondary;
        margin: 0;

        @media only screen and (min-width: $mMin) {
            margin: 0 auto;
            max-width: 552px;
            text-align: center;
        }
    }
}

.postscript {
    background-color: $bg-color;
    color: $primary-color;
    text-align: center;
}

.tablet-footer-item {
    display: inline;
    @include respond(large-up) {
        .sub-item {
            &:nth-child(2) {
                float: right;
                padding-right: 5px;
            }
        }
    }
}

@media only screen and (min-width: $large-breakpoint) and (max-width: $desktop-breakpoint) {
    .tablet-footer-item {
        width: 15%;
        margin-left: 4%;
        display: inline-block;
        .footer-item.sub-item {
            width: 100%;
            margin: 0;
            float: none;
        }
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1084px) {
    .footer-container {
        .footer-item.sub-item {
            margin-left: 2.5%;
        }
    }
}
