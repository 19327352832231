.ds-global-promo-banner {
    background-color: $brand-primary;
    max-height: 104px;
    padding: 12px 16px;
    position: relative;

    @media screen and (min-width: $xlMin) {
        max-height: 45px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .ds-background-link[href] {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .middot {
        &::before {
            content: " ";
            letter-spacing: 1em;
            background-image: radial-gradient(
                circle at center,
                #fff 0,
                #fff 2px,
                transparent 3px,
                transparent 100%
            );
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 18px 18px;
            padding-bottom: 2px;
        }
    }

    .ds-nowrap {
        white-space: nowrap;
    }

    li {
        color: $text-inverse;
        list-style: none;
        display: inline;
        @include ds-ui-semi(m);
        letter-spacing: 0.16px;

        &::before {
            content: " ";
            letter-spacing: 0;
        }
    }

    .ds-dialog__button:hover {
        color: $text-inverse;
    }

    .ds-dialog__button {
        background: transparent;
        color: $text-inverse;
        @include ds-ui-regular(s);
        padding-left: 0;
        padding-right: 0;
        padding-top: 4px;

        @media screen and (min-width: $xlMin) {
            @include ds-ui-semi(m);
            font-weight: 400;
            padding-top: 0;
        }
    }

    .ds-promo-banner-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;

        @media screen and (min-width: $xlMin) {
            max-width: 768px;
        }

        .ds-promo-line-1 {
            position: relative;
            text-align: center;
            font-variant-numeric: oldstyle-nums;
            @include ds-ui-semi(m);
            letter-spacing: 0.16px;
        }

        .ds-promo-code {
            margin-left: 2px;
            font-variant: small-caps;
        }

        .ds-promo-line-2 {
            position: relative;
            z-index: 100;
            display: inline-block;
            padding-left: 4px;
            text-align: center;

            @media screen and (min-width: $xlMin) {
                padding-left: 8px;
                padding-top: 0;
            }

            li {
                @include ds-ui-semi(s);
                font-weight: 400;

                @media screen and (min-width: $xlMin) {
                    @include ds-ui-semi(m);
                    font-weight: 400;
                }
            }
        }

        .ds-urgency {
            padding-top: 4px;

            @media screen and (min-width: $xlMin) {
                padding-top: 0;
            }
        }

        .ds-channel {
            padding-top: 4px;

            @media screen and (min-width: $xlMin) {
                padding-top: 0;
            }
        }

        .ds-member-exclusive {
            padding-top: 4px;

            @media screen and (min-width: $xlMin) {
                padding-top: 0;
            }
        }

        .ds-dialog_container {
            display: inline;
            position: relative;

            @media screen and (max-width: $mMin) {
                &:before {
                    position: absolute;
                    content: "";
                    top: -11px;
                    right: -4px;
                    left: -4px;
                    bottom: -11px;
                }
            }
        }
    }
}

.global-promo-banner-carousel-module {
    .slick-slider.slick-dotted {
        z-index: 111;
    }

    .ds-carousel-dots {
        display: none;
    }

    .slick-track {
        margin-bottom: 0;
    }

    .carousel-item {
        padding: 0;
    }

    .carousel-item {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    .ds-carousel-next-button,
    .ds-carousel-prev-button {
        height: 18px;
        width: 9px;
        padding: 0 12px;
        z-index: 112;

        @media screen and (min-width: $xlMin) {
            padding: 0 165px;
        }

        button {
            height: 18px;
            max-height: 18px;
            width: 9px;
            padding: 0;

            .slick-arrow {
                padding: 0;
            }

            &::before {
                content: "";
                background: url("../images/svg-icons/right-arrow.svg") no-repeat
                    scroll center transparent;
                height: 18px;
                width: 9px;
                z-index: 110;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }

    .ds-promo-banner-content {
        @media screen and (min-width: $xlMin) {
            max-width: 843px;
        }
    }
}

.global-promo-banner-carousel-module.bannersExist {
    .slider-controls {
        justify-content: space-between;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}
