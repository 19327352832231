.global-locations {
    .locations-header {
        h1 > .mobilehead {
            @media screen and (max-width: 479px) {
                font-size: 24px !important;
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }
    .single-accordion {
        &.ajax {
            @media screen and (max-width: 479px) {
                padding: 0 15px;
            }
        }
    }
    .country-label {
        background-image: url("/on/demandware.static/-/Sites-BathAndBodyWorks-Library/default/images/global-locations/flag_sprites@2x.png");
        background-size: 32px;

        &.curacao {
            background-position: 0 -558px;
        }

        &.india {
            background-position: 0 -576px;
        }
    }
}
