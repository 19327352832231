.ds-select-container {
    .ds-select {
        height: 48px;
        display: block;
        @include ds-ui-regular(l);
        color: $text-primary;
        padding: 0 48px 0 16px;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        border: 1px solid $border-secondary;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: $bg-primary;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00457 9.30005L0 1.35285L0.905028 0.300053L9.00457 7.45533L17.095 0.300053L18 1.35285L9.00457 9.30005Z' fill='black'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 19px top 50%, 0 0;
        background-size: 18px, 9px;
    }

    .ds-select-label {
        @include ds-ui-bold(m);
        color: $text-primary;
        margin-bottom: 8px;

        .optional-indicator {
            @include ds-body(s);
            color: $text-secondary;
        }
    }

    .error-text,
    .helper-text {
        @include ds-ui-regular(s);
        margin-top: 8px;
    }

    .error-text {
        display: none;
    }

    &.required-error .error-text {
        color: $text-feedback-danger;
        display: flex;
        align-items: center;

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C15.523 0 20 4.478 20 10C20 15.522 15.523 20 10 20C4.477 20 0 15.522 0 10C0 4.478 4.477 0 10 0ZM10 1.667C5.405 1.667 1.667 5.405 1.667 10C1.667 14.595 5.405 18.333 10 18.333C14.595 18.333 18.333 14.595 18.333 10C18.333 5.405 14.595 1.667 10 1.667ZM9.99866 12.5022C10.5502 12.5022 10.9973 12.9494 10.9973 13.5009C10.9973 14.0524 10.5502 14.4996 9.99866 14.4996C9.44712 14.4996 9 14.0524 9 13.5009C9 12.9494 9.44712 12.5022 9.99866 12.5022ZM9.99446 5C10.3742 4.9997 10.6882 5.2816 10.7381 5.64764L10.7451 5.7494L10.7487 10.251C10.749 10.6652 10.4135 11.0013 9.99926 11.0016C9.61957 11.0019 9.30555 10.72 9.25559 10.354L9.24866 10.2522L9.24506 5.7506C9.24473 5.33639 9.58025 5.00033 9.99446 5Z' fill='%23CB0100'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            content: "";
            display: block;
            flex-shrink: 0;
            height: 24px;
            margin-right: 4px;
            width: 24px;
        }
    }

    .helper-text {
        color: $text-secondary;
    }
}
