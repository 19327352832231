.infinite-scroll {
    .search-result-content .infinite-scroll-loading {
        text-align: center;
        background: url("../images/infinite-loading-indicator.gif") center no-repeat;
        height: 35px;
        width: 100%;
    }
}

.disable-infinite-scroll .search-result-content .page-element {
    display: none;
}
