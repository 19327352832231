.bopis-delivery-options {
    padding: 30px 0 26px 0;
    border-top: 1px solid $gainsboro;

    @include respond(large-up) {
        padding-top: 25px;
    }

    .out-of-stock-message,
    .bopis-recurring {
	    color: #CC0000;
        font-size: rem(12px);
        display: block;
        padding-top: rem(30px);
    }

    .bopis-delivery-option-input {
        width: 16px;
        height: 16px;
    }

    .delivery-options {
        @include ds-ui-semi(m);
    }

    .ship-it-msg {
        @include ds-heading(h5);
    }

    .ship-it-msg,
    .delivery-options {
        color: $text-primary;
        text-transform: none;
        padding: 0;
        margin-bottom: 20px;

        .ship-it-truck-icon {
            display: inline-block;

            &:before {
                content: '';
                background: url(../images/svg-icons/bopis-shipping.svg) no-repeat;
                background-size: 25px 12.8px;;
                width: 25px;
                height: 12.8px;
                margin: 1.6px 7px 1.6px 0;
                display: flex;
            }
        }
    }

    .availability-web {
        .availability-msg {
            @include ds-ui-regular(m);

            .in-stock-msg {
                color: $text-feedback-success;
                margin-top: 7px;
                height: 10px;
            }

            .header-bopismsg {
                color: $text-feedback-danger;
                line-height: 12px;
                margin-top: 7px;
            }
        }
    }

    .bopis-not-for-pickup {
        .availability-web {
            .availability-msg {
                .in-stock-msg,
                .header-bopismsg {
                    padding-left: 32px;
                }
            }
        }

        .noteligibleforpickup {
            padding: 19px 24px 0 32px;
            margin: 0;
            width: 236px;
            line-height: unset;

            .not-eligible-for-pickup-label {
                @include ds-ui-regular(m);
                color: $text-secondary;
            }
        }
    }

    .bopis-eligible-for-pickup {
        .ship-it-msg {
            text-transform: none;
            float: left;
            clear: none;
            margin-bottom: 0;
            padding-left: 5px;
        }

        .bopis-shipping-option {
            float: left;
            clear: none;
        }

        .availability-web {
            clear: both;
            margin-top: 0;
            .availability-msg {
                margin-bottom: 19px;
                .header-bopismsg,
                .in-stock-msg {
                    padding:5px 0 0 53px;
                    margin-top: 0;
                    height: 10px;
                }
            }
        }

        .bopis-pfs-with-store-container {
            position: relative;
            padding: 0 0 0 53px;

            .bopis-pick-from-store-option {
                position: absolute;
                left: 0;
            }

            .bopis-pfs-option-main-container {
                font-size: 0; // to remove extra space between tags
                .bopis-pick-from-store-option-label {
                    @include ds-heading(h5);
                    color: $text-brand-primary;
                    text-transform: none;
                    margin-bottom: 0;
                    display: inline-block;
                    position: relative;
                    padding-left: 0;
                    padding-right: 0;

                    .pdp-pick-up-icon {
                        &::before {
                            content: '';
                            background: url(../images/svg-icons/bopis-icon-small.svg) no-repeat;
                            background-size: 14px 16px;
                            background-position: center;
                            width: 23px;
                            height: 16px;
                            position: absolute;
                            left: -31px;
                        }
                    }

                    .pick-up-in-store-msg {
                        &.add-semi-colon {
                            &::after {
                                content: ': ';
                                white-space: pre;
                            }
                        }
                    }
                }

                .pdp-selected-store-name {
                    @include ds-ui-semi(m);
                    color: $text-primary;
                    margin-bottom: 0;
                    text-transform: capitalize;
                    display: block;
                    padding-right: 10px;

                    @include respond(large-up) {
                        display: inline-block;
                    }
                }

                .pdp-change-store-inline {
                    white-space: nowrap;
                    font-size: 14px;
                    line-height: 16px;

                    @include respond(medium-down) {
                        display: none !important;
                    }
                }

            }

            .bopis-set-store {
                @include ds-ui-semi(m);
                color: $text-primary;
                text-decoration: underline;
            }

            .bopis-set-store-container {
                &.bopis-pdp-set-store {
                    padding-top: 10px;
                    clear: both;
                }

                &.bopis-pdp-change-store {
                    margin-top: 13px;
                    @include respond(medium-down) {
                        margin-top: 16px;
                        margin-bottom: 5px;
                    }
                }
            }

            .availability-novariation {
                @include ds-ui-regular(m);
                color: $text-feedback-success;
                padding: 5px 0 0 0;
                margin-bottom: 0;
            }

            .availability-oos {
                color: $text-feedback-danger;
            }
        }

        .pdp-show-bopis-store-detail {
            clear: both;
            padding: 13px 0 0 52px;

            .pdp-show-selected-store-details-link {
                @include ds-ui-regular(s);
                color: $text-primary;
                display: inline;
                padding-right: 10px;
                position: relative;
                cursor: pointer;

                &::after {
                    content: '';
                    background: url(../images/svg-icons/UI-Carat.svg) no-repeat center;
                    position: absolute;
                    right: 0;
                    top: 7px;
                    width: 8px;
                    height: 4px;
                    background-size: 8px;
                    @include transition(all 0.5s ease-out);
                    transform: rotate(0);
                }

                &.active {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }

            .pdp-show-collapsed-info {
                @include ds-ui-regular(s);
                color: $text-tertiary;
                padding-top: 15px;

                .address-city-state-zip {
                    .address-city-state-zip-link {
                        color: $text-primary;
                        text-decoration: underline;
                        text-transform: capitalize;
                        display: inline-flex;
                    }
                }

                .store-phone-no {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    a {
                        @include ds-ui-regular(s);
                        color: $text-primary;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.bopis-enabled-add-to-cart-form,
.bopis-disabled-add-to-cart-form {
    padding-bottom: 13px;

    @include respond(large-up) {
        padding-bottom: 8px;
    }

    fieldset {
	    border-top: rem(1px) solid #E5E5E5;
        padding: rem(16px) 0 0;
    }

    .div-btn-2.disabled {
	    opacity: .45;
        pointer-events: none;
    }
}

.pdp-offer,
.quickview-offer,
.cart-offer {
    font-size: rem(14px);
    position: relative;

    .og-tooltip-content {
	    outline: #333333 dotted;
        outline-width: 1px;
        outline-offset: 1px;
    	&:hover {
			.tooltip-icon {
				background: url(../images/svg-icons/tooltip-active.svg) no-repeat;
				width: 1.625rem;
				height: 1.625rem;
				display: inline-block;
				content: '';
				outline: #333333 dotted;
				outline-width: 1px;
				outline-offset: 1px;
			}
        }
        .tooltip-copy {
	        h2 {
		        font-weight: 700;
                font-size: 13px;
                margin-left: -15px;
                margin-top: 0;
	        }
            ul + a {
	            color: #005699;

            }
        }
    }

    .freq-section {
        @include ds-body(m);
        color: $text-secondary;
        margin: rem(18px) 0 rem(28px) rem(44px) !important;
    }

    .content.bottom {
	    display: block;
        left: rem(-60px) !important;
        height: 200px;
    }

    .offer-text {
        @include ds-body(m);
        color: $text-secondary;
        margin: 0;
        padding: rem(5px) 0 0 rem(45px);
    }

    .og-tooltip {
        position: absolute !important;
        right: rem(10px);
        top: rem(7px);
    }

    .subscription-details {
        @include ds-heading(h5);
        color: $text-primary;
    }

    .tooltip-icon {
        background: url(../images/svg-icons/tooltip-default.svg) no-repeat;
        width: rem(26px);
        height: rem(26px);
        display: inline-block;
        content: '';

        &:hover {
            background: url(../images/svg-icons/tooltip-hover.svg) no-repeat;
            width: rem(26px);
            height: rem(26px);
            display: inline-block;
            content: '';
            outline: #333333 dotted;
            outline-width: 1px;
            outline-offset: 1px;
        }

        &:active {
            background: url(../images/svg-icons/tooltip-active.svg) no-repeat;
            width: rem(26px);
            height: rem(26px);
            display: inline-block;
            content: '';
            outline: #333333 dotted;
            outline-width: 1px;
            outline-offset: 1px;
        }

        &:focus {
            background: url(../images/svg-icons/tooltip-focus.svg) no-repeat;
            width: rem(26px);
            height: rem(26px);
            display: inline-block;
            content: '';
            outline: #333333 dotted;
            outline-width: 1px;
            outline-offset: 1px;
        }
    }

    span.new-phrase {
        @include ds-heading(h5);
        color: $brand-accent;

        &::before {
            background: url(../images/svg-icons/og-icon.svg) no-repeat;
            width: rem(22px);
            height: rem(15px);
            display: inline-block;
            content: '';
            margin: rem(15px) rem(6px) 0 0;
            vertical-align: text-bottom;
        }
    }

    .freq-sel {
        border-radius: 0;
        width: rem(152px);
        height: rem(43px);
    }
}

.tooltip:hover .content {
    height: 200px!important;
}


.og-tooltip-content {
	position: relative;
	@include respond(medium-down) {
        left: -60%!important;
        top: auto!important;
        position: absolute!important;
        width: 250px!important;
        margin-top: rem(-190px);
    }
    .fa-remove.close {
	    display: none;
	    @include respond(medium-down) {
	    position: absolute;
        top: 10px;
        right: 5px;
        display: block;
            &:before {
	            content: "\F00D";
                font-size: 16px;
	        }
        }
    }
}
.pdp-main-container {
    .bopis-set-store-container {
        &.bopis-pdp-change-store {
            @include respond(large-up) {
                display: none !important;
            }
        }
    }
}

.pdp-ql-main-container {
    hr.ql-bottom-line {
        border: 0.5px solid $gainsboro;
        margin-top: 13px;
        margin-bottom: 0;
    }
}

.og-modal-footer {
    .og-cancel {
        padding-top: rem(8px) !important;
        background-color: #ffffff !important;
        border: rem(1px) solid #E5E5E5 !important;
        color: #333333 !important;
    }
}

.og-address-form,
.og-shipping-form {
    .og-back,
    .og-cancel {
        width: rem(129px);
        height: rem(43px);
        padding: 0 !important;
    }

    .og-cancel {
	    margin-right: rem(15px);
    }

    .og-default {
        width: rem(122px);
        height: rem(43px);
        padding: 0 !important;
    }

    & > * {
        margin-top: rem(30px) !important;

        @include respond(medium-down) {
            margin-top: 0 !important;
        }
    }
}

.og-toast-container {
    .og-toast {
	    font-weight: bold !important;
        @include respond(medium-down) {
            text-align: left !important;
        }
        .og-toast-danger {
	        .ng-scope {
                @include respond(medium-down) {
                    text-align: left !important;
                }
            }

            button {
                color: #CC0000 !important;

                &:hover {
                    background-color: #FFE9E9 !important;
                }
            }
        }

        .og-toast-success {
	        .ng-scope {
                @include respond(medium-down) {
                    text-align: left !important;
                }
            }

            button {
	            color: #1D6B49 !important;

                &:hover {
                    background-color: #E9FFF2 !important;
                }
            }

        }
    }
}

.og-uib-day {
    button {
        padding: 0px rem(7px) !important;
        line-height: rem(17px) !important;

        &.og-dp-selected {
            background-color: #2A5799 !important;
        }
    }
}

.uib-datepicker .og-uib-daypicker {
	.og-date-picker-controls {
        margin-top: rem(18px) !important;
	}

    table {
        border: 0 !important;
        margin: 0 !important;

        @include respond(medium-down) {
            font-size: rem(12px) !important;
        }

        td {
	        padding: 0 !important;

            button {

            }
        }

        td, th {


            @include respond(medium-down) {
                height: rem(40px) !important;
            }
        }

        .og-dp-not-current-month {
            color: #333333 !important;
        }
    }
}

og-offer.cart-offer {
	margin-top: rem(18px);
}

.default-card-unset-error {
    padding: rem(12px) rem(25px) rem(12px) rem(15px);
    color: #CC0000;
    font-size: rem(14px);
    font-weight: bold;
    background-color: #FFE9E9;
    margin-bottom: rem(15px);

    a.default-payment-link {
        color: #cc0000;
        text-decoration: underline;
    }
}

.billing-new-card {
    .save-cc-container.checkbox {
        width: auto !important;

        .default-card-info {
            @include ds-ui-regular(s);
            color: $text-primary;
        }
	}
}

.form-row-button {
    &.continue-row {
	    margin-top: rem(40px);
    }
}

#og-sent-shipments {
	#shipment-processing-header {
		display:block;
	}
}

.checkout-shipping.form-horizontal {
    .form-row.checkbox {
        width: auto !important;
    }
}
