.ds-shoppable-image.has-desktop-override {
    .ds-shoppable-image-desktop {
        display: none;
    }

    @media screen and (min-width: $xlMin) {
        .ds-shoppable-image-mobile {
            display: none;
        }

        .ds-shoppable-image-desktop {
            display: block;
        }
    }
}
