.product-tile {
    @extend %clearfix;
    color: $secondary-color;
    position: relative;
    text-align: left;
    display: table-cell;
    vertical-align: top;
    line-height: 1.2;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-height: 300px;
        max-width: 100%;
        object-fit: contain;
        object-position: 50% 100%;
        @media only screen and (max-width: $desktop-breakpoint - 64) {
            max-height: 195px;
            max-width: 100%;
        }
    }
    .product-outline-block{
        .product-tile-link{
            display: block;
        }

        .product-social-text {
            align-items: center;
            background-color: $brand-primary;
            border-radius: 2px;
            color: $text-inverse;
            display: inline-flex;
            justify-content: center;
            margin-bottom: 32px;
            padding: 4px 8px;
            @include ds-ui-bold(s);
        }
    }
    .product-image {
        position: relative;
        display: table;
        height: auto;
        table-layout: fixed;
        width: 100%;
        margin-bottom: 16px;
        .quickview {
            display: none !important;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 65px;
            height: 65px;
            line-height: 80px;
            @include font-size(12px);
            text-decoration: underline;

            border: none;
            text-transform: none;
            text-align: center;
            letter-spacing: normal;
            padding: 0;
            max-height: none;
            border-radius: 50%;
            margin: 0 10px 10px 0;
            color: $brand-black;
            border-color: $bg-color;
            background-color: rgba(255, 255, 255, .75);

            &::before {
                display: block;
                content: ' ';
                position: absolute;
                top: 16px;
            left: 50%;
                transform: translateX(-50%);
                width: 20px;
                height: 12px;
                background-size: cover;
                background-image: url('../images/svg-icons/quicklook-eye.svg');
            }

            &:hover {
                text-decoration: none;
                border-color: transparent !important;
                background-color: white !important;
                opacity: .75;
            }
            &:active {
                opacity: 1;
            }
        }
        &.focused {
            a#quickviewbutton {
                display: block !important;
            }
        }
        body:not(.touch) & {
            &:hover {
                a#quickviewbutton {
                    @include respond(large-up){
                        display: block !important;
                    }
                }
            }
        }
        .thumb-link {
            display: table-cell;
            vertical-align: bottom;
            width: 100%;
            max-width: 100%;
            .card{
                position: relative;
            }
            &.rollover {
                perspective: 1000;
                .card {
                    /*transform-style: preserve-3d;
                    transition: all 0.5s linear;*/
                    z-index: 1;
                }
                @include respond(desktop) {
                    &:hover {
                        .face {
                            &.front {
                                opacity: 0;
                            }
                            &.back {
                                opacity: 1;
                            }
                        }

                    }
                }
                .face {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    /*backface-visibility: hidden;*/
                    opacity: 1;
                    transition: opacity .5s ease-in-out;
                }
                .face.back {
                    display: block;
                    /*transform: rotateY(180deg);*/
                    box-sizing: border-box;
                    top: 0;
                    position: absolute;
                    opacity: 0;
                }
            }
        }
    }

    .product-badge-icon {
        width: 40px;

        img {
            max-width: 100% !important;
        }
    }

    .recommendation_image {
        position: relative;

        .quickview-rec-link {
            display: none;
            box-sizing: border-box;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%,-50%);
            z-index: 101;
        }
    }

    .product-badge {
        margin: 2px 0 3px;

        @include respond(large-up) {
            margin: 2px 0 0;
        }

        .badge {
            @include ds-ui-semi(s);
            color: $text-brand-primary;
            text-align: center;
            width: 100%;

            &.outofstock {
                color: $text-brand-accent;
                text-transform: uppercase;
            }
        }
    }

    .product-cont {
        margin-bottom: 3px;
        margin-top: 1px;
    }

    .product-name {
        @include ds-ui-semi(m);
        margin-bottom: 2px;
        margin-top: 0;
        text-transform: none;
        color: $text-primary;

        @include respond(large-up) {
            margin-bottom: 5px;
        }

        a {
            color: $secondary-color;
        }
    }
    .product-type{
        @include ds-ui-regular(m);
        margin: 0;
        text-transform: none;
        color: $text-primary;

        @include respond(large-up) {
            margin-top: 3px;
        }
    }

    .grid-tile & .product-swatches {
        float: right;
    }

    .product-swatches-all {
        cursor: pointer;
        font-size: 0.9em;
    }

    .product-swatches {
        display: none;
        ul {
            list-style: none outside none;
            margin: 0;
            overflow: hidden;
            padding: 0;
            &.swatch-toggle {
                background: none repeat scroll 0 0 $white;
                display: none;
                position: absolute;
            }
        }

        &:hover ul.swatch-toggle {
            display: block;
        }

        li {
            float: left;
        }

        .swatch {
            display: block;
            width: 16px;
            img {
                border: 1px solid $white;
                height: 12px;
                padding: 1px;
                width: 12px;
            }
        }

        .selected img, img:hover {
            border-color: $citrus;
        }
    }

    .product-promo {
        @include ds-ui-semi(s);
        color: $text-brand-accent;
        margin-bottom: 16px;
        p{
            margin: 0;
            @include font-size(11px);
            @include line-height(16px);
            color: $red;
        }
        a{
            color: $text-brand-accent;
        }
    }

    .centered {
        width: auto;
        margin: 0 auto;
    }

    .add-to-cart {
        @include ds-utilities-button-labels;
        display: block;
        float: none;
        margin-left: 0;
        padding: 0 13px;
        width: 100%;
        min-height: 48px;
    }

    .product-compare {
        float: left;
        line-height: 11px;

        label {
            font-weight: 400;
            line-height: 1;
            padding: 0 0 0 .5em;
            width: auto;
        }

        input {
            float: left;
            margin: 0;
        }
    }

    .product-review {
        @include ds-ui-regular(s);
        color: $text-primary;
        margin: 6px 0 10px;
        display: inline-block;
        text-decoration: underline;

        .rating-title {
            display: inline-block;
            margin-right: 1em;
        }

        .rating {
            display: inline-block;
        }
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
        &:active {
            color:#C2C2C2;
        }
        &+ .appendtext {
            padding-top: 0;
        }
    }
    .rating-stars {
        position: relative;
        display: inline-block;
        vertical-align: sub;
        .star-mask {
            display: block;
            position: absolute;
        }
        .rating-star {
            background: rgb(255, 255, 255);
            display: block;
            box-sizing: border-box;
            float: left;
            width: 20px;
            height: 18px;
            margin: 1px;
            &.full-star {
                background: $brand-primary;
            }
            &.empty-star {
                background: rgb(242, 242, 242);
            }
        }
    }

    .wide-tiles & {
        height: auto !important; // overwrite synced heights set on element

        .product-image {
            float: left;
            padding-right: 2em;
        }

        .product-name, .product-pricing, .product-promo, .product-swatches, .product-review {
            float: left;
            text-align: left;
            width: 65%;
        }

        .product-name {
            font-size: 1.5em;
            height: auto;
            margin-top: 0;
        }

        .product-pricing {
            font-size: 1.3em;
        }

        .product-swatches {
            margin-bottom: 1em;
        }

        @include respond(medium-down) {
            .product-image {
                width: 41%;
            }

            .product-name, .product-pricing, .product-promo, .pr_stars, .product-swatches {
                float: right;
            }

            .product-name h2 {
                margin-top: 0px;
            }
        }

        @include respond(medium) {
            .product-name h2 {
                margin-top: 10px;
            }
            .product-image {
                width: 38%;
            }
        }
    }

    @include respond(medium-down) {

        .product-swatches .swatch img {
            height: 2em;
            width: 2em;
        }
    }

    @include respond(medium) {
        .pr_stars {
            left: 40px;
        }
    }

    .appendtext {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        word-break: break-word;
        color: $brand-blue;

        font-size: 12px;
        line-height: 14px;
        width: 100%;
        padding: 5px 17px 0;
        margin: 0;

        @include respond(large-up) {
            padding: 10px 2px 9px;
        }

        .bopis-icon {
            &::before {
                content: '';
                background: url(../images/svg-icons/bopis-icon-small.svg) no-repeat;
                background-size: 14px 18px;
                width: 14px;
                height: 18px;
                margin-right: 3px;
                display: flex;
            }
        }

        .available-msg {
            @include ds-ui-regular(s);
            color: $text-brand-primary;
            text-align: left;
        }
    }
    div[data-bv-show="inline_rating"] {
        margin-top: 9px;
        a[aria-label*="0.0"] {
            visibility: hidden !important;
        }

        .bv_main_container .bv_numReviews_component_container {
            text-decoration: underline !important;
        }

        .bv_main_container .bv_averageRating_component_container {
            display: none !important;
        }
    }

    .product-availability {
        text-align: left;

        .product-availability-message {
            @include ds-ui-bold(s);

            &.product-available {
                color: $text-brand-primary;
            }

            &.product-unavailable {
                color: $text-secondary;
            }
        }

        a {
            text-decoration: underline;
            text-transform: capitalize;
        }
    }

    .hide-element {
        display: none;
        width: 0;
        height: 0;
        transform: scale(0);
    }

    .loading-spinner {
        animation: rotate 1200ms linear infinite;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .quantity-field {
        display: flex;
        align-items: center;
        border: 2px solid $border-secondary;

        span.quantity-button {
            font-size: 24px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            width: 32px;
            padding: 8px;
            margin: 0 4px;

            &:hover {
                cursor: pointer;
                background: $bg-secondary;
            }

            &.disabled {
                color: $text-disabled;
                pointer-events: none;
            }
        }

        input#Quantity {
            @include ds-ui-bold(m);
            text-align: center;
            border: none;
            height: 44px;
        }
    }

    .reveal-qty-selector {
        transition: transform 0s;
    }
}

.product-pricing {
    @include ds-ui-semi(l);
    color: $text-primary;
    margin-top: 12px;
    span{
        @include ds-ui-semi(l);
        color: $text-primary;
        margin: 0 2px;
    }
    .product-standard-price {
        @include ds-ui-regular(l);
        text-decoration: line-through;
        color: $text-primary;
        & ~ {
            .product-sales-price{
                @include ds-ui-semi(l);
                color: $text-brand-accent;
            }
        }
    }
    a:nth-child(2) > .product-sales-price{
        color: $text-brand-accent;
    }
}

.capture-product-id {
    left: -999em;
    position: absolute;
}

// BEGIN ADDITIONAL PRODUCT IMAGE STYLING
// ======================================
.product-tile {
    height: 100% !important;
    overflow: visible;

    .zone_product-image_main {
        height: 100%;

        img {
            bottom: 0;
            left: 0;
            top: 100%;
            position: relative;
            transform: translate(0, -100%);
        }
    }

    .tile-image-wrapper {
        width: 100%;
    }

    .zone_product-image_alts {
        display: none;
    }

    .front.face {
        display: flex;
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .tile-image-wrapper {
            flex: 0 0 auto;

            @include respond(medium-down) {
                scroll-snap-align: center;
            }
        }

        @include respond(medium-down) {
            &.scrolled {
                scroll-snap-type: x mandatory;
            }
        }
    }

    .front.face::-webkit-scrollbar {
        display: none;
    }


    .zone_product-image_controls {
        position: absolute;
        display: none;
        width: 100%;
        bottom: 143px;
    }
    .product-image-tile_control {
        transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1) ease-in-out;
        background: rgba(256,256,256,.75) url(../images/svg-icons/UI-Carat.svg) no-repeat 50%;
        background-size: 12px;
        display: inline-block;
        border: 0;
        border-radius: 11px;
        color: transparent;
        overflow: hidden;
        width: 38px;
        height: 26px;
        padding: 0;
        position: absolute;
        &:hover,
        &:focus,
        &:active {
            color: transparent !important;
            border-color: transparent !important;
            background-color: rgba(256,256,256,.75) !important;
        }
    }
    .prev {
        transform: rotate(90deg);
        left: 24px;
    }
    .next {
        transform: rotate(-90deg);
        right: 24px;
    }
}


@include respond(large-up) {
    .tile-two-images {
        .front.face {
            .tile-image-wrapper {
                position: absolute;
                opacity: 0;
                transition: opacity .3s;
                left: 0;

                &.main-tile-image-wrapper {
                    position: static;
                    opacity: 1;
                }
            }

            &:hover {
                .tile-image-wrapper {
                    opacity: 1;

                    &.main-tile-image-wrapper {
                        opacity: 0;
                    }
                }
            }
        }
    }
    .product-tile .zone_product-image_controls.show {
        display: block;
    }
    .tile-three-plus-images:hover {
        .zone_product-image_controls {
            display: block;
        }
    }
    .recommendation-item {
        &:hover, &:focus-within {
            .quickview-rec-link {
                display:block;
            }
        }
    }
}

.jact-snackbar {
    position: fixed;
    bottom: 32px;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.20);
    display: flex;
    width: 320px;
    padding: 14px 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    z-index: 200000000;

    &.jact-snackbar-success {
        background-color: $bg-feedback-success;
        color: $text-feedback-success;

        span {
            &::before {
                background-image: url("../images/svg-icons/check-circle-fill.svg");
                content: "";
                height: 24px;
                left: 0;
                position: absolute;
                width: 24px;
                background-repeat: no-repeat;
            }
        }
    }

    &.jact-snackbar-error {
        background-color: $bg-feedback-danger;
        color: $text-feedback-danger;

        span {
            &::before {
                background-image: url("../images/svg-icons/alert-triangle-filled.svg");
                content: "";
                height: 24px;
                left: 0;
                top: 0;
                position: absolute;
                width: 24px;
                background-repeat: no-repeat;
            }
        }
    }

    span {
        @include ds-ui-semi(m);
        padding-left: 32px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 0 0;
        align-self: stretch;
    }
}

/*Hide Quick look Button in the Gift Card Page*/
body:not(.touch) .product-tile .product-image:hover a#quickviewbutton[title*="Gift Card"],
.product-tile .product-image a#quickviewbutton[title*="Gift Card"],
.product-tile .product-image.focused a#quickviewbutton[title*="Gift Card"] {
display: none !important;
}
// ====================================
// END ADDITIONAL PRODUCT IMAGE STYLING
