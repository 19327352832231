.content-slot img {
    height: auto;
    max-width: 100%;
}

.slot-grid-header p {
    margin: 0;
    padding: 0;
}

.product-listing-1x4 {
    background: none repeat scroll 0 0 $white;
    overflow: hidden;
    padding: .83em 0;
    .search-result-items .grid-tile {
        margin: 0 1% 0 2%;
        width: 18%;
        @include respond(medium-down) {
            margin: 2%;
            width: 92%;
        }
    }
}

.account-nav-asset {
    line-height: 1.5em;
    margin: 1em;
    padding: 1em 0;
    @include respond(medium-down) {
        margin: 0;
    }
    h2 {
        margin: 1.16em 0 1.66em;
    }
}

.content-asset {
    p {
        line-height: 1.6em;
        margin: 1em 0;
    }
    ul, ol {
        line-height: 1.6em;
        margin: 1em 0;
        padding: 0 1.5em;
    }
    ul li {
        list-style: disc outside none;
        margin-bottom: calculateEm(25px);
    }
    ol li {
        list-style: decimal outside none;
    }
    address {
        margin: 1em 0;
    }
    .account-options {
        margin: 0;
        padding: 0;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        #join-rewards {
        	& > img {
        		width: 94.95px;
        		margin-right: 0;
        	}
        	h3 > img {
        		width: 179px;
        		height: 34px;
        		margin: 0;
        	}
        	.info {
        		padding-left: 16px;
        		padding-top: 10px;
        		padding-bottom: 14px;
        		padding-right: 14px;
        		background-color: $hollywood-cerise;
        		width: 210px;
        		p {
        			color: $white;
        			margin-top: 44px;
        			@include font-size(11px);
        			@include line-height(14px);
        			span {
        				display: inline;
        			}
        		}
        		.underlined {
        			text-decoration: underline;
        		}
        	}
        	@include respond(medium-down) {
        		& > img {
	        		width: 92px;
        		}
        		h3 > img {
	        		width: 163px;
	        	}
	        	.info {
	        		padding-left: 12px;
	        		padding-top: 10px;
	        		padding-bottom: 14px;
	        		padding-right: 12px;
	        		width: 174px;
	        		p {
	        			color: $white;
	        			margin-top: 44px;
	        			@include font-size(9px);
	        			@include line-height(12px);
	        		}
        		}
        	}
        	@include respond(large) {
        		& > img {
	        		width: 79px;
        		}
        		h3 > img {
	        		width: 128px;
	        	}
	        	.info {
	        		padding-left: 10px;
				    padding-top: 5px;
				    padding-bottom: 9px;
				    padding-right: 3px;
				    width: 144px;
	        		p {
	        			color: $white;
	        			margin-top: 41px;
	        			@include font-size(9px);
	        			@include line-height(12px);
	        		}
        		}
        	}
        }
        li {
            float: left;
            list-style: none outside none !important;
            margin: 0 0 41px;
            padding: 0;
            position: relative;
            width: 100%;
            @include respond(large-up) {
                width: 48.5%;
                &.odd {
                    clear: both;
                }
                &.even {
                    float: right;
                }
            }
            @include respond(medium) {
                max-width:290px;
            }
            a {
                display: block;
                img {
	                margin-right: 15px;
	                float: left;
	                width: 72px;
	                vertical-align: top;
	                @include respond(large-up) {
	                   width: 84px;
	                   height: 84px;
	                   margin-right: 30px;
	                }
                }
                .info {
                    float: left;
                    width: calc(100% - 87px);
					@include respond(large-up) {
					   width: calc(100% - 114px);
					}
                    h3 {
                        margin: 5px 0;
                        @include font-size(14px);
                        @include respond(large-up) {
                            @include font-size(16px);
                        }
                    }
                    p {
                        margin: 0;
                        padding: 0;
                        @include font-size(14px);
                        @include line-height(20px);
                        max-width: 190px;
                        @include respond(large-up) {
                            @include line-height(20px);
                            max-width: none;
                        }
                        span {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.browser-compatibility-alert {
    background-color: $sangria;
    color: $white;
    font-size: 1.3em;
    line-height: 2em;
    padding: 0.8em;
}

.folder-slot {
    border-style: solid;
    border-color: $islamic-green;
    border-width: 1px;
    background-color: white;
    padding: 0.5em 0.5em;
    h2 {
        color: $islamic-green;
        margin: 0.5em 0em;
    }
}

.menu-slot {
    display: none;
    border-top: 1px solid $tutu;
    margin-top: 0.5em;
    padding-top: 1.5em;
    .social-links {
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 0;
        }
    }
    @include respond(large-up) {
        width: 13.5rem;
        display: block;
    }
}

#homepage-slides {
    margin: 0;
    position: relative;
    width: 20000em;
    .slide {
        float: left;
        overflow: hidden;
        position: relative;
        img {
            height: auto;
            max-width: 160%;
            @include respond(large-up) {
                max-width: 100%;
            }
        }
        .banner-message {
            position: absolute;
            top: 10%;
            @include respond(large-up) {
                top: 2%;
            }

            .banner-text1 {

                font-size: 2rem;
                font-weight: 300;
                text-transform: uppercase;
                @include respond(large-up) {
                    font-size: 5rem;
                    font-weight: 200;
                }
            }
            .banner-text2 {

                font-size: 1.5rem;
                font-style: italic;
                text-transform: lowercase;
                @include respond(large-up) {
                    font-size: 4rem;
                }
            }
            .banner-text3 {

                font-size: 0.5rem;
                font-weight: 400;
                text-transform: uppercase;
                @include respond(large-up) {
                    font-size: 1.3rem;
                    font-weight: 200;
                }
            }
        }
    }
    .slide1 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $cerulean;
                display: inline;
            }
            .banner-text2 {
                color: $white;
                display: inline;
            }
            .banner-text3 {
                color: $white;
                text-align: right;
            }
        }
    }
    .slide2 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $citrus;
                display: inline;
                letter-spacing: -0.1rem;
            }
            .banner-text2 {
                color: $dark-gray;
                display: inline;
            }
            .banner-text3 {
                color: $black;
            }
        }
    }
    .slide3 {
        .banner-message {
            left: 3%;
            .banner-text1 {
                color: $citrus;
                display: inline;
                margin-top: -2rem;
            }
            .banner-text2 {
                color: $white;
                display: inline;
            }
            .banner-text3 {
                color: $black;
            }
        }
    }
    .slide4 {
        .banner-message {
            right: 3%;
            .banner-text1 {
                color: $citrus;
                margin-top: 0rem;
                text-indent: 3rem;
                @include respond(large-up) {
                    margin-top: -1rem;
                }
            }
            .banner-text2 {
                color: $white;
            }
            .banner-text3 {
                color: $white;
            }
        }
    }
    .slide5 {
        .banner-message {
            left: 15%;
            .banner-text1 {
                color: $cerulean;
                display: inline;
            }
            .banner-text2 {
                color: $sangria;
                display: inline;
            }
            .banner-text3 {
                color: $white;
            }
        }
    }
}

#home-bottom-left {
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    width: 100%;
    h2 {
        color: $black;

        font-size: 2rem;
        font-weight: 100;
        margin: 0;
        padding: 1rem 0;
        text-transform: uppercase;
        @include respond(large-up) {
            font-size: 2.3rem;
        }
    }
    ul {
        margin: -.5em 0 .5em 0;
        li {
            border-right: 1px solid $dim-gray;
            color: $dim-gray;
            display: inline;

            font-size: .8rem;
            font-weight: 400;
            list-style: none outside none;
            margin: 0 5px 0 0;
            padding: 0 5px 0 0;
            text-transform: uppercase;
            &:last-child {
                border: 0 none;
            }
            span {
                margin: 0 10px 0 0;
            }
        }
    }
    img {
        display: block;
    }
}

#home-bottom-center {
    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    .home-bottom-center-text {
        position: absolute;
        text-align: center;
        top: 18%;
        width: 100%;
        h1 {
            color: $cerulean;

            font-size: 4rem;
            font-style: italic;
            font-weight: normal;
            margin: 0;
            padding: 0;
            text-transform: lowercase;
        }
        h2 {
            color: $dim-gray;

            font-size: 1.3rem;
            font-weight: 100;
            letter-spacing: .2em;
            margin-top: -.05rem;
            text-transform: uppercase;
        }
        h3 {
            color: $dim-gray;

            font-size: .8rem;
            font-weight: normal;
            margin-top: -1rem;
            text-transform: uppercase;
        }
    }
    img {
        display: block;
    }
}

.home-bottom-right {

    width: 100%;
    margin: auto;
    max-width: 320px;
    position: relative;
    text-align: center;
    .home-bottom-right-header {
        h2 {
            color: $dim-gray;
            font-size: 0.7rem;
            font-weight: 400;
            padding: .5em 0 0 0;
            margin: 0;
            @include respond(large-up) {
                font-size: 2.3rem;
                font-weight: 200;
            }
        }
        h3 {
            color: $rain-forest;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: 200;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            @include respond(large-up) {
                font-size: 2.3rem;
            }
        }
        img {
            display: block;
        }
    }
    .product-tile {
        border: 0 none;
        margin: 1em auto 0;
        min-height: 255px;
        width: 90%;
        img {
            box-shadow: 0px 0px 5px 3px $very-light-gray;
            max-width: 90%;
        }
        .product-name {
            height: auto;
        }
        .product-sales-price {
            color: $black;
        }
    }
}

.category-slot, .product-slot {
    h1 {
        color: $white;

        font-size: 3rem;
        font-weight: 300;
        position: absolute;
        text-align: right;
        bottom: 2%;
        left: 2%;
        @include respond(large-up) {
            font-weight: 100;
        }
    }
    h2 {
        color: $teal;

        font-size: 1.5rem;
        font-style: italic;
        margin: 0;
        padding: .1rem .5rem;
    }
    h3 {
        background-color: $white;
        bottom: 0;
        color: $black;

        font-size: 2rem;
        font-weight: 300;
        margin: 0;
        @include opacity(0.6);
        padding: 2rem;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
    }
    .catlanding-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
            height: auto;
            width: 100%;
        }
    }
    .category-tile {
        float: left;
        overflow: hidden;
        padding: 0 .15%;
        position: relative;
        width: 100%;
        @include respond(large-up) {
            width: 33%;
        }

        img {
            height: auto;
            width: 100%;
        }
    }
    .cat-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
            height: auto;
            width: 100%;
        }
        h1 {
            color: $night-rider;
            right: 2%;
        }
    }
    .search-result-items {
        .grid-tile {
            box-shadow: none;
            margin: 0;
            padding: 0 .15%;
            width: 100%;
            @include respond(large-up) {
                width: 33%;
            }

            .product-tile {
                margin: 0 .15%;
                padding: 1rem 0;
                width: 100%;
                .product-name {

                    font-size: 1.2rem;
                    font-weight: 100;
                    height: auto;
                    margin-top: .5em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    a {
                        color: $cerulean;
                    }
                }
                .product-pricing {

                    font-size: 1rem;
                }
            }
        }
    }
}

@include respond(large) {
    .html-slot-container img {
        max-width: 100%;
    }
}

.customer-care-content {
	@include respond(medium-down) {
	   padding: 0 15px;
	   margin-bottom: 50px;
	}
    h1 {
        &.page-title {
            @include respond(large-up) {
                margin-top: 0;
            }
        }
	}
	h3 {
	   @include font-size(16px);
	}
	.caption {
        @include font-bold(14px);
        color:  $secondary-color;
	}
    a {
        color: $secondary-color;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
    ol {
        li {
            margin-bottom: calculateEm(25px);
        }
    }
	ul {
	   li {
	       ul {
	           margin: 0;
	           li {
	               margin: 0;
	           }
	       }
	   }
	}
	p {
	   &.note {
	       color: $red;
	   }
	}
	.single-accordion {
        &.first {
            @include respond(medium-down) {
                margin-top: 20px;
            }
        }
        .single-accordion-title {
		    text-align: center;
		    margin-top: -1px;
            border-top: 1px solid $primary-border;
	        border-bottom: 1px solid $primary-border;
	        cursor: pointer;
	        padding: 18px 15px;
	        color: $secondary-color;
	        position: relative;
	        @include font-size(24px);

	        @include respond(medium-down) {
	            @include font-size(21px);
	            padding: 18px 35px 18px 10px;
	        }
            &:after {
	            display: block;
	            content: '';
	            float: right;
	            position: absolute;
	            width:20px;
	            height:22px;
	            @include transition(all 0.5s ease-out);
	            background-size: 20px;
	            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
	            top: 21px;
	            right: 10px;
                @include respond(large-up) {
                    top: 23px;
                    right: 15px;
                }
            }
            &.open {
                &:after {
                    transform: rotate(180deg)
                }
            }
	   }
	   .single-accordion-content {
	       display: none;
	   }
	}
	table {
        @include respond(small-down) {
            table-layout: fixed;
        }
        th {
			background-color: $white-smoke;
			@include font-bold(14px);
			@include line-height(15px);
			padding: 10px;
			color: $secondary-color;
			font-weight: normal;
			text-transform: uppercase;
			&.center {
			 text-align: center;
			}
			@include respond(small-down) {
                @include font-bold(10px);
                padding: 10px 5px;
			}
        }
        td {
            @include font-size(14px);
            padding: 10px;
            @include respond(small-down) {
                @include font-size(12px);
                padding: 10px 5px;
                .caption {
                    @include font-bold(12px);
                }
            }
        }
    }
}

#join-rewards img {
	width: 100%;
    height: auto;
}
.inactive-rewards #join-rewards img {
	@include respond(medium-up) {
		width: 335px;
    		margin: 0 auto;
    		display: block;
    	}
} 

// GRID SLOT STYLING
.grid-tile.grid-slot {
    display: none;
    &.active {
        display: flex;
        @media screen and (max-width: 479px) {
            flex-basis: 100%;
            height: auto !important;
        }
    }
    .grid-slot-content {
        @media screen and (max-width: 479px) {
            margin: 0 auto;
        }
    }
}