/* ---------------------------------------------------------
   Include Fonts
---------------------------------------------------------

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=2382b4b4-3c26-4aa5-bd54-e09c76e28ba3");
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=324c1b96-2960-4111-ac5a-e46bbcb8d333");
*/

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,800');

// ----------------------------------------
// UPDATED WEB FONTS
// ----------------------------------------

$local-font-path: unquote("../fonts/");

@mixin font-smoothing () {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin font-normal {
	font-style: normal;
	font-weight: normal;
}

// tracking to letter-spacing
@mixin tracking($tracking){
	letter-spacing: ($tracking/1000) * 1em;
}

//Source Sans

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-BlackItalic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-ExtraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-ExtraLightItalic.ttf") format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}


@font-face {
	font-family: "SourceSansPro";
	src: url("#{$local-font-path}sourceSans/SourceSansPro-SemiBoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

//
// Font Stack
//
$sans-stack-core: Arial, sans-serif !default;
$source-sans-pro: "SourceSansPro", $sans-stack-core;

//
// Standard Font Families
//

// usage: @include font-regular();
// ex: @include font-regular(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-regular(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs
@mixin font-regular($size:"", $breakpoint:"") {
	font-family: $source-sans-pro;
    font-weight: normal;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}

@mixin font-regular-extend($size:"", $breakpoint:"") {
	font-family: $source-sans-pro;
    font-weight: normal;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}

// usage: @include font-bold();
// ex: @include font-bold(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-bold(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs
@mixin font-bold($size:"", $breakpoint:"") {
    font-family: $source-sans-pro;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}