.ds-link {
    @include ds-ui-bold(m);
    color: $text-primary;
    text-decoration-line: underline;

    &:hover,
    &:active {
        text-decoration: none;
    }

    &:focus,
    &:active {
        color: $text-secondary;
    }
}
