h1 {
    @include h1;
    font-weight: normal;
}

h2 {
    @include h2;
    font-weight: normal;
}

h3 {
    @include h3();
    font-weight: normal;
}
h4{
    @include h4();
    font-weight: normal;
}
ul, ol{
    padding: 0;
    margin: 0;
    li{
        @include font-regular(14px);
        @include line-height(24px);
        color: $primary-color;
    }
}

b, strong {
    font-weight: normal;
}

#secondary h1 {

}

h1.primary-head{
    border-bottom: 1px solid $secondary-border;
    padding-bottom: calculateEm(10px);
    margin-bottom: calculateEm(25px);
    .dialog-required{
        float: right;
        position: relative;
        top: 40px;
    }
}
.primary-content {
    dl {
        clear: both;
        overflow: hidden;
        dt {
            clear: both;
            float: left;
            margin: 2% 0 0;
            min-width: 15%;
        }
        dd {
            float: left;
            margin: 2% 0 0;
            padding: 0 0 0 5%;
        }
    }
    h1{ 
        border-bottom: 1px solid $secondary-border;
        padding-bottom: calculateEm(10px);
        margin-bottom: calculateEm(25px);
        .dialog-required{
            float: right;
            position: relative;
            top: 40px;
        }
    }
    h2{
        .dialog-required{
            float: right;
        }
    }
}

.col-1 {
    float: left;
    margin: 4px 0;
    width: 48%;
    @include respond(medium-down) {
        float: none;
        width: 100%;
    }
}

.col-2 {
    float: right;
    margin: 4px 0;
    width: 48%;
    @include respond(medium-down) {
        float: none;
        width: 100%;
    }
}

.content-header {
    @include h1();
    span {
        font-size: 0.75em;
    }
}

.section-header {
    @include h1();
    margin: 0;
    padding: 1em;
    position: relative;
    text-transform: uppercase;
    .dialog-required {
        text-transform: none;
    }
}

.section-header-note {
    font-size: 0.9em;
    font-weight: 400;
    text-transform: none;
}

table .section-header {
    @include h3();
}



.back {
    display: block;
    margin: 0 0 10px;
}
.login-box{
   .form-row-button{
       text-align: left;
   }
}