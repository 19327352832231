#mini-cart {
    float: right;
    margin: 0;

    @include respond(large-up) {
        position: relative;
        top: -39px;
        margin: 0 27px 0 13px;
    }

    @include respond(standart) {
        top: -52px;
        margin: 0 41px 0 16px;
    }

    @include respond(desktop) {
        margin: 0 17px;
    }

    .mini-cart-name {
        a {
            @include ds-ui-semi(m);
            color: $text-primary;
            text-transform: capitalize;
        }
    }

    .remove-icon {
        height: 17px;
        width: 15px;

        &:hover {
            path {
                fill: $dim-gray;
            }
        }

        &:active {
            path {
                fill: $click;
            }
        }
    }
}

.mini-cart-total {
    line-height: 0;
    position: relative;
    z-index: 3;

    &.opened {
        @include respond(medium-down) {
            .cart-icon {
                opacity: 1 !important;
                z-index: 1000;
            }

            .mobile-label {
                display: none !important;
            }
        }

        &+ .mini-cart-content {
            max-height: calc(100vh - 200px);
        }
    }

    &:hover {
        .mini-cart-link {
            @include transition(opacity 0.5s ease-out);
            @include opacity(1);
        }
    }

    .mini-cart-link {
        color: $cobalt;
        text-transform: uppercase;
        display: inline-block;
        line-height: 0;

        @include respond(medium-down) {
            height: 36px;
            width: 36px;
        }

        .cart-icon {
            width: 36px;
            height: 36px;
            background: url("../images/svg-icons/UI-AddToBag-v2.svg") no-repeat scroll center;
            background-size: 25px 24px;

            @include respond(large-up) {
                background: url("../images/svg-icons/UI-AddToBag.svg") no-repeat scroll center;
                width: 32px;
                height: 36px;
                background-size: 32px 36px;
            }
        }

        .minicart-quantity {
            background-color: $trans;
            color: $cobalt;
            @include font-bold(14px);
            @include line-height(18px);
            height: 18px;
            position: absolute;
            right: 2px;
            text-align: center;
            top: 14px;
            width: 28px;

            @include respond(medium-down) {
                top: 13px;
                @include font-bold(10px);
                @include line-height(18px);
                right: 0;
                width: 100%;
            }
        }

        .mobile-label {
            display: block;
            @extend %mobile-label;
            /*margin: 13px 0px 10px 8px;*/
            position: absolute;
            bottom: -11px;
            left: 7px;
            @include respond(large-up) {
                display: none;
            }
        }
    }

    .mini-cart-empty {
        color: $cobalt;
        transition: color 500ms ease;

        &:hover {
            color: $denim;
            text-decoration: none;
        }

        .minicart-quantity {
            background-color: $trans;
            color: $cobalt;
        }
    }
}

.android {
    .mini-cart-total {
        .mini-cart-link {
            .minicart-quantity {
                top: 16px;

                @include respond(medium-down) {
                    top: 12px;
                }
            }
        }
    }
}

.mini-cart-content {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    transition: max-height 600ms;
    z-index: 150;

    @include respond(medium-down) {
        z-index: 100;
        width: 279px;
        background: $white;
        top: 37px;
        right: 0px;
        position: absolute;
        visibility: hidden;

        .cart-wrap {
            border: 1px solid $dark-gray;

            .mini-cart-totals {
                display: none;
            }

            .mini-cart-header {
                padding: 20px 0px 5px;
                margin: 0 20px;
            }

            .mini-cart-products {
                max-height: 350px;
                //overflow: scroll;
                padding: 0 20px 20px 20px;
            }
        }
    }

    @include respond(large-up) {
        right: 0;
        width: 290px;
        top: calculateRem(30px);
        background-color: $trans;

        .cart-wrap {
            padding: calculateRem(10px);
            margin-top: calculateRem(14px);
            border: 1px solid $dark-gray;
            background-color: $white;
        }
    }
}

.mini-cart-header {
    @include ds-heading(h4);
    color: $text-primary;
    padding: 9px 0 15px;
    text-transform: capitalize;
    text-align: center;
    border-bottom: 3px solid $bg-color;
    z-index: 100;
    position: relative;
}

.mini-cart-products {
    max-height: calc(100vh - 450px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -10px;
    padding-right: 10px;

    /*style scrollbar*/
    ::-webkit-scrollbar {
        width: 12px; /* for vertical scrollbars */
        height: 12px; /* for horizontal scrollbars */
    }

    ::-webkit-scrollbar-track {
        background: $click;
    }

    ::-webkit-scrollbar-thumb {
        background: $click;
    }

    .scroll-block {
        width: 100%;
        overflow: visible;
        box-sizing: border-box;
    }

    @include respond(medium-down) {
        .mini-cart-product {
            display: none;

            &:first-of-type {
                display: block;

                .mini-cart-price,
                .promotion,
                .attribute,
                .remove-item {
                    display: none;
                }
            }
        }
    }
}

.mini-cart-product {
    overflow: hidden;
    padding: 15px 5px;
    position: relative;
    line-height: normal;
    list-style: none;

    @include respond(medium-down) {
        padding-bottom: 0;
    }

    .social-proofing {
        @include ds-ui-bold(s);
        background-color: $brand-primary;
        border-radius: 2px;
        color: $text-inverse;
        display: inline-block;
        padding: 4px 8px;
        margin-bottom: 6px;

        @include respond(large-up) {
            margin-left: 10px;
        }
    }
}

.checkout-totals-wrap .mini-cart-product .social-proofing {
    display: none;
}

.mini-cart-image {
    float: left;
    width: 26%;

    img {
        max-width: 100%;
        width: 60px;
    }

    @include respond(medium-down) {
        width: 32%;
        position: relative;
        margin-top: -15px;
        left: -15px;
    }
}

.mini-cart-name,
.mini-cart-attributes,
.mini-cart-product .product-availability-list,
.mini-cart-pricing {
    float: right;
    width: 70%;

    @include respond(medium-down) {
        width: 68%;
        position: relative;
        padding-top: 2px;
    }
}

.mini-cart-name {
    @include ds-ui-semi(m);
    color: $text-primary;

    a {
        float: left;
        width: 88%;

        @include respond(medium-down) {
            width: 100%;
            padding-top: 2px;
        }
    }
}

.mini-cart-attributes,
.mini-cart-product .product-availability-list {
    font-size: 0.9em;
    margin: 0;

    .small {
        @include ds-ui-regular(s);
        color: $text-secondary;
        margin-bottom: 3px;

        @include respond(medium-down) {
            @include font-size(12px);
        }
    }
}

.remove-item {
    float: right;

    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }

        .icon {
            width: 17px !important;
            height: 15px !important;
        }
    }
}

.mini-cart-pricing {
    @include ds-ui-regular(s);
    color: $text-secondary;
    clear: right;

    @include respond(medium-down) {
        font-size: 1em;
    }

    span.mini-cart-price {
        @include ds-ui-semi(l);
        color: $text-secondary;
        display: block;
        text-align: right;
        clear: right;
    }

    .promotion {
        @include ds-ui-semi(s);
        margin: calculateEm(15px) 0 2px;
        width: 88%;
        color: $text-feedback-danger;

        p {
            margin: 0;

            > a {
                display: none;
            }
        }

        .callout-message p {
            color: $red;
            @include font-regular(11px);
            @include line-height(16px);
        }
    }
}

.mini-cart-totals {
    text-align: center;
    margin-bottom: 10px;
}

.mini-cart-subtotals {
    @include ds-ui-semi(l);
    color: $text-primary;
    border-top: 3px solid $bg-color;
    padding: 15px 5px 5px;
    @include clearfix;
    z-index: 100;
    position: relative;

    .label {
        float: left;
        text-transform: capitalize;
    }

    .value {
        float: right;
    }
}

.mini-cart-slot {
    width: 215px;
    margin: 17px auto 13px;

    .howtoredeem {
        display: none;
    }
}

.mini-cart-link-checkout {
    clear: both;
    display: block;
    margin: 1em 0;
    text-align: center;
}

.mini-cart-link-cart {
    @include ds-utilities-button;
    margin: 10px 0;
}

.checkout-mini-cart {
    background-color: $white-smoke;
    max-height: 330px;
    overflow: auto;
    padding-bottom: 20px;
}

.dialog-content {
    padding-bottom: 11px !important;

    .mini-cart-name {
        /*margin-top: 35px;*/
    }

    .mini-cart-image {
        width: 36%;
        text-align: center;

        a {
            display: block;
        }

        img {
            width: 177px;
        }

        @include respond(medium-down) {
            width: 36%;
            position: relative;
            top: 0;
            padding-bottom: 10px;

            img {
                width: auto;
            }
        }
    }

    .product-availability-list {
        float: right;
    }

    .mini-cart-attributes,
    .mini-cart-product .product-availability-list,
    .mini-cart-pricing,
    .mini-cart-name,
    .mini-cart-price,
    .product-availability-list {
        width: 60%;
        @include font-size(14px);
        padding-left: 28px;
        @include borderbox();
        @include line-height(25px);

        @include respond(medium-down) {
            padding-left: 0;
            @include font-size(12px);
            width: 62%;
        }
    }

    .mini-cart-name {
        a {
            @include ds-ui-semi(m);
            color: $text-primary;
            text-transform: capitalize;

            @include respond(medium-down) {
                padding-top: 0;
            }
        }
    }

    .mini-cart-attributes {
        margin: 0;

        .small {
            @include ds-ui-regular(s);
            color: $text-secondary;
            margin: 0;
            @include respond(medium-down) {
                padding-top: 7px;
            }
        }

        .attribute {
            float: left;

            @include respond(medium-down) {
                display: none;
            }

            &.size {
                color: $text-primary;

                @include respond(large-up) {
                    margin-right: calculateRem(30px);
                }
            }

            &:after {
                /*content: "/";*/
                padding: 0 5px 0 2px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            .label {
                display: none;
            }

            .value {
                @include ds-ui-regular(s);
                color: $text-secondary;
            }
        }

        .qty-block {
            @include ds-ui-regular(s);
            color: $text-secondary;
            /*margin-left: calculateRem(30px);*/
            @include respond(medium-down) {
                margin-left: 0;
                line-height: 1;
            }

            .label {
                &:after {
                    content: "";
                }
            }
        }
    }

    .remove-item {
        display: none;
    }

    .minicartslot {
        clear: right;
        margin: 20px 0 10px 225px;
        width: 225px;

        @include respond(medium-down) {
            display: none;
        }

        &.howtoredeem {
            margin-top: 10px;
            margin-bottom: 10px;

            a {
                @include font-size(14px);
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .mini-cart-pricing {
        @include respond(medium-down) {
            display: none;
        }

        .promotion {
            margin: calculateRem(20px) 0 calculateRem(20px) calculateRem(2px);

            p {
                margin: 0;

                > a {
                    display: block;
                    margin-top: 2px;
                    color: $primary-color;
                }
            }

            .callout-message p {
                @include font-regular(14px);
                line-height: 1;
            }
        }
    }

    .mini-cart-price {
        text-align: left !important;
        padding: 12px 0 0 0;
        margin-bottom: 20px;

        @include respond(medium-down) {
            width: auto;
        }
    }

    .mini-cart-subtotals {
        border: none;
        float: right;

        &.top {
            float: left;
        }
    }

    .not-added {
        @include ds-body(m);
        color: $text-secondary;
        letter-spacing: 0.01em;
        padding-left: 28px;
        margin: 0;
        @include borderbox();

        @include respond(medium-down) {
            padding-left: 0;
            width: 62%;
        }

        &.error {
            @include ds-ui-semi(m);
            color: $text-feedback-danger;
        }
    }
}

.ui-dialog {
    @include respond(medium-down) {
        max-width: none !important;
        width: 81% !important;

        .dialog-content {
            padding: 0 20px !important;
        }
    }

    &.rewardspopup {
        z-index: 9920002 !important; //overwrite bizrate popup
    }

    .ui-dialog-buttonpane {
        background: $bg-color;
        padding: 0;
        margin: 0;

        .mini-cart-link-cart,
        .mini-cart-slot {
            display: none;
        }

        .oversubtotal {
            margin: 0px 35px;
            overflow: hidden;

            @include respond(medium-down) {
                display: none;
            }

            .mini-cart-subtotals {
                float: right;
                width: 48%;
                text-align: right;
                padding: 10px 0 3px;

                &.top {
                    float: left;
                    text-align: left;
                }

                span {
                    display: inline;
                    float: none;
                    @include ds-ui-semi(m);
                }
            }
        }

        .ui-dialog-buttonset {
            margin: 7px 35px 30px 35px;
            border-top: 3px solid $white;
            float: none;
            padding-top: 5px;
            @include clearfix;

            @include respond(medium-down) {
                border: none;
                padding-top: 0;
                margin: 10px 20px 15px 20px;
            }

            button {
                &.ui-button.ui-widget {
                    margin-right: 0;
                    float: right;
                    min-height: 48px;

                    &:hover {
                        background: $white;
                        color: $secondary-color;
                        border-color: $primary-border;
                    }

                    &:active {
                        background: $bg-color;
                        color: $secondary-color;
                        border-color: $white;
                    }

                    @include respond(medium-down) {
                        float: none;
                        width: 100%;

                        &.cancelButton {
                            margin: 11px 0;
                        }
                    }
                }
            }

            .cancelButton {
                &.ui-button.ui-widget {
                    float: left;
                    background: $white;
                    color: $text-primary;
                    border-color: $primary-border;

                    &:hover {
                        background: $night-rider;
                        color: $white;
                        border-color: $night-rider;
                    }

                    &:active {
                        background: $bg-color;
                        color: $night-rider;
                        border-color: $white;
                    }
                }
            }

            .ui-button-text {
                @include ds-utilities-button-labels;
                padding: 0 13px;
                white-space: nowrap;
            }
        }

        .changedeliveryoption {
            padding: 2px 10px 1px;
            text-align: center;

            @include respond(large-up) {
                padding: 9px 10px 14px;
            }

            .added-for-pick-up {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 15px;
                margin: 0 0 6px 0;

                @include respond(large-up) {
                    margin: 0 0 2px 0;
                }

                .pick-up-icon {
                    padding: 0 12px 0 0;
                    &::before {
                        content: "";
                        background: url(../images/svg-icons/bopis-icon-small.svg) no-repeat;
                        background-size: 18px 23px;
                        width: 18px;
                        height: 23px;
                        display: flex;
                    }
                }

                .pick-up-store-msg {
                    @include ds-heading(h5);
                    color: $text-brand-primary;
                    margin: 0;
                    text-align: left;
                    text-transform: none;

                    .store-name {
                        color: $text-primary;
                        text-transform: capitalize;
                    }
                }
            }

            .change-option-info {
                @include ds-ui-regular(s);
                color: $text-primary;
                margin: 0;
                padding: 0;
            }

            .added-for-shipping {
                @include ds-heading(h5);
                color: $text-brand-primary;
                margin: 0 0 6px 0;
                text-transform: none;
                display: flex;
                justify-content: center;
                align-items: center;

                &::before {
                    content: "";
                    background: url(../images/svg-icons/bopis-shipping.svg) no-repeat;
                    background-size: 30px 15px;
                    width: 30px;
                    height: 15px;
                    padding: 0 9px 0 0;
                    display: flex;
                }
            }
        }
    }
}

// JATC RECCOMMENDATIONS
// -------------------------------------

$ui-light-gray1: #eaeaea;
$ui-light-gray2: #e5e5e5;
$ui-gray: #666;
$jatc-green: #1d6b49;
$jatc-red: #cc0000;

.prevent-scroll {
    height: 100%;
    overflow: hidden;
}
// hide JATC elements that persist into other dialog - TODO: remove persistent items

.ui-dialog:not(.jatc-recommendations) {
    .recommendations-container {
        display: none;
    }
}

.ui-dialog.jatc-recommendations {
    height: auto;

    @include respond(medium-up) {
        min-height: 557px;
    }

    @include respond(medium-down) {
        height: auto !important;
        max-width: none !important;
        width: 290px !important;
    }

    @media only screen and (max-width: 380px) {
        max-height: 90%;
        overflow: auto;
    }

    .ui-dialog-titlebar {
        background-color: $white;
        height: 35px;
        position: sticky; // sticky positioning not supported in IE
        top: 0;
        z-index: 10;
    }

    .ui-dialog-title {
        @include visually-hidden;
    }

    .dialog-content {
        border: $ui-light-gray1 2px solid;
        margin: 11px 36px 0;
        padding: 15px 170px 18px 21px !important;

        @include respond(medium-down) {
            margin: 11px 10px 0;
            padding: 25px 15px 20px !important;
        }

        .jatc-success {
            @include font-size(14px);
            color: $jatc-green;
            position: absolute;
            right: 23px;
            text-transform: capitalize;
            top: 18px;
            line-height: 17px;

            @include respond(medium-down) {
                position: relative;
                right: auto;
                top: auto;
                margin-bottom: 5px;
            }

            span {
                padding-left: 20px;
                position: relative;

                &::before {
                    background-image: url("../images/svg-icons/checkmark-jatc.svg");
                    content: "";
                    height: 11px;
                    left: 0;
                    position: absolute;
                    top: calc(50% + -5.5px);
                    width: 15px;
                    background-repeat: no-repeat;
                }
            }
        }

        .mini-cart-image,
        .mini-cart-name,
        .mini-cart-attributes {
            float: left;

            @include respond(medium-down) {
                left: auto;
            }
        }

        .mini-cart-image {
            height: 68px;
            vertical-align: bottom;
            width: 50.57px;

            @include respond(medium-down) {
                height: 73px;
                margin-top: 0;
                padding-bottom: 0;
                width: 51px;
            }

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-height: 68px;
                max-width: 100%;
                object-fit: contain;
                object-position: 50% 100%;

                @include respond(medium-down) {
                    max-height: 73px;
                }
            }
        }

        .mini-cart-name,
        .mini-cart-attributes {
            padding-left: 16.93px;
            width: 70%;

            @include respond(medium-down) {
                margin-left: 5%;
                padding: 0;
                width: 69%;
            }
            @include respond(small-down) {
                width: 67%;
            }
        }

        .mini-cart-name {
            @include line-height(19px);
        }

        .mini-cart-attributes {
            line-height: 16px;
            margin-top: 3px;
            .small {
                @include respond(medium-down) {
                    padding-top: 2px;
                }
            }

            .attribute {
                &.size {
                    display: none;
                }
            }
        }

        .mini-cart-pricing {
            display: none;
        }

        .not-added {
            padding-left: 0;

            @include respond(medium-down) {
                text-align: center;
                width: 100%;
            }

            &.title {
                @include font-size(14px);
                @include line-height(17px);
                color: $jatc-red;

                span {
                    padding-left: 20px;
                    position: relative;

                    &::before {
                        background-image: url("../images/svg-icons/xmark-jatc.svg");
                        content: "";
                        height: 11.5px;
                        left: 0;
                        position: absolute;
                        top: calc(50% + -5.75px);
                        width: 11.5px;
                    }
                }
            }

            &.error {
                @include font-size(14px);
                @include line-height(19px);
                color: $ui-gray;
                margin-top: 10px;

                @include respond(medium-down) {
                    margin-top: 8px;
                }
            }
        }

        .mini-cart-name {
            a {
                @include respond(medium-down) {
                    margin-bottom: 2px;
                }
            }
        }
    }

    .ui-dialog-buttonpane {
        background-color: $ui-light-gray1;
        margin-left: 36px;
        margin-right: 36px;
        margin-bottom: 20px;

        @include respond(medium-down) {
            margin-left: 10px;
            margin-right: 10px;
        }

        .oversubtotal {
            display: none;
        }

        .ui-dialog-buttonset {
            border: none;
            margin: 20px 40px;
            padding-top: 0;

            @include respond(medium-down) {
                margin: 10px 15px 20px 15px;
            }

            button {
                &.ui-button.ui-widget {
                    float: right;
                    @include font-size(11px);

                    margin: 0;
                    width: 44.75%;

                    @include respond(medium-down) {
                        @include font-size(14px);
                        width: 100%;
                    }

                    &.cancelButton {
                        &.ui-button.ui-widget {
                            float: left;
                        }

                        @include respond(medium-down) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            .ui-button-text {
                @include line-height(35px);
            }
        }

        .changedeliveryoption {
            @include respond(medium-down) {
                padding-left: 5px;
                padding-right: 5px;
                padding-bottom: 12px;
            }

            @include respond(large-up) {
                padding-top: 1px;
            }
        }
    }

    .recommendations-container {
        background-image: url("../images/spinner-rewards.gif");
        background-position: center 46%;
        background-repeat: no-repeat;
        background-size: 32px;
        height: auto;
        margin-left: 36px;
        margin-right: 36px;
        min-height: 300px;
        padding-bottom: 26px;

        @include respond(medium-down) {
            @include font-size(13px);
            margin-left: 10px;
            margin-right: 10px;
            // min-height: 320px;
            background-position: center 43%;
        }

        &.loaded {
            background: none;
        }

        h4 {
            @include font-size(12px);
            @include line-height(18px);
            letter-spacing: 0.9px;
            margin: 15px 0;
            padding: 0 20px;
            position: relative;
            text-align: center;
            z-index: 1;

            @include respond(medium-down) {
                @include font-size(12px);
                @include line-height(18px);
                letter-spacing: 0;
                margin: 14px 0 8px;
            }

            &:after {
                border-top: 1px solid $ui-light-gray2;
                bottom: 0;
                content: "";
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                top: 50%;
                width: 100%;
                z-index: -1;
            }

            span {
                background: $white;
                padding: 0 15px;
            }
        }

        .horizontal-carousel {
            opacity: 0;
            transition: opacity 500ms cubic-bezier(0, 0, 0.58, 1);

            @include respond(medium-down) {
                margin-bottom: 0px;
            }

            &:nth-child(n + 3) {
                display: none;
            }
        }

        &.loaded {
            .horizontal-carousel {
                opacity: 1;
            }
        }

        .recommendation_image {
            vertical-align: bottom;

            & > a {
                height: 127px;
                margin-left: auto;
                margin-right: auto;
                width: 106px;

                @include respond(medium-down) {
                    height: 144px;
                    width: 120px;
                }
            }

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-height: 127px;
                max-width: 100%;
                object-fit: contain;
                object-position: 50% 100%;

                @include respond(medium-down) {
                    max-height: 144px;
                }
            }
        }

        .sub-attribute {
            @include font-size(11px);
        }

        .product-price {
            @include font-size(14px);
            margin: 7.5px 0 14px;
            // margin: 10px 0 14px;
        }

        .promotion {
            // margin-top: -10px;
            .promotion-callout {
                .callout-message {
                    @include ds-ui-semi(s);
                    color: $text-feedback-danger;

                    &:nth-child(n + 2) {
                        display: none;
                    }

                    @include respond(medium-down) {
                        max-width: 200px;
                        margin: 0 auto;
                    }
                }
            }
        }

        .owl-item {
            min-height: 266px;
        }

        .owl-nav button {
            span {
                background-color: transparent !important;
                background-size: auto;
                height: 28px;
                width: 16px;
            }

            &.owl-prev {
                left: -29px;

                @include respond(medium-down) {
                    left: -9px;
                }

                span {
                    background-image: url("../images/svg-icons/arrow-jatc_l.svg");

                    &:hover {
                        background-image: url("../images/svg-icons/arrow-jatc_l-hover.svg");
                    }

                    &:active {
                        background-image: url("../images/svg-icons/arrow-jatc_l-active.svg");
                    }
                }
            }

            &.owl-next {
                right: -29px;

                @include respond(medium-down) {
                    right: -9px;
                }

                span {
                    background-image: url("../images/svg-icons/arrow-jatc_r.svg");

                    &:hover {
                        background-image: url("../images/svg-icons/arrow-jatc_r-hover.svg");
                    }

                    &:active {
                        background-image: url("../images/svg-icons/arrow-jatc_r-active.svg");
                    }
                }
            }
        }

        // Adding CSS to support JS forcing all JATC carousel items to have the same height - related changes in jatc-recommendations.js
        .recommendation-item {
            height: 100%;

            .product-tile {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: stretch;

                .product-cont,
                .product-price,
                .promotion {
                    flex: 0 0 auto;
                    padding-bottom: 4px;

                    @include respond(medium-down) {
                        padding-bottom: 4px;
                    }
                }

                .product-cont {
                    margin: 0;
                }

                .product-price {
                    // height: 100%;
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: flex-end;
                    .price-standard {
                        text-decoration: line-through;
                        ~ .price-sales {
                            color: #c00;
                        }
                    }
                }

                .promotion {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                form {
                    min-height: 35px;
                    position: relative;
                    // margin: 10px 0 20px;
                    @include respond(medium-down) {
                        // margin: 10px 0;
                    }

                    .add-to-cart {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                    }
                }
            }
        }
    }

    .ui-dialog-titlebar-close {
        width: 36px;
        height: 36px;
        right: 0;
    }
}

.ui-dialog.jatc-recommendations.maxqty {
    .dialog-content {
        padding: 17px 40px 15px !important;
        text-align: center;

        @include respond(medium-down) {
            padding: 20px 15px !important;
        }
    }
}
.ui-dialog.jatc-recommendations.collapsed {
    .recommendations-container {
        display: none;
    }
}
