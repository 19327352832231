// GRID PAGES
.search-promo {
    border-top: 1px solid #333;
    .html-slot-container {
        max-width: 820px;
    }
    h1,
    h2 {
        font-size: 1.5rem;
        text-transform: none;
    }
    p {
        font-size: 1rem;
        line-height: 1.75;
    }
    a {
        font-size: 1em;
    }
    strong {

        font-weight: normal;
    }
}
// OTHER PAGES
// ingredient pages, SEO placeholder pages
#main.full-width .content-asset .search-promo {
    border-top: 0;
    @media screen and (max-width: 768px) {
        margin-left: -20px;
        margin-right: -20px;
        padding: 0;
    }
    picture img {
        width: 100%;
    }
    .html-slot-container {
        border-top: 0;
        padding-left: 50px;
        padding-right: 60px;
        @media screen and (max-width: 768px) {
            padding: 20px;
        }
    }
    h1,
    h2 {
        @include font-size(20px);

        color: $brand-black;
        line-height: 1;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
        text-transform: none;
    }
    h1 {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
        &.seohead {
            border: none;

            margin-bottom: 1.25rem;
            margin-top: 0;
            padding-bottom: 0;
            @media screen and (max-width: 768px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
    h1:first-child,
    h2:first-child {
        @media screen and (max-width: 768px) {
            margin-top: 0;
        }
    }
    p {
        @include font-size(16px);
        line-height: 1.5;
    }
}
// ----------------------
.html-slot-container {
    .search-promo {
        border: none;
    }
}
.landing-top-banner {
    .search-promo {
        border: none;
    }
}
// SEO copy container on HP and LPs (not Top Offers, White Barn)
.intro-copy-container {
    border-top: 1px solid #333;
    max-width: 1440px;
    margin: auto;
    .intro-copy {
        max-width: 830px;
        text-align: center;
        margin: 0 auto 2rem;
        overflow: visible;
        h1 {
            margin: 1.75rem 0 0.5rem;
            font-size: 1.75rem;

            border: none;
            padding: 0;
        }
        h2 {
            font-size: 1.1rem;

        }
        p {
            font-size: 1rem;
            line-height: 1.4;
            a {
                font-size: 1rem;
                line-height: 1.4;
            }
        }
    }
}
.landing-top-banner {
    .intro-copy-container {
        border: none;
        .intro-copy {
            @media screen and (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .html-slot-container {
        h1 {
            border: none;
            margin: 0;
            @media screen and (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
h1.seo-header {
    border: none;
    padding: 0;
    margin: 9px 0 -21px;
}
