.ds-video-container {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;

    .ds-video-desktop,
    .ds-video-mobile {
        > div {
            margin: 0 auto;
        }
    }

    video-js:not(#vjs_video_3) {
        position: relative;
    }

    .ds-video-desktop {
        display: none;
    }

    @media screen and (min-width: $mMin) {
        &.has-desktop-override {
            .ds-video-mobile {
                display: none;
            }

            .ds-video-desktop {
                display: block;
            }
        }
    }
}
