.ds-button-group {
    list-style-type: none;
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 32px;
    row-gap: 24px;

    > li:last-child:nth-child(2n - 1) {
        grid-column: span 2;

        .ds-button {
            display: inline-flex;
        }
    }

    @media screen and (min-width: $xlMin) {
        display: inline-flex;
        flex-wrap: wrap;
        column-gap: 32px;
        justify-content: center;
    }
}