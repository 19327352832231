.ds-smooth-scroll-grab {
    cursor: grab;

    &:active,
    .ds-top-offers-tile-content:active {
        cursor: grabbing;
    }
}

.ds-top-offers-tile {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 136px;
    line-height: 0;
    text-align: center;
    flex: 1;
    width: 64vw;
    @include respond(small) {
        width: 85vw;
    }

    .ds-link-image {
        border: $white-smoke 1px solid;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;

        & + .ds-top-offers-tile-content {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
        }
    }

    .ds-link-button {
        margin-top: 0;
        padding: 0;   
        display: flex;
        align-items: flex-end;
        width: 100%;
        grid-column: 1/3;
        padding: 0 32px 32px 32px;
        box-sizing: border-box;
        .ds-false-link-button {
            width: 100%;
        }
    }
}

.ds-top-offers-tile-content {
    @include ds-ui-regular(m);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    background: $bg-primary;
    border-radius: 4px;
    border: $border-primary;
    width: 100%;

    &:active {
        cursor: grabbing;
    }
}

.ds-top-offers-urgency,
.ds-top-offers-price {
    @include ds-ui-bold(l);
}

.ds-top-offers-urgency {
    color: #005699;
}

.ds-top-offers-grid {
    .ds-top-offers-tile {
        width: auto;
    }
}

@media screen and (min-width: $mMin) {
    .ds-top-offers-tile {
        width: 38vw;
        .ds-link-button {
            margin-top: 16px;
            padding: 0 32px;
            display: inline-block;
            align-items: unset;
            width: auto;
            box-sizing: border-box;
            .ds-false-link-button {
                width: auto;
            }
        }
    }
    .ds-top-offers-tile-content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 32px;
        gap: 0;
    }
}

@media screen and (min-width: $lMin) {
    .ds-top-offers-tile {
        width: 28vw;
    }
}

@media screen and (min-width: $xlMin) {
    .ds-top-offers-tile {
        width: 300px;
    }
}
