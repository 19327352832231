@import "owl/core";
@import "owl/animate";
@import "owl/autoheight";
@import "owl/lazyload";
@import "owl/video";

/*OVERRIDES*/
.owl-nav {
    &.disabled {
        display: none;
    }
    
    button {
        width: auto;
        height: auto;
        position: absolute;
        top: calc(50% - 20px);
        background: none;
        z-index: 2;
        border: none;
        
        span {
            display: block;
            width: 40px;
            height: 40px;
            text-indent: -99999px;
            background-size: 35px;
            overflow: hidden;
            @include borderRadius(50%);
        }
        
        &:hover {
            border: none !important;
            background: none !important;
        }
        
        &.owl-prev {
            left: 0;
            
            span {
                background: url("../images/svg-icons/Caret-left.svg") no-repeat center;
            }
        }
        &.owl-next {
            right: 0;
            
            span {
                background: url("../images/svg-icons/Caret-right.svg") no-repeat center;
            }
        }
        
        &.disabled {
            display: none;
        }
    }
}

.owl-dots {
     text-align: center;
     margin: 10px 5px;
     display: none;
     &.disabled {
         display: none!important;
     }
     .owl-dot {
         width: 30px;
         height: 30px;
         border-radius: 10px;
         display: inline-block;
         color: $white;
         background: $secondary-color;
         margin: 0 5px;
         cursor: pointer;
         @include line-height(30px);
         :hover{
             background: $primary-color;
         }
         &.disabled {
             display: none!important;
         }
         &.active {
             background: $primary-color;
         }
     }
}
