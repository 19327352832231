.ds-container.ds-user-info-collection {
    margin-top: 16px;
    color: $night-rider;
    max-width: 568px;

    .ds-user-info-heading {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
        text-align: left;
    }

    .ds-info-heading-text {
        @include ds-ui-regular(m);;
        flex: 1;
    }
}

@media screen and (min-width: $mMax) {
    .ds-container.ds-user-info-form {
        max-width: 568px;
    }
}