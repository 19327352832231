#wrapper {
    &.white_burn {
        .breadcrumb{
            margin-left: auto;
        }
        .breadcrumb-element{
            &:last-child{
                @include respond(large-up) {
                    color: $white;
                }
            }
        }
        footer{
            margin-top: 0;
        }
        .main-wrap {
            background: url(../images/parchment-bg.gif) repeat;
            @include respond(large-up) {
                background: $black url(../images/wood-bg-vertical.jpg) repeat center top;
            }
        }
        .full-width{
            @include respond(medium-down) {
                display: none;
            }
            .left{
                float: left;
                position: relative;
                left: -20px;
                margin-bottom: 37px;
                width: 30%;
                .wb-logo{
                    max-width: 315px !important;
                }
            }
            .right{
                float: right;
                color: $white;

                text-transform: uppercase;
                letter-spacing: 2.7px;
                padding: 25px 39px 0 0
            }
        }
        .nav{
            .toggle{
                @include respond(large-up) {
                    color: $white;

                    letter-spacing: 2px;
                }
            }
        }
        #secondary {
            @include respond(large-up) {
                a {
                    color: $white;

                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
        }
        #main{
            #secondary {
                &.refinements {
                    min-width: 150px;
                }
            }
            .primary-content{
                @include respond(large-up){
                    width: 79%;
                    padding-bottom: 100px;
                }
                @include respond(landing-max){
                    width: calc(100% - 285px);
                }
                margin-right: 0;
                .section-2{
                    .oneelem{
                        @include respond(medium-down) {
                            display: none;
                        }
                    }
                }
                section{
                    background: url(../images/parchment-bg.gif) repeat;
                    .wrapper-block{
                        max-width: 940px;
                        margin: auto;
                        padding: 0 10px;
                        @include borderbox;
                        @include respond(desktop){
                            padding: 0;
                        }
                        @include respond(medium-down){
                            padding: 0;
                        }
                    }
                    h2{
                        margin: 4px 0;
                    }
                    p{
                        @include respond(medium-down) {

                            text-align: center;
                            line-height: 1.8;
                            color: #2c2a2a;
                        }
                    }
                }
                .landing-top-banner{
                    .top-text-container{
                        text-align: center;
                        padding: 5px 0;
                        overflow: hidden;
                        p{

                            color: $wb-text;
                            margin: 7px 0;
                            padding: 0 10px;
                            @include respond(medium-down) {

                                text-align: center;
                                margin: 0 10%;
                                line-height: 1.8;
                                padding-top: 11px;
                                a.blue-button{
                                    display: block;
                                    margin: 21px auto 80px;
                                    width: 50px;
                                }
                            }
                        }
                    }
                }
                .bottom-content{
                    @include respond(medium-down){
                        padding: 0;
                    }
                }
            }
        }
        .twoelem .pict {
            float:left;
            width: 47%;
            padding-left: 2.2%;
            @include respond(medium-down) {
                padding-left: 0;
            }


        }
        .twoelem .right {
            float: right;
            width: 50%;
            background-size: contain !important;
            @include respond(medium-down) {
                float: none;
                width: 100%;
                max-width: 430px;
                margin: auto;
            }

        }
        .twoelem .right .content{
            padding: 115px 15% 0;
            min-height: 410px;
            box-sizing: border-box;
            background-size: contain !important;
            @include respond(medium-down) {
                padding: 122px 0 155px;
                min-height: 0;
                margin-top: 50px;
            }
        }
        .twoelem .right .content p{
            line-height: 1.5;
            margin-bottom: 20px;
            @include respond(medium-down) {
                margin-bottom: 40px;
                padding: 0 5px;
            }
        }
        a.blue-button{
            background: $wb-btn-blue;

            text-transform: uppercase;
            color: $wb-dark-color;
            padding: 8px 20px;
            margin: 0 20px;
            text-decoration: none;
            letter-spacing: 2px;
            @include respond(medium-down){

                padding: 13px 40px;
            }
        }
        .oneelem{
            padding: 32px 0;
        }
        .new{
            padding-top: 65px;
        }
        .new .pict{
            float: right;
            width: 36%;
            position: relative;
            top: -111px;
            @include respond(medium-down) {
                top:0;
                width: 100%;
                text-align: center;
                float: none;
            }
        }
        .new .pazzle {
            width: 57%;
        }
        .pazzle{
            float: left;
            width: 940px;
            position: relative;
            @include respond(medium-down) {
                float: none;
                width: 100%;
                text-align: center;
            }
            img{
                padding-bottom: 10px;
                @include respond(medium-down) {
                    padding-bottom: 19px;
                }
            }
            .left{
                width: 66%;
                float: left;
            }
            .right{
                width: 32.8%;
            }

        }
        .pazzle .hover-block {
            position: absolute;
            width: 30%;
            left: 51%;
            top: 43%;
            text-align: center;
            @include respond(medium-down){
                width: 100%;
                left: 0;
                top: 39%;
            }
        }
        .pazzle .hover-block .active{
            display: none;
        }
        .pazzle .hover-block:hover .active{
            display: block;
        }
        .pazzle .hover-block:hover .regular{
            display: none;
        }
        #main .primary-content section.section-3 .wrapper-block,
        #main .primary-content section.section-4 .wrapper-block{
            padding: 40px 10px 0;
            max-width: 1005px;
            @include borderbox;
            @include respond(medium-down){
                padding: 40px 0 0;
            }
        }
        .section-3 .oneelem.center{
            @include respond(medium-down){
                background-size: contain !important;
                margin-top: 72px;
            }
        }
        .section-3 .content{
            padding-top: 70px;
        }
        .section-3 .content p{
            margin: 10px 0;
            padding-bottom: 20px;
            @include respond(medium-down){
                padding:0 40px 20px 40px;
            }
        }
        .section-3 .pict{
            width: 25.2%;
            padding-left: 0;
        }
        .section-3 .pazzle{
            width: 30%;
            float: left;
        }
        .section-3 .pazzle-2{
            width: 74.8%;
            float: right;
            @include respond(medium-down){
                width: 100%;
                float: none;
            }
        }
        .section-3 .pazzle-2 .title{
            border-left: 4px solid #008057;
            margin: 15px 22px 34px;
            padding: 10px 20px;
            p{
                margin: 0;
                color: #262626;
                font-size: 17px;
            }
            img{
                width: 34%;
            }
            @include respond(medium-down){
                border: 0;
                text-align: center;
                padding: 10px 20px 28px;
                img {
                    width: auto;
                }
            }
        }
        .section-3 .pazzle-2 .middle{
            float: left;
            width: 66%;
            text-align: center;
            padding: 0 10px;
            @include borderbox;
            @include respond(medium-down){
                float: none;
                width: 100%;
            }
        }
        .section-3 .pazzle-2 .pazzle{
            width: 34%;
            float: right;
            position: relative;
            top: -32px;
            @include respond(medium-down){
                float: none;
                width: 100%;
                top: 0;
                text-align: right;
                padding: 35px 24px 0 24px;
                box-sizing: border-box;
            }
        }
        .section-3 .pazzle-2 .pazzle .hover-block{
            width: 55%;
            left: 61%;
            top: auto;
            bottom: -50px;
            @include respond(medium-down){
                left: 5%;
                width: auto;
                bottom: 20px;
            }
        }
        .fullpict{
            padding: 48px 0 15px;
            @include respond(medium-down){
                padding: 0 0 15px;
                margin-top: 30px !important;
            }
        }
        .section-4{
            padding-top: 31px;
            @include respond(medium-down){
                padding-top: 9px;
            }
        }
        .section-4 .twoelem{
            @include respond(large-up){
                background: none !important;
            }
        }
        .section-4 .twoelem .pict{
            float: right;
            text-align: left;
            width: 40.7%;
            padding-left: 0;
        }
        .section-4 .twoelem .right{
            float: left;
            width: 55%;
            background-size: initial !important;
            min-height: 470px;
            margin-top: 29px;
            @include respond(medium-down){
                width: 100%;
                float: none;
                background: none !important;
                margin: 0;
                text-align: center;
                max-width: 100%;
            }
        }
        .section-4 .twoelem .right .content{
            padding: 166px 20% 0;
            @include respond(medium-down){
                padding: 175px 3% 210px;
            }
        }
        .section-4 .twoelem.new{
            padding-top: 83px;
            @include respond(medium-down){
                padding-top: 0;
            }
        }
        .section-4 .twoelem.new .left{
            float: left;
            width: 35.5%;
        }
        .section-4 .twoelem.new .middle{
            float: left;
            width: 30%;
            padding: 0 10px;
            @include borderbox;
        }
        .section-4 .twoelem.new .right{
            float: right;
            width: 32%;
            margin-top: 15px;
            min-height: 0;
            @include respond(medium-down){
                width: 100%;
                float: none;
                margin-top: 3px;
            }
        }
        .section-4 .pict.mobile-only.center{
            margin-top: 50px;
        }
        .section-4 .twoelem.new .right .hover-block{
            width: 57%;
            left: -29%;
            top: 5%;
            @include respond(medium-down){
                width: auto;
                left: 53%;
                top: 39%;
            }
        }
        .section-5{
            padding-top: 104px;
            @include respond(medium-down){
                padding-top: 126px;
            }
        }
        .section-5 .pict-text{
            float: left;
            width: calc(50% - 30px);
            padding: 0 15px;
            @include respond(medium-down){
                float: none;
                width: 100%;
                box-sizing: border-box;
            }
        }
        .section-5 .pict-text .pict{
            width: auto;
            float: none;
            @include respond(medium-down) {
                text-align: left;
            }
        }
        .section-5 .pict-text .pict .hover-block{
            width: 26%;
            left: 12%;
            top: 27%;
            @include respond(medium-down){
                width: auto;
                left: 64%;
                top: 31%;
            }
        }
        .section-5 .pict-text .content{
            padding: 60px 0 82px;
            width: 59%;
            @include respond(medium-down){
                width: 100%;
                float: none;
            }
            p{
                padding: 0 6px;
                line-height: 1.3;
                margin: 12px 0 25px;
                @include respond(medium-down){

                    line-height: 1.5 !important;
                    text-align: right !important;
                    margin: 19px 0 38px;
                }
            }
            a.blue-button{
                margin: 0;
            }
        }
        .section-5 .pict-text.left{
            text-align: right;
            @include respond(medium-down){
                padding: 0 25px;
            }
        }
        .section-5 .pict-text.left .right{
            overflow: hidden;
            @include respond(medium-down){
                background: none !important;
            }
        }
        .section-5 .pict-text.left .content{
            float: right;
            width: 70%;
            padding: 64px 0 125px 0;
            @include respond(medium-down){
                float: none;
                text-align: left;
                padding: 20px 0 33px;
                width: 100%;
                p{
                    text-align: left !important;
                }
            }
        }
        .section-5 .pict-text.right{
            text-align: left;
            margin-top: -35px;
            @include respond(medium-down){
                text-align: right;
                margin-top: 35px;
                padding: 0 25px;
            }
        }
        .section-5 .pict-text.right .right{
            @include respond(medium-down){
                background: none !important;
            }
        }
        .section-5 .pict-text.right .pict{
            @include respond(medium-down) {
                text-align: right;
            }
        }
        .section-6 .wrapper-block {
            overflow: hidden;
            max-width: 1040px !important;
            padding: 0 10px 100px !important;
            box-sizing: border-box;
            @include respond(medium-down){
                padding: 0 20px 100px !important;
            }
        }
        .section-6{
            @include respond(medium-down) {
                margin-top: 44px;
            }
        }
        .section-6 .pict {
            float: left;
            width: 54%;
            text-align: center;
            @include respond(medium-down){
                float: none;
                width: 100%;
            }
        }
        .section-6 .right-block {
            float: right;
            padding-top: 10px;
            width: 43%;
        }
        .section-6 .titles{
            width: 100%;
            text-align: center;
            height: 2px;
            margin: 27px 0 80px;
            background: url(../images/wood-bg-vertical.jpg) repeat center top;
        }
        .section-6 .titles span{
            position: relative;
            padding: 0 20px;
            background: url(../images/parchment-bg.gif) repeat;

            color: #090605;
            text-transform: uppercase;
            top: -10px;
            letter-spacing: 1.5px;
        }
        .section-6 .email{
            width: 140px;
            float: left;
        }
        .section-6 .email + .form-field{
            float: left;
        }
        .section-6 .content{
            padding-top: 33px;
        }
        .section-6 .mob-titles{

        }
        .section-6 .content p{
            font-size: 12px;
        }
        .section-6 .pict .blue-button.mobile-only{
            top: 50px;
            position: relative;
        }
    }
    &.wb-gift-spotlights{
        .breadcrumb-element{
            &:last-child{
                color: $white;
            }
        }
        .main-wrap {
            background: $black url(../images/wood-bg-vertical.jpg) repeat center top;
        }
        #main .primary-content section .wrapper-block{
            max-width: 1040px !important;
            padding: 50px 0 0 0 !important;
            @include respond(medium-down){
                padding: 50px 20px 0 !important;
            }
            p{

            }
        }
        #main .primary-content .section-7 .wrapper-block{
            padding: 50px 20px 160px 20px !important;
        }
        #main .primary-content .section-2 .wrapper-block{
            @include respond(medium-down){
                padding: 50px 0 0 0 !important;
            }
        }
        .row{
            display: table-row;
        }
        .row.second .cell{
            padding: 15px 7.5px 0;
        }
        .row.second .cell.couple{
            width: 50%;
            @include respond(medium-down){
                width: 100%;
            }
        }
        .row.second .cell.couple:first-child{padding-left: 0}
        .row.second .cell.couple:last-child{padding-right: 0}
        .cell{
            display: inline-block;
            width: 33.33%;
            vertical-align: top;
            padding: 0 7.5px;
            box-sizing: border-box;
            @include respond(medium-down){
                width: 100%;
                padding: 0;
                text-align: center;
            }
        }
        .cell.two{
            width: 66.66%;
            @include respond(medium-down){
                width: 100%;
            }
        }

        .va-top{vertical-align: top}
        .va-middle{vertical-align: middle}
        .va-bottom{vertical-align: bottom}

         #main .cell img{
            vertical-align: bottom;
        }
        .landing-top-banner{
            section{
                padding: 130px 0 0px;
                margin-bottom: -35px;
                @include respond(medium-down){
                    margin: 80px 0 0;

                }
            }
            img{
               position: absolute;
                top: -60px;
                @include respond(medium-down){
                    top: -90px;
                }
                @include respond(desktop) {
                    top: -140px;
                }
            }
            .top-text-container{
                @include respond(medium-down) {
                    p{
                        margin: 0 !important;
                    }
                }
            }
        }
        .cell .content{
            padding: 0 0 40px 20px;
            @include respond(medium-down){
                padding: 40px 20px 20px;
            }
            .blue-button{
                margin: 20px 0;
                @include respond(medium-down){
                    display: inline-block;
                }
            }
        }
        .cell.va-middle .content{
            padding: 70px 0px 0;
            @include respond(medium-down){
                padding: 40px 0px 30px;
            }
        }
        .cell.va-middle .content p{padding: 0 30px 0;}
        .section-6 .cell.va-middle .content{
            @include respond(desktop){
                padding: 130px 30px 0;
            }
        }
        .right{text-align: right}
        .left{text-align: left}
        .section-4, .section-5{
            padding-top: 0;
        }
        .section-6{
            margin-top: 0;
        }
        .section-6 .content p{
            font-size: initial;
        }
        .section-6 .pict {
            padding: 0 10px;
            box-sizing: border-box;
        }
        .section-6 .cell .cell {
            @include respond(medium-down){
                width: 33.33%;
                padding: 0 7.5px;
            }
        }
    }
    &.wb-our-story{
        #main .primary-content section .wrapper-block{
            max-width: 1040px !important;
            padding: 20px 10px 0 10px !important;
            @include respond(medium-down){
                padding: 20px 0px 0 !important;
                max-width: none !important;
            }
        }
        .section-5 {
            padding-top: 0;
        }
        .section-6 .titles{
            margin: 21px 0 37px;
        }
        .row{
            display: table-row;
            @include respond(medium-down){
                display: block;
            }
        }
        .cell{
            display: inline-block;
            width: 100%;
            vertical-align: top;
            padding: 0;
            box-sizing: border-box;
            @include respond(medium-down){
                padding: 0;
                text-align: center;
                &.titles-mob {
                    img{
                        width: 65%;
                    }
                }
                &.wide-padd {
                    padding: 0 20px;
                }
            }


        }
        .cell.two{
            width: 50%;
            @include respond(medium-down){
                float: left;
                padding-top: 15px;
                &:first-child{
                    padding-right: 7.5px;
                }
                &:last-child{
                    padding-left: 7.5px;
                }
            }
        }
        .cell.picture{
            width: 57.3%;
            text-align: right;
            @include respond(medium-down){
                width: 100%;
                text-align: center;
            }
        }
        .cell.picture.right{
            position: relative;
            text-align: left;
            @include respond(desktop){
                top: -25px;
            }
        }
        .cell.picture.center{
            @include respond(large-up){
                width: 48.3%;
            }
        }
        .cell.picture.bear{
            @include respond(desktop){
                margin-top: -77px;
            }
            @include respond(medium-down){
                text-align: center;
            }
        }
        .cell.picture.right > .row .cell.two{
            @include respond(desktop) {
                margin-top: -80px;
                img {
                    height: 400px;
                }
            }
        }
        .cell.text{
            @include respond(large-up) {
                width: 42%;
                .content{
                    padding: 17px 15%;
                }
                &.wide{
                    width: 100%;
                    .content{
                        padding: 0 30% 10px;
                        & > p{
                            padding-bottom: 30px;
                        }
                    }
                }
            }
            @include respond(medium-down) {
                .content{
                    padding: 20px 30px;
                }
                h2{
                    text-align: center;
                    img{
                        max-width: none;
                    }
                }
            }
        }
        .padds{
            padding: 46px 0 35px;
        }
        .padds-mob{
            @include respond(medium-down) {
                padding: 45px 0 35px;
            }
        }
        .pads{
            padding: 20px 0 50px;
        }
        .padds2{
            padding: 73px 0 15px;
        }
        .cell .content{
            @include respond(large-up) {
                text-align: center;
                padding: 0 30% 30px;
                p {
                    @include font-regular(13px);
                }
            }
        }
        .va-bottom{vertical-align: bottom}
        a.blue-button{
            @include respond(large-up){
                white-space: nowrap;
            }
            @include respond(small-down){
                display: block;
            }
        }
    }
    .wb-ticker{
        li{

            list-style: none;
            text-align: right;
            position: absolute;
            top: 27px;
            right: 15px;
            opacity: 0;
            margin-right: 5px;
            color: #fff;
            &.active{
                opacity: 1;
            }
        }
    }
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}
.flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
}