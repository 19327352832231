.ds-visual-navigation {
    .ds-marketing-copy-heading {
        margin-bottom: 9px;
    }
    .ds-visual-navigation-slider {
        margin-bottom: 8px;
    }
}

.ds-visual-navigation-slider {
    -ms-overflow-style: none;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin: 0 -15px 27px;
    overflow: auto hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &::after {
        content: "";
        border: 1px solid transparent;
    }
}

.ds-visual-navigation-tile {
    @include ds-ui-semi(m);
    display: block;
    text-align: center;
    border: 1px solid $white-smoke;
    border-radius: 4px;
    margin: 7px 0 8px 16px;
    min-width: 36vw;
    max-width: 200px;

    &:last-child {
        margin-right: 14px;
    }

    &:hover {
        border-color: $night-rider;
    }

    img {
        margin: 0 auto;
        width: 100%;
        border-radius: 4px 4px 0 0;
    }
}

.ds-visual-navigation-active,
.ds-visual-navigation-tile:active {
    border-color: $night-rider;
    background: $night-rider;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.15);

    .ds-visual-navigation-label {
        color: $text-inverse;
    }
}

.ds-visual-navigation-label {
    color: $text-primary;
    padding: 8px 8px 8px;
}

@media screen and (min-width: $mMin) {
    .ds-visual-navigation-tile {
        min-width: 26%;
    }
}

@media screen and (min-width: $lMin) {
    .ds-visual-navigation-tile {
        min-width: 20%;
    }
}
@media screen and (min-width: $xlMin) {
    .ds-visual-navigation-tile {
        min-width: 16.5%;
    }
}

.ds-loading-skeleton {
    .ds-visual-navigation-tile,
    .ds-visual-navigation-tile:active {
        height: 172px;
        background: $white-smoke;
        box-shadow: none;
        border-color: $white-smoke;

        @media screen and (min-width: $xlMin) {
            height: 236px;
        }

        .ds-visual-navigation-label {
            display: none;
        }

        img {
            display: none;
        }
    }
}
