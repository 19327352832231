.banner-module {
    .announcement-banner-module.ds-margin-bottom-exempt {
        padding: 0;
    }
}

.banner-component {
    .ds-link-image {
        display: block;
    }

    .ds-marketing-copy.ds-sub-module {
        display: inline-block;
        + .ds-tooltip-container .ds-simpletooltip_container {
            margin-top: 8px;
        }

        .ds-marketing-copy-cta-link {
            margin-left: 4px;
        }
    }

    .ds-js-simple-tooltip {
        @include ds-ui-regular(l);
        color: $text-secondary;

        &:hover {
            text-decoration: none;
        }

        &:active {
            text-decoration: none;
        }

        &:focus {
            text-decoration: underline;
        }
    }
}
