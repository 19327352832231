nav[role="navigation"] {
	@extend .clearfix;
	left: -86%;
	margin-top: 0;
	padding-top: 60px;
	position: fixed;
	background: $bg-color;
	width: 86%;
	max-width: 350px;
	height: 100%;
	top: 0;
	z-index: 101;
	@include transition(left 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94));

	@include respond(large-up) {
		padding-top: 0;
		border-bottom: 3px solid $bg-color;
		background-color: $white;
		color: $night-rider;
		left: auto;
		position: static;
		width: 100%;
		max-width: 100%;
		height: auto;
	}
}
.menu-active{
	nav[role="navigation"] {
		left: 0;
		height: 100%;
		z-index: 113;
		overflow-y: scroll;
	}
	.temp-add.stickyOn{
		z-index: 1;
	}
	.outer-close-filters{
		display: none;
	}
	.menu-overlay{
		visibility: visible;
		@include opacity(0.6);
		transition: opacity 0.5s linear;

		@include respond(large-up){
			visibility: hidden;
		}
	}
}
.menu-close{
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
	padding: 20px 19px 20px 15px;
	color: $cobalt;
	background-color: $white;
	@include font-regular(16px);
	text-transform: uppercase;
	@include clearfix;
	span{
		float: left;
	}
	.icon{
		float: right;
		width: 20px !important;
		height: 20px !important;
		&:active {
			fill: #c2c2c2;
		}
	}
	@include respond(large-up){
		display: none;
	}
}
.menu-heading {
	@include ds-heading(h4);
	display: block;
	float: none;
	left: 12px;
	overflow: visible;
	position: absolute;
	top: 19px;
	z-index: 1;
    color: $cobalt;
    text-transform: capitalize;

	@include respond(large-up) {
		display: none;
	}
}
.menu-toggle {
	background-color: transparent;
	border: none;
	display: block;
	float: left;
	width: 36px;
	padding: 0;
	margin: 0;
	height: 36px;
	@include borderbox();
	&:hover{
		background: none !important;
	}
	.icon{
		&.menu-mobile {
			height: 18px;
			padding: 9px 9.5px;
			width: 17px;
		}
	}
	span{
		@extend %mobile-label;
		position: relative;
		top: -19px;
		line-height: normal;
	}
	@include respond(large-up) {
		display: none;
	}
}
.safari{
	.menu-toggle{
		span{
			top: -18px;
		}
	}
}
.menu-overlay{
	visibility: hidden;
	position: fixed;
	height: 100%;
	width: 100%;
	background: $secondary-color;
	z-index: 112;
	top: 0;
	left: 0;
	@include opacity(0);
	transition: visibility 0s 0.5s, opacity 0.5s linear;

}
.menu-category {
	@extend %clearfix;
	list-style: none;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
	background: $white;
	@include respond(desktop) {
		/*max-width: $menu-max-with;*/
		max-width: 1160px;
	}
	@include respond(large-up) {
		margin: 0 auto;
		width: 100%;
		text-align: center;
		display: table;
		clear: both;
	}
	@include respond(medium-down) {
		border-bottom: 1px solid $primary-border;
	}
	li {
		list-style: none;
		border-top: 1px solid $primary-border;
		@include respond(large-up) {
			border: none;
			display: inline-block;
			line-height: 1;
			padding: 0 15px;
		}
		@include respond(large) {
			padding: 0;
		}
	}
	a {
		color: $secondary-color;
		position: relative;
		@include font-regular-extend(13px);
		@include respond(large-up) {
			@include line-height(20px);
			padding: 13px 28px 8px;
			display: inline-block;
			letter-spacing: 0.25px;
			vertical-align: bottom;
		}
		@include respond(desktop) {
			@include font-regular(14px);
		}
		@include respond(large) {
			padding: 13px 10px 8px;
		}
	}
}

.android{
	.menu-category{
		a{
			top: 1px;

		}
	}
}

.level-1 > li {
	/*&:nth-child(2){
		.level-2{
			display: block !important;
		}
	}*/
	@extend %clearfix;
	background-color: transparent;
	display: block;
	line-height: 1;
	@include respond(large-up){
		display: table-cell;
		white-space: nowrap;
		&.custom{
			vertical-align: middle;
			> a{
				width: 187px;
				padding: 0;
				left: 7%;
				top: 0px;
				position: relative;
				> span{
					display: none;
				}
			}
			.custom-menu{
				width: 130px;
				height: 36px;
				max-width: none;
			}
		}
		&:hover{
			> a{
				border-bottom: 3px solid $cobalt;
			}
		}
	}
	@include respond(medium-down) {
		&.custom {
			.icon{
				display: none;
			}
		}
	}
	@include respond(standart){
		&.custom{
			> a{
				position: relative;
				left: 1%;
			}
		}
		&.hand-soaps {
			padding: 0 calculateRem(16px);
		}
		&.gifts {
			padding: 0 calculateRem(15px) 0 calculateRem(17px);
		}
	}
	@include respond(large){
		&.custom{
			> a{
				width: 130px;
			}
			.custom-menu{
				width: 120px;
				left: 18%;
			}
		}
	}
	@include respond(medium-down){
		&.custom{
			.custom-menu{
				display: none;
			}
		}
	}

	.menu-item-toggle {
		cursor: pointer;
		float: right;
		width: 50px;
		height: 50px;
		margin: 4px 4px 0 0;
		background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
		background-size: 15px;
		@include transition(all 0.5s ease-out);

		&.active{
			transform: rotate(180deg);
		}
		@include respond(large-up) {
			display: none;
		}
	}
	a {
		transition: border 0.3s;
		&:hover {

		}
		@include respond(large-up) {
			&:hover {

			}
		}
	}
	> a {
		&.has-sub-menu {

		}
		@include respond(medium-down){
			display: block;
			margin: 20px 15px;
			@include font-regular(16px);
		}
		@include respond(large-up) {
			border-bottom: 3px solid $trans;
			+ .level-2{
				border-bottom: 3px solid $bg-color;
			}
			&:hover {
				border-bottom: 3px solid $cobalt;
			}
			&.open{
				border-bottom: 3px solid $cobalt;

			}
		}

		@include respond(desktop) {
		}
	}
	/*&.active{
		@include respond(medium-down) {
			// background: $bg-color;
			// border-color: $white;

			&::before {
				content: '';
				width: 4px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: #005699;
				z-index: 1;
			}
		}
	}*/
	&.body-care{
		.level-2 .inside {
			max-width: 100%;
			float: none;
			.menu-groupings .sub-column:nth-child(3){
				@include respond(large-up) {
					position: relative;
					left: 10px;
				}
			}
		}
	}
}

.level-2 {
	clear: left;
	display: none;
	background: $white;
	
	li{
		border: none;
	}
	.actions{
		.category-link {
			text-transform: capitalize;
			@include respond(large-up) {                
				@include font-regular(14px);
				color: $primary-color;
				padding-top: 5px;
				padding-bottom: 6px;
				&:hover {
					color: $cobalt;
				}
			}
			@include respond(medium-down) {
				padding: 10px 15px;
			}
			@include respond(large-down) {
				&:active{
					color: $cobalt;
				}
			}
		}
		.close-button{
			display: none;
		}
		margin-bottom: 20px;
		@include respond(medium-down) {
			margin-bottom: 30px;
		}
	}
	.sub-column{
		@include respond(medium-down){
			width: inherit !important;
		}
		.heading{
			@include font-bold(13px);
			@include respond(medium-down){
				color: $secondary-color;
				margin: 16px 0 13px;
			}
		}
		li{
			padding: 10px;
			@include respond(medium-down){
				padding: 8px 10px;
			}
			a{
				text-transform: none;
			}
			&.link{
				@include respond(medium-down) {
					> a {
						padding-left: 5px;
					}
				}
			}
		}
		&.feature{
			display: none;
		}
	}
	@include respond(large-up) {
		width: 100%;
		top: auto;
		left: 0;
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 75%,rgba(255,255,255,0.99) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.99) 75%, rgba(255,255,255,0.99) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 75%,rgba(255,255,255,0.99) 100%); /* Chrome10-25,Safari5.1-6 */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fcffffff',GradientType=0 ); /* IE6-9 */
		position: absolute;
		padding: 0;
		z-index: 112;
		text-align: center;
		border-top: 3px solid $bg-color;
		.columns{
			margin: 0 auto;
			padding: 10px 27px 20px 27px;
			text-align: center;
			overflow: hidden;
			position: relative;
			display: inline-block;
			@include borderbox();
			.inside{
				float: left;
				display: inline-block;
				max-width: calc(100% - 265px);
			}
		}
		.actions{
			width: calc(100% - 30px);
			@include clearfix;
			margin-bottom: 1px;
			border-bottom: 3px solid $bg-color;
			text-align: left;
			a{
				padding: 15px 0 11px;
			}
			.close-button{
				float: right;
				text-indent: -999em;
				background: url("../images/svg-icons/UI-Close-dark.svg") no-repeat center;
				background-size: 15px;
				width: 15px;
				height: auto;
				padding: 10px 0 0 0;
				display: none;
			}
		}
		.inside {
			&.wide{
				.menu-groupings {
					.sub-column {
						padding-right: 94px;
					}
				}
			}
			&.cuted {
				/*max-width: calc(100% - 230px);*/
				.menu-groupings {
					.sub-column {
						/*padding-right: 75px;*/
						@include respond(large-up){
							min-width: 176px;
						}
						@include respond(large){
							min-width: 0;
							padding-right: 10px;
						}
					}

				}
				& + .feature.sub-column{
					@include respond(large) {
						width: 230px;
						margin-left: 0;
						overflow: hidden;
					}
				}
			}
		}
		.sub-column{
			float: left;
			display: inline-block;
			text-align: left;
			.heading{
				@include font-bold(12px);
				text-transform: uppercase;
				margin: 16px 0 2px;
				color: $secondary-color;
			}
			li{
				display: block;
				padding: 0;
				a{
					padding: 13px 0 2px;
					color: $menu;
					text-transform: none;
					@include font-size(14px);
					white-space: nowrap;
					&:hover{
						color: $cobalt;
					}
					&:active{
						color: $click;
					}
				}
			}
			&.feature{
				display: inline-block;
				margin: 31px 0 0 15px;
				width: 250px;
				padding-right: 0;
				float: left;
				position: relative;
				text-align: left;
				img{
					max-width: 100%;
				}
				.contentasset{
					a{
						padding: 0;
						text-transform: none;
						&:hover{
							text-decoration: none;
						}
					}
					span{
						display: block;
						padding: 3px 0;
						&.cta{
							@include font-bold(12px);
						}
						&.heading{
							margin: 0;
							padding: 5px 0 2px;
						}
						&.subheading{
							@include font-regular(14px);
							color: $menu;
						}
					}

				}
			}
		}
		a {
			&:hover {
				color: $cobalt;
			}
		}
	}
	@include respond(large) {
		.columns{
			left: 0;
			padding: 10px 20px 20px;
			.inside {
				max-width: calc(100% - 230px);
				&.wide {
					.menu-groupings {
						.sub-column {
							padding-right: 94px;
						}
					}
				}
			}
		}
	}
	@include respond(large-down) {
		.sub-column li a {
			&:active{
				color: $cobalt;
			}
		}
	}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.level-2 .columns .inside{
		max-width: none;
		width: 58%;
	}
	.level-2 .columns .inside.cuted{
		width: 100%;
	}
	.level-2 .inside .menu-groupings .sub-column{
		min-width: 0;
	}
}

%sub-menu-item {
	@include ds-ui-regular(m);
	color: $text-secondary;
	display: block;
	padding: 8px 15px 8px 55px;
	text-transform: none;

	@include respond(large-up) {
		@include ds-ui-regular(s);
		white-space: normal;
		box-sizing: initial;
	}

	&:hover {
		color: $cobalt;
	}

	&:active {
		color: $click;
	}
}

.menu-search {
	position: relative;
	height: 60px;
	box-sizing: border-box;
	padding: 12.5px 0;
	overflow: hidden;
	background-color: $white;

	@include respond(large-up) {
		display: none;
	}

	&::before {
		content: url('../images/svg-icons/UI-Search.svg');
		position: absolute;
		width: 19px;
		height: 19px;
		fill: $cobalt;
		padding: 11px 0 0 12px;
	}

	input[type='search'] {
		@include ds-ui-regular(l);
		border: none;
		border-bottom: 1px solid $night-rider;
		width: 80%;
		height: 25px;
		margin: 9px 0 0 13%;
		padding: 0;
		@include transition(all 0.3s ease-out);
	}

	button {
		position: absolute;
		top: 13px;
		left: 0;
		width: 36px;
		height: 100%;
		overflow: hidden;
		opacity: 0;
	}

	.cancel {
		@include ds-ui-regular(s);
		text-transform: capitalize;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		color: $night-rider;
		position: absolute;
		top: 40%;
		right: -30px;
		opacity: 0;
		@include transition(all 0.3s ease-out);
	}

	&.active {
		&::before {
			content: url('../images/svg-icons/UI-Search-v2.svg');
		}
		.cancel {
			right: 5px;
			opacity: 1;
		}
		input[type='search'] {
			border-bottom-color: $cobalt;
			width: 70%;
		}
	}
}

nav#navigation {
    padding-bottom: 100px;
	z-index: 113;
	@include respond(large-up) {
		height: auto;
		padding-bottom: 0;
	}
	.nav-lvl1 {
		list-style: none;
		margin: 0;
		padding: 0;
		text-transform: capitalize;
		background: #fff;
		box-sizing: border-box;
		border-bottom: 1px solid $gainsboro;

		@include respond(large-up) {
			width: 100%;
			text-align: center;
			display: flex;
			list-style: none;
			border: none;
			justify-content: space-between;
			margin: 0 auto;
		}

		@include respond(1400px) { 
			max-width: 1400px;
		}

		> li {
			background-color: $white;
			display: block;
			line-height: 1;
			list-style: none;
			max-height: 58px;
			border-top: 1px solid $gainsboro;
			position: relative;
			@include transition(max-height 0.5s ease-out);

			@include respond(large-up) {
				background-color: transparent;
				white-space: normal;
				line-height: 1;
				border: none;
				padding: 0;
				list-style: none;
				max-height: auto;
				position: static;
			}

			@include respond(1220px) { 
				white-space: nowrap;
			}

			&::before {
				content: '';
				width: 0;
				height: 56px;
				position: absolute;
				top: 0;
				left: 0;
				background: $cobalt;
				z-index: 30;

				@include respond(large-up) {
					display: none;
				}
			}
			
			&.has-children {
				&::after {
					content: url('../images/svg-icons/megamenu-mobile_primary-carat.svg');
					width: auto;
					height: auto;
					position: absolute;
					top: 24px;
					right: 15px;
					z-index: 30;
					pointer-events: none;
					transform: rotate(180deg);
					@include transition(all 0.3s ease-out);

					@include respond(large-up) {
						content: url('../images/svg-icons/megamenu-carat.svg');
						display: none;
					}
				}

				.html-slot-container {
					@include respond(large-up) {
						// display none for ADA/keyboard navigation
						display: none;
					}
				}

				&.active {
					max-height: 2500px;

					&::before {
						width: 4px;
						@include transition(all 0.3s ease-out);
					}
					&::after {
						transform: rotate(360deg);
						transform-origin: center center;
						top: 18px;
					}

					.nav-lvl2 {
						top: 0;
						opacity: 1;
						pointer-events: auto;
						visibility: visible;

						@include respond(large-up) {
							top: auto;
							z-index: 120;
						}
					}

					a {
						@include respond(large-up) {
							border-color: $cobalt;
						}
					}

					// override pointer-event blocking on desktop when parent menu is active
					.html-slot-container {
						@include respond(large-up) {
							pointer-events: auto !important;
							// override display none for ADA/keyboard navigation
							display: block;
						}
					}
				}
				// removes animation between active sections on desktop
				&.muted {
					transition-property: none !important;
					.nav-lvl2 {
						@include respond(large-up) {
							transition-property: none !important;
						}
					}
				}

			}

			> a {
				@include ds-ui-semi(l);
				background-color: $white;
				color: $text-primary;
				display: block;
				padding: 20px 15px;
				position: relative;
				text-transform: capitalize;


				@include respond(large-up) {
					@include ds-ui-semi(m);
					border-bottom: 3px solid transparent;
					padding: 13px 12px 8px;
					display: inline-block;
					height: 100%;
					vertical-align: bottom;
					@include transition(border 0.3s ease-out);

					&:hover {
						border-color: $cobalt;
					}
				}

				@include respond(1050px) {
					padding: 13px 14px 8px;
				}

				&:active {
					color: $click;
				}
			}

			&.has-icon {
				> a {
					padding-left: 49px;

					@include respond(large-up) {
						padding-left: 28px;
					}

					&::before {
						position: absolute;
						width: 19px;
						height: 19px;
						fill: $cobalt;
						padding: 11px 0 0 15px;
						left: 0;
						top: 5px;

						@include respond(large-up) {
							display: none;
						}
					}
				}

				&.top-offers {
					> a {
						&::before {
							content: url('../images/svg-icons/UI-Tag-v2.svg');
							top: 0;
						}
						&:active {
							&::before {
								content: url('../images/svg-icons/UI-Tag-v2-active.svg');
							}
						}
					}
				}

				&.store-locator {
					> a {
						&::before {
							content: url('../images/svg-icons/UI-StoreLocator-v2.svg');
						}
						&:active {
							&::before {
								content: url('../images/svg-icons/UI-StoreLocator-v2-active.svg');
							}
						}
					}
				}

				&.my-account {
					> a {
						&::before {
							content: url('../images/svg-icons/UI-MyAccount-v2.svg');
						}
						&:active {
							&::before {
								content: url('../images/svg-icons/UI-MyAccount-v2-active.svg');
							}
						}
					}
					.link {
						> a,
						> button {
							@include ds-ui-regular(m);
							cursor: pointer;
							display: block;
							height: 35px;
							padding: 9px 5px 9px 35px;
							position: relative;
							text-transform: none;
							
							&:active {
								color: $click;
							}

							// wish list icon
							svg, img.icon {
								position: absolute;
								width: 11px;
								left: 15px;
								top: 3px;
							}
						}
					}
				}
			}
		}
	}

	.nav-lvl2 {
		position: relative;
		top: -15px;
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
		transition-property: visibility, opacity, top;
		transition-duration: 500ms;
		transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);

		@include respond(large-up) {
			position: absolute;
			z-index: 110;
			width: 100%;
			top: auto;
			left: 0;
			background: white;
			margin-top: 2px;
			box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
		}
		.nav-lvl2-container {

			@include respond(large-up) {
				max-width: 1160px;
				margin: 21px auto;
				text-align: left;
				min-height: 213px;
			}

			> ul {
				list-style: none;

				@include respond(large-up) {
					width: auto;
					display: inline-block;
					left: 0;
					position: relative;
					text-align: left;
					margin-left: 4%;
				}

				@include respond(desktop) {
					margin-left: 54px;
				}

				> li {
					position: relative;
					max-height: 44px;
					overflow: hidden;
					text-transform: capitalize;
					@include transition(max-height 0.3s ease-out);

					@include respond(large-up) {
						height: 36px;
						max-height: none;
						margin-bottom: 22px;
						overflow: visible;
					}

					.heading {
						@include ds-ui-semi(m);
						color: $text-secondary;
						height: 44px;
						padding: 9px 5px 9px 35px;
						cursor: pointer;
						display: block;

						@include respond(large-up) {
							@include ds-ui-semi(s);
							padding: 6px 60px 0 20px;
							height: auto;
						}
						
						a:active {
							color: $click;
						}
						
						&:active {
							color: $click;
						}
					}

					&.has-children {
						&::after {
							content: '';
							background-image: url('../images/svg-icons/megamenu-mobile_secondary-carat.svg');
							background-size: contain;
							background-repeat: no-repeat;
							width: 12px;
							height: 6px;
							position: absolute;
							top: 15px;
							right: 17.5px;
							pointer-events: none;
							@include transition(all 0.3s ease-out);

							@include respond(large-up) {
								background-image: url('../images/svg-icons/megamenu-carat.svg');
								top: 13px;
								right: 30px;
								transform: rotate(0deg);
								height: 10px;
								@include transition(none);
							}
						}
						&::before {
							@include respond(large-up) {
								content: '';
								width: 0;
								height: 36px;
								position: absolute;
								top: 0;
								left: 0;
								background: $cobalt;
								z-index: 30;
							}
						}
					}
					a {
						&:hover {
							@include respond(large-up) {
								color: $cobalt;
							}
						}
						&:active {
							color: $click;
						}
					}
					&.active {
						position: relative;
						max-height: 1000px;

						&::after {
							transform: rotate(180deg);
							transform-origin: center center;
							@include transition(all 0.3s ease-out);
						}

						@include respond(large-up) {

							.heading {
								color: $cobalt;
							}

							&::after {
								transform: rotate(0deg);
								background-image: url('../images/svg-icons/megamenu-carat_active.svg');
								right: 20px;
								@include transition(right 0.3s ease-out);
							}

							&::before {
								width: 4px;
								@include transition(all 0.3s ease-out);
							}

							&.category-top-offers {
								// styles to turn of active visible state on desktop
								.heading {
									color: $night-rider;
								}

								&::before {
									display: none;
								}
							}
						}
						
						.nav-lvl3 {
							top: 0;
							opacity: 1;
							pointer-events: auto;
							visibility: visible;
							@include transition(all 0.5s ease-out);

							@include respond(large-up) {
								left: 100%;
							}
						}
					}

					&.category-top-offers {

						@include respond(large-up) {
							position: absolute;
							left: auto;
							top: 0;
							height: 100%;
							border-left: 1px solid $gainsboro;
							@include transition(all 0.3s ease-out);

							&::after {
								display: none;
							}
						}
						.heading {
							@include respond(large-up) {
								padding: 6px 0 8px 30px;
								pointer-events: none;
							}
						}
						.toggle {
							display: none;
						}
						.html-slot-container {
							position: relative;
							opacity: 0;
							pointer-events: none;
							visibility: hidden;
							@include transition(all 0.5s ease-out);

							&.active {
								top: 0;
								opacity: 1;
								pointer-events: auto;
								visibility: visible;
							}

							@include respond(large-up) {
								opacity: 1;
								visibility: visible;
								@include transition(none);

								&.active {
									top: -8px;
								}
							}
						}
						&.two-column {
							.html-slot-container {
								height: inherit;
								column-count: 2;
							}
						}
						ul {
							> li {
								min-height: 41px;
								height: auto;
								display: block;
								position: relative;
				
								a {
									@extend %sub-menu-item;
									
				
									&:active {
										color: $click;
									}

									@include respond(large-up) {
										padding-left: 30px;
										width: 180px;
									}
								}
							}
						}
					}
					.close-button {
						display: none;
					}
					&.actions {
						.heading {
							a {
								@include respond(large-up) {
									line-height: 1.5;
									display: inline-block;
								}
								@include respond(desktop) {
									line-height: auto;
									display: inline;
								}
							}
						}
					}
				}
			}
		}

	}

	.nav-lvl3 {
		position: relative;
		top: -8px;
		opacity: 0;
		visibility: hidden;
		@include transition(all 0.5s ease-out);

		@include respond(large-up) {
			position: absolute;
			left: 95%;
			column-fill: auto;
			@include transition(none);
		}

		&.two-column {
			@include respond(large-up) {
				column-count: 2;
			}
		}

		&.three-column {
			@include respond(large-up) {
				column-count: 3;
			}
		}

		>li {
			height: 41px;
			display: block;
			position: relative;

			@include respond(large-up) {
				height: auto;
				min-height: 41px;
				display: flex;
			}

			a {
				@extend %sub-menu-item;
				height: 100%;
				@include respond(large-up) {
					padding: 11px 15px 0 0;
					width: 100px;
				}
			
				@include respond(desktop) {
					padding: 11px 30px 0 0;
					width: 180px;
				}
			}
		}

	}
	
	// Utility links from 'mobile-menu-utility' content slot
	.utility-links {
	    padding-top: 12px;
		padding-bottom: 0px;
		background: $white-smoke;
		> li {
			> a {
				@include ds-ui-bold(m);
				padding: 12px 15px;
				display: block;
			}
		}
	}

	.utility-links-container {
		img {
			padding: 1.25rem 0.9375rem 0;
		}
	}

	.mobile-menu-message-container {
		padding: 0 15px;
		background: $white-smoke;
		@include respond(small-down) {
		  padding: 0 5px 0 15px;
		}
		.accountUserName{
		    @include font-size(14px);

			color: $brand-blue;
		}
		.join-loyalty {
		    img {
		        padding-top: rem(20px);
		    }
		}
	}
}

// Generate tier 3 UL offsets
nav#navigation .nav-lvl2 .nav-lvl2-container >ul >li {
	@include respond(large-up) {
		$elements: 15;
		@for $i from 0 to $elements {
			&:nth-child(#{$i + 1}) >ul {
				top: (($i * -57) - 2) * 1px !important;
			}
		}
	}

	button {
		padding-top: 0;
	}
}
