.nav-link-list {
    margin-bottom: 16px;
    max-width: none;
    @media only screen and (min-width: $mMin) {
        max-width: 1168px;
        margin-left: auto;
        margin-right: auto;
    }
    .content {
        line-height: 0;
        margin-bottom: 16px;
        color: $text-secondary; 

        &:last-child {
            margin-bottom: 0;
        }

        @media only screen and (min-width: $mMin) {
            @include ds-ui-regular(m);
            margin-bottom: 8px;
            margin-right: 8px;
            padding-right: 8px;
            border-right: 1px solid $text-tertiary;
            &:last-child {
                border: none;
                padding: 0;
                margin: 0;
            }
        }
    }
    .ds-list-links {
        display: block;
        list-style: none;
        @media only screen and (min-width: $mMin) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .ds-link {
        @include ds-ui-regular(s);
        color: $text-secondary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}