.checkoutlogin {
    margin: 4em auto;
    width: 80%;
    .col-1 {
        @include respond(large-up) {
            border-right: 1px solid $gainsboro;
        }
    }
}
.flex-grid {
    display: flex;
		flex-direction: row;
		@include respond(medium-down) {
		    flex-direction: column;
		}
		padding: 2em 2em 3em 2em;
		@include respond(medium-down){
			padding: 1.5em 2em 3em 2em;
		}
}
.signin, .create-account {
    width: 45%;
    margin: 0 auto;
    @include respond(medium-down) {
        width: 100%;
    }
    @include respond(medium-up) {
	    &.box-border {
	        width: 100%;
	        .login-box-content {
	            border: 1px solid $primary-border;
	            padding: 40px 0;
	        }
	    }
    }
}


.pt_account .spacer {
	background: none;
	position: relative;
	display: inline-block;
	width: 9%;
	@include respond(medium-down) {
    	    width: 100%;
    	    margin-top: 50px;
    	}
	.line {
        border-left: 1px solid #AAAAAA;
        width: 1px;
        height: 100%;
        position: absolute;
        left: 50%;
        @include respond(medium-down) {
            border-left: 0px solid #AAAAAA;
		    border-top: 1px solid #AAAAAA;
            width: 100%;
            height: 1px;
            left: inherit;
            top: 50%;
		}
	}
	.or {
	    color: #666666;
	    width: 37px;
	    height: 37px;
	    border: 1px solid #AAAAAA;
	    border-radius: 20px;
	    background-color: #fff;

	    font-size: 14px;
	    letter-spacing: 0.52px;
	    line-height: 18px;
	    text-transform: uppercase;
	    position: absolute;
	    top: 27px;
	    @include respond(medium-down) {
	    	    top: calc(50% - 19px);
	    	}
	    left: calc(50% - 19px);
	    text-align: center;
	    line-height: 37px;
	}

}

.login-box {
    h3 {
        margin: 0;
    }
    .error {
        margin-left: 0;
    }
}

.login-rememberme {
    display: inline-block;
    margin-left: 6em;

}


#RegistrationForm.contact-us{
    @include respond(large-up){
        padding-top: calculateRem(10px);
        &.form-horizontal {
            .form-row{
                &.required{
                    label{
                        position: relative;
                        left: -10px;
                    }
                }
            }
            .field-wrapper{
                width: 63%;
                padding-left: 0;
                .select-style{
                    width: 168px;
                }
            }
            label{
                padding-left: 0;
                width: 63%;
                position: relative;
                margin: 0 auto 20px;
            }
        }
    }
    @include respond(medium-down){
        padding: 20px 15px;
        .form-row-button{
            button{
                width: 100%;
            }
        }
        .field-wrapper{
            .select-style{
                width: 168px;
            }
        }
    }
    @include respond(small){
        .field-wrapper{
            .select-style{
                max-width: 290px;
            }
        }
    }
    .addition-fields.block{
        display: block;
    }
}
.addition-fields.block{
    display: none;
}

#wrapper.narrow{
    #main.page-content {
        @include respond(medium-down) {
            padding: 0 15px;

            .og-freq-quantity-controls {
                margin-bottom: rem(10px);
            }
        }

        .slot-banner {
	        margin-bottom: 26px;
	        padding: 0;
	        margin-bottom: 45px;
	        margin-top: 0px;
            @include respond(small-down) {
                margin-bottom: 25px;
	            margin-top: 10px;
            }
        }
        .primary-head {
            margin: 0;
            padding-bottom: 15px;
        }
    }
}
.signin, .create-account, .boclogin, .loyaltylogin {
    .login-box{
        text-align: center;
        margin-top: 50px;
        @include respond(medium-up) {
            margin-top: 45px;
        }
        .login-box-content{
            @include respond(medium-up) {
                margin-top: 24px;
            }
            .privacy-cta-block {
                margin: 1.25em 0 1.25em 0;
                @include respond(medium-down){
                    margin: .75em 0 .5em 0;
                }
                &.hide {
                    display:none;
                }
            }
            .form-row-button button[type='submit'] {
                margin: 0 auto;
            }
        }
        .loginform, .loginform-new {
            .checkbox {
	            width: auto;
                height: auto;
            }
            @include respond(medium-up) {
                max-width: 290px;
                margin: auto;
            }
            .required-indicator{
                display: none;
            }
            label{
                left: 0;
                margin-bottom: 5px;
            }
            .form-row-button{
                text-align: center;
                margin: 0;
                #password-reset{
                    display: block;
                    float: left;
                    @include font-bold(12px);
                    color: $primary-color;
                    text-decoration: underline;
                    margin: 0 25px 0 0;

                    font-size: 0.875rem;
                    margin: 0px;
                }
                .login-rememberme{
                    margin: 1px 0 0 0;
                    float: right;
                }
                button{
                    clear: both;
                    margin: 2em auto 0 auto;
                    @include respond(small-down) {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}
.ui-dialog.loyalty-error-msg-flow {
    .form-row.password.required {
        margin: 0.625rem 0 1rem;
    }
    .loyalty-header {
		margin-top: -3px;
	}
    .loyaltylogin{
        .login-box {
            margin-bottom: 10px;
            .login-box-content {
	            .privacy-cta-block {
				    margin: 2em 0 1.25em 0;
				}
			}
        }
        .create-an-account-link {
            margin: 0.5em 0 1.2em;
        }
    }

    @include respond(medium-down) {
	    .loyaltylogin {
	        .login-box {
	            .login-box-content{
	                .privacy-cta-block{
	                    margin: 2.5em 0 .5em 0;
	                }
	            }
	        }
	        .create-an-account-link {
	            margin: 1em 0.5em 2em;
	            button {
	                margin: 0;
	                padding: 0;
	                font-size: 14px;
	            }
	        }
	    }
	}
}
.signin .login-box .loginform .form-row-button {
    margin-top: 0;
    .privacy-cta-block {
        @include respond(medium-down) {
			margin-bottom: 45px;
        }
    }
}
.signin .login-box .login-box-content {
    .privacy-cta-block {
        margin: 1.25em 0 1em 0;
        @include respond(medium-down) {
            margin: .75em 0 0 0;
        }
        a {
            @include respond(medium-down) {
                margin: .25em 0 .75em 0;
            }
        }
    }
}
.mac .loginform, .mac .loginform-new {
    input[type="checkbox"],
    input[type="radio"] {
        & + label {
            top: 1px;
            padding-right: 0;
        }
    }
}
.create-account{
    .login-box {
        h2{
            margin-bottom: 8px;
            @include respond(small-down) {
                margin-bottom: 10px;
            }
        }
        &.login-account, &.login-create-account {
        		@include respond(small-down) {
        			p:first-of-type {
        				padding: 0 13%;
        			}
        		}
        }
        .login-box-content {
            border: none;
            margin-top: 0;
            padding: 0;
            p{
                margin: 0 0 17px;
                @include respond(small-down) {
                margin: 0 0 25px;
                }
            }
        }
        .loginform, .loginform-new {


        }
        .content-asset {
            h3 {
                color: $primary-color;
                @include font-regular(18px);
                text-transform: none;
                padding-bottom: 5px;
            }
        }
    }
}

.track-order-form {
	.form-row-button {
        button {
            @include respond(large-up) {
                width: 135px;
                padding: 0;
            }
        }
		.or {
			@include font-size(14px);
			text-transform: capitalize;
			margin-top: 23px;
			margin-bottom: 5px;
			@include respond(large-up) {
                margin-top: 11px;
			}
		}
		.sign-in-wrap {
            @include font-size(14px);
            a {
                color: $secondary-color;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
                &:active{
                    color: $click;
                }
            }
		}
	}
}

// password show/hide button padding

#dwfrm_profile_login_password,
#dwfrm_login_password,
#dwfrm_resetpassword_password {
    padding-right: 59px;
}

.track-order-form,
#NewPasswordForm,
#RegistrationForm,
#ChangeMailForm {
    @include respond(medium-down){
        padding: 0;
        margin-bottom: 30px;
        .form-row-button{
            button{
                width: 100%;
            }
        }
    }
    legend{
        width: 100%;
        padding: 20px 0 0;
    }
    &.form-horizontal{
        label{
            @include respond(large-up) {
                width: 40%;
            }

        }
        .field-wrapper{
            @include respond(large-up) {
                width: 40%;
            }
        }
        .form-caption {
            @include respond(large-up) {
                width: 40%;
				margin: auto;
				padding-left: 2%;
				box-sizing: border-box;
				margin-top: 10px;
            }
            &.term-description {
                padding-left: 2.6rem;
                text-indent: unset;
            }
        }
        .checkbox{
            @include respond(medium-down){
                margin-bottom: 10px;
            }
            label{
                float: left;
                width: 82%;
                padding-right: 0;
                /*@include respond(large-up) {
                    width: 85%;
                }*/

                &.loyalty-join-terms-label {
                    float: none;
                    width: 100%;
                }
            }
            .input-checkbox{
                float: left;
            }
            .form-field-tooltip {
                float: left;
                padding-top: 0;
                width: auto;
                @include respond(medium-down){
                    float: right;
                }
            }
        }
        .tooltip{
            float: right;
            position: relative;
            right: 0;
        }
        .form-row-button {
            margin-left: 0;
            width: 100%;
        }
    }
    .content-asset{
        text-align: center;
        h3 {
            color: $primary-color;
            @include font-regular(14px);
            text-transform: none;
            padding-bottom: 5px;
            margin: 0;
        }
        p{
            margin: 0;
        }
    }
    .required-pass {
        margin: -10px auto 35px;
	    @include respond(large-up) {
	        width: 37%;
	        padding-left: 2%;
	        &.order-conf-pass{
	        	width: 100%;
	        }
	    }
        .content-asset {
			text-align: left;
			border:1px solid $primary-border;
			padding: 8px 15px;
            @include respond(large-up) {
				/*margin-left: 6%;*/
				padding: 15px 20px;
            }
            h3 {
	            padding: 0;
	            @include font-regular(14px);
	            color: $secondary-color;
	            text-transform: uppercase;
            }
            ul {
				padding: 0 0 0 15px;
				margin: 0;
				li {
					padding: 0;
					@include font-regular(12px);
					@include line-height(16px);
					margin: 0;
                    color:$primary-color;
					@include respond(large-up) {
						@include font-regular(14px);
						@include line-height(18px);
					}
                }
            }
        }
    }
}

.corporate-gifts-contact-us-top {
	@include font-regular(14px);
    @include line-height(24px);
    margin:42px 0 10px;
    padding: 0 15px;
    @include respond(large-up) {
        width: 64%;
        padding: 0;
        margin: 0 auto 13px;
        .content-asset {
            width: 85%;
        }
    }
    span {
        white-space: nowrap;
    }
}

.captcha-dialog {
	.ui-widget-header {
       display: none;
    }
    #captcha-dialog {
        text-align: center;
        padding: 25px 0;
        h1 {
            text-transform: uppercase;
            margin-bottom: 35px;
        }
        .or {
            text-transform: uppercase;
            margin: 22px 0;
            @include font-size(14px);
        }
        .tryagain {
            text-decoration: underline;
            @include font-size(14px);
            &:hover {
                text-decoration: none;
            }
        }
    }
}
.questions {
    text-align: center;
}
.signin .login-box {
    .error-form {
        color: $text-feedback-danger;
        background-color: $bg-feedback-danger;
        font-weight: bold;
        .icon {
            top: 4px;
        }
    }
    .loginerror {
        border-color: $text-feedback-danger;
    }
    h2 {
	    margin-bottom: 8px;
    }
    p {
        margin: 0 0 17px;
    }
}
#main .signin .login-box {
    @include respond(medium-down) {
        margin-top: 10px;
    }
}
.wishlist-landing {
    .login-create-account {
        margin: 70px 0;
        @include respond(medium-up) {
            margin: 90px 0 0;
        }
    }
}
