#wrapper {
	&.pt_categorylanding {
        .breadcrumb{
            margin-left: 0px;
            @include respond(landing-max) {
                margin-left: auto;
            }
            @include respond(medium-down) {
                margin-left: auto;
            }
        }
        #main {
	        @include respond(medium-down) {
	            padding: 0;
	        }
	        img {
	           max-width: 100%;
	           vertical-align: top;
	        }
	        .no-left-nav {
	           max-width: 1200px;
	           margin: 0 auto;
               .landing-top-banner {
                   padding: 0 16px;
                   h1, .ds-subcategory-heading {
                    @include ds-heading(h2);
                    border-bottom: 1px solid $secondary-border;
                    padding-bottom: 16px;
                    margin: 5px 0 32px;
                   }
               }
	        }
	        #secondary {
	           &.refinements {
	               box-sizing: border-box;
	               margin-top: 0;
                   /*min-width: 225px;*/
                   min-width: 150px;
                   @include respond(desktop) {
                       min-width: 190px;
                   }
                   @include respond(landing-max) {
                       //min-width: 290px;
                   }
	               @include respond(medium-down) {
	                   margin-bottom: 23px;
	                   padding: 0 15px;

	               }
                   .outer-close-filters{
                       @include respond(medium-down) {
                           display: none;
                       }
                   }
	               .filter-content-category {
	                   @include respond(medium-down) {
                           padding: 0 20px;
                           margin-bottom: 0;
                       }
	               }
                   .toggle {
                       margin-top: 0;
                       @include font-size(12px);
                       & + ul {
	                       padding: 0;
	                       margin: 10px 0 30px;
	                       @include respond(large-up) {
	                           margin-bottom: 47px;
	                       }
                       }
                   }
                   .mobile-only.filter-show {
                       width: 100%;
                       background-image: none;
                       background-color: #333;
                       color: #fff;
                       line-height: 20px;
                       text-align: center;
                       padding: 8px;
                       text-transform: uppercase;

                       &:hover,
                       &:active {
                           background-color: #f2f2f2;
                           color: #333;
                       }
                   }
                }
	        }
	        .primary-content {
	           @include respond(large-up) {
	               width: calc(100% - 225px);
	           }
	           @include respond(landing-max) {
	               left: 5px;
               }
            //    SHOP THE LOOK shop the look
               &.shop-the-look {
                   h1, .ds-subcategory-heading {
                    @include ds-heading(h2);
                    border-bottom: 1px solid $secondary-border;
                    padding-bottom: 16px;
                    margin: 5px 0px 32px;
                    @include respond(medium-down) {
                        margin-left: 16px;
                        margin-right: 16px;
                    }
                   }
                   .landing-top-banner {
                        margin-bottom: 20px;
                        @include respond(medium-down)  {
                            margin: 0 15px;
                        }
                        @include respond(large-up) {
                            margin-bottom: 30px;
                        }
                   }

                   .bopis-sort-banner {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: row;
                        margin-bottom: 32px;
                        @include respond(medium-down)  {
                            flex-direction: column-reverse;
                            margin: 0 15px 20px;
                        }

                        .sort-by {
                            margin-left: 7.4%;
                            min-width: 200px;
                            @include respond(medium-down)  {
                                margin-left: 0;
                                width: 100%;
                            }
                            #grid-sort-header {
                                @include respond(medium-down)  {
                                    border: 1px solid #333;
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                   }

                   .search-result-items.tiles-container {
                       margin-bottom: 29px;
                       @include respond(medium-down)  {
                            padding: 0 15px;
                            justify-content: space-between;
                            margin-bottom: 43px;
                        }
                       .grid-tile {
                           &:nth-child(4n+1) {
                               @include respond($desktop-breakpoint + 1) {
                                    margin-left: 0;
                               }
                           }
                           @include respond(small-down){
                                &.even {
                                    margin-left: 0;
                                }
                            }
                       }

                   }
               }
	        }

	        .breaker {
	            position: relative;
	            border-top: 1px solid $secondary-border;
	            text-align: center;
	            margin: 40px auto 25px;
	            @include respond(large-up) {
	                margin: 50px auto 34px;
	            }
	            h4 {
	                @include font-bold(17px);
	                letter-spacing: 2.5px;
	                display: inline;
	                padding: 0 20px;
	                position: relative;
	                top: -8px;
	                background: $white;
	                @include respond(large-up) {
                        left: 11px;
	                }
	            }
            }
            .landing-top-banner {
                h1, .ds-subcategory-heading {
                    @include ds-heading(h2);
                    border-bottom: 1px solid $secondary-border;
                    padding-bottom: 16px;
                 margin: 5px 0 32px;
                }
            }
            .product-top-banner{
                &.topoffers {
                    margin: -10px 0 30px;
                }
            }
	        .section {
	           &.last {
	               @include respond(medium-down) {
	                   margin-bottom: 100px;
	               }
	           }
	           &.brand {
	               @include respond(medium-down) {
                       margin-bottom: 70px;
                       &.grid {
                            margin-bottom: 5px;
                            padding: 0 15px;
                       }
                   }
	           }
	           &.no-breaker {
	               margin-bottom: 35px;
	               @include respond(large-up) {
	                   /*margin-bottom: 45px;*/
	               }
	           }
	           &.small {
	               max-width: 954px;
	           }
                &.top-offers{
                    border-bottom: 1px solid $secondary-color;
                    margin-top: 20px;
                    padding-bottom: 6px;
                    @include respond(medium-down) {
                        margin: 0px 15px 25px;
                        padding-bottom: 8px;
                    }
                    img{
                        max-width: 570px;
                        width: 100%;
                        @include respond(medium-down) {
                            max-width: 100%;
                        }
                    }
                }
                &.shop-by-category{
                    text-align: center;
                    margin: 17px 0 30px 14px;
                    @include respond(medium-down) {
                        margin: 13px 0 16px 0px;
                    }
                    img{
                        max-width: 323px;
                        height: 20px;
                        @include respond(medium-down) {
                            max-width: 260px;
                            height: 17px;
                        }
                    }
                }
	        }
	        .banner-container {
	             padding: 0;
	            .banner-cell {
	                text-align: center;
	                box-sizing: border-box;
	                line-height: 1;
	                img {
	                    display: block;
	                    margin: 0 auto 25px;
                        @include respond(medium-down) {
                            margin: 0 auto 10px;
                        }
	                }
	                .fake-button {
	                    margin-top: 26px;
                        display: inline-block;
                        @include respond(medium-down) {
                            margin-top: 11px;
                        }
	                }
                    span:not(.fake-button){
                        @include font-regular(14px);
                        color: $secondary-color;
                        display: block;
                        text-align: center;
                        padding: 3px 0;
                        &.name{
                            @include font-bold(14px);
                            text-transform: uppercase;
                        }
                        &.price{
                            @include font-regular-extend(30px);
                        }
                        &.discount{
                            @include font-regular(14px);
                            color: #999;
                        }
                    }

	            }
	            &.two-cells {
	                @include respond(large-up) {
                       max-width: 790px;
                       margin: 0 auto;
                    }
                    .banner-cell {
                        margin-bottom: 35px;
                        &.last {
                            margin-bottom: 0;
                        }
                        @include respond(large-up) {
                            float: left;
                            width: 50%;
                            margin-bottom: 45px;
                            &.odd {
                                padding-right: 10px;
                            }
                            &.even {
                                padding-left: 10px;
                            }
                        }
                    }
                }
	            &.three-cells {
	                .banner-cell {
	                    margin-bottom: 35px;
	                    &.last {
	                        margin-bottom: 0;
	                    }
	                    @include respond(large-up) {
	                        float: left;
	                        width: 31.429%;
	                        margin: 0 0 0 2.8%;
	                        &.first {
	                            margin: 0;
	                        }
	                    }
	                }
	            }
	            &.four-cells {
	                .banner-cell {
	                    float: left;
	                    width: 23.204%;
	                    margin-left: 2.39%;
	                    @include respond(large-up) {
	                       &.first {
                                margin: 0;
                            }
	                    }
	                    @include respond(medium-down) {
	                        width: 50%;
	                        margin: 0 0 30px;
	                        &.odd {
	                            padding-right: 8px;
	                        }
	                        &.even {
	                            float: right;
	                            padding-left: 8px;
	                        }
	                    }
	                    .fake-button {
                            @include respond(large-down) {
	                           padding: 0 1px;
                            }
	                        @include respond(medium-down) {
	                           padding: 0 13px;
	                        }
	                    }
	                }
	            }
	            &.three-two {
                   .breaker {
                       clear: both;
                       float: left;
                       width: calc(100% + 30px);
                       margin: 10px 0 29px -15px;
                       @include respond(large-up) {
                           margin: 5px 0 20px 0;
                       }
                   }
                   .banner-cell {
                        float: left;
                        width: 50%;
                        margin: 0 0 30px;
                        @include respond(large-up) {
                           width: 31.448%;
                           margin: 0 0 44px 2.8%;
                           &.new-row {
                                margin: 0;
                            }
                        }
                        @include respond(medium-down) {
                            &.odd {
                                padding-right: 8px;
                            }
                            &.even {
                                float: right;
                                padding-left: 8px;
                            }
                        }
                        .fake-button {
                            @include respond(large-down) {
                                padding: 0 3px;
                            }
                            @include respond(medium-down) {
                                padding: 0 12px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
	        }
	    }
	}
}

.bottom-banner-container {
    overflow: hidden;
    .bottom-banner-cell {
        float: left;
        overflow: hidden;
        text-align: center;
        @include respond(large) {
            img {
                max-width: 100%;
            }
        }
    }
    .banner-cell-1 {
        width: 33%;
    }
    .banner-cell-2 {
        width: 34%;
    }
    .banner-cell-3 {
        width: 33%;
    }
}
