@import "./cart-delivery-options";

#wrapper {

    position: relative;
    &.pt_cart {
    	overflow: hidden;
        @include respond(medium-down) {
            #main {
                padding: 0 15px;
            }
            #secondary {
                display: none;
            }
        }
        img {
           max-width: 100%;
           max-height: 100%;
        }

        &.dom-loading {
            .before-cart-dom-ready-loader {
                position: absolute;
                background: rgba(255,255,255,0.85);
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: 9999;

                &::before {
                    content:"";
                    background: url("../images/gingham_loader_64x64.gif") center center no-repeat;
                    background-size: 32px;
                    width: 32px;
                    height: 32px;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

.pt_cart {
    .primary-content {
       h1 {
            @include ds-heading(h2);
            color: $text-primary;
           @include respond(large-up) {
               margin: 0 0 30px;
           }

           @include respond(medium-down) {
               margin: 0;
           }
        }
        .select-bonus,
        .cart-actions .button-fancy-large {
            @include ds-utilities-button;
            height: 48px;

            @media screen and (min-width: $mMin) {
                width: calc(50% - 12.5px);
            }

            color: $text-inverse;
        	background-color: $checkout-button-default;
        	border-color: $checkout-button-default;
                &:hover, &:focus {
                    background-color: $checkout-button-dark;
                    border-color: $checkout-button-dark;
                    color: $text-inverse;
                }
                &:active {
	                background-color: $checkout-button-light;
	                border-color: $checkout-button-light;
	                color: $text-inverse;
	            }
            @include respond(medium-down){
                height: 40px;
                max-height: 40px;
            }
        }
        .checkout-button-icon {
            background: url("../images/svg-icons/lock-filled.svg") no-repeat;
            background-size: cover;
            display: inline-block;
            filter: $icon-inverse-filter;
            height: 16px;
            margin-right: 5px;
            position: relative;
            width: 16px;
        }
    }
    .mini-cart-content{
        display: none !important;
    }
    .recommendations {
        &.promo-block, &#cart1_rr {
            margin-top: 39px;
            @include respond(large-up) {
                border: 1px solid $primary-border;
                padding: 24px 0;
                position: relative;
                max-width: 783px;
                margin: 56px auto 0;
            }
            @include respond(large) {
                max-width: 648px;
            }
            .title {
                margin: 0 0 5px;
                @include font-bold(12px);
                text-transform: uppercase;
                text-align: center;
                @include respond(large-up) {
                    @include font-bold(14px);
                    margin: 0 0 5px;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    padding: 0 15px;
                    background: $white;
                    top: -12px;
                }
            }
            .product-tile {
                .sub-attribute {
                    @include font-size(11px);
                }
                .product-price {
                    @include font-size(14px);
                    margin: 10px 0 14px;
                    .price-sales {
                        color: $secondary-color;
                    }
                    .price-standard {
                        text-decoration: line-through;
                        & ~ .price-sales {
                            @include ds-ui-bold(m);
                            color: $text-feedback-danger;
                        }
                    }
                }
                .promotion {
                    .promotion-callout {
                        color: $text-brand-accent;
                        @include font-size(12px);
                        line-height: 1;
                        @include respond(large-up) {
                            @include font-size(11px);
                        }
                        .callout-message {
                            margin-bottom: 8px;
                        }
                        * {
                            @include font-size(14px);
                            line-height: 1.4;
                        }
                        p {
                            margin: 0;
                            color: $red;
                        }
                    }
                }
            }
        }
        &#cart1_rr {
            background: url("../images/gingham_loader_64x64.gif") center center no-repeat;
            background-size: 32px;
            h2 {
                display: none;
            }
            &.promo-block {
                background: none;
                h2 {
                    display: block;
                }
            }
            @include respond(medium-down) {
                display: none;
                min-height: 286px;
                &.mobile-visible {
                    display:block;
                }
            }
            @include respond(large-up) {
                min-height: 278px;
                margin-top: 59px;
                .close {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    display: block;
                    z-index: 10;
                    top: 10px;
                    right: 10px;
                    background: url("../images/svg-icons/UI-Close-dark.svg") no-repeat center;
                    cursor: pointer;
                }
            }

            .close {
                display: none;
            }
        }
    }
    .item-removed-message + .recommendations {
        &#cart1_rr {
            @include respond(large-up) {
                margin-top: 20px;
            }
        }
    }

    .owl-nav {
        button.owl-next {
            right: -75px;
            @include respond (large){
            	right: -60px;
            }
        }
        button.owl-prev {
            left: -75px;
            @include respond (large){
            	left: -60px;
            }
        }
    }
	.cart-continue-shopping {
        @include ds-ui-regular(s);
        color: $text-secondary;
		text-transform: capitalize;
	    display: flex;
	    align-items: center;
	    margin: 10px 0 10px 15px;
	    max-width: 160px;
        text-decoration: underline;
	    .svg-arrow-left-dark-grey-dims {
	        width: 18px;
	        height: 14px;
	        margin-right: 8px;
	    }
	    @include respond(large-up) {
            margin-left: 25px;
            &:focus {
                color: $dim-gray;
            }
            &:hover {
                color: $dim-gray;
                text-decoration: none;
                .arrow-left-dark-grey use {
                    fill: $dim-gray;
                }
            }
        }
        &:active {
            color: $click;
            text-decoration: none;
            .arrow-left-dark-grey use {
                fill: $click;
            }
        }
	}
    #cart-table {
        .frequency_block, .confirmation_shipping_icon {
            display: none;
	    }
    }
}

.cart-banner {
    img {
       vertical-align: top;
       max-width: 100%;
    }
    &.cart-top-banner {
       img {
           width: 100%;
       }
    }
}

#cart-table {
    margin-bottom: 0;

    @include respond(tablet-small) {
       table-layout: fixed;
    }
    thead {
        display: none;
        @include respond(large-up) {
            display: table-header-group;
            tr {
                border: 0 none;
            }
            th {
                background-color: $white-smoke;
                @include font-bold(14px);
                @include line-height(15px);
                padding: 10px 0;
                white-space: nowrap;
                &.item {
                    padding-left: 10px
                }
                &.header-total-price {
                    text-align: right;
                    padding-right: 20px
                }
            }
        }
    }
    tr {
        @include respond(medium-down) {
            position: relative;
            display: block;
            &::after {
                display: block;
                content: ' ';
                float: none;
                clear: both;
            }
        }
    }
    td {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        @include respond(large-up) {
            padding-top: 20px;
        }
        @include respond(medium-down) {
            display: block;
            float: left;
            width: 50%;
        }
    }

    .item-image {
        float: left;
        text-align: center;
        width: 110px;
        @include respond(large-up) {
            float: none;
            width: 150px;
            height: 205px;
        }
        @include respond(medium-down) {
            padding-top: 20px;
        }
        a {
            display: block;
            width: 100%;
            margin: 0;
        }
        img {
            display: block;
            max-width: 70px;
            max-height: 150px;
            @include respond(large-up) {
                max-width: 80px;
                max-height: 160px;
            }
            vertical-align: top;
            margin: 0 auto;
        }
    }
    .cart-promo {
        @include respond(medium-down) {
            width: 100%;
        }
    }
    .item-details {
        @include respond(large-up) {
            float: none;
            width: 315px;
        }
        @include respond(medium-down) {
            padding-top: 20px;
            width: 50%;
        }
        .product-list-item > a {
                cursor: default;
        }
        .product-list-item {
            margin-bottom: 27px;
            @include respond(small-down-iphone) {
                    margin-right: 15%;
            }
            .social-proofing {
                @include ds-ui-bold(s);
                background-color: $brand-primary;
                border-radius: 2px;
                color: $text-inverse;
                display: inline-block;
                padding: 4px 8px;
                margin-bottom: 6px;
            }
            .bbwd-tooltip-content-container {
                @include ds-body(m);
                color: $text-primary;

                span {
                    @include ds-body(m);
                    color: $text-primary;
                }
            }
            .giftcertamount {
                @include ds-ui-regular(s);
                color: $text-secondary;
            }
            .attribute {
                @include ds-ui-regular(s);
                color: $text-secondary;

                .value,
                .label {
                    @include ds-ui-regular(s);
                    color: $text-secondary;
                }
            }
            .name {
                margin-bottom: 5px;

                @include respond(small-down-iphone) {
		            max-width: 116px;
		        }

                a {
                    color: $text-primary;
                }
            }
            .gift-cert-delivery {
                @include ds-ui-semi(s);
                color: $text-feedback-success
            }
            .sub-attribute,
            .sku,
            .promo {
                @include ds-ui-regular(s);
                color: $text-secondary;
                margin-bottom: 5px;

                a {
                    color: $text-secondary;
                }
            }
            .item-edit-details {
                @include ds-ui-regular(s);
                color: $text-primary;
                margin-bottom: 5px;
            }
            .promo {
                @include ds-ui-semi(s);
                color: $text-feedback-danger;
                position: relative;
                margin-bottom: 0;
                margin-top: 10px;

                .tooltip.text-inline {
                    display: block;
                    margin-top: 5px;
                    width: 100px;
                }
            }
            .callout-message {
                color: $text-feedback-danger;
            }
            .promo__tooltip,
            .bbwd-tooltip {
                @include ds-ui-regular(s);
                color: $text-secondary;
            }
            .sku.visually-hidden {
                display: none;
            }
        }
        .item-edit-details {
            &.edit-bonus {
                margin-bottom: 13px;
            }
            a {
                @include ds-ui-regular(m);
                color: $text-secondary;
                text-decoration: underline;
                &:hover {
                   text-decoration: none;
                }
            }
        }
        .product-availability-list {
            li {
                @include font-size(12px);
                @include line-height(12px);
                &.not-available {
                    display: none;
                }
                &.low-radial-inventory {
                    display: none;
                }
            }

        }
        .add-to-wishlist, .in-wishlist {
             display: inline-block;
             @include ds-ui-regular(s);
             color: $text-primary;
             @include respond(large-up) {
                line-height: 1rem;
                padding-left: 20px;
                text-indent: -18px;
                max-width: 140px;
             }
             @include respond(small) {
				padding-left: 20px;
				text-indent: -18px;
			}
             svg, img.icon {
                width: 12px;
                height: 12px;
                position: relative;
                top: 2px;
             }
             span {
                text-decoration: underline;
                padding-left: 3px;
                &:hover {
                    text-decoration: none;
                }
             }
        }
        button.add-to-wishlist {
            max-width: none;
        }
        .in-wishlist {
            span {
                text-decoration: none;
            }
        }
    }
    .item-quantity {
        @include respond(large-up) {
            clear: none;
            float: none;
            text-align: left;
            width: 245px;
            padding-top: 20px;
            &.count {
                padding-top: 43px;
            }
        }
        input {
            width: 2.7rem;
        }
        span.error {
            display: block;
            clear: both;
            @include ds-ui-regular(m);
            color: $text-feedback-danger;
            max-width: 242px;
            padding: 5px 0 0 0;
            top: unset;

            b {
                font-weight: bold;
            }
        }

        .quantity-field {
            float: left;
            position: relative;
            @include ds-ui-semi(m);

            @include respond(large-up) {
                min-width: 107px;
            }

            input {
                @include ds-ui-semi(m);
                text-shadow: 0 1px 0 $white;
                text-transform: uppercase;
                height: 48px;
                width: 35px;
                text-align: center;
                padding: 0;
                background: $bg-color;
                margin: 0 1px;
                border: solid 1px $bg-color;
                &:active, &:focus {
                    border-color: $gainsboro;
                    background-color: $white;
                }
            }
            .quantity-button{
                display: flex;
                align-items: center;
                justify-content: center;
                float: left;
                text-align: center;
                width: 35px;
                height: 48px;
                box-sizing: border-box;
                cursor: pointer;
                background: $bg-color;
                border: solid 1px $bg-color;
                padding: 0;
                body:not(.touch) & {
                    &:hover {
                        color: $white;
                        border-color: $secondary-color;
                        background-color: $secondary-color;
                    }
                    &:active, &:focus {
                        border-color: $light-gray;
                        background-color: $white;
                        color: $secondary-color;
                    }
                }
                &:active,
                &:focus {
                    color: $white;
                    border-color: $night-rider;
                    background-color: $night-rider;
                }
                &.increase{
                    float: right;
                }
                &.disabled{
                    pointer-events: none;
                }
            }
        }
        .product-availability-list {
            clear: both;
            max-width: 190px;
            li {
                &:not(.not-available):not(.low-radial-inventory) {
                    display: none;
                }

                @include font-size(12px);
                @include line-height(12px);
            }
        }
    }
    .item-quantity-details {
        clear: left;
        float: left;
        text-align: center;
        width: 40%;
        @include respond(large-up) {
            clear: none;
            float: none;
            text-align: left;
            width: auto;
        }

        .item-user-actions {
            a, button {
                display: block;
                width: 100%;
                margin: .3rem 0;
                @include respond(large-up) {
                    width: auto;
                }
            }
        }
    }
    .item-price {
        color: $text-primary;
        @include ds-ui-semi(m);
        @include respond(large-up) {
            width: 100px;
        }
        @include respond(medium-down) {
            padding: 20px 0;
            &.empty-price {
                padding: 11px 8px;
            }
        }
    }
    .price-sales {
        color: $secondary-color;
    }
    .price-promotion {
        .price-sales {
            @include ds-ui-semi(m);
            color: $text-feedback-danger;

            &.price-free {
                text-transform: uppercase;
            }
        }
        .price-standard {
            text-decoration: line-through;
            margin-bottom: 10px;
            margin-right: 5px;
            @include respond(large-up) {
                display: block;
            }
        }
    }
    .item-total {
        word-wrap: break-word;
        @include ds-ui-semi(m);
        color: $text-primary;

        @include respond(large-up) {
            float: none;
            width: 115px;
            padding-right: 20px;
            text-align: right;
        }
        @include respond(medium-down) {
            margin-top: 20px;
            padding-bottom: 35px;
            margin-bottom: 0;
        }
        .price-free {
            text-transform: uppercase;
        }
    }
    .price-unadjusted {
        color: $dim-gray;
        display: block;
        margin: 0 0 .42em;
        span {
            text-decoration: line-through;
        }
    }
    .price-adjusted-total {
        color: $dim-gray;
        display: block;
        margin: .42em 0 0;
        span {
            color: $sangria;
        }
    }
    @include respond(large) {
        .item-user-actions {
            width: 72px;
        }
        .item-total .promo-adjustment {
            width: 77px;
        }
    }
    .item-remove {
        width: 25px;
        min-width: 25px;
        padding-top: 16px;
        padding-right: 10px;
        @include respond(large-up) {
            text-align: right;
        }
        @include respond(medium-down) {
            width: 20px;
            min-width: 20px;
            padding-top: 17px;
            padding-right: 0px;
            position: absolute;
            top: 0;
            right: 0;
        }
        .remove-item {
            line-height: 10px;
            float:none;
            &:hover{
                background: none;
            }
            @include respond(medium-down) {
                top: 20px;
                right: 5px;
            }
            svg {
                width: 20px;
                height: 20px;
            }
            span {
                height: 0;
                width: 0;
                position: absolute;
                left: -99em;
            }
            &:focus {
                outline: 1px dotted;
            }
        }
        button.remove-item,
        .remove-icon {
            &:hover {
                path {
                    fill: $dim-gray;
                }
            }
            &:active {
                path {
                    fill: $click;
                }
            }
        }
    }
    .cart-promo {
        padding: 20px 24px 20px 30px;
    }
    .select-bonus-item {
        @include font-size(14px);
        background: $bg-color;
        @include respond(medium-down) {
            width: 100%;
            text-align: center;
            padding: 20px 17px;
        }
        @include respond(large-up) {
            padding: 30px;
        }
        &.bonus-item-actions{
            @include respond(medium-down) {
                padding-top: 0;
                .select-bonus {
                    width: 100%;
                    box-sizing: border-box;
                }
            }
            @include respond(large-up) {
                text-align: right;
                padding: 20px 24px;
            }
        }
    }
    &.bopis-item-list {

        .cart-line-item {
            border-bottom: 1px solid $dim-gray;
            border-top: 1px solid $dim-gray;
            padding-bottom: 15px;
            @include respond(large-up) {
                border-top: none;
                padding-top: 20px;
                padding-bottom: 30px;
                min-height: 192px;
                box-sizing: border-box;
            }
            & ~ .cart-line-item {
                border-top: none;
            }
        }

        &.bopis-items-without-store {
            .cart-line-item {
                @include respond(medium-down) {
                    padding-bottom: 30px;
                }
            }
        }

        .product-main-details-with-image {
            display: flex;
            button.add-to-wishlist span,
            button.add-to-wishlist img {
                pointer-events: none;
            }
        }

        .cart-line-items-headings {
            background-color: $white-smoke;
            display: none;

            @include respond(large-up) {
                display: flex;
            }
            .section-header {
                @include ds-heading(h4);
                color: $text-primary;
                text-transform: capitalize;
                padding: 10px 0;
                white-space: nowrap;
                &.item {
                    padding-left: 14.5px;
                    box-sizing: border-box;
                    width: 400.5px;
                }

                &.header-total-price {
                    text-align: right;
                    padding-right: 20px;
                    margin-right: auto;
                    width: 167.5px;
                    box-sizing: border-box;
                }

                &.header-price {
                    width: 86px;
                }

                &.header-qty {
                    width: 242px;
                }

                @include respond($large-breakpoint, $max-small-coll) {
                    &.item {
                        width: 42%;
                    }

                    &.header-qty {
                        width: 25%;
                    }

                    &.header-total-price {
                        width: 15%;
                    }
                }

            }
        }

        .item-image {
            float: none;
            min-width: 110px;
            height: auto;
            @include respond(large-up) {
                min-width: 150px;
                height: auto;
                padding-top: 10px;
            }
        }

        .item-full-details {
            min-width: 140px;
            margin: 0 auto;

            @include respond(large-up) {
                display: flex;
                margin: 0;
            }
        }

        .item-details {
            box-sizing: border-box;
            // to override normal styles
            @include respond(medium-down) {
                width: auto;
                padding-right: 25px;
            }

            @include respond($large-breakpoint) {
                // 42% of container(total device width - spacing) - image container width
                width: calc((100vw - 40px) * 0.42 - 150px);
                padding-top: 10px;
                padding-right: 20px;

            }

            @include respond($max-small-coll) {
                width: calc( 400.5px - 150px);
            }
        }

        .price-and-qty-box {
            @include respond(large-up) {
                display: flex;
            }

            .item-price {
                @include respond(large-up) {
                    padding-top: 10px;
                    width: 86px;
                }
            }

            .item-quantity {
                @include respond(large-up) {
                    padding-top: 0;
                }

                @include respond($large-breakpoint) {
                    width: calc((100vw - 40px) * 0.25);
                }

                @include respond($max-small-coll) {
                    width: 242px;
                }

                .gift-quantity {
                    height: 35px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include ds-ui-semi(m);
                    color: $text-primary;
                }

                .product-availability-list {
                    clear: both;
                    max-width: 175px;
                    padding-top: 5px;

                    @include respond(large-up) {
                        padding-top: 18px;
                    }

                    li {
                        @include ds-ui-regular(m);

                        &.is-in-stock {
                            color: $text-feedback-success;
                        }

                        &:not(.not-available):not(.low-radial-inventory) {
                            display: block;
                        }
                    }
                }

            }

            .item-total {
                box-sizing: border-box;
                padding-bottom: 0;

                @include respond($large-breakpoint) {
                    width: calc((100vw - 40px) * 0.15);
                    padding: 10px 20px 11px 0;
                }

                @include respond($max-small-coll) {
                    width: 167.5px;
                }
            }
        }

        .item-remove {
            @include respond(medium-down) {
                position: unset;
            }
            @include respond(large-up) {
                text-align: left;
                margin-left: auto;
                padding-top: 4px;
            }
        }

        .desktop-delivery-options {
            display: none;

            @include respond(large-up) {
                display: block;
                width: calc((100vw - 40px) * 0.4 + 86px);
            }

            @include respond($max-small-coll) {
            	width: 495.5px;
            }
        }

        .mobile-delivery-options {
        	padding: 0 15px;
            @include respond(large-up) {
                display: none;
            }
        }
    }
}

.update-cart-row {
    border-bottom: 1px solid $gainsboro;

    .update-cart {
        display: flex;
        justify-content: center;
        padding: 30px 0;
        width: 100%;

        button {
            @include ds-utilities-button;
            width: 157px;

            @media screen and (max-width: $sMax) {
                margin: auto;
            }
        }
        @include respond(large-up) {
            justify-content: right;
        }
    }
}

.cart-promo {
    @include ds-ui-semi(m);
    color: $text-feedback-success;
    background: $bg-color;
    margin: 0;
    margin-top: 10px;
    @include respond(large-up) {
        margin-bottom: 10px;
    }
    .bonus-item-actions {
        text-align: right;
        vertical-align: middle;
    }
    &.cart-promo-approaching {
        background: $alice-blue;
        color: $text-feedback-info;
        padding: 11px 20px;
    }
    &.cart-bogo-promo {
        background: $bg-feedback-success;
        padding: 11px 20px;
    }
}
hr.promo-area {
	width: 100%;
	border: none;
	border-bottom: 1px solid $dim-gray;
	padding-top: 11px;
	margin: 0;
}

.cart-footer {
    @extend %clearfix;
    position: relative;
    margin: 20px 0 0;
    @include respond(large-up) {
        margin: 30px 0 0;
    }
    .cart-notify {
    	@include respond(medium-down) {
    		.alert {
    			margin-top: 20px;
	    		padding: 15px;
			    background-color: #FFE9E9;
			    color: white;
			    .frozen-notification {
			    	color: #CC0000;
				    font-size: 0.875rem;

				    a {
				    	color: #CC0000
				    }
			    }
    		}
    	}
    }
}

.cart-coupon-code-wrap {
    @include respond(large-up) {
        float: left;
        max-width: 45%;
        padding-bottom: 20px;
        .cart-notify {
     	position: relative;
    	box-sizing: border-box;
    	.alert {
    		margin-top: 20px;
    		padding: 15px;
		    background-color: #FFE9E9;
		    color: white;
		    .frozen-notification {
		    	color: #CC0000;
			    font-size: 0.875rem;

			    .content-asset {
			    	display: inline;
			    }
			    a {
			    	color: #CC0000;
			    }
		    }
    	}
     }
  }
  .blue-error-message {
      background-color: #E6F5FF;
      color: #1B79AA;
      padding: 8px 20px;
      .cart-promo-msg-link {
          color: #1B79AA;
          &:active {
              color: #005699;
          }
      }
  }
}

.coupon-list {
    .coupon-container {
        &:first-child {
            margin-top: 32px;
        }
        margin-bottom: 16px;
        position: relative;
        .info-block {
            background-color: $bg-feedback-success;
            line-height: 1.2;
            padding: 16px;
            .promoname {
                @include ds-ui-semi(m);
                color: $text-feedback-success;
                padding-bottom: 5px;
            }
            .coupon {
                @include ds-ui-regular(s);
                color: $text-primary;
                text-transform: uppercase;
                padding-bottom: 5px;
            }
        }
        .rewards-badge {
            display: none;
        }
        .remove {
            @include ds-ui-semi(s);
            background: none;
            border: none;
            color: $text-primary;
            padding: 0;
            text-decoration: underline;
            text-transform: capitalize;

            &:hover {
                text-decoration: none;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.loyalty .coupon-container {
        .info-block {
            position: relative;
            border: 2px solid $cobalt;
            background: none;
            padding: 4px 30px 4px 50px;
            min-height: 50px;
            .promoname {
                color: $cobalt;
                padding-right: 0;
            }
            .coupon {
                font-size: .63rem;
                color: $night-rider;
                padding-right: 0;
            }
        }
        .rewards-badge {
            display: block;
            width: 34px;
            height: 46px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 10px;
            .badge-icon {
                background: url("../images/UI-Rewards-badge.svg") no-repeat center;
                background-size: contain;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.cart-coupon-code {
    border: 1px solid $primary-border;
    box-sizing: border-box;
    padding: 22px 20px 30px;
    position: relative;

    @include respond(medium-down) {
    	padding-left: 19px;
    	padding-right: 19px;
    }

    h2 {
        @include ds-heading(h4);
        color: $text-primary;
        margin: 0;
    }
    p {
        @include ds-ui-regular(m);
        color : $text-secondary;
    }
    &.signin-modal {
        margin-top: 20px;

        h2 {
        	position: relative;
            @include ds-heading(h4);
            color: $text-primary;

	        span {
	        	display: inline-block;
	        	margin-left: 25px;
	        }
		}

        button.trigger-link {
            @include ds-ui-semi(m);
            background: none!important;
            border: none;
            padding: 0!important;
            color: $text-primary;
            text-decoration: underline;
            text-transform: capitalize;
            margin: 0 auto;
            justify-content: left;
            height:auto;
            min-height:auto;
        }
    }
    &.loyalty {
        margin-top: 20px;

        .loyalty-asset {
            img {
                float: left;
                padding: 5px 10px 5px 0;
                width: 22px;
            }
            p {
                @include ds-ui-semi(m);
                color: $text-brand-primary;
                margin-bottom: 10px;
                margin-top: 0px;
            }
            .loyalty-asset-caps {
                text-transform: uppercase;
            }
        }

        h2 {
        	position: relative;
            @include ds-heading(h4);
            color: $text-primary;

	        .rewards-icon-badge {
		        display: inline-block;
			    position: absolute;
			    left: 0;
			    top: -1px;
			    width: 19px;
			    height: 24px;
	        }
	        span {
	        	display: inline-block;
	        	margin-left: 25px;
	        }
		}
		.coupon-desc {
			padding-bottom: 0;
		}
    }
    #add-coupon {
        @include ds-utilities-button;
        width: 97px;

        @include respond(large-up) {
            width: 30%
        }
    }
    .bbwd-tooltip-close-button {
        background-size: 13px;
        background: url(../images/svg-icons/bbwd-tooltip-close.svg) no-repeat center;
        border-color: transparent;
        height: 13px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 8px;
        text-indent: -999em;
        top: -8px;
        transition: none;
        width: 13px;
    }
    .wrapper-coupons {
    	margin-top: 6px
    }
    .no-offers {
    	@include font-size(14px);
    	text-align: center;
    	color: $dim-gray;
    	margin-bottom: 31px;

    	@include respond(medium-down) {
    		@include line-height(17px);
    		max-width: 66%;
		    margin-left: auto;
		    margin-right: auto;
    	}
    }
    .offer-cell {
    	border: 2px solid $gainsboro;
    	list-style: none;
    	margin-bottom: 20px;
    	padding: 11px 20px 11px 18px;

    	@include respond(medium-down) {
    		padding-left: 18px;
    	}
    	&.reward {
    		@include respond(medium-down) {
	    		padding-left: 12px;
	    	}
    		.reward-image {
    			display: inline-block;
    			vertical-align: baseline;

    			.rewards-badge {
		            width: 34px;
		            height: 46px;
		            margin-right: 9px;
		        }
    		}
    		.left-align {
                width: calc(100% - 130px);
    			word-wrap: break-word;

    			.reward-title {
    				color: $cobalt;
    			}
    		}
    	}
    	.left-align {
    		display: inline-block;
    		width: calc(100% - 80px);
    		word-wrap: break-word;

    		.reward-title {
                @include ds-ui-semi(s);
    			color: $text-feedback-success;
    			margin-bottom: 7px;
    		}

    		.redeem-code {
                text-transform: uppercase;
                @include ds-ui-regular(s);
                color: $text-secondary;
    		}
    	}
    	.right-align {
    		display: inline-block;
    		vertical-align: baseline;
    		width: 76px;

            input {
                display: none;
            }

    		.add-coupon,.add-coupon-loyalty {
                @include ds-utilities-button;
                float: right;
                padding-left: 0;
                padding-right: 0;
                width: 52px;
                @include respond(large-up) {
                    width: 60px;
                }
    		}
    	}

    }
    .coupon-desc {
        @include ds-body(s);
        color: $text-primary;
        padding: 8px 0 15px;
        position: relative;

        @include respond(large-up) {
            padding-top: 12px;
        }
        p {
            width: auto;
            float: left;
            margin: 0;
        }
        .form-field-tooltip {
            float: right;
            width: 0;
            margin: 0;
            .tooltip {
                position: absolute;
                top: 9px;
                right: 7px;
                @include respond(large-up) {
                   right: 9px;
                   top: 6px;
                }
                @include respond(large) {
                    right: -10px;
                    top: 10px;
                }
            }
        }
    }
	.accordion-loyalty {
		margin-top: 20px;
		.error-loyalty {
		    text-align: center;
			margin-top: 26px;
    		max-width: 234px;
		    margin-bottom: 34px;
		    margin-left: auto;
		    margin-right: auto;

			.error-text {
               @include ds-ui-semi(m);
               color: $text-feedback-danger;
			}

			a {
				text-decoration: underline;
			}
		}
		.accordion-loyalty-title {
			padding-left: 10px;
			padding-top: 8px;
			padding-bottom: 8px;
			border-top: 1px solid $night-rider;
			border-bottom: 1px solid $night-rider;

			&.ui-state-active {
				border-bottom: none;
			}

			.title {
                @include ds-heading(h4);
                color: $text-primary;
				text-transform: capitalize;
			}
		}
		.list-of-rewards {
			border-bottom: 1px solid $night-rider;
			@include respond(medium-down) {
				max-width: 100%;
			}
		    .icon-wrapper {
		    	margin-top: 18px;
		    	margin-bottom: 34px;
		    	img {
		    		width: 32px;
		    		height: auto;
		    		margin-left: auto;
		    		margin-right: auto;
		    		display: block;
		    	}
		    	.text {
		    		text-align: center;
		    		margin-top: 10px;
		    		color: $night-rider;
		    		@include font-size(16px);
		    	}
		    }
		}
		.ui-icon{
            float: right;
            position: relative;
            width:20px;
            height:22px;
            @include transition(all 0.5s ease-out);
            background-size: 20px;
            background: url("../images/svg-icons/UI-Carat-bold.svg") no-repeat center;
            top: 4px;
            left: -7px;
        }
        .ui-icon.iconOpen {
            background: url("../images/svg-icons/UI-Carat-bold.svg") no-repeat center;
            transform: rotate(180deg);
        }
	}
    input {
        width: calc(100% - 97px);
        float: left;
        text-transform: uppercase;
        @include respond(large-up) {
            width: 70%;
        }
    }
    .bonus-activate-btn {
        @include ds-utilities-button;
        float: left;

        @include respond(large-up) {
            width: 97px;
        }
    }
    .error-form {
        @include ds-ui-regular(m);
        color: $text-feedback-danger;
        word-break: break-word;
        margin: 10px 0 0;
        padding: 12px 16px;

        &.reset {
            background-color: inherit;
            height: 20px;
        }

        a {
            color: $text-feedback-danger;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .success {
        @include font-bold(14px);
        color: $text-feedback-success;
        background-color: $bg-feedback-success;
        margin-top: 10px;
        padding: 9px 20px;
    }
}

.item-removed-message {
    @include ds-ui-semi(m);
    padding: 11px 20px;
    color: $text-feedback-success;
    background-color: $bg-feedback-success;
    margin-top: 25px;
}

.remove-coupon-dialog.ui-dialog {
    padding: 0;
    .ui-widget-header {
        .ui-dialog-title{
        	border: none;
        	padding:0;
        	margin:0;
        }
    }
    .ui-dialog-content {
        padding: 0 0 19px;
        text-align: center;
        span {
            @include ds-ui-semi(m);
            color: $text-primary;
            display: inline-block;
        }
    }
    .ui-dialog-buttonpane {
    	margin: 0 89px 15px 89px;
    	@include respond(medium-down) {
    		margin: 0 20px 15px 20px;
    	}
        background: none;
        .ui-dialog-buttonset {
        	display: block;
            padding: 0;
            margin: 0;
            border: none;
            @include respond(medium-up){
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            	width: 117px;
            }

            button {
                margin: 0;
                float: none;

                &:first-child {
                    @include ds-utilities-button;
                    margin-right: 0;
                }

                &:nth-child(2) {
                	background: none;
                	border: none;
                	color: $text-primary;
                	text-decoration: underline;
                	text-transform: capitalize;
                	letter-spacing: normal;
                	margin-top: 10px;

                	&:hover {
                		text-decoration: none;
                	}

                	&:active {
                		text-decoration: none;
                	}

                	.ui-button-text{
                        @include ds-ui-regular(m);
                        text-transform: capitalize;
                		padding: 0;
                	}
                }

                @include respond(medium-down) {
                    width: 46%;

                    &:first-child {
                        margin-right: 8%;
                    }
                }
            }

            .ui-button-text {
                padding: 0 26px;
            }
        }
    }
}

.cart-order-totals {
    margin: 22px -15px 0;
    padding: 10px 15px 0;
    box-sizing: border-box;
    background: $bg-color;
    @include respond(large-up) {
        float: right;
        margin: 0;
        width: 53%;
        max-width: 506px;
        padding: 12px 25px 15px;
    }
    .order-total td {
        @include font-bold(16px);
    }
}
.order-totals-table {
    tr {
        td {
            color: $text-primary;
            padding: 10px 0;

            &:first-child {
                text-transform: capitalize;
                @include ds-ui-regular(m);
            }

            &:last-of-type {
                text-transform: uppercase;
                @include ds-ui-semi(m);
            }

            @include respond(large-up) {
                padding-left: 7px;
                padding-right: 7px;
            }

            & + td {
                text-align: right;
            }

            .ship-method {
                text-transform: capitalize;
                @include ds-ui-semi(m);
                color: $text-primary;
            }

            .estimatedtax {
                @include ds-ui-regular(s);
                color: $text-secondary;
                text-transform: none;
                margin: 9px 0 6px;
            }

        }

        &.discount {

            a.text-inline {
                display: inline !important;
            }

            span.details{
                @include ds-ui-regular(s);
                color: $text-secondary;
            }

            td {
                color: $text-feedback-danger;
                text-transform: none;
                & + td {
                    text-align: right;
                }
            }

            &.loyalty-order {
                .discounts-hidden {
                    padding-bottom: 25px;
                }

                a.text-inline {
                    text-decoration: underline;
                    margin-left: 4px;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                td {
                    @include font-size(11px);
                    padding-bottom: 5px;
                }

                &.last td {
                    padding-bottom: 10px;
                }

                td:first-child {
                    color: $night-rider;
                    padding-left: 10px;
                    @include respond(large-up) {
                        padding-left: 17px;
                    }
                }

                &.header td:first-child,
                &.only td:first-child {
                    color: $text-feedback-danger;
                    padding-left: 0;
                    @include respond(large-up) {
                        padding-left: 7px;
                    }
                }
            }
        }

        &.order-total {
            td {
                @include ds-ui-semi(l);
                color: $text-primary;
                border-top: 3px solid $primary-border;
            }
        }
    }
}

.order-value {
    white-space: nowrap;
}

.cart-actions {
    @extend %clearfix;
    .expressbutton {
        display: block;
	    min-height: 48px;
	    width: 180px;
	    background: #FFC439;
	    border: 0;
	    text-align: center;
	    padding: 9px;
	    box-sizing: border-box;
	    margin-top: 0;
	    border-radius: 3px;
        float: left;

        @include respond(large-up) {
            float: right;
        }
        @include respond(medium-down) {
            width: 100%;
        }
        img {
            vertical-align: top;
        }
    }
    &.cart-actions-bottom {
        padding: 10px 15px 25px;
        box-sizing: border-box;
        background: $bg-color;
        button {
            float: right;
            width: 158px;
        }
        @include respond(medium-down) {
            margin: 0 -15px;
            padding: 10px 15px 25px;
            box-sizing: border-box;
            background: $bg-color;
        }
        @include respond(large-up) {
            float: right;
            margin: 0;
            width: 53%;
            max-width: 506px;
            padding: 0 25px 20px;
        }
        p.international-shop, p.cart-legal-message {
            float: left;
            width: 100%;
            color: $dim-gray;
            line-height: 1.2rem;
            margin-top: 1em;
            margin-bottom: 0;
            @include font-size(13px);
            a {
              @include font-size(13px);
            }
        }
    }
}

.cart-action-checkout {
    float: right;
    button {
        @include font-regular-extend(14px);
        background-color: $green;
        border-color: $green;
        @include respond(large-up) {
            @include font-regular-extend(11px);
            width: 158px;
        }
    }
}

.cart-action-continue-shopping {
    float: right;

    button {
        @include ds-utilities-button;
        width: auto;
        margin: auto;
    }

    button.button-text {
        margin-right: 1.0em;
    }
}

.cart-recommendations {
    .product-listing {
        @extend %clearfix;
        background-color: $white-smoke;
        padding: 1rem 0;
        h2 {
            margin-left: 1.3rem;
        }
        .search-result-items {
            margin-top: 1em;
        }
    }
    .cart-footer-content {
        margin: 40px 0 45px;
        @include respond(large-up) {
            margin: 60px 0 0;
        }
        img {
            vertical-align: top;
            max-width: 100%;
        }
    }
}

.cart-actions-top {
    margin-top: 0;
    @include respond(large-up) {
        margin-top: 39px;
        margin-bottom: 16px;
    }
    .cart-action-continue-shopping {
        float: none;
    }
    h2 {
        margin: 0 0 25px;
        display: none;
        @include respond(large-up) {
            display: block;
            float: left;
            margin: 20px 0 0;
            @include ds-heading(h3);
        }
    }
    & ~ .error-form {
        padding: 12px 28px 12px 54px;
        text-align: left;
        position: relative;
        margin: 16px 0 10px;
        @include ds-ui-semi(m);
        color: $text-feedback-danger;

        @include respond(medium-down){
            padding-right: 8.38px;
            margin: 10px 0 0 0;
        }

        img.icon{
            width: 24px;
            height: 24px;
            float: left;
            position: absolute;
            left: 20px;
            top: calc(50% - 12px);
        }
    }
    .checkout-buttons {
    	display: none;
	     @include respond(large-up) {
	     	display: block;
	     }
    }
    & + .item-removed-message {
        margin: 10px 0;
    }
}

.pt_cart {
	.cart-empty {
	    @extend %clearfix;
	    margin-top: 4rem;
	    @include respond(medium-down) {
	        .order-confirm-promo {
	            margin-bottom: 45px;
	        }
	    }
	    h1 {
	        text-align: center;
	        @include respond(medium-down){
	            margin: 0 0 30px;
	        }
	    }
	    .cart-action-continue-shopping {
	        float: none;
	        text-align: center;
	        margin-bottom: 4rem;
	    }
	    .product-listing {
	        @extend %clearfix;
	        background-color: $white-smoke;
	        padding: 1rem 0;
	        h2 {
	            margin-left: 1.3rem;
	        }
	        .search-result-items {
	            margin-top: 1em;
	        }
	    }
	}
    @include respond(medium-down) {
        .item-removed-message ~ .cart-empty {
            margin-top: 1rem;
        }
    }
}

.promo-adjustment {
    color: $sangria;
}

ul.product-availability-list {
    margin: 0;
    padding: 0;
    width: 100%;

    li {
        list-style: none outside none;
    }
    .on-order, .not-available, .low-radial-inventory {
        width: 190px;
        color: $sangria;
        b {
            font-weight: bold;
        }
    }
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }
    .not-user {
        margin: .83em 0;
    }
}

#password-reset {
    white-space: nowrap;
}

.fly-in-wrap {
    display: none;
}

.fly-in-dialog {
    @include respond(medium-down) {
       width: 260px!important;
    }
    .ui-widget-header {
        .ui-dialog-title {
            display: none;
        }
    }
    .dialog-content {
        padding: 44px 30px 35px!important;
        @include respond(medium-down) {
            padding: 22px 17px 19px!important;
        }
    }
    #flyIn {
        h1 {
            @include font-size(24px);
            text-transform: uppercase;
            margin: 0 0 8px;
            @include respond(large-up) {
                @include font-size(29px);
                margin: 0 0 7px;
            }
        }
        .image-wrap {
            float: right;
            max-width: 272px;
            margin-top: 28px;
            padding-right: 30px;
            @include respond(medium-down) {
                margin-top: 27px;
                max-width: 105px;
                padding-right: 8px;
            }
            img {
                max-width: 100%;
                vertical-align: top;
            }
        }
        .info {
            float: left;
            max-width: 110px;
            @include font-size(11px);
            @include line-height(13px);
            @include respond(large-up) {
                max-width: 185px;
                @include font-size(17px);
                @include line-height(25px);
            }
            .desc {
                position: relative;
                width: 175%;
                margin-bottom: 24px;
                @include respond(medium-down) {
                    color: $secondary-color;
                    margin-bottom: 16px;
                }
            }
            .price {
                @include font-size(29px);
                color: $secondary-color;
                margin: 5px 0 17px;
                @include respond(medium-down) {
                    margin-top: 18px;
                    @include font-size(39px);
                }
                sup {
                    font-size: 65%;
                }
           }
        }
        .add-to-cart-wrap {
            float: left;
            @include respond(medium-down) {
                width: 100%;
                margin-top: 17px;
            }
            .button {
                box-sizing: border-box;
                width: 100%;
                @include font-size(12px);
                @include respond(large-up) {
                    width: 119px;
                    padding: 0;
                }
            }
        }
    }
}
.sticky-drawer {
    @include ds-utilities-button-labels;
    width: 100%;
	position: fixed;
	bottom: 15px;
	left: 0;
	display: block;
	z-index: 101;
    transition: bottom 500ms;
	@include respond(large-up) {
		display: none;
	}
	.inner {
		margin: 0 15px;
		background: $night-rider;
		a.drawer-button {
			display: block;
			text-align: center;
			width: 100%;
			color: $white;
			padding-top: 15px;
			padding-bottom: 15px;

			span.arrow {
				display: inline-block;
				background: url("../images/svg-icons/Caret_Bold.svg") no-repeat center;
				background-size: 14px;
			    height: 12px;
			    width: 12px;
			    margin-left: 20px;
			    position: relative;
			    transition: transform 0.5s ease-out;
			}
			&:active {
			    background-color: $white-smoke;
                color: $night-rider;
                span.arrow {
                    background: url("../images/svg-icons/Caret_Bold_Grey.svg") no-repeat center;
                    background-size: 14px;
                }
			}
            &.open {
                span.arrow {
                    transform: rotate(180deg);
                    background: url("../images/svg-icons/Caret_Bold.svg") no-repeat center;
                    background-size: 14px;
                }
            }
            &.open:active {
                span.arrow {
                    background: url("../images/svg-icons/Caret_Bold_Grey.svg") no-repeat center;
                    background-size: 14px;
                }
            }

		}
		.drawer {
			height: 0;
			overflow: hidden;
			transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
            transition: height 500ms;
			&.expanded {
                display: block;
                height: auto;
			}
		}
		.cart-actions {
			margin: 0;
			padding: 15px;
		}
		.order-total {
			@extend %clearfix;
			text-transform: uppercase;
			font-size: 12px;
			color: $black;
			background: $white;
			padding: 15px 20px;

			span {
				float: right;
			}
		}
		button {
			float: none;
			width: 100%;
			margin-top: 15px;
		}
	}
}
.sticky-drawer-bg {
	display: none;
	position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    background: #333;
    height: 2000px;
    width: 100%;
    opacity: .6;
    z-index: 999;
}

.checkout-buttons {
	max-width: 504px;
	width: 100%;
	margin: 0;
	overflow: visible;
	.button, button {
		float: right;
		height: 35px;
		box-sizing: border-box;

        .button-fancy-large button {
            @include ds-utilities-button;
            height: 48px;

            @media screen and (min-width: $mMin) {
            	width: calc(50% - 8px);
            }
        }

		&.dw-apple-pay-button {
			margin-left: 0;
		}
	}

}
.cart-actions-bottom.cart-actions {
	button, .button {
		width: calc(50% - 8px);
		margin: 0;
		margin-left: 25px;

		&.expressbutton {
			margin-left: 0;
			&:hover {
				background-color: $white !important;
			}
		}
        //Remove when A/B test is finalized in tech debt ticket BBWDP-11230
		// &:hover, &:active {
		//   margin-top: 0;
		//   margin-bottom: 0;
		// }
	}
    //Remove when A/B test is finalized in tech debt ticket BBWDP-11230
	&.applepay.smart-button-disabled {
        min-height: 48px;
	    display: flex;
	    flex-direction: column;
	    .checkout-buttons {
	      align-self: flex-end;
	    }
		.button-fancy-large {
			width: 100%;
			margin-left: 0;
			margin-bottom: 16px;
		}
		.expressbutton {
			margin-left: 16px;
		}
		.dw-apple-pay-button {
			margin-left: 0;
		}
		@include respond(large-down) {
    		.button-fancy-large {
    		  font-size: 12px;
    		}
    		.checkout-button-icon {
                display: none;
    		}
		}
		@include respond(medium-down) {
            .button-fancy-large {
              font-size: 16px;
            }
            .checkout-button-icon {
                display: inline-block;
            }
		}
        @include respond(small) {
            .dw-apple-pay-logo-white.dw-apple-pay-border {
                margin-top: rem(12px);
            }
        }
	}
	@include respond(medium-down) {
        //Remove when A/B test is finalized in tech debt ticket BBWDP-11230
		// &:not(.applepay) {
			button, .button {
				width: 100%;
				margin-left: 0;
				margin-bottom: 16px;
				&.expressbutton {
					margin-bottom: 0px
				}
			}
			.checkout-buttons {
				max-width: 100%;
			}
		// }
	}
    //Remove when A/B test is finalized in tech debt ticket BBWDP-11230
	@include respond(medium-up) {
		&.applepay.smart-button-disabled {
			button, .button, .button-fancy-large {
				width: calc(33.333% - 10px);
				margin: 0;
				margin-left: 15px;
				float: right;
				&.dw-apple-pay-button {
					margin-left: 0;
				}
			}
		}
	}
}
.cart-actions {
    @include respond(medium-up) {
        &.cart-actions-bottom {
            button, .button, .button-fancy-large {
                width: calc(50% - 12.5px);
            }
        }
    }
}
.dw-apple-pay-button {
    opacity: 0;
    display: none;
    border-radius: 3px !important;
    min-height: 48px;
}
.dw-apple-pay-button:hover, .dw-apple-pay-button:active {
    //Remove when A/B test is finalized in tech debt ticket BBWDP-11230
    // margin-top: 0 !important;
    // margin-bottom: 0 !important;
    min-height: 48px;
    background-color: $black !important;
    color: $white !important;
    border-color: $black !important;
    background-image: -webkit-named-image(apple-pay-logo-white) !important;
}

.cart-bopis-header {
    background-color: $white-smoke;
    display: flex;
    justify-content: center;
    min-height: 35px;
    margin-top: 10px;
    .content {
        @include ds-heading(h4);
        color: $text-primary;
        margin: 9px 0 9px;
        text-transform: capitalize;
    }
    &-content {
        padding: 13px 0 15px;
        border-bottom: 3px solid $white-smoke;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        @include respond(medium-down) {
            flex-direction: column;
            padding: 30px 14px 18px 16px;
        }
        @include respond(large-up) {
            align-items: flex-start;
        }
        &__shipping {
            position: relative;
            @include ds-heading(h5);
            color: $text-brand-primary;
            padding-right: 30px;
            width: auto;
            box-sizing: border-box;

            b {
                @include ds-heading(h5);
            }

            @include respond(medium-down) {
                width: auto;
                display: flex;
                padding: 0 14px 0 12px;
                margin: 0 auto;
            }
            @include respond(large-up) {
                padding-top: 20px;
                text-align: right;
            }
            .shipping-icon {
                margin-right: 12px;
                position: relative;
                top: 2px;
                background: url(../images/svg-icons/bopis-shipping.svg) no-repeat;
                background-size: 30px 15px;
                width: 30px;
                height: 15px;
                display: inline-block;
            }
        }
        &__pickup {
            border-left: 3px solid $white-smoke;
            position: relative;
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0 0 0 30px;

            @media screen and (min-width: $mMin) {
                flex:none;
            }

            @include respond(medium-down) {
                border-left: none;
                width: auto;
                box-sizing: border-box;
                margin: 0 auto;
                padding: 0 19px 0 22px;
            }
            &.change-store {

                .pickup-items {
                    @include ds-heading(h5);
                    color: $brand-primary;
                    display: inline-block;

                    b {
                        @include ds-heading(h5);
                    }
                }
                .change-store-wrap {
                    @include respond(medium-down) {
                        flex: 1;
                    }
                    .store-details-action {
                        padding: 3px 0;
                        .store-name {

                            @include ds-ui-semi(m);
                            display: inline-block;
                            color: $text-primary;
                            margin: 0;
                            padding-right: 4px;
                            text-transform: capitalize;
                        }
                    }
                }
                .cart-show-bopis-store-detail {
                    display: flex;
                    .cart-show-selected-store-details-link {
                        @include ds-ui-regular(s);
                        color: $text-secondary;
                        display: inline;
                        padding-right: 15px;
                        position: relative;
                        cursor: pointer;
                        &::after {
                            content: '';
                            background: url(../images/svg-icons/UI-Carat.svg) no-repeat center;
                            position: absolute;
                            right: 0;
                            top: 6px;
                            width: 8px;
                            height: 4px;
                            background-size: 8px;
                            @include transition(all 0.5s ease-out);
                            transform: rotate(0);
                        }
                        &.active {
                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .bopis-cart-set-store {
                        a.bopis-set-store {
                            margin-left: 30px;
                            @include respond (medium-down){
                                margin-left: 27px;
                            }
                        }
                    }
                }

                .cart-show-bopis-store-detail-hidden-info{
                    .cart-show-collapsed-info {
                        @include ds-ui-regular(s);
                        color: $text-secondary;
                        padding-top: 15px;
                        .address-city-state-zip {
                            .address-city-state-zip-link {
                                text-decoration: underline;
                                text-transform: capitalize;
                                display: inline-flex;
                                @include night-rider-link;
                            }
                        }
                        .store-phone-no {
                            margin-top: 15px;
                            margin-bottom: 15px;
                            a {
                                @include night-rider-link;
                            }
                        }
                    }
                }
                .pickup-wrap {
                    display: flex;
                    width: 100%;
                    &-container {
                        flex: 1;
                    }
                }
                .pickup-icon {
                    margin-top: 17px;
                    margin-bottom: 10px;
                }
            }
            .content-container {
                display: flex;
                flex-direction: column;
                @include ds-heading(h5);
                color: $text-brand-primary;
                .content-wrap {
                    text-transform: capitalize;
                    padding-bottom: 4px;
                    .new-text {
                        color: $text-brand-accent;
                    }
                }
                .subcontent {
                    @include ds-ui-regular(s);
                    color: $text-secondary;
                    padding-bottom: 4px;
                }
            }
            .bopis-cart-set-store {
                a.bopis-set-store {
                    @include ds-ui-semi(m);
                    color: $text-primary;
                    text-decoration: underline;
                }
            }
            .pickup-icon {
                background: url("../images/svg-icons/bopis-icon-small.svg") no-repeat;
                background-size: 18px 23px;
                width: 18px;
                height: 23px;
                margin-right: 14px;
            }
        }
        &__divider {
            border-bottom: 3px solid $white-smoke;
            border-top: 0;
            border-left: 0;
            margin-top: 25px;
            margin-bottom: 20px;
            width: 100%;
            @include respond(large-up) {
                display: none;
            }
        }
    }
}
.promotion-code-message,
.bopis-message-container {
    background-color: $alice-blue;
    @include ds-ui-semi(m);
    color: $text-feedback-info;
    padding: 12px 30px 12px 15px;
    margin: 10px 0 0 0;
    @include respond(medium-down){
        padding-right: 15px;
    }
}
.promotion-code-removed-message{
    background-color: $bg-feedback-danger;
    @include ds-ui-semi(m);
    color: $text-feedback-danger;
    padding: 12px 30px 12px 15px;
    margin: 10px 0 0 0;
    @include respond(medium-down){
        padding-right: 15px;
    }
}
.out-of-stock-msg{
    background-color: #ffe9e9;
    @include ds-ui-semi(m);
    color: $text-feedback-danger;
    margin: 5px 0;
    padding: 12px 28px 12px 54px;
    text-align: left;
    position: relative;
    margin-top: 10px;
    margin-bottom: 0;
    @include respond(medium-down) {
        padding-right: 7.33px;
    }
    img.icon{
        width: 24px;
        height: 24px;
        float: left;
        position: absolute;
        left: 20px;
        top: calc(50% - 12px);
    }
}

.unsubscribe-error {
	padding-left: 15px;
	a,
    button {
		text-decoration: underline;
		color: #CC0000;
	}

    button {
        display: inline;
        font-weight: inherit;
        font-size: inherit;

        &:hover {
            color: #CC0000;
        }
    }
}

.order-pickup{
    span.order-store-name {
        text-transform: capitalize;
        @include ds-ui-semi(m);
        color: $text-primary;
    }
}

#bopisAlertModal{
    display: none;
    text-align: center;
    padding: 0 48px 25px;
    .change-store-action{
        @include ds-utilities-button;
        display: block;
        margin-bottom: 9.43px;
        &:hover,
        &:active{
            color: $black;
        }
        &-cancel{
            @include ds-ui-regular(m);
            color: $text-secondary;
            text-decoration: underline;
        }
    }
}

.alertmodal{
    @include respond(medium-down) {
        width: 290px !important;
    }
    .ui-dialog-title{
        text-align: center;
        @include ds-heading(h3);
        color: $text-primary;
        border-bottom: none;
        margin: 30.8px 0 8.51px;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 69.8px 0 68.5px;
        box-sizing: border-box;
        text-transform: none;
    }

    .message{
        @include ds-body(m);
        color: $text-secondary;
        margin-top: 3px;
    }

    .ui-dialog-titlebar-close{
        width: 36px;
        height: 35.08px;
        right: 0;
    }

}

.updating-cart-loader {
    display: none;
    position: absolute;
    background: rgba(255,255,255,0.85);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;

    &::before {
        content:"";
        background-image: url('../images/spinner-rewards.gif');
        background-size: 32px 32px;
        width: 32px;
        height: 32px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}

body.updating-cart {
    position: relative;
    .updating-cart-loader {
        display: block;
    }
}
#cart1_rr{
	.horizontal-carousel li{
		max-width: 137px;
	}
}

.og-uib-day button {
    color: #555;
}

.shipping-method {
	.bopis-recurring {
		color: #CC0000;
        font-size: rem(12px);
        display: block;
        padding-top: rem(30px);
	}
}

.cart-coupon-code .my-rewards.my-rewards-bonus {
    .my-bonus-title {
        display: none;
    }
    .reward-desc {
        color: $grey-80;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.16px;
        margin: 0 auto;
    }
    .reward-promotion {
        padding: 10px 40px;
        position: relative;
        margin: 0;
        .ui-icon {
            position: absolute;
            width: 20px;
            height: 22px;
            @include transition(all 0.5s ease-out);
            background-size: 20px;
            background: url("../images/svg-icons/UI-Carat-blue.svg")
                no-repeat center;
            top: calc(50% - 4px);
            left: calc(100% - 30px);
            &.iconOpen {
                transform: rotate(180deg);
            }
        }
        span {
            @include font-size(10px);
            display: block;
        }
        .reward-type {
            color: $hollywood-cerise;

            font-size: 13px;
            margin-bottom: 3px;
        }
        .reward-chanel,
        .reward-title {
            color: $cobalt;

            font-size: 10px;
            margin-bottom: 3px;
        }
        .reward-valid {
            text-transform: none;
            font-size: 12px;
            margin-bottom: 3px;

            &.expire-soon {
                color: $hollywood-cerise;
            }
        }

        .reward-code {
            font-size: 10px;
        }
        @include respond(medium-down) {
            padding: 10px 40px 10px 10px;
        }
    }
    .table {
        display: table;
        height: 0;
        min-height: 64px;
    }
    .table-alignment {
        display: table-cell;
        vertical-align: middle;
    }
    .reward-promotion-redeem {
        padding: 16px;
        align-items: center;
        gap: 16px;
        border: 1px solid $white-smoke;
        margin-top: 16px;
    }

    .bonus-details-link {
        text-decoration: underline;
        font-size: 16px;
    }

    .bonus-promotion {
        display: flex;
        flex-direction: column;

        .bonus-details .reward-title .bonus-heading {
            border-bottom: 0px;
            color: $brand-blue;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.16px;
            padding: 0px 0px 8px;
            margin: 0;
            position: relative;

            .position-icon {
                position: absolute;
                left: 0;
                top: 5px;
            }

            .bonus-details-link {
                text-decoration: underline;
                font-size: 16px;
            }
        }

        .bonus-activate {
            margin: 16px 0;
        }

        .bonus-activated-text {
           display: none;
        }

        .reward-valid {
            font-size: 12px;
            color: $text-tertiary;
            display: flex;
            align-items: baseline;

            @media only screen and (min-width: $mMin) {
                font-size: 16px;
            }

            button {
                font-size: inherit;
                margin-left: 1em;
                color: inherit;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .bonus-activated .bonus-activated-text {
            @include ds-utilities-button-labels;
            align-items: center;
            color: $text-primary;
            column-gap: 8px;
            display: flex;
            height: 48px;
            justify-content: center;

            &::before {
                background-image: url("../images/svg-icons/Success-16-green.svg");
                content: "";
                height: 16px;
                display: block;
                width: 16px;
                background-repeat: no-repeat;
            }
        }

        @media only screen and (min-width: $mMin) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            column-gap: 8px;

            .bonus-heading {
                font-size: 24px;
            }

            .bonus-activate {
                margin: 0;
            }
        }
    }

    .bonus-error {
        display: none;
    }

    .activate-error .bonus-error {
        color: $text-feedback-danger;
        @include ds-ui-regular(s);
        align-items: center;
        column-gap: 4px;
        display: flex;
        justify-content: center;
        margin-top: rem(16px);

        &::before {
            background-image: url("../images/svg-icons/Alert-24-red.svg");
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 24px;
            min-width: 24px;
            width: 24px;
        }
    }
}

/*This is used by the cart merge dismissible toast message, size desktop and large-up are used to prevent overlapping
elements and to make sure that if there are two lines of text the close button is vertically aligned.*/
.toast-message-container {
    text-align: center;
    @include respond(desktop){
        margin: rem(-24px) rem(0px) rem(-24px) rem(0px);
    }

    .toast-message-box {
        padding: rem(16px) rem(16px) rem(16px) rem(16px);
        background-color: $green-10;
        width:90%;
        margin: 0 auto;
        display: flex;

        @include respond(large-up){
            display: inline-block;
            width:unset;
            margin: unset;
        }
    }

    .toast-text {
        font-size: rem(16px);
        font-weight: 600;
        color: $green-70;
        padding: 0px 0px;
        text-align: left;
    }

    .toast-dismiss-btn {
        padding: rem(0px) rem(0px) rem(0px) rem(12px);
        background: none;
	    border: none;
        display: flex;
        align-items: center;

        @include respond(large-up){
            display: block;
            float: right;
        }

        &::before {
            background-image: url("../images/svg-icons/close-without-border.svg");
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 24px;
            min-width: 24px;
            width: 24px;
        }
    }
}

.full-width-btn {
    width: 100%!important;
}

.sticky-drawer-btns, #paypal-checkout {
    #express-pay-container {
        padding-top: 0rem;

        .express-btn-container {
            padding-top: 0rem;
        }
    }

    .cart-legal-message {
        padding-top: 1rem;
    }
}

#express-pay-container {
    padding-top: rem(32px);
    width: 100%;

    .express-btn-container {
        padding-top: rem(32px);
    }

    #paylater-message {
        margin-top: rem(16px);
    }

    .ap-full-width {
        padding-top:rem(16px);
        width: 100%;
        display: inline-block;
        .dw-apple-pay-button {
            width: 100%;
        }
    }

    &.multipay {
        .express-btn-container {
            padding-top: rem(16px);
            width: 100%;
            display: flex;
            align-items: center;
            flex: wrap;

            #pp-btn-container {
                flex-grow: 1;
                flex-basis: 66%;
                padding-top: 3px;
            }

            #ap-btn-container {
                flex-grow: 1;
                flex-basis: 34%;

                .dw-apple-pay-button {
                    width: 95%;
                }
            }
        }
    }
}

.cart-actions.cart-actions-bottom.sticky-drawer-btns {
    .dw-apple-pay-button {
        @include respond(small) {
            margin-top: rem(16px);
        }
    }
    &:not(.applepay) {
        .expressbutton.button {
            width: 100%;
        }
    }
}

.cart-actions.cart-actions-bottom.smart-button-disabled {
    &:not(.applepay) {
        @include respond(medium-down) {
            .button-fancy-large, .expressbutton.button {
                width: 100%;
            }
        }
    }
}

.review-order-totals #cartGiftMessaging {
    margin: 16px 0px 0px 0px;
}

#cartGiftMessaging {
    @include ds-ui-regular(m);
    color: $text-subtle;
    text-align: center;
    margin: 16px 16px;

    @include respond(large-up) {
        text-align: left;
    }

    div {
        vertical-align: top;
        display: inline-block;
    }

    b {
        color: $text-primary;
        font-weight: 700;
    }

    .gift-text {
        max-width: calc(90% - 24px);
        text-align: left;

        div#mockGiftMessage {
            width: 282px;
            padding: 0px 14px;
            margin-top: 10px;
            border: 1px solid $primary-border;
            text-align: center;
            display: block;

            @include respond(large-up) {
                width: 336px;
            }

            .mock-gift-text {
                display: inline;
                text-align: left;

                p {
                    @include ds-ui-regular(m);
                    color: $text-primary;
                    line-height: 12px;

                    b {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .gift-icon {
        width: 21px;
        height: 21px;
        padding-right: 4px;
    }
}
