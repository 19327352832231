@import "tooltip/tooltip";
@import "dialog/dialog";
@import "link-image/link-image";
@import "banner-component/banner";
@import "product-tile/product-tile";
@import "carousel/carousel";
@import "marketingcopy/marketingcopy";
@import "marketingcopy/shopthemodule";
@import "modules/copyblock";
@import "top-offers-tile/top-offers-tile";
@import "link-button/link-button";
@import "link/link";
@import "link-list/link-list";
@import "heading-grid/heading-grid";
@import "video/video";
@import "modules/top-offers";
@import "modules/subs";
@import "modules/other-ways-to-shop";
@import "modules/top-offers-grid";
@import "announcement-banner/announcement-banner";
@import "video/designSystemPlayer";
@import "live-text-image/live-text-image";
@import "button-group/button-group";
@import "visual-navigation/visual-navigation";
@import "search-banner/search-banner";
@import "form-components/input-field/input-field";
@import "form-components/select-field/select-field";
@import "form-components/submit-button/submit-button";
@import "modules/user-info-collection";
@import "modules/modal-dialog";
@import "modules/design-system-footer";
@import "modules/modal-email-collection";
@import "accordion-link-list/accordion-link-list";
@import "grid-tile-component/grid-tile";
@import "modules/international-redirect-modal";
@import "modal/modal";
@import "fulfillment-card/fulfillment-card";
@import "fulfillment-component/fulfillment";
@import "modules/recommendations";
@import "global-promo-banner/global-promo-banner";
@import "modules/single-subs";
@import "shoppable-content/shoppable-content";

.design-system::before {
    content: "";
    clear: both;
    display: table;
}

.ds-grid-container {
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;

    @media screen and (min-width: $mMin) {
        grid-template-columns: repeat(8, 1fr);
        grid-column-gap: 24px;
    }

    @media screen and (min-width: $lMin) {
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 32px;
    }
}

.ds-container {
    box-sizing: border-box;
    line-height: 0;
    margin: auto auto 48px;
    max-width: 1200px;
    padding: 0 16px;
    text-align: center;
}



.ds-heading-exempt {
    text-transform: none;
}

.ds-padding-exempt {
    padding: 0;
}

.ds-max-width-exempt {
    max-width: none;
}

.ds-margin-bottom-exempt {
    margin-bottom: 0;
}

.ds-hide {
    display: none !important;
}

.ds-small-padding-exempt {
    @media screen and (max-width: $sMax) {
        padding: 0;
    }
}

@media screen and (min-width: $mMin) {
    .ds-container {
        margin-bottom: 56px;
    }

    .ds-margin-bottom-exempt {
        margin-bottom: 0;
    }

    .ds-hide-medium-up {
        display: none !important;
    }
}
