.design-system {
    .product-image {
        margin-bottom: 4px;
    }

    .ds-graphic-and-tile-container {
        .product-tile {
            padding-bottom: 0;
    
            .product-outline-block {
                margin-bottom: 8px;
            }

            .product-badge {
                margin: 0 0 8px;
    
                .badge {
                    @include ds-ui-semi(s);
                    text-align: center;
                    color: $text-feedback-info;
                }

                .outofstock {
                    @include ds-ui-semi(s);
                    text-align: center;
                    color: $text-feedback-danger;
                    text-transform: none;
                }
            }

            .product-cont {
                margin: 0 0 8px;
    
                .product-name {
                    @include ds-ui-semi(m);
                    margin: 0;
                    text-transform: none;
                }
    
                .product-type {
                    @include ds-ui-regular(s);
                    margin-top: 0px;
                }
            }

            .product-pricing,
            .product-promo {
                margin: 0;
            }
    
            .product-standard-price {
                @include ds-ui-regular(l);
            }
    
            .product-sales-price {
                @include ds-ui-semi(l);
            }
    
            .product-sales-price, 
            .product-standard-price {
                margin: 0 1px;
            }
    
            .promotional-message {
                @include ds-ui-semi(s);
                margin: 0 0 8px;
                color: $red;
                
                .product-tile-link {
                    @include ds-ui-regular(s);
                }
            }
        }
    }
}
