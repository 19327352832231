//------------------------------------
//  MIXINS
//------------------------------------

// A useful mixin to output a list of CSS styles passed in as a map
//
// Example:
//
//	//set a map variable
//	$primary-nav: (
//		padding-top: .2em,
//		margin-top: .2em,
//		line-height: 1.3,
//	);
//
//	//now output with the mixin
//	@include output-styles($primary-nav);
//
// Compiles to:
//
//	//Outputted CSS:
//	.primary-nav {
//		padding-top: .2em;
//		margin-top: .2em;
//		line-height: 1.3;
//	}
//
@mixin output-styles($map) {
    @each $property, $value in $map {
        #{$property}: $value;
    }
}

//
// Generated a media query based on the given option
// Possible options:
//		print - styles only apply to printing
//		small-down-iphone - styles only apply  to viewports between 320px and 374px
//		small-down - styles only apply to viewports smaller than 480px
//		small - styles only apply to viewports between 320px and 480px
//		medium-down - styles only apply to viewports smaller than 768px
//		medium - styles only apply to viewports between 480px and 767px
//		medium-up - styles only apply to viewports 480px and above
//		large-down - styles only apply to viewports smaller than 959px
//		large - styles only apply to viewports between 768px and 959px
//		large-up - styles only apply to viewports 768px and above
//		desktop - styles only apply to viewports 1024px and above
//

@mixin respond($media, $max: null) {
    @if $media == "print" {
        @media only print {
            @content;
        }
    }
    @else if $media == "small-down" {
        @media only screen and (max-width: $medium-breakpoint - 1) {
            @content;
        }
    }
    @else if $media == "small-down-iphone" {
        @media only screen and (max-width: $small-breakpoint-iphone - 1) {
            @content;
        }
    }
    @else if $media == "small" {
        @media only screen and (min-width: $small-breakpoint) and (max-width: $medium-breakpoint - 1) {
            @content;
        }
    }
    @else if $media == "medium-down" {
        @media only screen and (max-width: $large-breakpoint - 1) {
            @content;
        }
    }
    @else if $media == "medium" {
        @media only screen and (min-width: $medium-breakpoint) and (max-width: $large-breakpoint - 1) {
            @content;
        }
    }
    @else if $media == "medium-up" {
        @media only screen and (min-width: $medium-breakpoint) {
            @content;
        }
    }
    @else if $media == "large-down" {
        @media only screen and (max-width: $desktop-breakpoint - 1) {
            @content;
        }
    }
    @else if $media == "tablet-small" {
        @media only screen and (min-width: $large-breakpoint) and (max-width: $standart-breakpoint - 1) {
            @content;
        }
    }
    @else if $media == "laptop" {
        @media only screen and (min-width: $standart-breakpoint) and (max-width: $desktop-breakpoint) {
            @content;
        }
    }
    @else if $media == "large" {
        @media only screen and (min-width: $large-breakpoint) and (max-width: $desktop-breakpoint) {
            @content;
        }
    }
    @else if $media == "large-up" {
        @media only screen and (min-width: $large-breakpoint) {
            @content;
        }
    }
    @else if $media == "standart" {
        @media only screen and (min-width: $standart-breakpoint) {
            @content;
        }
    }
    @else if $media == "desktop" {
        @media only screen and (min-width: $desktop-breakpoint + 1) {
            @content;
        }
    }
    @else if $media == "hero-crop" {
        @media only screen and (min-width: $hero-crop-width + 1) {
            @content;
        }
    }
    @else if $media == "landing-max" {
        @media only screen and (min-width: $max-width + 60) {
            @content;
        }
    }
    @else if $media == "search-max" {
        @media only screen and (min-width: $max-width + 40) {
            @content;
        }
    }
    @else if type-of($media) == number and $max == null {
        @media only screen and (min-width: $media) {
            @content;
        }
    }
    @else if type-of($media) == number and type-of($max) == number {
        @media only screen and (min-width: $media) and (max-width: $max - 1) {
            @content;
        }
    }
    @else if $media == "mid-large" {
        @media only screen and (min-width: 768px) and (max-width:900px) {
            @content;
        }
    }
    @else if $media == "minWidth-959" {
        @media only screen and (min-width: 901px) and (max-width: 960px) {
            @content;
        }
    }
    @else if $media == "minWidth-960" {
        @media only screen and (min-width: 960px) and (max-width: 1024px - 1) {
            @content;
        }
    }
    @else if $media == "minWidth-1024" {
        @media only screen and (min-width: 1024px) and (max-width: 1024px) {
            @content;
        }
    }
    @else if $media == "minWidth-1025" {
        @media only screen and (min-width: 1025px) and (max-width: 1200px) {
            @content;
        }
    }
	@else if $media == "minWidth-1200" {
        @media only screen and (min-width: 1201px) and (max-width: 1440px) {
            @content;
        }
    }
    @else if $media == "minWidth-1440" {
        @media only screen and (min-width: 1440px) {
            @content;
        }
    }
}

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == vertical {
        // vertical
        background: -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
    }
    @else if $orientation == horizontal {
        // horizontal
        background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
    @else {
        // radial
        background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -o-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -ms-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
}

// Font Sizer
// ex: @include font-size(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-size(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-size($size, $breakpoint: "") {
    @if $breakpoint == "" {
        font-size: $size;
        font-size: calculateRem($size);
    }
    @else {
        // Get a font size in VWs that will match the given pixel font-size in the given viewport.
        font-size: $size;
        font-size: calculateVw($size, $breakpoint);
    }
}

// Line Height Sizer
// ex:  @include line-height(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include line-height(14px, $medium-breakpoint);  //sets a scaling line-height value in vw's with a fixed fallback in pxs

@mixin line-height($size, $breakpoint: "") {
    @if $breakpoint == "" {
        line-height: $size;
        line-height: calculateRem($size);
    }
    @else {
        // Get a font size in VWs that will match the given pixel line-height in the given viewport.
        line-height: $size;
        line-height: calculateVw($size, $breakpoint);
    }
}

// Provides cross-browser CSS opacity. Takes a number between 0 and 1 as the argument. Ex: @include opacity(0.8);
// Note: to reduce code bloat, remove the any unneeded browser support below
@mixin opacity($opacity) {
    $opacity-ie: $opacity * 100;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacity-ie + ")";
    /* IE 5-7 */
    filter: alpha(opacity = $opacity-ie);
    /* Good browsers */
    opacity: $opacity;
}

// inline mixin for visually-hidden elements, when they only need these styles per specific contexts, like viewport.
@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// extensible classes /////////////////

//clearfix extension
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

//menu extension
%menu {
    float: left;
    margin: 0;
    padding: 0;
    li {
        float: left;
        list-style: none outside none !important;
    }
}

//full-bleed extension (using VW's)
%full-bleed-width {
    width: 100vw;
    margin-left: calc(0px - (100vw - 100%) /2);
    //just in case
    @include respond(below-small) {
        width: 100%;
        margin-left: 0;
    }
}

@mixin primary-default-link {
    color: $link-primary-default;
    text-decoration: underline;

    &:hover {
        color: $link-primary-default;
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
        color: $link-primary-default;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }

    &:active {
        color: $click;
        text-decoration: none;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }
}

@mixin night-rider-link {
    color: $night-rider;
    text-decoration: underline;

    &:hover {
        color: $night-rider;
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
        color: $night-rider;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }

    &:active {
        color: $click;
        text-decoration: none;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }
}

@mixin dim-gray-link {
    color: $dim-gray;
    text-decoration: underline;

    &:hover {
        color: $dim-gray;
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
        color: $dim-gray;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }

    &:active {
        color: $click;
        text-decoration: none;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }
}

@mixin error-text-link {
    color: $red;
    text-decoration: underline;

    &:hover {
        color: $red;
        text-decoration: none;
    }

    &:focus {
        color: $red;
        text-decoration: none;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }

    &:active {
        color: $click;
        text-decoration: none;
        outline: #000 dotted 1px!important;
        outline-offset: 2px;
    }
}

// design system typography
@mixin ds-body($size) {
    font-family: $source-sans-pro;
    font-weight: 400;

    @if $size == s {
        @include font-size(14px);
        @include line-height(20px);
        letter-spacing: 0.015em;
    } @else if $size == m {
        @include font-size(16px);
        @include line-height(24px);
        letter-spacing: 0.01em;
    } @else {
        @include font-size(18px);
        @include line-height(28px);
        letter-spacing: 0.005em;
    }
}

@mixin ds-heading($size) {
    font-family: $source-sans-pro;

    @if $size == h1 {
        @include font-size(28px);
        @include line-height(32px);
        font-weight: 700;
        letter-spacing: -0.005em;
    } @else if $size == h2 {
        @include font-size(24px);
        @include line-height(28px);
        font-weight: 700;
        letter-spacing: 0.00em;
    } @else if $size == h3 {
        @include font-size(20px);
        @include line-height(24px);
        font-weight: 700;
        letter-spacing: 0.005em;
    } @else if $size == h4 {
        @include font-size(18px);
        @include line-height(20px);
        font-weight: 700;
        letter-spacing: 0.005em;
    } @else if $size == h5 {
        @include font-size(16px);
        @include line-height(20px);
        font-weight: 700;
        letter-spacing: 0.01em;
    } @else if $size == h6 {
        @include font-size(14px);
        @include line-height(16px);
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.02em;
    }
}

@mixin ds-ui-base($size) {
    font-family: $source-sans-pro;

    @if $size == s {
        @include font-size(14px);
        @include line-height(16px);
        letter-spacing: 0.015em;
    } @else if $size == m {
        @include font-size(16px);
        @include line-height(20px);
        letter-spacing: 0.01em;
    } @else if $size == l {
        @include font-size(18px);
        @include line-height(20px);
        letter-spacing: 0.005em;
    }
}

@mixin ds-ui-regular($size) {
    font-weight: 400;
    @include ds-ui-base($size);
}

@mixin ds-ui-semi($size) {
    font-weight: 600;
    @include ds-ui-base($size);
}

@mixin ds-ui-bold($size) {
    font-weight: 700;
    @include ds-ui-base($size);
}

@mixin ds-utilities-button-labels {
    font-family: $source-sans-pro;
    @include font-size(16px);
    @include line-height(20px);
    font-weight: 600;
    letter-spacing: 0.015em;
    text-transform: uppercase;
}

@mixin ds-utilities-button {
    @include ds-utilities-button-labels;
    align-items: center;
    background-color: $bg-inverse-primary;
    border-color: $bg-inverse-primary;
    box-sizing: border-box;
    color: $white;
    display: flex;
    justify-content: center;
    min-height: 48px;
    padding: 0 32px;
    width: 100%;
    transition-property: background-color, border-color;
    transition-duration: 0.3s;
    transition-delay: ease;

    &:hover , &:focus {
        background-color: $bg-inverse-secondary;
        border-color: $bg-inverse-secondary;
    }

    &:active {
        background-color: $bg-tertiary;
        border-color: $border-secondary;
    }
}

@mixin ds-utilities-button-outline {
    @include ds-utilities-button;
    background: $bg-primary;
    border: 2px solid $border-dark;
    color: $text-primary;

    &:hover , &:focus {
        background: $bg-primary;
        border-color: $border-secondary;
        color: $text-secondary;
    }

    &:active {
        background-color: $bg-secondary;
        border-color: $border-secondary;
        color: $text-secondary;
    };
}

@mixin ds-utilities-button-minimal {
    @include ds-utilities-button;
    background: $bg-primary;
    border: 0 none;
    color: $text-primary;

    &:hover , &:focus {
        background: $bg-primary;
        color: $text-secondary;
    }

    &:active {
        background-color: $bg-secondary;
        color: $text-secondary;
    };
}


@mixin ds-display-heading($size) {
    font-family: $source-sans-pro;
    font-weight: 700;

    @if $size == 1 {
        @include font-size(64px);
        @include line-height(72px);
        letter-spacing: -0.015em;
    } @else if $size == 2 {
        @include font-size(48px);
        @include line-height(56px);
        letter-spacing: -0.01em;
    } @else if $size == 3 {
        @include font-size(32px);
        @include line-height(36px);
        letter-spacing: -0.005em;
    }
}

@mixin center($position: absolute) {
    position: $position;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
// global-use classes ////////////////////

.clearfix {
    @extend %clearfix;
}

.visually-hidden {
    @include visually-hidden;
}

.menu {
    @extend %menu;
}

.full-bleed-width {
    @extend %full-bleed-width;
}
