// LIGHTBOX STYLING
.global-email-dialog {
    .ui-dialog-titlebar {
        height: 0;
        position: relative;
        width: 100%;
        .ui-dialog-title {
            border: none;
            height: 0;
            margin: 0;
            padding: 0;
        }
        .ui-dialog-titlebar-close {
            position: absolute;
            right: 0 !important;
            top: 0;
        }
    }
    .ui-dialog-content {
        padding: 0;
        width: 570px !important;
        height: 315px !important;
        overflow: hidden;
        @media screen and (max-width: 767px) {
            width: 100% !important;
            height: 345px !important;
        }
    }
}
// HOME BOTTOM SLOTS
.home-bottom-slots {
    .home-bottom-center,
    .home-bottom-right {
        max-width: 1470px;
        width: 100%;
    }
}
// WALLET CHANGE
.mywallet-page .my-rewards h3.my-rewards-title {
    text-transform: none;
}
