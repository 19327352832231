#bonus-product-list {
    .bonus-product-item {
        @extend %clearfix;
        border-bottom: 1px solid $primary-border;
        @include respond(medium-down) {
            padding-bottom: 20px;
        }
        .product-col-1 {
            float: left;
            clear: none;
            @include respond(medium-down) {
                width: 40%;
            }
        }
        .product-col-2 {
            float: left;
            clear: none;
            @include respond(medium-down) {
                width: 60%;
            }
            .product-name {
                float: none;
                @include font-size(16px);
                line-height: 1;
                margin-bottom: 9px;
            }
            .product-add-to-cart {
		        padding-bottom: 0;
		        .inventory {
                    width: auto;
                    position: relative;
                    padding-bottom: 18px;
		            .quantity-button {
						@include line-height(33px);
						@include font-size(18px);
						width: calculateRem(33px);
						padding: 0;
		            }
		            input {
			            height: calculateRem(35px);
	                    width: calculateRem(35px);
		            }
                    .quantity-error {
		                color: $red;
		                text-transform: inherit;
		                position: absolute;
		                bottom: 0;
		                left: 0;
		                margin: 0;
		                @include font-size(11px);
                    }
		        }
		         .select-bonus-item {
                    height: calculateRem(35px);
					width: calculateRem(130px);
					@include font-size(11px);
					@include respond(medium-down) {
					   width: calculateRem(100px);
					}
					.touch & {
                        &:active {
		                    color: $night-rider;
		                    border-color: $white-smoke;
		                    background-color: $white-smoke;
                        }
                    }
		        }
		    }
        }
        .product-col-1,
        .product-col-2 {
            @include respond(small-down) {
                width: 100%;
            }
        }
    }
    .bonus-product-list-footer {
        @extend %clearfix;
        padding: 10px 0;
        .add-to-cart-bonus {
            box-sizing: border-box;
            width: 100%;
            @include font-size(14px);
            @include respond(large-up) {
                float: right;
                @include font-size(11px);
                width: 135px;
            }
            
        }
    }
    .selected-bonus-item {
        margin: 5px 0 5px 20px;
        position: relative;
        .item-name {
            color: $primary-color;
            @include font-size(14px);
            text-transform: uppercase;
        }
        .remove-link {
            cursor: pointer;
            color: $sangria;
            left: -20px;
            top: 4px;
            position: absolute;
            width: 16px;
            height: 16px;
            display: block;
            background: url("../images/svg-icons/UI-Close-dark.svg") no-repeat center;
        }
    }
}

.bonus-discount-container {
    .promo-details {
        display: none;
        &.visible {
            display: block;
        }
    }
}
