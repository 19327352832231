#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;
    div {
        float: left;
        text-align: center;
        width: 77px;
    }
    img {
        max-height: 100%;
        max-width: 80%;
    }
    span {
        display: block;
        height: 65px;
    }
    .divided span {
        border-right: 1px solid $gainsboro;
        padding-right: 5px;
    }
    @include respond(medium-down) {
        bottom: 0;
        width: 100%;
        div {
            width: 60px;
            &.product-next {
                float: right;
            }
        }
        .divided span {
            border: 0;
        }
    }
}

.main-image {
    display: block;
}

.primary-image {
    max-width: 100%;
}

.slide-cont {
    .owl-nav{
        button {
            width: auto;
            height: auto;
            top: 50%;
            background: none;
            z-index: 2;
            border: none;

            span {
                width: 20px;
                height: 30px;
                color: $secondary-color;
                background-color: transparent;
                background-size: 11px;
            }

            &:hover {
                border: none !important;
                background: none !important;
            }

            &.owl-prev,
            &.owl-prev:hover {
                span {
                    left: 15px;
                    background: url("../images/svg-icons/PDP-Arrow-2.svg") no-repeat center;
                    background-color: transparent;
                }
            }

            &.owl-next,
            &.owl-next:hover {
                span {
                    right: 15px;
                    background: url("../images/svg-icons/PDP-Arrow-1.svg") no-repeat center;
                    background-color: transparent;
                }
            }
        }
    }

    .owl-dots {
        text-align: left;
        margin: 0;
        .owl-dot {
            margin-right: calculateRem(10px);
            padding-top: calculateRem(10px);
            width: calculateRem(50px);
            height: calculateRem(57px);
            opacity: 0.5;
            &.active {
                opacity: 1;
            }
        }
    }

    #thumbnails{
        @include respond(medium-down) {
            display: none;
        }
        margin: 0 auto;
        width: calc(100% - 60px);
        &.owl-carousel .owl-controls {
            .owl-nav{
                button {
                    top: 35%;
                    &.owl-prev {
                        left: -10px;
                    }
                    &.owl-next {
                        right: -10px;
                    }
                }
            }
        }
        .owl-item{
            @include opacity(0.5);
            cursor: pointer;
            &.current{
                @include opacity(1);
            }
        }
    }
}
.rating {
    color: $rain-forest;
    .product-info & {
        font-size: 2em;
    }
}
.bv_button_buttonFull {
    line-height: calculateRem(22px);
    transition: none;
}
.product-variations, .product-options {
    margin-bottom: calculateEm(20px);
    .attribute {
        margin: calculateEm(10px) 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        h3,
        .label {
            @include font-size(11px);
        }
        .value {
            width: 100%;
            @include respond(large-up) {
                width: 75%;
            }
        }
        ul {
            float: right;
            width: 100%;
        }
        .selected-value {
            border: 0 none;
            padding: calculateRem(5px) 0 0 calculateRem(10px);
        }
        .size-chart-link {
            clear: both;
            float: none;
            a {
                border-color: $white !important;
                padding: 0;
            }
        }
    }
    .swatches li {
        float: left;
        &:hover, &.selected {
            a {
                border-color: $citrus;
            }
        }
        &.selectable {
            @include opacity(0.5);
            &.selected{
                @include opacity(1);
            }
        }
        &.unselectable {
            a {
                color: $gainsboro;
                @include opacity(0.8);
            }
            &:hover a {
                border-color: $gainsboro;
            }
        }
        a {
            display: block;
            line-height: calculateRem(33px);
            min-height: calculateRem(33px);
            min-width: calculateRem(33px);
            margin: 0 calculateRem(5px) calculateRem(5px) 0;
            text-align: center;
            white-space: nowrap;
        }
    }
    .color li {
        a {
            line-height: 0;
            width: calculateRem(33px);
            height: calculateRem(33px);

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.product-set .product-add-to-cart {
    display: block;
}

.pdp-main {
    @extend %clearfix;
    overflow: hidden;
    @include respond(large-up){
        #QuickViewDialog & {
            margin-top: 5px;
        }
    }
    .product-top-banner {
        img {
            vertical-align: top;
            width: 100%;
            margin-bottom: calculateRem(24px);
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        width: auto;
    }
    .product-cols-wrap{
        max-width: 767px;
        margin: auto;
        @include respond(medium-down){
            padding: 0 15px;
        }
    }
    .product-col-1 {
        float: left;
        width: 350px;
        margin-right: 1.9%;
    }
    .product-primary-image {
        max-width: 100%;
        padding: 0;
        text-align: center;
        position: relative;
        .product-image.main-image{
            line-height: 0;
            cursor: default;
        }
        .gift-card-image{
        	position: relative;
        	right: 0;
        	top: 0;
        }
        @include respond(medium-down){
            max-width: 350px;
            margin: auto;
        }
        a {
        	position: absolute;
        	right: 15px;
        	top: 18px;
        	overflow: visible;
        	@include respond(medium-down){
                right: 16px;
                top: 15px;
            }
            a {
            	position: unset;
            }
        }
        .wish-list-link {
        	display: block;
            position: relative;
            width: 38px;
            height: 36px;
            font-size: 0;
            float: right;
            cursor: pointer;
            opacity: 1;
            background: url("../images/svg-icons/PDP-Wishlist-2.svg") no-repeat center;
            z-index: 101;
            &.simple{
                &:hover {
                    background: url("../images/svg-icons/PDP-Wishlist-3.svg") no-repeat center;
                }
            }
            &.disabled {
                background: url("../images/svg-icons/PDP-Wishlist-1.svg") no-repeat center;
                &:hover {
                    background: url("../images/svg-icons/PDP-Wishlist-3.svg") no-repeat center;
                }
            }
            &.hidden {
                display: none;
            }
            &.remove-from-wish-list {
                background: url("../images/svg-icons/PDP-Wishlist-4.svg") no-repeat center;
                &:hover {
                    background: url("../images/svg-icons/PDP-Wishlist-5.svg") no-repeat center;
                }
                &.hidden + .wish-list-added{
                    display: none;
                }
            }
        }
        button.wish-list-link{
            border:none;
            position: absolute;
            right: 0;
        }
        .wish-list-guest-message, .wish-list-added{
            display: none;
            position: absolute;
            top: 55px;
            right: -15px;
            border: 1px solid $primary-border;
            background: white;
            width: 264px;
            padding: 10px 12px;
            z-index: 102;
            @include borderbox;
            .wish-list-image{
                background: url("../images/svg-icons/PDP-Wishlist-4.svg") no-repeat center;
                margin: auto;
                height: 70px;
            }

            p {
                @include ds-ui-regular(m);
                color: $text-secondary;
                margin: 10px 0;

                a {
                    @include ds-ui-regular(m);
	                position: unset;
	        		right: auto;
	        		top: auto;
	        		overflow: hidden;
                    color: $text-primary;
        		}
                a.button{
                    @include ds-utilities-button-labels;
                    color: $text-inverse;
                    text-decoration: none;
                    display: inline-flex;
                    height: 48px;
                    max-height: none;
                    align-items: center;
                }
            }
        }
        .wish-list-added{
            display:block;
            min-width: 240px;
            top: 53px;
            cursor: default;
            text-transform: none;

            &.hover{
                top: 36px;
                display: none;
                min-width: 275px;
            }
            &.justadded{
                display: none;
                top: 36px;
            }
            .wish-list-image{
                height: 40px;
                width: 40px;
                float: left;
                margin-right: 10px;
            }
        }
        .navigation{
            span{
                width: 20px;
                height: 30px;
                color: $secondary-color;
                position: absolute;
                top: 45%;
                display: none;
                cursor: pointer;
            }
            .prev{
                left: 15px;
            }
            .next{
                right: 15px;
            }
            .icon{
                width: 15px;
                height: 30px;
            }
        }
    }
    .product-info {
        clear: both;
        padding-top: 1em;
        // Height for PDP Tabs
        @include respond(large-up) {
            min-height: 25em;
        }

        ul {
            font-size: .8rem;
            list-style: square;
            @include respond(large-up) {
                padding-bottom: 1em;
                padding-top: 1em;
            }
        }
    }
    .product-col-2 {
        float: right;
        width: 51.5%;
        max-width: 470px;
        .brand-tlt {
            @include ds-ui-regular(m);
            color: $text-primary;
            padding-top: 0;
            text-transform: none;
        }
        @include respond(large-down) {
            width: 48.5%;
        }
        .price-standard {
            padding-right: .5rem;
            & + .price-sales {
                color: $text-brand-accent;
            }
        }
    }
    h1 {
        .brand-tlt {
            line-height: 1;
            padding-bottom: 0.3125rem;
        }
    }
    .pdp-agent-ats {
        color: $hollywood-cerise;
    }
    .small-title{
        @include ds-ui-regular(s);
        color: $text-secondary;
        padding: calculateEm(7px) 0 calculateEm(5px);
    }

    #product-content {
        margin-top: calculateEm(17px);
    }

    .product-name {
        margin: 0 0 4px;
        border: none;
        @include ds-heading(h2);
        color: $text-primary;
        padding-bottom: 0;
        text-transform: capitalize;

        @include respond(large-up) {
            margin: 1px 0 -4px;
        }
    }
    #product-content {
        .small-title {
            padding: 0px 0 14px;
        }
        .pdpslot {
            width: 270px;
            margin: 20px 0;
        }
        .details-view {
        	&:focus {
        		outline-offset: -1px;
        	}
        }
    }
    .rating {
        margin: 0 0 9px;
        &.guest {
            .bv-cv2-cleanslate {
                .bv-primarySummary-rating-container {
                    .bv-write-review{
                        display: none!important;
                    }
                }
            }
        }
    }

    .product-price {
        @include ds-ui-semi(l);
        color: $text-primary;
        margin-bottom: 10px;

        .price-standard {
            text-decoration: line-through;
        }

        .price-tiered {
            color: $sangria;
            @include font-size(14px);
        }
    }
    .product-review {
        @extend %clearfix;
        margin-bottom: .5rem;
        &.no-reviews {

        }
    }
    .product-main-attributes {
        .attribute {
            padding: .2em 0;
        }
        .label {
            padding: 0;
        }
    }
    form{
	    &.pdpForm{
		    span{
		    	&.error{
		        	padding-left: 0;
		    	}
		    }
	    }
	}
    .product-add-to-cart {
        margin: 0;
        padding: 8px 0;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        .inventory, .add-to-cart {
            width: calc(50% - 10px);
            -webkit-transition-property: none;
            -moz-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
        }

        .inventory {
            float: left;
            white-space: nowrap;
            position: relative;
            margin-right: 8px;
            height: 44px;
            border: 2px solid $bg-tertiary;

            input, .qty .quantity-button {
                background-color: $white;
                height: 44px;
            }

            input {
                @include ds-ui-semi(m);
                color: $text-primary;
                text-align: center;
                padding: 0;
                float: left;
                height: calculateRem(44px);
                width: 60%;
                border: none;

                &[disabled] {
                    color: $click;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
            .quantity {
            	.quantity-field {
		        	span, input {
		        		&:focus {
							outline-offset: -1px;
						}
					}
				}
            }
            .quantity-button {
                -webkit-tap-highlight-color: rgba(0,0,0,0);
                @include ds-ui-semi(m);
                align-items: center;
                background: $bg-color;
                color: $text-primary;
                display: flex;
                float: left;
                height: 44px;
                justify-content: center;
                text-align: center;
                width: 20%;

                &.increase{
                    float: right;
                }
            }

            .qty {
                .quantity-button {
                    @include ds-ui-semi(m);
                    color: $text-primary;

                    cursor: pointer;
                    body:not(.touch) & {
                        &:hover {
                            color: $white;
                            border-color: $night-rider;
                            background-color: $night-rider;
                        }
                        &:active {
                            color: $night-rider;
                            border-color: $light-gray;
                            background-color: $white;
                        }
                    }

                    &:active {
                        color: $white;
                        border-color: $night-rider;
                        background-color: $night-rider;
                    }

                    &.disabled {
                        color: $text-disabled;
                        pointer-events: none;
                    }
                }

                input {
                    &:active, &:focus {
                        border-color: $gainsboro;
                        background-color: $white;
                    }
                    &:hover {
                        border-color: $white-smoke;
                        background-color: $white;
                        -webkit-tap-highlight-color: rgba(0,0,0,0);
                    }
                }
            }
            span.error{
                position: absolute;
                top: 64px;
                left: -10px;
                b{
                    font-weight: bold;
                }
                @include respond(large-up){
                    top: 47px;
                    background: $white;
                    width: 300px;
                }
            }
        }
        .availability-web{
            label{
                display: none;
            }
        }
        button {
            @include ds-utilities-button-labels;
            color: $text-inverse;
            float: left;
            height: 48px;
            max-height: none;
            white-space: nowrap;
            width: calculateRem(135px);

            &:focus {
				outline-offset: 1px;
			}
            .touch & {
                &:active {
                    color: $night-rider;
                    border-color: $white-smoke;
                    background-color: $white-smoke;
                }
            }
            &[disabled] {
                opacity: 1;
                color: $click;
                border-color: $white-smoke;
                background-color: $white-smoke;
            }
        }

        .loyalty-exclusive-button {
            width: auto;
        }

        @include respond(small-down) {
            &.sticky {
                background-color: $white;
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                z-index: 100;

                .inventory, .add-to-cart {
                    width: calc(50% - 6px);
                }

                .loyalty-exclusive-button {
                    width: 100%;
                    box-shadow: inset 0px 48px $secondary-color;
                    background-color: $white;
                    border: none;

                    &:active {
                        box-shadow: none;
                    }
                }

                &.translateY {
                    transform: translateY(100%);
                }
            }
        }
    }
    .availability-web{
        margin-top: 2px;
        padding: 0;
        .lowinventory{
            margin-bottom: 15px;
            color: $sangria;
            @include font-size(12px);
            b {
                font-weight: bold;
            }
        }
    }
    .product-actions {
        @extend %clearfix;
        padding: 29px 0 9px;
        @include respond(large-up) {
            padding: 17px 0 3px;
        }
        .socialsharing {
            float: none;
            text-align: center;
        }
        a {
            margin: 0 2%;
            &.share-icon {
                background: $nobel;
                display: inline-block;
                height: 28px;
                width: 28px;
                border-radius: 14px;
                margin: 0 16px;
                .icon {
                    height: 18px;
                    width: 18px;
                    position: relative;
                    top: 5px;
                }
                &:active {
                    opacity: 0.6;
                }
            }
            &.facebook {
                &:hover {
                    background: $facebook;
                }
            }
            &.twitter {
                &:hover {
                    background: $twitter;
                }
            }
            &.instagram {
                &:hover {
                    background: $instagram;
                }
            }
            &.pinterest {
                &:hover {
                    background: $pinterest;
                }
            }
        }
    }
    .recommendations.promo-block {
        clear: left;
        padding: 1em 0;
        margin-top: 2em;
        border-top: 1px solid $secondary-color;

        &.social-commerce-container {
            display: none;

            button {
                max-height: fit-content;
            }
        }

        h2 {
            @include font-size(24px);
            text-align: center;
        }
        ul {
            list-style-type: none;
            position: relative;
        }
        li {
            float: left;
        }
        .owl-carousel{
            .owl-stage-outer{
                height: auto !important;
            }
        }
        .recommendation_image {
            img {
                width: 100%;
            }
        }
        .product-name {
            margin: 0px 0 5px;
            width: 100%;
        }
        .product-price {
            margin: 5px 0 15px;
            @include font-regular(14px);
            .price-standard {
                & ~ .price-sales {
                    color: $red;
                }
            }
        }
        .promotion {
            overflow: inherit;
            .promotion-callout {
                width: 100%;
                p {
                    a {
                        display: none;
                    }
                }
                .callout-message {
                    margin-bottom: 5px;
                    @include font-size(14px);
                    p{
                        @include font-regular(11px);
                    }
                }
            }
        }
        .sub-attribute {
            @include font-size(11px);
        }
        .product-tile:hover .recommendation-tooltip {
            display: block !important;
        }
        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }
    }
    .recommendations.promo-block.social-commerce-container {
        padding: 2.25em 0;
    }
    .promotion {
        overflow: hidden;
        margin-bottom: 12px;
        @include respond(large-up) {
            margin-bottom: 12px;
        }
        .promotion-callout {
            @include ds-ui-semi(l);
            color: $text-brand-accent;
            width: 79%;

            p {
                 margin: 0;
                 color: $text-brand-accent;
            }

            .tooltip {
                @include ds-ui-semi(s);
                color: $text-secondary;

                @include respond(large-up) {
                    @include font-size(14px);
                }
                .tooltip-content {
                    width: 70%;
                }
            }
             .callout-message {
                 display: block;
                 margin-bottom: -4px;
            }
        }
    }
    .product-set-list {
        border-top: 1px solid $gainsboro;
        margin: 0 0 0 6px;
        .product-set-item {
            border-bottom: 1px solid $gainsboro;
            clear: both;
            margin: 1em 0;
            overflow: hidden;
            padding: 1em 0 0;
        }
        .product-set-image {
            float: left;
            width: 22%;
        }
        .product-set-details {
            float: left;
            width: 78%;
        }
        .attribute {
            h3, .label {
                width: 25%;
            }
        }
        .product-name {
            float: none;
            font-size: 1.3em;
            margin: 0;
            width: auto;
        }
        .block-add-to-cart {
            background: none repeat scroll 0 0 $white-smoke;
            clear: both;
            overflow: hidden;
            padding: .5em 0;
            .inventory {
                float: left;
                margin-left: 22%;
                width: 20%;
            }
            label {
                padding: .7em 0;
                text-align: left;
                width: 40%;
            }
            .input-text {
                width: 35px;
            }
            .product-price {
                float: left;
                font-size: 1.6em;
                margin: 0;
                padding: 6px 0 0;
                text-align: right;
                width: 35%;
            }
            button {
                float: right;
                font-size: .9em;
                margin: 3px 5px 0 0;
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }
    @include respond(medium-down) {
        .product-col-1, .product-col-2 {
            clear: both;
            float: none;
            margin: 10px 0 0;
            width: 100%;
            max-width: none;
        }
        .product-col-1 {
            margin: 0;
        }
        .promotion .promotion-title {

        }
        .attribute {
            span.value {
                padding-top: 5px;
            }
        }
        .product-add-to-cart {
            .product-price {
                font-size: 2em;
                padding: 30px 10px;
                text-align: left;
                width: 100%;
            }
        }
        .product-set-list {
            .product-set-image img {
                max-width: 90%;
            }
            .block-add-to-cart {
                .inventory {
                    margin: 0;
                    width: 23%;
                }
                .product-price {
                    font-size: 1em;
                    width: 44%;
                }
                button {
                    font-size: .7em;
                }
            }
        }
        .product-review-links {
            margin-left: 6%;
        }
        .prSnippetTitle, .prSnippetRatingUnclickable {
            float: left;
        }
    }

    @include respond(large) {
        .product-set-list .block-add-to-cart button {
            font-size: .9em;
            margin-top: 3px;
        }
    }
    .review{
        border-top: 1px solid $secondary-border;
        margin-top: 2em;
        padding-top: 1em;
    }
    h1 span.small-title {
        @include ds-ui-regular(m);
        color: $text-secondary;
        display: block;
        text-transform: none;
        @include respond(large-up) {
            padding: 0 0 .3125em;
        }
    }
}

.js .pdp-main .recommendations .product-tile .recommendation-tooltip {
    display: none !important;
}

.js button.add-all-to-cart {
    display: inherit;
}

@include respond(medium-down) {
    .pt_product-details {
        .primary-content {
            padding-bottom: 68px;
        }
    }
}

.size-guide-select-link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .size-guide-select-link {
        color: $text-primary;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .size-guide-link {
        text-decoration: underline;
    }

    .select-size-header {
        display: flex;
        align-items: center;
    }

    .selected-size-header-text {
        padding-left: 5px;
    }
}

.size-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    margin: 10px 0 16px 0;

    .size-button {
        background-color: $base;
        border: 1.5px solid $border-feature;
        border-radius: 2px;
        color: $text-primary;
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 0.21px;
        height: 40px;

        &.selected {
            border: 2px solid $border-dark;
        }

        &.sold-out {
            cursor: not-allowed;
            position: relative;
            pointer-events: none;
            color: $grey-30;
            overflow: hidden;

            .slash {
                position: absolute;
                top: 50%;
                left: 0;
                width: 102%;
                height: 2px;
                background-color: $grey-30;
                transform-origin: center;
                transform: rotate(-15deg);
            }
        }
    }
}

.productpromo {
    margin: 13px 0;
    @include respond(large-up) {
       margin-top: 8px;
    }
    img {
        height: 39px;
        @include respond(large-up) {
            height: 35px;
        }
    }
}

.pdp-accordion {
    margin: 13px 0 15px;
    border-bottom: 1px solid $primary-border;
    h3.ui-accordion-header{
        @include ds-heading(h4);
        color: $text-primary;
        text-transform: capitalize;
        padding: 20px 14px;
        border-top: 1px solid $primary-border;

        .ui-icon{
            float: right;
            position: relative;
            width:20px;
            height:22px;
            @include transition(all 0.5s ease-out);
            background-size: 20px;
            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
            top: 7px;
            left: -7px;
        }
        .ui-icon.iconOpen {
            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
            transform: rotate(180deg);
        }
    }

    .ingredient-tab {
        overflow: visible;
    }

    div.ui-accordion-content {
        @include ds-body(m);
        color: $text-secondary;
        padding: 0 60px 20px 14px;

        .ingredient-links {
            padding-inline-start: 0;

            a {
                color: $text-feedback-info;
                margin-bottom: 20px;
                text-decoration: underline;

                @include respond(medium-down) {
                    margin-bottom: 16px;
                }
            }

            li {
                height: 38px;
                line-height: 18px;
                list-style: none;

                @include respond(medium-down) {
                    height: 36px;
                }
            }
        }

        p,
        a,
        ol li,
        ul li,
        h4 {
            @include ds-body(m);
            color: $text-secondary;
        }

        ol, ul{
            padding: 15px;
        }
        ul{
            li {
                list-style: disc;
            }
        }
    }
}

#shipping-restriction-accordion {
    border-bottom: none;
    .ui-accordion-header {
		background: none;
		border:0;
		color:$night-rider;
		display: inline-block;

		font-size: 12px;
		line-height: 24px;
		margin:0;
		padding:2px 2px 2px 0;
		text-transform: capitalize;
        .ui-icon {
			background: url(../images/svg-icons/UI-Carat.svg) no-repeat center;
			position: absolute;
			left: 100%;
			top: 21px;
			width: 8px;
			height: 4px;
			background-size: 8px;
			&.iconClosed{
				transform: rotate(180deg);
			}
		}
    }
    .ui-accordion-content {
        @include font-size(12px);
        padding: 8px 0 0;
		p{
			font-size: 12px;
			margin:0;
			padding:0;
		}
    }
}

/*bazaarvoice styles*/
#product-content .bv-cv2-cleanslate  .bv-primarySummary-rating-container{
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.pdp-ingredients {
    margin-bottom: 50px;

    a {
        @include ds-ui-regular(m);
        color: $text-primary;
        text-decoration: underline;
        margin-left: 14px;
        &:hover {
            text-decoration: none;
        }
        &:active {
            text-decoration: none;
        }
    }
}

#QuickViewDialog .pdp-ingredients a {
	margin-left: 0px;
}

.boipsrefinement{
    width: 100%;
    display: flex;
    clear: both;
    padding: 16px;
    background: $bg-secondary;
    box-sizing: border-box;

    @include respond(standart) {
        padding: 16px;
    }

    .bopisgrid_headings {
        display: flex;
        align-items: center;
        @include respond(standart) {
            display: flex;
            align-items: center;
        }

        .bopis-txt {
            display: flex;
            align-items: center;
            &::before {
                content: '';
                background: url(../images/svg-icons/bopis-icon-small.svg);
                background-size: 15px 19px;
	            width: 15px;
                height: 19px;
                display: inline-block;
                margin-right: 8px;
                @include respond(standart) {
                    background-size: 20px 23px;
                    width: 20px;
                    height: 23px;
                    margin-right: 8px;
                }
            }

            .pick-up-in-store {
                @include ds-heading(h5);
                color: $text-brand-primary;
                text-transform: capitalize;
            }
        }

        .selected-store-container {
            @include ds-ui-regular(m);
            color: $text-primary;
            .selected-store {
                @include ds-ui-semi(m);
                margin-right: 5px;
                text-transform: capitalize;
                @media only screen and (min-width: $lMin) {
                    margin: 0 5px 0 0;
                    vertical-align: bottom;
                }
            }

            .choose-store {
                white-space: nowrap;
                @include night-rider-link;
                @include ds-ui-semi(m);
                color: $text-primary;
                text-transform: capitalize;

                &.storename-chosen {
                    display: flex;
                    align-items: flex-end;

                    &:after {
                        content: "";
                        background: url(../images/svg-icons/chevron.svg) no-repeat center;
                        margin-left: 8px;
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }

    }

    .bopisgrid_wrapper {
        display: flex;

        &.hide {
            display: none;
        }

        @include respond(standart) {
            flex-direction: row;
            margin-left: 30px;
            align-items: center;
            padding-left: 0;
        }

        .show-availability-text {
            @include ds-ui-semi(m);
            color: $text-primary;
            margin-bottom: 0;
            white-space: nowrap;
            @include respond(standart) {
                margin-right: 8px;
            }
        }

        .toggle-on-off-icon {
            margin: 0 8px 0 0;
         }

        .show-availability-label {
            @include respond(standart) {
                margin: 0;
            }

            .sr-only {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0,0,0,0);
                border: 0;
            }
        }
    }

    &.refinement-bar-without-store {
        .bopisgrid_headings {
            display: flex;
        }

        .selected-store-container {
            margin-left: 5px;
            align-self: center;
        }

        .bopis-txt {
            margin-left: 7px;

            @include respond(large-up) {
                margin-left: 0;
            }
        }
    }

    &.refinement-bar-with-store {
        .selected-store-container {
            margin-left: 5px;
            @include respond(standart) {
                margin: 0 0 0 5px;
            }
        }

        .show-availability-label {
            margin-top: 2px;
        }
    }

    @media only screen and (max-width: 960px) {
        display: block;

        .bopisgrid_wrapper {
            margin: 12px 0 0 0;
            align-items: center;
        }

        .show-availability-label {
            margin: 0;
        }
    }
}

.no-bopis-item-container {
    text-align: center;
    padding: 2px;
    margin-top: 20px;
    margin-bottom: 122px;

    @include respond(large-up) {
        margin-top: 46px;
    }

    .no-items-available {
        color: $night-rider;

        font-size: 16px;
        letter-spacing: -0.23px;
        line-height: 19px;
        text-align: center;
        margin: 0 0 8px;
        text-transform: none;
    }

    .not-in-store-para {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: $dim-gray;

        padding: 2px 28px 0;
        margin: 0;

        .store-name {
            color: $night-rider;

            text-transform: capitalize;
        }
    }

    .check-another-store-para {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: $dim-gray;

        margin: 0 0 8px;

        .bopis-set-store {
            color: $night-rider;
        }
    }

    .no-results-para {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: $dim-gray;

        padding: 2px 28px 0;
        margin: 0;
        @include respond(large-up) {
            width: 60%;
            margin: 0 auto;
        }
    }
}

.pdp-main {
    .pdp-quantity-error-msg {
        @include ds-ui-regular(m);
        color: $text-feedback-danger;
        padding-bottom: 15px;
        margin-top: 13px;

        @include respond(large-up) {
            width: 313px;
            padding-top: 3px;
            padding-bottom: 12px;
        }

        .bopis-set-store {
            color: $red;
            text-decoration: underline;
            @include error-text-link;
        }

        b {

        }
    }
}

.size-not-selected-error {
    display: none;
    @include ds-ui-regular(m);
    color: $text-feedback-danger;
    padding-bottom: 11px;
}

.price-standard, .price-sales{
	display: inline;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.wish-list-guest-message{
		top: 36px !important;
		right: 0px !important;
	}
}

@include respond(small-down) {
    body.hide-chat-bubble #AgentAppContainer {
        display: none !important;
    }
}

#bv_review_maincontainer {
    abbr[title] {
        border: none;
    }
    [data-bv-v="contentHeader"] {
        button[disabled] {
            background: none;
            opacity: 1;
            letter-spacing: normal;
        }
    }
    [data-bv-v="feedback"] button {
        letter-spacing: normal;
    }
}
