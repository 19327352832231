#stores .stores-container .store .bodyinfo {
    display: inherit !important;
}
.gm-style-pbc {
    z-index: 2 !important;
}
.store {
    .events {
        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        .rewards-program {
            color: #ed088c;
            line-height: 1.2;
            span {
                display: block;
            }
        }
    }
}
