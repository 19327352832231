.ds-search-banner {
    box-sizing: border-box;
    line-height: 0;
    margin: 5px auto 32px;
    padding: 0 16px;
    text-align: center;
}

.slot-grid-header .ds-search-banner {
    margin: 15px auto 17px;
    padding: 0;
}

@media screen and (min-width: $lMin) {
    .ds-search-banner {
        padding: 0;
    }

    .slot-grid-header .ds-search-banner {
        margin-top: 32px
    }
}