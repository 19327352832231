.ds-text {
    max-width: 568px;
    margin: 0 auto;
    text-align: center;

    .ds-text-paragraph {
        @include ds-body(l);
        color: $text-secondary;
         & > :first-child {
            margin-top: 0;
         }

        p,
        a {
            @include ds-body(l);
            color: $text-secondary;
        }

        b {
            font-weight: 700;
        }

        strong {
            font-weight: 600;
        }

        p {
            margin-top: 8px;
        }

        ul, ol {
            margin-top: 8px;
            padding-left: 32px;
            padding-bottom: 24px;
            li {
                @include ds-body(l);
                color: $text-secondary;
                margin-top: 8px;
                &:first-child {
                    margin-top: 0
                }
            }
        }
    }

    .ds-text-heading {
        @include ds-heading(h2);
        text-transform: none;
        color: $text-primary;
        margin: 0;
    }
}

.copy-block-module {
    .ds-text {
        text-align: left;
    }
}
