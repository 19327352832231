#wrapper {
    &.pt_error {
        #main {
            &.custom-error {
				max-width: none;
				padding: 0;
				position: relative;
				.banner-wrap {
				    text-align: center;
				    overflow: hidden;
					img {
						max-width: 100%;
						vertical-align: top;
						@include respond(large-up) {
							width: 137%;
							max-width: none;
							position: relative;
							left: 50%;
							transform: translate(-50%, 0);
                        }
		                @include respond(hero-crop) {
		                    width: auto;
		                }
					}
				}
				.error-page-wrap {
					position: absolute;
					top: 0;
					left: 50%;
					width: 100%;
					max-width: 1440px;
					box-sizing: border-box;
                    transform: translate(-50%, 0);
                    @include respond(large-up) {
                        padding: 0 20px;
                    }
                    .error-page-message {
                        text-align: center;
                        @include respond(large-up) {
                           text-align: left; 
                        }
                        h1 {

                            @include font-size(89px);
                            line-height: 1;
                            color: $white;
                            margin-bottom: 5px;
                            @include respond(large-up) {
                                float: left;
                            }
                            @include respond(standart) {
                                @include font-size(172px);
                                margin: 42px 0 3px;
                                padding-left: 70px;
                            }
                            span {
                                display: block;
                                @include font-size(48px);
                                @include respond(large-up) {
                                    display: inline-block;
                                    float: right;
                                    margin-top: 5px;
                                }
                                @include respond(standart) {
                                    @include font-size(90px);
                                    margin-left: 20px;
                                    margin-top: 10px;
                                }
                            }
                        }
                        p {
                            @include font-size(22px);
                            @include line-height(30px);
                            color: $secondary-color;
                            margin: 0 0 23px;
                            @include respond(standart) {
	                            @include font-size(32px);
	                            @include line-height(40px);
	                            margin-bottom: 29px;
                                padding-left: 90px
                            }
                        }
                        .button {
                            background: transparent;
                            border: 2px solid $white;
                            @include font-size(14px);
                            max-height: 36px;
                            @include line-height(36px);
                            width: 131px;
                            padding: 0;
                            @include respond(standart) {
                                width: 178px;
                                @include font-size(19px);
                                max-height: 44px;
                                @include line-height(44px);
                                margin-left: 90px;
                            }
                            body:not(.touch) & {
                                &:hover {
				                    background-color: $secondary-color;
				                    border-color: $secondary-color;
				                    color: $white;
                                }
				                &:active {
				                    background-color: $white-smoke;
				                    border-color: $white;
				                    color: $secondary-color;
				                }
                            }
				            &:active {
				                background-color: $white-smoke;
				                border-color: $white;
				                color: $secondary-color;
				            }
                        }
                    }
                }
            }
	    }
    }
}
