.appeasement-wrapper {
    align-items: center;
    display: flex;
    flex-flow: column;
    max-width: 955px;
    padding: 0 20px;

    .primary-head {
        width: 100%;
    }

    p, a {
        font-size: 1rem;
        line-height: 1.75;
        color: #333;
    }

    .appeasementDisabledLink {
        text-decoration: underline;
    }

    .appeasement-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .appeasement-img {
            margin: 0 0 20px 0;
            width: 100%;

            img {
                width: 100%;
            }
        }

        #appeasement-form {
            flex-grow: 1;
        }
    }
}

@include respond(large-up) {
    .appeasement-wrapper {
        margin: 0 auto;

        .appeasement-content {
            flex-flow: row;

            .appeasement-img {
                max-width: 389px;
                margin: 0 60px 0 0;
            }
        }
    }
}
