@import "shopping_cart";
@import "adatooltip";
@import "../../../../../app_lyonscg/cartridge/scss/default/checkout/multishipping";
@import "../../../../../app_lyonscg/cartridge/scss/default/checkout/multi_inventory";

.timeout-dialog {
	.ui-widget-header {
	   display: none;
	}
	#timeout-dialog {
        text-align: center;
        padding: 32px 35px 8px;
        p {
            margin: 0;
            &#timeout-message {
                @include font-size(34px);
                margin: 13px 0;
                line-height: 1;
                color: $secondary-color;
            }
        }
    }
    .ui-dialog-buttonpane {
        background: transparent;
        .ui-dialog-buttonset {
            text-align: center;
            border: 0 none;
            padding: 0 35px 30px;
            margin: 0;
            button {
                float: none;
                margin: 0;
                .ui-button-text {
                    padding: 0 15px;
                    @include line-height(35px);
                }
            }
            button.ui-button.ui-widget{
           		float: none;
           	}
        }
    }
}

.gift-box-dialog {
	.ui-widget-header {
       .ui-dialog-title {
            display: none;
       }
    }
    .content-asset p {
        @include respond(medium-down) {
	        margin: 45px 0 1em 0;
	    }
    }
    #dialog-container {
        text-align: center;
        padding: 22px 43px 35px;
        img {
            max-width: 100%;
            vertical-align: top;
        }
    }
}

#dialog-container {
    .qty-block {
    	display: none;
    }
}

#wrapper {
    &.pt_checkout {
	    .mini-cart-content{
		    .cart-wrap{
                display: none;
            }
        }
        #main{
            padding: 0 15px;
            max-width: 954px;
            .primary-content {
                margin-top: 34px;
                @include respond(large-up) {
                    width: calc(100% - 324px);
                }
            }
            .success-message ~ .primary-content {
                margin-top: 15px;
            }
            #secondary {
                @include respond(large-up) {
                    width: 311px;
                    padding: 0;
                    float: right;
                    margin-top: 34px;
                }
            }
            &.full-width {
                .primary-content {
                    width: 100%;
                }
            }
        }

        // adding styles to display the tooltips on mobile - mlawrence 11-15-19
        .form-row.help {
            @include respond(medium-down) {
                position: relative;
            }
            .field-wrapper {
                @include respond(medium-down) {
                    float: left;
                    width: 88%;
                }
            }
            .form-field-tooltip {
                @include respond(medium-down) {
                    float: left;
                }
            }
        }
    }
}

.checkout-progress-indicator {
    @include ds-ui-regular(s);
    color: $text-secondary;
    overflow: hidden;
    position: relative;
    margin: 24px auto;
    width: 730px;
    text-align: center;
    display: flex;
    justify-content: center;
    @include respond(medium-down) {
        width: 440px;
        display: table;
        table-layout: fixed;
    }
    @include respond(small-down) {
        width: 290px;
    }
    div {
        float: left;
        width: 33.33%;
        position: relative;

        &:after {
	        content: '';
	        display: block;
	        width: 240px;
	        height: 3px;
	        position: absolute;
	        background: $bg-color;
	        bottom: 13px;
	        left: 50%;
	        @include respond(medium-down) {
	            width: 140px;
	        }
	        @include respond(small-down) {
	            width: 100px;
	        }
        }
        @include respond(medium-down) {
            float: none;
            display: table-cell;
            vertical-align: bottom;
        }
        @include respond(small-down) {
            &.step-1 {
                width: 56px;
                & ~ .step-2 {
				    width: 152px;
				}
				& ~ .step-3 {
				    width: 82px;
				}
            }
        }

        &.active {
            @include ds-ui-semi(s);
	        color: $text-brand-primary;

	        span {
	            background: $white;
	            border-color: $icon-brand-primary;
                color: $text-brand-primary;
                cursor: pointer;
	        }

            &:active {
                span {
                    color: $text-brand-primary;
                    border-color: $icon-brand-primary;
                    background: $white;
                }
            }
        }

		span {
			@include ds-ui-semi(s);
			background: $bg-color;
			border-radius: 15px;
			border: 2px solid $bg-color;
			box-sizing: border-box;
			display: block;
			height: 30px;
			line-height: 26px;
			margin: 15px auto 0;
			position: relative;
			width: 30px;
			z-index: 10;
		}

        a {
            @include ds-ui-regular(s);
            color: $text-brand-primary;
            display: inline-block;
            overflow: visible;
            position: relative;

            &:after {
				content: '';
				display: block;
				width: 240px;
				height: 3px;
				position: absolute;
				background: $cobalt;
				bottom: 13px;
				left: 50%;
				z-index: 1;

				@include respond(medium-down) {
				    width: 140px;
				}

				@include respond(small-down) {
				    width: 100px;
				}
            }

            span {
                color: $text-inverse;
                border-color: $cobalt;
                background: $cobalt;
            }

            &:active {
                span {
                    color: $text-inverse;
                    border-color: $active-blue;
                    background: $active-blue;
                }
            }
        }
        &.step-3 {
            &:after {
				left: auto;
				right: 50%;
            }
            a {
                &:after {
                    left: auto;
                    right: 50%;
                }
            }
        }
    }
    .e-giftcard-step {
    	.step3-placeholder {
    		font-style: normal;
	    	&:after {
	    		display: none;
	    	}
    	 	@include respond(medium-down) {
    	 		display: block;
    			padding: 0 25px;
    	 	}
    	}
    	a {
			@include respond(medium-down) {
	        	width: 60%;
	        }
    	}
    }
}

.checkout-top-banner {
	text-align: center;
	img {
	   vertical-align: top;
	   max-width: 100%;
	}
}

.checkout-customer-info {
	border-top: 1px solid $primary-border;
    border-bottom: 1px solid $primary-border;
    @include respond(large-up) {
        max-width: 467px;
    }

    h2 {
        &.customer-info-header {
            @include ds-heading(h4);
            color: $text-primary;
            margin: 0;
            cursor: pointer;
            padding: 20px 35px 20px 18px;
            position: relative;
            @include respond(large-up) {
                padding: 19px 2%;
            }
            &:after {
                content: '';
                display: block;
	            float: right;
                position: absolute;
	            width:15px;
	            height:15px;
	            @include transition(all 0.5s ease-out);
	            background-size: 20px;
	            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
	            top: 50%;
                margin-top: -7px;
	            right: 15px;
	            @include respond(large-up) {
	                position: relative;
                    right: 12px;
                    margin-top: 0;
	                top: 4px;
	            }
	        }
	        &.open, &.ui-state-active {
				&:after {
				    background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
                    transform: rotate(180deg);
				}
	        }
	        span {
                @include ds-ui-regular(s);
                color: $text-secondary;
                text-transform: none;

	            a {
                    color: $text-secondary;
					text-decoration: underline;
					&:hover {
                        text-decoration: none;
                    }
	            }
	        }
        }
    }
    .customer-info-content {
        display: none;
        padding: 0 18px 15px;
        @include respond(large-up) {
            padding: 0 2% 15px;
        }
    }

    .welcome {
        @include ds-heading(h4);
        color: $text-brand-primary;
        margin-top: 7px;
    }

    #proceed-to-billing {
        @include ds-body(m);
        color: $text-primary;
        margin: 4px 0 6px;

        @media only screen and (min-width: $lMin) {
            margin-bottom: 10px;
        }

        a {
            text-decoration: underline;
            color: $secondary-color;
            white-space: nowrap;
            &:hover {
                text-decoration: none;
            }
            &:active{
                color: $click;
            }
        }
    }
    .login-form {
        .content-asset {
            [role="heading"][aria-level="2"] {
                @include ds-ui-semi(m);
                color: $text-primary;
            }

            p {
                @include ds-ui-regular(m);
                color: $text-primary;
            }
        }

        .error-form,
        .blue-error-message {
            @include respond(large-up) {
                max-width: 352px;
                width: 82%;
            }
        }
        .form-row {
            label {
                @include ds-ui-semi(m);
                color: $text-primary;
            }

            .input-text {
                @include ds-ui-regular(l);
                color: $text-primary;
            }

            .field-wrapper {
                @include respond(large-up) {
                    width: 88%;
                    max-width: 416px;
                    margin: 0;
                    float: left;
                }
            }
            &.form-row-button {
                display: flex;
                align-items: center;
                text-align: left;
                margin-top: 20px;

                button {
                    width: 100%;

                    @include respond(large-up) {
                        width: 132px;
                    }
                }
            }
        }
        #password-reset {
            @include ds-ui-regular(m);
            color: $text-secondary;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.login-form-in-billing {
        margin-bottom: 40px;

        .customer-info-content {
            padding-bottom: 31px;

            @include respond(large-up) {
                padding-bottom: 25px;
            }
        }

        .welcome {
            margin-top: 11px;

            @include respond(large-up) {
                margin-top: 5px;
            }
        }

        .sktp-description {
            color: $dim-gray;
            font-size: 14px;
            line-height: 21px;

            margin: 8px 0 0 0;

            @include respond(large-up) {
                line-height: 24px;
                margin: 6px 0 0 0;
            }

            .skip-to-payment-link {
                color: $night-rider;
                @include night-rider-link;
            }
        }
    }
}

form.address {
    .pt_checkout & {
        position: relative;
        @include respond(large-up) {
            max-width: 487px;
        }
        @include respond(medium-down) {
            padding: 0 14px;
        }
        legend {
            @include ds-heading(h3);
            color: $text-primary;
            text-transform: capitalize;
            padding: 35px 0 17px 0;

            &.top-title {
                padding-top: 0;
            }
            & + .dialog-required {
                top: 13px;
            }
            @include respond(large-up) {
                padding: 35px 0 17px 2%;
            }
        }

        .dialog-required {
            @include respond(large-up) {
	            right: 12.5%;
	            top: 3px;
            }
        }

        .dialog-wrap {
            .dialog-required {
	            @include respond(large-up) {
	                right: 0;
	            }
            }
        }

        .form-row {
            label {
                @include ds-ui-semi(m);
                color: $text-primary;
                width: auto;
                margin-bottom: 8px;
            }
            .field-wrapper {
                @include respond(large-up) {
                    width: 88%;
                    max-width: calc(416px - -2%);
                    margin: 0;
                    float: left;
                }
                &.parent-field {
                    @include respond(large-up) {
                        max-width: 467px;
                        width: 100%;
                        margin-top: -15px;
                    }
                    &.paypal {
                        text-align: left;
                        @include respond(large-up) {
                            margin-top: -5px;
                        }

                        h2 {
                            @include ds-ui-semi(m);
                            color: $text-primary;
                            margin: 0 0 5px;
                        }

                        p {
                            @include ds-body(m);
                            color: $text-primary;
                            margin: 0 0 17px;
                        }
                    }
                }
            }
            .form-field-tooltip {
                padding-top: 0;
                width: 7%;
                margin-left: 2%;
            }
            &.hidden {
                display: none;
            }
            &.cvn {
                .field-wrapper {
                    width: 80px;
					@include respond(small-down){
						position: relative;
					}
					@include respond(small){
						position: relative;
					}
                    .error {
                        white-space: nowrap;
                    }
                }
                .form-field-tooltip {
                    display: block;
                    float: left;
				    width: 26px;
				    padding-top: 0;

                    @include respond(large-up) {
                        margin-left: 10px;
                    }
                }
                &.new-card-cvn {
                    .field-wrapper {
                        @include respond(large-up) {
                            width: 169px;
                        }
                    }
                }
            }
            &.month,
			&.year,
			&.day {
                margin: 0;
                @include respond(large-up) {
                    margin-left: 2%;
                }
                label {
                    margin: 0;
                }
                .field-wrapper {
                    width: 121px;
                    @include respond(large-up) {
                        width: 169px;
                        padding: 0;
                    }
                    .error {
                        white-space: nowrap;
                    }
                }
			}
			&.year {
                @include respond(medium-down) {
                    margin-left: 20px;
                }
			}
            &.with-tooltip-text {
                .form-field-tooltip {
                    padding-top: 12px;
                    width: 10%;
                    @include respond(tablet-small) {
                        width: 11.6%;
                        margin-left: 0.4%;
                    }
                    .tooltip {
	                    background: none;
	                    text-indent: inherit;
	                    border-radius: inherit;
	                    width: 60px;
	                    height: inherit;
	                    .details {
				            text-decoration: underline;
				            color: $secondary-color;
				        }
				        &:hover {
				            .details {
				                text-decoration: none;
				            }
				        }
                    }
                }
            }
            &.selectbox {
                .field-wrapper {
                    max-width: calc(169px - -2%);
                    @include respond(large-up) {
                        max-width: calc(154px - -2%);
                    }
                }
            }
            &.checkbox {
                label {
                    @include ds-ui-regular(m);

                    span {
                        span {
                            display: block;
                            padding-left: 20px;
                        }
                    }
                }
                margin: 10px 0 5px;
                @include respond(small-down){
					margin: 10px 0 0;
					height: 40px;
                }
                @include respond(small){
					margin: 10px 0 0;
					height: 40px;
				}
                &.addtoemail {
                    label {
                        padding-left: 20px;
                        span {
                            display: inline-block;
                            width: 93%;
                        }
                        a {
                            text-decoration: underline;
					        color: $secondary-color;
					        &:hover {
					            text-decoration: none;
					        }
					        &:active{
					            color: $click;
					            text-decoration: none;
					        }
                        }
                    }
                    .input-checkbox {
                        position: absolute;
                    }
                }
            }
            &.gift-row {
                margin-top: 26px;
                .field-wrapper {
                    label {
                        @include ds-ui-regular(m);
                        padding-right: 26px;
                    }
                }

                legend {
                    @include ds-ui-bold(m);
                    color: $text-primary;
                }
            }
            &.form-row-button {
                @include respond(large-up) {
                    width: auto;
                    margin-left: 2%;
                    text-align: left;
                    margin-bottom: 22px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }

                @include respond(medium-down) {
                    margin-left: -14px;
                    margin-right: -14px;
                }

                .verify-field {
                    @include ds-ui-regular(s);
                    flex: 0 1 100%;
                }

                button {
                    @include ds-utilities-button;

                    @include respond(large-up) {
                        min-width: 175px;
                        width: auto;
                    }
                }
                &.PayPal {
                    display: none;
                }
            }
        }
        .balance {
            margin: 0;
            &.error{
                clear: both;
                text-align: left;
            }
            &.success{
                padding-top: 15px;
                color: $egg-blue;
                clear: both;
                text-align: left;
            }
        }
        .giftcert-error-top.blue, .giftcert-error-bottom.blue {
        	p {
        		background-color: $alice-blue;
           		color: #1b79aa;
        	}
        }
        .giftcert-error-top, .giftcert-error-bottom {
            padding-top: 0px;
            color: $red;
            text-align: left;
            clear: both;
            p {
           		background-color:#ffe9e9;
           		color: $red;
           		@include font-bold(14px);
             	padding: 14px 26px 16px 20px;
               	margin: 20px 5px 0px 0;
                line-height: 1.2em;
                max-width: 378px;
          	}
          &.overtwo p  {
          	background: #e6f5ff;
    		color: #1b79aa;
          }
        }
        .giftcert-error-top p {
        	margin-top: 10px;
        	margin-bottom: 20px;
        }
        .redemption{
            margin: 14px 0 29px 2%;
            padding-left: 0;
            padding-bottom: 0;
            &.giftcert{
	            margin: 20px 0 0px 0%;
                .success{
                    @include font-bold(14px);
                    letter-spacing: 0px;
                 	color: $mint-cream2;
                 	background-color: $mint-cream;
                 	padding: 12px 20px 1px;
                    padding-top: 12px;
    				padding-bottom: 3px;
                    color: $egg-blue;
                    clear: both;
                    text-align: left;
                    width: auto;
                	display: block;
                	margin-bottom: 20px;
                	max-width: 384px;
                    .gift-card-data {
                        color: $night-rider;
                    	@include font-regular(10px);
                    	padding: 11px 0;
                    	text-transform: uppercase;
                    	letter-spacing:.04px;
                    }
                    &:last-child {
                    	margin-bottom: 0;
                    }
                }
                .remove{
                    .gift-remove{
                        width: 15px;
                        height: 15px;
                        position: relative;
                        top: -55px;
                        right: -6px;
                        float:right;
                    }
                }
            }
        }

        .giftcard-limit {
            @include ds-ui-regular(s);
            display: none;
       		margin-top: 30px;
       		margin-bottom: 30px
        }

        .g-recaptcha-apply{
            margin: 15px 0;
        }
        .current-shipping {
            border: 1px solid $primary-border;
            padding: 14px 20px;
            box-sizing: border-box;
            margin-top: 11px;
            display: flex;
            flex-direction: column;
            @include font-size(14px);
            @include line-height(24px);
            @include respond(large-up) {
                margin: 6px 0 0 2%;
                padding: 20px 20px;
                max-width: 416px;
            }
            .current-shipping-name {
                color: $secondary-color;
                @include font-bold(14px);
            }
            .address {
                margin-bottom: 23px;
            }
            .billing-address-content{
                @include ds-ui-regular(m);
                color: $text-primary;
				width: 100%;
			}

			.edit-link-container{
				width:100%;
			}

            .edit-link {
                @include ds-ui-regular(m);
                color: $text-primary;
                float:left;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
        .billing-address-form {
            &.hidden {
                display: none;
            }
        }
        .billing-canada-asset {
            @include font-size(11px);
            @include respond(large-up) {
                margin-left: 2%;
                max-width: 416px;
            }
            &.top-asset {
                margin-top: 20px;
                @include respond(large-up) {
                    margin-top: 26px;
                    .content-asset {
                        padding-left: 20px;
                    }
                }
            }
            &.bottom-asset {
                @include respond(medium-down) {
                    margin-bottom: 30px;
                }
            }
        }
        .billing-accordion {
            margin: 50px -14px 29px;
            @include respond(large-up) {
                max-width: 467px;
                margin: 50px 0 16px;
            }
            h2 {
                @include ds-heading(h4);
                margin: 0;
                padding: 18px 14px;
                border-top: 1px solid $primary-border;

	            @include respond(large-up) {
	                padding: 18px 2%;
	            }

                .ui-icon{
		            float: right;
		            position: relative;
		            width:20px;
		            height:22px;
		            @include transition(all 0.5s ease-out);
		            background-size: 20px;
		            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
		            top: 9px;
		            left: 0;

                    &.ui-icon-triangle-1-s {
                        background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
                        transform: rotate(180deg);
                    }
                }
            }

            h2:last-of-type {
            	border-bottom: 1px solid $primary-border;
            	&.ui-accordion-header-active {
            		border-bottom: none;
            	}
            }

            .coupon-code-header {
                @include ds-heading(h4);
                color: $text-primary;
            }

            .success {
                 @include font-bold(14px);
                 color: $mint-cream2;
                 background-color: $mint-cream;
                 padding: 12px 20px 1px;
             }
            .billing-coupon-code,.billing-coupon-code-loyalty {
                padding: 0 0 0 2%;
                @include respond(medium-down) {
                   padding: 0 14px;
                }
                .form-row {
			        &.coupon-row {
			            float: left;
                        margin-bottom: 0;
			            width: calc(100% - 75px);
			            @include respond(large-up) {
			                width: calc(100% - 107px);
			            }
			            input {
			            	text-transform: uppercase;
			            }
			        }
			        .field-wrapper {
                        @include respond(large-up) {
                            width: 100%;
                            padding: 0;
                        }
                    }
                    label {
                        @include respond(large-up) {
                            padding-left: 0;
                        }
                    }
                    &.gift-pin-row {
                        float: left;
                        width: 114px;
                        margin-bottom: 0px;
                        @include respond(small-down) {
                            width: 100px;
                        }
                        span.error {
                            white-space: nowrap;
                        }
                        &.help {
                            position: relative;
                            .form-field-tooltip {
                                position: absolute;
                                right: -20px;
                                @include respond(small-down) {
                                    right: -10px;
                                }
                            }
                        }
                    }
			    }
			    .coupon-apply,.coupon-apply-loyalty {
			        button {
                        @include ds-utilities-button;
			            width: auto;

                        &.apply {
                            width: auto;
                        }
                    }

                    .or {
                        @include font-size(14px);
                        @include line-height(35px);
                        text-transform: capitalize;
                        margin: 0 15px;
                        display: inline-block;
                        color: $secondary-color;
                        @include respond(medium-down) {
                            display: none;
                        }
                    }

                    &.giftcert-apply {
                        display: flex;
                        margin-top: 0px;
                        align-items: center;
                    }

                    .button-text {
                        @include ds-ui-regular(m);
                        color: $text-secondary;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
			    }
			    .coupon-error, .coupon-error-loyalty {
                    .error-msg {
                        @include ds-ui-regular(m);
                        background-color: $bg-feedback-danger;
                        color: $text-feedback-danger;
                        word-break: break-word;
                        margin: 10px 0 0;
                        padding: 12px 16px;

                        &.reset {
                            background-color: inherit;
                            height: 20px;
                        }

                        a {
                            color: $text-feedback-danger;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
			    }

			    .coupon-error {
			    	&.blue-error-message {
        				background-color: #E6F5FF;
        				color: #1B79AA;
        				margin-top: -10px;
        				.cart-promo-msg-link {
                            color: #1B79AA;
                            @include font-bold(14px);
            				&:active {
                				color: #005699;
            				}
        				}
        				p {
							color: #1B79AA;
							padding: 12px 15px 12px 18px;
						}
    				}
			    }
                .success {
                    @include font-bold(14px);
                    color: $mint-cream2;
                    background-color: $mint-cream;
                    padding: 12px 20px 1px;
                 	margin-right: 17px;
                 	@include respond(medium-down) {
                 		margin-right: 5px;
                 	}
                }
			    .redemption {
                    &.coupon {
                        margin: 0 0 20px;
                        width: 100%;
                        .success {
                            &.msg {
								@include font-regular(12px);
								color: $egg-blue;
								display: inline-block;
								padding-left: 30px;
                                &:before {
									content: '';
									display: block;
									width: 25px;
									height: 25px;
									float: left;
									margin: -7px 0 0 -30px;
									background: url("../images/svg-icons/Alert-Check.svg") no-repeat center;
                                }
                            }
                        }
                        .promo-value{
                            padding-left: 5px;
                            color: $red;
                            @include font-regular(12px);
                        }
                    }
			    }
			    .g-recaptcha-apply {
					transform: scale(0.9);
					transform-origin: 0 0;
					div {
				        @include respond(small-down) {
                            max-width: 262px;
                        }
					}
			    }
            }
            .billing-coupon-code-loyalty {
                .coupon-list {
                    padding-top: 0;
                }
                .coupon-error-loyalty {
                    margin-bottom: 32px;
                }
            }
            .billing-coupon-code {
                margin-top: 16px;

                .billing-coupon-field {
                    display: flex;
                    padding: 2px 0;

                    input[name$="_couponCode"] {
                        text-transform: uppercase;
                    }
                }
            	&:last-of-type {
            		border-bottom: 1px solid $primary-border;
            		overflow: initial;
            	}
	            .success {
	           		padding: 14px 20px 3px;
	           		margin-right: 1%;
	            }
	        }
        }
        .payment-method-options {

            @include respond(large-up) {
                padding-left: 2%;
            }

            .form-row {
		        clear: none;
		        float: left;
		        width: 100%;
		        margin: 6px 0 19px;
                @include respond(desktop) {
                  width: 320px;
                }

		        &:last-child:not(:only-of-type) {
		          margin-bottom: 3px;

		          @include respond(desktop) {
		             width: auto;
                     margin-left: 24px;
		          }
		        }

                label {
                    @include ds-ui-regular(m);
                    padding-left: 19px;
                    float: left;
                    margin: 0;

                    img {
                        height: 40px;
                        @include respond(small) {
                            height: 36px;
                        }
                    }
		        }

		        .payment-image {
                    display: flex;
                    float: right;
                    margin-left: 9px;
                    margin-top: -10px;
                    @include respond (small-down-iphone){
                    	margin-right: -20px;
                    	margin-left: 5px;
                    }
                    .vsangelcard {
                        margin-left: 5px;
                    }
                    img {
                        &[alt="DinersClub"] {
                            display:none!important;
                        }
                    }
		        }

		        .field-wrapper {
		            width: auto;
		            float: left;
		            position: relative;
		            .input-radio {
                        position: absolute;
                        top: 5px;
                        left: 0;
		            }
		        }
		    }
		}
		&.checkout-shipping {
		    .shipping-suggested-addresses {
		        background-color: $medium-spring-green;
		        box-sizing: border-box;
		        text-align: left;
		        padding: 15px 20px 11px;
		        margin: 0 0 24px;
		        @include respond(large-up) {
		            margin-left: 2%;
		            max-width: 416px;
		        }
		        .suggested-addresses-label {
                    @include ds-ui-semi(m);
                    color: $red;
                    display: flex;
                    margin-bottom: 18px;
		            &:before {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='21' viewBox='0 0 22 21' fill='none'%3E%3Cpath d='M11.0001 -0.000976562C16.5238 -0.000976562 21.0016 4.47687 21.0016 10.0006C21.0016 15.5243 16.5238 20.0021 11.0001 20.0021C5.47638 20.0021 0.998535 15.5243 0.998535 10.0006C0.998535 4.47687 5.47638 -0.000976562 11.0001 -0.000976562ZM11.0001 1.49902C6.30481 1.49902 2.49854 5.3053 2.49854 10.0006C2.49854 14.6959 6.30481 18.5021 11.0001 18.5021C15.6954 18.5021 19.5016 14.6959 19.5016 10.0006C19.5016 5.3053 15.6954 1.49902 11.0001 1.49902ZM10.9964 8.49961C11.3761 8.49936 11.6901 8.78131 11.74 9.14735L11.7469 9.24911L11.7505 14.7507C11.7508 15.1649 11.4152 15.5009 11.001 15.5012C10.6213 15.5014 10.3073 15.2195 10.2574 14.8535L10.2505 14.7517L10.2469 9.2501C10.2467 8.83588 10.5822 8.49988 10.9964 8.49961ZM11.0005 5.00184C11.5521 5.00184 11.9992 5.44896 11.9992 6.0005C11.9992 6.55205 11.5521 6.99917 11.0005 6.99917C10.449 6.99917 10.0019 6.55205 10.0019 6.0005C10.0019 5.44896 10.449 5.00184 11.0005 5.00184Z' fill='%23CC0000'/%3E%3C/svg%3E");
                        background-position: center;
                        background-repeat: no-repeat;
                        content: '';
                        display: block;
                        height: 22px;
                        margin-right: 10px;
                        width: 44px;
				    }
		        }
		        .suggested-addresses-list {
		            .form-row {
                        margin-bottom: 13px;
		                .field-wrapper {
		                    float: left;
		                    width: 15px;
		                    padding: 0;
		                    @include respond(medium-down) {
                                padding-top: 2px;
		                    }
		                    input {
								width: 15px;
								height: 15px;
		                    }
		                }
		                label {
		                    padding-left: 25px;
		                    margin: 0;
		                    @include respond(medium-down) {
                                @include line-height(19px);
		                    }
		                }
		            }
		        }
		    }
        }

        .alternative-pickup-person-fields {
            position: relative;
            padding-left: 3px;

            @include respond(large-up) {
                padding-left: 0;
                max-width: 417px;
                margin-left: 2%;
            }
            .pickup-icon {
                background: url('../images/svg-icons/bopis-icon-large.svg') no-repeat;
                background-size: 24px 31px;
                width: 24px;
                height: 31px;
                position: absolute;
                padding-right: 10px;
                top: 45px;

                @include respond(large-up){
                	top: 40px;
                }

            }

            legend {
                padding: 49px 0 19px 34px;
                @include ds-heading(h5);
                text-transform: capitalize;

                @include respond(large-up) {
                    padding-top: 44px;
                }
            }

            .carry-govt-id {
                @include ds-ui-regular(m);
                color: $text-secondary;
                margin: 0 0 20px 0;
                padding-right: 26px;
            }

            .self-pickup-radio-block {
                display: flex;
                margin: 0 0 24px 0;
            }

            .someone-else-pickup-radio-block {
                display: flex;
                margin: 0 0 20px 0;
            }

            .self-pick-up-input,
            .someone-else-pickup-input {
                width: 16px;
                height: 16px;
            }

            .self-pick-up-label,
            .someone-else-pickup-label {
                @include ds-ui-regular(m);
                color: $text-primary;
                margin: 0;
                padding: 0 0 0 10px;
            }

            .alternative-pickup-person-details {
                padding-left: 14px;
                @include transition(all 0.5s linear);
                display: none;
                height: 0;
                padding-top: 5px;

                &.show-details-form {
                    display: block;
                    height: auto;
                }
                &::after {
                    content: '';
                    clear: both;
                }

                .dialog-required {
                    right: 0;
                    span em {
                        @include line-height(13px);
                    }
                }

                .form-row {
                    margin:0 0 20px 0;

                    .field-wrapper {
                        box-sizing: border-box;
                        padding-left: 12px;

                        @include respond(large-up) {
                            width: 100%;
                            max-width: unset;
                        }
                    }

                    label {
                        margin-bottom: 20.64px;
                    }
                }
            }
        }
    }
}

.shipping-methods-wrap {
    legend {
        padding-top: 25px;
        @include ds-heading(h3);
        color: $text-primary;

        @media only screen and (min-width: $lMin) {
            padding-top: 24px;
        }
    }

    .shipping-methods-banner {
        margin: 5px 0 20px;
        @include respond(large-up) {
			margin-left: 2%;
			margin-bottom: 20px;
			max-width: 377px;
        }
        .shipping-banner {
			border: 2px solid $primary-border;
			padding: 10px 10px 15px;
			max-width: 370px;
			box-sizing: border-box;
            img {
				height: 36px;
				vertical-align: top;
				float: left;
				margin-top: 10px;
            }
			span {
                @include font-size(14px);
                @include line-height(16px);
                display: inline-block;
                float: right;
                width: calc(100% - 97px);
                margin-top: 7px;
			}
        }
    }

    .restricted-items {
        border: 1px solid $red;
        box-sizing: border-box;
        text-align: left;
        padding: 15px 20px;
        margin-bottom: 16px;
        @include respond(large-up) {
            margin-left: 2%;
            max-width: 416px;
        }
        .restricted-label {
            color: $red;
            @include font-size(14px);
            @include line-height(17px);
            a {
                display: block;
                text-decoration: underline;
		        @include font-size(14px);
		        color: $secondary-color;
		        &:hover {
		            text-decoration: none;
		        }
		        &:active{
		            color: $click;
		        }
            }
        }
        .restricted-label + .restricted-label {
            margin-top: 20px;
        }
        .restricted-list {
            list-style: none;
            @include respond(large-up) {
                max-width: 80%;
            }
            margin: 30px auto;
            li {
                display: block;
                margin: 15px 0;
                .restricted-image {
                    float: left;
                    width: 100px;
                    img {
                      max-width: 100%;
                      vertical-align: top;
                    }
                }
                .restricted-details {
                    float: right;
                    width: calc(100% - 120px);
                    @include font-size(12px);
                    @include line-height(14px);
                    .restricted-label {
                        @include font-size(12px);
                        @include line-height(16px);
                        margin-top: 10px;
                    }
                    .name {
                        @include font-size(14px);
                        @include line-height(14px);

                        letter-spacing: 0.5px;
                        color: $secondary-color;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .button-fancy-large {
            width: 100%;
            margin-bottom: 10px;
            @include font-size(13px);
            letter-spacing: 1.3px;
        }
        .button-fancy-large + a[role="button"]{
           text-decoration: underline;
        }
    }
}
#shipping-method-list {
    .restricted-items {
        .form-row {
            text-align: center;
            margin-bottom: 0px;
            max-width: 100%;
        }
    }
	.form-row {
	   position: relative;
	   margin-bottom: 16px;
	   max-width: 450px;
	   @include respond(large-up) {
		   &.label-inline {
		       margin-bottom: 30px;
		       line-height: 0;
		   }
	   }
	   .field-wrapper {
	       float: left;
	       width: 14px;
	       position: absolute;
	       left: 0;
	       top: 2px;
	       @include respond(large-up) {
	           left: 10px;
	           top: 1px;
	       }
	   }
	   .details-lnk {
	       margin: 0 10px 0 5px;
	       @include font-size(12px);
           @include respond(medium-down){
               display: inline;
               width: 36px;
               margin: 10px 0 0;
           }
	       .details {
	           color: $dim-gray;
	           @include font-size(14px);
	           @include respond(medium-down){
	               @include font-size(13px);
	           }
	       }
	   }
	    label {
            @include ds-ui-regular(m);
            color: $text-secondary;
            float: left;
            padding-left: 20px;
            margin-bottom: 0px;

            @include respond(large-up) {
	            padding-left: 30px;
                margin-bottom: 10px;
	        }

           .outline {
                .bold {
                    @include ds-ui-semi(m);
                    color: $text-primary;
                }

                &.active {
                    outline: 1px dotted;
                }
           }

	       a {
	           @include font-size(14px);
	           margin: 0 10px;
	       }
	       span {
	           &.standard-shipping {
	               text-decoration: line-through;
	           }
	           &.discount-shipping {
	               color: $red;
	           }
	       }
	   }
	}

	.safari & {
	   .form-row {
	       .field-wrapper {
	           @include respond(medium-down) {
	               top: 4px;
	           }
	       }
        }
	}
	.float-left {
        float: none;
	    width: 365px;
	    margin-right: 20px;
	    @include respond(medium-down) {
	    	width: 71%;
	    }
	}

    .shipping-price {
		float: right;
        @include ds-ui-semi(m);
        color: $text-primary;

        .bold {
            color: $text-primary;
        }

        @media screen and (min-width: 768px) {
            margin-top: 8px;
        }
	}

	.expected-delivery {
        @include ds-ui-regular(m);
        color: $text-secondary;
        max-width: 190px;
        padding-left: 20px;
        text-align: left;

		.bold-text {
			@include ds-ui-semi(m);
            color: $text-primary;
		}
		.details-lnk {
			padding-left: 0;
			margin-left: 0;
		}

        @media only screen and (min-width: $lMin) {
            max-width: 347px;
            padding-left: 30px;
        }
	}

    span.standard-shipping {
        color: $text-secondary;
        margin-right: 5px;
        text-decoration: line-through;
    }
}

.shipping-method-tooltip {
    margin-bottom: 0;
    @include respond(medium-down) {

    }
    .label {
        float: left;
    }
    .value {
        float: right;
    }
    p {
    	@include line-height(18px);
    }
    span {
    	@include font-size(14px);

    	color: $night-rider;
    	@include respond(medium-down) {
    		@include font-size(13px);
    	}
    }
}
.shipping-method-tooltip-custom {

    max-width: 360px;
    @include respond(medium-down) {
    	max-width: 270px;
    }
    p {
        font-size: .95em;
    }
    p.large {
    	margin: 8px 0 7px 0;
    	@include font-size(14px);
    	letter-spacing: 1px;
    	@include line-height(20px);
    	color: $night-rider;
    }
    p.small {
    	margin: 5px 0 0 0;
    	@include font-size(12px);
    	@include line-height(16px);
    }
}

.shipping-method {
    label {
        display: inline;
        float: none !important;
        padding-right: .42em !important;
    }
    .shipping-description {
        display: inline;
    }
}


.gift-message-box {
    background: $bg-color;
    padding: 15px 19px 58px;
    position: relative;
    margin-top: -13px;
    @include respond(large-up) {
        max-width: 416px;
        margin-left: 2%;
        box-sizing: border-box;
    }
    &.hidden {
        display: none;
    }
   &.form-errors {
   		display:block;
   	}
    .see-gift-box {
        position: absolute;
        top: 18px;
        right: 20px;
        text-decoration: underline;
        @include font-size(14px);
        color: $secondary-color;
        &:hover {
            text-decoration: none;
        }
        &:active{
            color: $click;
        }
        @include respond(medium-down) {
        	z-index: 150;
        }
    }
    .form-row {
        label {
            &:first-child {
                @include respond(large-up) {
	                float: left;
	                padding-left: 0;
	                max-width: 150px;
	                text-align: right;
	                padding-right: 22px;
	            }
            }
            span {
                color: $primary-color;
	            @include font-size(14px);
	            @include line-height(24px);
	            @include respond(large-up) {
	               line-height: 1
	            }
	            span {
	               display: block;
	               @include font-size(11px);
                   @include line-height(16px);
	            }
            }
        }
       .form-caption.error-message {
       		float:right;
       		padding-right: 35px;
       	}
        .field-wrapper {
            @include respond(large-up) {
                width: calc(100% - 150px)!important;
            }
        }
        &.gift-row-title {
            margin: 0 0 14px;
            .field-wrapper {
                @include respond(large-up) {
                    padding-top: 3px;
                }
                label {
                    padding-right: 26px;
                }
            }
        }
    }
    .gift-message-text {
        margin: 10px 0 0;
        label {
            &:before {
                content: '';
                display: inline-block;
                width: 14px;
                height: 15px;
                margin-right: 8px;
                background: url("../images/gift-box-icon.png") no-repeat center;
            }
        }
        .field-wrapper {
            padding-left: 0;
        }
    }
}

.redemption {
    color: $text-feedback-danger;

    span.error {
        margin-left: 0;
    }

    .success {
        color: $islamic-green;
    }
}

.pt_checkout form.address .payment-method-options .form-row.billing-exposed-title, .pt_checkout form.address .form-row .field-wrapper.parent-field.paypal h2.billing-exposed-title {
    margin: 24px 0px 20px;
    padding: 0px 0px;
    font-weight:700;
    font-size: 1rem;

    label.billing-exposed-label {
        padding-left: 0px;

        .bold {
            font-weight: 700;
        }
    }
}

.billing-exposed-require {
    position: absolute;
    text-align: right;
    width: 88%;
}

#payment-methods .payment-header-info.billing-exposed-secure {
    margin: 0px 0px 24px;
    font-weight: 400;
}

.payment-method {
    clear: both;
    display: none;
    .dialog-wrap {
        @include respond(large-up) {
            max-width: 467px;
        }
        .dialog-required {
            top: 6px;
            @include respond(large-up) {
                top: 10px;
            }
        }
    }
}

.payment-method-expanded, .payment-method-exposed {
    display: block;
    .billing-new-card{
		.form-row{
			.disable-save-card{
				+ label{
					span{
						color:$dim-gray;
					}
				}
			}
		}
	}
    .billing-exposed-separator {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .billing-exposed-circle {
        width: 36px;
        height: 36px;
        border: 2px solid $bg-color;
        border-radius: 50%;
        line-height: 36px;
        color: $text-secondary;
        font-weight: 700;
        font-size: 0.875rem;
    }

    .billing-exposed-separator::before,
    .billing-exposed-separator::after {
        content: '';
        flex: 1;
        border-bottom: 2px solid $bg-color;
    }

    .billing-exposed-separator:not(:empty)::before {
        margin-right: .25em;
    }

    .billing-exposed-separator:not(:empty)::after {
        margin-left: .25em;
    }
}

.month,
.year,
.day {
    clear: none;
    float: left;
    margin: 0 0 2% 2%;
    label {
        @extend .visually-hidden;
    }
    select {
        width: 100%;
    }
}

.month .form-caption {
    margin-left: 0;
}

.cvn {
    clear: left;
    .field-wrapper {
        width: 80px;
        float: left;
        .field-wrapper {
            white-space: nowrap;
        }
    }
}

.ssn {
    clear: left;
}

.bml-terms-and-conditions {
    border: 1px solid $dark-gray;
    font-size: 0.8em;
    height: 400px;
    overflow: auto;
    padding: 1em;
}

.bml-table-col-1 {
    width: 25%;
}

.checkout-totals-wrap {
    border: 1px solid $primary-border;
    padding: 0 13px 6px;
    .section-header {
        @include ds-heading(h4);
        color: $text-primary;
        text-transform: capitalize;
        border-bottom: 3px solid $primary-border;
        text-align: center;
        padding: 21px 0 20px;
    }
    .ship-method {
        text-transform: capitalize;
    }
    .checkout-mini-cart {
        max-height: 400px;
        background: transparent;
        @include respond(medium-down){
            overflow: hidden;
            max-height: none;
        }
        .mini-cart-product {
            padding: 25px 20px 0 0;
            @include respond(medium-down) {
                padding-right: 16px;
            }
            &:last-child {
                padding-bottom: 25px;
            }
            .mini-cart-image {
                left: 0;
                margin-top: 0;
                img {
                    vertical-align: top;
                }
            }

            .mini-cart-name {
                padding-top: 0;

                a {
                    pointer-events: none;
                    cursor: default;
                }
            }

            .mini-cart-attributes,
            .mini-cart-pricing {
                @include ds-ui-regular(s);
                color: $text-secondary;

                .promotion-callout {
                    @include ds-ui-semi(s);
                    color: $text-feedback-danger;
                }
            }

            .mini-cart-attributes .small {
                @include ds-ui-regular(s);
                margin-bottom: 0;
            }

            .remove-item {
                display: none;
            }
        }
    }
    .checkout-order-totals {
        border-top: 3px solid $primary-border;
        padding-top: 13px;

        .order-store-name {
            display: block;
        }
    }

    .bopis-mini-dashboard-order-summary-box {
        display: flex;
        border-bottom: 3px solid $white-smoke;
        justify-content: center;
        padding: 9px 0;
        color: #333;

        .mini-dashboard-shipping-details {
            padding-right: 10px;
            border-right: 3px solid $white-smoke;
            display: flex;
            padding-top: 2px;
            align-items: baseline;

            .shipping-icon {
                background: url('../images/svg-icons/bopis-shipping.svg') no-repeat;
                background-size: 20px 10px;
                width: 20px;
                height: 10px;
                padding-right: 5px;
                display: flex;
                align-self: center;
            }

            b {
                @include ds-heading(h5);
                color: $text-primary;
            }

            .shipping-count-text {
                @include ds-heading(h5);
                color: $text-primary;
                white-space: pre;
            }
        }

        .mini-dashboard-pickup-details {
            padding-left: 10px;
            display: flex;
            padding-top: 2px;
            align-items: baseline;

            .pickup-icon {
                background: url('../images/svg-icons/bopis-icon-small.svg') no-repeat;
                background-size: 10px 13px;
                width: 10px;
                height: 13px;
                padding-right: 5px;
                display: flex;
                align-self: center;
            }

            b {
                @include ds-heading(h5);
                color: $text-primary;
            }

            .pickup-count-text {
                @include ds-heading(h5);
                color: $text-primary;
                white-space: pre;
            }
        }
    }

    .mini-dashboard-shipping-text {
        @include ds-heading(h5);
        color: $text-brand-primary;
        display: flex;
        padding: 10px 0 13px;
        .shipping-icon {
            background: url('../images/svg-icons/bopis-shipping.svg') no-repeat;
            background-size: 30px 15px;
            width: 30px;
            height: 15px;
            margin-right: 5px;
            display: flex;
        }

        b {
            @include ds-heading(h5);
            color: $text-primary;
        }
    }

    .mini-dashboard-pickup-text {
        display: flex;
        align-items: center;
        color: $cobalt;
        min-height: 32px;
        padding: 4px 0;

        @include font-size(12px);
        @include line-height(16px);
        margin: 0;

        .pick-up-icon {
            background: url("../images/svg-icons/bopis-icon-small.svg") no-repeat;
            background-size: 18px 23px;
            width: 20px;
            height: 23px;
            margin-right: 6px;

        }

        .store-name {
            @include ds-heading(h5);
            color: $text-primary;
            text-transform: capitalize;
            margin: 0;
        }

        .pickup-text-wrap {
            @include ds-heading(h5);
            color: $text-brand-primary;
            flex: 1;
        }
    }
}

#secondary .checkout-totals-wrap .checkout-mini-cart .mini-cart-product .mini-cart-name a {
    @include ds-ui-semi(m);
    color: $text-primary;
}

.mac .addtoemail {
    @include respond(large-up) {
        input[type="checkbox"],
        input[type="radio"] {
            & + label {
                top: -1px;
            }
        }
    }
}

.order-component-block {
    margin: 42px 0;
    border: 1px solid $primary-border;
    padding: 30px 18px 30px;
    position: relative;

    @include respond(medium-down) {
        margin: 37px 0;
    }
    .section-header {
        @include ds-heading(h4);
        color: $text-primary;
        text-transform: capitalize;
        padding: 0 0 23px;
    }
    a {
        @include ds-ui-regular(s);
        color: $text-brand-primary;
        position: absolute;
        right: 18px;
        top: 30px;
    }

    .details {
        @include ds-ui-regular(m);
        color: $text-primary;

        .minishipments-method {
            padding-top: 23px;
        }
        .mini-payment-title {
            text-transform: uppercase;
        }
        &.mini-payments-info {
            padding-top: 23px;
        }
    }
    #secondary & {
        a {
            @include ds-ui-regular(m);
            color: $text-primary;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
        .address a {
            display: inline-flex;
            position: initial;
        }
    }
}

.fake-continue-row {
	margin-top: 37px;
	@include respond(large-up) {
	   margin: 0;
	   display: none;
	}
	button {
        @include ds-utilities-button;
	}
}

.review-top-actions {
	position: relative;
	@include respond(large-up) {
	   padding-top: 12px;
	   margin-bottom: 25px;
	}
	.submit-order {
	   @include respond(large-up) {
	       position: absolute;
	       right: 0;
	       top: -3px;
	   }
	}

	h1 {
		text-transform: capitalize;
		@include ds-heading(h3);
		border-bottom: 1px solid $secondary-border;
		margin-bottom: 17px;

		@media only screen and (min-width: $xlMin) {
			@include ds-heading(h2);
			padding-bottom: 14px;
			margin: 0;
		}
	}
    .auto-refresh-messaging, .checkout-legal-message {
        color: $night-rider;

        font-size: 12px;
        line-height: 16px;
        @include respond(large-up) {
            position: absolute;
            right: 0;
            top: -3px;
            transform: translateX(-174px);
            max-width: 347px;
        }
        p {
            margin: 0;
        }
    }
}

.canada-top-assets {
	@include font-size(11px);
	margin-bottom: 25px;
    @include respond(large-up) {
        width: calc(100% - 325px);
    }
    * {
        @include font-size(11px);
    }
}

.review-order-totals {
	.review-totals,
    .review-sms-wrap {
	   @include respond(large-up) {
	       float: left;
	       width: calc(100% - 325px);
	       &.order-details {
	          width: 100%;
	       }
	   }
       .review-component-block,
       .review-sms-information {
		    margin: 0 0 13px;
		    border: 1px solid $primary-border;
		    padding: 24px 17px 30px;
		    position: relative;
		    @include respond(large-up) {
                margin-bottom: 11px;
                padding: 20px 30px 30px;
		    }
		    .edit-link {
                @include ds-ui-regular(m);
                position: absolute;
                right: 20px;
                top: 20px;
                color: $text-primary;
                text-decoration: underline;

                @include respond(large-up) {
                    right: 30px;
                    top: 20px;
                }
                &:hover {
                    text-decoration: none;
                }
                &:active{
                    color: $click;
                }
		    }
		    .details {
                @include ds-ui-regular(m);
                color: $text-secondary;

                .bold {
                    @include ds-ui-semi(m);
                    color: $text-primary;
                }

			    @include respond(large-up) {
                    float: left;
                    width: 50%;
                }
                & + .details {
                    @include respond(medium-down) {
                        margin-top: 27px;
                    }
                }

				.subtitle {
					color: $text-primary;
					@include ds-heading(h5);
					text-transform: capitalize;
				}

			    h3 {
			        margin-top: 0;
                    line-height: 1.5rem;
			    }
			    .minishipments-method,
			    .minipayments-method {
                    padding: 0 0 5px;
			    }
			    address {
                    font-style: normal;
			    }
			    .mini-payment-title {
                    text-transform: uppercase;
                    &:not(.first) {
                        margin-top: 15px;
                    }
			    }
		    }
		    &.order-gift-message {
                .label {
                    text-transform: uppercase;
                    @include font-size(13px);
                    @include line-height(18px);
                    color: $secondary-color;
                    margin-bottom: 5px;
                    &:before {
						content: '';
						display: inline-block;
						width: 14px;
						height: 15px;
						margin-right: 8px;
						background: url("../images/gift-box-icon.png") no-repeat center;
                    }
                }
                .value {
                    word-break: break-all;
                    @include respond(large-up) {
                        @include font-size(11px);
                    }
                }
		    }
	    }
	}
	.place-order-totals {
		box-sizing: border-box;
		background: $bg-color;
		padding: 0 10px 5px;

		@include respond(large-up) {
			float: right;
			width: 311px;
		}

		@include respond(medium-down) {
			margin: 29px -15px 0;
			padding: 0 15px 25px;
		}

		.checkout-legal-message {
			@include ds-ui-regular(s);
			color: $text-primary;

			a {
				@include ds-ui-regular(s);
				color: $text-primary;
			}
		}

       .submit-order {
           button {
               width: 100%;

               &:hover:not(.disabled), &:focus:not(.disabled) {
                   background-color: $bg-inverse-secondary;
                   color: $text-inverse;
               }
               &:active:not(.disabled) {
                background-color: $bg-tertiary;
                color: $text-inverse;
            }
           }
       }

	   tr.order-total td {
	       padding: 27px 7px 34px;
           @include respond(medium-down) {
               padding: 27px 0 14px;
           }
	   }

		h2 {
			@include ds-heading(h3);
			text-align: center;
			border-bottom: 3px solid $primary-border;
			margin: 0 0 10px;
			padding: 18px 0;
		}
	}
}

.order-summary-title,
.primary-content .order-summary-title {
    @include ds-heading(h3);
    color: $text-primary;
    text-transform: capitalize;
    margin: 28px 0 10px;
    border-bottom: 1px solid $secondary-border;
    padding-bottom: 14px;

    &.virtual-gc-checkout-heading {
    	border-bottom: 1px solid $gainsboro;
    }

    @include respond(large-up) {
        width: calc(100% - 325px);
        &.order-title {
            width: 100%;
        }
    }
    &.returned-title {
        text-transform: none;
    }

	@media only screen and (min-width: $xlMin) {
		@include ds-heading(h2);
	}
}

.order-summary-table {
	&#cart-table {
        @include respond(large-up) {
            width: calc(100% - 325px);
            &.order-details-table {
                width: 100%;
            }
        }
        @include respond(tablet-small) {
            table-layout: auto;
        }
        thead {
            display: table-header-group;
            tr {
                display: none;
                @include respond(large-up) {
                    display: table-row;
                    border-bottom: 1px solid $primary-border;
                }
            }
            th {
                background-color: $white;
                &.header-total-price,
                &.item-qty {
                    text-align: center;
                }
            }

            &.item-details-header {
                @media screen and (max-width: 768px) {
                    display: none;
                }

                &::after {
                    content: "";
                    height: 1.25em;
                    width: 1em;
                    display: block;
                }
            }
        }
        .cart-row {
        	.item-qty, .item-total{
        		@include respond(medium-down) {
        			float:right;
        		}
        	}
	        &.first {
				 td	{
	            	@include respond(large-up) {
	                    padding-top: 32px;
	                }
		         }
		         .item-image {
			          @include respond(large-up) {
			         	height: 166px;
			          }
		         }
			}
		}

		.cart-row {
			border-bottom: 0 none;

			&:not(.first) {
				margin-top: 12px;
			}

            .method {
                padding: 0;
                @include respond(medium-down) {
                    border-bottom: 1px solid $primary-border;
                }
            }

            td {
                @include respond(large-up) {
                    padding-bottom: 0;
                    padding-top: 0;
                }
                @include respond(medium-down) {
                    width: 100%;
                }
            }
            .item-image {
                width: 120px;
                height: 134px;
                @include respond(medium-down) {
                    width: 30%;
                    padding-top: 15px;
                }
            }
            .item-details {
                @include respond(medium-down) {
                    width: 70%;
                }
                .product-list-item {
                    @include respond(large-up) {
                        max-width: 200px;
                    }
                }
            }
            .item-track {
                @include respond(medium-down) {
                    width: 70%;
                }
                .track {
                    @include font-size(14px);
                    color: $cobalt;
                    text-decoration: underline;
                    white-space: nowrap;
                    display: inline-block;
					@include respond(medium-down) {
					   margin-bottom: 13px;
					}
                    &:hover {
                        text-decoration: none;
                    }
                    &:active {
                        color: $click;
                    }
                }
            }
            .item-total {
                @include ds-ui-semi(m);
                color: $text-primary;

                @include respond(large-up) {
                    text-align: right;
                    padding-right: 30px;
                }
                @include respond(medium-down) {
                   width: 70%;
                   padding-top: 10px;
                   padding-bottom: 0;
                   margin-top : 0;                }
            }
            .item-qty {
                @include ds-ui-semi(m);
                color: $text-primary;

                @include respond(large-up) {
                    text-align: center;
                }
                @include respond(medium-down) {
                    width: 70%;
                }
                .mobile-label {
                    text-transform: uppercase;
                    padding-right: 5px;
                    @include respond(large-up) {
                        display: none;
                    }
                }
            }
        }

		.section-header {
			@include ds-heading(h4);
			color: $text-primary;
			padding: 8px 12px 10px;
            text-transform: capitalize;

			&.item-total {
				padding-right: 30px;
			}
		}

        .safari & {
            .cart-row {
                .method {
                    .shipment-title {
                        .tooltip {
                            top: -7px;
                        }
                    }
                }
            }
        }
	}
}
.order-summary-table {
	&#cart-table {
		.cart-row {
			&.gc-only-checkout {
				td {
					@include respond(large-up) {
						padding-top: 30px;
					}
				}
			}
		}
	}
}
#virtual-gc-table {
	@include respond(large-up){
		width: calc(100% - 325px);
		margin-bottom: 30px;
    }
    .item-details{
        padding-left:0;
    }
	.virtual-gc-heading {
        color: #333;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.01em;
        line-height: 20px;
        text-transform: uppercase;
	}
	.virtual-gc {
		font-size: 14px;
        letter-spacing: 0.015em;
		line-height: 16px;

		.virtual-gc-image {
            width: 120px;
            padding: 15px 0;
            @include respond(medium-down) {
                width: 30%;
            }
			.e-gift-card-img {
                max-width: 80px;
                margin: auto;
				img {
                    width: 100%;
				}
			}
		}
	}
	.method{
        padding: 0;
        .shipment-title{
            align-items: center;
            display: flex;
            padding:15px 0 0 0;

            .tooltip {
                position: static;
            }
        }
	}
}
.shipment-title {
    display: flex;
    align-items: center;
	margin-top: 35px;
    padding-bottom: 7px;
    padding: 26px 0 7px;
    border-top: 1px solid $primary-border;

    @include respond(large-up) {
        margin-top: 0;
    }
    &.virtual-gc-checkout {
    	border-top: none;
    	margin-top: 0;
    	padding-top:0;
    }
    &.first {
        margin-top: 0;
        padding-top: 0;
        border: 0 none;
        @include respond(large-up) {
            padding: 0;
        }
    }
    svg, img.icon {
        width: 50px;
        height: 56px;
        float: left;
    }
    .shipping-type {
        display: inline-block;
        @include ds-heading(h5);
        color: $text-primary;

		&.value {
			margin: 0 10px 0 0;
            color: $text-brand-primary;
		}

        &.via {
            @include respond(medium-down) {
                position: relative;
                top: -8px;
                display: block;
            }

        }
    }
    .shipping-icon {
        margin-right: 8px;
        background: url(../images/svg-icons/bopis-shipping.svg) no-repeat;
        background-size: 42px 22px;
        width: 42px;
        height: 22px;
        display: inline-block;
        vertical-align: bottom;
        padding-top: 1px;
    }
    .tooltip {
        & ~ .shipping-type {
            margin-left: 5px;
        }
        @include respond(medium-down) {
            display: none;
        }
    }
}

.order-summary-footer {
    position: relative;
    margin-top: 26px;
    padding-top: 30px;
    border-top: 1px solid $primary-border;
    @include respond(large-up) {
       width: calc(100% - 325px);
       margin-top: 18px;
    }
    .continue {
        @include ds-utilities-button;

        @media screen and (min-width: $mMin) {
            width: auto;
        }

        @include respond(large-up) {
            float: right;
        }
    }
    .auto-refresh-messaging, .checkout-legal-message {
        color: $night-rider;

        font-size: 12px;
        line-height: 16px;
        margin: 31px 0 0;
        @include respond(large-up) {
            position: absolute;
            right: 0;
            top: -3px;
            transform: translateX(-174px);
            max-width: 347px;
        }
        p {
            margin: 0;
        }
    }
}

.summary-help {
    float: right;
    font-size: 0.8em;
    margin: 1em 0;
}

.submit-order {
	clear: both;
	display: block;
	text-align: right;

	button {
		@include ds-utilities-button;
		text-transform: capitalize;
		max-height: 48px;
		width:100%;
		position: relative;
		z-index: 1;

		@include respond(large-up) {
			width:159px;
		}
	}
}

.pt_order-confirmation {
    &#wrapper {
        #main {
            max-width: 954px;
            padding: 0 15px;
        }
    }
    .confirmation-login-title {
        text-align: center;
        margin-top: 34px;
        @include respond(large-up) {
            margin-top: 74px;
        }
        h3 {
            text-transform: none;
            @include font-size(38px);
            @include line-height(38px);
            margin: 0;
        }
    }
    .loyalty-slot-confirm {
    	margin-top: 28px;
    	text-align: center;
    	@include respond(medium-down) {
    		margin-top: 41px;
    	}
    	img {
    	    width: 100%;
    	}
    }
    #RegistrationForm .dialog-required {
    	margin-right: 0;
    }
    .login-create-account {
        border-top: 1px solid $primary-border;
        border-bottom: 1px solid $primary-border;
        margin: 20px -15px 0;

        .required-indicator {
            @include ds-ui-regular(s);
        }

        @include respond(large-up) {
            margin: 20px 0 0;
        }

        h2 {
            @include ds-heading(h3);
            margin: 0;
            cursor: pointer;
            padding: 21px 15px;
            text-align: center;
            position: relative;

            @include respond(large-up) {
                @include font-size(24px);
                padding: 21px 0;
            }

            span.ui-icon{
	            float: right;
	            position: relative;
	            width:20px;
	            height:22px;
	            @include transition(all 0.5s ease-out);
	            background-size: 20px;
	            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
	            top: 7px;
	            left: -7px;
	        }
	        span.ui-icon.iconOpen {
	            background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
	            transform: rotate(180deg);
	        }
            span {
                display: inline-block;
                &:after {
	                display: block;
	                float: right;
	                position: absolute;
	                width:15px;
	                height:15px;
	                @include transition(all 0.5s ease-out);
	                background-size: 20px;
	                background: url("../images/svg-icons/UI-Carat.svg") no-repeat center;
	                top: 23px;
	                right: 15px;
	                @include respond(large-up) {
                        position: relative;
                        right: -27px;
                        top: 4px;
	                }
                }
            }
            &.open {
                span {
	                &:after {
	                    transform: rotate(180deg);
	                }
                }
            }
        }
	    .login-box-content {
	    	overflow: visible;
	        padding: 0 0 60px;
	        //display: none;
	        width: 290px;
	        margin: 0 auto;
	        @include respond(large-up) {
	            padding: 0 0 70px;
	            width: 396px;
	        }
	        @include respond(medium-down) {
	            width: calc(100% - 30px);
	        }
	        #RegistrationForm {
	           .dialog-required-wrap {
	               margin-bottom: 25px;
	           }
	           input[disabled] {
	               background-color: $bg-secondary;
	               color: $text-disabled;
	           }
	           .form-row {
                    label {
                        @include ds-ui-semi(m);
                        color: $text-primary;
                    }

                    span.error {
                        @include ds-ui-regular(s);
                        color:$text-feedback-danger;
                    }

                    .required-indicator {
                        color: $text-feedback-danger;
                    }

	               .error-message {
	                   width: auto;
	                   padding-left: 10px;
	               }
	               &.hidden {
	                   display: none;
	               }
	               &.customeremail {
	                   label {
	                       left: 0;
	                       float: left;
	                       color: $primary-color;
	                       margin: 0;
	                       padding: 2px 0 0;
	                       .required-indicator {
	                           display: none;
	                       }
	                   }
	                   .field-wrapper {
	                       float: left;
	                       width: calc(100% - 35px);
                           position: relative;
                           .foundMatch{
                           	    font-size: 12px;
							    font-size: 0.75rem;
							    color: #CC0000;
							    background: none;
							    text-align: left;
							    padding: 0 0 0.3125rem 0.4rem;
                           }
	                       input {
								border: 0 none;
								background: transparent;
                                @include ds-ui-regular(l);
                                color: $text-primary;
								padding: 0 5px;
								height: 17px;
                                @include respond(large-up) {
                                    position: relative;
                                    left: 1px;
                                }
	                       }
	                   }
	               }
	           }
	           .account-password-restrictions {
					border: 1px solid $primary-border;
					text-align: left;
					padding: 10px 15px;
					margin-bottom: 25px;
					h3 {
					    margin: 0;
					    padding: 0;
					    text-transform: uppercase;
					    color: $secondary-color;
					    @include font-bold(14px);
					}
					ul {
					    margin: 0;
					    padding: 0 0 0 15px;
					    line-height: 1;
					    li {
					      margin: 0;
					      line-height: 1.5;
					    }
					}
                }
                .form-caption {
	                margin: 5px 0 0 5px;
                    @include ds-ui-regular(s);
                    color: $text-secondary;
	            }

                .term-condition {
	                display: block;
	                margin-bottom: 30px;
                    @include ds-ui-regular(s);
                    color: $text-secondary;

	                @include respond(medium-down) {
	                    margin-bottom: 43px;
	                }
	                .with-note {
	                    margin: 3px 0 0 0;
	                    padding: 0;
	                    float: left;
	                    width: 5%;
	                    .form-caption {
	                        display: none;
	                    }
	                }
	                .term-description {
	                    float: right;
	                    width: 92%;
	                    margin-top: 2px;
	                    margin-bottom: 0;
	                    @include respond(medium-down) {
	                       margin-left: 7px;
	                       letter-spacing: -0.1px;
	                    }
                        &, label, a.privacy-policy {
                            color: $text-secondary;
                            @include font-size(16px);
                        }
	                    label {
	                        width: 98%;
	                        cursor: pointer;
	                    }
                        .loyalty-join-terms-label {
                            display: block;
	                        cursor: pointer;
                            float: none;
                            font-weight: bold;
                            margin: 0 0 0.5em 0;
                            padding: 0;
                            width: 100%;
                        }
	                    a {
	                    	color: $night-rider;
	                        @include font-size(13px);
	                        text-decoration: underline;
	                        &:hover {
	                            text-decoration: none;
	                        }
	                        &:active {
	                            color: $click;
	                        }
	                        &.bold-text {

	                        }
	                        &.small-text {
                               @include font-size(10px);
                            }
	                    }
	                    p {
	                        @include font-size(10px);
	                        margin: 0;
	                        padding: 5px 0 0 0;
	                        @include line-height(13px);
	                        color: $dim-gray;
	                    }
	                }
	            }
	            &.loyalty-registration-confirmation {
    	            @include respond(large-up) {
    	                .form-row {
    	                    margin-right: 1px;
    	                    .form-caption {
    	                        margin-left: 0;
    	                    }
    	                }
    	                .form-row,
                        .dialog-required-wrap,
                        .required-pass,
                        .term-condition {
                            max-width: 320px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        .form-row.wide-block {
                            width: 100%;
                            max-width: initial;
                        }
	                }

                    .confirmation-join-today-banner {
                        width: 100%;
                    }
	            }
	        }
	        .button-wrap {
	           @include respond(large-up) {
	               text-align: center;
	           }
	           button {
                @include ds-utilities-button;

                @media screen and (min-width: $mMin) {
                    margin: auto;
                    width: auto;
                }
	           }
	        }
	    }
    }
    .order-confirmation-details {
        margin-top: 34px;
        @include respond(large-up) {
          margin-top: 32px;
        }
        h1 {
            padding-bottom: 14px;
        }
        .order-totals-table {
            .estimatedtax {
                display: none;
            }
        }
    }
    .order-confirm-promo {
        text-align: center;
        margin-top: 42px;
        img {
	        vertical-align: top;
	        max-width: 100%;
        }
        p {
            @include font-size(21px);
            @include line-height(24px);
            color: $secondary-color;
        }
    }
    .confirmation {
        @include respond(medium-down) {
            margin-bottom: 36px;
        }
    }
    .loyalty-confirmation p {
        background: $mint-cream;
        color: $text-feedback-success;
        @include ds-ui-semi(m);
        margin: 0.2em 0;
        padding: 6px 16px;
    }

    div.privacy-cta-block {
        margin: 0 0 1em 0;

        a {
            @include ds-ui-regular(m);
            color: $text-primary;

            @include respond(large-down) {
                margin-bottom: 0;
            }
        }
    }
}

.confirmation-message {
    img {
        vertical-align: top;
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }
    .order-information {
        margin-top: 30px;
        text-align: center;
        @include ds-ui-regular(m);
        color: $text-primary;

        @include respond(large-up) {
            margin-top: 25px;
        }

        div .value {
            @include ds-ui-semi(m);
        }

        .print-wrap {
            margin-top: 22px;
            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }

                &:active{
                    color: $click;
                }
            }
        }
    }
}

.order-confirm-message {
	.content-asset {
	   margin-top: 20px;
	   text-align: center;
	   @include respond(large-up) {
	       margin-top: 24px;
	   }
	   img {
	        vertical-align: top;
	        max-width: 100%;
        }
	}
}

.order-discount {
    color: $text-feedback-danger;
}

.gift-cert-used {
    padding: .83em 0;
}

.order-detail-options {
    border-top: 1px solid $gainsboro;
    margin: 1.67em 0 0;
    padding: 1.67em 0 0;
    text-align: right;
    a {
        margin: 0 .83em;
    }
}

.summary .edit {
    position: absolute;
    text-transform: uppercase;
    right: 10px;
    font-size: 0.9em;
    line-height: 1.5em;
}

.pt_checkout #main,
.pt_cart #main,
.pt_order-confirmation #main {
    padding: 0 1.15em;
}

.pt_order-confirmation .order-confirmation-details .content-header {
    margin: .6em 0 !important;
}

.order-shipment-details {
    .label,
    .firstname,
    .lastname {
        font-weight: bold;
    }
}

.item-shipping-address {
    min-width: 120px;
}

.order-shipment-gift-message-header {
    font-weight: bold;
    font-size: 1.2em;
    padding-bottom: 0px;
}

.order-shipment-gift-message {
    font-size: 1.1em;
}

.billing-error {
    color: $red;
    margin: 0 0 .83em 24%;
}

.billing-expired-card {
    color: $red;
    margin: 5px 0px;
	@include respond(large-up) {
	    margin: 30px 0px 0px 0px;
	}
}

.cvn-tooltip {
    font-weight: bold;
    margin: 5px;
    text-align: center;
    img {
        height: 150px;
        width: 110px;
    }
}

#preferred-store-panel input[type="text"] {
    @include respond(small-down) {
        width: 49.5966%;
    }

    @include respond(medium) {
        width: 49.5966%;
    }
}

@include respond(medium-down) {
    table.item-list {
        .section-header {
            background-image: none;
        }
        .item-image,
        .item-details,
        .item-availability,
        .item-dashboard {
            display: inline-block;
        }
        .item-image {
            width: 30%;
        }
        .item-details {
            width: 50%;
        }
        .item-availability,
        .item-dashboard {
            width: 97%;
        }
        .delete-registry {
            text-align: center;
        }
    }
    .list-table-header {
        padding: 1em !important;
        .button {
            display: block;
            margin: 20px 0;
            width: 196px;
        }
        p {
            display: none;
        }
        button {
            display: block;
            width: 260px;
        }
    }
}
#payment-methods {
    .payment-header {
        padding-bottom: 0;
    }
    .payment-header-info {
        @include ds-ui-bold(s);
        color: $text-secondary;
        display: flex;
        margin: 12px 0 24px;

        .lock-icon {
            background: url(../images/svg-icons/lock.svg) no-repeat;
            background-size: cover;
            content: '';
            display: inline-block;
            filter: $icon-secondary-filter;
            font-weight: normal;
            height: 16px;
            margin: 0 5px 0 -2px;
            padding: 0;
            text-transform: none;
            width: 16px;
        }

        @include respond(large-up) {
            padding-left: 2%;
        }
    }
}
.secure-icon-input-wrapper {
    position: relative;

    &::after {
        background: url(../images/svg-icons/lock.svg) no-repeat;
        content: '';
        filter: $icon-secondary-filter;
        height: 24px;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
    }
}
.payment-method{
	#creditCardList{
		text-align: left;
	}
	.billing-new-card{
		&.hidden {
        	display: none;
    	}
		.max-card-error{
			color:$red;

			font-size: 0.75rem;
			margin:0 0 15px 2%;
			padding:0 0 0 24px;
			width:55%;
			@include respond(small-down){
				margin:0 0 20px;
				width:80%;
			}
			@include respond(small){
				margin:0 0 20px;
				width:80%;
			}

		}
	}
    .billing-saved-card {
        position: relative;
        border-bottom: 3px solid $primary-border;
        .billing-expired-card {
	        width: 200px;
    		position: absolute;
    		top: 25px;
    		right: 80px;
            @include respond(medium-down) {
	            position:static!important;
			}
        }
        @include respond(medium-down) {
            margin: 0 -14px;
            padding:  15px 14px 23px;
        }
        @include respond(large-up) {
            margin-left: -2%;
            padding: 20px 0 20px 2%;
        }
        .saved-card-info {

            @include respond(medium-up) {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }

	        .saved-card-title {
                @include ds-ui-semi(m);
                color: $text-primary;
	        }

	        .card-radio {
	            margin: 16px 0 0;
				@include respond(small-down){
					position: relative;
				}
				@include respond(small){
					position: relative;
				}
	            @include respond(large-up) {
	               margin-bottom: 12px;
	            }

                label {
                    @include ds-ui-regular(m);
                    color: $text-primary;
                }
	        }
        }
        .error-text {
	            color: $red;
	     }
		.card-cvn {
            margin: 28px 0 0;
            padding-left: 36px;

            &.hidden {
                display: none;
            }
            @include respond(medium-up) {
                margin: 0;
                padding: 0;
            }
            .form-row {
                margin: 0;
                .form-field-tooltip {
                    margin-bottom: 0;
					@include respond(small-down){
						position: relative;
						top: 1px;
					}
					@include respond(small){
						position: relative;
						top: 1px;
					}
                }
            }
			label {
                display: block;
			    left: -10px;
			    position: relative;
			    @include respond(large-up) {
                    margin: 4px 0 11px;
			    }
			}
		}
        .card-delete {
            @include respond(large-up) {
                padding-top: 5px;
            }
			.remove {
			    text-align: center;
			    @include respond(medium-down) {
			        position: absolute;
			        top: 20px;
			        right: 12px;
			    }
			    svg, img.icon {
			        width: 19px;
			        height: 19px;
			    }
                span {
	                display: block;
	                @include font-size(8px);
	                text-transform: uppercase;
	                @include respond(medium-down) {
	                   display: none;
	                }
                }
			}
        }

        .billing-exposed-card-delete {
            margin-bottom: 1rem;
            @include respond(large-up) {
                padding-top: 5px;
            }
            .remove {
                @include ds-ui-regular(s);
                text-decoration: underline;
            }
        }

		.default-label{
            @include ds-ui-regular(m);
            color: $text-secondary;
			clear:left;
			display: block;
			margin:0;
			padding:0 0 0 26px;
			width:100%;

			@include respond(small-down){
				float:right;
				padding:0;
				position: relative;
				text-align:right;
				top:-20px;
				width:50%;
			}
			@include respond(small){
				float:right;
				padding:0;
				position: relative;
				text-align:right;
				top:-20px;
				width:50%;
			}
		}
	}
}

.verify-field {
    @include ds-ui-semi(s);
    color: $text-primary;
    margin: 20px 0;
    text-align: center;
}

.promo-msg{
    @include ds-body(m);
    color: $text-secondary;
    margin: 16px 0 0;
}

.summary-error {
    @include ds-ui-semi(m);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 3px 12px 3px 10px;
    margin-bottom: 20px;
    &:before {
	     content: '';
	     display: block;
	     width: 26px;
	     height: 26px;
	     float: left;
	     margin: 0px 20px 0 0;
	     background: url("../images/svg-icons/Alert-Error.svg") no-repeat center;
	}
	@include respond(medium-down) {
	   &:before {
	       width: 50px;
	       height: 40px;
	   }
	}
}

.add-new-card-wrap {
    @include ds-ui-regular(m);
    color: $text-primary;
    border-bottom: 3px solid $primary-border;

    &.billing-exposed-add-new-card {
        border-bottom: none;
    }

	@include respond(medium-down) {
		margin: 0 -14px;
		padding:  26px 14px;
    }

    @include respond(large-up) {
        margin-left: -2%;
        padding:  26px 2%;
    }

    label {
        @include ds-ui-regular(m);
        color: $text-primary;
    }
}

.field-wrapper{
    &.focused {
        .sms-number,
        .phone,
        .email {
            border: 1px solid $primary-border;
            & + span.error {
                display: none !important;
            }
        }
    }
}
.ui-dialog.giftCard-dialog .dialog-titlebar{
    border-bottom: 0;
    margin-bottom: 5px;

}

.ui-dialog.giftCard-dialog{
	overflow: visible;
	#dialog-container{
		overflow: visible;
	}
	.form-field-tooltip{
		padding-bottom: .75em;
		margin-left: 0;
	}
}

.bbwd-tooltip-content-container{
	p{
		&.screen-reader-content{
			margin: 0;
			height: 0;
			opacity: 0;
		}
	}
}

.ui-dialog.giftCard-remove-dialog {
    #dialog-container {
    	padding: 0px 23px 24px !important;
    	@include respond(small-down-iphone) {
    		padding: 0px 9px 9px !important;
    	}
    }
     .ui-dialog-titlebar {
    	display:none;
    	}
}
.ui-dialog .gift-card {
    border-bottom: 0;
    margin-bottom: 5px;
    .gift-card-cancel-text {
    	padding: 28px 50px 15px;
    	text-align: center;
    	line-height: 1.8em;
    	font-size: 1.1em;
   		@include respond(small-down-iphone) {
   			padding: 28px 40px 15px;
   		}
    }
    a.button-fancy-large.remove {
    	background-color: #333;
    	border: 1px solid #333;
    	color: $white;
    	cursor: pointer;
    	display: inline-block;
		text-align: center;
		transition: all 0.3s ease;
		max-height: 35px;
		letter-spacing: 0.12em;
		border-radius: 0;
		padding: 0 2.3em;

    	font-weight: normal;
    	line-height: 2.0625rem;
    	float:left;
    	font-size:1em;
    	&:hover{
    		color: #333;
    border-color: #F2F2F2;
    background-color: #F2F2F2;
    }
    }
   button.button-fancy-large{
		font-size: 1em;
    	margin-left: 20px;
    	padding: 0 2.3em;
    	@include respond(small-down-iphone) {
    		margin-left: 10px;
    	}
   }
}
.dialog-content{
    .billing-coupon-code{
        .form-row {
            text-align: left;
            margin-bottom: 25px;
            label {
                margin-bottom: 10px;
            }
            input[name$="_giftCertPin"] {
                width: 60%;
            }
            &.help {
                position: relative;

                .form-field-tooltip {
                    position: absolute;
                    left: 64%;
                    bottom: -8px;
                }
            }
        }
        #check-giftcert-modal{
            margin: 25px 0 ;
        }
        .g-recaptcha-checkbalance {
            @include respond(small-down) {
                transform: scale(0.7);
                transform-origin: 0 0;
            }
        }
        .wrap-balance-gc{
            text-align: center;
            padding: 30px 0;
            border: 1px solid $egg-blue;
            margin: 0 0 20px;
            width: 100%;
            &.hidden{
                display: none;
            }
            &.error{
                border: 1px solid $red;
            }
            .balance{
                color: $egg-blue;
                @include font-bold(14px);
                margin: 0 0 15px;
                text-align: center;
                padding: 0 15px;
                &.error{
                    color: $red;
                    & + #add-giftcert{
                        display: none;
                    }
                }
            }

        }
        .giftcert-error-top, .giftcert-error-bottom {
            color: $red;
            padding-bottom: 20px;
        }
    }
}
.ui-dialog.resetpassword {
	.ui-dialog-title {
		margin-bottom: 0;
	}
	#dialog-container > p {
		margin-top: 12px;
		margin-bottom: 18px;
	}
	.ui-dialog-content {
		margin-bottom: 8px;
	}
	.request-password-complete {
		margin-top: 17px;
		margin-bottom: 8px;
	}
	.request-password-button {
		margin-top: 29px;
		margin-bottom: 31px;
		.button {
			display: block;
			margin: 0 auto;
			width: 62.5px;
		}
 	}
 	.resetSuccess{
 		display: none;
 	}
 	.request-password-text-header{
 		display: none;
 	}
 	.request-password-text {
	    border: none;
	    padding: 0px;
	    width:auto;
	    margin-left: 0;
	    p{
	    	text-align: left;
	    }
	}
}
.order-conf-pass{
	.pw-requirements {
	    list-style: none;
	    margin: 0 auto;
        width: 270px;
	    -webkit-padding-start: 0;
	    @include respond(medium-down) {
	        margin: 0 auto;
	        -webkit-padding-start: 0;
	    }
	    @include respond(small) {
	    	margin-left: 0px;
	    }
	    .pw-requirement {
	        @include font-size(12px);
	        background: none;
	        padding: 0 0 calculateRem(18px) 0;
	        position: relative;
	        top: calculateRem(5px);
	        line-height: unset;
	        span {
	            display: inline-block;
	            padding:0;
	            margin-bottom: -3px;

	            color: $dim-gray;
	            @include font-size(.75rem);
	             &.error {
	                color: $red;
	                float: none;
	                padding: 0;
	                top: 0;
	                .circle {
	                    border-color: $red;
	                }
	            }
	            &.success {
	                color: $egg-blue;

	                .circle {
	                    width: 22px;
                        height: 22px;
                        border: none;
                        background: url("../images/svg-icons/Alert-Check.svg") no-repeat center;
                        margin: 0;
	                }
	            }
	            &.circle {
	                border: 1px solid $dim-gray;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    display: inline-block;
                    left: 0;
                    margin: 3px;
                    position: absolute;
                    top: -1px;
	            }
	        }
            > span {
                line-height: calculateRem(22px);
                padding-left: 30px;
            }
	    }
	}
}
#password-requirements {
    display:none;
    #RegistrationForm & {
        margin-bottom: 40px;
        margin-top: 32px;
        padding-left: 0;
    }
}
input.error-new {
    border-color: $sangria;
}
.ui-dialog.rewardspopupimg {
	background-color: transparent;
    .ui-dialog-content {
    	padding: 0
    }
    img {
    	width: 100%;
    }
    .ui-dialog-title {
    	display: none;
    }
    .ui-dialog-titlebar-close {
    	top: -1px;
    }
    @include respond(medium-down) {
    	width: 290px !important;
    	.ui-dialog-titlebar-close {
    		right: 21px;
    	}
    }
}
.ui-tooltip.shipping-tooltip-wrapper {
	border-color: $night-rider;
	&.ui-widget-content:before {
		border-right-color: $night-rider;
	}
	.ui-tooltip-content {
		width: 360px;
		padding: 15px 20px;
		@include respond(medium-down) {
			width: auto;
			padding: 15px 15px;
		}
	}
	.shipping-method-tooltip {
		.label {
			@include line-height(18px);
		}
		p {
			color: $night-rider;
		}
	}
	@include respond(medium-down) {
		max-width: 300px;
		margin-left: 3.125vw;
		p {
			@include line-height(18px);
			@include font-size(13px);
		}
	}
	@include respond(small) {
		max-width: 270px;
	}
}

.success-message {
    @include respond(large-up) {
        padding: 14px 15px 11px;
        margin-left: 25px;
        margin-top: 20px;
    }
    padding: 12px 15px 10px;
    background-color: $mint-cream;
    margin-top: 15px;
    color: $jatc-green;
    @include font-size(14px);
    @include line-height(16px);

}

.confirmation{
	.order-confirmation-details {
		.alert {
			padding: 15px;
		    background-color: #FFE9E9;
		    color: white;
		    margin-top: 20px;
		    .frozen-notification {
		    	color: #CC0000;
			    font-size: 0.875rem;

			    .content-asset {
			    	display: inline;
			    }
			    a {
			    	color: #CC0000;
			    }
		    }
		}
	}
}
.angel-card-error-msg {
	background-color: #E6F5FF;
}
.copy-message-vs-card {
	color: #1B79AA;
    font-size: 14px;
    padding: 6px 31px 6px 15px;
    margin: 0;

    @include respond(medium-down){
    	padding: 6px 9px 6px 15px;
    	text-align: left;
    }
}
.angel-card-loyalty{
	margin-top: 11px;
}
.old-angel-card-tele{
	white-space: nowrap;
}
.review-pickup-info-wrap {
    display: flex;
    flex-direction: column;
    @include respond(large-up) {
        float: left;
        width: calc(100% - 323px);
        flex-direction: row;
        margin-bottom: 8px;

        &.order-detail-review-box {
            width: 100%;
        }
    }
    .review-pickup-information, .review-store-details {
        box-sizing: border-box;
        border: 1px solid $gainsboro;
        padding: 23px 29px 35px 30px;
        position: relative;
        flex: 1 1 50%;
        @include respond(medium-down) {
            flex: 1 1 100%;
            padding-right: 22px;
        }
    }
    .review-pickup-information {
        @include ds-ui-regular(m);
        margin-right: 6.5px;

        @include respond(medium-down) {
            margin-right: 0;
            margin-top: 0;
        }
        .edit-pickup-person-link,
        .edit-sms-info-link {
            @include ds-ui-regular(m);
            color: $text-primary;
            position: absolute;
            right: 22px;
            top: 27px;
            text-decoration: underline;

            &:hover,
            &:focus{
                color: $cobalt;
            }
            @include respond(large-up) {
                right: 30px;
                top: 27px;
            }
        }
        .pickup-information-heading {
            @include ds-heading(h5);
            color: $text-brand-primary;
            margin: 0;
            padding-bottom: 15px;
            text-transform: capitalize;
            .pickup-icon {
                background: url(../images/svg-icons/bopis-icon-small.svg) no-repeat;
                background-size: 20px 20px;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                display: inline-block;
                top: 5px;
                position: relative;
            }
        }

		.pickup-person-info {
			@include ds-ui-semi(m);
			color: $text-primary;
			margin-bottom: 11px;
		}

        .pickup-alert {
            @include ds-ui-regular(m);
            color: $text-secondary;
            margin-top: 0;
        }
    }
    .review-store-details {
        margin-left: 6.5px;

		@include respond(medium-down) {
			margin-top: 13px;
			margin-left: 0;
		}

		.details-heading {
			@include ds-heading(h5);
			color: $text-primary;
			margin: 0 0 13px 0;
			padding-bottom: 0;
			text-transform: none;
		}

		.store-name {
			@include ds-ui-semi(m);
			color: $text-primary;
			margin: 0 0 11px 0;
			text-transform: capitalize;
		}

        .store-address {
            @include ds-ui-regular(m);
            color: $text-secondary;

            .address-city-state-zip {
                text-transform: capitalize;
                margin-bottom: 11px;

                .city-state-name {
                    text-transform: capitalize;
                }
            }
            .store-phone-no {
                margin-bottom: 13px;
            }
        }
    }
}

.pickup-confirmation-msg-wrap {
    @include respond(medium-down) {
        display: flex;
        justify-content: center;
    }

    .pickup-confirmation-msg-container {
        background-color: #E6F5FF;
        padding: 15px 36px 15px 15px;
        width: 549px;
        margin: 30px auto 0 auto;
        color:$text-primary;

        @include respond(medium-down) {
            padding-bottom: 20px;
            padding-right: 21.4px;
        }

        .msg-content {
            padding: 0 0 0 25px;
            line-height: 0;
            margin: 0;

            li {
                @include ds-ui-regular(m);
                color: $text-feedback-info;
                padding-bottom: 5px;
                padding-left: 10px;
                margin-bottom: 0;
                &:last-child {
                    padding-bottom: 0;
                }
            }

            .customer-care-link {
                color: $link-primary-default;
                text-decoration: underline;
            }
        }

        .msg-heading {
            @include ds-ui-semi(m);
            color: $text-feedback-info;
            margin: 0 0 6px 0;
        }
    }
}

.order-confirmation-details-wrap {
    .order-confirmation-details-container {
        width: 463px;
        margin: 20px auto 10px auto;
        border-top: 2px solid $white-smoke;
        padding: 20px 68.5px 0 68.5px;
        font-size: 12px;
        color: $night-rider;
        display: flex;
        justify-content: center;
        @include respond(medium-down) {
            width: auto;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
        }
        .order-shipping-container {
            padding-right: 30px;
            border-right: 3px solid $white-smoke;

            line-height: 16px;
            text-align: center;
            width: 200px;
            max-width: 200px;
            @include respond(medium-down) {
                padding-left: 13px;
                padding-right: 20px;
            }

            .shipping-count-details {
                @include ds-heading(h5);
                color: $text-primary;
                margin-bottom: 15px;
            }

            .shipping-address {
                @include ds-ui-regular(m);
                color: $text-primary;
                text-transform: capitalize;
            }

            .shipping-icon {
                background: url(../images/svg-icons/bopis-shipping.svg) no-repeat;
                background-size: 30px 15px;
                width: 30px;
                height: 15px;
                display: inline-block;
                margin-top: 10px;
            }
        }
        .order-pickup-container {
            padding-left: 30px;

            line-height: 16px;
            text-align: center;
            width: 200px;
            max-width: 200px;
            @include respond(medium-down) {
                padding-left: 20px;
                padding-right: 14px;
            }

            &.only-pickup {
                padding-left: 0;
            }

            .pickup-count-details {
                @include ds-heading(h5);
                margin-bottom: 15px;
            }

            .pickup-address {
                @include ds-ui-regular(m);
                color: $text-primary;
                text-transform: capitalize;
            }

            .pickup-icon {
                background: url("../images/svg-icons/bopis-icon-small.svg") no-repeat;
                background-size: 18px 23px;
                width: 18px;
                height: 23px;
                display: flex;
                margin: 3px auto 0 auto;
                margin-bottom: 3px;
            }
        }
    }
}

.order-totals-table {
    .order-subtotal {
        td:first-of-type {
            display: flex;
            align-items: center;
        }
    }
    .ds-tooltip-container.ds-iroll {
        display: inline-block;
        margin-bottom: 0;
        margin-left: 6px;

        .ds-simpletooltip_container {
            margin-top: 0;

            .ds-tooltip-button {
                background-size: contain;
                height: 22px;
                width: 22px;
            }
        }
    }
    .shipping-surcharge-wrap {
        display: none;
        padding: 10px 0;
    }
}

.pt_cart .shipping-surcharge-wrap {
    display: table-cell;
}

.review-order-totals{
    &.order-review-section{
        .place-order-totals{
            @include respond(large-up){
                position: absolute;
                top: 73px;
                right: 0;
            }
        }
    }
    &.confirmation-review-section{
        .place-order-totals{
            @include respond(large-up){
                position: absolute;
                right: 0;
            }
            @include respond(medium-down){
                margin-bottom: -4px;
            }
        }
    }
}

#shipping-surcharge {
    background-color: $bg-primary;
    color: $text-brand-primary;
    font-weight: normal;
    padding: 16px;
    text-transform: none;

    .bolded {
        font-weight: bold;
    }
    .surcharge-message {
        position: relative;
        padding-left: 32px;

        &::before {
            background: url(../images/svg-icons/info.svg) no-repeat;
            background-size: cover;
            content: '';
            display: inline-block;
            filter: $icon-brand-primary-filter;
            height: 24px;
            left: 0;
            position: absolute;
            width: 24px;
        }
    }
    .continue-link {
        color: $text-primary;
        padding-left: 32px;
        text-decoration: underline;
    }
}

.pickup-store-map-section-wrap {
    .pickup-store-map-section-container {
        width: 450px;
        margin: 0 auto 27.5px auto;
        padding: 20px 74.5px 0 75.5px;
        font-size: 12px;
        color: #333;
        display: flex;
        justify-content: center;
        @include respond (medium-down) {
            width: auto;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .pickup-store-map-box {
        width: 450px;
        border: 3px solid #F2F2f2;
        padding: 14px 35px 26px 44px;
        @include respond(medium-down) {
            padding: 9px 12px 21px 17px;
        }
    }

    .pickup-person-section {
        @include ds-ui-semi(m);
        color: $text-primary;
        text-align: center;
    }

    .pickup-details-wrap {
        margin-top: 10px;
        display: flex;
        @include respond(medium-down) {
            justify-content: center;
        }
        .map-section {
            width: 200px;
            margin-top: 4px;
            margin-right: 20px;
            @include respond(medium-down) {
                width: 100px;
                margin-right: 10px;
            }
            .map, .static-google-map {
                width: 200px;
                height: 116px;
                overflow: hidden;
                @include respond(medium-down) {
                    width: 100px;
                }
            }
            .store-map {
                border-bottom: none;
            }
        }
        .pickup-details {
            .pickup-store-wrap {
                @include ds-ui-regular(m);
                color: $text-primary;

                .store-name {
                    @include ds-ui-semi(m);
                    color: $text-primary;
                    text-transform: capitalize;
                    margin-bottom: 8px;
                }
            }
            .store-details {
                .store-details-wrap {
                    @include ds-ui-regular(s);
                    color: $text-secondary;

                    .store-address {
                        a {
                            text-transform: capitalize;
                            @include dim-gray-link;
                        }
                    }
                    .store-phone-no {
                        margin-top: 10px;
                        a {
                            @include dim-gray-link;
                        }
                    }
                    .store-hours {
                        margin-top: 10px;
                        .store-time {
                            text-transform: lowercase;
                        }
                    }
                }
            }
        }
    }
}
.review-order-summary{

    &.shipping-items-head,
    &.pickup-items-head{
        .shipping-type.value{
            @include ds-heading(h5);
            color: $text-brand-primary;
            display: inline-block;
            margin-left: 0;
            text-transform: capitalize;
        }

        .items-head-wrap {
            @include ds-heading(h5);
            color: $text-primary;
            display: inline-block;
            flex: 1;
                span {
                    display: inline-block;
                }
             }

             span.shipping-type{
                &.via{
                    @include respond(medium-down){
                        top: 0;
                        display: inline-block;
                    }
                }
            }
        }
    &.shipping-items-head{
        margin-top: 22px;
        margin-bottom: 21px;
        margin-left: 4px;
        font-size: 0;
        &.only-shipping-heading{
            margin-top: 24px;
            margin-bottom: 18px;
            @include respond (medium-down){
                margin-top: 20px;
            }
        }
        @include respond(medium-down){
            margin-top: 20px;
            margin-bottom: 8px;
        }

        .shipping-type.value{
            margin-right: 5px;
            padding-bottom: 1px;
        }

        .items-head-wrap{
            padding-bottom: 1px;
            .shipping-type.via{
                white-space: pre;
            }
        }
    }
    &.pickup-items-head{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 6px;
        @include respond (medium-down){
            margin-left: 4px;
        }
        .pickup-icon{
            margin-right: 8px;
            background: url(../images/svg-icons/bopis-icon-small.svg) no-repeat;
            background-size: 24px 30.92px;
            width: 24px;
            height: 30.92px;
            display: inline-block;
        }
        .shipping-type.value{
            margin-right: 2px;
        }

        .shipping-type.via{
            text-transform: capitalize;
        }
    }
}

.order-summary-table{
    &#cart-table{
        .item-image{
            @include respond(large-up){
                height: auto;
            }
        }
        tr.pickup-items-heading{
            border-bottom: 1px solid $gainsboro;
            @include respond(medium-down){
                display: none;
            }
                th#item-qty,
                th#item-price{
                    text-align: center;
                }
        }

        .shipment-title{
            &.pickup-items-wrap{
                padding-top: 30px;
                margin-top: 48px;
                padding-bottom: 14px;
                &.only-pickup-heading{
                    margin-top: 19px;
                    margin-bottom: 14px;
                }
                @include respond(medium-down){
                    padding-top: 25px;
                    padding-bottom: 15px;
                    margin-top: 35px;
                }
            }
        }
        .cart-row{
            .method.pickup-state{
                @include respond(medium-down){
                    border-bottom: none;
                }
            }
        }
    }
}

.order-details-button-wrap {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .view-order-details-btn {
        @include ds-utilities-button;
        border: 2.5px solid $night-rider;
        padding: 9.5px 22.5px 8.5px;

        @media screen and (min-width: $mMin) {
            width: auto;
        }
    }
}

.order-details-horizontal-line{
    border-top: 2px solid $white-smoke;
    width: 463px;
    padding: 0 68.5px;
    margin: 40px auto 0 auto;
    @include respond(medium-down){
        width: auto;
        padding-left: 0;
        padding-right: 0;
    }
}

.order-summary-table#cart-table{
    &.only-shipping{
        margin-bottom: 0;
    }
}

.shipment-title{
    &.pickup-items-wrap{
        &.only-pickup-heading{
            margin-top: 20px;
            margin-bottom: 16px;
            @include respond (medium-down){
                padding: 0;
                margin-top: 17px;
                margin-bottom: 10px;
            }
        }
    }
}
.checkout-header{
		.top-banner {
			.wrap{
			@include respond(medium-down){
				border-bottom : none;
			}
				.icon-items{
				@include respond(medium-down){
					overflow : visible;
					padding : 0;
				}
			}
		}
	}
}

.skip-to-payment-reference {
    visibility: hidden;
}
.cart-wrap {
	.frequency_block {
		width: 125px;
		position: relative;
		padding-left: 25px;
		.frequency_indicator {
		    &::before {
			    position: absolute;
			    left:0;
			    top: 8px;
		     }
	    }
	}
}

.frequency_block.freq_jatc-modal {
    display: none;
}
.ui-dialog {
    .frequency_block.freq_mini-cart,.mini-dashboard-shipping-text {
	    display: none!important;
    }
}
.frequency_block {
	@include ds-heading(h5);
	margin: 15px 0;

	.frequency_indicator {
		&::before {
			background: url(../images/svg-icons/og-icon.svg) no-repeat;
			width: 1.375rem;
			height: 0.9375rem;
			display: inline-block;
			content: '';
			vertical-align: text-bottom;
		}

		span {
			color: $text-brand-primary;
		}
	}
}

.checkout-mini-cart, .mini-cart-content {
	.frequency_block.jatc-freq[subscribed|=""] {
        ~ {
			.mini-dashboard-shipping-text {
				display: none;
            }
        }
    }

    span.mini-cart-price {
        @include ds-ui-semi(m);
        color: $text-primary;
    }

    .mini-cart-price {
        @include ds-ui-semi(m);
        color: $text-primary;
	    float: right;

        .price-sales {
            @include ds-ui-semi(m);
            color: $text-primary;
        }
	}

    .mini-cart-pricing.mini-cart-price {
        @include ds-ui-semi(m);
        color: $text-primary;
    }

    span.mini-cart-price{
        @include ds-ui-semi(m);
        color: $text-primary;
    }
}
.mini-dashboard-pickup-text {
    ~ {
        .mini-dashboard-shipping-text {
            display: none;
        }
    }
}
.ui-dialog {
	.frequency_block.freq_jatc-modal {
		display: block!important;
	}
}

#creditcard-remove-confirm {
    display: none;
    h1 {
        text-transform: uppercase;
    }

}

.ui-dialog.creditcard-remove-dialog {
    .ui-dialog-title {
        display: none;
    }
    .ui-dialog-buttonset {
        border-top: 0px;
    }
    .ui-dialog-buttonpane {
        background: none;
    }
    h1 {
        text-transform: uppercase;
        font-weight: bold;
    }
}

.confirmation-message {
    margin-bottom: 15px;
    font-size: 15px;
}

.cancelbutton {
    background: none!important;
    border: none;
    padding: 0!important;
    color: black;
    text-decoration: underline;
    cursor: pointer;
    text-transform: capitalize;
    width:100%;
}

.deletebutton {
    width: 100%;
}
.mini-dashboard-shipping-text {
    @include ds-heading(h5);
    color: $text-brand-primary;
    display: flex;


    .shipping-icon {
        background: url('../images/svg-icons/bopis-shipping.svg') no-repeat;
        background-size: 30px 15px;
        background-position: center;
        height: 20px;
        width: 30px;
        margin-right: 5px;
        display: flex;
    }
}

.sms-element {
    margin-bottom: -20px;

    .input-checkbox {
        width: 16px;
        height: 16px;
    }
    .form-row.checkbox {
        span.required-indicator {
            position: absolute;
            left: 0;
            top: 0;
            color: #c00;
        }
        .field-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
        }
    }
    .legal-acknowledge-wrapper {
        height: auto !important;
        margin-bottom: 0;
        span.error {
            position: absolute;
            bottom: -24px;
            left: 0px;
            top: auto;
        }
    }
    .contact-wrapper {
        padding-left: 24px;
    }
    .bopis-sms-contact,
    .content-asset {
        display: none;
    }
}

.pt_checkout .form-row.checkbox {
    input[type="checkbox"] {
        margin-top: 2px;
        min-height: 16px;
        min-width: 16px;
        vertical-align: top;
    }
    label span span {
        color: $grey-80;
        margin-left: 0;
        padding-left: 0 !important;
    }
}
.legal-acknowledge-wrapper {
    height: auto !important;
    margin-bottom: 0;

    .field-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
    }
    span.error {
        position: absolute;
        bottom: -16px;
        left: 33px;
        top: auto;
    }
    .input-checkbox {
        width: 16px;
        height: 16px;
    }
    .required-indicator {
        @include font-size(16px);
    }
    .sms-optin-label {
        @include ds-ui-bold(m);
        margin-bottom: 8px;
    }
    .sms-optin-label-legal {
        @include font-size(14px);
        @include line-height(20px);
        font-weight: 400;

        a {
            text-decoration: underline;
        }
    }
}

.pt_checkout form.address .sms-element legend {
    padding-top: 10px;
}

.pt_checkout form.address .form-row .add-new-card-wrap label {
    @include ds-ui-regular(m);
    color: $text-primary;
}

.contact-wrapper {
    span.error {
        padding-left: 0;
    }
}

.secure-payment-container {
    padding: 0 0 17px 2%;

    .secure-payment-init-ui {
        display: none;
        padding-left: 19px;
        margin-bottom: 20px;

        &.active {
            display: block;
        }
    }

    .launch-secure-payment-button {
        display: inline-block;

        font-weight: 400;
        padding: 0 1.0625em;
        font-size: 11px;
        font-size: .6875rem;
        cursor: pointer;
        background-color: #333;
        border: 1px solid #333;
        line-height: 33px;
        line-height: 2.0625rem;
        color: #fff;
        text-align: center;
        transition: all .3s ease;
        max-height: 35px;
        letter-spacing: .12em;
        border-radius: 0;
        margin-right: 20px;
    }
    .secure-payment-message {
        width: 100%;
        height: 0;
        box-sizing: border-box;
        padding: 0 10px;

        visibility: hidden;
        overflow: hidden;
        transition: 0.3s height, 0.3s margin, 0.3s padding, 0s visibility 0.3s;
        margin-bottom: 0;

        &.new-session {
            &.initiated {
                background-color: #e6f5ff;
                color: #285699;
                a {
                    color: #285699;
                }
            }
            &.complete {
                background-color: #e9fff2;
                color: #1e6a49;
                a {
                    color: #1e6a49;
                }
            }
            &.expired,
            &.failed {
                background-color: #fcdedf;
                color: #cc1900;
                a {
                    color: #cc1900;
                }
            }
            a {
                text-decoration: underline;
            }
        }

        &.initiating {
            background-color: #e6f5ff;
            color: #285699;

            :after {
                overflow: hidden;
                display: inline-block;
                vertical-align: bottom;
                -webkit-animation: ellipsis steps(4,end) 900ms infinite;
                animation: ellipsis steps(4,end) 900ms infinite;
                content: "\2026"; /* ascii code for the ellipsis character */
                width: 0px;
            }

            @keyframes ellipsis {
                to {
                    width: 1.25em;
                }
            }

            @-webkit-keyframes ellipsis {
                to {
                    width: 1.25em;
                }
            }
        }

        &.active {
            height: auto;
            visibility: visible;
            padding: 13px 10px;
            margin-bottom: 20px;
            transition: 0.7s height, 0.7s margin, 0.7s padding, 0s visibility 0.7s;
        }
    }
    .secure-payment-active-ui {
        display: none;

        &.active {
            display: block;
        }
    }

    #pcipal {
        width: 100%;
        height: 359px;
        border: solid 3px #f8f8f8;
        background:#f8f8f8;
    }
}

@include respond(large-up) {
    #securePaymentUI .cvv-row .field-wrapper {
        width: 169px;
    }
}

.gift-option {
    @include ds-ui-regular(m);
    color: $text-primary;
    background: $bg-primary;
    border: 1px solid $primary-border;
    margin-top: 48px;
    padding-top: 16px;

    b {
        font-weight: 700;
    }

    .gift-title, .gift-input {
        margin: 0px 18px 16px 18px;

        .gift-text {
            margin-left: 10px;
            max-width: calc(90% - 24px);

            p {
                @include ds-ui-regular(m);
                color: $text-primary;
                margin: 0 auto;

                &.toggle-with-gift-checkbox {
                    padding-bottom: 16px;

                    &.hidden {
                        display: none;
                    }
                }
            }
        }

        div {
            display: inline-block;
        }
    }

    div.gift-input {
        margin: 0px 18px 0px 18px;
    }

    .gift-icon, .gift-text, .gift-checkbox {
        vertical-align: top;

        input, div.form-row.checkbox  {
            width: 18px!important;
            height: 18px!important;
            margin: 0 auto;
            margin-left: 1px;
        }
    }

    label {
        display: none;
    }

    .gift-icon {
        width: 21px;
        height: 21px;
        flex-shrink: 0;
    }

    .gift-message-box {
        background-color: $bg-primary;
        padding-bottom: 16px;

        @include respond(medium-up) {
            .form-row .field-wrapper {
                width: unset!important;
                max-width: unset!important;
                width: 100%!important;
            }
        }

        .form-row.gift-message-text.textarea  {

            .gift-textarea-desc {
                @include ds-ui-regular(s);
                color: $bg-inverse-secondary;
                text-align: left;
                padding-left: 6px;
                margin-bottom: 4px;

                b {
                    color: $text-primary;
                    font-weight: 600;
                }
            }

            textarea::placeholder {
                color: $text-tertiary;
            }

            .char-count {
                @include ds-ui-regular(s);
                color: $bg-inverse-secondary;
            }

            .gift-textarea-forbidden {
                @include ds-ui-regular(s);
                font-size: 12px;
                color: $text-subtle;
                text-align: left;
                padding-left: 6px;
                margin-top: 4px;

                b {
                    color: $text-primary;
                }

                .gift-to {
                    margin-top: 16px;

                    span {
                        color: $text-primary;
                    }
                }

                .gift-from {
                    margin-top: 6px;
                }
            }

        }
    }

}
