.shippingaddress {
    .select-address {
        width: 100%;
        margin-left: 0;
    }
    .edit-address {
        margin-top: 0.5em;
    }
}

.formactions {
    margin-top: 20px;
    text-align: right;
}

.item-list .multi-shipment-row {
    border-bottom: none;
}

.minishipments-method {
    padding-top: 10px;
}

.multishipprogress {
    div, .step-3 {
        display: inline;
        padding: 1em;
    }
    .step-4 {
        padding-right: 0;
    }
}

.pt_checkout #cart-table .cart-row .shipment-label {
    font-weight: 700;
    margin-top: 35px;
    font-size: 1.4em;
}
