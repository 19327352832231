.search-suggestion-wrapper {
    @extend %clearfix;
    background-color: $white;
    border: 1px solid $nobel;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    @include respond(large-up) {
        &.full {
			border:none;
			box-shadow: none;
			position:relative;
			width: 100%;
            .phrase-suggestions {
                width: 42.8571429%;
                float: left;
            }
            .product-suggestions {
                width: 57.1428571%;
                float: right;
            }
        }
    }

    @include respond(medium-up) {
        .product-image {
            min-height: rem(172px);
        }
    }

    .hitgroup {
        color: $dim-gray;
        margin: .7rem 0 .7rem .7rem;
        .header {
            margin: rem(8px) 0 rem(12px) 0;
        }
        .hit {
            display: block;
            margin-left: 1rem;
            margin-bottom: .5rem;
            &:hover {
                text-decoration: underline;
            }
        }
        .parent-category {
            color: $nobel;
        }
    }
}

.phrase-suggestions {
    border-top: 1px solid $very-light-gray;
    @include respond(large-up) {
        border-top: none;
    }
}

.product-suggestion {
    @extend %clearfix;
    border: 1px solid $white;
    border-top: 1px solid $very-light-gray;
    padding: .5em;
    &:hover,
    &:focus {
        background-color: $gainsboro;
        border: 1px solid $very-light-gray;
    }
    .product-image {
        float: left;
        text-align: center;
        width: 25%;
        img {
            max-width: 100%;
        }
    }
    .product-details {
        float: right;
        padding-top: .5em;
        width: 70%;
        .product-price {
            color: $black;
        }
    }
}
.top-banner .wrap {
    @include respond(desktop) {
        z-index: 150;
    }
    .primary-logo{
	    z-index: 100;
    }
}
.search-phrase {
    color: $nobel;
    margin: 1em;
    .completed {
        font-style: italic;
        .original {
            text-decoration: underline;
            font-weight: bold;
        }
    }
    .corrected {
        font-weight: bold;
    }
}

.header-search.onsite-search-wrapper {
	display: block;
	position: absolute;
	right: 0;
	top: 3px;
	width: 100%;
	@include respond(tablet-small){
		top: rem(14px);
	}
	
	.badge-container {
	    min-height: rem(18px);
	}

	.product-listing-1x4 {
	    padding-top: 0;
	    .search-result-items {
	        .grid-tile {
	            @include respond(tablet-small){
                    width:38% !important;
                }
	        }
	    }
	}

	form {
		height: rem(35px);
		position:absolute;
		right: rem(120px);
		top: rem(7px);
		width: rem(285px);

		@include respond(tablet-small) {
		    right: rem(115px);
		}
	}

	#search-suggestions{
	    @include respond(medium-down) {
		    position: absolute;
		    left: 0;
		    border-top: rem(2px) solid #e5e5e5;
		    padding: 0;
		    top: rem(52px);

		    .search-data-list a::before {
		        display: block;
		        position: absolute;
		        content: "";
                left: 0;
                width: rem(15px);
                height: rem(15px);
                margin: 2px 2px 0 2px;
		    }
		}

		background: #fff;
		display:block;
		position:relative;
		top: rem(28px);
		width:100%;
		height: 0;
		.search-suggestion-wrapper{
		    border: none;
			box-shadow: none;
			display:none;
			flex-direction: row;
			padding: rem(30px) rem(45px) rem(45px);
			position: relative;
			width:100%;
			z-index: 99;
			min-height: rem(85px);
			top: rem(4px);

            @include respond(medium-down) {
			    padding: rem(28px) rem(15px) rem(26px);
			    top: 0;
			}
			
			@include respond(tablet-small) {
			    padding: rem(21px) rem(35px) rem(35px);
			    top: 0;
			}

			@include respond(laptop) {
			    padding-top: rem(28px);
			}
			
            .flyout-product-recommendation {
				position: relative;
                @include respond(medium-down) {
                    display: none;
                }
                
                .search-result-items {
					@include respond(minWidth-1200){
						padding: 0 rem(45px) rem(45px) rem(24px)!important;
                	}
                    @include respond(medium-up) {
                        padding: 0 rem(45px) rem(45px) rem(40px);
                    }
                    
                    @include respond(mid-large) {
                        padding: 0 rem(75px);
                    }

                    @include respond(minWidth-959) {
	                    padding: 0 0 0 rem(39px);
	                }

	                @include respond(minWidth-960) {
	                    padding: 0 0 0 rem(37px);
	                }
			        @include respond(minWidth-1024) {
	                    padding: 0 0 0 rem(53px);
	                }

			        @include respond(minWidth-1025) {
	                    padding: 0 0 0 rem(21px);
	                }
                }

                width: 70%;

				.search-product-header{
					box-sizing: border-box;
					display: flex;
					flex-direction: row;
					padding: 0 rem(92px) 0 rem(40px);

					@include respond(mid-large) {
					    padding-left: rem(75px);
					}

					@include respond(minWidth-959) {
						padding-left: rem(40px);
					}

					@include respond(minWidth-960) {
						padding: 0 rem(60px) 0 rem(35px);
					}

			        @include respond(minWidth-1024) {
						padding: 0 rem(88px) 0 rem(53px);
					}

			        @include respond(minWidth-1025) {
						padding: 0 rem(55px) 0 rem(16px);
					}

					h2.header, h4.header{
						width:80%;
					}

					a.search-product-viewall{
						@include ds-ui-regular(s);
						color:$night-rider;
						margin:0;
						padding:0;
						text-align: right;
						text-transform: capitalize;
						text-decoration: underline;
						width:20%;

						@include respond(tablet-small) {
					        padding: 0 rem(15px) 0 0;
					    }

					    @include respond(minWidth-1440) {
						    padding: 0;
						}
					}
				}
				h2.header, h4.header{
					color:$night-rider;
					@include ds-heading(h5);
					margin:0;
					padding:0 0 rem(21px) 0;
				}

				ul{
					li.grid-tile{
						flex-basis: 16%;
						margin: 0 30px 0 0;
						@include respond(mid-large) {
							flex-basis: 42%!important;
						    &:nth-child(n+6) {
						        display: none;
						    }
							&:nth-child(3n+1){
								margin-right:0!important;
							}
						}
						
						@include respond(minWidth-959) {
						    &:nth-child(n+7) {
						        display: none;
						    }
						}
						
						@include respond(laptop) {
						    &:nth-child(n+7) {
						        display: none;
						    }
					    }

					    @include respond(minWidth-1025) {
					        flex-basis: 20% !important;
						    &:nth-child(n+9) {
						        display: none;
						    }
							&:last-child(){
								margin-right: 0;
							}
						}
						@include respond(minWidth-1200){
                    		&:last-child(){
								margin-right: 0;
							}
                		}
						.product-tile{
							.zone_product-image_main{
								img{
									max-height: 172px;
								}
							}
							
							@include respond(tablet-small) {
							    min-width: rem(137px);
							}
							
							@include respond(laptop) {
							    min-width: rem(148px);
							}
						}
					}
				}
				.product-suggestions{
					box-sizing: border-box;
					display: block;
					float: none;
					padding:0;
					width: 100%;
					.product-listing{
						ul{
							li.grid-tile{
								flex-basis: 16%;
								margin: 0 30px 0 0;
								
								@include respond(minWidth-959) {
									flex-basis: 26%;
									width: 27% !important;
						        }
						        @include respond(minWidth-960) {
						            flex-basis: 27%;
						        }

						        @include respond(minWidth-1024) {
						            flex-basis: 27%;
						        }
								
								@include respond(minWidth-1200) {
									&:last-child{
										margin-right: 0;
									}
								}

								.product-tile{
									.zone_product-image_main{
										img{
											max-height: 172px;
										}
									}
								}
							}
						}
					}
				}
			}
			.flyout-product-recommendation {
				ul{
					li.grid-tile {			
					@include respond(minWidth-959){
						margin: 0 28px 0 0;
						flex-basis: 26%;
						&:last-child {
            				margin-right: 0;
    					}
						&:nth-child(n+7) {
							display: none;
						}
					}			
    			}	
				}
	        	
			}
				
			.phrase-suggestions {
			    @include respond(medium-up) {
				    width:30%;
				    border-left: 1px solid #e5e5e5;
				}

                @include respond(medium-down) {
				    width:100%;
				    border: none;
				}

				.search-result-clear{
					display: block;
					position:relative;
					right: 30px;
					text-align: right;
					top:15px;
					width:100%;
				}

				h2.header{
					@include ds-heading(h5);
				    margin: rem(8px) 0 rem(12px) 0;
				    padding-left: rem(45px);

				    @include respond(small) {
				        padding-left: 0;
				    }

				    @include respond(large) {
				        padding-left: rem(34px);
				    }

				    @include respond(minWidth-959) {
				        padding-left: rem(32px);
                    }
				}

				.hitgroup{
					box-sizing: border-box;
					margin: 0;

                    @include respond(medium-down) {
                        padding: 0;
					}

					padding: 0 0 0 rem(45px);

					@include respond(tablet-small) {
					    padding: 0 0 0 rem(35px);
					}

					div{
						display: flex;
						flex-direction: row;
						span.popular-icon{
							background-image: url('../images/svg-icons/popular-searches-icon.svg');
							background-repeat: no-repeat;
							background-position:left center;
							width:25px;
						}
						a.hit {
							@include ds-ui-regular(m);
							text-transform: capitalize;
							margin:0;
							padding: rem(10px) 0 rem(10px) 0px;
							width: fit-content;
							width: -moz-fit-content;

							@include respond(large-up) {
								@include ds-ui-regular(s);
							}
						}
					}
				}
			}
			.phrase-suggestions#search-history{
				.hitgroup{
					div{
						span.search-history-icon{
							background-image: url('../images/svg-icons/search-history-icon.svg');
							background-repeat: no-repeat;
							background-position: left center;
							padding: 0 13px 0 12px;
						}
						a.hit{

							font-size: 14px;
							line-height: 16px;
							margin:0;
							padding: 10px 0 10px 0px;
							@include respond(small) {
								font-size: 16px;
							}
						}
					}
				}
			}
			.phrase-suggestions#search-guide{
			    @include respond(medium-down) {
                    padding: 0;
                    overflow-y: scroll;
                    height: rem(480px);
                }

				padding: rem(5px) 0 0 rem(39px);

				@include respond(tablet-small) {
				    padding-left: rem(35px);
				}
				
				@include respond(laptop) {
				    padding-left: rem(45px);
				}

				div.search-data-list{
					 span.search-list-icon{
						background-image: url('../images/svg-icons/Search.svg');
						background-repeat: no-repeat;
						background-position: right;
						width: rem(15px);
						height: rem(15px);
						margin-right: rem(10px);
					} 
					box-sizing: border-box;
					display:flex;
					flex-direction: row;
					padding:rem(8px) 0;
					position:relative;
					align-items: center;

					a {
						@include ds-ui-regular(m);
						margin: 0;
						color: #333333;
						display: inline-block;
						text-transform: capitalize;
                        width: calc(100% - 3.3125rem);

						@include respond(large-up) {
							@include ds-ui-regular(s);
						}

						&:hover {
							color: #666666;
							text-decoration: underline;
						}

						&:active {
						    color: #aaaaaa;
							text-decoration: underline;
						}
					}
					button.searchpath{
						background-image: url('../images/svg-icons/Path-icon-default.svg');
						background-repeat: no-repeat;
						min-height: auto;
						padding:7px 0;
						position:absolute;
						right: 0;
						width: rem(12px);
						left: auto;

						@include respond(medium-down) {
						    height: auto;
                            top: auto;
						}

						&:hover{
							background-image: url('../images/svg-icons/autopopulate-arrow-icon-hover.svg');
							background-color: transparent;
						}
						&:focus{
							background-image: url('../images/svg-icons/autopopulate-arrow-icon-focus.svg');
							background-color: transparent;
						}
						&:active{
							background-image: url('../images/svg-icons/autopopulate-arrow-icon-active.svg');
							background-color: transparent;
						}

						@include respond(mid-large) {
						    right: 0;
                        }

						@include respond(minWidth-959) {
				            right: rem(4px);
                        }

                        @include respond(minWidth-960) {
				            right: rem(-10px);
                        }

                        @include respond(minWidth-1024) {
				            right: rem(-6px);
                        }

                        @include respond(minWidth-1025) {
				            right: rem(-9px);
                        }

                        @include respond(minWidth-1440) {
				            right: rem(-6px);
				        }
					}
				}
			}
		}
	}
}

#headerSearch{
	#sticky{
	button#headersearch{
		@include respond(large) {
			top: 0px;
			right: 4px;
		}
		@include respond(small-down) {
			top: 3px;
			right: 8px;
		}
	}
}
}

#headerSearch.onsite-search-wrapper {
    button {
        .icon-search {
            width: rem(19px) !important;
            height: rem(19px) !important;
        }
    }
    @include respond(medium-down) {
        height: rem(54px);
        position: fixed;

        &::after {
            background: url("../images/svg-icons/search-icon-blue-new.svg") no-repeat 0px 0px;
            box-sizing: border-box;
            content: "";
            height: rem(19px);
            right: rem(10px);
            margin: rem(8px);
            position: absolute;
            top: rem(10px);
            @include transition(none);
            width: rem(19px);
        }
 
        &::before {
            display: none;
        }
    }
 
    form {
        @include respond(medium-down) {
            right: 0 !important;
		}

		&.search_desktop {
			@include respond(medium-down) {
				display: none;
				.search-focus & {
					display: block;
					width: 100%;
				}
            }

			.arrow-left {
				display: none;
			    @include respond(medium-down) {
					left: rem(7px);
					width: rem(15px);
					height: rem(15px);
					margin: rem(9px) rem(2px) 0 rem(2px);
					background: url("../images/svg-icons/Caret-left.svg") no-repeat center;
					background-size: rem(10px);
				}
			}

			input[type="search"] {
			    @include respond(medium-down) {
				    padding: rem(21px) rem(40px) rem(21px) rem(40px);
				    padding-left: rem(40px);
                    top: 0;
                    border: none;
                    
                    &.reset-button-present {
                        padding: rem(21px) rem(76px) rem(21px) rem(40px);
                    }

				    @-moz-document url-prefix() {
                        padding: rem(21px) rem(40px) rem(21px) rem(40px);
                    }
				}
			}

		    fieldset {
				@include respond(medium-up) {
					position: relative;
				}
			}

			&.on-site-search-form {
				@include respond(small) {
					width: 97% !important;
					right:unset !important;
					left:1.5%;
				}
				@include respond(medium) {
					width: 97% !important;
					right:unset !important;
					left:1.5%;
				}
		        @-moz-document url-prefix() {
                    width: rem(345px);
                }

				@include respond(medium-up) {
				    top: rem(-6px) !important;
				    width: rem(285px);
				    right:rem(120px) !important;
				}

				@include respond(tablet-small) {
				    top: rem(-17px) !important;
				    width: rem(106px);
				    right:rem(124px) !important;
				}

				@include respond(minWidth-959) {
				    width: rem(195px);
				    right: rem(125px) !important;
				}

				@include respond(minWidth-960) {
				    width: rem(140px);
				    right: rem(138px) !important;
				}

				@include respond(minWidth-1024) {
		            width: rem(171px);
		            right:rem(141px) !important;
				}

				@include respond(minWidth-1025) {
		            width: rem(186px);
		            right:rem(118px) !important;
				}

				@include respond(minWidth-1440) {
		            width: rem(290px);
		            right:rem(120px) !important;				
				}

				&.fixed {
				    @include respond(small) {
				        position: fixed;
				        margin-top: 0;
                        top: rem(7px);
                        z-index: 9999;
                        height: rem(54px);
                    }
				}

				.on-site-search-box {
					height: 35px;
				}
			}

			button {
				top: 0;
				right: rem(7px);

				@include respond(tablet-small) {
				    top: 0;
				}
		    }
        }
    }
}

#headerSearch form fieldset button#search-reset{
	background: url('../images/svg-icons/remove-search-icon-default.svg') no-repeat;
	background-position:0;
	display: none;
	margin:0;
	padding:10px;
	position: absolute;
	right:rem(40px);
    top: rem(8px) !important;
	z-index: 102;
	left: auto;

	@include respond(medium-down) {
        top: rem(3px);
        right: rem(39px);
    }

	&:focus{
		background-image: url('../images/svg-icons/remove-search-icon-focus.svg');
		background-repeat: no-repeat;
		background-position:left center;
	}
	&:hover{
		background-image: url('../images/svg-icons/remove-search-icon-hover.svg');
		background-repeat: no-repeat;
		background-position:left center;
	}
	&:active{
		background-image: url('../images/svg-icons/remove-search-icon-active.svg');
		background-repeat: no-repeat;
		background-position:left center;
	}
}

.BBW-search-expand{
	width:rem(376px) !important;

	@include respond(medium) {
		width:204px !important;
	}

	@include respond(tablet-small) {
		width: rem(211px) !important;
	}

	@include respond(minWidth-959) {
	    width: rem(297px) !important;
	}

	@include respond(minWidth-960) {
	    width: rem(260px) !important;
	}

	@include respond(minWidth-1024) {
	    width: rem(290px) !important;
	}

	@include respond(minWidth-1025) {
	    width: rem(285px) !important;
	}

	@include respond(minWidth-1440) {
	    width: rem(408px) !important;
	}
}

.BBW-search-shrink{
	@include respond(medium) {
		width: rem(110px) !important;
	}
	@include respond(tablet-small) {
		width: rem(108px) !important;
	}

	@include respond(minWidth-959) {
	    width: rem(194px) !important;
	}

	@include respond(minWidth-960) {
	    width: rem(136px) !important;
	}

    @include respond(minWidth-1024) {
	    width: rem(170px) !important;
	}

	@include respond(minWidth-1025) {
	    width: rem(186px) !important;
	}

	@include respond(minWidth-1440) {
	    width: rem(290px) !important;
	}
}

.expand-position-right{
	@include respond(medium-up) {
		right: rem(119px) !important;
    }

    @include respond(laptop) {
		right:rem(32px) !important;
	}
    
    @include respond(tablet-small) {
		right: rem(115px) !important;
    }
  
    input[type="search"] {
        padding-right: calc(100% - 19rem);

        @include respond(laptop) {
		    padding-right: calc(100% - 13.25rem);
	    }

        @include respond(tablet-small) {
		    padding-right: calc(100% - 10.3125rem);
        }

	    @include respond(minWidth-959) {
	        padding-right: calc(100% - 15.3125rem);
	    }

	    @include respond(minWidth-960) {
	        padding-right: calc(100% - 13.5rem);
	    }

        @include respond(minWidth-1024) {
	        padding-right: calc(100% - 14.875rem);
	    }

	    @include respond(minWidth-1025) {
	        padding-right: calc(100% - 14.375rem);
	    }

        @include respond(minWidth-1440) {
	        padding-right: calc(100% - 22.125rem);
	    }

        &.reset-button-present {
            padding-right: calc(100% - 19rem);

            @include respond(laptop) {
		        padding-right: calc(100% - 13.25rem);
	        }

            @include respond(tablet-small) {
		        padding-right: calc(100% - 8.75rem);
            }

	        @include respond(minWidth-959) {
	            padding-right: calc(100% - 14.25rem);
	        }

	        @include respond(minWidth-960) {
	            padding-right: calc(100% - 11.75rem);
	        }

            @include respond(minWidth-1024) {
	            padding-right: calc(100% - 13.4375rem);
	        }

	        @include respond(minWidth-1025) {
	            padding-right: calc(100% - 13.125rem);
	        }

            @include respond(minWidth-1440) {
	            padding-right: calc(100% - 21rem);
	        }
	    }
	}

	@include respond(medium-down) {
		right: 27px !important;
	}
}
.shrink-position-right{
	right:110px !important;

	@include respond(medium-up) {
		right: rem(119px) !important;
	}

	@include respond(laptop) {
		right:rem(32px) !important;
	}

	@include respond(tablet-small) {
		right:rem(115px) !important;
	}

	#search-reset {
		display: none !important;
	}
}

.search-fly-out-opacity{
	background: rgba(51,51,51,0.6);
	height: 100%;
	left:0;
	position:fixed;
	top:rem(152px);
	width: 100%;
	z-index: 98;

	@include respond(medium-down) {
		background: #ffffff;
		top: rem(54px);
	}
}

.search-suggestion-wrapper .loader{
	position: absolute;
	height: 100%;
	overflow: hidden;
	width:100%;
	.loader-indicator{
		position: absolute;
		left:50%;
		top:50%;
		transform: translate(-50%, -50%);
	}
	.loader-bg{display: none;}
}

.nooverflow {
    &.removeoverflow {
        overflow: unset !important;
    }
}