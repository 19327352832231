@import "wishlist";
@import "gift_certificates";
@import "login_pages";
@import "order_history";
@import "customer_care";
@import "points_history";

.account-content {
    .slot-banner {
        margin-bottom: 28px;
        padding: 0;

        @include respond(large-up) {
            margin-bottom: 31px;
        }
    }

    .set-width {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include respond(desktop) {
            width: 710px;
        }

        @include respond(small) {
            width: 100%;
        }
    }

    .content-slot-custom {
        @include font-size(13px);
        @include line-height(16px);
        text-align: center;
        font-weight: 300;
    }

    .account-change-notification {
        margin-bottom: 25px;
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button.ds-close-button {
            background-image: $close-small;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            height: 25px;
            max-height: none;
            right: 0;
            top: 175px;
            transition: none;

            .success {
                text-align: left;
                display: block;

                &:hover {
                    background-color: $bg-secondary;
                    padding: 10px 25px;
                }
            }
        }


        span {
            @include ds-heading(h5);
            padding-left: 1em;
        }

        &.success {
            background-color: $bg-feedback-success;
            color: $text-feedback-success;
        }
        &.fail {
            background-color: $bg-feedback-danger;
            color: $text-feedback-danger;
            padding: 0 0.5em;

            span {
                display: flex;
                align-items: center;
                padding-left: 0;

                &::before {
                    content: url('../images/svg-icons/alert-error-triangle.svg');
                }
            }
        }
        &.hidden {
            display: none;
        }
    }

    div.privacy-cta-block {
        @include line-height(24px);
        margin: 0 0 1em 0;

        a {
            @include respond(large-down) {
                margin-bottom: 0;
            }

            @include font-size(12px);
            color: $night-rider;
        }
    }

    p.dsr-tile-cta {
        text-decoration: underline;
        line-height: 1.25rem;
        margin: 0px;
        padding: 0px;
        word-break: break-word;
        max-width: 175px;
        color: #333333;
    }

    ul.data-access {
        list-style-type: none;
        margin: 0 0 70px;
        flex-direction: row;

        @include respond(large-up) {
            width: 48.5%;
        }

        @include respond(medium-down) {
            width: 100%;
        }
    }

    li.dsr-tile a img {
        float: left;

        @include respond(large-up) {
            width: 84px;
            height: 84px;
            margin-right: 30px;
        }

        @include respond(medium-down) {
            width: 72px;
            height: 72px;
            margin-right: 15px;
        }
    }

    li.dsr-tile h3 {
        margin: 5px 0;

        @include respond(large-up) {
            font-size: 16px;
        }

        @include respond(medium-down) {
            font-size: 0.875rem;
        }
    }

    li.dsr-tile a {
        display: block;
    }

    li.dsr-tile div.info {
        float: left;
        width: calc(100% - 114px);
    }

    .bonus-email-landing {
        text-align: center;
        h2 {
            @include ds-heading(h2);
            color: $text-brand-primary;
            font-weight: bold;
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &.Error h2 {
            color: $text-feedback-danger;
        }

        p {
            @include ds-ui-base(l);
            color: $text-primary;
        }
    }
}

.request-password-text {
    margin: 0 auto;
    text-align: center;
    width: 60%;

    @include respond(small) {
        width: 100%;
    }

    .request-password-text-header {
        h1 {
            border: none;
        }
    }
}

.wishlist-content {
    .love-it-head-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $primary-border;

        @include respond(large-up) {
            border-bottom: 1px solid $secondary-border;
            @include font-regular(24px);
            padding-bottom: 0.625em;
            margin-bottom: 1.5625em;
            text-transform: none;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    h2.love-it-head-2 {
        position: relative;
        padding-bottom: 0.625em;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $secondary-border;
        @include font-regular(24px);

        @include respond(large-up) {
            display: block;
            flex: 1 1 auto;
            border-bottom: 0 none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .username,
        .wishlist-header {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            line-height: 1.1;
        }

        .loyalty-banner.wishlist {
            @include font-regular(14px);
            margin-top: 0.5rem;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include respond(medium-down) {
            .wishlist-header {
                display: block;
            }
        }
    }

    .open-share-modal {
        @include ds-utilities-button-labels;
        align-items: center;
        background: none;
        border: none;
        box-sizing: border-box;
        color: $text-primary;
        display: flex;
        justify-content: center;
        min-height: 48px;
        padding: 0 32px;
        transition-property: background-color, border-color;
        transition-duration: 0.3s;
        transition-delay: ease;
        width: 100%;

        &:hover , &:focus, &:active {
            color: $text-secondary;
        }

        @include respond(large-up) {
            display: inline-flex;
            flex: 0 auto;
            min-height: auto;
            padding: 0;
            width: auto;
        }

        > svg {
            height: calculateRem(16px);
            margin-right: 0.25rem;
            width: calculateRem(16px);
        }
    }
}

#love-it-share.ds-modal {
    width: 343px;

    .ds-modal-content {
        padding: calculateRem(24px);
    }

    h4 {
        @include ds-heading(h4);
        margin: 0 0 calculateRem(24px) 0;
    }

    p {
        @include ds-body(m);
        margin: 0 0 calculateRem(24px) 0;
    }

    .url-container {
        @include ds-ui-base(l);
        align-items: center;
        border: 1px solid $border-dark;
        display: flex;
        height: calculateRem(22px);
        margin: 0 0 calculateRem(24px) 0;
        overflow: hidden;
        padding: calculateRem(12px) calculateRem(16px);
        text-indent: 0;
        white-space: nowrap;
    }

    .button-primary {
        @include ds-utilities-button;
        display: inline-flex;
        width: auto;
    }

    .button-close {
        @include ds-utilities-button-minimal;
        display: inline-flex;
        margin-right: calculateRem(24px);
        width: auto;
    }
}

h1 {
    .loyalty-banner {
        @include font-size(14px);
        vertical-align: bottom;
        padding: 6px 0 0 4px;

        &.wishlist {
            float: right;
            margin-top: 6px;
            padding-top: 0;
        }

        @include respond(medium-up) {
            position: absolute;
            padding: 3px 0 0 0;
        }

        @include respond(medium-down) {
            display: inline-block;
            padding-left: 0;

            &.wishlist {
                float: none;
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    .username {
        margin-right: 20px;
        display: inline-block;
    }

    .account-logout {
        @include font-size(12px);
        right: 0;
        position: absolute;

        @include respond(medium-down) {
            margin-top: 3px;
            bottom: 11px;
        }

        a {
            color: $secondary-color;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &:active {
                color: $click;
            }
        }
    }
}

.registration-selection {

    input,
    label {
        display: inline;
        float: none;
    }
}

#RegistrationForm,
#ChangePasswordForm,
#ChangeMailForm {
    .dialog-required {
        margin-right: -5px;
    }

    .form-row {
        &.uneditable input {
            background-color: $white;
            color: $night-rider;
            border-color: #fafafa;

            @include respond(medium-down) {
                &:disabled {
                    opacity: initial;
                }
            }
        }

        @include respond(large-up) {
            margin-right: calculateRem(17.5px);
        }

        .form-caption.error-message {
            @include respond(medium-down) {
                width: auto;
            }
        }
    }

    #loyaltyOffer .term-description {
        margin-bottom: 5px;
        margin-left: 24px;
        text-indent: 0;
    }
}

#secondary {
    &.account-nav {
        .subheader {
            @include font-bold(12px);
            @include line-height(10px);
            text-transform: uppercase;
            color: $secondary-color;
            display: block;
            margin-bottom: 22px;
            margin-top: 0;
        }

        .top-level.toggle {
            margin: 0 -15px 12px;
        }

        .secondary-navigation {
            padding: 20px 0 20px;

            @include respond(large-up) {
                padding: 0;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    margin: 0 0 20px;

                    @include respond(large-up) {
                        margin: 0 0 15px;
                    }

                    a {
                        @include font-size(14px);
                        color: $primary-color;

                        &.current {
                            color: $cobalt;
                        }

                        &:hover {
                            color: $cobalt;
                        }

                        &:active {
                            color: $click;
                        }
                    }
                }
            }
        }
    }
}

.account-nav-help {
    text-align: center;
    margin: 0 0 30px;
    @include line-height(24px);

    @include respond(large-up) {
        margin: 28px 0 0;
    }

    h3 {
        color: $primary-color;
        @include font-regular(14px);
        text-transform: none;
        margin: 0;
    }

    p {
        margin: 0;
    }
}

#addresses {
    h2 {
        margin: 0 0 34px;

        @include respond(medium-down) {
            margin: 0 0 28px !important;
        }
    }

    .new-address-btn {
        @include respond(medium-down) {
            width: 100%;
            box-sizing: border-box;
        }
    }
}

.address-list {
    padding: 0;

    li {
        list-style: none outside none;
        margin: 0;
        min-height: 135px;
        padding: 25px 0;
        width: 100%;

        @include respond(large-up) {
            width: 33.3%;
            float: left;

            &.new-row {
                clear: both;
            }
        }

        @include respond(tablet-small) {
            @include font-size(12px);
        }

        &.default {
            float: none;
            width: auto;
            padding: 0;

            @include respond(medium-down) {
                padding-top: 2px;
            }

            h3 {
                @include font-size(16px);
                border-bottom: 1px solid $primary-border;
                padding-bottom: 16px;
                margin: 0;
            }

            .addresses-info {
                position: relative;
                margin-top: 60px;

                .address-create {
                    position: absolute;
                    right: 0;
                    bottom: 16px;
                    box-sizing: border-box;
                    width: 185px;
                    @include font-size(11px);

                    @include respond(small-down) {
                        @include font-size(13px);
                        width: 103px;

                        span {
                            display: none;
                        }
                    }
                }
            }

            .address-info {
                background: $bg-color;
                padding: 22px 28px 30px;

                @include respond(large-up) {
                    padding-bottom: 36px;
                }

                .address-edit {
                    padding-left: 0;
                }
            }
        }

        .address-info {
            .mini-address-title {
                color: $secondary-color;
                @include font-bold(14px);
            }

            address {
                font-style: normal;
            }

            a {
                color: $secondary-color;
                text-decoration: underline;
                padding: 0 10px;

                @include respond(tablet-small) {
                    padding: 0 3px;
                }

                &.address-make-default {
                    padding-left: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &:active {
                    color: $click;
                }
            }
        }
    }
}

.address-dialog {
    #edit-address-form {
        .dialog-required {
            top: 10px;
        }

        .form-row {
            label {
                width: 100%;
                padding-left: 0;
            }

            .field-wrapper {
                width: 100%;
                padding-left: 0;
            }

            .form-field-tooltip {
                display: none;
            }

            .form-caption {
                margin-left: 0;
            }

            &.form-row-button {
                width: 100%;
                max-width: 120px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;

                button {
                    &.apply-button {
                        width: 105px;
                    }

                    &.cancel-button {
                        background: transparent;
                        text-decoration: underline;
                        padding: 0;
                        color: $secondary-color;
                        margin-top: 20px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

.payment-list {
    overflow: hidden;
    padding: 0;

    li {
        float: left;
        list-style: none outside none;
        margin: 0;
        min-height: 110px;
        width: 33%;

        @include respond(medium-down) {
            float: none;
            width: auto;
        }
    }

    .errorMsg {
        border-style: solid;
        border-color: red;
        border-width: 1px;
    }

    .error-text {
        color: $red;
    }
}

.order-history-items {
    margin: 20px 0;
}

.order-history-table {

    th,
    td {
        padding: 5px 10px;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none outside none;
    }

    .order-shipped-to,
    .order-items,
    .order-total-price {
        width: 33%;
    }

    .toggle {
        cursor: pointer;
        display: block;
    }
}

.js .order-history-table .hidden {
    display: none;
}

.facebook-registration {
    @include respond(medium-down) {
        left: -3px;
        position: relative;
        width: 100.7% !important;

        .fb_iframe_widget {
            position: relative;
            width: 100.7% !important;

            span {
                position: relative;
                width: 100.7% !important;

                iframe {
                    position: relative;
                    width: 100.7% !important;
                }
            }
        }
    }

    @include respond(medium) {
        left: 0px;
        position: relative;
        width: 100% !important;

        .fb_iframe_widget {
            position: relative;
            width: 100% !important;

            span {
                position: relative;
                width: 100% !important;

                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }

    @include respond(large) {
        left: 0px;
        position: relative;
        width: 100% !important;

        .fb_iframe_widget {
            position: relative;
            width: 100% !important;

            span {
                position: relative;
                width: 100% !important;

                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
}

.account-content {
    h2.login-name-header {
        padding-bottom: 0.625em;
        margin-bottom: 1.5625em;

        .login-header {
            float: left;
            width: 100%;
            max-width: calc(100% - 60px);
            color: #333;

            #csr-bopis-toggle {
                margin-top: 1%;
            }

            .bopis-experience {
                display: inline;
            }
        }

        .loyalty-banner {
            position: relative;
        }
    }

    #RegistrationForm,
    #ChangePasswordForm,
    #ChangeMailForm {
        .dialog-required {
            right: 0;
            top: -15px;

            span {
                padding: 0;
            }
        }

        .account-info-header {
            padding: 16px 0 7px;
            border-bottom: 1px solid $primary-border;
            width: 100%;
            position: relative;
            color: $secondary-color;
            margin-bottom: 18px;
            @include font-size(14px);
            text-transform: uppercase;
        }

        .form-row {
            width: 290px;

            @include respond(medium-up) {
                width: 300px;
            }

            margin-left: auto;
            margin-right: auto;

            label {
                width: 100%;
                padding-left: 0;
            }

            .field-wrapper {
                width: 100%;
                padding-left: 0;
            }

            .form-caption {
                width: 100%;
                margin: 0;
                padding: 0;
            }

            &.checkbox {
                .field-wrapper {
                    width: calc(100% - 30px);
                    float: left;

                    input {
                        float: left;
                    }

                    label {
                        width: calc(100% - 30px);
                        color: $primary-color;
                        float: left;
                        margin-left: 9px;
                        padding: 0;
                    }
                }
            }

            .form-field-tooltip {
                float: right;
                width: auto;
                padding-top: 0;
                margin: 0;
            }

            &.form-row-button {
                button {
                    @include respond(medium-down) {
                        width: 100%;
                        @include font-size(13px);
                    }
                }
            }

            &.with-note {
                .form-caption {
                    margin-top: 10px;
                }

                .error-message {
                    padding-left: 0.625rem;
                }
            }

            &.with-help {
                .field-wrapper {
                    display: inline-block;
                    width: 88%;
                    float: left;
                }

                .form-field-tooltip {
                    margin-top: 4px;
                }
            }
        }
    }

    .top-reward-banner.slot-banner {
        margin-bottom: 8px;
    }
}

.passwordreset {
    max-width: 575px;
    border: 1px solid $primary-border;
    margin: 40px auto 0;
    box-sizing: border-box;
    padding: 9%;
    text-align: center;

    h2 {
        @include font-regular(24px);

        @include respond(medium-down) {
            line-height: 1.2;
        }
    }

    p {
        a.button {
            text-decoration: none;
            margin-top: 20px;
        }
    }

    #PasswordResetForm {
        .form-error {
            clear: both;
            color: #cc0000;
            text-align: left;
            padding: 0 0 0.3125rem 0.625rem;
        }
    }
}

.passwordreset-help {
    .login-box {
        margin-top: 60px;

        p {
            margin: 0;
        }
    }
}

.primary-content.centered {
    margin: 0 auto;
    float: none;

    @include respond(medium-down) {
        a.button.fullwidth {
            width: calc(100% - 2.125em);
        }
    }
}

@include respond(medium-down) {

    #wrapper #main.page-content.account-content .centered h1,
    #wrapper.narrow #main.page-content.centered .primary-head {
        margin-top: 10px;
    }
}

@include respond(large-down) {
    .pt_account .account-content .primary-content .login-name-header {
        position: relative;

        .login-header {
            width: calc(100% - 3em);
        }
    }
}

@include respond(medium-down) {
    .legacy {
        a.button {
            width: calc(100% - 2.125em - 2px);
        }
    }
}

.pw-requirements {
    list-style: none;
    margin: 0 auto;
    width: 270px;
    -webkit-padding-start: 0;

    @include respond(medium-down) {
        margin: 0 auto;
        -webkit-padding-start: 0;
    }

    @include respond(small) {
        margin-left: 0px;
    }

    .pw-requirement {
        @include font-size(12px);
        background: none;
        padding: 0 0 calculateRem(18px) 0;
        position: relative;
        top: calculateRem(5px);
        line-height: unset;

        span {
            display: inline-block;
            padding: 0;
            margin-bottom: -3px;

            color: $dim-gray;
            @include font-size(0.75rem);

            &.pw-error {
                color: $red;
                float: none;
                top: 0;

                .circle {
                    border-color: $red;
                }
            }

            &.success {
                color: $egg-blue;

                .circle {
                    width: 22px;
                    height: 22px;
                    border: none;
                    background: url("../images/svg-icons/Alert-Check.svg") no-repeat center;
                    margin: 0;
                }
            }

            &.circle {
                border: 1px solid $dim-gray;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                display: inline-block;
                left: 0;
                margin: 3px;
                position: absolute;
                top: -1px;
            }
        }

        >span {
            line-height: calculateRem(22px);
            padding-left: 30px;
        }
    }
}

#password-requirements {
    display: none;

    #RegistrationForm & {
        margin-bottom: 40px;
        margin-top: 32px;
        padding-left: 0;
    }
}

#RegistrationForm {
    .form-row {
        @include respond(medium-down) {
            max-width: 450px;
        }
    }
}

.newpasswordconfirmheader {
    margin-bottom: 20px;

    @include respond(small) {
        margin-bottom: 0px;
    }
}

.resetSuccess {
    text-align: center;

    @include respond(small) {
        text-align: center;
        margin-top: 30px;
    }

    h3 {
        text-transform: capitalize;
        font-size: 0.875rem;
        color: #666;
    }
}

.newpasswordconfirm {
    margin: 0 0 24px 0;
    text-align: center;

    @include respond(small) {
        margin-bottom: 30px;
    }
}

.newpasswordconfirmbtn {
    text-align: center;
}

@include respond(medium-down) {
    .primary-content {
        .request-password-complete {
            margin-top: 35px;
        }

        .request-password-text {
            margin-top: 40px;

            >p {
                margin-bottom: 30px;
            }
        }
    }
}

@include respond(large-up) {
    .primary-content .request-password-text .resetSuccess {
        display: none;
    }
}

/*Styles for Rewards Popup*/
.rewardspopup {
    @include respond(small) {
        width: 90vw !important;
    }

    .ui-dialog-title {
        margin: 0;
        padding: 0;
        height: 0;
        border: 0;
    }

    .rewards-header {
        img {
            width: 100%;
            height: auto;
        }
    }

    #dialog-container {
        padding: 0;

        @include respond(medium-down) {
            padding: 0 !important;
        }

        .complite-profile {
            padding: 8px 0 5px;
            margin-top: 1px;
        }

        #join-rewards-form {
            padding: 0 20px;

            font-weight: 400;

            .form-caption {
                line-height: 16px;
            }

            .apply-button {
                letter-spacing: 1.1px;

                &:disabled,
                [disabled] {
                    background-color: #c2c2c2;

                    &:hover {
                        background-color: #c2c2c2;
                    }
                }
            }
        }

        .rewards-fields {
            .form-row {
                margin: 10px 0 24px;

                label {
                    margin-bottom: 24px;
                }

                label,
                .field-wrapper {
                    width: 100%;
                    padding-left: 0;
                }

                .field-wrapper {
                    span {
                        line-height: 20px;
                    }
                }
            }
        }

        .form-caption {
            margin: 5px 0 0 5px;
            line-height: 18px;
        }

        .form-row-button {
            .apply-button {
                padding: 0 10px;

                &:disabled,
                [disabled] {
                    background: $very-light-gray;

                    &:hover {
                        color: $dim-gray;
                        border-color: $very-light-gray;
                        background-color: $very-light-gray;
                    }
                }
            }
        }

        .term-condition {
            display: block;

            .with-note {
                margin: 3px 0 0 0;
                padding: 0;
                float: left;
                width: 5%;

                .form-caption,
                label {
                    display: none;
                }
            }

            .term-description {
                float: right;
                width: 95%;
                margin-bottom: 5px;
                margin-top: 2px;
                text-indent: 0;

                @include respond(medium-down) {
                    width: 92%;
                }

                label {
                    width: 98%;
                    cursor: pointer;
                }

                a {
                    @include font-size(11px);
                    text-decoration: underline;
                    color: $night-rider;

                    &:hover {
                        text-decoration: none;
                        color: $dim-gray;
                    }

                    &:active {
                        color: $click;
                    }
                }

                p {
                    @include font-size(10px);
                    margin: 0;
                    padding: 5px 0 0 0;
                    @include line-height(13px);
                    color: $dim-gray;
                }
            }
        }

        .form-row-button {
            margin-left: 0;
            width: 100%;
            margin-bottom: 16px;
        }

        .center-align {
            display: block;
            text-align: center;
            padding-bottom: 19px;

            a {
                text-decoration: underline;
                line-height: 16px;
                @include font-size(13px);
                color: $night-rider;

                &:hover {
                    color: $dim-gray;
                    text-decoration: none;
                }

                &:active {
                    color: $click;
                }
            }
        }
    }

    .account-nav-help.rewards {
        display: none;
    }
}

.pt_account,
.dialog-content {
    div.privacy-cta-block {
        @include line-height(24px);
        margin: 0 0 1.25em 0;

        @include respond(large-down) {
            margin-bottom: 0.25em;
        }

        a {
            @include respond(large-down) {
                margin-bottom: 0;
            }

            @include font-size(12px);
            color: $night-rider;
        }
    }

    .track-order-form {
        div.privacy-cta-block {
            margin: -2.25em 0 0.95em 0;

            @include respond(large-down) {
                margin: -1.8em 0 -0.25em 0;
            }
        }
    }
}

.pt_account .account-content {
    div.privacy-cta-block {
        margin: -0.5em 0 1.1em 0;

        a {
            @include respond(large-down) {
                margin: -1em 0 -0.5em 0;
            }
        }
    }

    #ChangeMailForm {
        div.privacy-cta-block {
            @include respond(large-up) {
                margin: -1.5em 0 1.1em 0;
            }

            a {
                @include respond(large-down) {
                    margin: -1.8em 0 -0.5em 0;
                }
            }
        }
    }
}

@include respond(large-up) {
    .pt_account .account-content .primary-content {
        padding-bottom: 0;

        .alert {
            padding: 15px;
            background-color: #ffe9e9;
            color: white;
            margin: 30px auto 0 auto;
        }

        .messagelisting .alert {
            background-color: #e6f5ff;
            color: #005699;

            p,
            a {
                padding: 0;
                margin: 0;
                color: inherit;
            }
        }

        .info-notification {
            margin-top: 20px;
        }

        .closebtn {
            margin-left: 15px;
            color: #cc0000;
            font-weight: bold;
            float: right;
            font-size: 30px;
            line-height: 1.25rem;
            cursor: pointer;
            transition: 0.3s;
        }

        button.closebtn {
            margin-left: 15px;
            color: #cc0000;
            float: right;
            font-size: 30px;
            cursor: pointer;
            transition: 0.3s;
            background-color: transparent;
            padding: 0;
            border: 0;
            background: url("../images/svg-icons/close.svg") no-repeat center;

            &:hover {
                background: url("../images/svg-icons/close-hover.svg") no-repeat center;
            }

            &:active {
                background: url("../images/svg-icons/close-click.svg") no-repeat center;
            }

            width: 20px;
            height: 20px;
        }

        .messagelisting button.closebtn {
            background: url("../images/svg-icons/close-blue.svg") no-repeat center;
        }

        .banner-cancelled,
        .frozen-notification,
        .terminated-notification {
            color: #cc0000;
            font-size: 0.875rem;

            .content-asset {
                display: inline;
            }

            a {
                color: #cc0000;
            }
        }
    }
}

@include respond(medium-down) {
    .pt_account .account-content .primary-content {
        .alert {
            padding: 15px;
            background-color: #ffe9e9;
            color: white;
            margin: 30px auto 0 auto;
        }

        .messagelisting .alert {
            background-color: #e6f5ff;
            color: #005699;

            p,
            a {
                padding: 0;
                margin: 0;
                color: inherit;
            }
        }

        .closebtn {
            margin-left: 15px;
            color: #cc0000;
            font-weight: bold;
            float: right;
            font-size: 30px;
            line-height: 1.25rem;
            cursor: pointer;
            transition: 0.3s;
        }

        button.closebtn {
            margin-left: 15px;
            color: #cc0000;
            float: right;
            font-size: 30px;
            cursor: pointer;
            transition: 0.3s;
            background-color: transparent;
            padding: 0;
            border: 0;
            background: url("../images/svg-icons/close.svg") no-repeat center;

            &:hover {
                background: url("../images/svg-icons/close-hover.svg") no-repeat center;
            }

            &:active {
                background: url("../images/svg-icons/close-click.svg") no-repeat center;
            }

            width: 20px;
            height: 20px;
        }

        .messagelisting button.closebtn {
            background: url("../images/svg-icons/close-blue.svg") no-repeat center;
        }

        .banner-cancelled,
        .frozen-notification,
        .terminated-notification {
            color: #cc0000;
            font-size: 0.875rem;
            line-height: 1.25rem;

            .content-asset {
                display: inline;
            }

            a {
                color: #cc0000;
            }
        }

        .rewards-header {}

        #join-rewards-form {
            padding: 0 18px;

            .complite-profile {
                padding: 8px 0 5px;
                margin-top: 1px;
                margin-left: 4px;
            }

            .rewards-fields {
                .form-row {
                    margin: 10px 0 24px;

                    label {
                        margin-bottom: 24px;
                    }

                    label,
                    .field-wrapper {
                        width: 100%;
                        padding-left: 0;
                    }

                    .field-wrapper {
                        span {
                            line-height: 20px;
                        }
                    }
                }
            }

            .form-caption {
                margin: 5px 0 0 5px;
                line-height: 18px;
                max-width: 249px;
            }

            .form-row-button {
                .apply-button {
                    padding: 0 10px;
                    min-width: 254px;
                }
            }

            .term-condition {
                display: block;
                max-width: 249px;
                margin: 0 auto;

                .with-note {
                    margin: 3px 7px 0 0;
                    padding: 0;
                    float: left;
                    width: 5%;

                    .form-caption,
                    label {
                        display: none;
                    }
                }
            }

            .form-row-button {
                margin-left: 0;
                width: 100%;
                margin-bottom: 16px;
            }

            .center-align {
                display: block;
                text-align: center;
                padding-bottom: 19px;

                a {
                    text-decoration: underline;
                    line-height: 16px;
                    @include font-size(13px);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .account-nav-help.rewards {
            margin-top: 31px;
        }
    }
}

/*Styles for Rewards Popup*/
/*Styles for My wallet page*/
.rewards-timestamp {
    text-align: center;
    padding: 15px 0;
}

.mywallet-page {
    float: left;
    width: 100%;
    position: relative;
    max-width: 710px;
    text-align: center;
    overflow: hidden;

    .icon-wrapper {
        margin-top: 100px;

        img {
            width: 32px;
            height: auto;
        }

        .animation-text {
            margin-top: 20px;
            color: $night-rider;
            @include font-size(16px);
        }

        .animation-text::before {
            content: "Almost there ";
            margin-left: 20px;
        }

        .animation-text::after {
            overflow: hidden;
            display: inline-block;
            vertical-align: bottom;
            -webkit-animation: ellipsis steps(4, end) 1s infinite;
            animation: ellipsis steps(4, end) 1s infinite;
            content: "\2026";
            /* ascii code for the ellipsis character */
            width: 0;
            margin-right: 20px;
        }

        @keyframes ellipsis {
            to {
                width: 20px;
                margin-right: 0;
            }
        }
    }

    .my-rewards {
        text-align: left;

        .empty-container {
            text-align: center;
            padding-bottom: 40px;
        }

        .my-wallet-accordion {
            margin: 0 4px 80px 4px;
        }

        h2 {
            margin-bottom: 0;
            padding: 10px 10px 12px 40px;
            @include font-size(24px);
            border-bottom: 1px solid $night-rider;
            text-transform: uppercase;

            @include respond(medium-down) {
                padding-bottom: 15px;
                padding-right: 0;
            }

            &.my-rewards-title {
                position: relative;

                .position-icon {
                    position: absolute;
                    left: 6px;
                    top: 5px;
                }
            }

            &.my-offers-title {
                position: relative;
                padding-left: 40px;

                .position-icon {
                    position: absolute;
                    left: 0;
                    top: 5px;
                }
            }

            &.my-bonus-title {
                position: relative;
                padding-left: 40px;

                .position-icon {
                    position: absolute;
                    left: 0;
                    top: 5px;
                }
            }

            span {
                @include font-size(14px);
                color: $dim-gray;
                font-weight: normal;

                text-transform: none;

                @include respond(medium-down) {
                    display: block;
                    margin-top: 12px;
                    margin-left: -40px;
                    @include font-size(12.8px);
                }
            }
        }

        h3 {
            padding: 27px 10px 27px 40px;
            margin: 0;

            @include font-size(16px);
            position: relative;
            margin-top: 3px;

            .position-icon {
                position: absolute;
                left: 6px;

                @include respond(medium-down) {
                    top: 50%;
                    transform: translateY(calc(-50%));
                }
            }

            &.in-store-online {
                position: relative;

                .position-icon {
                    position: absolute;
                    left: 0;
                    top: 17px;

                    @include respond(medium-down) {
                        top: 50%;
                        transform: translateY(calc(-50%));
                    }
                }
            }

            &.in-store {
                position: relative;

                .position-icon {
                    position: absolute;
                    left: 0;
                    top: 17px;

                    @include respond(medium-down) {
                        top: 50%;
                        transform: translateY(calc(-50%));
                    }
                }
            }

            &.online {
                position: relative;

                .position-icon {
                    position: absolute;
                    left: 0;
                    top: 17px;

                    @include respond(medium-down) {
                        top: 50%;
                        transform: translateY(calc(-50%));
                    }
                }
            }
        }

        .accordion-item {
            border-bottom: 1px solid $cobalt;
            border-top: 1px solid $cobalt;
            margin-top: -1px;
        }

        .reward-promotion {
            padding: 10px 40px;
            position: relative;
            margin: 0;

            .ui-icon {
                position: absolute;
                width: 20px;
                height: 22px;
                @include transition(all 0.5s ease-out);
                background-size: 20px;
                background: url("../images/svg-icons/UI-Carat-blue.svg") no-repeat center;
                top: calc(50% - 4px);
                left: calc(100% - 30px);

                &.iconOpen {
                    transform: rotate(180deg);
                }
            }

            span {
                @include font-size(10px);
                display: block;
            }

            .reward-type {
                color: $hollywood-cerise;
                font-size: 16px;
                margin-bottom: 3px;
            }

            .reward-channel,
            .reward-title {
                color: $cobalt;
                font-size: 13px;
                margin-bottom: 3px;
            }

            .reward-valid {
                text-transform: none;
                font-size: 12px;
                margin-bottom: 3px;

                &.expire-soon {
                    color: $hollywood-cerise;
                }
            }

            .reward-code {
                font-size: 10px;
            }

            @include respond(medium-down) {
                padding: 10px 40px 10px 10px;
            }
        }

        .table {
            display: table;
            height: 0;
            min-height: 64px;
        }

        .table-alignment {
            display: table-cell;
            vertical-align: middle;
        }

        .reward-promotion-redeem {
            margin-top: 6px;
            overflow: hidden;

            .barcode-full {
                float: left;
                width: 100%;
                text-align: center;

                .copy-code {
                    margin-bottom: 38px;
                }
            }

            .barcode-left {
                float: left;
                width: 50%;
                text-align: center;

                @include respond(large-down) {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }

            .barcode-right {
                float: right;
                width: 50%;
                text-align: center;

                @include respond(large-down) {
                    width: 100%;
                }
            }

            .redeem-type {
                text-transform: uppercase;
                color: $cobalt;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            .redeem-code {
                font-size: 12px;
                text-transform: uppercase;
                color: $night-rider;
                @include line-height(16px);
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .copy-code {
                width: 290px;
                border: none;

                &.copied {
                    color: $egg-blue;
                    background-color: $mint-cream;
                    border: none;
                }

                @include respond(medium-down) {
                    width: 288px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .barcode-container {
                margin-top: 7px;
            }

            .barcode-details {
                text-align: center;
                margin-bottom: 16px;
                margin-top: 10px;

                .details-link {
                    text-decoration: underline;
                }
            }

            &.bonus-redeem {
                border-bottom: 1px solid #e5e5e5;
            }
        }

        .bonus-details-link {
            text-decoration: underline;
            font-size: 16px;
        }

        .bonus-promotion {
            display: flex;
            flex-direction: column;

            .bonus-heading {
                border-bottom: 0px;
                color: $text-primary;
                font-size: 16px;
                line-height: normal;
                padding: 0px 0px 8px;
                margin: 0;
                position: relative;

                .position-icon {
                    position: absolute;
                    left: 0;
                    top: 5px;
                }

                .bonus-details-link {
                    text-decoration: underline;
                    font-size: 16px;
                }
            }

            .bonus-activate {
                margin: 16px 0;

            }

            button:not(.js-modal-trigger) {
                @include ds-utilities-button;
                padding: 0 24px;
            }

            .bonus-activated-text {
                display: none;
            }

            .bonus-shop-now {
                transition: 1s;
                border-width: 2px;
                font-weight: 600;

                &.hidden {
                    display: none;
                }
            }

            .reward-valid {
                font-size: 12px;
                color: $text-tertiary;
                display: flex;
                align-items: baseline;

                button {
                    font-size: inherit;
                    margin-left: 1em;
                    color: inherit;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .bonus-activated {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-evenly;
            }

            .bonus-activated .bonus-activated-text {
                @include ds-utilities-button-labels;
                align-items: center;
                color: $text-primary;
                column-gap: 8px;
                display: flex;
                height: 48px;
                justify-content: center;

                &::before {
                    background-image: url("../images/svg-icons/Success-16-green.svg");
                    content: "";
                    height: 16px;
                    display: block;
                    width: 16px;
                    background-repeat: no-repeat;
                }
            }

            @media only screen and (min-width: $mMin) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                column-gap: 8px;
                margin-bottom: 16px;

                .bonus-activate {
                    margin: 0;
                }

                .bonus-activated {
                    flex-direction: column;
                }

                .bonus-activated-text {
                    margin-right: 24px;
                }
            }
        }

        .bonus-error {
            display: none;
        }

        .activate-error .bonus-error {
            color: $text-feedback-danger;
            @include ds-ui-regular(s);
            align-items: center;
            column-gap: 4px;
            display: flex;
            justify-content: center;
            margin-bottom: 16px;

            &::before {
                background-image: url("../images/svg-icons/Alert-24-red.svg");
                background-repeat: no-repeat;
                content: "";
                display: block;
                height: 24px;
                min-width: 24px;
                width: 24px;
            }
        }
    }

    .my-rewards-bonus .reward-promotion-redeem {
        margin-top: 24px;
        border-bottom: 1px solid $border-primary;
    }

    .error-form,
    .blue-error-message {
        margin-bottom: 20px;
    }

    .bottominfo {
        text-align: center;
        width: 50%;
        min-width: 290px;
        margin: 63px auto 0;

        @include respond(medium-down) {
            width: 100%;
        }
    }

    .rewards-cta-container {
        text-align: center;
    }

    .rewards-cta-message {
        p {
            margin: 13px auto 5px;
            width: 235px;

            font-size: 18px;
            color: #005699;
            text-align: center;
            line-height: 20px;
        }
    }

    .rewards-cta {
        display: inline-block;
        margin: 10px 10px 10px 0;
        line-height: 21px;

        &.count-rewards {
            h2 {
                margin: 0;
                padding: 0;
                border: none;
            }

            span {
                @include font-size(24px);

                text-transform: uppercase;
                color: #333;
                letter-spacing: -0.025em;

                span {
                    display: inline-block;
                    @include font-size(16px);
                    color: $white;
                    padding: 7px 12px 20px 8px;
                    min-width: 20px;
                    text-align: center;
                    background: url("../images/svg-icons/reward-count-background.svg") no-repeat center;
                    background-position-y: 0px;
                    background-position-x: 0px;
                    vertical-align: 15%;
                    margin-left: 3px;

                    &.icon {
                        background: url("../images/svg-icons/UI-Rewards-badge.svg") no-repeat center;
                        background-size: 100%;
                        padding: 0;
                        width: 35px;
                        height: 48px;
                        vertical-align: -88%;
                        margin: 0 5px 0 0;
                    }
                }
            }
        }

        &.rewards-work {
            span {
                @include font-size(16px);

                span {
                    border-radius: 1.5rem;
                    display: inline-block;
                    @include font-size(22px);
                    color: $dim-gray;
                    padding: 5px 8px;
                    min-width: 17px;
                    text-align: center;
                    background-color: $white-smoke;
                    font-weight: bold;
                    font-family: serif;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .rewards-box {
        display: inline-block;
        width: 100%;
        text-align: center;
        max-width: 634px;
        margin: 0 auto;

        a {
            @include font-size(14px);
            text-decoration: underline;
            color: $dim-gray;

            &.emphasized {
                color: $red;
            }

            &:hover {
                text-decoration: none;
            }

            &:active {
                color: $click;
            }
        }

        p {
            @include font-size(12px);
            line-height: 16px;
            margin: 27px 30% 25px;
            color: $night-rider;
            text-align: center;

            @include respond(medium-down) {
                margin: 12px 5% 25px;
            }

            a {
                @include font-size(12px);
                color: $night-rider;
            }
        }

        .capped-link {
            display: inline-block;
            margin-bottom: 20px;
        }
    }

    .container-box {
        position: relative;
        float: left;
        left: 50%;
        margin-left: -114px;
        margin-bottom: 20px;
        margin-top: 5px;

        .progress-descrition {
            position: absolute;
            top: 36px;
            width: 100%;
            text-align: center;

            sup {
                top: -0.2em;
            }

            .amount-from-to {
                display: block;
                width: 100%;
                margin-top: -8px;

                span {
                    color: $cobalt;

                    @include font-size(14px);
                    float: left;

                    &:last-child {
                        float: right;
                    }
                }
            }

            .next-reward {
                span {
                    display: block;
                    @include font-size(13px);
                    color: $night-rider;

                    letter-spacing: 0px;
                    line-height: 100%;
                    text-transform: uppercase;

                    &.comma {
                        display: inline;
                        @include font-size(23px);
                    }

                    &.amount {
                        @include font-size(40px);

                        letter-spacing: 0;
                        line-height: 38px;
                        margin-bottom: 6px;
                        text-align: center;
                    }

                    &.spent {
                        color: $brand-pink;

                        letter-spacing: 0;
                        margin-top: 6px;
                        margin-bottom: 10px;
                        text-transform: uppercase;
                        @include font-size(14px);

                        &.negative {
                            font-style: italic;
                        }

                        .spent-amount {
                            display: inline;
                        }
                    }

                    sup {
                        @include font-size(22px);
                        left: 0;
                        top: -12px;
                    }

                    &.reduce-size {
                        @include font-size(36px);
                    }
                }
            }
        }

        #container-db {
            height: 109px;
            width: 228px;
            position: relative;
            z-index: 3;
        }

        #container-mb {
            height: 109px;
            width: 226px;
            position: absolute;
            z-index: 2;
            left: 1px;
            top: 1px;
        }

        #container-lb {
            height: 109px;
            width: 226px;
            position: absolute;
            left: 1px;
            top: 1px;
        }
    }

    .blue-box-container {
        background: $cobalt;
        padding: 10px;
        width: 100%;
        max-width: 634px;
        display: inline-block;
        box-sizing: border-box;
        margin: 0 auto;
        /*commited for fix https://lyonscg.atlassian.net/projects/BLP2/issues/BLP2-214 */
        /*@include respond(medium-down) {
            width: 100vw;
            margin-left: -15px;
        }*/
    }

    .box-title {
        text-align: center;
        color: $white;
        @include font-size(10px);
        line-height: 16px;
        display: inline-block;
        width: 100%;
        padding-bottom: 10px;

        @include respond(360px) {
            @include font-size(11px);
        }

        @include respond(370px) {
            @include font-size(12px);
        }
    }

    .title {
        background: $white;
        width: 100%;
        display: inline-block;
        margin: 0;
        position: relative;
        text-align: center;
        padding: 9px 0 9px;
        cursor: pointer;
        z-index: 2;

        .ui-icon {
            float: right;
            position: absolute;
            width: 15px;
            height: 22px;
            @include transition(all 0.5s ease-out);
            background-size: 20px;
            background: url("../images/svg-icons/UI-Carat-blue.svg") no-repeat center;
            top: 5px;
            margin-top: 0;
            right: 7px;
            left: auto;
        }

        .ui-icon.iconOpen {
            background: url("../images/svg-icons/UI-Carat-blue.svg") no-repeat center;
            transform: rotate(180deg);
        }

        .title-blue {
            color: $cobalt;
            @include font-size(12px);
            line-height: 14px;
            text-transform: uppercase;

            outline: none;
        }
    }

    .content {
        background: $white;
        width: 100%;
        display: inline-block;
        text-align: center;

        .count-rewards {
            margin: 9px auto 6px;

            @include respond(medium-down) {
                max-width: calc(100% - 14px);
            }

            &::after {
                clear: both;
                content: "";
                display: block;
            }

            &>span {
                display: inline-block;
                line-height: 27px;
                @include font-size(12px);

                &.you-have {
                    margin-right: 3px;
                }
            }

            .next-reward-capped {
                display: inline-block;
                line-height: 27px;

                @include respond(medium-down) {
                    float: right;
                }
            }

            .earned {
                margin-right: 30px;

                &.negative-points {
                    margin-right: 0;

                    @include respond(medium-down) {
                        float: none;
                    }
                }

                span {
                    width: 27px;
                    height: 27px;
                    border-radius: 20px;
                    display: inline-block;
                    line-height: 27px;
                    @include font-size(12px);
                    color: $white;
                    text-align: center;
                    background-color: $cobalt;
                    margin-right: 3px;
                    font-weight: bold;
                }

                @include respond(small-down-iphone) {
                    margin-right: 12px;
                }
            }

            svg {
                text {
                    @include font-size(14px);
                }
            }
        }

        p {
            @include font-size(12px);
            margin: 0 0 10px 0;
        }
    }
}

/*End Styles for My wallet page*/
#main {
    .howrewardswork {
        @include respond(medium-down) {
            margin-top: 10px;
        }

        .rewards-info-banner {
            display: block;
            margin-bottom: 36px;

            @include respond(medium-down) {
                width: 100vw;
                margin-left: -15px;

                img.banner {
                    width: 100%;
                }
            }
        }

        .rewards-hours {
            background: $white-smoke;
            display: flex;
            align-items: center;
            padding: 12px 9px;
            margin: 0 15px 30px 0;

            img {
                display: inline-block;

                @include respond(medium-down) {
                    width: 40px;
                    height: 56px;
                    flex-shrink: 0;
                }
            }

            span {
                @include font-size(12.5px);
                line-height: 15px;
                display: block;
                color: $night-rider;
                margin-left: 10px;
            }
        }

        .free-fan {
            display: block;
            width: 100%;
            text-align: center;

            img {
                margin-bottom: 15px;
            }

            span {
                color: $night-rider;
                display: block;

                @include respond(medium-down) {
                    @include line-height(21px);
                    margin: 0 auto;
                    max-width: 220px;
                }
            }
        }

        ul {
            li {
                display: block;
                width: 100%;
                max-width: 767px;
                margin: 0 auto;

                .title {
                    text-align: center;
                    display: block;
                    position: relative;
                    border-top: 2.5px solid $reward-hr;
                    margin-top: 80px;

                    @include respond(medium-down) {
                        margin-top: 45px;
                    }

                    .white-background {
                        height: 34px;
                        width: 64px;
                        display: inline-block;
                        z-index: 1;
                        text-align: center;
                        background-color: $white;
                        @include font-size(18px);
                        line-height: 34px;
                        color: $white;
                        margin-top: -30px;
                    }

                    span {
                        z-index: 2;
                        height: 34px;
                        width: 34px;
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        border-radius: 17px;
                        background-color: $cobalt;
                        @include font-size(18px);

                        line-height: 34px;
                        color: $white;
                        margin-top: -30px;
                    }
                }

                .content {
                    text-align: center;

                    .center-box {
                        h3 {
                            @include font-size(17.5px);
                            padding: 0;
                            margin: 0 0 20px 0;

                            text-transform: none;
                        }

                        p.not-ready {
                            @include font-size(10px);
                            color: $night-rider;
                            font-weight: 300;
                            line-height: 16px;
                            margin: 28px 0 67px;
                        }

                        p {
                            color: $night-rider;

                            a:active {
                                color: $click;
                            }
                        }

                        h4 {
                            @include font-size(12px);
                        }

                        p.small {
                            margin-top: 30px;
                            color: $black;

                            @include respond(medium-down) {
                                @include line-height(15px);
                                margin-bottom: 100px;
                            }
                        }
                    }

                    .title-img {
                        display: block;
                        width: 100%;
                        margin: 20px 0 14px;
                    }

                    .info-container {
                        &>div {
                            display: inline-block;
                            width: 49%;
                            text-align: left;
                            padding: 0 50px;
                            box-sizing: border-box;

                            @include respond(medium-down) {
                                width: 100%;
                                text-align: center;
                                margin-right: auto;
                                margin-left: auto;
                                display: block;
                            }

                            &.left-box {
                                color: $night-rider;
                                padding-left: 14%;
                                line-height: 22px;

                                @include respond(medium-down) {
                                    padding: 0;
                                    max-width: 230px;
                                }
                            }

                            &.right-box {
                                color: $night-rider;
                                padding-right: 14%;
                                padding-left: 0;

                                &.rewards-animation {
                                    position: relative;

                                    @include respond(medium-down) {
                                        margin-bottom: 95px;
                                        margin-top: 80px;
                                        padding-right: 0;
                                        max-width: 265px;
                                    }

                                    .icon-wrapper {
                                        position: absolute;
                                        top: -60px;
                                        left: 33%;

                                        @include respond(medium-down) {
                                            position: relative;
                                            top: 0;
                                            left: 0;
                                        }

                                        img {
                                            width: 32px;
                                            height: auto;
                                        }
                                    }
                                }

                                &.rewards-error {
                                    .error-loyalty {
                                        @include font-size(12px);
                                        text-align: center;

                                        .error-text {
                                            @include line-height(16px);
                                            color: $red;
                                        }

                                        .threshold-retry {
                                            text-decoration: underline;
                                            margin-bottom: 14px;
                                            display: block;
                                        }
                                    }
                                }

                                &.rewards-point {
                                    display: none;
                                    position: relative;

                                    @include respond(medium-down) {
                                        margin-top: 30px;
                                        padding-right: 0;
                                        max-width: 265px;
                                    }

                                    .rewards-overlay-text {
                                        position: absolute;
                                        top: 70px;
                                        width: 155px;
                                        text-align: center;

                                        @include respond(medium-down) {
                                            left: 0;
                                        }

                                        sup {
                                            top: -8px;
                                            left: 2px;
                                            @include font-size(18px);
                                        }

                                        &>span {
                                            @include font-size(10px);
                                            //display: block;
                                            color: $night-rider;
                                            text-transform: uppercase;

                                            &#rewards-count {
                                                @include font-size(30px);
                                            }

                                            &.last-span {
                                                display: block;
                                                margin-top: -10px;
                                                margin-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            &.center-box {
                                text-align: center;

                                @include respond(medium-down) {
                                    padding: 0;

                                    .not-ready {
                                        max-width: 230px;
                                        margin-left: auto;
                                        margin-right: auto;
                                        margin-bottom: 54px;
                                    }
                                }
                            }

                            h5 {
                                @include font-size(11px);
                                text-transform: uppercase;
                                margin: 0;
                                padding: 0;

                                margin-bottom: 5px;
                            }
                        }

                        &.center {
                            &>div {
                                text-align: center;
                                @include line-height(21px);

                                &.left-box {
                                    color: $night-rider;
                                    padding: 0;
                                    width: 200px;
                                    margin-right: 25px;
                                    margin-top: 25px;

                                    @include respond(medium-down) {
                                        margin-right: auto;
                                    }
                                }

                                &.right-box {
                                    color: $night-rider;
                                    padding: 0;
                                    width: 200px;
                                    margin-left: 25px;
                                    margin-top: 25px;

                                    @include respond(medium-down) {
                                        margin-left: auto;
                                    }

                                    img {
                                        margin-left: 7px;
                                    }
                                }

                                span {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*details-popup my wallet page - details link*/
.details-popup {
    .details-dialog-container {
        padding: 0 20px 20px 20px;
    }

    img {
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 20px;

        @include respond(medium-down) {
            margin-right: 29px;
        }
    }

    @include respond(medium-down) {

        h2,
        h3 {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ui-dialog-title {
        display: none;
    }

    h3,
    div {
        @include font-size(12px);
        line-height: 16px;
        text-align: center;
    }

    h3 {
        margin: 0;
    }

    .details-dialog>div {
        max-width: 290px;
        margin: 0 auto;

        @include respond(medium-down) {
            max-width: 244px;
            margin-bottom: 15px;
        }
    }

    @include respond(medium-down) {
        &.ui-dialog {
            width: 290px !important;
        }
    }
}

.page-content .primary-content {
    h2.rewards-status__title {
        padding: 16px 0 7px;
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
        position: relative;
        color: #333;
        margin-bottom: 18px;

        font-weight: normal;
        @include font-size(14px);
    }

    h2.join-rewards__title {
        color: #fff;

        @include font-size(16px);
        @include line-height(17px);
        margin: 0;
        padding: 0;
        border: none;

        @include respond(small-down-iphone) {
            @include font-size(12px);
        }
    }
}

.info {
    #join-rewards {
        display: flex;
        justify-content: center;
        margin-top: rem(35px);
    }
}

.account-options {
    #join-rewards {
        margin: 0;
        display: flex;

        .join-rewards__my-bbw-logo img {
            display: block;
            width: 95px;
            float: none;
            margin: 0;
            max-width: none;
        }
    }
}

.join-rewards {
    display: flex;
    justify-content: center;
    margin: 30px auto 0 auto;
    max-width: 335px;

    &__my-bbw-logo {
        background-color: #005698;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            width: 95px;
            max-width: none;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: #ed088c;
        padding: 15px;

        @include respond(large-up) {
            width: 210px;
        }
    }

    p {
        @include font-size(12px);
        @include line-height(14px);
        color: #ffffff;
        margin: 5px 0 0;

        .underlined {
            text-decoration: underline;
        }

        @include respond(small-down-iphone) {
            @include font-size(9px);
            margin-top: 5px;
        }

        @include respond(large-up) {
            @include font-size(11px) @include line-height(14px);
            max-width: 210px;
        }
    }
}

.rewards-status {
    &__banner {
        width: 100%;

        &--mobile {
            display: block;
        }

        &--desktop {
            display: none;
        }

        @media only screen and (min-width: 480px) {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: block;
            }
        }
    }

    .prospective-acc {
        margin-top: 25px;
    }
}

.loyalty-questions {
    margin-top: 40px;

    @include respond(large-up) {
        margin-top: 50px;
    }

    &__body {
        color: #666666;
        @include font-size(16px);
        text-align: center;

        @include respond(large-up) {
            @include font-size(14px);
        }
    }
}

.active-rewards {
    margin-top: 30px;
    background-color: #e20887;
    padding: 17px;

    @include respond(desktop) {
        span {
            &.capped-name {
                display: block;
            }
        }
    }

    &__copy {
        color: #fff;
        line-height: 20px;
        margin: 0;
        padding-right: 70px;
        @include font-size(14px);
        font-weight: 300;

        @include respond(medium-down) {
            padding: 0;
        }

        b {
            font-weight: 300;
        }
    }

    span {
        span.singular {
            display: none;
        }

        &.one {
            span.singular {
                display: inline-block;
            }

            span.plural {
                display: none;
            }
        }
    }
}

#loyaltyOffer {
    .terms-conditions,
    .contact-us,
    .privacy-policy {
        text-decoration: underline;
    }

    .checkbox {
        width: auto;
        height: auto;
    }

    &.dimmed {
        opacity: 0.4;
        cursor: not-allowed;

        a {
            pointer-events: none;
        }
    }
    .term-condition > .term-description,
    > .term-description {
        float: right;
        width: 95%;
        margin-bottom: 5px;
        margin-top: 2px;
        text-indent: 0;
    }

    .term-description {

        @include respond(medium-down) {
            width: 92%;
        }

        &, label, a.privacy-policy {
            color: $text-secondary;
            @include font-size(16px);
        }

        .loyalty-join-terms-label {
            display: block;
            float: none;
            font-weight: bold;
            margin: 0 0 0.5em 0;
            padding: 0;
            width: 100%;
        }
    }
}

#loyaltyOfferSorry {
    display: none;
}

#my-account-dropdown {
    padding-bottom: 20px;
}

#loyaltyOffer .term-condition,
#loyaltyOffer .term-condition label {
    color: #666666;
}

#loyaltyOffer .term-condition a {
    color: #333333;
}

.content-asset .account-options li a img.my-account-join-tile-img {
    width: 95%;
}

.mywallet-page .my-rewards h3.my-rewards-title .position-icon {
    top: 50%;
    transform: translateY(calc(-50%));
}

.pt_account.info .account-content #RegistrationForm legend .dialog-required {
    top: -35px;

    @include respond(medium-down) {
        top: -15px;
    }
}

.ui-dialog.negative-balance {
    width: 100%;
    max-width: 465px;

    .ui-dialog-title {
        border: none;
        margin: 0;
        padding: 0;
        height: 36px;
        width: 100%;
    }

    .negative-info-container {
        text-align: center;
    }

    span {
        display: block;
    }

    .prompt {
        @include font-size(12px);
        color: #005699;
        letter-spacing: 1px;
        line-height: 14px;
        text-align: center;
        margin: auto;
        display: inline-block;
    }

    .negative-balance {
        @include font-size(24px);
        margin-top: 3px;
        color: black;
    }

    .info-gain-cta {
        margin-top: 30px;

        a {
            text-decoration: none;
        }
    }
}

.privacy-messages {
    text-indent: 0;

    a {
        text-decoration: underline;
        color: #333;
    }
}

/*Corporate Sales and Gift Page styles start here*/

.breadcrumb {
    @include respond(small-down) {
        box-sizing: border-box;
    }
}

#wrapper.pt_corporategifts #main {
    padding: 0;
}

#wrapper #corp-sale-program.primary-content {
    padding: 0;
    width: 100%;

    @include respond(desktop) {
        box-sizing: border-box;
        padding: 0;
    }

    @include respond(large) {
        box-sizing: border-box;
        padding: 0 15px;
    }

    @include respond(medium) {
        box-sizing: border-box;
        padding: 0 15px;
    }

    @include respond(small) {
        box-sizing: border-box;
        padding: 0 15px;
    }

    @include respond(small-down) {
        box-sizing: border-box;
        padding: 0 15px;
    }

    .corporate-sales-program-section1 {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include respond(small-down) {
            padding: 38px 0 0;
        }

        .corp-sale-top-head {
            position: relative;
            height: 99px;
            width: 100%;

            .BBW-logo-banner {
                position: absolute;
                height: 99px;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .corp-sale-headline {
                position: relative;

                h1 {
                    border: none;
                    color: $night-rider;
                    font-size: 75px;
                    letter-spacing: 1.3px;
                    line-height: 75px;
                    margin: 0;
                    padding: 12px 0;
                    text-align: center;
                    width: 100%;

                    @include respond(standart) {
                        font-size: 75px !important;
                        line-height: 75px !important;
                        padding: 12px 0 !important;
                    }

                    @include respond($max-small-coll) {
                        font-size: 75px !important;
                        line-height: 75px !important;
                        padding: 12px 0 !important;
                    }

                    @include respond(large) {
                        box-sizing: border-box;
                        font-size: 62px;
                        line-height: 50px;
                        margin: 0;
                        padding: 0 31%;
                        width: 100%;
                    }

                    @include respond(medium) {
                        box-sizing: border-box;
                        font-size: calc(40px + (46 - 40) * ((100vw - 480px) / (767 - 480)));
                        line-height: 30px;
                        margin: 0;
                        padding: 0 40%;
                        width: 100%;
                    }

                    @include respond(small) {
                        box-sizing: border-box;
                        font-size: 40px;
                        line-height: 30px;
                        margin: 0;
                        padding: 0 37%;
                        width: 100%;
                    }

                    @include respond(small-down-iphone) {
                        box-sizing: border-box;
                        font-size: 40px;
                        line-height: 30px;
                        margin: 0;
                        padding: 0 33%;
                        width: 100%;
                    }
                }
            }
        }

        .major-fragrance {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 40px 17% 0;
            text-align: center;
            width: 100%;

            @include respond(large) {
                box-sizing: border-box;
                padding: 40px 5% 0;
                width: 100%;
            }

            @include respond(medium) {
                box-sizing: border-box;
                padding: 40px 5% 0;
                width: 100%;
            }

            @include respond(small) {
                box-sizing: border-box;
                padding: 35px 0 0;
                width: 100%;
            }

            h2 {
                font-size: 24px;
                line-height: 24px;
                margin: 0;
                padding: 0;
                text-transform: uppercase;

                @include respond(small-down) {
                    box-sizing: border-box;
                    font-size: 1.3125rem;
                    padding: 0 13%;
                    width: 100%;
                }
            }

            p {
                color: #666666;

                font-size: 14px;
                margin: 0;
                padding: 20px 0 0;
                line-height: 24px;

                strong {}
            }
        }
    }

    .corporate-sales-program-section2 {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 60px 50px 0px;
        width: 100%;

        @include respond(large-down) {
            padding: 60px 0px 0;
            width: 100%;
        }

        @include respond(medium-down) {
            flex-direction: column;
            padding: 60px 0px 0;
            width: 100%;
        }

        @include respond(small-down) {
            flex-direction: column;
            padding: 60px 0 0;
            width: 100%;
        }

        .corp-sales-left-column {
            box-sizing: border-box;
            margin: 0px 70px 0 0;
            width: 62.5%;

            @include respond(large) {
                margin: 0px 30px 0 0;
                width: calc(100%-315px);
            }

            @include respond(large-up) {
                margin: 0px 30px 0 0;
                width: calc(100%-315px);
            }

            @include respond(medium-down) {
                width: 100%;
                margin-right: 0;
            }

            @include respond(small-down) {
                width: 100%;
                margin-right: 0;
            }

            .corporate-gifts-page-image {
                width: 100%;
            }

            img {
                height: auto;
                width: 100%;
            }
        }

        .corp-sales-right-column {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0 0 0 11px;
            width: 37.5%;

            @include respond(large) {
                width: 315px;
            }

            @include respond(large-up) {
                width: 315px;
            }

            @include respond(medium-down) {
                margin: 0 auto;
                padding: 0;
                width: 304px;
            }

            @include respond(small-down) {
                margin: 0 auto;
                padding: 0;
                width: 304px;
            }

            .corporate-sale-intro-text {
                width: 100%;

                h2 {
                    font-size: 24px;
                    line-height: 24px;
                    margin: 0;
                    padding: 0px;
                    text-transform: uppercase;

                    @include respond(medium-down) {
                        padding: 40px 0 0;
                    }

                    @include respond(small-down) {
                        padding: 40px 0 0;
                    }
                }

                p {
                    color: $dim-gray;

                    font-size: 14px;
                    margin: 0;
                    padding: 10px 0 0;
                    line-height: 24px;
                    width: 304px;

                    @include respond(medium-down) {
                        width: 304px;
                    }

                    @include respond(small-down) {
                        width: 304px;
                    }
                }
            }

            .confirmation-message {
                box-sizing: border-box;
                width: 100%;
                background-color: $mint-cream;
                margin: 30px 0;
                padding: 12px 15px 15px;

                p {
                    width: 100%;
                    color: $egg-blue;

                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 16px;
                    margin: 0;
                }
            }

            #CorporateSalesForm {
                padding: 30px 0 0;

                @include respond(small-down) {
                    padding: 30px 0 0;
                }
            }

            .form-row {
                margin: 0 0 30px;
                width: 100%;

                &.textarea {
                    margin: 0 0 30px;
                }
            }

            .form-row.checkbox {
                margin: 0;

                .form-row.checkbox {
                    margin: 0 0 15px;
                }

                .form-row.checkbox:nth-child(3n) {
                    margin: 0;
                }
            }

            .form-row-button {
                & button {
                    @include respond(small-down) {
                        width: 50%;
                    }
                }
            }

            .dialog-required {
                margin-right: 10px;
            }

            label {
                margin: 0;
                padding: 0 0 20px;
                width: 100%;
            }

            legend {
                color: #333;
                display: block;

                font-weight: normal;
                font-size: 0.875rem;
                line-height: 1;
                margin: 0;
                padding: 0 0 20px;
                text-align: left;
                text-transform: capitalize;
                width: 100%;

                span {
                    padding: 0;
                }
            }

            .form-row.required legend {
                position: relative;
                left: -10px;
            }

            .form-horizontal .field-wrapper {
                padding: 0;
                width: 100%;

                input[type="text"] {
                    box-sizing: border-box;
                    margin: 0;
                    width: 100%;
                }
            }

            .form-row.checkbox input[type="checkbox"] {
                border: 1px solid #e5e5e5;
                border-radius: 3px;
                margin: 0;
                padding: 10px;
                width: auto;
            }

            .form-row.checkbox label {
                margin: 0 0 0 18px;
                padding: 0;
                width: auto;
            }

            .corporate-sales-terms-conditions {
                h4 {
                    color: $night-rider;

                    font-size: 14px;
                    line-height: 16px;
                    margin: 0;
                    padding: 0;
                    text-align: left;

                    @include respond(small-down) {
                        margin: 0px;
                    }
                }

                p {
                    color: $dim-gray;

                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                    text-align: left;
                    margin: 0;
                    padding: 12px 0 0;

                    @include respond(small-down) {
                        margin: 0;
                        padding: 12px 0 0;
                    }
                }

                ol {
                    margin: 18px 0 0;

                    @include respond(small-down) {
                        margin: 18px 0 0;
                        padding: 0 0 0 20px;
                    }

                    li {
                        color: $dim-gray;

                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0;
                        list-style-type: decimal;
                        text-align: left;

                        strong {}

                        a {
                            color: $dim-gray;

                            font-size: 12px;
                            text-decoration: underline;
                        }
                    }
                }

                .corporate-sales-privacy {
                    margin: 30px 0 0;

                    p {
                        color: $night-rider;

                        font-size: 14px;
                        line-height: 24px;
                        text-align: center;
                        margin: 0;
                        padding: 0 0 20px;

                        a {
                            color: $night-rider;

                            font-size: 14px;
                        }
                    }
                }
            }

            .corporate-gifts-questions {
                text-align: center;

                p {
                    color: $dim-gray;

                    font-size: 14px;
                    line-height: 24px;
                    margin: 0;
                    padding: 0;

                    a {
                        color: $night-rider;

                        font-size: 14px;
                    }
                }
            }
        }
    }
}

footer {
    @include respond(small-down) {
        margin-top: 96px;
    }
}

/*Corporate Sales and Gift Page styles end here*/

/*My Payment Method Page Styles Start Here*/
.my-payment-banner {
    +h1 {
        color: $night-rider;

        font-size: 1.5rem;
        line-height: 24px;
        margin: 30px 0 14.5px;
    }

    picture {
        img {
            width: 100%;
        }
    }
}

.paymentslist {
    h1 {
        color: $night-rider;

        font-size: 1.5rem;
        line-height: 24px;
        margin: 0 0 15px;
    }

    .default-card-success {
        background: $mint-cream;
        box-sizing: border-box;
        margin: 0;
        padding: 15px;
        width: 100%;

        p {
            color: $egg-blue;

            font-size: 0.875rem;
            line-height: 16px;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    .delete-default-card,
    .add-card-message {
        background: $mint-cream;
        box-sizing: border-box;
        margin: 0;
        padding: 15px;
        width: 100%;

        p {
            color: $egg-blue;

            font-size: 0.875rem;
            line-height: 16px;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    .my-default-card {
        padding: 0 0 30px;

        h2 {
            border: none;
            color: $night-rider;

            font-size: 1rem;
            line-height: 18px;
            margin: 0;
            padding: 15px 0 14.5px;
            text-transform: uppercase;

            @include respond(small-down) {
                border-bottom: 1px solid $gainsboro;
                margin: 0 0 15px;
            }

            @include respond(small) {
                border-bottom: 1px solid $gainsboro;
                margin: 0 0 15px;
            }
        }

        .no-default-card {
            color: $night-rider;

            font-size: 0.875rem;
            line-height: 15px;
            padding: 30px 0 0;
            text-align: center;
            width: 100%;
        }

        .payment-list {
            width: 100%;

            li.default-card {
                width: 100%;

                &.errorMsg {
                    width: 99.5%;
                }

                @include respond(medium-down) {
                    &.errorMsg {
                        width: 99%;
                    }
                }

                &.card-expired {
                    .default-credit-card-container {
                        border: 1px solid $red;

                        .cc-exp {
                            color: $red;
                        }
                    }
                }

                .default-credit-card-container {
                    background-color: $white-smoke;
                    box-sizing: border-box;
                    color: $dim-gray;
                    margin: 0;
                    padding: 15px;
                    width: 100%;

                    .cc-owner {
                        color: $dim-gray;
                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    .cc-type {
                        color: $night-rider;

                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    .cc-number {
                        color: $dim-gray;
                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    .cc-exp {
                        color: $dim-gray;
                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    form {
                        fieldset {
                            span {
                                color: $black;
                                padding: 0;
                            }

                            .button-text {
                                &.edit {
                                    color: $night-rider;
                                    margin: 0;
                                    padding: 0 7px 0 0;
                                    text-decoration: underline;

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }

                                &.delete {
                                    color: $night-rider;
                                    margin: 0;
                                    padding: 0 0 0 7px;
                                    text-decoration: underline;

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .expired-card-message {
                    color: $red;
                    float: left;

                    font-size: 0.75rem;
                    line-height: 14px;
                    margin: 0;
                    padding: 5px 0 0 0;
                    width: 100%;
                }
            }
        }

        .auto-refresh-message {
            margin-bottom: 15px;

            a {
                text-decoration: underline;
            }
        }
    }

    .my-other-cards {
        margin: 30px 0 0;

        h2 {
            border-bottom: 1px solid #e5e5e5;
            color: #333;

            font-size: 1rem;
            line-height: 18px;
            margin: 0 0 15px 0;
            padding: 0 0 14.5px 0;
            text-transform: uppercase;
        }

        .max-card-error {
            background: $medium-spring-green;
            box-sizing: border-box;
            color: $red;

            font-size: 0.875rem;
            margin: 0 0 15px;
            padding: 15px;
            width: 100%;
        }

        .add-new-card-cta {
            text-align: center;
            width: 100%;

            button {
                color: #fff;
                font-size: 0.813rem;
                line-height: 16px;
                min-height: none;
                padding: 9px 16px 8px;
                text-transform: uppercase;
                width: 228px;

                @include respond(small-down) {
                    width: 100%;
                }

                @include respond(small) {
                    width: 100%;
                }
            }
        }

        ul.payment-list {
            li {
                margin: 15px 0 0 0;
                width: 100%;

                &.card-expired {
                    .other-credit-card-container {
                        border: 1px solid $red;

                        .cc-exp {
                            color: $red;
                        }
                    }
                }

                .other-credit-card-container {
                    box-sizing: border-box;
                    margin: 0;
                    padding: 15px;
                    width: 100%;

                    .cc-owner {
                        color: $dim-gray;
                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    .cc-type {
                        color: $night-rider;

                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    .cc-number {
                        color: $dim-gray;
                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    .cc-exp {
                        color: $dim-gray;
                        font-size: 0.875rem;
                        line-height: 24px;
                        margin: 0;
                        padding: 0;
                    }

                    form {
                        fieldset {
                            button {
                                color: #333;

                                font-size: 0.875rem;
                                line-height: 18px;
                                margin: 0;
                                padding: 0;
                                text-decoration: underline;

                                &.set-default {
                                    padding: 0;
                                }

                                &:hover {
                                    background: none;
                                    text-decoration: none;
                                }
                            }

                            span {
                                padding: 0 7px;
                            }
                        }
                    }
                }

                .expired-card-message {
                    color: $red;
                    float: left;

                    font-size: 0.75rem;
                    line-height: 14px;
                    margin: 0;
                    padding: 5px 0 0 0;
                    width: 100%;
                }
            }
        }
    }
}

.my-payment-add-new-card-form {
    box-sizing: border-box;
    padding: 0 116px;
    width: 100%;

    @include respond(small-down) {
        padding: 0;
    }

    @include respond(small) {
        padding: 0;
    }

    h3 {
        color: $night-rider;

        font-size: 0.875rem;
        line-height: 18px;
        margin: 0;
        padding: 7px 0 16px 12px;
    }

    .dialog-required {
        color: $dim-gray;

        font-size: 11px;
        line-height: 13px;
        margin: 10px 0 0 0;
        position: absolute;
        right: 116px;
        text-transform: capitalize;

        @include respond(small-down) {
            right: 15px;
        }

        em {
            font-style: normal;
        }
    }

    .add-card-error {
        background: $medium-spring-green;
        box-sizing: border-box;
        color: $red;

        font-size: 0.875rem;
        line-height: 16px;
        margin: 0 0 6px 0;
        padding: 15px;
        width: 100%;
    }

    .form-validation-error-message {
        background: $medium-spring-green;
        box-sizing: border-box;
        color: $red;

        font-size: 0.875rem;
        line-height: 16px;
        margin: 0 0 6px 0;
        padding: 15px;
        width: 100%;
    }

    .billing-address-error-message {
        background: $medium-spring-green;
        box-sizing: border-box;
        color: $red;

        font-size: 0.875rem;
        line-height: 16px;
        margin: 0 0 15px 0;
        padding: 15px;
        width: 100%;
    }

    #CreditCardForm {
        box-sizing: border-box;
        padding: 0;
        width: 100%;

        .form-row {
            width: 100%;

            label {
                width: 100%;
            }

            .field-wrapper {
                width: 100%;

                input[type="text"] {
                    font-size: 0.875rem;
                }

                .select-style {
                    select {
                        font-size: 0.875rem;
                    }
                }
            }

            &.month {
                float: left;
                margin: 0 0 1.875rem 0%;
                padding: 0 0 0 2%;
                width: 122px;

                label {
                    margin: 0;
                }

                .form-caption {
                    &.error-message {
                        width: 100%;
                    }
                }
            }

            &.year {
                float: left;
                margin: 0 0 1.875rem 16px;
                width: 122px;

                @include respond(small-down) {
                    float: right;
                }

                @include respond(small) {
                    float: right;
                }

                label {
                    margin: 0;
                }
            }

            &.save-cc-default-container {
                float: left;
                margin: 0;
                padding: 0;
                width: 100%;

                .field-wrapper {
                    display: block;
                    width: 100%;

                    .save-default-card {
                        display: inline;
                    }

                    label {
                        display: inline;
                    }

                    .uncheck-default+label {
                        color: $dim-gray;
                    }
                }
            }

            &.cc-exp-date {
                width: 100%;
            }
        }

        .make-default-message {
            color: $cobalt;
            float: left;

            font-size: 0.75rem;
            margin: 0 0 0 7%;
            padding: 7px 0 0 0;
            width: 55%;

            @include respond(small-down) {
                width: 93%;
            }

            @include respond(small) {
                width: 93%;
            }
        }

        .add-new-section-cta {
            display: block;
            margin: 0;
            padding: 0;
            padding: 60px 0 0 0;
            text-align: center;
            width: 100%;

            button#credit-card-create {
                display: block;
                box-sizing: border-box;

                font-size: 0.813rem;
                margin: 0 22% 10px;
                padding: 0;
                width: 56%;

                @include respond(small-down) {
                    margin: 0 0 10px;
                    width: 100%;
                }

                @include respond(small) {
                    margin: 0 0 10px;
                    width: 100%;
                }
            }

            button#credit-card-edit {
                box-sizing: border-box;

                font-size: 0.813rem;
                margin: 0 22% 10px;
                padding: 0;
                width: 56%;

                @include respond(small-down) {
                    margin: 0 0 10px;
                    width: 100%;
                }

                @include respond(small) {
                    margin: 0 0 10px;
                    width: 100%;
                }
            }

            button.cancel-button {
                border: none;
                background: none;
                display: inline-block;

                font-size: 0.813rem;
                margin: 0 22% 10px;
                padding: 0;
                text-decoration: underline;
                text-transform: capitalize;
                width: auto;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .form-label-text {
            text-align: left;
            margin: 0.625rem 0 1.25rem;
            padding: 0;
            width: 100%;

            label {
                margin: 0;
            }

            .required-indicator {
                color: $red;
                float: left;
                margin-right: 0.125em;
            }
        }

        .billing-address-container {
            box-sizing: border-box;
            float: left;
            margin: 60px 0 0;
            padding: 0 0 0 12px;
            width: 100%;

            h3 {
                color: $night-rider;

                font-size: 14px;
                line-height: 18px;
                margin: 0;
                padding: 0 0 15px;
                text-transform: uppercase;
                text-align: left;
                width: 100%;
            }

            .saved-addresses {
                width: 100%;

                .field-wrapper {
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    .radio-select {
                        margin: 0 0 28px;
                        position: relative;
                        width: 100%;

                        .form-row {
                            &.radio {
                                border: 1px solid $white-smoke;
                                box-sizing: border-box;
                                margin: 0 0 15px;
                                padding: 15px;
                                width: 100%;

                                .field-wrapper {
                                    border: none;

                                    .input-radio {
                                        display: inline-block;
                                        position: absolute;
                                        opacity: 0;
                                        width: 92%;
                                    }
                                }

                                label {
                                    padding: 0;

                                    div {
                                        color: $night-rider;

                                        font-size: 0.875rem;
                                        line-height: 24px;
                                        margin: 0;
                                        padding: 0;
                                    }

                                    span {
                                        color: $dim-gray;

                                        font-size: 0.875rem;
                                        line-height: 24px;
                                        margin: 0;
                                        padding: 0;
                                    }
                                }
                            }

                            &.errorSaveAddress {
                                border: 1px solid $red;
                            }

                            &.activeSaveAddress {
                                border: 1px dotted #333;
                                background: #f2f2f2;
                            }
                        }
                    }
                }
            }

            .add-new-addr {
                color: $night-rider;

                font-size: 14px;
                line-height: 24px;
                margin: 0;
                padding: 0;
                text-transform: capitalize;
                text-align: center;
                width: 100%;

                a {
                    color: $night-rider;
                    text-decoration: underline;

                    &:hover {
                        color: $dim-gray;
                        text-decoration: none;
                    }

                    &:active {
                        color: $dim-gray;
                    }

                    &:focus {
                        color: $dim-gray;
                    }
                }
            }

            .stored-payment-billing-address {
                .save-address-row {
                    box-sizing: border-box;
                    padding: 0 0 0 2%;
                    width: 100%;

                    #save-address-checkbox {
                        float: left;
                        opacity: 0.5;
                        width: auto;
                    }

                    label {
                        float: left;
                        padding: 0 0 0 2%;
                        opacity: 0.5;
                    }
                }

                .dialog-required {
                    margin: 0;
                }

                .form-row {
                    &.required {
                        width: 100%;
                    }

                    &.selectbox {
                        .field-wrapper {
                            .select-style {
                                width: 172px;
                            }
                        }
                    }

                    &.statesUS {
                        .field-wrapper {
                            .select-style {
                                width: 172px;
                            }
                        }
                    }

                    &.phone-link {
                        .field-wrapper {
                            float: left;
                            margin: 0;
                            padding: 0 0 0 2%;
                            width: 232px;

                            @include respond(small-down) {
                                width: 85%;
                            }

                            @include respond(small) {
                                width: 85%;
                            }

                            .phone {
                                float: left;
                                width: 222px;

                                @include respond(small-down) {
                                    width: 100%;
                                }

                                @include respond(small) {
                                    width: 100%;
                                }
                            }
                        }

                        .form-field-tooltip {
                            margin: 0 0 0 10px;
                            padding: 0;
                            text-align: left;
                            width: 10%;

                            @include respond(small-down) {
                                float: left;
                            }

                            @include respond(small) {
                                float: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

.disabled-form {
    .form-credit-card-section {
        .form-row {
            label {
                span {
                    color: $click;
                }
            }

            .field-wrapper {
                .input-text {
                    color: $click;
                }

                .select-style {
                    select {
                        color: $click;
                    }
                }
            }
        }
    }
}

.payment-method-delete {
    @include respond(small-down) {
        margin: 0 5%;
        width: 90% !important;
    }

    @include respond(small) {
        margin: 0 5%;
        width: 90% !important;
    }

    .ui-widget-header {
        .ui-dialog-title {
            display: none;
        }
    }

    #dialog-container {
        &.ui-dialog-content {
            padding: 0 !important;
        }

        .delete-card-popup-container {
            box-sizing: border-box;
            padding: 15px;
            text-align: center;
            width: 100%;

            .just-checking {
                color: $night-rider;

                font-size: 1.313rem;
                margin: 0;
                padding: 0 0 15px;
                text-align: left;
                text-transform: uppercase;
                width: 100%;
            }

            .delete-message {
                color: $dim-gray;

                font-size: 0.875rem;
                margin: 0;
                padding: 0 0 30px;
                text-align: left;
                width: 100%;
            }

            .delete-card-button {
                background: $night-rider;
                box-sizing: border-box;
                color: $white;
                font-size: 0.813rem;
                line-height: 16px;
                margin: 0 0 10px;
                max-height: none;
                padding: 9px 16px 8px;
                width: 150px;

                @include respond(small-down) {
                    width: 100%;
                }

                @include respond(small) {
                    width: 100%;
                }
            }

            .delete-popup-cancel {
                background: none;
                border: 0;
                color: $night-rider;
                display: block;
                font-size: 0.875rem;
                line-height: 24px;
                letter-spacing: normal;
                margin: 0;
                padding: 0;
                text-align: center;
                text-decoration: underline;
                text-transform: capitalize;
                width: 100%;
            }
        }
    }
}

/*My Payment Method Page Styles End Here*/
