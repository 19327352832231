.kibo-recommendations-container {
    margin: 48px 16px;

    @media screen and (min-width: $xlMin) {
        max-width: 1200px;
        margin: 56px auto;
    }

    &.ds-homepage-recommendations .ds-marketing-copy-heading {
        @include ds-heading(h2);
        color: $text-primary;
        text-align: center;
        display: block;
        margin-bottom: 16px;
    }

    .ds-smooth-scroll, .ds-smooth-slide {
        display: flex;
        flex-flow: row nowrap;
        column-gap: 16px;
        overflow-y: auto;
        align-items: flex-end;

        @media screen and (min-width: $xlMin) {
            padding-bottom: 28px;
            column-gap: 32px;
            margin-left: 16px;
            margin-right: 16px;
        }

        &::-webkit-scrollbar {
            display: none;

            @media screen and (min-width: $xlMin) {
                margin-top: 20px;
                height: 8px;
                display: inline;
            }
        }

        &::-webkit-scrollbar-thumb {
            @media screen and (min-width: $xlMin) {
                border-radius: 8px;
                background-color: $text-tertiary;
            }
        }

        &::-webkit-scrollbar-track {
            @media screen and (min-width: $xlMin) {
                background-color: transparent;
            }
        }

        &::-webkit-scrollbar-track-piece {
            @media screen and (min-width: $xlMin) {
                border-radius: 8px;
                margin-right: 16px;
            }
        }
    }

    .product-tile {
        min-height: 100%;
        margin-top: auto;
        margin-bottom: 0;
        flex: 1;
        min-width: 228px;
        max-width: 256px;
    }

    .product-image {
        margin-bottom: 12px;
    }

    .product-badge {
        @include ds-ui-semi(s);
        color: $text-feedback-info;
        margin: 0;
    }

    .product-cont {
        margin-top: 2px;
        margin-bottom: 0;
    }

    .product-name {
        margin-bottom: 0;
    }

    .product-type {
        margin-top: 0;
        color: $text-secondary;
    }

    .product-pricing {
        margin-top: 8px;
        margin-bottom: 2px;
    }

    .product-standard-price {
        margin: 0;
    }

    .product-pricing {
        .product-standard-price {
            margin-left: 0;
            margin-right: 8px;

        }

        .product-sales-price {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .product-promo {
        margin: 0;
    }

    .add-to-cart {
        margin-top: 12px;
        background-color: $bg-inverse-primary;
        border-color: $border-dark;

        &:hover,
        &:focus {
            background-color: $bg-inverse-secondary;
            border-color: $bg-inverse-secondary;
        }

        &:active {
            background-color: $bg-tertiary;
            border-color: $border-secondary;
            color: $text-inverse;
        }
    }

    .ds-heading {
        @include ds-heading(h2);
    }

    .view-product {
        display: none;
    }

    @media screen and (min-width: 768px) {
        &.cart-recommendations {
            .product-tile {
                min-width: 160px;
            }

            .grid-tile:first-of-type {
                margin-left: 24px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        &.cart-recommendations {
            margin: 48px 0;
        }
    }
}

.kibo-recommendations-container, .recommendations-modal-overlay {
    .ds-heading {
        color: $text-primary;
        text-align: center;
        display: block;
        margin-bottom: 16px;
    }
}

.recommendations-modal-overlay {
    .ds-modal.open {
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 620px;
        max-width: 343px;

        .ds-modal-content {
            padding: 32px 19px;
        }

        .slick-button {
            z-index: 999;
            margin-top: -30%;
            &.slick-disabled {
                display: none !important;
            }

            .slick-chevron {
                height: 26px;
                max-width: none;

                &.previous {
                    transform: rotate(90deg);
                    margin-left: -5px;
                }

                &.next {
                    transform: rotate(270deg);
                }
            }
        }

        .slick-track {
            margin-bottom: 0;
        }

        .slick-slide {
            margin: 0 26px;
        }

        @media screen and (max-width: 1199px) {
            min-height: 515px;
        }

        .kibo-recommendations-container .ds-smooth-slide {
            overflow: hidden;
        }

        .modal-loading-section {
            min-height: 100%;
            margin-top: 50%;
            display: flex;
            justify-content: center;
            align-items: center;



            .modal-spinner {
                animation: rotate 1200ms linear infinite;
                height: 48px;
            }
        }

        .grid-tile.recommendation-item {
            min-width: 100%;

            .product-tile {
                max-width: 100%;
                min-width: 100%;
            }

            .tile-image-wrapper {
                min-height: 300px;

                @media screen and (max-width: 960px) {
                    min-height: 195px;
                }
            }
        }

        .view-product {
            text-align: center;
            margin-top: 20px;
            display: block;
            @include ds-ui-semi(m);
            color: $text-primary;
            text-decoration: underline;

        }
    }

    .ds-heading {
        @include ds-heading(h3);
    }

    .ds-smooth-scroll, .kibo-recommendations-container .ds-smooth-slide {
        padding-bottom: 0;
    }

    .kibo-recommendations-container {
        margin: 0 auto;
    }

}
