.ds-fulfillment-card {
    color: $text-secondary;
    max-width: 396px;
    text-align: left;
    width: 100%;

    .ds-fulfillment-desktop {
        display: none;

        @media screen and (min-width: $xlMin) {
            display: block;
        }
    }

    .ds-fulfillment-mobile {
        @media screen and (min-width: $xlMin) {
            display: none;
        }
    }

    .ds-fulfillment-content {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12px 0 16px 12px;
        border: 1.5px solid $border-primary;
        border-radius: 4px;
    }

    .ds-heading-container {
        @include ds-heading(h2);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 4px;
    }

    .ds-dot {
        @include ds-ui-semi(m);
        margin: 0 4px;
    }

    .ds-supporting-text {
        @include ds-ui-regular(m);
    }
}

.ds-fulfillment-card-heavy,
.ds-fulfillment-card-urgent {
    color: $text-primary;
    position: relative;

    &::after {
        background-color: $border-dark;
        background-image: url("../images/svg-icons/history-24.svg");
        background-position: center;
        background-repeat: no-repeat;
        border-bottom-left-radius: 4px;
        border-radius: 0 4px;
        content: "";
        display: block;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
    }

    .ds-fulfillment-content {
        border-color: $border-dark;
        padding: 12px 16px 16px 16px;
    }

    .ds-heading-container {
        @include ds-heading(h1);
    }

    .ds-supporting-text {
        @include ds-ui-regular(l);
    }

    .ds-fulfillment-method {
        @include ds-ui-bold(l);
    }
}

.ds-fulfillment-card-urgent {
    color: $text-feedback-danger;

    &::after {
        background-color: $text-feedback-danger;
    }

    .ds-fulfillment-content {
        border-color: $text-feedback-danger;
    }
}

.ds-fulfillment-card-light {
    @media screen and (min-width: $xlMin) {
        .ds-heading-container {
            margin-top: 2px;
        }

        .ds-supporting-text {
            margin-bottom: 2px;
        }
    }
}

.ds-supporting-text__post {
    display: none;
    @media screen and (min-width: $xlMin) {
        display: block;
    }
}

.ds-fulfillment-content__branding {
    padding-right: 0;
    @media screen and (min-width: $xlMin) {
        padding-right: 8px;    
    }
}

.ds-fulfillment-content__external-icon{
    padding-left: 4px;
}

.ds-fulfillment-card-feature {
    position: relative;
    transition: box-shadow .2s ease-in-out;

    .ds-fulfillment-content {
        border-color: $border-feature;
    }

    &:hover {
        box-shadow: 0px 4px 6px -1px #{rgba($brand-black, .10)}, 0px 2px 4px -1px #{rgba($brand-black, .06)};
    }

    .ds-supporting-text {  
        display: flex;
        align-items: center;
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
