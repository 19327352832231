.footer {
    .ds-user-info-collection {
        margin-bottom: 40px;

        @media only screen and (min-width: $mMin) and (max-width: $lMax) {
            margin-bottom: 32px;
        }
    }

    .socials {
        @media only screen and (max-width: $sMax) {
            margin-left: 16px;
        }

        li {
            line-height: 10px;
        }
    }

    .social-header {
        text-transform: capitalize;
        margin: 0 0 16px 0;
        @include ds-ui-bold(m);
        text-align: left;
    }
}
